import React, { useEffect } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import CustomCheckbox from '../../../../common/CustomCheckbox';
import CurrencyInput from '../../../../common/CurrencyInput';
import PropTypes from 'prop-types';

const AddonCapacityAndPricing = ({
	// setEnabled,
	maxNumber,
	setMaxNumber,
	price,
	setPrice,
	isUnlimited,
	setUnlimited
}) => {
	const dictionary = useDictionary();

	// const [showDialog, setShowDialog] = useState(false);

	useEffect(() => {}, [maxNumber, price]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2 style={{ fontSize: '24px' }}>
					{dictionary.how_many_can_you_provide}
				</h2>
				<div className="text" style={{ opacity: '0.8' }}>
					{dictionary.think_about_addon}
				</div>
				<div style={{ marginTop: '30px' }} className="group-inputs">
					<label style={{ fontSize: '16px' }}>{dictionary.max_addons}</label>
					<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
						<input
							style={{ width: '100px' }}
							type="number"
							min="1"
							step="1"
							onKeyDown={(e) =>
								['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
							}
							value={maxNumber}
							onChange={(e) => setMaxNumber(e.target.value)}
						/>
					</div>
				</div>
				<CustomCheckbox
					checked={isUnlimited}
					setChecked={setUnlimited}
					type={2}
					label={dictionary.unlimited_addons}
				/>
				<h2 style={{ fontSize: '24px', marginTop: '44px' }}>
					{dictionary.addon_pricing}
				</h2>
				<div style={{ marginTop: '15px', opacity: '0.8' }} className="text">
					{dictionary.how_much_you_charge}
				</div>
				<CurrencyInput
					label={dictionary.price_per_item}
					value={price}
					setValue={setPrice}
				/>
			</div>
		</div>
	);
};

AddonCapacityAndPricing.propTypes = {
	setEnabled: PropTypes.func.isRequired,
	maxNumber: PropTypes.number.isRequired,
	setMaxNumber: PropTypes.func.isRequired,
	price: PropTypes.number.isRequired,
	setPrice: PropTypes.func.isRequired,
	isUnlimited: PropTypes.bool.isRequired,
	setUnlimited: PropTypes.func.isRequired
};

export default AddonCapacityAndPricing;
