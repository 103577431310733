import '../../../../assets/css/settings.css';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../common/SimpleLoader';
// import * as auth from '../../auth/__redux/authRedux';
import * as common from '../../common/__redux/commonRedux';
import { getProviderInfo } from '../provider_home/networking';
import CompanyProfile from './forms/CompanyProfile';
import LanguageSelection from './forms/LanguageSelection';
import MyProfile from './forms/MyProfile';
import Notifications from './forms/Notifications';
import LegalInfo from './forms/legal_info/LegalInfo';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

const MySettings = (props) => {
	const dictionary = useDictionary();

	const [index, setIndex] = useState(0);
	const [showLoader, setShowLoader] = useState(true);
	/*    const [firstName, setFirstName] = useState('')
        const [lastName, setLastName] = useState('')
        const [phone, setPhone] = useState('')
        const [email, setEmail] = useState('')
        const [profilePhoto, setProfilePhoto] = useState('')
        const [companyName, setCompanyName] = useState('')
        const [legalName, setLegalName] = useState('')
        const [website, setWebsite] = useState('')
        const [address1, setAddress1] = useState('')
        const [address2, setAddress2] = useState('')
        const [city, setCity] = useState('')
        const [region, setRegion] = useState('')
        const [country, setCountry] = useState('')*/

	const [info, setInfo] = useState(null);
	const [profilePhoto, setProfilePhoto] = useState();
	const [companyPhoto, setCompanyPhoto] = useState('');
	const [redirected, setRedirected] = useState(0);

	const { id } = useParams();

	const source = axios.CancelToken.source();

	useEffect(() => {
		getUserInfoAsync();
		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	useEffect(() => {
		if (info !== null) {
			props.update_profile(info);
		}
	}, [info]);

	useEffect(() => {
		if (info !== null) {
			const newInfo = {
				...info,
				profile_photo: profilePhoto
			};
			setInfo(newInfo);
		}
	}, [profilePhoto]);

	useEffect(() => {
		if (info !== null) {
			const newInfo = {
				...info,
				company_photo: companyPhoto
			};
			setInfo(newInfo);
		}
	}, [companyPhoto]);

	const getUserInfoAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getProviderInfo(source.token);

			setInfo(data);
			setProfilePhoto(data.profile_photo);
			setCompanyPhoto(data.company_photo);
			localStorage.setItem('company_photo_path', data.company_photo?.path);
			setShowLoader(false);
			if (id && redirected === 0) {
				setIndex(parseInt(id));
				setRedirected(redirected + 1);
			}
		} catch (e) {
			setShowLoader(false);
		}
	};

	const routeMap = {
		0: (
			<MyProfile
				info={info}
				setInfo={setInfo}
				profilePhoto={profilePhoto}
				setProfilePhoto={setProfilePhoto}
			/>
		),
		1: (
			<CompanyProfile
				info={info}
				setInfo={setInfo}
				companyPhoto={companyPhoto}
				setCompanyPhoto={setCompanyPhoto}
			/>
		),
		2: (
			<LegalInfo
				getUserInfoAsync={getUserInfoAsync}
				setShowLoader={setShowLoader}
				remoteData={info}
			/>
		),
		3: <LanguageSelection />,
		4: <Notifications info={info} setInfo={setInfo} />
	};

	return (
		<div className="settings-wrapper">
			<h2>{dictionary.my_settings}</h2>
			<div className="settings-container">
				<div className="settings-tab-bar">
					<div
						onClick={() => setIndex(0)}
						className={
							index === 0 ? 'settings-tab settings-tab-active' : 'settings-tab'
						}
						data-testid="profile-tab"
					>
						{dictionary.profile}
					</div>
					<div
						onClick={() => setIndex(1)}
						className={
							index === 1 ? 'settings-tab settings-tab-active' : 'settings-tab'
						}
						data-testid="company-tab"
					>
						{dictionary.company_profile}
					</div>
					<div
						onClick={() => setIndex(2)}
						className={
							index === 2 ? 'settings-tab settings-tab-active' : 'settings-tab'
						}
						data-testid="legal-tab"
					>
						{dictionary.legal_info}
					</div>
					{/*<div onClick={() => setIndex(3)}
                         className={index === 3 ? 'settings-tab settings-tab-active' : 'settings-tab'}>{dictionary.language}</div>
                    <div onClick={() => setIndex(4)}
                         className={index === 4 ? 'settings-tab settings-tab-active' : 'settings-tab'}>{dictionary.notifications}</div>
                    <div onClick={() => setIndex(5)}
                         className={index === 5 ? 'settings-tab settings-tab-active' : 'settings-tab'}>{dictionary.billing}</div>*/}
				</div>
				{routeMap[index]}
				<SimpleLoader showLoader={showLoader} />
			</div>
		</div>
	);
};

MySettings.propTypes = {
	update_profile: PropTypes.func
};

export default connect(common.states, common.actions)(MySettings);
