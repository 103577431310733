import { ADMIN_COLLECTION_GROUP } from '../../../../../config';
import { DataTableHelper } from '../../../../../utilities/helpers/DataTableHelper';
import axios from 'axios';

export function getGroups(
	sortParam,
	pageSize,
	pageNumber,
	searchParam = '',
	createDateParam = '',
	filterStatusParams = [],
	filterPaymentStatusParams = []
) {
	return axios.get(
		ADMIN_COLLECTION_GROUP +
			( DataTableHelper.prepareParameters(
						sortParam,
						pageSize,
						pageNumber,
						null,
						searchParam,
						createDateParam,
						filterStatusParams,
						filterPaymentStatusParams
				)),
	);
}

export function getGroup(id) {
	return axios.get(ADMIN_COLLECTION_GROUP + '/' + id);
}

export function createProviderGroup(data) {
	return axios.post(ADMIN_COLLECTION_GROUP , data);
}

export function deleteProviderGroup(id) {
	return axios.delete(ADMIN_COLLECTION_GROUP + '/' + id);
}

export function updateProviderGroup(id, data) {
	return axios
		.put(ADMIN_COLLECTION_GROUP + '/' + id, data)
		.catch((error) => {
			return error.response;
		});
}

export function changeGroupStatus(groupId, newStatus) {
	const data = {
		is_active: newStatus
	};
	return axios
		.put(ADMIN_COLLECTION_GROUP + '/' + groupId, data)
		.catch((error) => {
			return error.response;
		});
}

export function removeProviderExperience(groupId, experienceId) {
	return axios
		.delete(ADMIN_COLLECTION_GROUP + '/' + groupId + '/experience/' + experienceId)
		.catch((error) => {
			return error.response;
		});
}

