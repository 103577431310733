import '../App.css';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './Routes';
import PropTypes from 'prop-types';

function App({ store, persistor, basename }) {
	return (
		<Provider store={store}>
			<PersistGate persistor={persistor}>
				<BrowserRouter basename={basename}>
					<Routes />
				</BrowserRouter>
			</PersistGate>
		</Provider>
	);
}

App.propTypes = {
	store: PropTypes.object,
	persistor: PropTypes.object,
	basename: PropTypes.string
};

export default App;
