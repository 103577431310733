import error from '../../../assets/icons/ic_fail.svg';
import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import { connect } from 'react-redux';
import * as common from './__redux/commonRedux';
import PropTypes from 'prop-types';

const ErrorModal = ({ code, setErrorDialogVisibility, setErrorMessage }) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 10);
	}, []);

	const closeDialog = () => {
		setOpacity(0);
		setTimeout(() => {
			setErrorMessage('');
			setErrorDialogVisibility(false);
		}, 300);
	};

	return (
		<div
			className="dialog-wrapper"
			style={{ opacity: opacity, zIndex: '12' }}
			onClick={() => {
				closeDialog();
			}}
		>
			<div
				className="dialog-container dialog-container-narrow"
				onClick={(e) => e.stopPropagation()}
				style={{ padding: '24px', alignItems: 'center', gap: '12px' }}
			>
				<div
					className="dialog-close-button"
					style={{ top: '12px' }}
					onClick={closeDialog}
				/>
				<img
					className="modal-large-icon"
					style={{ marginTop: '24px' }}
					src={error}
				/>
				<h4>{dictionary.errors[code] || dictionary.errors.default}</h4>
			</div>
		</div>
	);
};

ErrorModal.propTypes = {
	code: PropTypes.string,
	setErrorDialogVisibility: PropTypes.func,
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(ErrorModal);
