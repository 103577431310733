/* eslint-disable no-unused-vars */
export const DateTimeUtils = (function () {
	function treatAsUTC(date) {
		let result = new Date(date);
		result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
		return result;
	}

	function daysBetween(date) {
		let millisecondsPerDay = 24 * 60 * 60 * 1000;
		let diff = (Date.now() - date * 1000) / millisecondsPerDay;
		if (diff < 1) {
			return 0;
		}
		return Math.round((Date.now() - date * 1000) / millisecondsPerDay);
	}

	function isToday(timestamp) {
		let date = new Date(timestamp);
		let now = Date.now();
		const yearDate = date.getYear();
		const monthDate = date.getMonth();
		const dayDate = date.getDate();
		const yearNow = now.getYear();
		const monthNow = now.getMonth();
		const dayNow = now.getDate();
		if (yearDate === yearNow && monthDate === monthNow && dayDate === dayNow) {
			return true;
		}
		return false;
	}

	function dateFormat(timestamp) {
		return new Date(timestamp * 1000).toLocaleString(['en-us'], {
			year: 'numeric',
			month: '2-digit',
			day: '2-digit'
		});
	}

	function formatDate(date, format) {
		if (typeof date === 'object') {
			return date.toISOString().slice(0, 10);
			/*const map = {
                mm: date.getMonth() + 1,
                dd: date.getDate(),
                yy: date.getFullYear().toString().slice(-2),
                yyyy: date.getFullYear()
            }

            return format.replace(/mm|dd|yy|yyy/gi, matched => map[matched])*/
		}

		if (date.includes(' ')) {
			return date.split(' ')[0];
		}

		return date;
	}

	function getCurrentDate(separator = '/') {
		let newDate = new Date();
		let date = newDate.getDate();
		let month = newDate.getMonth() + 1;
		let year = newDate.getFullYear();

		return `${
			month < 10 ? `0${month}` : `${month}`
		}${separator}${date}${separator}${year}`;
	}

	function getCurrentMonthYear() {
		let date = new Date();
		let options = { month: 'long', year: 'numeric' };

		return date.toLocaleString('en-US', options);
	}

	function timeToMilliSeconds(time) {
		if (time) {
			const timeArray = time.split(':');
			return (+timeArray[0] + +timeArray[1] / 60) * 3600000;
		} else {
			return 0;
		}
	}

	return {
		daysBetween: function (date) {
			return daysBetween(date);
		},
		isToday: function (timestamp) {
			return isToday(timestamp);
		},
		dateFormat: function (timestamp) {
			return dateFormat(timestamp);
		},
		formatDate: function (date, format) {
			return formatDate(date, format);
		},
		getCurrentDate: function (separator) {
			return getCurrentDate(separator);
		},
		getCurrentMonthYear: function () {
			return getCurrentMonthYear();
		},
		timeToMilliSeconds: function (time) {
			return timeToMilliSeconds(time);
		}
	};
})();
