import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useFormik } from 'formik';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const WizardProfileForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);

	const wizardProfileSchema = Yup.object().shape({
		first_name: Yup.string().required(dictionary.first_name_required),
		last_name: Yup.string().required(dictionary.last_name_required),
		phone: Yup.string().required(dictionary.phone_number_required)
	});

	const formik = useFormik({
		initialValues: {
			first_name: props.details.first_name,
			last_name: props.details.last_name,
			phone: props.details.phone
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, props.details);
			newDetails.first_name = values.first_name;
			newDetails.last_name = values.last_name;
			newDetails.phone = values.phone;

			props.setDetails(newDetails);
			props.setStep(2);
		},
		validateOnMount: true,
		validationSchema: wizardProfileSchema
	});

	FormikHelper.setFormik(formik);

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	return (
		<form
			style={{ opacity: showForm ? '1' : '0', overflowY: 'auto' }}
			className="login-form-content-container transparent-form thin-scrollbar"
			onSubmit={formik.handleSubmit}
		>
			<h1>{dictionary.lets_setup}</h1>
			{/* <div className="text">{dictionary.step1_message}</div> */}
			{FormikHelper.getInputTemplate(
				'first_name',
				dictionary.first_name,
				'John',
				'text',
				true,
				true
			)}
			{FormikHelper.getInputTemplate(
				'last_name',
				dictionary.last_name,
				'Wick',
				'text',
				true,
				true
			)}
			{FormikHelper.getPhoneNumberInputTemplate(
				'phone',
				dictionary.phone,
				'00302102201630',
				'phone',
				true,
				true
			)}

			<div className="group-inputs" style={{ marginTop: '30px' }}>
				<button
					data-testid="continue-button"
					type="submit"
					className={formik.isValid ? 'button' : 'button button-disabled'}
				>
					{dictionary.continue}
				</button>
			</div>
		</form>
	);
};

WizardProfileForm.propTypes = {
	details: PropTypes.object.isRequired,
	setDetails: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired
};

export default WizardProfileForm;
