import users from '../../../../../assets/icons/ic_users.svg';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import RelatedExperienceItem from '../../provider-resources/RelatedExperieceItem';
import PropTypes from 'prop-types';
// import addOns from '../../../../../assets/icons/ic_four_circles.svg';
import React, { useMemo } from 'react';
import { DateFormatHelper } from '../../../../../utilities/helpers/DateFormatHelper';

const ExperienceDetailsPopup = ({
	experience,
	onCancelClicked,
	showActions,
	onEditSlotClicked,
	showSlotDetails,
	onDeleteSlotClicked,
}) => {
	const dictionary = useDictionary();

	const onEditClicked = () => {
		onCancelClicked();
		onEditSlotClicked(false);
	};

	const handleOpenDetails = () => {
		showSlotDetails(true);
		onCancelClicked();
		// history.push(`/${NavigatorPaths.calendar.path}?id=${experience.id}`);
	};

	const getActions = () => {
		return (
			<div
				className="dialog-title-container"
				style={{ justifyContent: 'flex-end', gap: '12px' }}
			>
				{DateFormatHelper.isFutureDay(experience.start_time) && experience.status.value === 'open' ? (
					<>
						<div
							className="dialog-close-button dialog-edit-button"
							data-testid="edit-button"
							onClick={onEditClicked}
						/>
						<div
							className="dialog-close-button dialog-delete-button"
							data-testid="delete-button"
							onClick={() => onDeleteSlotClicked(false)}
						/>
					</>
				) : null}
				<div
					className="dialog-close-button"
					style={{ position: 'unset' }}
					onClick={onCancelClicked}
				/>
			</div>
		);
	};

	return (
		<div
			onClick={(e) => e.stopPropagation()}
			className="dialog-container dialog-container-narrow"
			style={{ maxHeight: '90%' }}
		>
			{showActions ? (
				getActions()
			) : (
				<div className="dialog-title-container">
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
			)}
			<div
				className="dialog-content-container-with-scroll thin-scrollbar"
				style={{ height: 'unset' }}
			>
				<div className="experience-details-dialog-row">
					<div
						className="experience-details-dialog-color"
						style={{ backgroundColor: experience?.color, marginTop: '3px' }}
					/>
					<div className="experience-details-dialog-row-content">
						<h3 style={{ lineHeight: '20px' }} data-testid="experience-title">
							{experience?.title}
						</h3>
						<h4 style={{ fontWeight: 'normal' }}>
							{DateFormatHelper.rangeDayMonthTimeFormat(
								experience.start_time,
								experience.end_time
							)}
						</h4>
						<div
							className="calendar-slot-experience-list-item"
							style={{
								fontSize: '13px',
								lineHeight: '16px'
							}}
						>
							{DateFormatHelper.slotRecurringFormat(
								experience?.setting,
								dictionary
							)}
						</div>
						{experience.experiences ? (
							<>
								<h4 style={{ marginTop: '24px' }}>
									{dictionary.used_in_experiences}
								</h4>
								<div
									className="experience-info-rows-container"
									style={{ height: 'unset' }}
								>
									{experience.experiences.map((experience) => (
										<RelatedExperienceItem key={experience} item={experience} />
									))}
									{!experience.experiences ||
									experience.experiences.length === 0 ? (
										<div className={'text'}>
											{dictionary.no_experiences_yet}
										</div>
									) : null}
								</div>
							</>
						) : (
							<h4 style={{ fontSize: '14px', marginTop: '24px' }}>
								{experience.is_private_tour
									? dictionary.private
									: dictionary.public}
							</h4>
						)}
					</div>
					<div className="participant-avatar" style={{ marginTop: '26px' }} />
				</div>

				{experience.capacity ? (
					<>
						<div
							className="experience-details-dialog-row"
							style={{ marginTop: '24px' }}
						>
							<img src={users} />
							<h4 style={{ fontSize: '14px' }}>{dictionary.participants}</h4>
							<div className="flex-filler" />
							<h4
								style={{
									fontSize: '14px',
									minWidth: '56px'
								}}
							>
								{experience?.booked + '/' + experience?.capacity?.toString()}
							</h4>
						</div>
						{/* <div className='experience-details-dialog-row' style={{marginTop: '24px'}}>
                        <img src={addOns}/>
                        <h4 style={{fontSize: '14px'}}>{dictionary.add_ons}</h4>
                        <div className='flex-filler'/>
                        <h4 style={{fontSize: '14px', minWidth: '56px'}}>0</h4>
                    </div>*/}
					</>
				) : null}
			</div>

			{showActions ? (
				<>
					<div className="dialog-title-divider" />
					<div
						className="dialog-buttons-container"
						style={{ padding: '12px', marginBottom: '0', gap: '16px' }}
					>
						{/*<button className='button plus-button'
                        style={{width: '0', flexGrow: '1'}}>{dictionary.create_new_booking}</button>*/}
						<div
							className="secondary-button"
							data-testid="details-button"
							onClick={handleOpenDetails}
						>
							{dictionary.details}
						</div>
					</div>
				</>
			) : null}
		</div>
	);
};

ExperienceDetailsPopup.propTypes = {
	experience: PropTypes.object,
	onCancelClicked: PropTypes.func,
	onEditSlotClicked: PropTypes.func,
	onDeleteSlotClicked: PropTypes.func,
	showActions: PropTypes.bool,
	showSlotDetails: PropTypes.func
};

export default ExperienceDetailsPopup;
