// import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUsage from './TermsOfUsage';
import GeneralTermsAndConditions from './provider_documents/GeneralTermsAndConditions';
import ProviderCancellationPolicy from './provider_documents/ProviderCancellationPolicy';
import ProviderTermsAndConditions from './provider_documents/ProviderTermsAndConditions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const LegalInfoDialog = ({ type, setLegalInfoDialogVisibility }) => {
	// const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setLegalInfoDialogVisibility(false);
		}, 800);
	};

	const documentsMap = {
		1: <PrivacyPolicy />,
		2: <TermsOfUsage />,
		3: <GeneralTermsAndConditions />,
		4: <ProviderTermsAndConditions />,
		5: <ProviderCancellationPolicy />
	};

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ opacity: opacity }}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className="dialog-container terms-dialog-container"
				style={{ maxHeight: '90%' }}
				data-testid="terms-dialog"
			>
				<div className="dialog-title-container">
					<div className="dialog-title">
						{/*{isTerms ? dictionary.terms_label : dictionary.privacy_policy_label}*/}
					</div>
					<div
						className="dialog-close-button"
						onClick={onCancelClicked}
						data-testid="terms-dialog-close-button"
					/>
				</div>
				<div
					className="dialog-title-divider"
					style={{ marginBottom: 'unset' }}
				/>
				<div className="terms-dialog-content-container thin-scrollbar-trans">
					{documentsMap[type]}
				</div>
			</div>
		</div>
	);
};

LegalInfoDialog.propTypes = {
	type: PropTypes.number.isRequired,
	setLegalInfoDialogVisibility: PropTypes.func.isRequired
};

export default LegalInfoDialog;
