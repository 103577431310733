import { Popover } from 'react-tiny-popover';
import React, { useEffect, useRef, useState } from 'react';
import '../../../../assets/css/custom_timepicker.css';
import PropTypes from 'prop-types';
import InfiniteScrollLoop from '../../common/InfiniteScrollLoop';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/ic_clock.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';

export const TimePickerField = ({ value, disabled, onChange }) => {
	const dictionary = useDictionary();
	const timePickerRef = useRef(null);
	const timeSelectWrapper = useRef(null);
	const hContentRef = useRef(null);
	const mContentRef = useRef(null);
	const hInputRef = useRef(null);
	const mInputRef = useRef(null);
	const [showPicker, setShowPicker] = useState(false);
	const [hour, setHour] = useState(null);
	const [minute, setMinute] = useState(null);
	const [editable, setEditable] = useState(false);

	const handleOpen = () => {
		if (!disabled) {
			setShowPicker(true);
			if (editable) {
				setEditable(false);
			}
		}
	};

	const handleClose = () => {
		setShowPicker(false);
	};

	function handleClickOutside(event) {
		if (timeSelectWrapper.current && !timeSelectWrapper.current.contains(event.target)) {
			setShowPicker(false);
		}
	}

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const displayTime = (time) => `${time > 9 ? '' : '0'}${time}`;

	const handleApply = (e) => {
		setEditable(true);
		onChange(`${hour}:${minute}`);
		hInputRef.current?.focus();
		e.stopPropagation();
		handleClose();
	};

	const handleValueChange = (e) => {
		let type;
		if (e.target === hInputRef.current) {
			type = 'h';
		}

		if (e.target === mInputRef.current) {
			type = 'm';
		}

		let h = +hour,
			m = +minute;
		switch (e.key) {
			case 'ArrowDown': {
				if (type === 'h') {
					h = h - 1;
					if (h < 0) {
						h = 23;
					}

					setHour(displayTime(h));
					onChange(`${displayTime(h)}:${minute}`);
				}

				if (type === 'm') {
					m = m - 1;
					if (m < 0) {
						m = 59;
					}

					setMinute(displayTime(m));
					onChange(`${hour}:${displayTime(m)}`);
				}
				break;
			}

			case 'ArrowUp': {
				if (type === 'h') {
					h = h + 1;
					if (h > 23) {
						h = 0;
					}

					setHour(displayTime(h));
					onChange(`${displayTime(h)}:${minute}`);
				}

				if (type === 'm') {
					m = m + 1;
					if (m > 59) {
						m = 0;
					}

					setMinute(displayTime(m));
					onChange(`${hour}:${displayTime(m)}`);
				}
				break;
			}

			case 'ArrowLeft': {
				if (type === 'm') {
					hInputRef.current.focus();
				}
				break;
			}

			case 'ArrowRight': {
				if (type === 'h') {
					mInputRef.current.focus();
				}
				break;
			}
		}

		if (e.keyCode < 48 || e.keyCode > 57) {
			return;
		}

		if (type === 'h') {
			if (h < 2) {
				h = h * 10 + +e.key;
				if (h > 2) {
					mInputRef.current.focus();
				}
			} else if (h < 3) {
				if (+e.key < 4) {
					h = h * 10 + +e.key;
				} else {
					h = h * 10 + +e.key - 24;
				}
				mInputRef.current.focus();
			} else {
				h = +e.key;
				if (h > 2) {
					mInputRef.current.focus();
				}
			}
			setHour(displayTime(h));
			onChange(`${displayTime(h)}:${minute}`);
		}

		if (type === 'm') {
			if (m < 6) {
				m = m * 10 + +e.key;
				mInputRef.current.blur();
			} else {
				m = +e.key;
			}
			setMinute(displayTime(m));
			onChange(`${hour}:${displayTime(m)}`);
		}
	};

	useEffect(() => {
		if (value) {
			setHour(value.split(':')[0]);
			setMinute(value.split(':')[1]);
		} else {
			setHour(null)
			setMinute(null)
		}
	}, [value]);

	useEffect(() => {
		if (hContentRef.current && mContentRef.current) {
			const hActiveItems = hContentRef.current.getElementsByClassName(
				'time-picker-item--active'
			);
			const mActiveItems = mContentRef.current.getElementsByClassName(
				'time-picker-item--active'
			);
			setTimeout(() => {
				if (hActiveItems.length > 0) {
					hActiveItems[2].scrollIntoView();
				}
				if (mActiveItems.length > 0) {
					mActiveItems[2].scrollIntoView();
				}
			}, 50);
		}
	}, [showPicker]);

	return (
		<div className={`time-picker-field ${disabled && 'time-picker-field--disabled'}`} ref={timePickerRef} onClick={handleOpen}>
			<div className="time-picker-input">
				{value ? (
					<>
						<input
							type="text"
							readOnly={!editable}
							ref={hInputRef}
							value={hour}
							onKeyDown={handleValueChange}
						/>
						<span>:</span>
						<input
							type="text"
							readOnly={!editable}
							ref={mInputRef}
							value={minute}
							onKeyDown={handleValueChange}
						/>
					</>
				) : (
					<div className="time-picker-placeholder">
						{dictionary.select_time}
					</div>
				)}
			</div>
			<Popover
				isOpen={showPicker}
				align="end"
				positions={['bottom']}
				containerStyle={{
					left: `${-(timePickerRef.current?.offsetWidth - 208)}px`
				}}
				onClickOutside={handleClose}
				content={
					<div className="time-picker" ref={timeSelectWrapper}>
						<div className="time-picker-content">
							<div className="time-picker-selector" ref={hContentRef}>
								<InfiniteScrollLoop>
									{new Array(24).fill('').map((_, index) => (
										<div
											key={index}
											className={`time-picker-item ${
												hour === displayTime(index)
													? 'time-picker-item--active'
													: ''
											}`}
											onClick={(e) => {
												setHour(displayTime(index));
												e.currentTarget.scrollIntoView({
													behavior: 'smooth'
												});
											}}
										>
											{displayTime(index)}
										</div>
									))}
								</InfiniteScrollLoop>
							</div>
							<div className="time-picker-selector" ref={mContentRef}>
								<InfiniteScrollLoop>
									{new Array(4).fill('').map((_, index) => (
										<div
											key={index}
											className={`time-picker-item ${
												minute === displayTime(index * 15)
													? 'time-picker-item--active'
													: ''
											}`}
											onClick={(e) => {
												setMinute(displayTime(index * 15));
												e.currentTarget.scrollIntoView({
													behavior: 'smooth'
												});
											}}
										>
											{displayTime(index * 15)}
										</div>
									))}
								</InfiniteScrollLoop>
							</div>
						</div>
						<div className="time-picker-action">
							<button
								disabled={!(hour && minute)}
								className="button"
								onClick={handleApply}
							>
								Apply
							</button>
						</div>
					</div>
				}
			>
				<ClockIcon />
			</Popover>
		</div>
	);
};

TimePickerField.propTypes = {
	value: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};
