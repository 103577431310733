import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import resourceIllustration from '../../../../../../assets/images/schedule_resource_illustration.svg';
import FormLoader from '../../../../common/FormLoader';
import * as common from '../../../../common/__redux/commonRedux';
import CustomToast from '../../../../common/CustomToast';
import placeholder from '../../../../../../assets/images/bg_placeholder_square.svg';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import useComponentVisible from '../../../../../../utilities/hooks/useComponentVisible';
import { createProviderGroup, getGroup, updateProviderGroup, removeProviderExperience } from '../networking';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';

const GroupInfo = ({ type, showDialog, setShowDialog, groupSaveEvent, editId }) => {
    const dictionary = useDictionary();
    const [showDialogAnimation, setShowDialogAnimation] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [groupNameValue, setGroupNameValue] = useState('');
    const [groupDescriptionValue, setGroupDescriptionValue] = useState('');
    const [groupExperiences, setGroupExperiences] = useState([]);
    const [enableCreating, setEnableCreating] = useState(false);
    const [isExperienceRemoved, setIsExperienceRemoved] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState({});
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [showLoaderMessage, setShowLoaderMessage] = useState(false);
    const [loaderMessage, setLoaderMessage] = useState(null);

    useEffect(() => {
        if (type === 'edit') {
            getGroupInfo();
        }
    }, []);

    useEffect(() => {
        setShowDialogAnimation(true);
        setEnableCreating(true);
    }, []);

    const getGroupInfo = async () => {
        setShowLoader(true);
        try {
            let { data } = await getGroup(editId);
            
            if (data) {
                setGroupNameValue(data.title || '');
                setGroupDescriptionValue(data.description || '');
                setGroupExperiences(data.experiences || []);
            }
            setShowLoader(false);
        } catch (e) {
            setShowLoader(false);
        }
    };

    const handleTitleChange = (event) => {
        setGroupNameValue(event.target.value);
    };

    const handleDescriptionChange = (event) => {
        setGroupDescriptionValue(event.target.value);
    };

    const dismissDialog = () => {
        setShowDialogAnimation(false);
        if (isExperienceRemoved === true) {
            groupSaveEvent(true);
        }
        setTimeout(() => {
            setShowDialog(false);
        }, 300);
    };

    const onFinishClicked = () => {
        if (type === 'edit') {
            updateGroup();
        } else {
            createGroup();
        }
    };

    const updateGroup = async () => {
        setShowLoader(true);
        setEnableCreating(false);
        if (groupNameValue !== null) {
            try {
                let body = { title: groupNameValue, description: groupDescriptionValue };
                await updateProviderGroup(editId, body);
                setShowLoader(false);
                groupSaveEvent(true);
                dismissDialog();
            } catch (e) {
                setEnableCreating(true);
                setShowLoader(false);
                if (e?.response?.data?.message) {
                    toast.error(<CustomToast message={e.response.data.message} />, {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        closeButton: false,
                        icon: false,
                        className: 'toast-white-error'
                    });
                }
            }
        }
    };

    const createGroup = async () => {
        setShowLoader(true);
        setEnableCreating(false);
        if (groupNameValue !== null) {
            try {
                let body = { title: groupNameValue, description: groupDescriptionValue };
                const { data } = await createProviderGroup(body);
                setShowLoader(false);
                groupSaveEvent(data);
                dismissDialog();
            } catch (e) {
                setEnableCreating(true);
                setShowLoader(false);
                if (e?.response?.data?.message) {
                    toast.error(<CustomToast message={e.response.data.message} />, {
                        position: 'bottom-left',
                        autoClose: 5000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        closeButton: false,
                        icon: false,
                        className: 'toast-white-error'
                    });
                }
            }
        }
    };

    const removeExperience = async (experienceId) => {
        setShowLoader(true);
        try {
            await removeProviderExperience(editId, experienceId);
            setGroupExperiences((prevExperiences) =>
                prevExperiences.filter((experience) => experience.id !== experienceId)
            );
            setIsExperienceRemoved(true);
            setSelectedExperience({});
            setIsComponentVisible(false);
            setShowLoader(false);
        } catch (error) {
            setShowLoader(false);
            toast.error(<CustomToast message={error?.response?.data?.message || 'Error removing experience'} />, {
                position: 'bottom-left',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                closeButton: false,
                icon: false,
                className: 'toast-white-error'
            });
        }
    };

    const handleExperienceClick = (experience, event) => {
        setSelectedExperience(experience);
        setMenuPosition({ top: event.nativeEvent.layerY, left: event.nativeEvent.layerX-110 });
        setIsComponentVisible(true);
    };

    return (
        <div className={`group-category-tags-wrapper${showDialogAnimation ? ' group-category-tags-wrapper-visible' : ''}`}>
            <div className="group-dialog-content-wrapper thin-scrollbar">
                <div
                    className="dialog-close-button"
                    style={{ right: '24px', top: '24px', cursor: 'pointer', position: 'fixed' }}
                    onClick={dismissDialog}
                />
                <img src={resourceIllustration} />
                <div className="group-dialog-content-container">
                    <h2
                        style={{
                            whiteSpace: 'pre-line',
                            marginBottom: 32,
                            lineHeight: '24px'
                        }}
                    >
                        {type === 'create' ? dictionary.create_new_group : dictionary.edit_group}
                    </h2>
                    <div className="group-inputs" style={{ marginTop: '16px', marginBottom: 16 }}>
                        <label style={{ marginBottom: 8 }}>
                            {dictionary.group_name}
                        </label>
                        <input type="text" maxLength='255' value={groupNameValue} onChange={handleTitleChange} />
                    </div>
                    <div className="group-inputs" style={{ marginTop: '16px', marginBottom: 16 }}>
                        <label style={{ marginBottom: -5 }}>
                            {dictionary.description}
                        </label>
                        <textarea rows="4" cols="50" maxLength='255' onChange={handleDescriptionChange} defaultValue={groupDescriptionValue} />
                    </div>
                    {type === 'edit' && groupExperiences.length > 0 ? (
                        <div className="group-inputs" style={{ marginTop: '16px', marginBottom: 16 }}>
                            <label style={{ marginBottom: 8 }}>
                                {dictionary.experiences}
                            </label>
                            <div className='experiences-list-wrapper'>
                                {groupExperiences.map((experience, index) => (
                                    <div key={index} className='group-experience'>
                                        <img key={index}
                                            className="experience-thumb"
                                            onError={(e) => {
                                                e.currentTarget.src = placeholder;
                                            }}
                                            src={
                                                experience.thumbnail_url
                                                    ? ImageHelper.getFullImageUrl(experience.thumbnail_url)
                                                    : placeholder
                                            }
                                        />
                                        <span className='group-experience-title'>{experience.title}</span>
                                        <div className='group-experience-actions'>
                                            <button onClick={(event) => handleExperienceClick(experience, event)} >⋮</button>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div ref={ref} className="group-action-menus-container" style={{ position: 'absolute', top: menuPosition.top, left: menuPosition.left }}>
                                {isComponentVisible ? (
                                    <>
                                        <div className="group-actions-container">
                                            <div onClick={() => removeExperience(selectedExperience.id)}
                                                className="group-action-container"
                                            >
                                                <div>{dictionary.remove}</div>
                                            </div>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>
                    ) : null}
                </div>
            </div>

            <FormLoader
                onDismissClicked={dismissDialog}
                showMessage={showLoaderMessage}
                message={loaderMessage}
                rounded={true}
                showLoader={showLoader}
                showEnter={false}
            />

            <div className="group-dialog-button-container">
                <button
                    disabled={!enableCreating}
                    onClick={onFinishClicked}
                    className={'button'}
                    data-testid="group-dialog-finish-button"
                >
                    {dictionary.save}
                </button>
            </div>
        </div>
    );
};

GroupInfo.propTypes = {
    type: PropTypes.string,
    showDialog: PropTypes.bool,
    setShowDialog: PropTypes.func,
    groupSaveEvent: PropTypes.func,
    editId: PropTypes.string,
};

export default connect(common.states, common.actions)(GroupInfo);
