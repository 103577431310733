import React, { useEffect, useState } from 'react';
// import LoginForm from '../forms/Loginform';
import { connect } from 'react-redux';
import * as auth from '../__redux/authRedux';
// import { CryptoHelper } from '../../../../utilities/helpers/CryptoHelper.js';
import { getPK } from '../__redux/authCrud';
import '././../../../../assets/css/login.css';
import '././../../../../assets/css/form_elements.css';
import { useParams } from 'react-router-dom';
// import ResetPasswordForm from '../forms/ResetPasswordForm';
import ResendActivationForm from '../forms/ResendActivationForm';

const ResetPassword = () => {
	const { hash } = useParams();
	const [sessionId, setSessionId] = useState(hash);
	// eslint-disable-next-line no-unused-vars
	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		getPK(sessionId).then((response) => {
			setMetadata(response.data.metadata);
		});
	}, []);

	return (
		<div className="main-container">
			<ResendActivationForm />
		</div>
	);
};

export default connect(
	({ auth }) => ({ errors: auth.errors, account_type: auth.account_type }),
	auth.actions
)(ResetPassword);
