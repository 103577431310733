import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import { useBookingStatuses } from '../../../../../utilities/hooks/useBookingStatuses';
import PropTypes from 'prop-types';
import {DateFormatHelper} from "../../../../../utilities/helpers/DateFormatHelper";
import PaymentStatusListItem from "../../../common/status-label/PaymentStatusListItem";

const ResaleBookingItem = ({ resaleBookingItem }) => {
	const bookingStatuses = useBookingStatuses();
	const currencyDecorator = UseCurrencyDecorator();
	const getBookingStatus = (status) => {
		return (
			<div className={'booking-status ' + bookingStatuses[status].class}>
				{bookingStatuses[status].text}
			</div>
		);
	};

	return (
		<tr>
			<td>
				<div className="booking-number">{resaleBookingItem.display_id}</div>
			</td>
			<td>
				{DateFormatHelper.monthTimeFormat(resaleBookingItem.created_at)}
			</td>
			<td>{getBookingStatus(resaleBookingItem.status.value)}</td>
			<td>
				<div className="booking-customer-container-no-font">
					<img
						className="booking-customer-avatar"
						src={ImageHelper.getFullImageUrl(resaleBookingItem.provider_logo)}
					/>
					<div>{resaleBookingItem.provider}</div>
				</div>
			</td>
			<td>
				<div className="experience-table-row-container">
					<div className="experience-table-row-image-container">
						<img
							className="experience-table-row-image-item"
							src={ImageHelper.getFullImageUrl(
								resaleBookingItem.experience_thumbnail
							)}
						/>
					</div>
					<div className="experience-table-row-title-container">
						{resaleBookingItem.experience_title}
					</div>
				</div>
			</td>
			<td>
				{DateFormatHelper.monthTimeFormat(resaleBookingItem.slot_start_time)}
			</td>
			<td>
				{resaleBookingItem?.group?.title}
			</td>
			<td>
				<div className="booking-customer-container-no-font">
					<img
						className="booking-customer-avatar"
						src={resaleBookingItem.customer_avatar}
					/>
					<div>{resaleBookingItem.customer_name}</div>
				</div>
			</td>
			<td>
				<PaymentStatusListItem status={resaleBookingItem?.payment_status.value} />
			</td>
			<td>
				<div className="booking-price">
					{currencyDecorator(resaleBookingItem.amount)}
				</div>
			</td>
			<td>
				<div className="booking-price">
					{currencyDecorator(resaleBookingItem.reseller_amount)}
				</div>
			</td>
			<td>
				<div className="bookings-actions-wrapper"></div>
			</td>
		</tr>
	);
};

ResaleBookingItem.propTypes = {
	resaleBookingItem: PropTypes.object
};

export default ResaleBookingItem;
