import React, { useCallback, useEffect, useRef } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import { useDropzone } from 'react-dropzone';
import Switch from '../../../../common/Switch';
import PropTypes from 'prop-types';

const AddonNameAndDetails = ({
	name,
	setName,
	description,
	setDescription,
	fileToUpload,
	setFileToUpload,
	image,
	// setImage,
	setEnabled,
	isGroupAddon,
	setGroupAddon,
	supplier,
	setSupplier
}) => {
	const dictionary = useDictionary();
	const inputRef = useRef();

	useEffect(() => {
		setEnabled(name !== '' && description !== '');
	}, [name, description]);

	useEffect(() => {
		return URL.revokeObjectURL(fileToUpload?.preview);
	}, []);
	const onDrop = useCallback((acceptedFiles) => {
		setFileToUpload(
			Object.assign(acceptedFiles[0], {
				preview: URL.createObjectURL(acceptedFiles[0])
			})
		);
	}, []);

	const onNameChanged = (e) => {
		setName(e.target.value);
	};

	const onDescriptionChanged = (e) => {
		setDescription(e.target.value);
	};

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		accept: ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4'],
		onDrop
	});

	const getPhoto = () => {
		if (fileToUpload !== null) {
			return fileToUpload.preview;
		} else if (image !== null) {
			return ImageHelper.getFullImageUrl(image);
		} else {
			return null;
		}
	};

	return (
		<div
			className="new-experience-form-wrapper "
			style={{ background: 'white', flexFlow: 'column' }}
		>
			<div
				className="new-experience-form-container thin-scrollbar"
				style={{ height: '0', flexGrow: '1' }}
			>
				<h2 style={{ fontSize: '24px' }}>{dictionary.name_addon}</h2>
				<div style={{ marginTop: '20px' }} className="text">
					{dictionary.name}
				</div>
				<div className="group-inputs">
					<input type="text" value={name} onChange={onNameChanged} />
				</div>
				<div style={{ marginTop: '20px' }} className="text">
					{dictionary.description}
				</div>
				<textarea
					value={description}
					onChange={onDescriptionChanged}
					placeholder={dictionary.describe_addon}
				/>

				<div style={{ display: 'flex', width: '100%' }}>
					<div style={{ width: '0', flexGrow: '1' }}>
						<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
							{dictionary.group_addon}
						</h3>
						<div style={{ marginTop: '8px', opacity: '0.8' }} className="text">
							{dictionary.current_multiple_participants}
						</div>
					</div>
					<Switch
						value={isGroupAddon}
						onChange={(e) => setGroupAddon(e.target.checked)}
					/>
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '32px' }}>
					{dictionary.add_photo}
				</h3>
				<div style={{ marginTop: '8px', opacity: '0.8' }} className="text">
					{dictionary.add_on_closeup_photo}
				</div>

				<div
					className="upload-media-wrapper"
					style={{ width: 'unset', marginTop: '16px' }}
					{...getRootProps()}
				>
					<input
						multiple={false}
						ref={inputRef}
						{...getInputProps()}
						className="gallery-upload-input"
					/>
					<div className="upload-media-container">
						<div className="upload-gallery-icon" />
						<h3 style={{ fontSize: '18px', marginTop: '25px' }}>
							{dictionary.drag_photo_here}
						</h3>
						<div style={{ marginTop: '10px' }} className="text">
							{dictionary.add_one_photo}
						</div>
						<div style={{ marginTop: '25px' }} className="secondary-button">
							{dictionary.upload_from_device}
						</div>
					</div>
					{getPhoto() ? (
						<img src={getPhoto()} className="uploaded-image-full" />
					) : null}
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '32px' }}>
					{dictionary.supplier}
				</h3>
				<div style={{ marginTop: '8px', opacity: '0.8' }} className="text">
					{dictionary.use_external_supplier}
				</div>
				<div className="group-inputs">
					<input
						type="text"
						value={supplier}
						onChange={(e) => setSupplier(e.target.value)}
					/>
				</div>
			</div>
		</div>
	);
};

AddonNameAndDetails.propTypes = {
	name: PropTypes.string,
	setName: PropTypes.func,
	description: PropTypes.string,
	setDescription: PropTypes.func,
	fileToUpload: PropTypes.object,
	setFileToUpload: PropTypes.func,
	image: PropTypes.object,
	setImage: PropTypes.func,
	setEnabled: PropTypes.func,
	isGroupAddon: PropTypes.bool,
	setGroupAddon: PropTypes.func,
	supplier: PropTypes.string,
	setSupplier: PropTypes.func
};

export default AddonNameAndDetails;
