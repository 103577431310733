import logo from '../../../assets/images/logo_white_letter.svg';
import ImageHelper from '../../../utilities/helpers/ImageHelper';
import { detectMobile2 } from '../../../utilities/helpers/MobileHelper';
import { TextFormatHelper } from '../../../utilities/helpers/TextFormatHelper';
import { useAccount } from '../../../utilities/hooks/UseAccount';
import { useCookies } from '../../../utilities/hooks/UseCookie';
import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import { useUser } from '../../../utilities/hooks/UseUser';
import useComponentVisible from '../../../utilities/hooks/useComponentVisible';
import { getProviderInfo, stripeLogin } from '../components/provider_home/networking';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

const ProfileWidget = () => {
	const dictionary = useDictionary();
	const user = useUser();
	const account = useAccount();
	const [companyPhoto, setCompanyPhoto] = useState(account?.company_photo);
	const companyPhotoPath = localStorage.getItem('company_photo_path');

	const history = useHistory();
	const [_, __, deleteCookies] = useCookies();

	const isMobile = detectMobile2();
	const source = axios.CancelToken.source();

	const onLogoutClicked = () => {
		history.push('/logout');
		deleteCookies();
	};

	const onSettingsCLicked = () => {
		history.push('/settings');
	};

	const onPayoutsClicked = async () => {
		history.push('/home/payouts');
		// let response = await stripeLogin();
		// let stripeLoginUrl = response.data.url;
		// window.open(stripeLoginUrl, '_blank');
	};

	const onTermsClicked = () => {
		history.push('/settings/2');
	};

	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	useEffect(() => {
		if (!companyPhoto) {
			getUserInfoAsync();
		}
	}, []);

	const getUserInfoAsync = async () => {
		try {
			let { data } = await getProviderInfo(source.token);
			setCompanyPhoto(data?.company_photo);
			localStorage.setItem('company_photo_path', data?.company_photo?.path);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div
			ref={ref}
			className="profile-widget-wrapper"
			onClick={() => setIsComponentVisible(!isComponentVisible)}
		>
			{!isMobile ? (
				<>
					{companyPhotoPath ? (
						<img
							onError={(e) => {
								e.currentTarget.src = logo;
							}}
							src={ImageHelper.getFullImageUrl(companyPhotoPath)}
							className="profile-widget-logo"
						/>
					) : (
						<div className="profile-widget-initials">
							<div className="profile-widget-initials-text">
								{TextFormatHelper.getInitials(
									account?.first_name,
									account?.last_name
								)}
							</div>
						</div>
					)}
					<div className="caret-down" style={{ opacity: '0.6' }} />
				</>
			) : null}
			{isComponentVisible || isMobile ? (
				<div className="profile-widget-info-container">
					<div className="profile-widget-top-row">
						{companyPhotoPath ? (
							<img
								onError={(e) => {
									e.currentTarget.src = logo;
								}}
								src={ImageHelper.getFullImageUrl(companyPhotoPath)}
								className="profile-widget-logo"
							/>
						) : (
							<div className="profile-widget-initials">
								<div className="profile-widget-initials-text">
									{TextFormatHelper.getInitials(
										account?.first_name,
										account?.last_name
									)}
								</div>
							</div>
						)}
						<div
							className="profile-widget-top-row-info-container"
							style={{ overflow: 'hidden' }}
						>
							<h4
								title={user?.first_name + ' ' + user?.last_name}
								className="ellipsize ellipsize-1"
								style={{
									fontWeight: 'normal',
									display: 'unset',
									whiteSpace: 'nowrap'
								}}
							>
								{user?.first_name + ' ' + user?.last_name}
							</h4>
							{/* <h4 title={account?.email} className='ellipsize ellipsize-1' style={{display:'unset', whiteSpace:'nowrap'}}>{account?.email}</h4> */}
							<h4>{account?.brand_name}</h4>
						</div>
					</div>
					<div className="profile-widget-divider" />
					<div onClick={onSettingsCLicked} className="drop-down-item">
						{dictionary.my_settings}
					</div>
					<div onClick={onPayoutsClicked} className="drop-down-item">
						{dictionary.payouts}
					</div>
					{/*    <div className='drop-down-item'>
                    {dictionary.upiria_support}
                </div>*/}
					<div onClick={onTermsClicked} className="drop-down-item">
						{dictionary.terms_and_policies}
					</div>
					<div className="profile-widget-divider" />
					<a onClick={onLogoutClicked} className="drop-down-item">
						{dictionary.log_out}
					</a>
				</div>
			) : null}
		</div>
	);
};

export default ProfileWidget;
