import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import CustomCheckbox from '../../../../common/CustomCheckbox';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const ParticipantRequirements = ({
	setEnabled,
	minimumAge,
	setMinimumAge,
	childFriendly,
	setChildFriendly,
	childFriendlyReason,
	setChildFriendlyReason,
	accessible,
	setAccessible,
	accessibleReason,
	setAccessibleReason
}) => {
	const dictionary = useDictionary();

	const onAgeChanged = (e) => {
		setMinimumAge(e.target.value);
	};

	useEffect(() => {
		if (!accessible && !childFriendly && minimumAge !== '') {
			setEnabled(true);
		} else if (accessible && accessibleReason === '') {
			setEnabled(false);
		} else if (childFriendly && childFriendlyReason === '') {
			setEnabled(false);
		} else {
			setEnabled(minimumAge !== '');
		}
	}, [
		minimumAge,
		accessible,
		accessibleReason,
		childFriendly,
		childFriendlyReason
	]);

	const onTextChanged = (e, type) => {
		if (type === 'child') {
			setChildFriendlyReason(e.target.value);
		} else {
			setAccessibleReason(e.target.value);
		}
	};

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2>{dictionary.who_can_attend}</h2>
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.keep_in_mind}
				</div>
				<div style={{ marginTop: '30px' }} className="group-inputs">
					<label>{dictionary.minimum_age}</label>
					<input
						value={minimumAge}
						min="0"
						step="1"
						onKeyDown={(e) =>
							['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
						}
						onChange={onAgeChanged}
						type="number"
						data-testid="minimum-age-input"
					/>
				</div>
				<div
					style={{ marginTop: '0px', fontSize: '12px', marginBottom: '12px' }}
					className="text"
				>
					{dictionary.set_age_limits}
				</div>

				<CustomCheckbox
					label={dictionary.child_friendly}
					checked={childFriendly}
					setChecked={setChildFriendly}
				/>

				{childFriendly ? (
					<>
						<textarea
							style={{ marginBottom: '20px' }}
							value={childFriendlyReason}
							onChange={(e) => onTextChanged(e, 'child')}
							placeholder={dictionary.explain_child_friendly}
							data-testid="child-friendly-reason"
						/>
					</>
				) : null}

				<CustomCheckbox
					label={dictionary.accessible}
					setChecked={setAccessible}
					checked={accessible}
				/>

				{accessible ? (
					<>
						<textarea
							style={{ marginBottom: '20px' }}
							value={accessibleReason}
							onChange={(e) => onTextChanged(e, 'accessible')}
							placeholder={dictionary.explain_accessible}
							data-testid="accessible-reason"
						/>
					</>
				) : null}
			</div>
		</div>
	);
};

ParticipantRequirements.propTypes = {
	setEnabled: PropTypes.func.isRequired,
	minimumAge: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
		.isRequired,
	setMinimumAge: PropTypes.func.isRequired,
	childFriendly: PropTypes.bool.isRequired,
	setChildFriendly: PropTypes.func.isRequired,
	childFriendlyReason: PropTypes.string.isRequired,
	setChildFriendlyReason: PropTypes.func.isRequired,
	accessible: PropTypes.bool.isRequired,
	setAccessible: PropTypes.func.isRequired,
	accessibleReason: PropTypes.string.isRequired,
	setAccessibleReason: PropTypes.func.isRequired
};

export default ParticipantRequirements;
