import React from 'react';
import Header from './containers/common/Header';
import Navigation from './containers/common/navigation/Navigation';
import { BrowserRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as common from './containers/common/__redux/commonRedux';
import PropTypes from 'prop-types';

const Layout = (props) => {
	return (
		<BrowserRouter>
			<div className="main-container" style={{ boxSizing: 'border-box' }}>
				<div className="content-container">
					{<Navigation />}
					<div className="main-content-container">
						{<Header />}
						<div className="page-route-container thin-scrollbar">{props.children}</div>
					</div>
				</div>
			</div>
		</BrowserRouter>
	);
};

Layout.propTypes = {
	children: PropTypes.node
};

export default connect(common.states, common.actions)(Layout);
