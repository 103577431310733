import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { Link, NavLink } from 'react-router-dom';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import { stripeLogin } from '../networking';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tooltip from '../../../common/Tooltip';

const PayoutsOverview = ({ result, setShowLoader, providerInfo }) => {
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();
	const [verificationStatus, setVerificationStatus] = useState(null);
	const isUserVerified = verificationStatus === '3';
	const history = useHistory();
	
	useEffect(() => {
		if (providerInfo) {
			setVerificationStatus(providerInfo?.verification_status?.value);
		}
	}, [providerInfo]);

	// if (!providerInfo) {
	// 	return null;
	// }

	const onDetailsClicked = async () => {
		history.push('/home/payouts');
	};

	return (
		<>
			<div className="payouts-title-row">
				<h2 data-testid="payouts-header">{dictionary.payouts}</h2>
				{isUserVerified && (
					<div
						style={{ cursor: 'pointer' }}
						className="see-details"
						data-testid="see-details"
						onClick={onDetailsClicked}
					>
						{dictionary.see_details}
					</div>
				)}
			</div>
			<div className="payout-container" data-testid="pending-payouts">
				<div className='payout-text-area'>
					<span className='payout-text'>{dictionary.pending}</span>
					<div className='text-with-info provider-payout-info'> <Tooltip content={dictionary.upcoming_earnings} />
					</div>
				</div>
				<h3>{result ? currencyDecorator(result?.pending_balance) : ''}</h3>
				{/*<h4 style={{fontSize:'12px', opacity:'0.6'}}>{dictionary.estimated_upcoming} {currencyDecorator(0)}</h4>*/}
			</div>

			<div className="payout-container" data-testid="available-payouts">
				<div className='payout-text-area'>
					<span className='payout-text'>{dictionary.available}</span>
					<div className='text-with-info provider-payout-info'> <Tooltip content={dictionary.ready_to_withdraw} />
					</div>
				</div>
				<h3>{result ? currencyDecorator(result?.available_balance) : ''}</h3>
				{/*<h4 style={{fontSize:'12px', opacity:'0.6'}}>{dictionary.last_payment} {currencyDecorator(0)}</h4>*/}
			</div>
		</>
	);
};

PayoutsOverview.propTypes = {
	result: PropTypes.object,
	setShowLoader: PropTypes.func,
	providerInfo: PropTypes.object
};

export default PayoutsOverview;
