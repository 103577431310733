import placeholder from '../../../../../assets/images/bg_placeholder_square.svg';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import React from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import moment from 'moment';
import PropTypes from 'prop-types';

const UpcomingAgendaItem = ({ item, onAgendaItemClicked }) => {
	const dictionary = useDictionary();

	const isResource = Object.prototype.hasOwnProperty.call(item, 'experiences');

	const formatDate = () => {
		let formatted = '';
		let date = moment();
		let start = moment(item.start_time * 1000);
		let end = moment((item.start_time + parseInt(item.duration)) * 1000);

		let dayDiff = date.dayOfYear() - start.dayOfYear();

		if (dayDiff === 0) {
			formatted = dictionary.today;
		} else if (dayDiff === 1) {
			formatted = dictionary.tomorrow;
		} else {
			formatted = start.format('DD/MM/YYYY');
		}

		formatted +=
			' ' +
			start.format('h:mm A') +
			(item.duration ? ' - ' + end.format('h:mm A') : '');

		return formatted;
	};

	const getParticipants = () => {
		if (isResource) {
			let sum = 0;
			item.bookings.map((booking) =>
				booking.participant_groups.map((group) => (sum += group.quantity))
			);

			return sum.toString();
		} else {
			return item?.booked + '/' + item?.capacity;
		}
		//return (item?.participant_groups.length > 0 ? item?.participant_groups?.reduce((x, y) => x + y.quantity, 0,) : '0') + '/' + item?.slot?.capacity
	};

	const getImage = () => {
		let imageUrl = '';
		let color = '';
		if (isResource) {
			imageUrl = item?.experiences[0]?.assets?.thumbnail_url;
			color = item?.experiences[0]?.color;
		} else {
			imageUrl = item?.experience?.assets[0]?.thumbnail_url;
			color = item.experience.color;
		}

		return (
			<div
				className="experience-thumbs-container"
				style={{ width: 'unset', height: 'unset', marginLeft: 'unset' }}
			>
				<img
					className="experience-thumb"
					style={{ height: '104px', width: '88px' }}
					onError={(e) => {
						e.currentTarget.src = placeholder;
						e.currentTarget.style.padding = '10px';
					}}
					src={imageUrl ? ImageHelper.getFullImageUrl(imageUrl) : null}
				/>
				<div
					className="experience-thumb-color-line"
					style={{ background: color }}
				/>
			</div>
		);
	};

	const getTitle = () => {
		if (isResource) {
			return item?.bookings?.map((item) => (
				<h4
					key={item?.experience?.id}
					style={{ fontWeight: 'normal', fontSize: '13px' }}
				>
					{item?.experience?.title}
				</h4>
			));
		} else {
			return (
				<h4 style={{ fontWeight: 'normal', fontSize: '13px' }}>
					{item?.experience?.title}
				</h4>
			);
		}
	};

	return (
		<div
			key={item.id}
			className="experience-item-full-container"
			onClick={() =>
				onAgendaItemClicked(item.id, isResource ? 'resource' : 'experience')
			}
			style={{
				gap: '16px',
				marginLeft: 'unset',
				marginRight: 'unset',
				cursor: 'pointer'
			}}
		>
			{getImage()}

			<div
				className="experience-item-basic-info-container"
				style={{ gap: '8px' }}
			>
				<h4>{formatDate()}</h4>
				{getTitle()}
			</div>

			<div
				className="experience-item-divider"
				style={{ marginLeft: 'unset', marginRight: 'unset' }}
			/>
			<div
				className="experience-item-basic-info-container"
				style={{ gap: '8px' }}
			>
				<div style={{ display: 'flex', gap: '8px' }}>
					<h4>{dictionary.participants}</h4>
					<h4 style={{ fontWeight: 'normal', opacity: '0.6' }}>
						{getParticipants()}
					</h4>
				</div>
				{item.booked <= 0 ? (
					<h4
						style={{
							color: 'rgba(34, 38, 76, 0.6)',
							fontWeight: 'normal'
						}}
						data-testid="no-bookings-yet"
					>
						{dictionary.no_bookings_for_slot_yet}
					</h4>
				) : null}
			</div>
		</div>
	);
};

UpcomingAgendaItem.propTypes = {
	item: PropTypes.object.isRequired,
	onAgendaItemClicked: PropTypes.func.isRequired
};

export default UpcomingAgendaItem;
