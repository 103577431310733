import React from 'react';
import '../../../assets/css/tooltip.css';
import PropTypes from 'prop-types';

const Tooltip = ({ content }) => {
	return (
		<div className="custom-tooltip-container">
			<div className="custom-tooltip-text">{content}</div>
			<div className="arrow"></div>
		</div>
	);
};

Tooltip.propTypes = {
	content: PropTypes.string.isRequired
};

export default Tooltip;
