import React from 'react';
import PropTypes from 'prop-types';

const ResellerItem = ({ provider }) => {
	return (
		<div
			key={provider.id}
			className="experience-row-container"
			style={{ marginBottom: '16px' }}
			data-testid="participant-item"
		>
			<div style={{ display: 'flex' }}>
				{<img className="participant-avatar" />}
				<div>
					<h4
						style={{ marginLeft: '8px' }}
					>
						{provider.brand_name}
					</h4>
					<h4
						style={{ fontWeight: 'normal', marginLeft: '8px', marginTop: '3px' }}
					>
						{provider.first_name + ' ' + provider.last_name}
					</h4>
				</div>
			</div>
		</div>
	);
};

ResellerItem.propTypes = {
	provider: PropTypes.object.isRequired
};

export default ResellerItem;
