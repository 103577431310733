import axios from 'axios';
import {
	ADMIN_BOKKINGS_URL,
	ADMIN_RESELLERS_URL,
	PROVIDERS_ADMIN_EXPERIENCES_URL,
	PROVIDERS_ADMIN_MARKETPLACE_EXPERIENCES_URL,
	PROVIDERS_EXPERIENCES_URL,
	PROVIDERS_URL,
	RESELLERS_URL
} from '../../../../../config';
import { DataTableHelper } from '../../../../../utilities/helpers/DataTableHelper';
import {AmountFormatHelper} from "../../../../../utilities/helpers/AmountFormatHelper";

export function getProviders(sortParam) {
	return axios.get(
		PROVIDERS_URL +
			DataTableHelper.prepareParameters(sortParam),
	);
}

export function changeProviderStatus(providerId, verify) {
	return axios.post(PROVIDERS_URL + '/' + providerId + '/verify', {
		verify: verify
	});
}

export function changeDocumentStatus(documentId, status) {
	return axios.put(PROVIDERS_URL + '/legal-files/' + documentId, {
		status: status
	});
}

export function getLegalFiles(documentId) {
	return axios.get(PROVIDERS_URL + '/legal-files/' + documentId, {
		responseType: 'blob'
	});
}

export function getProviderDetails(id) {
	return axios.get(PROVIDERS_URL + '/' + id);
}

export function getResellers(id) {
	return axios.get(ADMIN_RESELLERS_URL + '/' + id);
}

export function getExperienceByProvider(providerId) {
	return axios.get(PROVIDERS_ADMIN_MARKETPLACE_EXPERIENCES_URL + '?provider_id=' + providerId + '&channel_id=2');
}

export function getMarketplaceExperienceByProvider(providerId) {
	return axios.get(PROVIDERS_ADMIN_MARKETPLACE_EXPERIENCES_URL + '?provider_id=' + providerId + '&channel_id=1');
}

export function getResaleCollectionByProvider(providerId) {
	return axios.get(PROVIDERS_ADMIN_MARKETPLACE_EXPERIENCES_URL + '?provider_id=' + providerId + '&channel_id=3');
}

export function updateCommission(providerId, commission) {
	return axios.put(`${PROVIDERS_URL}\\${providerId}\\upiria-commission`, {
		upiria_commission: AmountFormatHelper.deFormatAmount(commission)
	});
}

export const getProviderBookingList = (pId,pageSize,pageNumber) => {
	return axios.get(ADMIN_BOKKINGS_URL + `?start=${pageSize * pageNumber}&length=${pageSize}` + '&providers=' + pId)
}