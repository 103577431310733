import FinancialOverview from './finiancial_overview/FinancialOverview';
import '../../../../assets/css/home.css';
import ProviderOnBoardingActivities from './onboarding_activities/ProviderOnBoardingActivities';
import DocumentsOnBoardingDialog from './DocumentsOnBoardingDialog';
import React, { useEffect, useState } from 'react';
import SubmitDocumentsDialog from './submit_documents_dialog/SubmitDocumentsDialog';
import { getProviderInfo } from './networking';
import UpcomingBookings from './upcoming_bookings/UpcomingBookings';
import SimpleLoader from '../../common/SimpleLoader';
import SlotDetails from '../provider_calendar/slot_details/SlotDetails';
import { deleteSlot, getSlotDetails } from '../provider_calendar/networking';
import EditSlotTypeDialog from '../provider_calendar/EditSlotTypeDialog';
import ConfirmationModal from '../../common/ConfirmationModal';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import ScheduleSlotDialog from '../provider_calendar/schedule_slot_dialog/ScheduleSlotDialog';
import axios from 'axios';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useLocation } from "react-router-dom";

const ProviderHome = () => {
	const history= useHistory();
	const dictionary = useDictionary();
	const location = useLocation();
	const [showDocumentsOnBoarding, setShowDocumentsOnBoarding] = useState(false);
	const [showDocumentsUploadDialog, setShowDocumentsUploadDialog] =
		useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [config, setConfig] = useState(null);

	const [showSlotDetails, setSlotDetailsVisibility] = useState(false);
	const [slotDetails, setSlotDetails] = useState(null);

	const [scheduleMode, setScheduleMode] = useState('experience');

	const [showScheduleDialog, setShowScheduleDialog] = useState(false);
	const [showEditSlotTypeDialog, setEditSlotTypeDialogVisibility] =
		useState(false);
	const [editType, setEditType] = useState(null);
	const [editSlot, setEditSlot] = useState(null);

	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [isResource, setIsResource] = useState(false);

	const [reloadCounter, setReloadCounter] = useState(0);

	const source = axios.CancelToken.source();

	useEffect(() => {
		getProviderInfoAsync();

		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	const hideDocumentsOnBoarding = () => {
		localStorage.setItem('show-documents', 1);
		setShowDocumentsOnBoarding(false);
	};

	const getProviderInfoAsync = async () => {
		try {
			let { data } = await getProviderInfo(source.token);
			setConfig(data);
			let show = localStorage.getItem('show-documents') || 0;
			if (show === 0) {
				setShowDocumentsOnBoarding(!data?.documents_submitted);
				setShowLoader(false);
			}
			if (!data?.experience_created) {
				setShowLoader(false);
			}
		} catch (e) {
			setShowLoader(false);
		}
	};

	const onDocumentsSubmitted = () => {
		getProviderInfoAsync();
		setShowDocumentsUploadDialog(false);
	};

	// const getActivitiesVisibility = () => {
	// 	if (!config) {
	// 		return null;
	// 	} else {
	// 	}
	// };

	const getSlotAsync = async (slotId, type) => {
		setShowLoader(true);
		try {
			const { data } = await getSlotDetails(slotId, type);
			setEditSlot(data);
			setSlotDetails(data);
			setSlotDetailsVisibility(true);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const onAgendaItemClicked = (id, type) => {
		history.push(`/calendar/${id}?${type}`);
	};

	const dismissDetails = () => {
		setTimeout(() => {
			setSlotDetailsVisibility(false);
		}, 300);
	};

	const onEditSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(false);
		setEditSlotTypeDialogVisibility(true);
	};

	const showEditSlotDialogWithMode = (editType) => {
		setEditType(editType);
		if (isDelete) {
			setDeleteDialogVisibility(true);
		} else {
			setShowScheduleDialog(true);
		}
	};

	const deleteSlotAsync = async (onSuccess, onFail) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteSlot(editSlot.id, editType, isResource);
			onSuccess();
		} catch (error) {
			onFail();
		}
	};

	const onDeleteComplete = () => {
		setSlotDetailsVisibility(false);
		onScheduleSet();
	};

	const onScheduleSet = () => {
		if (showSlotDetails) {
			setSlotDetailsVisibility(false);
		}
		setReloadCounter(reloadCounter + 1);
	};

	const onNoResults = () => {
		setShowScheduleDialog(false);
	};

	const onDeleteSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(true);
		setEditSlotTypeDialogVisibility(true);
	};

	return (
		<div style={{ position: "relative", width: '100%' }}>
			<div className="home-container thin-scrollbar">
				{config ? (
					<ProviderOnBoardingActivities
						reloadCounter={reloadCounter}
						setShowLoader={setShowLoader}
						config={config}
						showDocumentsUploadDialog={setShowDocumentsUploadDialog}
						onAgendaItemClicked={onAgendaItemClicked}
						showSlotDetails={showSlotDetails}
						slotDetails={slotDetails}
						dismissDetails={dismissDetails}
					/>
				) : null}
				<div className="home-divider" />
				{/* <FinancialOverview providerInfo={config} /> */}
				{config ? (
					<>
						<FinancialOverview providerInfo={config} />
					</>
				) : null}{' '}
			</div>
			{showDocumentsOnBoarding && (
				<DocumentsOnBoardingDialog
					showUploadDialog={setShowDocumentsUploadDialog}
					hideDocumentsOnBoarding={hideDocumentsOnBoarding}
					setShowLoader={setShowLoader}
				/>
			)}
			{showDocumentsUploadDialog && (
				<SubmitDocumentsDialog
					onDocumentsSubmitted={onDocumentsSubmitted}
					showDialog={setShowDocumentsUploadDialog}
				/>
			)}

			{/* {showSlotDetails ? (
				<SlotDetails
					onDeleteSlotClicked={onDeleteSlotClicked}
					onEditSlotClicked={onEditSlotClicked}
					details={slotDetails}
					dismissDetails={dismissDetails}
				/>
			) : null} */}

			{showEditSlotTypeDialog ? (
				<EditSlotTypeDialog
					isDelete={isDelete}
					isResource={isResource}
					showEditSlotDialogWithMode={showEditSlotDialogWithMode}
					editSlot={editSlot}
					setEditSlotTypeDialogVisibility={setEditSlotTypeDialogVisibility}
				/>
			) : null}

			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => onDeleteComplete()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteSlotAsync}
					title={dictionary.delete_slot}
					message={dictionary.delete_slot_message}
					successMessage={dictionary.slot_deleted}
					errorMessage={dictionary.problem_deleting_slot}
					action={dictionary.delete}
				/>
			) : null}

			{showScheduleDialog ? (
				<ScheduleSlotDialog
					isResource={isResource}
					editType={editType}
					editSlot={editSlot}
					scheduleMode={scheduleMode}
					setShowScheduleDialog={setShowScheduleDialog}
					onScheduleSet={onScheduleSet}
					onNoResults={onNoResults}
				/>
			) : null}

			{showLoader ? <SimpleLoader showLoader={showLoader} /> : null}
		</div>
	);
};

export default ProviderHome;
