import pencil from '../../../../../assets/icons/ic_pencil.svg';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { DateFormatHelper } from '../../../../../utilities/helpers/DateFormatHelper';
import ConverterHelper from '../../../../../utilities/helpers/Converters';

const SlotInfo = ({ details, onEditSlotClicked, showActions }) => {
	const dictionary = useDictionary();
	const pricing = useMemo(() => {
		let result = {};
		details?.participant_groups?.map((group) => {
			result[group?.name] = +group?.rate / 100;
		});

		return result;
	}, [details]);

	return (
		<div
			className="booking-experience-details-container"
			style={{ borderRadius: '12px' }}
		>
			<div
				className="settings-tab-bar"
				style={{
					justifyContent: 'space-between',
					backgroundColor: 'white',
					alignItems: 'center',
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12
				}}
			>
				<div
					className="settings-tab"
					style={{ color: 'var(--brand-dark-blue)', cursor: 'initial' }}
				>
					{dictionary.details}
				</div>
				{showActions ? (
					<div
						className="tertiary-button"
						style={{ marginRight: '32px', gap: '6px' }}
						onClick={() => {
							onEditSlotClicked(
								Object.prototype.hasOwnProperty.call(details, 'experiences')
							);
						}}
						data-testid="edit-slot-button"
					>
						<img src={pencil} />
						{dictionary.edit}
					</div>
				) : null}
			</div>
			<div
				className="booking-experience-details-content-container thin-scrollbar"
				data-testid="booking-experience-details-container"
			>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.date}
					</h4>
					<h4
						style={{
							fontWeight: 'normal',
							width: '150px'
						}}
					>
						{DateFormatHelper.rangeDayMonthYearFormat(
							details?.start_time,
							details?.end_time
						)}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						marginTop: '24px'
					}}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.time}
					</h4>
					<h4
						style={{
							fontWeight: 'normal',
							width: '150px'
						}}
					>
						{DateFormatHelper.timeRangeFormat(
							details?.start_time,
							details?.end_time
						)}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						marginTop: '24px'
					}}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.duration}
					</h4>
					<h4
						style={{
							fontWeight: 'normal',
							width: '150px'
						}}
					>
						{ConverterHelper.secondsToDayHourMinute(
							details.duration,
							dictionary
						)}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						marginTop: '24px'
					}}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.repeat}
					</h4>
					<h4
						style={{
							fontWeight: 'normal',
							width: '150px'
						}}
					>
						{details?.is_one
							? dictionary.no_repeat
							: DateFormatHelper.slotRecurringFormat(
									details?.setting,
									dictionary
							  )}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{
						justifyContent: 'space-between',
						alignItems: 'flex-start',
						marginTop: '24px'
					}}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.access}
					</h4>
					<h4
						style={{
							fontWeight: 'normal',
							width: '150px'
						}}
					>
						{details.is_private_tour ? dictionary.private : dictionary.public}
					</h4>
				</div>
				{details?.capacity ? (
					<div
						className="participant-row-container"
						style={{
							justifyContent: 'space-between',
							alignItems: 'flex-start',
							marginTop: '24px'
						}}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.max_group_size}
						</h4>
						<h4 style={{ fontWeight: 'normal', width: '150px' }}>
							{details?.capacity}
						</h4>
					</div>
				) : null}
				{Object.entries(pricing).length > 0 && (
					<div
						className="participant-row-container"
						style={{
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							marginTop: '24px'
						}}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.pricing}
						</h4>
						<div style={{ fontWeight: 'normal', width: '150px' }}>
							{Object.entries(pricing).map(([key, value], index) => (
								<div
									key={index}
									style={{
										display: 'flex',
										marginBottom: 8
									}}
								>
									<h4 style={{ flex: 1, fontWeight: 400 }}>{key}</h4>
									<h4
										style={{ width: 40, textAlign: 'right', fontWeight: 400 }}
									>
										{value} €
									</h4>
								</div>
							))}
						</div>
					</div>
				)}
				{details.rates.length > 0 && (
					<div
						className="participant-row-container"
						style={{
							alignItems: 'flex-start',
							justifyContent: 'space-between',
							marginTop: '24px'
						}}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.group_rates}
						</h4>
						<div style={{ fontWeight: 'normal', width: '150px' }}>
							{details.rates.map((rate) => (
								<div key={rate.id} style={{ display: 'flex', marginBottom: 8 }}>
									<h4 style={{ flex: 1, fontWeight: 400 }}>
										{rate.min_size + '+' + ' ' + dictionary.participants}
									</h4>
									<h4
										style={{ width: 40, textAlign: 'right', fontWeight: 400 }}
									>
										-{rate.discount} %
									</h4>
									<div className="experience-info-title"></div>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

SlotInfo.propTypes = {
	details: PropTypes.object,
	onEditSlotClicked: PropTypes.func,
	showActions: PropTypes.bool
};

export default SlotInfo;
