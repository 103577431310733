import ic_bookings from '../../../../../../assets/icons/ic_bookings_blue.svg';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import SlotBookingItem from './SlotBookingItem';
import PropTypes from 'prop-types';
import React from 'react';

const SlotBookings = ({ details, onBookingClicked }) => {
	const dictionary = useDictionary();

	return (
		<div className="settings-page-container thin-scrollbar fade-on-load">
			<div className="slot-detail-title" style={{ fontWeight: 500 }}>
				{dictionary.bookings}
			</div>
			{details?.bookings?.length > 0 ? (
				<table style={{ marginTop: 16, marginLeft: -8, borderCollapse: "collapse" }}>
					<tbody>
					{details?.bookings?.map((booking) => (
						<SlotBookingItem
							key={booking.id}
							onBookingClicked={() => onBookingClicked(booking)}
							booking={booking}
						/>
					))}
					</tbody>
				</table>
			) : (
				<div className="empty-participants-wrapper">
					<div className="empty-participants">
						<div className="empty-participants-icon-wrapper">
							<img src={ic_bookings} />
						</div>
						<h5 className="empty-participants-title">
							{dictionary.no_bookings_yet}
						</h5>
						<p className="empty-participants-description">
							{dictionary.all_bookings_will_be_listed}
						</p>
					</div>
				</div>
			)}
		</div>
	);
};

SlotBookings.propTypes = {
	details: PropTypes.object,
	onBookingClicked: PropTypes.func
};

export default SlotBookings;
