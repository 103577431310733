// import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React from 'react';

const SlotParticipantItem = ({ booking, details, isResource }) => {
	// const dictionary = useDictionary();

	const getParticipants = () => {
		let groups = '';
		booking?.participant_groups.map((group) => {
			groups += group.quantity.toString() + ' ' + group.name + ', ';
		});

		groups = groups.substring(0, groups.length - 2);
		if (!isResource) {
			return groups;
		} else {
			let experience = details?.experiences?.find(
				(exerience) => exerience.id === booking?.experience?.id
			);

			if (experience) {
				return groups + ' (' + experience.title + ')';
			}
		}
	};

	return (
		<div
			className="participant-row-container"
			style={{ paddingTop: 4, paddingBottom: 4, minWidth: 360 }}
		>
			{<img className="participant-avatar" />}
			<h4
				style={{ fontWeight: 'normal', marginLeft: '8px', minWidth: '164px' }}
				data-testid="customer-name"
			>
				{booking?.customer_name}
			</h4>
			<h4
				style={{ fontWeight: 'normal', marginLeft: '8px', opacity: '0.6' }}
				data-testid="booking-participants"
			>
				{getParticipants()}
			</h4>
			<div className="flex-filler" />
			<h4
				style={{ fontWeight: 'bold', marginLeft: '8px' }}
				data-testid="booking-index"
			>
				{booking?.index}
			</h4>
			{/*<div className='flex-filler'/>
            <h4 style={{fontSize:'12px', cursor:'pointer'}}>{dictionary.edit}</h4>
            <h4 style={{marginLeft:'20px', fontSize:'12px', cursor:'pointer', color:'var(--brand-red)'}}>{dictionary.remove}</h4>*/}
		</div>
	);
};

SlotParticipantItem.propTypes = {
	booking: PropTypes.object,
	details: PropTypes.object,
	isResource: PropTypes.bool
};

export default SlotParticipantItem;
