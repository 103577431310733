import PropTypes from 'prop-types';
import placeholder from '../../../../../assets/images/bg_placeholder_square.svg';
import ImageHelper from "../../../../../utilities/helpers/ImageHelper";

/**
 * Function to display assets.
 *
 * Get viewType from local storage and change maxImages and css classes
 *
 * @param assets
 * @returns {JSX.Element}
 * @constructor
 */
const ResaleImageCollage = ({
    isResaleCollection,
    assets
}) => {
    const viewType = parseInt(localStorage.getItem(isResaleCollection ? 'resale_collection_selected_view_type' : 'resale_marketplace_selected_view_type') ?? 0);

    //Default for fullView
    let totalImages = 4;
    let imageItemCssClass = 'resale-collection-img-collage-item';
    let imageCollageCssClass = 'resale-collection-img-collage';

    //Card view
    if(viewType === 1){
       totalImages = 3;
       imageItemCssClass = 'resale-collection-card-item-experience-image-collage-item';
       imageCollageCssClass = 'resale-collection-card-item-experience-image-collage';
    }

    let imgElements = [];
    for (let index = 0; index < totalImages; index++){
        imgElements.push(
            <img key={(assets?.[index]) ? assets[index] : `asset-${index}`} className={`${imageItemCssClass} ${assets?.[index] ? '' : 'placeholder'}`}
                 src={(assets?.[index]) ? ImageHelper.getFullImageUrl(assets[index]) : placeholder}
                 onError={(e) => {
                     e.currentTarget.src = placeholder;
                     e.currentTarget.style.objectFit = 'cover';
                 }}
            />
        );
    }

    return (
        <div className={imageCollageCssClass}>
            {imgElements}
        </div>
    );
}

ResaleImageCollage.prototype = {
    assets: PropTypes.array.isRequired
};

export default ResaleImageCollage;
