import back from "../../../../../../assets/icons/ic_caret_left.svg";
import { useDictionary } from "../../../../../../utilities/hooks/UseDictionary";
import SimpleLoader from "../../../../common/SimpleLoader";
import * as common from "../../../../common/__redux/commonRedux";
import DropdownButton from "../../../../common/dropdown_button/DropdownButton";
import StatusLabel from "../../../../common/status-label/StatusLabel";
import { getBookingDetails } from "../networking";
import BookingExperienceDetails from "./BookingExperienceDetails";
import SendInvoiceDialog from "./SendInvoiceDialog";
import Invoice from "./tabs/invoice/Invoice";
import ParticipantsAndAddons from "./tabs/pariticipants_and_addons/ParticipantsAndAddons";
import Payments from "./tabs/payments/Payments";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { NavigatorPaths } from "../../../../../../assets/dictionaries/NavigatorPaths";
import { ReactComponent as CalendarIcon } from "../../../../../../assets/icons/ic_calendar.svg";
import { ReactComponent as EnvelopeIcon } from "../../../../../../assets/icons/ic_envelope.svg";
import { ReactComponent as CellPhoneIcon } from "../../../../../../assets/icons/ic_phone.svg";
import SuggestDateDialog from "../../../provider_home/booking_requests/SuggestDateDialog";
import RejectRequestDialog from "../../../provider_home/booking_requests/RejectRequestDialog";
import BookingNotes from "./tabs/booking_notes/BookingNotes";
import { updateBookingRequestState } from "../../../provider_home/networking";
import ConfirmRequestDialog from "../../../provider_home/booking_requests/ConfirmRequestDialog";
import ExperienceDetailDialog from "../../../resale_hub/components/ExperienceDetailDialog";
import CustomToast from '../../../../common/CustomToast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookingDetails = ({ setErrorMessage }) => {
	const { id } = useParams();
	const dictionary = useDictionary();
	const history = useHistory();

	const [tabIndex, setTabIndex] = useState(0);
	const [opacity, setOpacity] = useState(0);
	const [showLoader, setLoaderVisibility] = useState(true);
	const [showSendInvoiceDialog, , setShowSendInvoiceDialog] = useState(false);
	const [bookingStatus, setBookingStatus] = useState(null);
	const [paymentStatus, setPaymentStatus] = useState(null);
	const [moreStatus, setMoreStatus] = useState(null);
	const [details, setDetails] = useState(null);
	const [showSuggestDateDialog, setShowSuggestDateDialog] = useState(false);
	const [showConfirmRequestDialog, setShowConfirmRequestDialog] = useState(false);
	const [showRejectRequestDialog, setShowRejectRequestDialog] = useState(false);
	const [selectedViewUrl, setSelectedViewUrl] = useState(null);
	const [selectedViewUrlForBook, setSelectedViewUrlForBook] = useState(null);

	const isExpired = useMemo(() => moment.unix(details?.slot.start_time).valueOf() < new Date().valueOf(), [details])

	const isPhoneAble = useMemo(() => details?.customer?.phone, [details]);

	// const isMessageAble = useMemo(() => ((details?.status.key === "confirmed" || details?.status.key === "reschedule") && details.payment_status.key === "paid"), [details]);
	const isMessageAble = useMemo(() => ((details?.status.key === "confirmed" || details?.status.key === "reschedule") && details.payment_status.key === "paid") || (details?.status.key === "reschedule" && details.payment_status.key === "pending"), [details]);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 10);

		getDetailsByIdAsync();
	}, []);

	const getDetailsByIdAsync = async () => {
		setLoaderVisibility(true)
		try {
			let { data } = await getBookingDetails(id);
			setDetails(data);
			setBookingStatus(data.status.value);
			setPaymentStatus(data.payment_status.value);

			setLoaderVisibility(false);
		} catch (e) {
			setLoaderVisibility(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
				setTimeout(() => {
					setErrorMessage("");
					dismiss();
				}, 2000);
			}
		}
	};

	const routeMap = {
		0: <ParticipantsAndAddons details={details} />,
		1: <Invoice details={details} />,
		2: <Payments details={details} />,
		3: <BookingNotes details={details} />
	};

	const moreOptions = {
		1: {
			text: dictionary.message_customer,
			icon: <EnvelopeIcon />,
			disabled: !isMessageAble
		},
		2: {
			text: dictionary.call_customer,
			icon: <CellPhoneIcon />,
			disabled: !isPhoneAble,
			type: 'call',
			phone: details?.customer.phone,
		}
	};

	const dismiss = () => {
		// setOpacity(0);
		// history.goBack();
		history.push("/admin/bookings");
	};

	const handleOpenDetailDialog = (viewUrl) => {
		setSelectedViewUrlForBook(null);
		setSelectedViewUrl(viewUrl);
	};
	
	const handleCloseDetailDialog = () => {
		setSelectedViewUrl(null);
	};
	const handleOpenBookingDialog = (viewUrl) => {
		setSelectedViewUrl(null);
		setSelectedViewUrlForBook(viewUrl);
	};
	const handleCloseBookingDetailDialog = () => {
		setSelectedViewUrlForBook(null);
	};

	useEffect(() => {
		if (moreStatus === dictionary.reschedule) {
			handleOpenSuggestDateDialog();
		}
		if (moreStatus === dictionary.message_customer) {
			window.location.href = "mailto:" + details.customer.email;
		}
	}, [moreStatus]);

	const handleOpenSuggestDateDialog = () => {
		setShowSuggestDateDialog(true);
	};

	const handleUpdateRequestStatus = async (data) => {
		try {
			setLoaderVisibility(true);
			const response = await updateBookingRequestState(id, data);
			await getDetailsByIdAsync();
			setLoaderVisibility(false);
		} catch (error) {
			setLoaderVisibility(false);
			if (error?.response?.data?.message) {
				toast.error(<CustomToast message={error.response.data.message} />,
				{
					position: 'bottom-left',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				});
			}
		}
	};

	const handleClickSeeCalendar = () => {
		history.push(`/${NavigatorPaths.calendar.path}`, details)
	}

	return (
		<div
			className="calendar-container"
			style={{
				// position: 'relative', commented this for making Experience dialoge work well, if any issue need to discuss
				background: 'var(--background-color)',
				paddingTop: '0'
			}}
		>
			<div className="booking-details-wrapper" style={{ opacity: opacity }}>
				<div className={"booking-header-container booking-header-container-details admin-booking-header-container"}>
					<div className="booking-header-title">
						<div className="booking-detail-back-button" onClick={() => dismiss()}>
							<img src={back} />
						</div>
						<h2 style={{ marginLeft: "8px", fontWeight: 500 }} data-testid="booking-details-header">
							{dictionary.booking + " " + (details?.display_id ?? "")}
						</h2>
					</div>

					<div className="booking-status-labels-container">
						<StatusLabel type="booking" status={bookingStatus}/>
						<StatusLabel type="payment" status={paymentStatus}/>
						{bookingStatus === 'pending' && !isExpired &&
							<>
								<button className="button secondary-button button--small reject-button" onClick={handleClickSeeCalendar}>
									<CalendarIcon />
									{dictionary.see_calendar}
								</button>
							</>
						}
						{/*{(bookingStatus === 'pending' || bookingStatus === 'confirmed') &&*/}
						{/*	<>*/}
						{/*		<button*/}
						{/*			className="button secondary-button button--small reject-button"*/}
						{/*			onClick={handleOpenSuggestDateDialog}*/}
						{/*		>*/}
						{/*			<AddUserIcon/>*/}
						{/*			{dictionary.add_participant}*/}
						{/*		</button>*/}
						{/*		<button*/}
						{/*			className="button secondary-button button--small reject-button"*/}
						{/*			onClick={handleOpenSuggestDateDialog}*/}
						{/*		>*/}
						{/*			<AddOnIcon/>*/}
						{/*			{dictionary.add_addon}*/}
						{/*		</button>*/}
						{/*	</>*/}
						{/*}*/}
						{/*{bookingStatus === "confirmed" && (*/}
						{/*	<button className="button secondary-button button--small reject-button">*/}
						{/*		<EnvelopeIcon />*/}
						{/*		{dictionary.send_invoice}*/}
						{/*	</button>*/}
						{/*)}*/}

						{(bookingStatus === "confirmed" || bookingStatus === "reschedule") && (
							<DropdownButton
								label={dictionary.more}
								options={moreOptions}
								option={moreStatus}
								setOption={setMoreStatus}
							/>
						)}
					</div>
				</div>

				<div className="booking-details-body">
					<div className="booking-details-container">
						<div className="settings-tab-bar">
							<div
								onClick={() => setTabIndex(0)}
								className={tabIndex === 0 ? "settings-tab settings-tab-active" : "settings-tab"}
								data-testid="participants-and-addons-tab"
							>
								{dictionary.participants}
							</div>
							{(paymentStatus === "paid" || paymentStatus === "refunded") && (
								<div
									onClick={() => setTabIndex(1)}
									className={
										tabIndex === 1
											? 'settings-tab settings-tab-active'
											: 'settings-tab'
									}
									data-testid="invoice-tab"
								>
									{dictionary.invoice}
								</div>
							)}
							{(paymentStatus === "paid" || paymentStatus === "refunded" || paymentStatus === "partial_refunded") && (
								<div
									onClick={() => setTabIndex(2)}
									className={
										tabIndex === 2
											? 'settings-tab settings-tab-active'
											: 'settings-tab'
									}
									data-testid="payments-tab"
								>
									{dictionary.payments}
								</div>
							)}
							{!!details?.notes && (
								<div
									onClick={() => setTabIndex(3)}
									className={tabIndex === 3 ? "settings-tab settings-tab-active" : "settings-tab"}
									data-testid="booking-note-tab"
								>
									{dictionary.booking_notes}
								</div>
							)}
						</div>
						{details ? routeMap[tabIndex] : null}
					</div>
					{details ? <BookingExperienceDetails details={details} viewExperienceHandler={handleOpenDetailDialog} viewBookingHandler={handleOpenBookingDialog}/> : null}
				</div>
				{showSendInvoiceDialog && <SendInvoiceDialog />}
				{showConfirmRequestDialog && (
					<ConfirmRequestDialog
						detail={details}
						onConfirm={handleUpdateRequestStatus}
						setShowDialog={setShowConfirmRequestDialog}
					/>
				)}
				{showSuggestDateDialog && (
					<SuggestDateDialog
						isConfirmed={bookingStatus === "confirmed"}
						request={details}
						onSuggest={handleUpdateRequestStatus}
						setShowDialog={setShowSuggestDateDialog}
					/>
				)}
				{showRejectRequestDialog && (
					<RejectRequestDialog
						onReject={handleUpdateRequestStatus}
						setShowDialog={setShowRejectRequestDialog}
					/>
				)}
				<ExperienceDetailDialog
				viewUrl={selectedViewUrl}
				showDialog={Boolean(selectedViewUrl)}
				setShowDialog={handleCloseDetailDialog}
			/>
			<ExperienceDetailDialog
				viewUrl={selectedViewUrlForBook}
				showDialog={Boolean(selectedViewUrlForBook)}
				setShowDialog={handleCloseBookingDetailDialog}
			/>
			<SimpleLoader showLoader={showLoader} />
			<ToastContainer />
			</div>
		</div>

	);
};

BookingDetails.propTypes = {
	// bookingId: PropTypes.string.isRequired,
	// dismissDetails: PropTypes.func.isRequired,
	setErrorMessage: PropTypes.func.isRequired
};

export default connect(common.states, common.actions)(BookingDetails);
