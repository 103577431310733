import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import CustomCheckbox from '../../../../common/CustomCheckbox';
import Tooltip from '../../../../common/Tooltip';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import {useAccount} from "../../../../../../utilities/hooks/UseAccount";

const CancellationPolicy = ({
	setEnabled,
	cancellationPolicyData,
	setCancellationPolicyData,
	policyTitle,
	setPolicyTitle,
	upiriaCancellationPolicy,
	setUpiriaCancellationPolicy,
	policyDescription,
	setPolicyDescription,
	rememberPolicy,
	setRememberPolicy,
	noRefund,
	setNoRefund
}) => {
	const [policy, setPolicy] = useState([]);

	const dictionary = useDictionary();
	const account = useAccount();
	const isVerified = account.verification_status.value === '3';

	const onRadioChanged = (e) => {
		if (e.target.value === 'no') {
			setUpiriaCancellationPolicy(true);
		} else {
			setUpiriaCancellationPolicy(false);
		}
	};

	const onRefundRadioChanged = (e) => {
		if (e.target.value === 'no') {
			setNoRefund(true);
		} else {
			setNoRefund(false);
		}
	};

	const checkPolicy = () => {
		if (upiriaCancellationPolicy) {
			setEnabled(true);
		} else {
			if (!policyTitle.trim() || !policyDescription.trim()) {
				setEnabled(false);
			} else {
				setEnabled(true);
			}
		}
	};

	const onTextChanged = (e) => {
		if (e.target.id === 'title') {
			setPolicyTitle(e.target.value);
		} else {
			setPolicyDescription(e.target.value);
		}
	};

	const onDayChange = (e, index) => {
		const name = e.target.name;
		const value = e.target.value;
		if (name === 'firstDay') {
			if (
				value >= 1 &&
				(value < policy[index].to || policy.length - 1 === index)
			) {
				setCancellationPolicyData(
					cancellationPolicyData.map((data, idx) => ({
						...data,
						to: idx === index - 1 ? -value + ' Day' : data.to,
						from: idx === index ? -value + ' Day' : data.from
					}))
				);
			}
		}

		if (name === 'lastDay') {
			if (
				value > policy[index].from &&
				value < 31 &&
				(value < policy[index + 1].to || policy.length - 2 === index)
			) {
				setCancellationPolicyData(
					cancellationPolicyData.map((data, idx) => ({
						...data,
						to: idx === index ? -value + ' Day' : data.to,
						from: idx === index + 1 ? -value + ' Day' : data.from
					}))
				);
			}
		}
	};

	const onPercentChange = (e, index) => {
		const value = e.target.value;
		const isLast = index === policy.length - 1;
		if (
			(index === 0 &&
				value > 0 &&
				value <= policy[index + 1]?.refund_percentage - 1) ||
			(!isLast &&
				value > policy[index - 1]?.refund_percentage &&
				value <= policy[index + 1]?.refund_percentage - 1) ||
			(isLast && value > policy[index - 1]?.refund_percentage)
		) {
			setCancellationPolicyData(
				cancellationPolicyData.map((data, idx) => ({
					...data,
					refund_percentage:
						idx === index ? value / 100 : data.refund_percentage
				}))
			);
		}
	};

	const handleAddRange = () => {
		let policyData = [...cancellationPolicyData];
		policyData = [
			...policyData.slice(0, -1),
			{
				...policyData[policyData.length - 1],
				to: -policy[policyData.length - 1].from - 1 + ' Day',
				refund_percentage:
					policy[policyData.length - 1].refund_percentage === 100
						? 0.99
						: policy[policyData.length - 1].refund_percentage / 100
			},
			{
				from: -policy[policyData.length - 1].from - 1 + ' Day',
				to: policyData[policyData.length - 1].to,
				refund_percentage: 1
			}
		];
		setCancellationPolicyData(policyData);
	};

	const handleDeleteRange = (idx) => () => {
		setCancellationPolicyData([
			...cancellationPolicyData
				.filter((_, i) => idx !== i)
				.map((item, index) => ({
					...item,
					from:
						idx === 0
							? index === 0
								? '0 Day'
								: item.from
							: index === idx
							? cancellationPolicyData[index - 1].to
							: item.from
				}))
		]);
	};

	useEffect(() => {
		checkPolicy();
	}, []);

	useEffect(() => {
		checkPolicy();
	}, [policyTitle, policyDescription, upiriaCancellationPolicy]);

	useEffect(() => {
		setPolicy(
			cancellationPolicyData.map(({ from, to, refund_percentage }, index) => ({
				from: -from.split(' ')[0],
				to: -to.split(' ')[0],
				refund_percentage: +refund_percentage * 100,
				isLastItem: cancellationPolicyData.length - 1 === index
			}))
		);
	}, [cancellationPolicyData]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container thin-scrollbar">
				<h2 style={{ fontSize: '24px' }}>{dictionary.choose_policy}</h2>
				<div style={{ marginTop: '30px' }} className="text">
					{dictionary.changes_to_your_cancellation}
				</div>
				<label style={{ marginTop: '25px' }} className="radio-label">
					{dictionary.upiria_cancellation_policy}
					<input
						onChange={onRadioChanged}
						checked={upiriaCancellationPolicy}
						value="no"
						type="radio"
						name="language"
						data-testid="upiria-cancellation-policy"
					/>
					<span className="checkmark" />
				</label>
				<div className="terms-text" style={{ marginLeft: 36 }}>
					{dictionary.guests_can_cancel}
				</div>
				<div className="terms-text" style={{ marginLeft: 36, marginTop: 10 }}>
					{dictionary.read_more_at}
					<span style={{ fontWeight: 'bold' }}>
						{dictionary.terms_and_conditions}
					</span>
				</div>
				<div className="custom-policy-radio-button">
					<label className={`radio-label ${!isVerified && 'radio-disabled'}`}>
						{dictionary.custom_cancellation_policy}
						<input
							onChange={onRadioChanged}
							checked={!upiriaCancellationPolicy}
							disabled={!isVerified}
							value="yes"
							type="radio"
							name="language"
							data-testid="custom-cancellation-policy"
						/>
						<span className="checkmark" />
					</label>
					{!isVerified && (
						<Tooltip content={dictionary.you_need_to_be_verified_account} />
					)}
				</div>
				<div
					className="cancellation-policy-form-container"
					style={{ display: upiriaCancellationPolicy ? 'none' : 'block' }}
				>
					<div className="group-inputs">
						<label style={{ fontWeight: 400 }}>{dictionary.title}</label>
						<input
							onChange={onTextChanged}
							value={policyTitle}
							id="title"
							type="text"
							placeholder={dictionary.cancellation_policy_title}
							data-testid="cancellation-policy-title"
						/>
					</div>

					<div className="group-inputs">
						<label style={{ fontWeight: 400 }}>{dictionary.description}</label>
						<textarea onChange={onTextChanged} value={policyDescription} data-testid="cancellation-policy-description"/>
					</div>

					<label style={{ marginTop: 10 }} className="radio-label">
						{dictionary.no_refund}
						<input
							onChange={onRefundRadioChanged}
							checked={noRefund}
							value="no"
							type="radio"
							name="refund"
						/>
						<span className="checkmark checkmark--small" />
					</label>

					<label style={{ marginTop: '25px' }} className="radio-label">
						{dictionary.refunded}
						<input
							onChange={onRefundRadioChanged}
							checked={!noRefund}
							value="yes"
							type="radio"
							name="refund"
						/>
						<span className="checkmark checkmark--small" />
					</label>

					{!noRefund && (
						<div style={{ marginLeft: 20 }}>
							<div>
								{policy.map(
									({ from, to, refund_percentage, isLastItem }, index) => (
										<div
											key={index}
											className="d-flex items-center group-inputs"
											style={{ alignItems: 'center' }}
										>
											<h4
												style={{
													fontWeight: 400,
													marginRight: 'auto',
													textTransform: 'capitalize'
												}}
											>
												{dictionary.from}
											</h4>
											<div className="days-input-container">
												<input
													name="firstDay"
													value={from}
													type="number"
													min={1}
													disabled={index === 0}
													className="days-input"
													onChange={(e) => onDayChange(e, index)}
												/>
											</div>
											<h4
												style={{
													fontWeight: 400,
													margin: isLastItem ? '0 6px' : 'auto'
												}}
											>
												{!isLastItem ? dictionary.to : dictionary.and}
											</h4>
											<div
												className={!isLastItem ? 'days-input-container' : ''}
											>
												<input
													name="lastDay"
													value={!isLastItem ? to : 'More'}
													type={!isLastItem ? 'number' : 'text'}
													min={2}
													disabled={isLastItem}
													className="days-input"
													onChange={(e) => onDayChange(e, index)}
												/>
											</div>
											<h4 style={{ fontWeight: 400, margin: 'auto' }}>
												{dictionary.before_activity} -
											</h4>
											<div
												className={index !== 0 ? 'percent-input-container' : ''}
											>
												<input
													type={index === 0 ? 'text' : 'number'}
													className="percent-input"
													step={1}
													min={1}
													max={
														isLastItem
															? 100
															: policy[index + 1]?.refund_percentage - 1
													}
													style={index === 0 ? { paddingRight: 10 } : {}}
													value={index === 0 ? 'No refund' : refund_percentage}
													onChange={(e) => onPercentChange(e, index)}
												/>
											</div>
											<div
												className={
													(cancellationPolicyData.length <= 2 && 'disabled') +
													' dialog-document-row-delete'
												}
												data-testid="delete-range-button"
												onClick={
													cancellationPolicyData.length > 2 &&
													handleDeleteRange(index)
												}
											/>
										</div>
									)
								)}
							</div>
							<div
								className="secondary-button"
								onClick={handleAddRange}
								style={{ marginTop: 20, marginBottom: 10 }}
								data-testid="add-range-button"
							>
								<div className="plus-dark-icon"></div>
								{dictionary.add_range}
							</div>
						</div>
					)}

					<CustomCheckbox
						label={dictionary.i_would_like_you_to_remember}
						checked={rememberPolicy}
						setChecked={setRememberPolicy}
					/>
				</div>
			</div>
		</div>
	);
};

CancellationPolicy.propTypes = {
	setEnabled: PropTypes.func,
	cancellationPolicyData: PropTypes.array,
	setCancellationPolicyData: PropTypes.func,
	policyTitle: PropTypes.string,
	setPolicyTitle: PropTypes.func,
	upiriaCancellationPolicy: PropTypes.bool,
	setUpiriaCancellationPolicy: PropTypes.func,
	policyDescription: PropTypes.string,
	setPolicyDescription: PropTypes.func,
	rememberPolicy: PropTypes.bool,
	setRememberPolicy: PropTypes.func,
	noRefund: PropTypes.bool,
	setNoRefund: PropTypes.func
};

export default CancellationPolicy;
