import experienceIllustration from '../../../../../assets/images/schedule_experience_illustration.svg';
import resourceIllustration from '../../../../../assets/images/schedule_resource_illustration.svg';
import { ArrayHelper } from '../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import ConfirmationModal from '../../../common/ConfirmationModal';
import CustomToast from '../../../common/CustomToast';
import FormLoader from '../../../common/FormLoader';
import * as common from '../../../common/__redux/commonRedux';
import CustomSelect from '../../../common/custom_select/CustomSelect';
import ResourceSelect from '../../new_experience/steps/name_and_details/resource_select/ResourceSelect';
import { getAllResources } from '../../provider-resources/networking';
import {
	// getAllExperiences,
	getAvailableExperiencesList
} from '../../provider_experiences/networking';
import { createResourceSlot, createSlot, updateSlot } from '../networking';
// wmModeValues
import LinkedExperienceItem from './LinkedExperienceItem';
import CustomRepeatDialog from './custom_repeat_dialog/CustomRepeatDialog';
import ExperienceSelect from './experience_select/ExperienceSelect';
import axios from 'axios';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import { DatePickerField } from '../../custom/DatePickerField';
import { TimePickerField } from '../../custom/TimePickerField';

const ScheduleSlotDialog = (props) => {
	const dictionary = useDictionary();

	const [date, setDate] = useState('');
	const [time, setTime] = useState('');

	const [showDialog, setDialogVisibility] = useState(false);
	const [enableScheduling, setEnableScheduling] = useState(false);

	const [experience, setExperience] = useState(null);
	const [resource, setResource] = useState(null);

	const [experiences, setExperiences] = useState([]);
	const [resources, setResources] = useState([]);
	const [selectedLinkedExperiences, setSelectedLinkedExperiences] = useState(
		[]
	);

	const [showLoader, setShowLoader] = useState(false);
	const [showLoaderMessage, setShowLoaderMessage] = useState(false);
	const [loaderMessage, setLoaderMessage] = useState(null);

	const [minutes, setMinutes] = useState(0);
	const [hours, setHours] = useState(0);
	const [days, setDays] = useState(0);

	const [breakMinutes, setBreakMinutes] = useState(0);
	const [breakHours, setBreakHours] = useState(0);

	const [repeat, setRepeat] = useState(1);
	// const [notification, setNotification] = useState(10);

	const [showRepetition, setShowRepetition] = useState(false);

	const [capacity, setCapacity] = useState(1);

	//custom repeat values
	const [neverEnds, setNeverEnds] = useState();
	const [endsOn, setEndsOn] = useState('');
	const [dailyRepeatMode, setDailyRepeatMode] = useState();
	const [customRepetition, setCustomRepetition] = useState([]);
	const [dailyRepeatStartTime, setDailyRepeatStartTime] = useState('');
	const [dailyRepeatEndTime, setDailyRepeatEndTime] = useState('');
	const [startTimestamp, setStartTimestamp] = useState(0);
	const [endTimestamp, setEndTimestamp] = useState(0);

	//week month repeat
	const [wmRepeat, setWmRepeat] = useState();
	const [wmRepeatNum, setWmRepeatNum] = useState(1);
	const [wmMode, setWmMode] = useState(7);

	const [changeCount, setChangeCount] = useState(0);
	const [showDismissDialog, setDismissDialogVisibility] = useState(false);

	const [isPrivateTour, setIsPrivateTour] = useState(false);

	const source = axios.CancelToken.source();

	// const notificationValues = [
	// 	{
	// 		name: dictionary.ten_minutes_before_start,
	// 		value: 10
	// 	},
	// 	{
	// 		name: dictionary.half_hour_before_start,
	// 		value: 30
	// 	},
	// 	{
	// 		name: dictionary.one_hour_before_start,
	// 		value: 60
	// 	}
	// ];

	const wmModeValues = [
		{
			name: dictionary.week,
			value: 7,
			frequency: 'weekly'
		},
		{
			name: dictionary.month,
			value: 30,
			frequency: 'monthly'
		}
	];

	const repeatSchedules = [
		{
			name: dictionary.does_not_repeat,
			title: dictionary.does_not_repeat,
			value: 1
		},
		{
			name: repeat === 5 ? dictionary.edit_custom_schedule : dictionary.custom,
			title: dictionary.custom,
			value: 5
		}
	];

	const onSetPrivateTour = (e) => {
		setIsPrivateTour(e.target.checked);
	};

	useEffect(() => {
		if (props.scheduleMode) {
			if (
				(parseInt(days) !== 0 ||
					parseInt(hours) !== 0 ||
					parseInt(minutes) !== 0) &&
				date !== '' &&
				time !== ''
			) {
				setEnableScheduling(true);
			} else {
				if (
					date !== '' &&
					repeat !== 1 &&
					dailyRepeatMode !== '0' &&
					dailyRepeatStartTime !== ''
				) {
					setEnableScheduling(true);
				} else {
					setEnableScheduling(false);
				}
			}
		} else {
			let enable;
			if (dailyRepeatMode !== '0' && dailyRepeatStartTime !== '') {
				enable = date !== '';
			} else {
				enable = date !== '' && time !== '';
			}

			if (props.scheduleMode === 'experience') {
				enable = enable && experience !== null;
			} else {
				enable = enable && resource !== null;
			}

			enable = enable && (days !== 0 || hours !== 0 || minutes !== 0);

			setEnableScheduling(enable);
		}

		setChangeCount(changeCount + 1);
	}, [
		date,
		time,
		experience,
		resource,
		minutes,
		hours,
		days,
		dailyRepeatMode,
		dailyRepeatStartTime
	]);

	const onFinishClicked = () => {
		if (props.editType) {
			updateSlotAsync();
		} else {
			createSlotAsync();
		}
	};

	/**
	 * Update slots
	 * @returns {Promise<void>}
	 */
	const updateSlotAsync = async () => {
		setShowLoader(true);
		let start = new Date(date);
		let parts = [];
		parts = time.split(':');
		start.setHours(parts[0]);
		start.setMinutes(parts[1]);

		let body = {
			start_time: start.getTime() / 1000,
			duration: days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60,
			update_type: props.editType,
			is_private_tour: isPrivateTour
		};

		if (props.scheduleMode === 'experience') {
			body.capacity = capacity;
		} else {
			body.quantity = capacity;
		}

		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await updateSlot(
				props.editSlot.id,
				body,
				props.isResource
			);
			setShowLoader(false);
			props.getSlotAsync(props.editSlot.id, props.scheduleMode);
			dismissDialog();
			props.onScheduleSet();
		} catch (error) {
			setShowLoader(false);
		}
	};

	const createSlotAsync = async () => {
		setShowLoader(true);
		setEnableScheduling(false);
		//start date is initialized according to selected date
		let start = new Date(date);
		let end;
		let parts = [];
		let partsEnd = [];
		//if there is a repeat schedult then extract the parts from start and end time
		if (
			repeat !== 1 &&
			dailyRepeatMode !== '0' &&
			dailyRepeatStartTime !== ''
		) {
			parts = dailyRepeatStartTime.split(':');
			partsEnd = dailyRepeatEndTime.split(':');

			end = new Date(date);
			end.setHours(partsEnd[0]);
			end.setMinutes(partsEnd[1]);
		}
		//otherwise extract the parts from the selected time
		else {
			parts = time.split(':');
		}

		start.setHours(parts[0]);
		start.setMinutes(parts[1]);

		let daysNew;

		if (repeat === 2) {
			daysNew = [1, 2, 3, 4, 5, 6, 7];
		} else if (repeat === 3) {
			daysNew = [2];
		} else if (repeat === 4) {
			daysNew = [2, 3, 4, 5, 6];
		} else if (repeat === 5) {
			daysNew = Object.assign(customRepetition, []);
			daysNew.map((item, index) => (daysNew[index] = daysNew[index]++));
		}

		let body = {
			start_time: start.getTime() / 1000,
			duration: days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60,
			is_private_tour: isPrivateTour,
			day_repeat: dailyRepeatMode
		};

		//if the slot is not repeated within the week then don't add a repeat schedule
		if (wmRepeat) {
			body.repeat = neverEnds
				? {
						week_days: daysNew,
						interval: wmRepeatNum,
						frequency: wmModeValues.find((item) => item.value === wmMode)
							.frequency
				  }
				: {
						//in this case send until
						until: new Date(endsOn).getTime() / 1000,
						week_days: daysNew,
						interval: wmRepeatNum,
						frequency: wmModeValues.find((item) => item.value === wmMode)
							.frequency
				  };
		}

		if (breakHours !== 0 || breakMinutes !== 0) {
			body.break_time = breakHours * 60 * 60 + breakMinutes * 60;
		}

		if (
			repeat !== 1 &&
			dailyRepeatMode !== '0' &&
			dailyRepeatStartTime !== ''
		) {
			body.end_time = end.getTime() / 1000;
		}

		if (props.scheduleMode === 'experience') {
			body.capacity = capacity;
		} else {
			let experienceIds = selectedLinkedExperiences.map((item) => item.id);
			body.quantity = capacity;
			body.linked_experiences = experienceIds;
		}

		if (repeat === 1) {
			delete body['repeat'];
			delete body['day_repeat'];
			delete body['end_time'];
		}

		try {
			// eslint-disable-next-line no-unused-vars
			const { data } =
				props.scheduleMode === 'experience'
					? await createSlot(experience.id, body)
					: await createResourceSlot(resource.id, body);
			setShowLoader(false);
			dismissDialog();
			props.onScheduleSet();
		} catch (e) {
			setEnableScheduling(true);
			setShowLoader(false);
			// if (e.response) {
			// 	props.editSlot(e.response.data.error_code.toString());
			// }
			if (e?.response?.data?.message) {
				toast.error(<CustomToast message={e.response.data.message} />, {
					position: 'bottom-left',
					autoClose: 5000,
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-error'
				});
			}
		}
	};

	useEffect(() => {
		if (props.editType) {
			setDate(moment.unix(props.editSlot.start_time).format('yyyy-MM-DD'));
			setTime(moment.unix(props.editSlot.start_time).format('HH:mm:ss'));
			let slotDuration = props.editSlot.end_time - props.editSlot.start_time;

			setDays(parseInt(slotDuration / 86400));
			setHours(parseInt(parseInt(slotDuration % 86400) / 3600));
			setMinutes(parseInt(parseInt(slotDuration % 86400) % 3600) / 60);

			setCapacity(
				props.isResource ? props.editSlot.quantity : props.editSlot.capacity
			);
			setIsPrivateTour(props.editSlot.is_private_tour);
		} else {
			if (props.isResource) {
				setDays(parseInt(props.editSlot?.experiences[0]?.duration / 86400));
				setHours(
					parseInt(
						parseInt(props.editSlot?.experiences[0]?.duration % 86400) / 3600
					)
				);
				setMinutes(
					parseInt(
						parseInt(props.editSlot?.experiences[0]?.duration % 86400) % 3600
					) / 60
				);
			}

			if (props.scheduleMode === 'experience') {
				getAllExperiencesAsync();
			} else {
				getResourcesAsync();
			}
		}

		setTimeout(() => {
			setDialogVisibility(true);
		}, 50);

		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	useEffect(() => {
		if (experience !== null) {
			setCapacity(experience?.maximum_group_size);
			setIsPrivateTour(experience?.is_private_tour);
			initDuration();
		}
	}, [experience]);

	useEffect(() => {
		if (resource != null) {
			setSelectedLinkedExperiences(resource?.experiences);
			initResourceDuration();
		}
	}, [resource]);

	const initDuration = () => {
		setDays(parseInt(experience?.duration / 86400));
		setHours(parseInt(parseInt(experience?.duration % 86400) / 3600));
		setMinutes(parseInt(parseInt(experience?.duration % 86400) % 3600) / 60);
	};

	const initResourceDuration = () => {
		if (resource.experiences.length > 0) {
			setDays(parseInt(resource.experiences[0]?.duration / 86400));
			setHours(
				parseInt(parseInt(resource.experiences[0]?.duration % 86400) / 3600)
			);
			setMinutes(
				parseInt(parseInt(resource.experiences[0]?.duration % 86400) % 3600) /
					60
			);
		}
	};

	const getAllExperiencesAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getAvailableExperiencesList(source.token);
			if (data.data.length > 0) {
				setShowLoader(false);
				setExperiences(data.data);
				setExperience(data.data[1]);
			} else {
				props.onNoResults();
			}
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getResourcesAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getAllResources(null, null, null, source.token);
			if (data.data.length > 0) {
				let newResources = data.data.filter(
					(item) => item.experiences.length > 0
				);
				setResources(newResources);
				setResource(newResources[1]);
			} else {
				// props.scheduleMode();
			}
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const onCancelClicked = () => {
		if (!props.editType && changeCount < 4) {
			dismissDialog();
		} else if (props.editSlot && changeCount < 3) {
			dismissDialog();
		} else {
			setDismissDialogVisibility(true);
		}
	};

	const dismissDialog = () => {
		setDialogVisibility(false);
		console.log('@@@@@@@@@@@@');
		props.setShowScheduleDialog(false);
		// setTimeout(() => {
		// 	console.log('@@@@@@@@@@')
		//
		// }, 600);
	};

	const onDateChanged = (value) => {
		setDate(moment(value).format('YYYY-MM-DD'));
	};

	const onTimeChanged = (time) => {
		setTime(time);
	};

	const onRepeatChanged = (value) => {
		if (value === 5) {
			setShowRepetition(true);
		}
	};

	const removeExperience = (experience) => {
		if (selectedLinkedExperiences.length > 1) {
			let newExperiences = Object.assign([], selectedLinkedExperiences);
			ArrayHelper.removeItemOnce(newExperiences, experience);
			setSelectedLinkedExperiences(newExperiences);
		}
	};

	const onCapacityChanged = (e) => {
		const value = parseInt(e.target.value);
		if (value > 0) {
			if (props.editType && props.isResource) {
				setCapacity(e.target.value);
			} else {
				setCapacity(e.target.value);
			}
		}
	};

	return (
		<div
			className="dialog-wrapper"
			style={{ background: 'transparent', position: 'fixed', zIndex: '11' }}
			data-testid="schedule-dialog-wrapper"
		>
			<div className="dialog-portal" onClick={() => onCancelClicked()} />
			<div
				className={
					showDialog
						? 'experience-category-tags-wrapper experience-category-tags-wrapper-visible'
						: 'experience-category-tags-wrapper'
				}
				data-testid="schedule-dialog"
			>
				<div className="schedule-dialog-content-wrapper thin-scrollbar">
					<div
						className="dialog-close-button"
						style={{ right: '24px', top: '24px' }}
						onClick={onCancelClicked}
						data-testid="schedule-dialog-close-button"
					/>
					<img
						src={
							props.scheduleMode === 'experience'
								? experienceIllustration
								: resourceIllustration
						}
					/>
					<div className="schedule-dialog-content-container">
						<h2
							style={{
								whiteSpace: 'pre-line',
								marginBottom: 32,
								lineHeight: '24px'
							}}
						>
							{props.editType
								? dictionary.edit_time_slot
								: props.scheduleMode === 'experience'
								? dictionary.schedule_experience
								: dictionary.schedule_experiences_resource}
						</h2>

						{!props.editType ? (
							<>
								{props.scheduleMode === 'experience' ? (
									<ExperienceSelect
										value={experience}
										setValue={setExperience}
										items={experiences}
									/>
								) : (
									<ResourceSelect
										value={resource}
										setValue={setResource}
										items={resources}
										label={dictionary.resource}
										showSubtitle={false}
									/>
								)}

								{props.scheduleMode === 'resource' ? (
									<>
										<h2
											style={{
												whiteSpace: 'pre-line',
												marginTop: '32px',
												marginBottom: '16px'
											}}
										>
											{dictionary.linked_experiences}
										</h2>

										{selectedLinkedExperiences.map((item) => (
											<LinkedExperienceItem
												key={item.id}
												removeExperience={removeExperience}
												experience={item}
											/>
										))}
									</>
								) : null}
							</>
						) : null}

						{!props.editType ||
						props.editType === dictionary.edit_slot_types.single ? (
							<div
								style={{ marginTop: 0, marginBottom: 16 }}
								className="group-inputs"
							>
								<label style={{ marginBottom: 8 }}>{dictionary.date}</label>
								<DatePickerField
									placeholderText="Select date"
									value={date}
									minDate={new Date()}
									dateFormat="EEE, MMM d, yyyy"
									onChange={onDateChanged}
								/>
							</div>
						) : null}

						{!props.editType ||
						props.editType === dictionary.edit_slot_types.single ||
						props?.editSlot?.setting?.day_repeat !== 2 ? (
							<div
								style={{ marginTop: 0, marginBottom: 16 }}
								className="group-inputs"
							>
								<label style={{ marginBottom: 8 }}>{dictionary.time}</label>
								<TimePickerField value={time} onChange={onTimeChanged} />
							</div>
						) : (
							<div className="warning-container" style={{ marginTop: -8, marginBottom: 16 }}>
								{dictionary.edit_repeat_slot_notification}
							</div>
						)}

						{repeat !== 1 &&
						dailyRepeatMode !== '0' &&
						dailyRepeatStartTime !== '' ? (
							<div className="warning-container" style={{ marginTop: -8, marginBottom: 16 }}>
								{dictionary.time_set_by_repeat}
							</div>
						) : null}

						{!props.editType ||
						props.editType === dictionary.edit_slot_types.single ||
						props?.editSlot?.setting?.day_repeat !== 2 ? (
							<>
								<label style={{ marginBottom: 8 }}>{dictionary.duration}</label>
								<div
									className="group-inputs"
									style={{
										display: 'flex',
										justifyContent: 'space-between',
										marginTop: '0',
										marginBottom: 16
									}}
								>
									<div className="duration-input-container">
										<input
											className="duration-input"
											type="number"
											min="0"
											step="1"
											value={days}
											onChange={(e) => setDays(e.target.value)}
											data-testid="schedule-dialog-days-input"
										/>
										<div className="duration-input-label">
											{dictionary.days_dialog}
										</div>
									</div>
									<div className="duration-input-container">
										<input
											className="duration-input"
											type="number"
											min="0"
											step="1"
											value={hours}
											onChange={(e) => setHours(e.target.value)}
											data-testid="schedule-dialog-hours-input"
										/>
										<div className="duration-input-label">
											{dictionary.hours_dialog}
										</div>
									</div>
									<div className="duration-input-container">
										<input
											className="duration-input"
											type="number"
											min="0"
											step="1"
											value={minutes}
											onChange={(e) => setMinutes(e.target.value)}
											data-testid="schedule-dialog-minutes-input"
										/>
										<div className="duration-input-label">
											{dictionary.minutes_dialog}
										</div>
									</div>
								</div>
							</>
						) : null}

						{dailyRepeatMode !== '0' &&
						dailyRepeatStartTime !== '' &&
						repeat !== 1 ? (
							<>
								<label style={{ marginTop: 16, marginBottom: 8 }}>
									{dictionary.break_time}
								</label>
								<div
									className="group-inputs"
									style={{
										display: 'flex',
										gap: '2px',
										marginTop: '0',
										marginBottom: 16
									}}
								>
									<div className="duration-input-container">
										<input
											className="duration-input"
											type="number"
											min="0"
											step="1"
											value={breakHours}
											onChange={(e) => setBreakHours(e.target.value)}
											data-testid="schedule-dialog-break-hours-input"
										/>
										<div className="duration-input-label">
											{dictionary.hours_dialog}
										</div>
									</div>
									<div className="duration-input-container">
										<input
											className="duration-input"
											type="number"
											min="0"
											step="1"
											value={breakMinutes}
											onChange={(e) => setBreakMinutes(e.target.value)}
											data-testid="schedule-dialog-break-minutes-input"
										/>
										<div className="duration-input-label">
											{dictionary.minutes_dialog}
										</div>
									</div>
								</div>
							</>
						) : null}

						{!props.editType ? (
							<CustomSelect
								onValueChanged={onRepeatChanged}
								value={repeat}
								setValue={setRepeat}
								placeholder={dictionary.select_repeat_schedule}
								title={dictionary.repeat_scheduling}
								items={repeatSchedules}
								style={{
									marginBottom: 16
								}}
							/>
						) : null}

						<h3
							style={{
								fontSize: '16px',
								marginTop: '16px',
								marginBottom: '16px'
							}}
						>
							{dictionary.organization_details}
						</h3>
						{/*<CustomSelect value={notification} setValue={setNotification}
                                      placeholder={dictionary.notification}
                                      title={dictionary.notification} items={notificationValues}/>*/}

						<h3
							style={{
								fontSize: '16px',
								marginTop: '16px'
							}}
						>
							{props.scheduleMode === 'experience'
								? dictionary.capacity
								: dictionary.quantity}
						</h3>
						<div
							className="group-inputs"
							style={{ marginTop: '16px', marginBottom: 16 }}
						>
							{props.scheduleMode === 'experience' && (
								<label style={{ marginBottom: 8 }}>
									{dictionary.maximum_group_size}
								</label>
							)}
							<input
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								onChange={onCapacityChanged}
								type="number"
								step={1}
								value={capacity}
								data-testid="schedule-dialog-capacity-input"
							/>
						</div>

						<div
							className="switch-container"
							style={{ marginTop: 0, width: '100%' }}
						>
							<div className="text">{dictionary.private}</div>
							<label style={{ marginBottom: 8 }} className="switch">
								<input
									id="slot-is-private-tour"
									checked={isPrivateTour}
									type="checkbox"
									onChange={onSetPrivateTour}
									data-testid="slot-is-private-tour"
								/>
								<span className="slider round" />
							</label>
						</div>
					</div>
				</div>

				<FormLoader
					onDismissClicked={onCancelClicked}
					showMessage={showLoaderMessage}
					message={loaderMessage}
					rounded={true}
					showLoader={showLoader}
					showEnter={false}
				/>

				<div className="schedule-dialog-button-container">
					<button
						disabled={!enableScheduling}
						onClick={() => onFinishClicked()}
						className={'button'}
						data-testid="schedule-dialog-finish-button"
					>
						{props.editType ? dictionary.save : dictionary.schedule}
					</button>
				</div>

				{showRepetition ? (
					<CustomRepeatDialog
						wmModeValues={wmModeValues}
						minutes={minutes}
						hours={hours}
						days={days}
						breakMinutes={breakMinutes}
						breakHours={breakHours}
						neverEnds={neverEnds}
						setNeverEnds={setNeverEnds}
						endsOn={endsOn}
						setEndsOn={setEndsOn}
						showRepetition={showRepetition}
						dailyRepeatMode={dailyRepeatMode}
						setDailyRepeatMode={setDailyRepeatMode}
						dailyRepeatStartTime={dailyRepeatStartTime}
						setDailyRepeatStartTime={setDailyRepeatStartTime}
						dailyRepeatEndTime={dailyRepeatEndTime}
						setDailyRepeatEndTime={setDailyRepeatEndTime}
						startTimestamp={startTimestamp}
						setStartTimestamp={setStartTimestamp}
						endTimestamp={endTimestamp}
						setEndTimestamp={setEndTimestamp}
						setShowRepetition={setShowRepetition}
						wmRepeat={wmRepeat}
						setWmRepeat={setWmRepeat}
						wmRepeatNum={wmRepeatNum}
						setWmRepeatNum={setWmRepeatNum}
						wmMode={wmMode}
						setWmMode={setWmMode}
						selection={customRepetition}
						setSelection={setCustomRepetition}
					/>
				) : null}

				{showDismissDialog ? (
					<ConfirmationModal
						setShowModal={setDismissDialogVisibility}
						asyncTask={dismissDialog}
						title={dictionary.dismiss_dialog}
						action={dictionary.dismiss}
					/>
				) : null}
			</div>
		</div>
	);
};

ScheduleSlotDialog.propTypes = {
	setShowScheduleDialog: PropTypes.func,
	scheduleMode: PropTypes.string,
	onNoResults: PropTypes.func,
	editType: PropTypes.string,
	editSlot: PropTypes.object,
	setErrorMessage: PropTypes.func,
	isResource: PropTypes.bool,
	getSlotAsync: PropTypes.func,
	onScheduleSet: PropTypes.func
};

export default connect(common.states, common.actions)(ScheduleSlotDialog);
