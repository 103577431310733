import '../../../../assets/css/sort_options.css';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useSortOptions } from '../../../../utilities/hooks/UseSortOptions';
import useComponentVisible from '../../../../utilities/hooks/useComponentVisible';
import SortOption from './SortOption';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useState } from 'react';

const SortOptionsSelector = ({
	name,
	startPosition = 'right',
	onValueChanged
}) => {
	const options = useSortOptions(name);
	const dictionary = useDictionary();
	const [selectedOption, setSelectedOption] = useState(null);
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	useEffect(() => {
		let option = retrieveSavedOption();
		setSelectedOption(option);
		if (onValueChanged) {
			onValueChanged(option);
		}
	}, []);

	const retrieveSavedOption = () => {
		let option = localStorage.getItem(name + '_selected_sort_type');
		if (!option) {
			return options[0];
		}
		option = JSON.parse(option);
		return option;
	};

	const saveSelectedOption = (option) => {
		localStorage.setItem(name + '_selected_sort_type', JSON.stringify(option));
	};

	const onOptionSelected = (newOption) => {
		setSelectedOption(newOption);
		saveSelectedOption(newOption);
		setIsComponentVisible(false);
		onValueChanged(newOption);
	};

	return (
		<div className="sort-options-wrapper" ref={ref}>
			<div
				className={
					isComponentVisible
						? 'selected-sort-option-container selected-sort-option-container-active'
						: 'selected-sort-option-container'
				}
				onClick={() => setIsComponentVisible(!isComponentVisible)}
			>
				{selectedOption?.label}
			</div>
			{isComponentVisible ? (
				<div
					className={
						startPosition === 'right'
							? 'sort-options-container'
							: 'sort-options-container sort-options-container--left'
					}
				>
					<h4
						style={{
							color: 'rgba(34, 38, 76, 0.6)',
							height: '40px',
							paddingLeft: '16px',
							lineHeight: '40px'
						}}
					>
						{dictionary.sort_by}
					</h4>
					{options.map((item) => (
						<SortOption
							key={item.value + '_' + item.direction} // or key={index}
							onOptionSelected={onOptionSelected}
							option={item}
							selectedOption={selectedOption}
						/>
					))}
				</div>
			) : null}
		</div>
	);
};

SortOptionsSelector.propTypes = {
	name: PropTypes.string.isRequired,
	startPosition: PropTypes.string,
	onValueChanged: PropTypes.func
};

export default SortOptionsSelector;
