import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';

import { connect } from 'react-redux';
import * as common from '../../../../common/__redux/commonRedux';
import PropTypes from 'prop-types';

import caretRightFilter from '../../../../../../assets/icons/ic_caret_right_filter.svg';
import caretDown from '../../../../../../assets/icons/ic_caret_down.svg';
import icCalendar from '../../../../../../assets/icons/ic_calendar_title.svg';
import icStatus from '../../../../../../assets/icons/ic_status.svg';
import icRemoveFilter from '../../../../../../assets/icons/ic_remove_filter.svg';
import SelectFilterStatus from './SelectFilterStatus';
import SelectFilterPaymentStatus from './SelectFilterPaymentStatus';
import { useBookingStatuses } from '../../../../../../utilities/hooks/useBookingStatuses';
import { usePaymentStatuses } from '../../../../../../utilities/hooks/usePaymentStatuses';
import icExperience from '../../../../../../assets/icons/ic_experience.svg';
import { DatePickerField } from '../../../custom/DatePickerField';
import SelectFilterProviders from './SelectFilterProviders';
import moment from "moment";
import { getProviders } from '../networking';

const FilterDialog = (props) => {
	const dictionary = useDictionary();
	const bookingStatuses = useBookingStatuses();
	const paymentStatuses = usePaymentStatuses()
	const [date, setDate] = useState('');

	const [showDialog, setDialogVisibility] = useState(false);
	const [enableScheduling, setEnableScheduling] = useState(false);

	const [imgCreateDateCarret, setImgCreateDateCarret] =
		useState(caretRightFilter);
	const [showCreateDateInput, setShowCreateDateInput] = useState(false);
	const [showStatusSelectbox, setShowStatusSelectbox] = useState(false);
	const [showPaymentStatusSelectbox, setShowPaymentStatusSelectbox] = useState(false);
	const [showBoxFilter, setShowBoxFilter] = useState(true);

	const [statusNameSelecteds, setStatusNameSelecteds] = useState([]);
	const [statusCheckeds, setStatusCheckeds] = useState([]);

	const [paymentStatusNameSelecteds, setPaymentStatusNameSelecteds] = useState([]);
	const [paymentStatusCheckeds, setPaymentStatusCheckeds] = useState([]);
	const [showProviderSelectbox, setShowProviderSelectbox] = useState(false);
	const [providerNameSelecteds, setProviderNameSelecteds] = useState([]);
	const [providerCheckeds, setProviderCheckeds] = useState([]);
	const [providers, setProviders] = useState([]);
	const [providerStatuses, setProviderStatuses] = useState({});

	const showProviderBox = () => {
		if (showProviderSelectbox) {
			setShowProviderSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowProviderSelectbox(true);
			setShowBoxFilter(false);
		}
	}

	const getAllProvidersAsync = async () => {
		const providerData  = await getProviders();
		if (providerData.data.data.length > 0) {
			setProviders(providerData.data.data);
			let providerObjs = {};
			for (const provider of providerData.data.data) {
				const itemProvider = {
					id: provider.id,
					text:  provider.brand_name ? provider.brand_name : provider.email,
					class: provider.id
				};
				providerObjs[provider.id] = itemProvider;
			}
			localStorage.setItem('admin_booking_filter_provider_list', JSON.stringify(providerObjs));
			setProviderStatuses(providerObjs);
		}
	};

	const getProviderSelected = () => {
		const providerIds = JSON.parse(localStorage.getItem('admin_booking_filter_provider')) || [];
		let expStatuses = providerStatuses;
		if (Object.keys(providerStatuses).length === 0) {
			expStatuses = JSON.parse(localStorage.getItem('admin_booking_filter_provider_list')) || {};
			setProviderStatuses(expStatuses);
		}
		let providerSelecteds = [];
		Object.keys(expStatuses).map((providerStatus) => {
			if (providerIds.length > 0) {
				if (providerIds.includes(providerStatus)) {
					providerSelecteds[providerStatus] = expStatuses[providerStatus];
				}
			}
		});

		setProviderNameSelecteds(providerSelecteds);

		let providerCheckedElms = [];
		Object.keys(expStatuses).map((providerceStatus) => {
			if (providerIds.length === 0) {
				providerCheckedElms[providerceStatus] = false;
			} else {
				if (providerIds.includes(providerceStatus)) {
					providerCheckedElms[providerceStatus] = true;
				}
			}
		});

		setProviderCheckeds(providerCheckedElms);
	}
	const removeExperienceFilter = (experienceId) => {
		let experienceIds = JSON.parse(localStorage.getItem('admin_booking_filter_provider')) || [];
		if (experienceIds.includes(experienceId)) { 
			for( var i = 0; i < experienceIds.length; i++) { 
				if ( experienceIds[i] === experienceId) { 
					experienceIds.splice(i, 1); 
				}
			}
		}

		if (experienceIds.length > 0) {
			localStorage.setItem('admin_booking_filter_provider', JSON.stringify(experienceIds));
		} else {
			localStorage.removeItem('admin_booking_filter_provider');
		}

		props.onProviderValuesChanged();
		props.setFilterProviderParams(experienceIds);
		getProviderSelected();
		setEnableScheduling(true);
	}
	const onFilterProviderSelected = (statusParams) => {
		props.setFilterProviderParams(statusParams);
		props.onProviderValuesChanged();
		getProviderSelected();
	};

	const onFinishClicked = () => {
		props.setCurrentPage(0);
		props.getBookingsAsync();
		onCancelClicked();
	};

	useEffect(() => {
		setTimeout(() => {
			setDialogVisibility(true);
		}, 50);

		const dateFilter = localStorage.getItem('bookings_filter_date');
		if (dateFilter) {
			setDate(dateFilter);
			setShowCreateDateInput(true);
			setImgCreateDateCarret(caretDown);
		}

		getStatusSelected();
		getPaymentStatusSelected();
		getAllProvidersAsync();
		getProviderSelected();
		if (props.filterCount > 0) {
			setEnableScheduling(true);
		}
	}, []);

	const onCancelClicked = () => {
		dismissDialog();
	};

	const dismissDialog = () => {
		setDialogVisibility(false);
		setTimeout(() => {
			props.setShowScheduleDialog(false);
		}, 600);
	};

	const getStatusSelected = () => {
		const statusIds =
			JSON.parse(localStorage.getItem('bookings_filter_status')) || [];
		let statusSelecteds = [];
		Object.keys(bookingStatuses).map((bookingStatus) => {
			if (statusIds.length > 0) {
				if (statusIds.includes(bookingStatus)) {
					statusSelecteds[bookingStatus] = bookingStatuses[bookingStatus];
				}
			}
		});

		setStatusNameSelecteds(statusSelecteds);

		let statusCheckedElms = [];
		Object.keys(bookingStatuses).map((bookingStatus) => {
			if (statusIds.length === 0) {
				statusCheckedElms[bookingStatus] = false;
			} else {
				if (statusIds.includes(bookingStatus)) {
					statusCheckedElms[bookingStatus] = true;
				}
			}
		});

		setStatusCheckeds(statusCheckedElms);
	};

	const getPaymentStatusSelected = () => {
		const paymentStatusIds =
			JSON.parse(localStorage.getItem('bookings_filter_payment_status')) || [];
		let paymentStatusSelecteds = [];
		Object.keys(paymentStatuses).map((paymentStatus) => {
			if (paymentStatusIds.length > 0) {
				if (paymentStatusIds.includes(paymentStatus)) {
					paymentStatusSelecteds[paymentStatus] = paymentStatuses[paymentStatus];
				}
			}
		});

		setPaymentStatusNameSelecteds(paymentStatusSelecteds);

		let paymentStatusCheckedElms = [];
		Object.keys(paymentStatuses).map((paymentStatus) => {
			if (paymentStatusIds.length === 0) {
				paymentStatusCheckedElms[paymentStatus] = false;
			} else {
				if (paymentStatusIds.includes(paymentStatus)) {
					paymentStatusCheckedElms[paymentStatus] = true;
				}
			}
		});

		setPaymentStatusCheckeds(paymentStatusCheckedElms);
	}

	const onDateChange = (date) => {
		date = moment(date).format("YYYY-MM-DD")
		setDate(date);
		if (date !== '') {
			setEnableScheduling(true);
			localStorage.setItem('bookings_filter_date', date);
		} else {
			// if (props.filterCount - 1 <= 0) {
			// 	setEnableScheduling(false);
			// }
			setEnableScheduling(true);
			localStorage.removeItem('bookings_filter_date');
		}

		props.onCreateDateValueChanged(date);
	};

	const showCreateDate = () => {
		if (showCreateDateInput) {
			setShowCreateDateInput(false);
			setImgCreateDateCarret(caretRightFilter);
		} else {
			setShowCreateDateInput(true);
			setImgCreateDateCarret(caretDown);
		}
	};

	const showStatusBox = () => {
		if (showStatusSelectbox) {
			setShowStatusSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowStatusSelectbox(true);
			setShowBoxFilter(false);
		}
	};

	const onFilterStatusSelected = (statusParams) => {
		props.setFilterStatusParams(statusParams);
		props.onStatusValuesChanged();
		getStatusSelected();
	};

	const removeStatusFilter = (statusId) => {
		let statusIds =
			JSON.parse(localStorage.getItem('bookings_filter_status')) || [];
		if (statusIds.includes(statusId)) {
			for (var i = 0; i < statusIds.length; i++) {
				if (statusIds[i] === statusId) {
					statusIds.splice(i, 1);
				}
			}
		}

		if (statusIds.length > 0) {
			localStorage.setItem('bookings_filter_status', JSON.stringify(statusIds));
		} else {
			localStorage.removeItem('bookings_filter_status');
		}

		props.setFilterStatusParams(statusIds);
		props.onStatusValuesChanged();
		getStatusSelected();
		setEnableScheduling(true);
		// if (props.filterCount - 1 <= 0) {
		// 	setEnableScheduling(false);
		// }
	};

	const showPaymentStatusBox = () => {
		if (showPaymentStatusSelectbox) {
			setShowPaymentStatusSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowPaymentStatusSelectbox(true);
			setShowBoxFilter(false);
		}
	};

	const onFilterPaymentStatusSelected = (statusParams) => {
		props.setFilterPaymentStatusParams(statusParams);
		props.onPaymentStatusValuesChanged();
		getPaymentStatusSelected();
	};

	const removePaymentStatusFilter = (paymentStatusId) => {
		let paymentStatusIds =
			JSON.parse(localStorage.getItem('bookings_filter_payment_status')) || [];
		if (paymentStatusIds.includes(paymentStatusId)) {
			for (var i = 0; i < paymentStatusIds.length; i++) {
				if (paymentStatusIds[i] === paymentStatusId) {
					paymentStatusIds.splice(i, 1);
				}
			}
		}

		if (paymentStatusIds.length > 0) {
			localStorage.setItem('bookings_filter_payment_status', JSON.stringify(paymentStatusIds));
		} else {
			localStorage.removeItem('bookings_filter_payment_status');
		}

		props.setFilterPaymentStatusParams(paymentStatusIds);
		props.onPaymentStatusValuesChanged();
		getPaymentStatusSelected();
		setEnableScheduling(true);
		// if (props.filterCount - 1 <= 0) {
		// 	setEnableScheduling(false);
		// }
	};

	const handleResetAll = () => {
		localStorage.removeItem('bookings_filter_date');
		localStorage.removeItem('bookings_filter_status');
		localStorage.removeItem('bookings_filter_payment_status');
		localStorage.removeItem('admin_booking_filter_provider');
		props.onProviderValuesChanged();
		getProviderSelected(); 
		props.onStatusValuesChanged();
		props.onPaymentStatusValuesChanged();
		getStatusSelected();
		getPaymentStatusSelected();
		setDate('');
		setShowStatusSelectbox(false);
		setShowPaymentStatusSelectbox(false);
		setShowProviderSelectbox(false);
		setShowBoxFilter(true);
		setEnableScheduling(false);
		props.setSearchParam('');
		props.setCreateDateParam('');
		props.setFilterStatusParams([]);
		props.setFilterPaymentStatusParams([]);
		props.setFilterProviderParams([]);
		props.getBookingsAsyncNoParams();
		props.setCurrentPage(0);
	};

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ background: 'transparent', position: 'fixed', zIndex: '11' }}
			data-testid="schedule-dialog-wrapper"
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={
					showDialog
						? 'experience-category-tags-wrapper experience-category-tags-wrapper-visible'
						: 'experience-category-tags-wrapper'
				}
				data-testid="schedule-dialog"
			>
				<div className="schedule-dialog-content-wrapper thin-scrollbar">
					<div
						className="dialog-close-button"
						style={{ right: '24px', top: '21px' }}
						onClick={onCancelClicked}
						data-testid="schedule-dialog-close-button"
					/>

					<div className="schedule-dialog-content-container schedule-dialog-content-container-pa-0">
						<div className="schedule-dialog-content-container-content border-bottom">
							<h2
								style={{
									whiteSpace: 'pre-line',
									fontSize: '16px',
									lineHeight: '24px',
									fontWeight: 500
								}}
							>
								Filters
							</h2>
						</div>

						<div className="schedule-dialog-content-container-content">
							{showBoxFilter ? (
								<div className="box-filter-list">
									<div className="group-filter">
										<div className="group-filter-icon-label">
											<img className="group-filter-icon" src={icCalendar} />
											<span
												onClick={showCreateDate}
												className="group-filter-label"
											>
												{dictionary.create_date}
											</span>
										</div>
										<img
											onClick={showCreateDate}
											className="icon-to-select"
											src={imgCreateDateCarret}
										/>
									</div>

									{showCreateDateInput ? (
										<div className="group-inputs" style={{ margin: '6px 0 2px' }}>
											<DatePickerField
												value={date}
												dateFormat="EEE, MMM d, yyyy"
												placeholderText="Select date"
												onChange={onDateChange}
											/>
										</div>
									) : null}

									<div className="group-filter">
										<div className="group-filter-icon-label">
											<img className="group-filter-icon" src={icStatus} />
											<span
												onClick={showStatusBox}
												className="group-filter-label"
											>
												{dictionary.status}
											</span>
										</div>
										<img
											onClick={showStatusBox}
											className="icon-to-select"
											src={caretRightFilter}
										/>
									</div>

									<div className="group-filter-selected-status">
										{Object.keys(statusNameSelecteds).map(
											(statusNameSelected) => (
												<div
													className="filter-selected-item"
													key={statusNameSelected}
												>
													<span>
														{statusNameSelecteds[statusNameSelected].text}
													</span>
													<img
														onClick={() =>
															removeStatusFilter(statusNameSelected)
														}
														src={icRemoveFilter}
													/>
												</div>
											)
										)}
									</div>

									<div className="group-filter">
										<div className="group-filter-icon-label">
											<img className="group-filter-icon" src={icStatus} />
											<span
												onClick={showPaymentStatusBox}
												className="group-filter-label"
											>
												{dictionary.payment_status}
											</span>
										</div>
										<img
											onClick={showPaymentStatusBox}
											className="icon-to-select"
											src={caretRightFilter}
										/>
									</div>

									<div className="group-filter-selected-status">
										{Object.keys(paymentStatusNameSelecteds).map(
											(paymentStatusNameSelected,index) => (
												<div
													className="filter-selected-item"
													key={index}
												>
													<span>
														{paymentStatusNameSelecteds[paymentStatusNameSelected].text}
													</span>
													<img
														onClick={() =>
															removePaymentStatusFilter(paymentStatusNameSelected)
														}
														src={icRemoveFilter}
													/>
												</div>
											)
										)}
									</div>
									<div className="box-filter-list">
										<div className="group-filter">
											<div className="group-filter-icon-label">
												<img className="group-filter-icon" src={icExperience} />
												<span onClick={showProviderBox} className="group-filter-label">{dictionary.providers}</span>
											</div>
											<img onClick={showProviderBox} className="icon-to-select" src={caretRightFilter} />
										</div>
										
										<div className="group-filter-selected-status">
											{Object.keys(providerNameSelecteds).map((experienceNameSelected) => (
												<div className="filter-selected-item" key={experienceNameSelected}>
													<span>{providerNameSelecteds[experienceNameSelected].text}</span>
													<img onClick={() => removeExperienceFilter(experienceNameSelected)} src={icRemoveFilter} />
												</div>
											))}
										</div>
									</div>
								</div>
							) : null}

							{showStatusSelectbox ? (
								<SelectFilterStatus
									setShowBoxFilter={setShowBoxFilter}
									setShowStatusSelectbox={setShowStatusSelectbox}
									onFilterStatusSelected={onFilterStatusSelected}
									statusCheckeds={statusCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
								/>
							) : null}

							{showPaymentStatusSelectbox ? (
								<SelectFilterPaymentStatus
									setShowBoxFilter={setShowBoxFilter}
									setShowPaymentStatusSelectbox={setShowPaymentStatusSelectbox}
									onFilterPaymentStatusSelected={onFilterPaymentStatusSelected}
									paymentStatusCheckeds={paymentStatusCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
								/>
							) : null}

							{showProviderSelectbox ? (
								<SelectFilterProviders
									setShowBoxFilter={setShowBoxFilter} 
									setShowProviderSelectbox={setShowProviderSelectbox} 
									onFilterProviderSelected={onFilterProviderSelected}
									providerCheckeds={providerCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
									providers={providers}
								/>
							) : null}
						</div>
					</div>
				</div>

				<div className="filter-dialog-button-container">
					<button
						onClick={() => handleResetAll()}
						className="schedule-dialog-reset-button"
						data-testid="schedule-dialog-reset-button"
					>
						Reset
					</button>
					<button
						disabled={!enableScheduling}
						onClick={() => onFinishClicked()}
						className="button filter-dialog-apply-button"
						data-testid="filter-dialog-apply-button"
					>
						Apply
					</button>
				</div>
			</div>
		</div>
	);
};

FilterDialog.propTypes = {
	onCreateDateValueChanged: PropTypes.func,
	onStatusValuesChanged: PropTypes.func,
	onPaymentStatusValuesChanged: PropTypes.func,
	setShowScheduleDialog: PropTypes.func,
	countAllFilter: PropTypes.func,
	filterCount: PropTypes.number,
	getBookingsAsync: PropTypes.func,
	getBookingsAsyncNoParams: PropTypes.func,
	setSearchParam: PropTypes.func,
	setCreateDateParam: PropTypes.func,
	setFilterStatusParams: PropTypes.func,
	setFilterPaymentStatusParams: PropTypes.func,
	setCurrentPage: PropTypes.func,
	onPagingParameterChanged: PropTypes.func,
	onProviderValuesChanged: PropTypes.func,
	setFilterProviderParams: PropTypes.func,
};

export default connect(common.states, common.actions)(FilterDialog);
