import { min } from 'moment';
import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import GroupRateItem from './GroupRateItem';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const GroupRates = ({
	participants,
	setEnabled,
	groupRates,
	setGroupRates,
	minValue,
	maxValue
}) => {
	const dictionary = useDictionary();

	const onAddItemClicked = () => {
        let newItems = Object.assign([], groupRates);
        const newItem = {
            min_size: minValue,
            max_size: maxValue,
            discount: 1,
            participantPrice: (participants[0]?.rate * (1 - (1 / 100 || 0))).toFixed(2),
            isInvalid: false
        };

        // Validate the new item immediately
        newItem.isInvalid = !isValidRange(newItem.min_size, newItem.max_size, newItems.length);

        newItems.push(newItem);
        setGroupRates(newItems);
        setEnabled(checkGroupRates(newItems));
    };

    const onRemoveItemClicked = (item) => {
        let newItems = Object.assign([], groupRates);
        ArrayHelper.removeItemOnce(newItems, item);
        setGroupRates(newItems);
        setEnabled(checkGroupRates(newItems));
    };

    const onItemChanged = (e, index, attribute) => {
        const value = +e.target.value;

        if (attribute === 'discount') {
            if (value < 0 || value > 99) {
                return;
            }
        }
        if (attribute === 'participantPrice') {
            if (value > participants[0]?.rate) {
                return;
            }
        }

        let newItems = Object.assign([], groupRates);
        newItems[index][attribute] = value;

        if (attribute === 'discount') {
            if (newItems[index].isInvalid && value > 0) {
                newItems[index].isInvalid = false;
            }
            newItems[index]['participantPrice'] = (
                participants[0]?.rate *
                (1 - (value / 100 || 0))
            ).toFixed(2);
        }
        if (attribute === 'participantPrice') {
            const discount = +(
                ((participants[0]?.rate - value) / participants[0]?.rate) *
                100
            ).toFixed(0);
            newItems[index]['discount'] = discount;
            if (newItems[index].isInvalid && discount > 0) {
                newItems[index].isInvalid = false;
            }
        }

        const min_size = newItems[index].min_size;
        const max_size = newItems[index].max_size;

        if (attribute === 'min_size' || attribute === 'max_size') {
            newItems[index].isInvalid = !isValidRange(min_size, max_size, index);
        }

        setGroupRates(newItems);
        setEnabled(checkGroupRates(newItems));
    };

    const isValidRange = (min, max, index) => {
        for (let i = 0; i < groupRates.length; i++) {
            if (i !== index) {
                const currentMin = groupRates[i].min_size;
                const currentMax = groupRates[i].max_size;

                if ((min === currentMin && max === currentMax) ||
                    (min >= currentMin && min <= currentMax) ||
                    (max >= currentMin && max <= currentMax) ||
                    (min <= currentMin && max >= currentMax)) {
                    return false;
                }
            }
        }
        return true;
    };
	

	const checkGroupRates = (groupRatesToCheck) => {
        if (groupRatesToCheck) {
            if (groupRatesToCheck.length === 0) {
                return true;
            }
            for (let i = 0; i < groupRatesToCheck.length; i++) {
                if (groupRatesToCheck[i].min_size === 0 ||
                    groupRatesToCheck[i].max_size === 0 ||
                    groupRatesToCheck[i].isInvalid) {
                    return false;
                }
            }
        }
        return true;
    };

    useEffect(() => {
        setEnabled(checkGroupRates(groupRates));
    }, [groupRates]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2 style={{ fontSize: '24px' }}>{dictionary.group_rates}</h2>
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.think_about_group_size}
				</div>
				<div className="provided-items-container" style={{ maxWidth: '430px' }}>
					{groupRates?.map((item, index) => (
						<GroupRateItem
							max={maxValue}
							min={minValue}
							item={item}
							key={index}
							index={index}
							setValue={onItemChanged}
							onRemoveItemClicked={onRemoveItemClicked}
							groupRated={groupRates}
						/>
					))}
				</div>
				<div
					onClick={onAddItemClicked}
					style={{ marginTop: '25px' }}
					className="secondary-button plus-button"
					data-testid="add-group-rate"
				>
					{dictionary.add_group_rate}
				</div>
			</div>
		</div>
	);
};

GroupRates.propTypes = {
	participants: PropTypes.array,
	setEnabled: PropTypes.func.isRequired,
	groupRates: PropTypes.array.isRequired,
	setGroupRates: PropTypes.func.isRequired,
	minValue: PropTypes.number.isRequired,
	maxValue: PropTypes.number.isRequired
};

export default GroupRates;
