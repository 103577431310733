import back from '../../../../../../assets/icons/ic_caret_left.svg';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../../common/SimpleLoader';
import * as common from '../../../../common/__redux/commonRedux';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { NavigatorPaths } from '../../../../../../assets/dictionaries/NavigatorPaths';
import {
	getExperienceByProvider,
	getProviderDetails,
	getMarketplaceExperienceByProvider,
	getResaleCollectionByProvider, getResellers, getProviderBookingList
} from '../networking';
import Experiences from './tabs/experiences/Experiences';
import ProviderDetailRight from './ProviderDetailRight';
import SetUpiriaCommissionDialog from './../SetUpiriaCommissionDialog';
import Assets from './tabs/experiences/Assets';
import {AmountFormatHelper} from "../../../../../../utilities/helpers/AmountFormatHelper";
import Resellers from "./tabs/resellers/Resellers";
import BookingsTab from './tabs/booking/bookings';
import useClientWidth from '../../../../../../utilities/hooks/useClientWidth';

const ProviderDetail = ({ id, dismissDetails, setErrorMessage }) => {
	const dictionary = useDictionary();
	const clientWidth = useClientWidth();
	const history = useHistory();
	const [tabIndex, setTabIndex] = useState(0);
	const [opacity, setOpacity] = useState(0);
	const [showLoader, setLoaderVisibility] = useState(true);
	const [moreStatus, setMoreStatus] = useState(null);
	const [details, setDetails] = useState(null);
	const [showCancelDialog, setCancelDialogVisibility] = useState(false);
	const [experiences, setExperiences] = useState([]);
	const [resellers, setResellers] = useState([]);
	const [marketplaceExperiences, setMarketplaceExperiences] = useState([]);
	const [resaleCollection, setResaleCollection] = useState([]);
	const [assets, setAssets] = useState([]);
	const [showCommissionDialog, setCommissionVisibility] = useState(false);
	const [upiriaCommission, setUpiriaCommission] = useState(0);
	const [bookingList, setbookingList] = useState([]);
	const [loading, setLoading] = useState(false);
	const [hasMore, setHasMore] = useState(true);
	const [totalBooking, setTotalBooking] = useState(0);
	const [pageNumber, setPageNumber] = useState(0);
	const pageSize = 15;

	useEffect(() => {
		setOpacity(1);
		getDetailsByIdAsync();
	}, [upiriaCommission]);
	useEffect(()=>{
		fetchMoreData(id)
	},[])

	const getDetailsByIdAsync = async () => {
		try {
			let { data } = await getProviderDetails(id);
			if (data) {
				setUpiriaCommission(data?.upiria_commission ? AmountFormatHelper.formatAmount(data?.upiria_commission) : 0);
				setDetails(data);
				const experiences = await getExperienceByProvider(data.id);
				setExperiences(experiences?.data ?? []);
				const marketplaceExperiences = await getMarketplaceExperienceByProvider(data.id);
				setMarketplaceExperiences(marketplaceExperiences?.data ?? []);
				const resaleCollection = await getResaleCollectionByProvider(data.id);
				setResaleCollection(resaleCollection?.data ?? []);

				const experienceAssets = [];
				if (experiences?.data?.length) {
					experiences?.data.map((experience) => {
						if (experience?.assets?.length) {
							experience.assets.map((asset) => {
								experienceAssets.push(asset);
							});
						}
					});
				}
				setAssets(experienceAssets);
			}
			let resellersData = await getResellers(id);
			setResellers(resellersData?.data ?? []);
			setLoaderVisibility(false);
		} catch (e) {
			setLoaderVisibility(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
				setTimeout(() => {
					setErrorMessage('');
					dismiss();
				}, 2000);
			}
		}
	};
	const onBookingClicked = (booking) => {
		history.push(`admin/bookings/${booking.id}`, booking);
	};
  
	const fetchMoreData = async () => {
		try {
			if(totalBooking && (totalBooking <= pageSize || bookingList.length === totalBooking)) {
				setHasMore(false);
                return;
			}
			setLoading(true);
			const booking = await getProviderBookingList(id, pageSize, pageNumber);
			setTotalBooking(booking?.data?.recordsFiltered)
			
			let bookingLists = booking?.data?.data ?? [];
			
			if (bookingLists.length === 0) {
				setHasMore(false);
			} else {
				setbookingList(prev => ([...prev, ...bookingLists]));
				setPageNumber(prevPageNumber => prevPageNumber + 1);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		} finally {
			setLoading(false);
		}
	};

	const routeMap = {
		0: <Experiences experiences={experiences} />,
		1: <Experiences experiences={marketplaceExperiences} />,
		2: <Experiences experiences={resaleCollection} />,
		3: <Resellers providers={resellers} />,
		4: <Assets assets={assets} />,
		5: <BookingsTab details={bookingList} onBookingClicked={onBookingClicked} loading={loading} hasMore={hasMore} onLoadMore={fetchMoreData} />
	};

	const dismiss = () => {
		dismissDetails();
		setOpacity(0);
		history.push(NavigatorPaths.applications.path);
	};

	const showPopupSetCommission = () => {
		setCommissionVisibility(true);
	};

	useEffect(() => {
		if (moreStatus === dictionary.cancel) {
			setCancelDialogVisibility(true);
		}
		if (moreStatus === dictionary.message_customer) {
			window.location.href = 'mailto:' + details.customer_email;
		}
	}, [moreStatus]);

	return (
		<div className="booking-details-wrapper provider-booking-details" style={{ opacity: opacity }}>
			<div className="booking-header-container booking-header-container-details">
				<div className="booking-header-title">
					<div className="booking-detail-back-button" onClick={() => dismiss()}>
						<img src={back} />
					</div>
					<h2 style={{ marginLeft: '8px' }} data-testid="booking-details-header">
						{ details ? details.first_name + ' ' + details.last_name : '' }
					</h2>
				</div>
				<div className="experiences-actions">
					<div className="experience-create-button">
						<div className="button"
							onClick={showPopupSetCommission}
							data-testid="share-resale-collection"
						>
							{dictionary.set_upiria_commission}
						</div>
					</div>
				</div>
			</div>

			<div className="booking-details-body provider-details-body" style={{ height: clientWidth >= 986 ? '0' : '600px' }}>
				<div className="booking-details-container">
					<div className="settings-tab-bar">
						<div
							onClick={() => setTabIndex(0)}
							className={
								tabIndex === 0
									? 'settings-tab settings-tab-active'
									: 'settings-tab'
							}
							data-testid="experiences-and-addons-tab"
						>
							{dictionary.experiences}
						</div>
						<div
							onClick={() => setTabIndex(1)}
							className={
								tabIndex === 1 ? 'settings-tab settings-tab-active' : 'settings-tab'
							}
							data-testid="marketplaces-tab"
						>
							{dictionary.marketplaces}
						</div>
						<div
							onClick={() => setTabIndex(2)}
							className={
								tabIndex === 2 ? 'settings-tab settings-tab-active' : 'settings-tab'
							}
							data-testid="resale_collection-tab"
						>
							{dictionary.resale_experience}
						</div>
						<div
							onClick={() => setTabIndex(3)}
							className={
								tabIndex === 3 ? 'settings-tab settings-tab-active' : 'settings-tab'
							}
							data-testid="resellers-tab"
						>
							{dictionary.resellers}
						</div>
						<div
							onClick={() => setTabIndex(5)}
							className={
								tabIndex === 5 ? 'settings-tab settings-tab-active' : 'settings-tab'
							}
							data-testid="provider-booking-tab"
						>
							{dictionary.bookings}
						</div>
						<div
							onClick={() => setTabIndex(4)}
							className={
								tabIndex === 4 ? 'settings-tab settings-tab-active' : 'settings-tab'
							}
							data-testid="assets-tab"
						>
							{dictionary.assets}
						</div>
					</div>
					{details ? routeMap[tabIndex] : null}
					<SimpleLoader showLoader={showLoader} />
				</div>
				{details ? <ProviderDetailRight details={details} /> : null}
			</div>

			{showCommissionDialog ? (
				<SetUpiriaCommissionDialog
					providerId={details?.id}
					setCommissionVisibility={setCommissionVisibility}
					upiriaCommission={upiriaCommission}
					setUpiriaCommission={setUpiriaCommission}
				/>
			) : null}
		</div>
	);
};

ProviderDetail.propTypes = {
	id: PropTypes.string.isRequired,
	dismissDetails: PropTypes.func.isRequired,
	setErrorMessage: PropTypes.func.isRequired
};

export default connect(common.states, common.actions)(ProviderDetail);
