import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { DateFormatHelper } from '../../../../utilities/helpers/DateFormatHelper';

const EditSlotTypeDialog = ({
	setEditSlotTypeDialogVisibility,
	showEditSlotDialogWithMode,
	editSlot,
	isDelete
}) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);
	const [editMode, setEditMode] = useState(dictionary.edit_slot_types.single);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setEditSlotTypeDialogVisibility(false);
		}, 800);
	};

	const onSave = () => {
		onCancelClicked();
		showEditSlotDialogWithMode(editMode);
	};

	const onRadioChanged = (e) => {
		setEditMode(e.target.value);
	};

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ opacity: opacity, zIndex: '11' }}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className="dialog-container"
				style={{ maxHeight: '90%' }}
				data-testid="edit-slot-type-dialog"
			>
				<div className="dialog-title-container">
					<div className="dialog-title">
						{!isDelete
							? dictionary.edit_recurring_time_slot
							: dictionary.delete_slot}
					</div>
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div
					className="dialog-title-divider"
					style={{ marginTop: 12, marginBottom: 0 }}
				/>
				<div className="dialog-content-container">
					<label className="radio-label">
						{dictionary.this_experience}
						<input
							onChange={onRadioChanged}
							checked={editMode === dictionary.edit_slot_types.single}
							value={dictionary.edit_slot_types.single}
							type="radio"
						/>
						<span className="checkmark" />
					</label>
					{!editSlot.is_one && (
						<>
							{editSlot?.setting?.day_repeat === 2 &&
								editSlot?.setting?.repeat_week_days.length > 1 && (
									<label className="radio-label">
										{DateFormatHelper.slotOccurringDayTimeFormat(
											editSlot.start_time,
											editSlot.end_time,
											dictionary
										)}
										<input
											onChange={onRadioChanged}
											checked={
												editMode === dictionary.edit_slot_types.recurring_day_time
											}
											value={dictionary.edit_slot_types.recurring_day_time}
											type="radio"
										/>
										<span className="checkmark" />
									</label>
								)}
							{editSlot?.setting?.repeat_week_days.length > 1 && (
								<label className="radio-label">
									{DateFormatHelper.slotOccurringDayFormat(
										editSlot.start_time,
										editSlot.end_time,
										dictionary
									)}
									<input
										onChange={onRadioChanged}
										checked={
											editMode === dictionary.edit_slot_types.recurring_day
										}
										value={dictionary.edit_slot_types.recurring_day}
										type="radio"
									/>
									<span className="checkmark" />
								</label>
							)}
							<label className="radio-label">
								{dictionary.this_and_all_recurring}
								{DateFormatHelper.slotRecurringFormat(
									editSlot?.setting,
									dictionary
								)}
								<input
									onChange={onRadioChanged}
									checked={editMode === dictionary.edit_slot_types.all}
									value={dictionary.edit_slot_types.all}
									type="radio"
								/>
								<span className="checkmark" />
							</label>
						</>
					)}
				</div>
				<div
					className="dialog-title-divider"
					style={{ marginTop: -18, marginBottom: 0 }}
				/>
				<div className="dialog-buttons-container" style={{ margin: 12 }}>
					<div
						onClick={onCancelClicked}
						className="tertiary-button"
						data-testid="cancel-btn"
					>
						{dictionary.cancel}
					</div>
					<div onClick={onSave} className="button" data-testid="save-btn">
						{!isDelete ? dictionary.edit : dictionary.delete}
					</div>
				</div>
			</div>
		</div>
	);
};

EditSlotTypeDialog.propTypes = {
	setEditSlotTypeDialogVisibility: PropTypes.func,
	showEditSlotDialogWithMode: PropTypes.func,
	editSlot: PropTypes.object,
	isDelete: PropTypes.bool
};

export default EditSlotTypeDialog;
