import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import RepetitionSelector from './RepetitionSelector';
import CustomSelect from '../../../../common/custom_select/CustomSelect';
import PropTypes from 'prop-types';
import { TimePickerField } from '../../../custom/TimePickerField';
import { DatePickerField } from '../../../custom/DatePickerField';
import WorkingHoursDialog from './WorkingHoursDialog';
import { DateTimeUtils } from '../../../../../../utilities/helpers/DateTimeUtils';
import Switch from '../../../../common/Switch';

const CustomRepeatDialog = ({
	wmModeValues,
	minutes,
	hours,
	days,
	breakHours,
	breakMinutes,
	selection,
	setSelection,
	setShowRepetition,
	endsOn,
	setEndsOn,
	// showRepetition,
	dailyRepeatMode,
	setDailyRepeatMode,
	dailyRepeatStartTime,
	setDailyRepeatStartTime,
	dailyRepeatEndTime,
	setDailyRepeatEndTime,
	startTimestamp,
	setStartTimestamp,
	endTimestamp,
	setEndTimestamp,
	wmRepeat,
	setWmRepeat,
	wmRepeatNum,
	setWmRepeatNum,
	wmMode,
	setWmMode,
	neverEnds,
	setNeverEnds
}) => {
	const dictionary = useDictionary();

	const [opacity, setOpacity] = useState(0);
	const [thisSelection, setThisSelection] = useState(selection);
	const [showWorkingHours, setShowWorkingHours] = useState(false);
	const [enableSave, setEnableSave] = useState(false);

	const onCancelClick = () => {
		setOpacity(0);
		setTimeout(() => {
			setShowRepetition(false);
		}, 800);
	};
	const handleDailyRepeatMode = () => {
		setDailyRepeatMode(dailyRepeatMode === '2' ? null : '2');
		if (dailyRepeatMode === '2') {
			onChangeStartTime('');
			onChangeEndTime('');
		}
	};

	const handleWmRepeatChange = (e) => {
		setWmRepeat(e.target.value === 'on');
	};

	// const handleWmModeChange = (value) => {
	// 	setWmMode(value);
	// };

	const handleEndsChange = (e) => {
		setNeverEnds(e.target.value === 'never');
	};

	const getWarningMessage = () => {
		let duration = hours * 3600000 + minutes * 60000;
		let breakDuration = breakHours * 3600000 + breakMinutes * 60000;
		let difference = endTimestamp - startTimestamp;
		if (endTimestamp === 0 || startTimestamp === 0) {
			return null;
		}

		if (duration === 0) {
			return null;
		}
		if (difference < duration + breakDuration) {
			return (
				<div
					className="warning-container"
					data-testid="warning-container"
					style={{ margin: '8px 0 0' }}
				>
					<span>
						{dictionary.duration_shorter_than + ' '}
						<b>
							{' '}
							{hours +
								' ' +
								dictionary.hours +
								' ' +
								minutes +
								' ' +
								dictionary.minutes}
						</b>
					</span>
				</div>
			);
		} else if (difference / (duration + breakDuration) >= 1) {
			let times = Math.floor(difference / (duration + breakDuration));
			return (
				<div
					className="warning-container small-message-container"
					style={{ margin: '8px 0 0' }}
				>
					{dictionary.slot_will_repeat +
						' ' +
						times +
						' ' +
						dictionary.times_within_the_day}
				</div>
			);
		}
	};

	const onChangeStartTime = (time) => {
		setDailyRepeatStartTime(time);
		setStartTimestamp(DateTimeUtils.timeToMilliSeconds(time));
	};

	const onChangeEndTime = (time) => {
		setDailyRepeatEndTime(time);
		setEndTimestamp(DateTimeUtils.timeToMilliSeconds(time));
	};

	useEffect(() => {
		if (dailyRepeatMode === '2') {
			if (dailyRepeatStartTime && dailyRepeatEndTime) {
				setEnableSave(true);
			} else {
				setEnableSave(false);
			}
		} else {
			if (wmRepeat !== undefined) {
				setEnableSave(true);
				if (wmRepeat) {
					if (neverEnds) {
						setEnableSave(true);
					} else {
						if (endsOn) {
							setEnableSave(true);
						} else {
							setEnableSave(false);
						}
					}
				} else {
					setEnableSave(true);
				}
			}
		}
	}, [
		dailyRepeatMode,
		wmRepeat,
		neverEnds,
		endsOn,
		dailyRepeatStartTime,
		dailyRepeatEndTime
	]);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	return (
		<>
			<div
				style={{ opacity: opacity }}
				className="dialog-wrapper"
				data-testid="custom-repeat-dialog"
			>
				<div
					className="dialog-container dialog-container-max-height"
					style={{ width: 466 }}
				>
					<div
						className="dialog-title-container"
						data-testid="dialog-title-container"
					>
						<div
							className="dialog-title"
							data-testid="dialog-title"
							style={{ fontWeight: 500 }}
						>
							{dictionary.repeat_scheduling}
						</div>
						<div className="dialog-close-button" onClick={onCancelClick} />
					</div>
					<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
					<div
						className="dialog-content-container-with-scroll thin-scrollbar-trans"
						style={{ paddingBottom: 20, height: 'unset' }}
					>
						{days < 1 ? (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between'
									}}
								>
									<div className="dialog-title" style={{ fontWeight: 500 }}>
										{dictionary.repeat_during_the_day}
									</div>
									<Switch
										style={{ marginTop: 0 }}
										value={dailyRepeatMode === '2'}
										onChange={handleDailyRepeatMode}
									/>
								</div>

								<div className="schedule-dialog-day-repeat-wrapper">
									<div
										className="experience-info-title"
										style={{
											color: 'rgba(34, 38, 76, 0.8);',
											maxWidth: 'unset',
											marginTop: 12,
											marginBottom: 12,
											fontFamily: 'Roboto'
										}}
									>
										{dictionary.set_custom}
									</div>

									<div className="schedule-dialog-start-end-time-wrapper">
										<div className="schedule-dialog-start-end-time-container">
											<label style={{ marginBottom: 0 }}>
												{dictionary.start_time}
											</label>
											<TimePickerField
												value={dailyRepeatStartTime}
												disabled={dailyRepeatMode !== '2'}
												onChange={onChangeStartTime}
											/>
										</div>

										<div className="schedule-dialog-start-end-time-container">
											<label style={{ marginBottom: 0 }}>
												{dictionary.end_time}
											</label>
											<TimePickerField
												value={dailyRepeatEndTime}
												disabled={dailyRepeatMode !== '2'}
												onChange={onChangeEndTime}
											/>
										</div>
									</div>
								</div>
							</>
						) : null}

						{dailyRepeatMode === '2' && getWarningMessage()}

						<div
							className="dialog-title"
							style={{
								fontFamily: 'Roboto',
								fontWeight: 500,
								marginTop: 28,
								marginBottom: 16
							}}
						>
							{dictionary.recurring}
						</div>

						<div
							className="settings-info-row"
							style={{ gap: '16px', marginBottom: 16 }}
						>
							<label className="radio-label" style={{ marginBottom: '0' }}>
								{dictionary.no}
								<input
									onChange={handleWmRepeatChange}
									checked={wmRepeat === false}
									value="never"
									type="radio"
									name="repeat_wm"
									data-testid="never-repeat"
								/>
								<span className="checkmark" />
							</label>
							<label
								className="radio-label"
								style={{ marginLeft: '16px', marginBottom: '0' }}
							>
								{dictionary.every}
								<input
									onChange={handleWmRepeatChange}
									checked={wmRepeat}
									value="on"
									type="radio"
									name="repeat_wm"
									data-testid="repeat"
								/>
								<span className="checkmark" />
							</label>
							<input
								style={{ marginLeft: '16px' }}
								disabled={!wmRepeat}
								type="number"
								min="1"
								step="1"
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								value={wmRepeatNum}
								onChange={(e) => {
									setWmRepeatNum(e.target.value);
								}}
								className="repeat-day-input"
								data-testid="repeat-number"
							/>

							<CustomSelect
								disabled={!wmRepeat}
								style={{ marginTop: -15, marginBottom: 0 }}
								absolute={true}
								value={wmMode}
								setValue={setWmMode}
								placeholder={dictionary.select_repeat_schedule}
								items={wmModeValues}
							/>
						</div>

						<RepetitionSelector
							selection={thisSelection}
							disabled={!wmRepeat}
							setSelection={setThisSelection}
						/>

						<label
							style={{
								fontFamily: 'Roboto',
								fontWeight: 500,
								marginTop: 28,
								marginBottom: 4
							}}
						>
							{dictionary.ends}
						</label>
						<div
							className="terms-text"
							style={{ color: 'rgba(34, 38, 76, 0.60)', marginBottom: 8 }}
						>
							{dictionary.time_slots_can_be_booked}
						</div>

						<div className="settings-info-row">
							<label
								className={`radio-label ${!wmRepeat && 'radio-disabled'}`}
								style={{ marginBottom: '0' }}
							>
								{dictionary.on_six_months}
								<input
									onChange={handleEndsChange}
									checked={neverEnds}
									value="never"
									type="radio"
									name="ends"
									disabled={!wmRepeat}
									data-testid="never-ends"
								/>
								<span className="checkmark" />
							</label>
							<label
								className={`radio-label ${!wmRepeat && 'radio-disabled'}`}
								style={{ marginLeft: '32px', marginBottom: '0' }}
							>
								{dictionary.on}
								<input
									onChange={handleEndsChange}
									checked={neverEnds === false}
									value="on"
									type="radio"
									name="ends"
									disabled={!wmRepeat}
									data-testid="ends-on"
								/>
								<span className="checkmark" />
							</label>
							<DatePickerField
								value={endsOn}
								onChange={setEndsOn}
								minDate={new Date()}
								disabled={neverEnds === undefined || neverEnds || !wmRepeat}
								className="repeat-end-on-date-picker"
								dateFormat="MMM d, yyyy"
								style={{
									marginLeft: 16,
									width: 145
								}}
							/>
						</div>
					</div>
					<div className="dialog-title-divider" style={{ margin: 0 }} />
					<div
						className="dialog-buttons-container"
						style={{ padding: 12, marginBottom: '0' }}
					>
						<div
							onClick={onCancelClick}
							className="tertiary-button"
							data-testid="cancel-btn"
						>
							{dictionary.cancel}
						</div>
						<button
							onClick={() => {
								setSelection(thisSelection);
								onCancelClick();
							}}
							disabled={!enableSave}
							className="button"
							data-testid="save-btn"
						>
							{dictionary.save}
						</button>
					</div>
				</div>
			</div>
			{showWorkingHours && <WorkingHoursDialog setShow={setShowWorkingHours} />}
		</>
	);
};

CustomRepeatDialog.propTypes = {
	wmModeValues: PropTypes.array,
	minutes: PropTypes.array,
	hours: PropTypes.array,
	days: PropTypes.array,
	breakHours: PropTypes.array,
	breakMinutes: PropTypes.array,
	selection: PropTypes.array,
	setSelection: PropTypes.func,
	setShowRepetition: PropTypes.func,
	endsOn: PropTypes.string,
	setEndsOn: PropTypes.func,
	showRepetition: PropTypes.bool,
	dailyRepeatMode: PropTypes.string,
	setDailyRepeatMode: PropTypes.func,
	dailyRepeatStartTime: PropTypes.string,
	setDailyRepeatStartTime: PropTypes.func,
	dailyRepeatEndTime: PropTypes.string,
	setDailyRepeatEndTime: PropTypes.func,
	startTimestamp: PropTypes.string,
	setStartTimestamp: PropTypes.func,
	endTimestamp: PropTypes.string,
	setEndTimestamp: PropTypes.func,
	wmRepeat: PropTypes.string,
	setWmRepeat: PropTypes.func,
	wmRepeatNum: PropTypes.string,
	setWmRepeatNum: PropTypes.func,
	wmMode: PropTypes.string,
	setWmMode: PropTypes.func,
	neverEnds: PropTypes.bool,
	setNeverEnds: PropTypes.func
};

export default CustomRepeatDialog;
