import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import { UseCurrencyDecorator } from '../../../../../../utilities/hooks/UseCurrencyDecorator';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { DateFormatHelper } from '../../../../../../utilities/helpers/DateFormatHelper';
import placeholder from "../../../../../../assets/images/bg_placeholder_square.svg";
import warning from "../../../../../../assets/icons/ic_warning_triangle.svg";
import eye from '../../../../../../assets/icons/ic_eye.svg';
import useClientWidth from '../../../../../../utilities/hooks/useClientWidth';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { NavigatorPaths } from '../../../../../../assets/dictionaries/NavigatorPaths';

const BookingExperienceDetails = ({ details,viewExperienceHandler,viewBookingHandler }) => {
	const dictionary = useDictionary();
	const clientWidth = useClientWidth();
	const currencyDecorator = UseCurrencyDecorator();
	const history = useHistory();
	const [isCopied, setIsCopied] = useState(null);

	const onCopyClicked = (shareUrl) => {
		navigator.clipboard.writeText(shareUrl).then(
			function () {
				setIsCopied(shareUrl + '_compact');
				setTimeout(() => {
					setIsCopied(null);
				}, 2000);
			},
			// eslint-disable-next-line no-unused-vars
			function (err) {}
		);
	};

	const getCopyButton = () => {
		return (
			<div
				onClick={() => onCopyClicked(details.booking_url)}
				className="experience-item-button-container"
				data-testid="experience-item-copy-button"
			>
				<div
					className={`share-link-copy-icon ${isCopied === details.booking_url + `_compact` ? 'copied' : ''}`}
					data-testid="share-link-copy-icon"
				></div>
			</div>
		);
	};
	
	return (
		<div
			className="booking-experience-details-container"
			data-testid="booking-experience-details-container"
		>
			<div
				className="settings-tab-bar"
				style={{
					justifyContent: 'space-between',
					backgroundColor: 'white',
					alignItems: 'center',
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12
				}}
			>
				<div
					className="settings-tab"
					style={{ color: 'var(--brand-dark-blue)', cursor: 'initial' }}
				>
					{dictionary.details}
				</div>
				<div style={{display: 'flex',justifyContent:'center',alignItems:'center'}}>
					<div
						onClick={() => viewBookingHandler(details.booking_url)}
						className="settings-tab"
						style={{ color: 'var(--brand-red)', cursor: 'pointer' }}
					>
						{dictionary?.view_booking}
					</div>
					{getCopyButton()}
				</div>
				{/*<div className='tertiary-button' style={{marginRight:'32px', gap:'6px'}}>
          <img src={pencil}/>
          {dictionary.edit}
        </div>*/}
			</div>
			<div className="booking-experience-details-content-container thin-scrollbar">
				<div className="booking-experience-images-container">
					{/*{details.experience.assets.map((item, index) => {*/}
					{/*	if (index <= 3) {*/}
					{/*		return (*/}
					{/*			<img*/}
					{/*				key={item?.id}*/}
					{/*				className="booking-experience-images-container-image"*/}
					{/*				src={ImageHelper.getFullImageUrl(item?.thumbnail_url)}*/}
					{/*			/>*/}
					{/*		*/}
					{/*		);*/}
					{/*	}*/}
					{/*})}*/}
					{[0,1,2].map((e)=>{
						return (
							<img
								key={e}
								className="experience-compact-thumb"
								onError={(e) => {
									e.currentTarget.src = placeholder;
								}}
								src={
									details.experience?.assets[e]
										? ImageHelper.getFullImageUrl(
											details.experience?.assets[e]
										)
										: placeholder
								}
							/>
						)
					})}
					<div
						className="booking-experience-images-container-color"
						style={{ backgroundColor: details.experience.color }}
					/>
				</div>
				<div className="participant-row-container" style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '16px' : '8px') }}>
					<h3 onClick={() => viewExperienceHandler(details.experience.view_url)} style={{ fontSize: '16px', marginTop: (clientWidth >= 767 ? '16px' : '8px'), marginBottom: '12px', cursor: 'pointer' }}>
						{details.experience.title}
					</h3>
					<img onClick={() => viewExperienceHandler(details.experience.view_url)} src={eye} style={{ cursor: 'pointer' }}/>
				</div>
				<div className="participant-row-container" style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
					<h4 style={{opacity: 0.6, fontWeight: 'normal'}}>
						{dictionary.provider}
					</h4>
					<h4 style={{fontWeight: 'normal'}}>
						<div className='truncate booking-brand-title' onClick={()=>(history.push(`/${ NavigatorPaths.applications.path}?id=${details.provider.id}`))} style={{ cursor: 'pointer',width: '100%',color: 'var(--brand-red)' }} title={details.provider.brand_name}>{details.provider.brand_name}</div>
					</h4>
				</div>
				{details.status.value === 'reschedule' && details.reschedule_slot ? (
					
                    <>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal'}}>
                                {dictionary.date}
                            </h4>
							<h4 style={{fontWeight: 'normal'}}>
                                {DateFormatHelper.rangeDayMonthYearFormat(
                                    details.reschedule_slot.start_time,
                                    details.reschedule_slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4>
                            </h4>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal' , textDecorationLine : 'line-through'}}>
                                {DateFormatHelper.rangeDayMonthYearFormat(
                                    details.slot.start_time,
                                    details.slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal'}}>
                                {dictionary.time}
                            </h4>
							<h4 style={{fontWeight: 'normal'}}>
                                {DateFormatHelper.timeRangeFormat(
                                    details.reschedule_slot.start_time,
                                    details.reschedule_slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4>
                            </h4>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal' , textDecorationLine : 'line-through'}}>
                                {DateFormatHelper.timeRangeFormat(
                                    details.slot.start_time,
                                    details.slot.end_time
                                )}
                            </h4>
                        </div>
                    </>
                ): (
                    <>
                    <div className="participant-row-container"
                        style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                      {/* Your else condition JSX here */}
                      <h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
                        {dictionary.date}
                        </h4>
                        <h4 style={{ fontWeight: 'normal' }}>
                            {DateFormatHelper.rangeDayMonthYearFormat(
                                details.slot.start_time,
                                details.slot.end_time
                            )}
                        </h4>
                    </div>
                    <div className="participant-row-container" style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                        <h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
                            {dictionary.time}
                        </h4>
                        <h4 style={{ fontWeight: 'normal' }}>
                            {DateFormatHelper.timeRangeFormat(
                                details.slot.start_time,
                                details.slot.end_time
                            )}
                        </h4>
                    </div>
                    </>
                  )}


				{details.overlap &&
					<div className="d-flex overlap-warn-container">
						<img src={warning} />
						<div className="overlap-warn">
							Suggested slot is overlapping with other existing slots

						</div>
					</div>
				}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.customer}
					</h4>
					<div className="booking-customer-container">
						<img className="booking-customer-avatar" src={details.avatar_url} />
						<h4 className='truncate booking-customer-container-customername' title={details.customer.name}>{details.customer.name}</h4>
					</div>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.email}
					</h4>
					<div className="booking-customer-container">
						<h4 style={{ fontWeight: 'normal' }}>{details.customer.email}</h4>
					</div>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.channel}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>{details.channel}</h4>
				</div>
				{details?.group ? (
					<div
						className="participant-row-container"
						style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.group}
						</h4>
						<div style={{ fontWeight: 'normal' }} className='truncate booking-detail-group-title' title={details.group.title}>{details.group.title}</div>
					</div>
				) : null}
				{/*<div*/}
				{/*	className="participant-row-container"*/}
				{/*	style={{ justifyContent: 'space-between', marginTop: '24px' }}*/}
				{/*>*/}
				{/*	<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>*/}
				{/*		{dictionary.email}*/}
				{/*	</h4>*/}
				{/*	<div className="booking-customer-container">*/}
				{/*		<h4 style={{ fontWeight: 'normal' }}>{details.customer_email}</h4>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.phone}
					</h4>
					<div className="booking-customer-container">
						<h4 style={{ fontWeight: 'normal' }}>{details?.customer?.phone ?? '-'}</h4>
					</div>
				</div>

				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.total_price}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{currencyDecorator(details.amount)}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.discount}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>{details.discount || 0}%</h4>
				</div>
				{details.voucher ? (
					<div
						className="participant-row-container"
						style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.voucher}
						</h4>
						<h4 style={{ fontWeight: 'normal' }}>{details.voucher}</h4>
					</div>
				) : null}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.date_created}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{DateFormatHelper.monthYearTimeFormat(details.created_at)}
					</h4>
				</div>
				{/*<div*/}
				{/*	className="participant-row-container"*/}
				{/*	style={{ justifyContent: 'space-between', marginTop: '24px' }}*/}
				{/*>*/}
				{/*	<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>*/}
				{/*		{dictionary.notes}*/}
				{/*	</h4>*/}
				{/*	<h4 style={{ fontWeight: 'normal' }}>*/}
				{/*		{details.notes ?? dictionary.no_notes}*/}
				{/*	</h4>*/}
				{/*</div>*/}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px'), gap: '5px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.access}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{details.slot.is_private_tour
							? dictionary.private
							: dictionary.public}
					</h4>
				</div>
			</div>
		</div>
	);
};

BookingExperienceDetails.propTypes = {
	details: PropTypes.object.isRequired,
};

export default BookingExperienceDetails;
