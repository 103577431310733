// import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PayoutsOverview from './PayoutsOverview';
import ReportsOverview from './ReportsOverview';
import { getFinancialOverview } from '../networking';
import { useEffect, useState } from 'react';
import SimpleLoader from '../../../common/SimpleLoader';
import React from 'react';
import PropTypes from 'prop-types';

const FinancialOverview = ({providerInfo}) => {
	// const dictionary = useDictionary();

	const [showLoader, setLoaderVisibility] = useState(false);
	const [overviewResult, setOverviewResult] = useState(null);

	useEffect(() => {
		let result = new Object;
		result['pending_balance'] = providerInfo.pending_balance;
		result['available_balance'] = providerInfo.available_balance;
		setOverviewResult(result);
	}, []);

	return (
		<div className="home-payouts-container thin-scrollbar">
			<PayoutsOverview
				result={overviewResult}
				setShowLoader={setLoaderVisibility}
				providerInfo={providerInfo}
			/>
			{/*<ReportsOverview/>*/}

			<SimpleLoader showLoader={showLoader} />
		</div>
	);
};

FinancialOverview.propTypes = {
	providerInfo: PropTypes.object
};

export default FinancialOverview;
