import { ENVIRONMENT } from '../../config';
import React from 'react';

export const DataTableHelper = (function () {
	function prepareParams(data) {
		let variables = '';
		let orderOnce = false;
		if (data) {
			const { columns } = data;
			const query = ['draw=1', 'start=' + data.start, 'length=' + data.length];
			for (const dataField in columns) {
				const { id, order, search, queryField } = columns[dataField];
				if (search != null) {
					let search_query = '';
					if (Array.isArray(search)) {
						search_query =
							'columns[' +
							id +
							'][search][value]=' +
							search[0] +
							',' +
							search[1];
					} else if (typeof search === 'object') {
						if (search.date) {
							let date =
								search.date.getFullYear() +
								'-' +
								(search.date.getMonth() + 1) +
								'-' +
								(search.date.getDate() < 9 ? '0' : '') +
								search.date.getDate();
							//search_query='columns[' + id + '][search][value]=' + search.date.getTime()/1000;
							search_query = 'columns[' + id + '][search][value]=' + date;
						}
					} else {
						search_query = 'columns[' + id + '][search][value]=' + search;
					}
					query.push(search_query);
				}

				if (order !== false) {
					orderOnce = true;
					const order_column = 'order[0][column]=' + id;
					const order_dir = 'order[0][dir]=' + order;
					query.push(order_column);
					query.push(order_dir);
				}

				const data_query =
					'columns[' + id + '][data]=' + (queryField ? queryField : dataField);
				query.push(data_query);
			}

			if (!orderOnce) {
				const order_column = 'order[0][column]=0';
				const order_dir = 'order[0][dir]=desc';
				query.push(order_column);
				query.push(order_dir);
			}

			variables = ENVIRONMENT === 'production' ? '?' + query.join('&') : '';
		}
		return variables;
	}

	function prepareParameters(
		sortOrder,
		pageSize,
		pageNumber,
		channelId,
		searchParam = '',
		createDateParam = '',
		filterStatusParams = [],
		filterPaymentStatusParams = [],
		providerIds = [],
		excludeExperienceIds = [],
		isAdmin= false
	) {
		let variables = '';
		const query = [];
		if (sortOrder) {
			const order_column = 'columns[0][data]=' + sortOrder.value;
			const order_dir = 'order[0][dir]=' + sortOrder.direction;
			query.push(order_column);
			query.push(order_dir);
			query.push('order[0][column]=0');
		}

		if (searchParam !== '' && searchParam !== null) {
			query.push('q=' + searchParam);
		}

		let lastColumn = 0;
		if (createDateParam !== '' && createDateParam !== null) {
			if (sortOrder.value === 'created_at') {
				query.push('columns[0][search][value]=' + createDateParam);
				lastColumn = 0;
			} else {
				query.push('columns[1][data]=created_at');
				query.push('columns[1][search][value]=' + createDateParam);
				lastColumn = 1;
			}
		}
		if (filterStatusParams.length > 0) {
			const statusColumn = 'columns[' + (lastColumn + 1) + '][data]=status';
			query.push(
				'columns[' +
					(lastColumn + 1) +
					'][search][value]=' +
					filterStatusParams.join(',')
			);
			lastColumn += 1;
			query.push(statusColumn);
		}

		if (filterPaymentStatusParams.length > 0) {
			const paymentStatusColumn = 'columns[' + (lastColumn + 1) + '][data]=payment_status';
			query.push(
				'columns[' +
					(lastColumn + 1) +
					'][search][value]=' +
					filterPaymentStatusParams.join(',')
			);
			lastColumn += 1;
			query.push(paymentStatusColumn);
		}

		let providerIdsParam = '';
		if (providerIds.length > 0) {
			providerIds.map(providerId => {
				providerIdsParam += providerId + ',';
			});
			providerIdsParam = providerIdsParam.slice(0, -1);
		}

		if (providerIdsParam !== '') {
			query.push('providers=' + providerIdsParam);
		}

		let excludeExperienceIdsParam = '';
		if (excludeExperienceIds.length > 0) {
			excludeExperienceIds.map(excludeExperienceIds => {
				excludeExperienceIdsParam += excludeExperienceIds + ',';
			});
			excludeExperienceIdsParam = excludeExperienceIdsParam.slice(0, -1);
		}

		if (excludeExperienceIdsParam !== '') {
			query.push('exclude_experience_ids=' + excludeExperienceIdsParam);
		}
		
		if (pageSize) {
			query.push('length=' + pageSize);
		}

		if (pageNumber != null) {
			query.push('start=' + pageNumber * pageSize);
		}

		if (channelId != null) {
			query.push('channelId=' + channelId);
		}

		if (query.length > 0) {
			variables = '?' + query.join('&');
		}

		return variables;
	}

	return {
		prepareParams: function (date) {
			return prepareParams(date);
		},
		prepareParameters: function (
			sortOrder,
			pageSize,
			pageNumber,
			channelId,
			searchParam,
			createDateParam,
			filterStatusParams,
			filterPaymentStatusParams,
			providerIds,
			excludeExperienceIds,
			isAdmin
		) {
			return prepareParameters(
				sortOrder,
				pageSize,
				pageNumber,
				channelId,
				searchParam,
				createDateParam,
				filterStatusParams,
				filterPaymentStatusParams,
				providerIds,
				excludeExperienceIds,
				isAdmin
			);
		}
	};
})();
