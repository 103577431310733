import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import Swal from 'sweetalert2';
import { useAccount } from '../../../../../../utilities/hooks/UseAccount';
import { toast } from 'react-toastify';
import CustomToast from '../../../../common/CustomToast';
import {AmountFormatHelper} from "../../../../../../utilities/helpers/AmountFormatHelper";

let isFirstRender = true;

const Reselling = ({
	setEnabled,
	isReselling,
	setAllowReselling,
	resellerCommission,
	setResellerCommission,
	upiriaCommission
}) => {
	const dictionary = useDictionary();
	const account = useAccount();
	const isVerified = account.verification_status.value === '3';

	const onRadioChanged = (e) => {
		setEnabled(true);
		if (e.target.value === 'hide') {
			setAllowReselling(false);
		} else {
			setAllowReselling(true);
		}
	};

	const onChange = (e) => {
		setResellerCommission(e.target.value * 100);
	};

	useEffect(() => {
		setEnabled(true);
		isFirstRender = false;
	}, []);

	useEffect(() => {
		if (!isVerified && !isFirstRender) {
			toast.info(
				<CustomToast message={dictionary.unverified_provider_warning} />,
				{
					position: 'bottom-left',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				}
			);
		}
	}, [isVerified]);

	return (
		<div className="new-experience-form-wrapper">
			{!isVerified && <div className="new-experience-form-layer" />}
			<div className="new-experience-form-container thin-scrollbar">
				<h2 style={{ fontSize: '24px' }}>{dictionary.reselling}</h2>
				<div style={{ marginTop: '30px', opacity: 0.8 }} className="text">
					{dictionary.reselling_your_experience}
				</div>
				<label style={{ marginTop: '32px' }} className="radio-label">
					{dictionary.enable_reselling}
					<input
						onChange={onRadioChanged}
						checked={isReselling}
						value="allow"
						type="radio"
						disabled={!isVerified}
						name="allowReselling"
						data-testid="enable-reselling-radio"
					/>
					<span className="checkmark" />
				</label>
				<label className="radio-label">
					{dictionary.disable_reselling}
					<input
						onChange={onRadioChanged}
						checked={isReselling !== undefined && !isReselling}
						value="hide"
						type="radio"
						disabled={!isVerified}
						name="allowReselling"
						data-testid="disable-reselling-radio"
					/>
					<span className="checkmark" />
				</label>
				<div style={{ display: isReselling ? 'block' : 'none' }}>
					<h3 style={{ fontSize: '16px', marginTop: '40px' }}>
						{dictionary.commissions}
					</h3>
					<div
						className="group-inputs d-flex"
						style={{
							marginTop: 16,
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: 16
						}}
					>
						<h4 style={{ fontWeight: 400 }}>
							{dictionary.resellers_commission}
						</h4>
						<div className="percent-input-container">
							<input
								type="number"
								min={1}
								max={50}
								value={resellerCommission / 100}
								onChange={onChange}
								disabled={!isVerified}
								className="percent-input"
								data-testid="reseller-commission-input"
							/>
						</div>
					</div>
					<div
						className="group-inputs d-flex"
						style={{
							marginTop: '0',
							alignItems: 'center',
							justifyContent: 'space-between',
							marginBottom: '0'
						}}
					>
						<h4 style={{ fontWeight: 400 }}>{dictionary.upiria_commission}</h4>
						<div className="percent-input-container">
							<input
								disabled
								value={AmountFormatHelper.formatAmount(upiriaCommission)}
								type="number"
								className="percent-input"
								data-testid="upiria-commission"
							/>
						</div>
					</div>
					<div
						style={{ marginTop: '16px', opacity: 0.6 }}
						className="terms-text"
					>
						{dictionary.resell_an_experience}
					</div>
				</div>
			</div>
		</div>
	);
};

Reselling.propTypes = {
	setEnabled: PropTypes.func,
	isReselling: PropTypes.bool,
	setAllowReselling: PropTypes.func,
	resellerCommission: PropTypes.number,
	setResellerCommission: PropTypes.func,
	upiriaCommission: PropTypes.number,
};

export default Reselling;
