import React, { useState } from 'react';
import showPwdImg from '../../../../assets/icons/show_password.svg';
import hidePwdImg from '../../../../assets/icons/hide_password.svg';

function ShowHidePassword(
	name,
	label,
	placeholder,
	required,
	formik,
	isLogin = false
) {
	const [isRevealPwd, setIsRevealPwd] = useState(false);

	return (
		<div className="pwd-container">
			<div
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
				</label>
				<input
					className="grey-text"
					name={name}
					type={isRevealPwd ? 'text' : 'password'}
					placeholder={placeholder}
					onChange={formik.handleChange}
					data-testid={name + '-input'}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
				{formik.errors[name] && formik.touched[name] ? (
					<span style={{ top: '72px', left: 0 }} className="error-tooltip">
						{formik.errors[name]}
					</span>
				) : null}
			</div>
			<img
				className={isLogin ? 'pwd-container-login-img' : 'pwd-container-img'}
				title={isRevealPwd ? 'Hide password' : 'Show password'}
				src={isRevealPwd ? hidePwdImg : showPwdImg}
				onClick={() => setIsRevealPwd((prevState) => !prevState)}
			/>
		</div>
	);
}

export default ShowHidePassword;
