import PropTypes from 'prop-types';
import {useDictionary} from "../../../../../utilities/hooks/UseDictionary";
import ConverterHelper from "../../../../../utilities/helpers/Converters";

/**
 * Pass null for optional
 *
 * @param category
 * @param location
 * @param language
 * @param duration
 * @param capacity
 * @param childFriendly
 * @param accessible
 * @returns {JSX.Element}
 * @constructor
 */
const ResaleExperienceDetails = ({
    category,
    location,
    language,
    duration,
    capacity,
    child_friendly,
    accessible,
}) => {
    const dictionary = useDictionary();

    const isSet = (value) => {
        return !(value === undefined || value === null);
    }

    return (
        <div className="resale-collection-item-experience-detail">
            <div className="resale-collection-item-experience-detail-label">
                {dictionary.details}
            </div>
            <div className="resale-collection-item-experience-detail-list thin-scrollbar">
                {(category.length > 0)?
                    <div key={'category'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.category}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {category.join(', ')}
                        </div>
                    </div>
                :null}
                {(location) ?
                    <div key={'location'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.location}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {location}
                        </div>
                    </div>
                :null}
                {(language.length > 0)?
                    <div key={'language'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.language}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            English, German, French, Greek
                        </div>
                    </div>
                :null}
                {(duration)?
                    <div key={'duration'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.duration}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {ConverterHelper.secondsToDayHourMinute(duration, dictionary)}
                        </div>
                    </div>
                :null}
                {(capacity)?
                    <div key={'capacity'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.capacity}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {ConverterHelper.participantsLabel(capacity, dictionary)}
                        </div>
                    </div>
                :null}
                {(isSet(child_friendly))?
                    <div key={'child_friendly'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.child_friendly}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {ConverterHelper.booleanToLabel(child_friendly, dictionary)}
                        </div>
                    </div>
                :null}
                {(isSet(accessible))?
                    <div key={'accessible'} className="resale-collection-item-experience-detail-list-item">
                        <div className="resale-collection-item-experience-detail-list-item-label">
                            {dictionary.accessible}
                        </div>
                        <div className="resale-collection-item-experience-detail-list-item-text">
                            {ConverterHelper.booleanToLabel(accessible, dictionary)}
                        </div>
                    </div>
                :null}
            </div>
        </div>
    );
}

ResaleExperienceDetails.prototype = {
    category: PropTypes.array,
    location: PropTypes.string,
    language: PropTypes.array,
    duration: PropTypes.string,
    capacity: PropTypes.string,
    childFriendly: PropTypes.bool,
    accessible: PropTypes.bool,
};

export default ResaleExperienceDetails;