import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import '../../../../../assets/css/applications.css';
import PendingApplicationItem from './PendingApplicationItem';
import React, { useEffect, useState } from 'react';
import { getProviders } from './networking';
import DialogLoader from '../../../common/DialogLoader';
import SortOptionsSelector from '../../../common/sort_options/SortOptionsSelector';
import ProviderDetail from './provider_details/ProviderDetail';
import { NavigatorPaths } from '../../../../../assets/dictionaries/NavigatorPaths';
import { useHistory, useLocation } from 'react-router-dom';

const ProviderApplications = () => {
	const dictionary = useDictionary();
	const [providers, setProviders] = useState([]);
	const [showLoader, setLoaderVisibility] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [showDetail, setShowDetail] = useState(false);
	const [selectedProvider, setSelectedProvider] = useState(null);
	const history = useHistory();

	useEffect(() => {
		if (selectedOption) getProvidersAsync();
	}, [selectedOption]);

	const getProvidersAsync = async () => {
		setLoaderVisibility(true);
		try {
			const { data } = await getProviders(selectedOption);
			setProviders(data.data);
			if(history.location.search) {
				const id = history.location.search.split('=')[1];

                const provider = data.data.find((provider) => provider.id === id);

                if(provider) {
                    setSelectedProvider(provider);
                    setShowDetail(true);
					setTimeout(()=>{
						setLoaderVisibility(false);
					})
                } else {
					setLoaderVisibility(false);
				}
			} else {
				setLoaderVisibility(false);
			}
		} catch (error) {
			setLoaderVisibility(false);
		}
	};

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};

	const dismissDetails = () => {
		setTimeout(() => {
			setShowDetail(false);
			setSelectedProvider(null);
		}, 300);
		getProvidersAsync();
	};

	const onProviderClicked = (provider) => {
		setSelectedProvider(provider);
		history.push(`/${ NavigatorPaths.applications.path}?id=${provider.id}`);
		setShowDetail(true);
	};

	return (
		<div className="home-container">
			{!showDetail ? (
				<div className="home-content-container">
					<div className="application-top-bar">
						<h2 data-testid="application-title">{dictionary.provider_applications}</h2>
						<div className="application-actions"></div>
						<div className="flex-filler"></div>
						<div className="application-filter-actions">
							<SortOptionsSelector
								onValueChanged={onSortOptionValueChanged}
								name={'providers'}
							/>
						</div>
					</div>
					
					<div className="onboarding-activities-container thin-scrollbar">
						{providers.map((application) => (
							<PendingApplicationItem
								key={application.id}
								setLoaderVisibility={setLoaderVisibility}
								item={application}
								onUpdated={getProvidersAsync}
								onProviderClicked={onProviderClicked}
							/>
						))}
					</div>
				</div>
			) : null}
			{showDetail ? (
				<ProviderDetail
					dismissDetails={dismissDetails}
					id={selectedProvider.id}
				/>
			) : null}
			{showLoader && <DialogLoader showLoader={showLoader} />}
		</div>
	);
};

export default ProviderApplications;
