import '../../../assets/css/tab_selector.css';
import list from '../../../assets/icons/ic_list.svg';
import tiles from '../../../assets/icons/ic_tiles.svg';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ViewTypeSelector = ({ name, onValueChanged }) => {
	const [selectedOption, setSelectedOption] = useState(null);

	useEffect(() => {
		setSelectedOption(retrieveSavedOption());
	}, []);

	const retrieveSavedOption = () => {
		let option = localStorage.getItem(name + '_selected_view_type');
		if (!option) {
			return 0;
		}
		return parseInt(option);
	};

	const saveSelectedOption = (option) => {
		localStorage.setItem(name + '_selected_view_type', option);
	};

	const onTabClicked = (index) => {
		setSelectedOption(index);
		saveSelectedOption(index);
		if (onValueChanged) {
			onValueChanged(index);
		}
	};

	return (
		<div className="tab-selector-container">
			<div
				onClick={() => onTabClicked(0)}
				className={
					selectedOption === 0
						? 'tab-selector-tab tab-selector-tab-left tab-selector-tab-active'
						: 'tab-selector-tab tab-selector-tab-left'
				}
				data-testid="list-tab"
			>
				<img src={list} />
			</div>
			<div
				onClick={() => onTabClicked(1)}
				className={
					selectedOption === 1
						? 'tab-selector-tab tab-selector-tab-right tab-selector-tab-active'
						: 'tab-selector-tab tab-selector-tab-right'
				}
				data-testid="tiles-tab"
			>
				<img src={tiles} />
			</div>
		</div>
	);
};

ViewTypeSelector.propTypes = {
	name: PropTypes.string,
	onValueChanged: PropTypes.func
};

export default ViewTypeSelector;
