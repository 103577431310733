import PropTypes from 'prop-types';
import {useDictionary} from "../../../../../utilities/hooks/UseDictionary";

import ConverterHelper from "../../../../../utilities/helpers/Converters";
import {TextFormatHelper} from "../../../../../utilities/helpers/TextFormatHelper";
import {CURRENCY} from "../../../../../config";
import ResaleImageCollage from "./ResaleImageCollage";

const ExperienceListItemCard = ({
    isResaleCollection,
    experience,
    HeaderItems,
    ActionButtons
}) => {

    const dictionary = useDictionary();

    // todo check
    // resale-collection-card-item-experience-block-detail-text
    return (
        <div className="resale-collection-item-card-container">
            <div className="resale-collection-card-item-experience-block">
                <div className="resale-collection-card-item-experience-block-detail">
                    <HeaderItems/>
                    <div className="resale-collection-card-item-experience-block-detail-text">
                        {experience.experience_title}
                    </div>
                </div>
                <ActionButtons/>
            </div>
            <div className="resale-collection-card-item-experience-pricing">
                <div className="resale-collection-card-item-experience-pricing-commission">
                    <div className="resale-collection-card-item-experience-pricing-commission-text">
                        {ConverterHelper.normalizeCommission(experience.reseller_commission)}%
                    </div>
                    <div className="resale-collection-card-item-experience-pricing-commission-label">
                        {dictionary.commission}
                    </div>
                </div>
                <div className="resale-collection-card-item-experience-pricing-line"></div>
                <div className="resale-collection-card-item-experience-pricing-list">
                    {experience.participant_groups.map((group) => (
                        <div key={`commission-${group.id}`} className="resale-collection-card-item-experience-pricing-list-item">
                            <div className="resale-collection-card-item-experience-pricing-list-item-label">
                                {TextFormatHelper.toUpperCaseFirstLetter(group.name)}
                            </div>
                            <div className="resale-collection-card-item-experience-pricing-list-item-text">
                                {ConverterHelper.calculateCommissionAmount(
                                    group.rate,
                                    experience.reseller_commission
                                )}
                                {CURRENCY}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ResaleImageCollage isResaleCollection={isResaleCollection} assets={experience.assets}/>
        </div>
    );
}

ExperienceListItemCard.prototype = {
    experience: PropTypes.object.isRequired,
    HeaderItems: PropTypes.element,
    ActionButtons: PropTypes.element
};

export default ExperienceListItemCard;
