import axios from 'axios';
import {
	// EXPERIENCE_URL,
	PROVIDER_EXPERIENCE_CATEGORIES_URL,
	PROVIDERS_EXPERIENCES_URL,
	PROVIDERS_ADMIN_EXPERIENCES_URL,
	PROVIDERS_URL,
	PROVIDER_GROUPS

} from '../../../../config';
import { DataTableHelper } from '../../../../utilities/helpers/DataTableHelper';

export function getExperienceTempId() {
	return axios.post(PROVIDERS_EXPERIENCES_URL);
}

export function getExperienceAdminTempId() {
	return axios.post(PROVIDERS_ADMIN_EXPERIENCES_URL);
}


export function getAllExperiences(
	sortParam,
	pageSize,
	pageNumber,
	cancelToken,
	providerId = ''
) {
	return axios.get(
		PROVIDERS_EXPERIENCES_URL +
			DataTableHelper.prepareParameters(sortParam, pageSize, pageNumber, null, '', '', [], providerId),
		{ cancelToken: cancelToken }
	);
}

export function getAllAdminExperiences(
	sortParam,
	pageSize,
	pageNumber,
	cancelToken,
	providerIds = []
) {
	return axios.get(
		PROVIDERS_ADMIN_EXPERIENCES_URL +
		DataTableHelper.prepareParameters(sortParam, pageSize, pageNumber, null, '', '', [], providerIds),
		{ cancelToken: cancelToken }
	);
}


export function getAvailableExperiencesList(cancelToken) {
	return axios.get(PROVIDERS_EXPERIENCES_URL + '/available', {
		cancelToken: cancelToken
	});
}

export function getAvailableAndPauseExperiencesList(cancelToken) {
	return axios.get(PROVIDERS_EXPERIENCES_URL + '/available-and-pause', {
		cancelToken: cancelToken
	});
}	

export function getExperienceById(id) {
	return axios.get(PROVIDERS_EXPERIENCES_URL + '/' + id);
}

export function deleteExperience(id) {
	return axios.delete(PROVIDERS_EXPERIENCES_URL + '/' + id);
}

export function changeExperienceStatus(experienceId, newStatus) {
	return axios.put(`${PROVIDERS_EXPERIENCES_URL}\\${experienceId}\\status`, {
		status_id: newStatus
	});
}

export function updateExperience(id, data) {
	return axios
		.put(PROVIDERS_EXPERIENCES_URL + '/' + id, data)
		.catch((error) => {
			return error.response;
		});
}

export function getExperienceCategories() {
	return axios.get(PROVIDER_EXPERIENCE_CATEGORIES_URL);
}

export function getExperienceAssets(experienceId) {
	return axios.get(`${PROVIDERS_EXPERIENCES_URL}\\${experienceId}\\assets`);
}

export function uploadExperienceAsset(experienceId, file) {
	const formData = new FormData();

	formData.append('file', file, file.name);
	return axios
		.post(`${PROVIDERS_EXPERIENCES_URL}\\${experienceId}\\assets`, formData)
		.catch((error) => {
			return error.response;
		});
}

export function deleteExperienceAsset(experienceId, assetId) {
	return axios.delete(
		`${PROVIDERS_EXPERIENCES_URL}\\${experienceId}\\assets\\${assetId}`
	);
}

export function getProviders() {
	return axios.get(PROVIDERS_URL);
}

export function getProviderGroups(
	sortOrder,
	pageSize,
	pageNumber,
	excludeExperienceIds = [],
	searchParam = '',
	createDateParam = '',
	filterStatusParams = [],
	filterPaymentStatusParams = [],
	providerIds = [],
) {
	return axios.get(
		PROVIDER_GROUPS +
		( DataTableHelper.prepareParameters(
			sortOrder,
			// pageSize,
			// pageNumber,
			null,
			searchParam,
			createDateParam,
			filterStatusParams,
			filterPaymentStatusParams,
			providerIds,
			excludeExperienceIds
		)),
	);
}

export function addProviderGroup(groupId,experienceId) {
	return axios.post(`${PROVIDER_GROUPS}\\${groupId}\\experience\\${experienceId}`)
		.catch((error) => {
			return error.response;
		});
}

export function removeProviderGroup(groupId,experienceId) {
	return axios.delete(`${PROVIDER_GROUPS}\\${groupId}\\experience\\${experienceId}`)
		.catch((error) => {
			return error.response;
		});
}