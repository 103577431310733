import PropTypes from 'prop-types';
import React from 'react';

const ExperienceStep = ({ step, setStep, max, activeStep, name }) => {
	const getIcon = () => {
		if (max > step) {
			return <div className="new-experience-step-complete" />;
		} else {
			return (
				<div
					className={
						activeStep === step
							? 'new-experience-step new-experience-step-active'
							: 'new-experience-step'
					}
				>
					{step}
				</div>
			);
		}
	};

	const onStepClicked = () => {
		if (max >= step) {
			setStep(step);
		}
	};

	return (
		<div onClick={onStepClicked} className="new-experience-step-container">
			{getIcon()}
			<div
				style={{ cursor: max >= step ? 'pointer' : 'unset' }}
				className={
					activeStep === step
						? 'new-experience-step-title new-experience-step-title-active'
						: 'new-experience-step-title'
				}
				data-testid={'new-experience-step-title-' + step}
			>
				{name}
			</div>
		</div>
	);
};

ExperienceStep.propTypes = {
	step: PropTypes.number.isRequired,
	setStep: PropTypes.func.isRequired,
	max: PropTypes.number.isRequired,
	activeStep: PropTypes.number.isRequired,
	name: PropTypes.string.isRequired
};

export default ExperienceStep;
