import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import ProvidedItemRow from '../provided_items/ProvidedItemRow';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import React from 'react';

const ItemsToBring = ({
	setEnabled,
	bringItems,
	setBringItems,
	itemsToBring,
	setItemsToBring
}) => {
	const dictionary = useDictionary();

	const onItemChanged = (e, index) => {
		let newItems = Object.assign([], itemsToBring);
		newItems[index] = e.target.value;
		setItemsToBring(newItems);
	};

	const onAddItemClicked = () => {
		let newItems = Object.assign([], itemsToBring);
		newItems.push('');
		setItemsToBring(newItems);
	};

	const onRemoveItemClicked = (item) => {
		let newItems = Object.assign([], itemsToBring);
		ArrayHelper.removeItemOnce(newItems, item);
		setItemsToBring(newItems);
	};

	useEffect(() => {
		if (bringItems === 'no') {
			setEnabled(true);
		} else {
			setEnabled(itemsToBring.length > 0 || itemsToBring.includes([]));
		}
	}, [itemsToBring]);

	const onRadioChanged = (e) => {
		setBringItems(e.target.value);
		if (e.target.value === 'no') {
			setEnabled(true);
			setItemsToBring([]);
		} else {
			setEnabled(itemsToBring.length > 0 || itemsToBring.includes([]));
		}
	};

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2>{dictionary.bring_anything_to_experience}</h2>
				<div style={{ marginTop: '30px' }} className="text">
					{dictionary.select_applies}
				</div>
				<label style={{ marginTop: '25px' }} className="radio-label">
					{dictionary.yes}
					<input
						onChange={onRadioChanged}
						checked={bringItems === 'yes'}
						value="yes"
						type="radio"
						name="language"
						data-testid="yes-radio"
					/>
					<span className="checkmark" />
				</label>
				<label className="radio-label">
					{dictionary.no_just_show_up}
					<input
						onChange={onRadioChanged}
						checked={bringItems === 'no'}
						value="no"
						type="radio"
						name="language"
						data-testid="no-radio"
					/>
					<span className="checkmark" />
				</label>
				<div style={{ display: bringItems === 'yes' ? 'block' : 'none' }}>
					<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
						{dictionary.what_to_bring}
					</h3>
					<div style={{ marginTop: '15px' }} className="text">
						{dictionary.list_will_be_emailed}
					</div>
					<div
						className="provided-items-container"
						style={{ marginTop: '5px' }}
					>
						{itemsToBring.map((item, index) => (
							<ProvidedItemRow
								onRemoveItemClicked={onRemoveItemClicked}
								key={index}
								index={index}
								value={item}
								setValue={onItemChanged}
							/>
						))}
					</div>
					<div
						onClick={onAddItemClicked}
						className="secondary-button"
						data-testid="add-item-button"
					>
						{dictionary.add_another_item}
					</div>
				</div>
			</div>
		</div>
	);
};

ItemsToBring.propTypes = {
	setEnabled: PropTypes.func,
	bringItems: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
	setBringItems: PropTypes.func,
	itemsToBring: PropTypes.array,
	setItemsToBring: PropTypes.func
};

export default ItemsToBring;
