import '../../../../assets/css/experiences.css';
import { ENABLE_RESOURCES } from '../../../../ui_config';
import { detectMobile2 } from '../../../../utilities/helpers/MobileHelper';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import ConfirmationModal from '../../common/ConfirmationModal';
import CustomToast from '../../common/CustomToast';
import FormLoader from '../../common/FormLoader';
import {
	getExperienceById,
	updateExperience
} from '../provider_experiences/networking';
import ExperienceStep from './ExperienceStep';
import BookingSettings from './steps/booking_settings/BookingSettings';
import CancellationPolicy from './steps/cancellation_policy/CancellationPolicy';
import CapacityAndPricing from './steps/capacity_and_pricing/CapacityAndPricing';
import ExperienceCategories from './steps/experience_categories/ExperienceCategories';
import GroupRates from './steps/group_rates/GroupRates';
import ItemsToBring from './steps/items_to_bring/ItemsToBring';
import MeetingPoint from './steps/meeting_point/MeetingPoint';
import NameAndDetails from './steps/name_and_details/NameAndDetails';
import ParticipantRequirements from './steps/participant_requirements/ParticipantRequirements';
import PhotosAndVideos from './steps/photos_and_videos/PhotosAndVideos';
import ProvidedItems from './steps/provided_items/ProvidedItems';
import Reselling from './steps/reselling/Reselling';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import { getLanguages } from './networking';
import { getMatchedCountryData } from '../../../../utilities/helpers/LanguageHelper';
import { countries } from "../../../../data/countries";
import countryCodes from "country-codes-list";

const NewExperience = ({ tempId, tempData, onExperienceSaved, editId }) => {
	const dictionary = useDictionary();

	const [step, setStep] = useState(1);
	const [max, setMax] = useState(0);
	const totalSteps = 12;
	const [showDismissDialog, setShowDismissDialog] = useState(false);
	const [selectedLanguages, setSelectedLanguages] = useState([]);
	const [allLanguages, setAllLanguages] = useState([]);

	const onCancel = () => {
		setShowDismissDialog(false);
	};

	//experience categories
	const [selectedTags, setSelectedTags] = useState([]);

	//name and details
	const [name, setName] = useState('');
	const [plans, setPlans] = useState('');
	const [colorCode, setColorCode] = useState('');
	const [resource, setResource] = useState({ title: dictionary.none, id: -1 });
	const [isEcoFriendly, setIsEcoFriendly] = useState(false);
	const [days, setDays] = useState(0);
	const [hours, setHours] = useState(0);
	const [minutes, setMinutes] = useState(0);
	const isMobile = detectMobile2();
	const [workingHours, setWorkingHours] = useState(
		new Map([
			['Sunday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Monday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Tuesday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Wednesday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Thursday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Friday', { selected: false, hours: [{ from: 0, to: 0 }] }],
			['Saturday', { selected: false, hours: [{ from: 0, to: 0 }] }]
		])
	);

	//meeting point
	const [place, setPlace] = useState(null);
	const [directions, setDirections] = useState('');

	//provided items
	const [providedItems, setProvidedItems] = useState([]);

	//items to bring
	const [bringItems, setBringItems] = useState(false);
	const [itemsToBring, setItemsToBring] = useState([]);

	//participant requirements
	const [minimumAge, setMinimumAge] = useState(0);
	const [childFriendly, setChildFriendly] = useState(false);
	const [childFriendlyReason, setChildFriendlyReason] = useState('');
	const [accessible, setAccessible] = useState(false);
	const [accessibleReason, setAccessibleReason] = useState('');

	//minimum and maximum participants
	const [minParticipants, setMinParticipants] = useState(1);
	const [maxParticipants, setMaxParticipants] = useState(1);
	const [showCapacityValidationError, setShowCapacityValidationError] =
		useState(false);

	//capacity and pricing
	const [participantGroups, setParticipantGroups] = useState([
		{ name: dictionary.adults, rate: 5 }
	]);

	//group rates
	const [groupRates, setGroupRates] = useState([]);

	//booking settings
	const [cutoffTime, setCutoffTime] = useState(0);
	const [allowRequests, setAllowRequests] = useState(true);
	const [isPrivateTour, setIsPrivateTour] = useState(false);
	const [autoConfirm, setAutoConfirm] = useState(true);

	//reselling
	const [isReselling, setIsReselling] = useState(false);
	const [resellerCommission, setResellerCommission] = useState(100);
	const [upiriaCommission, setUpiriaCommission] = useState(1000);

	//cancellation policy
	const [upiriaCancellationPolicy, setUpiriaCancellationPolicy] =
		useState(true);
	const [rememberPolicy, setRememberPolicy] = useState(false);
	const [cancellationPolicyData, setCancellationPolicyData] = useState([
		{ from: '0 Day', to: '-1 Day', refund_percentage: 0 },
		{ from: '-1 Day', to: '-7 Day', refund_percentage: 0.5 },
		{ from: '-7 Day', to: 'now', refund_percentage: 0.95 }
	]);
	const [policyTitle, setPolicyTitle] = useState('');
	const [policyDescription, setPolicyDescription] = useState('');
	const [noRefund, setNoRefund] = useState(false);

	// eslint-disable-next-line no-unused-vars
	const [status, setStatus] = useState({
		id: 2,
		title: 'In progress',
		color: '#F9BB05'
	});
	const [showLoader, setShowLoader] = useState(false);
	const [enabled, setEnabled] = useState(false);

	const setIncrementalStep = (index) => {
		setStep(index);
		if (index > max) {
			setMax(index);
		}
	};

	const initializeData = (data) => {
		if (data?.latitude !== null && data?.longitude !== null) {
			setPlace(transformLocation(data?.latitude, data?.longitude));
		}
		setIsPrivateTour(data?.is_private_tour);
		setAllowRequests(data?.step >= 11 ? data?.allow_requests : true);
		setAutoConfirm(data?.step >= 11 ? data?.auto_confirm : true);
		if (data?.color) {
			setColorCode(data?.color);
		}
		if (data?.description) {
			setPlans(data?.description);
		}
		setResource(data?.resource);
		setIsEcoFriendly(data?.is_eco_friendly);
		setProvidedItems(data?.provided_items);
		setBringItems(data?.needed_items?.length > 0 ? 'yes' : 'no');
		setItemsToBring(data?.needed_items);
		if (data?.title) {
			setName(data?.title);
		}
		setDirections(data?.directions);
		setGroupRates(
			data?.group_rates?.map((item) => ({
				...item,
				participantPrice:
					(1 - +item.discount / 100) *
					(data?.participant_groups ? data.participant_groups[0].rate : 0)
			}))
		);
		if (data?.minimum_age) {
			setMinimumAge(data?.minimum_age);
		}
		setChildFriendly(data?.is_child_friendly);
		if (data?.child_friendly_reason) {
			setChildFriendlyReason(data?.child_friendly_reason);
		}
		setAccessible(data?.is_accessible);
		if (data?.accessible_reason) {
			setAccessibleReason(data?.accessible_reason);
		}
		setCutoffTime(+data?.cutoff_time);
		setStatus(data?.status);
		if (data?.step) {
			setStep(data?.step);
		}
		setMax(data?.step);
		if (data?.tags) {
			setSelectedTags(transformTags(data?.tags));
		}
		setDays(parseInt(data?.duration / 86400));
		setHours(parseInt(parseInt(data?.duration % 86400) / 3600));
		setMinutes(parseInt(parseInt(data?.duration % 86400) % 3600) / 60);
		if (editId) {
			setUpiriaCancellationPolicy(data?.has_upiria_cancellation_policy);
		}
		if (data?.minimum_group_size) {
			setMinParticipants(data?.minimum_group_size);
		}
		if (data?.maximum_group_size) {
			setMaxParticipants(data?.maximum_group_size);
		}
		if (data?.participant_groups) {
			setParticipantGroups(data?.participant_groups);
		}
		setIsReselling(data?.is_reselling);
		if (data?.reseller_commission) {
			setResellerCommission(data?.reseller_commission);
		}
		setUpiriaCommission(data?.upiria_commission);
		if (data?.cancellation_policy_title) {
			setPolicyTitle(data?.cancellation_policy_title);
		}
		if (data?.cancellation_policy_description) {
			setPolicyDescription(data?.cancellation_policy_description);
		}
		if (data?.cancellation_policy_data) {
			setCancellationPolicyData(JSON.parse(data?.cancellation_policy_data));
		}
		if (
			data?.cancellation_policy_data === null &&
			data?.has_upiria_cancellation_policy === false
		) {
			setNoRefund(true);
		}
		if (data?.languages) {
			setSelectedLanguages(data.languages.map((language) => language?.language_id?.toString()));
		}
	};

	const updateExperienceAsync = async (
		completed = false,
		saveOnNextClicked = false
	) => {
		let dataNew = {
			title: name,
			description: plans,
			latitude: place?.geometry?.location?.lat(),
			longitude: place?.geometry?.location?.lng(),
			directions: directions,
			color: colorCode,
			is_eco_friendly: isEcoFriendly,
			duration: days * 86400 + hours * 3600 + minutes * 60,
			category_tags: selectedTags,
			provided_items: providedItems,
			needed_items: itemsToBring,
			participant_groups: participantGroups,
			group_rates: groupRates,
			minimum_age: minimumAge,
			is_child_friendly: childFriendly,
			child_friendly_reason: childFriendlyReason,
			is_accessible: accessible,
			accessible_reason: accessibleReason,
			minimum_group_size: minParticipants,
			maximum_group_size: maxParticipants,
			cutoff_time: cutoffTime,
			allow_requests: allowRequests,
			is_private_tour: isPrivateTour,
			auto_confirm: autoConfirm,
			has_upiria_cancellation_policy: upiriaCancellationPolicy,
			is_reselling: isReselling,
			reseller_commission: resellerCommission,
			cancellation_policy_title: policyTitle,
			cancellation_policy_description: policyDescription,
			cancellation_policy_remember: rememberPolicy,
			cancellation_policy_data: noRefund
				? null
				: JSON.stringify(cancellationPolicyData),
			step: step,
			working_hours: transformWorkingHours(workingHours),
			languages: selectedLanguages
		};
		if (completed) {
			dataNew.completed = true;
		}

		if (resource?.id !== -1 && ENABLE_RESOURCES) {
			dataNew.resource_id = resource?.id;
		}

		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await updateExperience(
				editId !== '' ? editId : tempId,
				dataNew
			);
			setShowLoader(false);
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty('error_code')) {
				toast.info(<CustomToast message={data.message} />, {
					position: 'bottom-left',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				});
			} else if (!saveOnNextClicked) {
				onExperienceSaved();
			}
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getExperienceAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getExperienceById(editId);
			initializeData(data);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getLanguagesAsync = async () => {
		try {
			let { data } = await getLanguages();
			setAllLanguages(data.filter(({ code }) => !!getMatchedCountryData(code)));
		} catch (e) {
			console.log(e);
		}
	};

	const transformWorkingHours = () => {
		let newHours = [];
		let index = 0;
		workingHours.forEach((item) => {
			if (item.selected) {
				let newDay = {};
				newDay.day = index;
				newDay.hours = item.hours;
				newHours.push(newDay);
			}
			index++;
		});
		return newHours;
	};

	const transformTags = (tags) => {
		let newTags = [];
		tags.map((tag) => newTags.push(tag?.categories_tag));
		return newTags;
	};

	const transformLocation = (lat, lon) => {
		let newPlace = {};
		newPlace['geometry'] = {};
		newPlace.geometry['location'] = {};
		newPlace.geometry.location.lat = function () {
			return lat;
		};
		newPlace.geometry.location.lng = function () {
			return lon;
		};
		return newPlace;
	};

	const stepsMap = {
		1: (
			<ExperienceCategories
				setEnabled={setEnabled}
				enabled={enabled}
				selectedTags={selectedTags}
				setSelectedTags={setSelectedTags}
			/>
		),
		2: (
			<NameAndDetails
				setEnabled={setEnabled}
				enabled={enabled}
				name={name}
				setName={setName}
				plans={plans}
				setPlans={setPlans}
				colorCode={colorCode}
				setColorCode={setColorCode}
				days={days}
				setDays={setDays}
				hours={hours}
				setHours={setHours}
				minutes={minutes}
				setMinutes={setMinutes}
				workingHours={workingHours}
				setWorkingHours={setWorkingHours}
				resource={resource}
				setResource={setResource}
				allLanguages={allLanguages}
				selectedLanguages={selectedLanguages}
				setSelectedLanguages={setSelectedLanguages}
				isEcoFriendly={isEcoFriendly}
				setIsEcoFriendly={setIsEcoFriendly}
			/>
		),
		3: (
			<MeetingPoint
				directions={directions}
				setDirections={setDirections}
				setEnabled={setEnabled}
				enabled={enabled}
				place={place}
				setPlace={setPlace}
				setStep={setStep}
			/>
		),
		4: (
			<ProvidedItems
				setEnabled={setEnabled}
				enabled={enabled}
				providedItems={providedItems}
				setProvidedItems={setProvidedItems}
				setStep={setStep}
			/>
		),
		5: (
			<ItemsToBring
				setEnabled={setEnabled}
				enabled={enabled}
				bringItems={bringItems}
				setBringItems={setBringItems}
				itemsToBring={itemsToBring}
				setItemsToBring={setItemsToBring}
				setStep={setStep}
			/>
		),
		6: (
			<ParticipantRequirements
				setEnabled={setEnabled}
				enabled={enabled}
				minimumAge={minimumAge}
				setMinimumAge={setMinimumAge}
				childFriendly={childFriendly}
				setChildFriendly={setChildFriendly}
				childFriendlyReason={childFriendlyReason}
				setChildFriendlyReason={setChildFriendlyReason}
				accessible={accessible}
				accessibleReason={accessibleReason}
				setAccessibleReason={setAccessibleReason}
				setAccessible={setAccessible}
				setStep={setStep}
			/>
		),
		7: (
			<PhotosAndVideos
				tempId={tempId}
				editId={editId}
				setEnabled={setEnabled}
				enabled={enabled}
				setStep={setStep}
			/>
		),
		8: (
			<CapacityAndPricing
				setEnabled={setEnabled}
				minimumAge={minimumAge}
				minParticipants={minParticipants}
				setMinParticipants={setMinParticipants}
				maxParticipants={maxParticipants}
				setMaxParticipants={setMaxParticipants}
				participantGroups={participantGroups}
				setParticipantGroups={setParticipantGroups}
				showCapacityValidationError={showCapacityValidationError}
				setShowCapacityValidationError={setShowCapacityValidationError}
				setStep={setStep}
			/>
		),
		9: (
			<GroupRates
				maxValue={maxParticipants}
				minValue={minParticipants}
				setEnabled={setEnabled}
				enabled={enabled}
				participants={participantGroups}
				groupRates={groupRates}
				setGroupRates={setGroupRates}
				setStep={setStep}
			/>
		),
		10: (
			<Reselling
				setEnabled={setEnabled}
				isReselling={isReselling}
				setAllowReselling={setIsReselling}
				resellerCommission={resellerCommission}
				setResellerCommission={setResellerCommission}
				upiriaCommission={upiriaCommission}
			/>
		),
		11: (
			<BookingSettings
				setEnabled={setEnabled}
				enabled={enabled}
				cutoffTime={cutoffTime}
				setCutoffTime={setCutoffTime}
				allowRequests={allowRequests}
				setAllowRequests={setAllowRequests}
				isPrivateTour={isPrivateTour}
				setIsPrivateTour={setIsPrivateTour}
				autoConfirm={autoConfirm}
				setAutoConfirm={setAutoConfirm}
				setStep={setStep}
			/>
		),
		12: (
			<CancellationPolicy
				setEnabled={setEnabled}
				enabled={enabled}
				upiriaCancellationPolicy={upiriaCancellationPolicy}
				setUpiriaCancellationPolicy={setUpiriaCancellationPolicy}
				rememberPolicy={rememberPolicy}
				setRememberPolicy={setRememberPolicy}
				noRefund={noRefund}
				setNoRefund={setNoRefund}
				cancellationPolicyData={cancellationPolicyData}
				setCancellationPolicyData={setCancellationPolicyData}
				policyTitle={policyTitle}
				setPolicyTitle={setPolicyTitle}
				policyDescription={policyDescription}
				setPolicyDescription={setPolicyDescription}
				setStep={setStep}
			/>
		)
	};

	const onNextClicked = async () => {
		if (enabled) {
			switch (step) {
				case 2: {
					await updateExperienceAsync(false, true);
					setIncrementalStep(step + 1);
					break;
				}

				case 8: {
					if (minParticipants > maxParticipants) {
						setShowCapacityValidationError(true);
					} else {
						setIncrementalStep(step + 1);
					}
					break;
				}

				case 9: {
					if (groupRates?.find(({ discount }) => discount === 0)) {
						setGroupRates(
							groupRates.map((rate) => ({
								...rate,
								isInvalid: rate.discount === 0
							}))
						);
					} else {
						setIncrementalStep(step + 1);
					}
					break;
				}

				case totalSteps: {
					await updateExperienceAsync(true);
					break;
				}

				default: {
					setIncrementalStep(step + 1);
				}
			}
		}
	};

	const onPreviousClicked = () => {
		setIncrementalStep(step - 1);
	};

	const onSaveClicked = async () => {
		if (max === 0) {
			onExperienceSaved();
		} else {
			await updateExperienceAsync();
		}
	};

	const onCloseClicked = () => {
		//if user is on step 2 or further, show the dialog
		if (step > 1) {
			setShowDismissDialog(true);
		} else {
			onExperienceSaved();
		}
	};

	useEffect(() => {
		const onPopState = (event) => {
			event.preventDefault();
			setShowDismissDialog(true);
			// Push a new state to the history stack, so the user can press back again
			history.pushState(null, document.title, location.href);
		};

		window.addEventListener('popstate', onPopState);

		// Push the current state into the history stack to prevent the user from going back immediately
		history.pushState(null, document.title, location.href);

		return () => {
			window.removeEventListener('popstate', onPopState);
		};
	}, []);

	useEffect(async () => {
		if (editId) {
			await getExperienceAsync();
		}
		await getLanguagesAsync();
	}, []);

	useEffect(() => {
		if (tempData) {
			initializeData(tempData);
		}
	}, [tempData]);

	return (
		<div className="new-experience-wrapper">
			<div className="new-experience-nav-wrapper">
				<div
					className="round-close-button-white new-experience-close-button"
					onClick={onCloseClicked}
				/>
				<div className="new-experience-nav-container">
					<div className="new-experience-nav-top-container">
						<div className="new-experience-nav-top-logo" />
						<div
							onClick={onSaveClicked}
							className="sidebar-button"
							data-testid="save-experience-button"
						>
							{max === 0 ? dictionary.exit : dictionary.save_and_exit}
						</div>
					</div>
					<h2
						className="new-experience-nav-title mobile-invisible"
						style={{ color: 'white' }}
					>
						{editId
							? dictionary.edit_experience
							: dictionary.create_new_experience}
					</h2>
					<div className="new-experience-nav-items-container thin-scrollbar">
						<ExperienceStep
							step={1}
							max={max}
							activeStep={step}
							name={dictionary.category}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={2}
							max={max}
							activeStep={step}
							name={dictionary.name_and_details}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={3}
							max={max}
							activeStep={step}
							name={dictionary.meeting_point}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={4}
							max={max}
							activeStep={step}
							name={dictionary.provided_items}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={5}
							max={max}
							activeStep={step}
							name={dictionary.items_to_bring}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={6}
							max={max}
							activeStep={step}
							name={dictionary.participant_requirements}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={7}
							max={max}
							activeStep={step}
							name={dictionary.photos_and_videos}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={8}
							max={max}
							activeStep={step}
							name={dictionary.capacity_and_pricing}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={9}
							max={max}
							activeStep={step}
							name={dictionary.group_rates}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={10}
							max={max}
							activeStep={step}
							name={dictionary.reselling}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={11}
							max={max}
							activeStep={step}
							name={dictionary.booking_settings}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={12}
							max={max}
							activeStep={step}
							name={dictionary.cancellation_policy}
							setStep={setIncrementalStep}
						/>
					</div>
				</div>
			</div>
			<div className="new-experience-content-wrapper">
				<div className="new-experience-content-container">{stepsMap[step]}</div>
				<div className="new-experience-bottom-navigation-container">
					{step !== 1 && (
						<div
							onClick={onPreviousClicked}
							className="button secondary-button"
						>
							{dictionary.back}
						</div>
					)}
					{step !== totalSteps && (
						<div>
							{totalSteps -
								step +
								1 +
								' ' +
								(isMobile
									? dictionary.steps_left_short
									: dictionary.steps_left)}
						</div>
					)}
					<div
						onClick={onNextClicked}
						className={enabled ? 'button' : 'button button-disabled'}
					>
						{step === totalSteps ? dictionary.finish : dictionary.next}
					</div>
				</div>
			</div>
			<FormLoader showLoader={showLoader} />
			{showDismissDialog ? (
				<ConfirmationModal
					setShowModal={setShowDismissDialog}
					asyncTask={onExperienceSaved}
					title={dictionary.exitExperience_dialog}
					action={dictionary.exit_newExperience}
					onCancel={onCancel} // Add this line
				/>
			) : null}

			<ToastContainer />
		</div>
	);
};

NewExperience.propTypes = {
	tempId: PropTypes.string,
	tempData: PropTypes.object,
	onExperienceSaved: PropTypes.func,
	editId: PropTypes.string
};

export default NewExperience;
