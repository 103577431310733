import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import dots from '../../../../assets/icons/ic_three_dots.svg';
// a
import check from '../../../../assets/icons/ic_check.svg';
import { ReactComponent as EyeIcon } from '../../../../assets/icons/ic_eye.svg';
import { ReactComponent as RoundedCloseIcon } from '../../../../assets/icons/ic_close_round.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/ic_close.svg';
import { ReactComponent as ClockIcon } from '../../../../assets/icons/ic_clock.svg';

import useComponentVisible from '../../../../utilities/hooks/useComponentVisible';
import { useBookingStatuses } from '../../../../utilities/hooks/useBookingStatuses';
import { UseCurrencyDecorator } from '../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import {DateFormatHelper} from "../../../../utilities/helpers/DateFormatHelper";
import IconButton from '../atom/IconButton.js';
import moment from 'moment';
import PaymentStatusListItem from "../../common/status-label/PaymentStatusListItem";

// eslint-disable-next-line no-unused-vars
const BookingItem = ({ item, onDeleteClicked, onBookingClicked, onCancelClicked }) => {
	const dictionary = useDictionary();

	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const [showStatuses, setStatusesVisibility] = useState(false);

	const currencyDecorator = UseCurrencyDecorator();

	useEffect(() => {
		if (!isComponentVisible) {
			setStatusesVisibility(false);
		}
	}, [isComponentVisible]);

	const bookingStatuses = useBookingStatuses();
	const getBookingStatus = (status) => {
		return (
			<div className={'booking-status ' + bookingStatuses[status]?.class}>
				{bookingStatuses[status]?.text}
			</div>
		);
	};

	const changeBookingStatusAsync = async (e) => {
		e.stopPropagation();
	};

	const onContextClicked = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	return (
		<tr
			data-testid={'booking-item'}
			style={{ cursor: 'pointer' }}
			onClick={() => onBookingClicked(item)}
		>
			<td className="booking-index">
				<div className="booking-number" data-testid="booking-number">
					{item.index}
				</div>
			</td>
			<td data-testid="booking-date">
				{DateFormatHelper.monthTimeFormat(item.created_at)}
			</td>
				<td data-testid="booking-status">
					{getBookingStatus(item.status.value)}
				</td>
				<td>
					<div
						className="booking-customer-container"
						data-testid="booking-customer-container"
					>
					<img className="booking-customer-avatar" src={item.avatar_url} />
					<div data-testid="booking-customer-name" className='truncate admin-booking-customer-name' title={item.customer_name}>{item.customer_name}</div>
					</div>
				</td>
				<td data-testid="booking-marketplace">{item.channel}</td>
				<td data-testid="booking-experience-title-slot">
					<div className='truncate booking-experience-title' title={item.experience.title +
						' ' + DateFormatHelper.monthTimeFormat(item.slot.start_time)}>{item.experience.title +
						' ' + DateFormatHelper.monthTimeFormat(item.slot.start_time)}</div>
					
				</td>
				<td data-testid="booking-payment-status">
					<PaymentStatusListItem status={item.payment_status.value} />
				</td>
				<td>
					<div className="booking-amount" data-testid="booking-price">
						{currencyDecorator(item.amount)}
					</div>
				</td>
			<td>
				<div className="bookings-actions-wrapper">
					<IconButton onClickIcon={onContextClicked} activeState={isComponentVisible} styleObject={{width: '32px',height: '32px',borderRadius: '6px'}}>
						<img src={dots} />
					</IconButton>

					<div ref={ref} className="booking-action-menus-container">
						{isComponentVisible ? (
							<>
								<div className="booking-actions-container">
									{/*<div className='booking-action-container'
                                         onClick={(e) => {
                                             e.stopPropagation()
                                             setStatusesVisibility(!showStatuses)
                                         }}>
                                        <img src={booking}/>
                                        <div>
                                            {dictionary.status + ':' + bookingStatuses[item.status.value].text}
                                        </div>
                                        <div className='flex-filler'/>
                                        <img src={caret}/>
                                    </div>*/}
									<div
										className="booking-action-container"
										data-testid="booking-action-view"
									>
										<EyeIcon />
										<div>{dictionary.view}</div>
									</div>
									{!(item.status.value === 'cancelled' || item.status.value === 'cancelled_provider' || moment.unix(item?.slot.start_time).valueOf() <= new Date().valueOf()) &&
										<div
											className="booking-action-container"
											data-testid="booking-action-view"
											onClick={(e) => {
												e.stopPropagation();
												onCancelClicked(item);
											}}
										>
											<RoundedCloseIcon/>
											<div>{dictionary.cancel}</div>
										</div>
									}
									{/*<div className='booking-action-container' onClick={() => onDeleteClicked(item)}>
                                        <img src={bin}/>
                                        <div>
                                            {dictionary.delete}
                                        </div>
                                    </div>*/}
								</div>
								{showStatuses ? (
									<div className="booking-actions-container booking-statuses-container">
										{Object.keys(bookingStatuses).map((key) => (
											<div
												key={key}
												onClick={(e) => {
													changeBookingStatusAsync(e, key);
												}}
												className="booking-action-container"
												style={{ justifyContent: 'space-between' }}
											>
												<div>{bookingStatuses[key].text}</div>
												{item.status.value === key ? <img src={check} /> : null}
											</div>
										))}
									</div>
								) : null}
							</>
						) : null}
					</div>
				</div>
			</td>
		</tr>
	);
};

BookingItem.propTypes = {
	item: PropTypes.object,
	onDeleteClicked: PropTypes.func,
	onBookingClicked: PropTypes.func,
	onCancelClicked: PropTypes.func
};

export default BookingItem;
