import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { ReactComponent as InfoIcon } from '../../../../../../assets/icons/ic_info_light.svg';

const ExperienceCategoryTags = ({
	category,
	selectedTags,
	addTag,
	showTags,
	setShowTags
}) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(1);
	const [tags, setTags] = useState([]);

	useEffect(() => {
		setOpacity(0);
		setTimeout(() => {
			setOpacity(1);
		}, 100);
	}, [category]);

	useEffect(() => {
		let newTags = [];

		category?.tags?.forEach((tag) => {
			// Check if the tag is not already selected before adding it to the newTags array
			if (
				!selectedTags.some((selectedTag) => selectedTag.title === tag.title)
			) {
				newTags.push(tag);
			}
		});

		setTags(newTags);
	}, [category, selectedTags]);

	return (
		<div
			className={
				showTags
					? 'experience-category-tags-wrapper experience-category-tags-wrapper-visible'
					: 'experience-category-tags-wrapper'
			}
		>
			<div
				className="experience-category-tags-top-container"
				data-testid="experience-category-tags-top-container"
			>
				<h3 style={{ fontSize: '16px' }}>{dictionary.select_tags}</h3>
				<div
					onClick={() => setShowTags(false)}
					className="dialog-close-button"
				/>
			</div>
			<div className="experience-category-tags-container">
				<div
					className="experience-category-large-container"
					style={{ backgroundColor: category.color, opacity: opacity }}
				>
					<div
						className="experience-category-large-container-title"
						data-testid="experience-category-large-container-title"
					>
						{category.title}
					</div>
					<img
						className="experience-category-large-container-image"
						src={ImageHelper.getLargeCategoryImageUrl(category.image_url)}
					/>
				</div>

				<div
					className="new-experience-tags-container thin-scrollbar"
					style={{ overflowY: 'auto' }}
				>
					{tags.map((tag, index) => (
						<div
							key={index}
							onClick={() => addTag(tag)}
							className="experience-tag"
							data-testid={'experience-tag-' + tag.title}
						>
							{tag.title}
						</div>
					))}
				</div>

				{selectedTags.length === 3 && (
					<div className="new-experience-tags-alert">
						<InfoIcon />
						<div className="terms-text">
							{dictionary.max_selected_tags_message}
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

ExperienceCategoryTags.propTypes = {
	category: PropTypes.object,
	selectedTags: PropTypes.array,
	addTag: PropTypes.func,
	showTags: PropTypes.bool,
	setShowTags: PropTypes.func
};

export default ExperienceCategoryTags;
