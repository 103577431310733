import { useDictionary } from '../../../../../../../../utilities/hooks/UseDictionary';
import React from 'react';
import PropTypes from 'prop-types';
import ExperienceItem from './ExperienceItem';

const Experiences = ({ experiences }) => {
	const dictionary = useDictionary();

	return (
		<div className="detail-tab-container thin-scrollbar fade-on-load">
			<div
				className="participant-row-container"
				style={{ justifyContent: 'space-between', marginBottom: '20px' }}
			>
				<h3 data-testid="participant-groups-header">
					{ dictionary.experiences }
				</h3>
				<div
					style={{ fontWeight: 'normal', opacity: '0.6' }}
					className="booking-number"
				>
				</div>
			</div>
			{experiences?.map((experience) => (
				<ExperienceItem key={experience.id} experience={experience} />
			))}
		</div>
	);
};

Experiences.propTypes = {
	experiences: PropTypes.array
};

export default Experiences;
