import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { DateFormatHelper } from "../../../../../utilities/helpers/DateFormatHelper";
import MoneyInput from "../../../common/MoneyInput";
import { UseCurrencyDecorator } from "../../../../../utilities/hooks/UseCurrencyDecorator";
import {TextFormatHelper} from "../../../../../utilities/helpers/TextFormatHelper";
import CustomCheckbox from "../../../common/CustomCheckbox";

const ConfirmRequestDialog = ({
	onConfirm,
	detail,
	setShowDialog,
}) => {
	const dictionary = useDictionary();
	const [isCustomAmount, setIsCustomAmount] = useState(dictionary.original_amount);
	const [customAmount, setCustomAmount] = useState(0);
	const [changeReason, setChangeReason] = useState("");
	const [isOverBooking, setIsOverBooking] = useState(false);
	const [isAllowOverBooking, setIsAllowOverBooking] = useState(true);


	useEffect(() => {
		detail && setIsOverBooking(detail?.overbooking)
		setCustomAmount(detail?.amount);
	}, [detail]);
	const currencyDecorator = UseCurrencyDecorator();
	const onCancelClicked = () => {
		setShowDialog(false);
	};
	const onConfirmClicked = () => {
		const data =
			isCustomAmount === dictionary.original_amount
				? {status: "confirmed", is_custom_amount: false, allow_overbooking: isAllowOverBooking }
				: {status: "confirmed", is_custom_amount: true, custom_amount: Number(customAmount), custom_amount_note: changeReason, allow_overbooking: isAllowOverBooking };
		onConfirm(data);
		setShowDialog(false);
	};

	const onRadioChanged = (e) => {
		setIsCustomAmount(e.target.value);
	};
	const onItemBlur = () => {
		const newValue = customAmount >= 5 ? customAmount : 5;
		setCustomAmount(newValue);
	};

	const isDisabled = useMemo(() => {
		if(isCustomAmount === dictionary.original_amount){
			return false;
		}
		return (changeReason === "");
	}, [isCustomAmount, changeReason]);

	return (
		<div className="dialog-wrapper" onClick={(e) => e.stopPropagation()}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">
						{dictionary.booking_confirmation}
					</div>
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container">
					<div className='d-flex'>
						<label className='nowrap' style={{ marginBottom: 8, fontWeight: 500 }}>
							{dictionary.experience_title}:
						</label>
						<label style={{ marginBottom: 8, marginLeft: 8, fontWeight: 500 }}>
							{detail?.experience?.title}
						</label>
					</div>
					<label style={{ marginBottom: 8, fontWeight: 500 }}>
						{dictionary.customer}: {detail?.customer_name}
					</label>
					<label style={{ marginBottom: 8, fontWeight: 500 }}>
						{dictionary.date_time}: {DateFormatHelper.rangeDayMonthYearFormat(
						detail?.slot?.start_time,
						detail?.slot?.end_time
					)}
					</label>
					<div className='d-flex'>
						<label style={{ marginBottom: 8, fontWeight: 500 }}>
							{dictionary.notes}:
						</label>
						<label style={{ marginBottom: 8, marginLeft: 8, fontWeight: 500 }}>
							{detail?.notes?.request_availability?.customer || 'No notes'}
						</label>
					</div>

					<label style={{ marginBottom: 8, marginTop: 24, fontWeight: 500 }}>
						{dictionary.total_cost}:
					</label>

					<div>
						<label className="radio-label">
							{dictionary.original_amount}: {currencyDecorator(detail?.amount)}
							<input
								type="radio"
								value={dictionary.original_amount}
								checked={isCustomAmount === dictionary.original_amount}
								onChange={onRadioChanged}
							/>
							<span className="checkmark" />
						</label>
					</div>
					<div className="d-flex items-center">
						<label className="radio-label">
							{dictionary.change_amount}:
							<input
								type="radio"
								value={dictionary.change_amount}
								checked={isCustomAmount === dictionary.change_amount}
								onChange={onRadioChanged}
							/>
							<span className="checkmark" />
						</label>
						{isCustomAmount === dictionary.change_amount &&
							<div className="group-inputs" style={{ width: '120px', marginTop: 0, marginBottom: 10	, marginLeft: 12 }}>
								<MoneyInput
									min={detail?.amount}
									value={customAmount}
									setValue={(e) => setCustomAmount(e.target.value)}
									onBlur={onItemBlur}
								/>
							</div>
						}
					</div>
					<div className="slot-detail-content" style={{ marginBottom: 24 }}>
						{isCustomAmount === dictionary.change_amount ?
						<div className="group-inputs">
							<div className="text">{dictionary.reason_for_change}:</div>
							<textarea
								placeholder={dictionary.describe_reason_change}
								disabled={isCustomAmount !== dictionary.change_amount}
								value={changeReason}
								onChange={(e) => setChangeReason(e.target.value)}
								data-testid="experience-name-input"
							/>
						</div>
							: null}
						{isOverBooking ?
						<CustomCheckbox
							label={dictionary.allow_overbooking}
							checked={isAllowOverBooking}
							setChecked={setIsAllowOverBooking}
						/> : null }
					</div>

				</div>

				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{
						padding: 12,
						marginBottom: 0,
						alignSelf: 'center',
						width: '100%'
					}}
				>
					<button onClick={onCancelClicked} className="tertiary-button">
						{dictionary.cancel}
					</button>
					<button
						disabled={isDisabled}
						onClick={onConfirmClicked}
						className="button"
					>
						{dictionary.confirm}
					</button>
				</div>
			</div>
		</div>
	);
};

ConfirmRequestDialog.propTypes = {
	setShowDialog: PropTypes.func,
	onConfirm: PropTypes.func,
	detail: PropTypes.object
};

export default ConfirmRequestDialog;
