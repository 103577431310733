import { ENABLE_RESOURCES } from '../ui_config';
import { useDictionary } from '../utilities/hooks/UseDictionary';
import BasePage from './BasePage';
import Layout from './Layout';
import Confirm from './containers/auth/pages/Confirm.js';
import ForgotPassword from './containers/auth/pages/ForgotPassword';
import Login from './containers/auth/pages/Login';
import Logout from './containers/auth/pages/Logout';
import ResendActivation from './containers/auth/pages/ResendActivation';
import ResetPassword from './containers/auth/pages/ResetPassword';
import Register from './containers/auth/pages/register/Register';
import ErrorModal from './containers/common/ErrorModal';
import SuccessModal from './containers/common/SuccessModal';
import WarningModal from './containers/common/WarningModal';
import * as common from './containers/common/__redux/commonRedux';
import AccountSetupWizard from './containers/components/account_setup_wizard/AccountSetupWizard';
import ProviderApplications from './containers/components/admin/provider_applications/ProviderApplications';
import Bookings from './containers/components/bookings/Bookings';
import ExperiencesAddons from './containers/components/experience-addons/ExperiencesAddons';
import AddonDetails from './containers/components/experience-addons/new_addon/AddonDetails';
import MySettings from './containers/components/my_settings/MySettings';
import ProviderResources from './containers/components/provider-resources/ProviderResources';
import ResourceDetails from './containers/components/provider-resources/new_resource/ResourceDetails';
import ProviderCalendar from './containers/components/provider_calendar/ProviderCalendar';
import ProviderExperiences from './containers/components/provider_experiences/ProviderExperiences';
import ProviderHome from './containers/components/provider_home/ProviderHome';
import ProviderPayouts from './containers/components/provider_home/payouts/ProviderPayouts';
import ResaleBookings from './containers/components/resale_hub/bookings/ResaleBookings';
import ResaleMarketplace from "./containers/components/resale_hub/ResaleMarketplace";
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, shallowEqual, useSelector } from 'react-redux';
import {Redirect, Route, Switch, useHistory,} from 'react-router-dom';
import ResaleCollection from "./containers/components/resale_hub/ResaleCollection";
import {NavigatorPaths} from "../assets/dictionaries/NavigatorPaths";
import BookingDetails from "./containers/components/bookings/booking_details/BookingDetails";
import SlotDetails from "./containers/components/provider_calendar/slot_details/SlotDetails";
import AdminProviderExperiences from './containers/components/admin/provider_experiences/AdminProviderExperiences.js';
import Groups from './containers/components/groups/Groups';
import AdminBookings from './containers/components/admin/bookings/Bookings.js';
import AdminBookingDetail from './containers/components/admin/bookings/booking_details/BookingDetails.js';
import AdminGroups from './containers/components/admin/groups/Groups'
const Routes = (props) => {
	const dictionary = useDictionary();
	const [showErrorDialog, setErrorDialogVisibility] = useState(false);
	const [showWarningDialog, setWarningDialogVisibility] = useState(false);
	const [showSuccessDialog, setSuccessDialogVisibility] = useState(false);
	const { isAuthorized } = useSelector(
		({ auth }) => ({
			isAuthorized: auth.user != null
		}),
		shallowEqual
	);

	// eslint-disable-next-line no-unused-vars
	const { user_type } = useSelector(
		({ auth }) => ({
			user_type: auth?.user?.user_type
		}),
		shallowEqual
	);

	const { type } = useSelector(
		({ auth }) => ({
			type: auth?.user?.type
		}),
		shallowEqual
	);

	const { account } = useSelector(
		({ auth }) => ({
			account: auth?.account
		}),
		shallowEqual
	);

	const history = useHistory();

	const { isVerified } = useSelector(
		({ auth }) => ({
			isVerified: auth.user?.verified
		}),
		shallowEqual
	);

	useEffect(() => {
		if (type === 2 && account === null) {
			history.push('/wizard');
		}
	}, [isVerified, account, type]);

	useEffect(() => {
		if (props.errorMessage !== '') {
			setErrorDialogVisibility(true);
		} else {
			setErrorDialogVisibility(false);
		}
	}, [props.errorMessage]);

	useEffect(() => {
		if (props.successMessage !== '') {
			setSuccessDialogVisibility(true);
		} else {
			if (showSuccessDialog) {
				setSuccessDialogVisibility(false);
			}
		}
	}, [props.successMessage]);

	useEffect(() => {
		if (props.warningMessage !== '') {
			setWarningDialogVisibility(true);
		} else {
			if (showWarningDialog) {
				setWarningDialogVisibility(false);
			}
		}
	}, [props.warningMessage]);

	const routes = [
		{
			path: NavigatorPaths.home.path,
			pageTitle: dictionary.home,
			linkTitle: dictionary.home,
			component: ProviderHome,
			name: NavigatorPaths.home.name
			/*nested: {
                path: 'home/payouts',
                pageTitle: dictionary.payouts.toLowerCase(),
                linkTitle: dictionary.payouts.toLowerCase(),
                component: ProviderPayouts,
                name: 'navigation_home_payouts',
            }*/
		},
		{
			path: NavigatorPaths.home_payouts.path,
			pageTitle: dictionary.payouts.toLowerCase(),
			linkTitle: dictionary.payouts.toLowerCase(),
			component: ProviderPayouts,
			name: NavigatorPaths.home_payouts.name
		},
		{
			path: NavigatorPaths.applications.path,
			pageTitle: dictionary.provider_applications,
			linkTitle: dictionary.provider_applications,
			component: ProviderApplications,
			name: NavigatorPaths.applications.name
		},
		{
			path: NavigatorPaths.settings.path,
			pageTitle: dictionary.my_settings,
			linkTitle: dictionary.my_settings,
			component: MySettings,
			name: NavigatorPaths.settings.name
		},
		{
			path: NavigatorPaths.experiences.path,
			pageTitle: dictionary.experiences,
			linkTitle: dictionary.experiences,
			component: ProviderExperiences,
			name: NavigatorPaths.experiences.name
		},
		{
			path: NavigatorPaths.provider_resources_add.path,
			pageTitle: dictionary.create_resource,
			linkTitle: null,
			component: ResourceDetails,
			name: NavigatorPaths.provider_resources_add.name
		},
		{
			path: NavigatorPaths.provider_resources_edit.path,
			pageTitle: dictionary.edit_resource,
			linkTitle: null,
			component: ResourceDetails,
			name: NavigatorPaths.provider_resources_edit.name
		},
		{
			path: NavigatorPaths.provider_resources.path,
			pageTitle: dictionary.resources,
			linkTitle: dictionary.resources,
			component: ProviderResources,
			name: NavigatorPaths.provider_resources.name
		},
		{
			path: NavigatorPaths.experience_addons_add.path,
			pageTitle: dictionary.create_addon,
			linkTitle: null,
			component: AddonDetails,
			name: NavigatorPaths.experience_addons_add.name
		},

		{
			path: NavigatorPaths.experience_addons_edit.path,
			pageTitle: dictionary.edit_addon,
			linkTitle: null,
			component: AddonDetails,
			name: NavigatorPaths.experience_addons_edit.name
		},
		{
			path: NavigatorPaths.experience_addons.path,
			pageTitle: dictionary.resources,
			linkTitle: dictionary.resources,
			component: ExperiencesAddons,
			name: NavigatorPaths.experience_addons.name
		},

		{
			path: NavigatorPaths.admin_experiences.path,
			pageTitle: dictionary.experiences,
			linkTitle: dictionary.experiences,
			component: AdminProviderExperiences,
			name: NavigatorPaths.admin_experiences.name
		},
		{
			path: NavigatorPaths.resale_marketplace.path,
			pageTitle: dictionary.marketplace,
			linkTitle: dictionary.marketplace,
			component: ResaleMarketplace,
			name: NavigatorPaths.resale_marketplace.name
		},
		{
			path: NavigatorPaths.resale_collection.path,
			pageTitle: dictionary.resale_collection,
			linkTitle: dictionary.resale_collection,
			component: ResaleCollection,
			name: NavigatorPaths.resale_collection.name
		},
		{
			path: NavigatorPaths.resale_bookings.path,
			pageTitle: dictionary.bookings,
			linkTitle: dictionary.bookings,
			component: ResaleBookings,
			name: NavigatorPaths.resale_bookings.name
		},
		{
			path: NavigatorPaths.bookings.path,
			pageTitle: dictionary.bookings,
			linkTitle: dictionary.bookings,
			component: Bookings,
			name: NavigatorPaths.bookings.name
		},
		{
			path: NavigatorPaths.bookings.path,
			pageTitle: dictionary.calendar,
			linkTitle: dictionary.calendar,
			component: ProviderCalendar,
			name: NavigatorPaths.bookings.name
		},
		{
			path: NavigatorPaths.groups.path,
			pageTitle: dictionary.collections,
			linkTitle: dictionary.collections,
			component: Groups,
			name: NavigatorPaths.groups.name
		},
		{
			path: NavigatorPaths.admin_bookings.path,
			pageTitle: dictionary.bookings,
			linkTitle: dictionary.bookings,
			component: AdminBookings,
			name: NavigatorPaths.admin_bookings.name
		},
		{
			path: NavigatorPaths.admin_bookings_details.path,
			pageTitle: dictionary.bookings,
			linkTitle: dictionary.bookings,
			component: AdminBookingDetail,
			name: NavigatorPaths.admin_bookings_details.name
		},
		{
			path: NavigatorPaths.admin_groups.path,
			pageTitle: dictionary.collections,
			linkTitle: dictionary.collections,
			component: AdminGroups,
			name: NavigatorPaths.admin_groups.name
		},
		//admin routes
	];

	useEffect(() => {
		if (!props.routes && props.components && isAuthorized) {
			const newRoutes = getRoutes();
			newRoutes.push(
				{
					path: NavigatorPaths.settings.path,
					pageTitle: dictionary.my_settings,
					linkTitle: dictionary.my_settings,
					component: MySettings,
					name: NavigatorPaths.settings.name
				},
				{
					path: NavigatorPaths.home_payouts.path,
					pageTitle: dictionary.payouts.toLowerCase(),
					linkTitle: dictionary.payouts.toLowerCase(),
					component: ProviderPayouts,
					name: NavigatorPaths.home_payouts.name
				},
				{
					path: NavigatorPaths.experiences.path,
					pageTitle: dictionary.experiences,
					linkTitle: dictionary.experiences,
					component: ProviderExperiences,
					name: NavigatorPaths.experiences.name
				},
				{
					path: NavigatorPaths.admin_experiences.path,
					pageTitle: dictionary.experiences,
					linkTitle: dictionary.experiences,
					component: AdminProviderExperiences,
					name: NavigatorPaths.admin_experiences.name
				},
				{
					path: NavigatorPaths.resale_marketplace.path,
					pageTitle: dictionary.marketplace,
					linkTitle: dictionary.marketplace,
					component: ResaleMarketplace,
					name: NavigatorPaths.resale_marketplace.name
				},
				{
					path: NavigatorPaths.resale_collection.path,
					pageTitle: dictionary.resale_collection,
					linkTitle: dictionary.resale_collection,
					component: ResaleCollection,
					name: NavigatorPaths.resale_collection.name
				},
				{
					path: NavigatorPaths.resale_bookings.path,
					pageTitle: dictionary.bookings,
					linkTitle: dictionary.bookings,
					component: ResaleBookings,
					name: NavigatorPaths.resale_bookings.name
				},
				{
					path: NavigatorPaths.calendar.path,
					pageTitle: dictionary.calendar,
					linkTitle: dictionary.calendar,
					component: ProviderCalendar,
					name: NavigatorPaths.calendar.name
				},
				{
					path: NavigatorPaths.calendar_slot_details.path,
					pageTitle: dictionary.calendar,
					linkTitle: dictionary.calendar,
					component: SlotDetails,
					name: NavigatorPaths.calendar.name
				},
				{
					path: 'logout',
					pageTitle: 'logout',
					linkTitle: 'logout',
					component: Logout,
					name: 'logout'
				},
				{
					path: NavigatorPaths.applications.path,
					pageTitle: dictionary.provider_applications,
					linkTitle: dictionary.provider_applications,
					component: ProviderApplications,
					name: NavigatorPaths.applications.name
				},
				{
					path: NavigatorPaths.bookings.path,
					pageTitle: dictionary.bookings,
					linkTitle: dictionary.bookings,
					component: Bookings,
					name: NavigatorPaths.bookings.name
				},
				{
					path: NavigatorPaths.booking_detail.path,
					pageTitle: dictionary.bookings,
					linkTitle: dictionary.bookings,
					component: BookingDetails,
					name: NavigatorPaths.booking_detail.name
				},
				{
					path: NavigatorPaths.provider_resources_edit.path,
					pageTitle: dictionary.edit_resource,
					linkTitle: null,
					component: ResourceDetails,
					name: NavigatorPaths.provider_resources_edit.name
				},

				{
					path: NavigatorPaths.experience_addons_add.name,
					pageTitle: dictionary.create_addon,
					linkTitle: null,
					component: AddonDetails,
					name: NavigatorPaths.experience_addons_add.name
				},

				{
					path: NavigatorPaths.experience_addons_edit.path,
					pageTitle: dictionary.edit_addon,
					linkTitle: null,
					component: AddonDetails,
					name: NavigatorPaths.experience_addons_edit.name
				},
				{
					path: NavigatorPaths.experience_addons.path,
					pageTitle: dictionary.resources,
					linkTitle: dictionary.resources,
					component: ExperiencesAddons,
					name: NavigatorPaths.experience_addons.name
				},
				{
					path: NavigatorPaths.groups.path,
					pageTitle: dictionary.collections,
					linkTitle: dictionary.collections,
					component: Groups,
					name: NavigatorPaths.groups.name
				},
				{
					path: NavigatorPaths.admin_bookings.path,
					pageTitle: dictionary.bookings,
					linkTitle: dictionary.bookings,
					component: AdminBookings,
					name: NavigatorPaths.admin_bookings.name
				},
				{
					path: NavigatorPaths.admin_bookings_details.path,
					pageTitle: dictionary.bookings,
					linkTitle: dictionary.bookings,
					component: AdminBookingDetail,
					name: NavigatorPaths.admin_bookings_details.name
				},
				{
					path: NavigatorPaths.admin_groups.path,
					pageTitle: dictionary.collections,
					linkTitle: dictionary.collections,
					component: AdminGroups,
					name: NavigatorPaths.admin_groups.name
				}
			);

			if (ENABLE_RESOURCES) {
				newRoutes.push(
					{
						path: NavigatorPaths.provider_resources.path,
						pageTitle: dictionary.resources,
						linkTitle: dictionary.resources,
						component: ProviderResources,
						name: NavigatorPaths.provider_resources.name
					},
					{
						path: NavigatorPaths.provider_resources_add.path,
						pageTitle: dictionary.create_resource,
						linkTitle: null,
						component: ResourceDetails,
						name: NavigatorPaths.provider_resources_add.name
					}
				);
			}

			props.setRoutes(newRoutes);
		}
		if (!isAuthorized) {
			props.setRoutes(null);
		}

		if (!props.routes && !props.components && props.user) {
			window.location.href = '/logout';
		}
	});

	function getRoutes() {
		let newRoutes = props.user && props.user.verified ? [] : [];
		/*[] : [routes.find(({name}) => name === 'navigation_intro')];*/
		const components = props.components;
		if (props.components) {
			for (const [key] of Object.entries(components)) {
				const newRoute = routes.find(({ name }) => name === key);

				if (newRoute && newRoute.path) {
					newRoutes.push(newRoute);
				}
			}

			const navigation = props.components.navigation
				? props.components.navigation.sub_modules
				: [];
			// eslint-disable-next-line no-unused-vars
			navigation.map((module, index) => {
				const newRoute = routes.find(({ name }) => name === module.name);
				if (newRoute) {
					newRoutes.push(newRoute);
				}
			});
		}
		return newRoutes;
	}

	return (
		<>
			<>
				{!isAuthorized ? (
					<Switch>
						{/*<Route path="/logout_expired" component={LogoutExpired} />*/}
						<Route path="/confirm/:lang_id/:hash" component={Confirm} />
						<Route path="/register" component={Register} />
						<Route path="/forgot-password" component={ForgotPassword} />
						<Route path="/resend-activation" component={ResendActivation} />
						<Route path="/login" component={Login} />
						<Route
							path="/reset-password/:lang_id/:hash"
							component={ResetPassword}
						/>
						<Redirect to="/login" />
					</Switch>
				) : type === 2 && account === null ? (
					<Route path="/wizard" component={AccountSetupWizard} />
				) : (
					<Layout showNavigation={isVerified}>{<BasePage />}</Layout>
				)}
			</>
			{showErrorDialog ? (
				<ErrorModal
					code={props.errorMessage}
					setErrorDialogVisibility={setErrorDialogVisibility}
				/>
			) : null}
			{showWarningDialog ? (
				<WarningModal
					warningMessage={props.warningMessage}
					setWarningDialogVisibility={setWarningDialogVisibility}
				/>
			) : null}
			{showSuccessDialog ? (
				<SuccessModal
					successMessage={props.successMessage}
					setSuccessDialogVisibility={setSuccessDialogVisibility}
				   
				/>
			) : null}
		</>
	);
};

Routes.propTypes = {
	routes: PropTypes.array,
	components: PropTypes.object,
	user: PropTypes.object,
	setRoutes: PropTypes.func,
	setComponents: PropTypes.func,
	setUser: PropTypes.func,
	setErrorMessage: PropTypes.func,
	setWarningMessage: PropTypes.func,
	setSuccessMessage: PropTypes.func,
	errorMessage: PropTypes.string,
	warningMessage: PropTypes.string,
	successMessage: PropTypes.string
};

export default connect(common.states, common.actions)(Routes);
