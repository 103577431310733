import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import '../../../../../assets/css/dialogs.css';
import SubmitDocumentsDialogDocumentRow from './SubmitDocumentsDialogDocumentRow';
import React, { useEffect, useState } from 'react';
import DialogLoader from '../../../common/DialogLoader';
// import { submitLegalInfoWithDocuments, submitLegalInfo } from './networking';
import { submitLegalInfo } from './networking';
import { connect } from 'react-redux';
import * as common from '../../../common/__redux/commonRedux';
import CustomCheckbox from '../../../common/CustomCheckbox';
import LegalInfoDialog from '../../../common/legal-info-dialog/LegalInfoDialog';
import PropTypes from 'prop-types';
import { TextFormatHelper } from '../../../../../utilities/helpers/TextFormatHelper';
// import { MAX_LEGAL_FILE_SIZE, LEGAL_FILES_TYPES } from '../../../../../config';
import { authenticateProvider } from '../networking';

const SubmitDocumentsDialog = ({
	showDialog,
	onDocumentsSubmitted,
	setErrorMessage
}) => {
	const dictionary = useDictionary();
	// const [idFile, setIdFile] = useState(null);
	// const [addressFile, setAddressFile] = useState(null);
	// const [ibanFile, setIbanFile] = useState(null);
	// const [companyFile, setCompanyFile] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [submitted, setSubmitted] = useState(0);

	const [vatNumber, setVatNumber] = useState('');
	const [beneficiary, setBeneficiary] = useState('');
	const [iban, setIban] = useState('');

	const [showLegalInfoDialog, setLegalInfoDialogVisibility] = useState(false);
	const [type, setType] = useState(3);
	const [acceptTerms, setAcceptTerms] = useState(false);

	useEffect(() => {
		if (submitted === 4) {
			setShowLoader(false);
			onDocumentsSubmitted();
		}
	}, [submitted]);

	const updateLegalInfoASync = async () => {
		try {
			setShowLoader(true);
			// eslint-disable-next-line no-unused-vars
			// let files = [idFile, addressFile, ibanFile, companyFile];
			// let fileTypes = [1, 2, 3, 4]; //hardcode value
			// eslint-disable-next-line no-unused-vars
			// let { data } = await submitLegalInfoWithDocuments(
			// eslint-disable-next-line no-unused-vars
			let { data } = await submitLegalInfo(
				beneficiary,
				iban,
				vatNumber
				// files,
				// fileTypes
			);
			// setSubmitted(files.length);

			let response = await authenticateProvider();
			let stripeUrl = response.data.url;
			//redirect to stripeUrl
			// window.location.assign(stripeUrl);
			window.location.href = stripeUrl;

			// onDocumentsSubmitted();
		} catch (e) {
			setShowLoader(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
			}
		}
	};

	const getAllFilesSelectedStatus = () => {
		return (
			// idFile === null ||
			// addressFile === null ||
			// ibanFile === null ||
			// companyFile === null ||
			iban === '' || beneficiary === '' || vatNumber === '' || !acceptTerms
		);
	};

	const showLegaDialog = (type) => {
		setType(type);
		setLegalInfoDialogVisibility(true);
	};

	return (
		<div className="dialog-wrapper fade-on-load">
			<div className="dialog-container documents-dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">{dictionary.company_legal_info}</div>
					<div
						className="dialog-close-button"
						onClick={() => showDialog(false)}
					/>
				</div>
				<div className="dialog-title-divider" />
				<div
					className="dialog-content-container-with-scroll thin-scrollbar"
					style={{
						padding: '0 24px',
						minHeight: '200px',
						maxHeight: '80%',
						height: 'fit-content'
					}}
				>
					<div className="group-inputs">
						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{dictionary.vat_number}
						</label>
						<input
							onChange={(e) => setVatNumber(e.target.value)}
							type="text"
							value={vatNumber}
							data-cy="vat-number"
							data-testid="vat-number"
							placeholder="EL100000000"
						/>



						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{'IBAN'}
						</label>
						<input
							onChange={(e) => setIban(e.target.value)}
							type="text"
							value={iban}
							data-testid="iban"
							placeholder="GR000000000"
						/>

						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{dictionary.bank_account_beneficiary_name}
						</label>
						<input
							onChange={(e) => setBeneficiary(e.target.value)}
							type="text"
							value={beneficiary}
							data-testid="beneficiary-name"
							placeholder="eg. Upiria P.C."
						/>
					</div>
					{/* <SubmitDocumentsDialogDocumentRow
						setFile={setIdFile}
						title={dictionary.id_document}
						text={dictionary.id_document_text}
					/>
					<SubmitDocumentsDialogDocumentRow
						setFile={setAddressFile}
						title={dictionary.proof_of_address}
						text={dictionary.proof_of_address_document_text}
					/>
					<SubmitDocumentsDialogDocumentRow
						setFile={setIbanFile}
						title={dictionary.iban_document}
						text={dictionary.iban_document_text}
					/>
					<SubmitDocumentsDialogDocumentRow
						setFile={setCompanyFile}
						title={dictionary.company_document}
						text={dictionary.company_document_text}
					/>

					<div
						style={{
							marginTop: '15px',
							marginBottom: '10px',
							fontSize: '12px'
						}}
						className="terms-text"
					>
						Max file size:{' '}
						{TextFormatHelper.formatFileSize(MAX_LEGAL_FILE_SIZE)}. Supported
						file types: {TextFormatHelper.formatFileTypes(LEGAL_FILES_TYPES)}.
					</div> */}

					<div
						style={{ marginTop: '15px', marginBottom: '20px' }}
						className="terms-text"
					>
						<div
							style={{ width: '100%', display: 'flex', alignItems: 'center' }}
						>
							<CustomCheckbox
								checked={acceptTerms}
								setChecked={setAcceptTerms}
								type={1}
							/>
							<div style={{ marginTop: 12 }}>
								{dictionary.agree_cancellation_policy}
								<span
									className="link-text"
									onClick={() => showLegaDialog(5)}
									data-testid="cancellation-policy-label"
								>
									{dictionary.cancellation_policy_label}
								</span>
								<span>{','}</span>
								<span
									className="link-text"
									onClick={() => showLegaDialog(4)}
									data-testid="privacy-policy-label"
								>
									{dictionary.provider_terms_and_conditions}
								</span>
								<span style={{ paddingLeft: '10px' }}>
									{dictionary.and}
								</span>
								<span
									className="link-text"
									onClick={() => showLegaDialog(3)}
									data-testid="terms-and-conditions-label"
								>
									{dictionary.general_terms_and_conditions}
								</span>
							</div>
						</div>
						{/*<span*/}
						{/*	className="link-text"*/}
						{/*	onClick={() => showLegaDialog(5)}*/}
						{/*	data-testid="cancellation-policy-label"*/}
						{/*>*/}
						{/*	{dictionary.cancellation_policy_label}*/}
						{/*</span>*/}


					</div>
				</div>
				<div
					className="dialog-title-divider"
					style={{ marginTop: '20px', marginBottom: '20px' }}
				/>
				<div
					style={{ paddingLeft: '24px', paddingRight: '24px' }}
					className="dialog-buttons-container"
				>
					<div
						onClick={() => showDialog(false)}
						className="tertiary-button"
						data-testid="cancel-button"
					>
						{dictionary.cancel}
					</div>
					<button
						disabled={getAllFilesSelectedStatus()}
						onClick={() => {
							updateLegalInfoASync();
						}}
						type="submit"
						className="button"
						data-testid="submit-button"
					>
						{dictionary.submit}
					</button>
				</div>

				{<DialogLoader showLoader={showLoader} />}
			</div>
			{showLegalInfoDialog ? (
				<LegalInfoDialog
					type={type}
					setLegalInfoDialogVisibility={setLegalInfoDialogVisibility}
				/>
			) : null}
		</div>
	);
};

SubmitDocumentsDialog.propTypes = {
	showDialog: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
	onDocumentsSubmitted: PropTypes.func,
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(SubmitDocumentsDialog);
