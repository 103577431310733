import React, {useEffect, useRef, useState} from 'react';
import { subMonths, addMonths, addYears, subYears } from 'date-fns';
import PropTypes from 'prop-types';
import { ReactComponent as LeftArrowIcon } from '../../../../assets/icons/ic_caret_left.svg';
import '../../../../assets/css/range_calendar.css';

const monthDefault = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const weekDefault = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

const RangeCalendar = ({ startDate, type, onClose, onSetDate }) => {
    const [selectedDay, setSelectedDay] = useState(new Date());
    const [isSelectDateMode, setIsSelectDateMode] = useState(true);
    const [isYearCalendarMode, setIsYearCalendarMode] = useState(true);
    const [firstDayOfThisMonth, setFirstDayOfThisMonth] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));

    const wrapper = useRef();
    const currentMonth = selectedDay.getMonth();
    const currentYear = selectedDay.getFullYear();
    const currentDay = selectedDay.getDate();

    const nextMonthDate = new Date(firstDayOfThisMonth.getFullYear(), firstDayOfThisMonth.getMonth() + 1, 1);

    let i;
    let lastDate;
    let month = [];
    let week = [];
    let dateNum = 1, firstWeekDay;

    lastDate = new Date(nextMonthDate - 1); //the first day of the next month minus 1 day

    firstWeekDay = firstDayOfThisMonth.getDay();
    const latestMonth = new Date(firstDayOfThisMonth - 1);


    for (i = 0; i < firstWeekDay; i++) {
        week.push({year: latestMonth.getFullYear(), month: latestMonth.getMonth(), day: latestMonth.getDate() - (firstWeekDay - i - 1)});
    }
    for (i = firstWeekDay; i < 7; i++) {
        week.push({year: firstDayOfThisMonth.getFullYear(), month: firstDayOfThisMonth.getMonth(), day: dateNum++});
    }
    month.push(week);

    while (dateNum <= lastDate.getDate()){
        week=[];
        let firstOfNextMonth = 0;
        for (i = 0; i < 7 ;i ++) {
            if (dateNum <= lastDate.getDate()) {
                week.push({year: firstDayOfThisMonth.getFullYear(), month: firstDayOfThisMonth.getMonth(), day: dateNum++});
            } else {
                week.push({year: firstDayOfThisMonth.getFullYear(), month: firstDayOfThisMonth.getMonth() + 1, day: ++firstOfNextMonth});
            }
        }

        month.push(week);
    }

    function handleClickOutside(event) {
        if (wrapper.current && !wrapper.current.contains(event.target)) {
            onClose();
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    useEffect(() => {
        setSelectedDay(new Date(startDate));
        setIsSelectDateMode(type === 'timeGridDay' || type === 'timeGridWeek');
        if (type === 'dayGridMonth') {
            if (new Date(startDate).getDate() === 1) {
                setFirstDayOfThisMonth(new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), 1));
            } else {
                setFirstDayOfThisMonth(new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth() + 1, 1));
            }

        } else setFirstDayOfThisMonth(new Date(new Date(startDate).getFullYear(), new Date(startDate).getMonth(), 1));
    }, [startDate, type]);

    return (
        <div ref={wrapper} className="calendar-wrapper">
            {isSelectDateMode &&
                <div className="month-calendar">
                    <div className="header-actions">

                        <div className="header-icon-button-group">
                            <button className="header-icon-button" onClick={() => setFirstDayOfThisMonth(subMonths(firstDayOfThisMonth, 1))}>
                                <LeftArrowIcon />
                            </button>
                            <p className="month-and-year" onClick={() => setIsSelectDateMode(false)}>
                                {monthDefault[firstDayOfThisMonth.getMonth()]} {firstDayOfThisMonth.getFullYear()}
                            </p>
                            <button className="header-icon-button" onClick={() => setFirstDayOfThisMonth(addMonths(firstDayOfThisMonth, 1))}>
                                <LeftArrowIcon />
                            </button>

                        </div>
                    </div>

                    <table className="calendar-table">
                        <thead>
                            <tr className="week-row">
                                {weekDefault.map(week => <td key={week} className="day-cell">{week}</td>)}
                            </tr>
                        </thead>
                        {month.map((week, index) => (
                            <tr
                                key={index}
                                className={`
                                    ${type === 'timeGridWeek' && 'day-row'} 
                                    ${week[0].day === selectedDay.getDate() && week[0].month === selectedDay.getMonth() && week[0].year === selectedDay.getFullYear() && 'active'}`
                                }
                                onClick={() => {
                                    type === 'timeGridWeek' && setSelectedDay(new Date(week[0].year, week[0].month, week[0].day));
                                    type === 'timeGridWeek' && onSetDate(new Date(week[0].year, week[0].month, week[0].day), type);
                                    type === 'timeGridWeek' && onClose();
                                }}
                            >
                                {week.map((date, ind) => (
                                    <td
                                        key={`${index}-${ind}`}
                                        className={`
                                        day-cell 
                                        ${type === 'timeGridDay' && 'time-grid-mode'} 
                                        ${((index === 0 && date.day > 8) || (index > 2 && date.day < 8)) && 'grey-color'} 
                                        ${currentMonth === date.month && currentYear === date.year && currentDay === date.day && 'active'}`}
                                        onClick={() => {
                                            type === 'timeGridDay' && onSetDate(new Date(date.year, date.month, date.day), type);
                                            type === 'timeGridDay' && onClose();
                                        }}
                                    >

                                        {date.day}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </table>
                </div>
            }
            {!isSelectDateMode &&
                <div className="year-calendar-wrapper">
                    <div className="header-actions">
                        <div className="header-icon-button-group">
                            <button
                                className="header-icon-button"
                                onClick={() => setFirstDayOfThisMonth(subYears(firstDayOfThisMonth, 1))}
                            >
                                <LeftArrowIcon/>
                            </button>
                            <p className="month-and-year" onClick={() => setIsYearCalendarMode(!isYearCalendarMode)}>
                                {isYearCalendarMode ?
                                    `${firstDayOfThisMonth.getFullYear()}`
                                    :
                                    `${firstDayOfThisMonth.getFullYear()} - ${firstDayOfThisMonth.getFullYear() + 11}`
                                }
                            </p>
                            <button
                                className="header-icon-button"
                                onClick={() => setFirstDayOfThisMonth(addYears(firstDayOfThisMonth, 1))}
                            >
                                <LeftArrowIcon />
                            </button>

                        </div>
                    </div>
                    {isYearCalendarMode ?
                        <div className="year-calendar">
                            {monthDefault.map((month, index) =>
                                <button
                                    key={index}
                                    className={`year-cell ${firstDayOfThisMonth.getMonth() === index && 'active'}`}
                                    onClick={() => {
                                        setFirstDayOfThisMonth(new Date(firstDayOfThisMonth.getFullYear(), index, 1));
                                        type === 'dayGridMonth' ? onClose() : setIsSelectDateMode(true);
                                        type === 'dayGridMonth' && onSetDate(new Date(firstDayOfThisMonth.getFullYear(), index, 1), type);
                                    }}
                                >
                                    {month.slice(0, 3)}
                                </button>
                            )}
                        </div>
                        :
                        <div className="year-calendar">
                            {new Array(12).fill(0).map((_, index) =>
                                <button
                                    key={index}
                                    className={`year-cell ${firstDayOfThisMonth.getFullYear() === firstDayOfThisMonth.getFullYear() + index && 'active'}`}
                                    onClick={() => {
                                        setFirstDayOfThisMonth(new Date(firstDayOfThisMonth.getFullYear() + index, firstDayOfThisMonth.getMonth(), 1));
                                        setIsYearCalendarMode(true);
                                    }}
                                >
                                    {firstDayOfThisMonth.getFullYear() + index}
                                </button>
                            )}
                        </div>
                    }
                </div>
            }
        </div>
    )
};

RangeCalendar.prototype = {
    type: PropTypes.string,
    startDate: PropTypes.any,
    onClose: PropTypes.func,
}

export default RangeCalendar;