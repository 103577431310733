import React from 'react';
import moment from 'moment/moment';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';

const BookingItem = ({ item }) => {
	const decorator = UseCurrencyDecorator();

	function capitalize(str) {
		return str.charAt(0).toUpperCase() + str.slice(1);
	}

	return (
		<tr>
			<td>
				<div className="booking-number">
					{moment(item.date).format('MMM Do YYYY')}
				</div>
			</td>
			<td>{ capitalize(item.type) }</td>
			<td>{item.description}</td>
			<td>
				{ (item.amount) ? decorator(item.amount/100) : '-' }
			</td>
			<td>
				{item.fee ? (
					<span>{ '-'+decorator(item.fee / 100) }</span>
				) : (
					<span style={{ display: 'block', textAlign: 'center', width: '40%' }}>-</span>
				)}
			</td>
			<td style={{ background: 'white'}}>
				{item.balance ? (
					<span>{decorator(item.balance / 100)}</span>
				) : (
					<span style={{ display: 'block', textAlign: 'center', width: '40%' }}>-</span>
				)}
			</td>
		</tr>
	);
};

BookingItem.propTypes = {
	item: PropTypes.object.isRequired
};

export default BookingItem;
