import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import dots from '../../../../../assets/icons/ic_three_dots.svg';
// a
import check from '../../../../../assets/icons/ic_check.svg';
import { ReactComponent as EyeIcon } from '../../../../../assets/icons/ic_eye.svg';
import { ReactComponent as CloseIcon } from '../../../../../assets/icons/ic_close.svg';
import { ReactComponent as ClockIcon } from '../../../../../assets/icons/ic_clock.svg';

import useComponentVisible from '../../../../../utilities/hooks/useComponentVisible';
import { useBookingStatuses } from '../../../../../utilities/hooks/useBookingStatuses';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import {DateFormatHelper} from "../../../../../utilities/helpers/DateFormatHelper";
import IconButton from '../../atom/IconButton.js';

// eslint-disable-next-line no-unused-vars
const BookingItem = ({ item, onDeleteClicked, onBookingClicked }) => {
	const dictionary = useDictionary();

	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);
	const [showStatuses, setStatusesVisibility] = useState(false);

	const currencyDecorator = UseCurrencyDecorator();

	useEffect(() => {
		if (!isComponentVisible) {
			setStatusesVisibility(false);
		}
	}, [isComponentVisible]);

	const bookingStatuses = useBookingStatuses();
	const getBookingStatus = (status) => {
		return (
			<div className={'booking-status ' + bookingStatuses[status]?.class}>
				{bookingStatuses[status]?.text}
			</div>
		);
	};

	const getPaymentStatus = (status) => {
		if (status === 'paid') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-paid-icon" />
					<div>{dictionary.paid}</div>
				</div>
			);
		} else if (status === 'refunded') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-refunded-icon" />
					<div>{dictionary.refunded}</div>
				</div>
			);
		} else if (status === 'pending') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-pending-icon">
						<ClockIcon />
					</div>
					<div>{dictionary.pending}</div>
				</div>
			)
		} else if (status === 'failed') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-unpaid-icon"></div>
					<div>{dictionary.failed}</div>
				</div>
			);
		} else if (status === 'partial_refunded') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-refunded-icon" />
					<div>{dictionary.partial_refunded}</div>
				</div>
			);
		}
		return (
			<div className="booking-customer-container">
				<div className="booking-status-unpaid-icon" />
				<div>{dictionary.no_charge}</div>
			</div>
		);

	};

	const changeBookingStatusAsync = async (e) => {
		e.stopPropagation();
	};

	const onContextClicked = () => {
		setIsComponentVisible(!isComponentVisible);
	};

	return (
		<tr
			data-testid={'booking-item'}
			style={{ cursor: 'pointer' }}
			onClick={() => onBookingClicked(item)}
		>
			<td className="booking-index">
				<div className="booking-number" data-testid="booking-number">
					{item.display_id}
				</div>
			</td>
			<td data-testid="booking-date">
				{DateFormatHelper.monthTimeFormat(item.created_at)}
			</td>
				<>
					<td data-testid="booking-status">
						<div className='truncate booking-experience-title' title={item.provider.brand_name}>
							{item.provider.brand_name}
						</div>
					</td>
					<td data-testid="booking-experience-title-slot">
						<div className='truncate booking-experience-title' title={item.experience.title + ' ' + DateFormatHelper.monthTimeFormat(item.slot.start_time)}>{item.experience.title + ' ' + DateFormatHelper.monthTimeFormat(item.slot.start_time)}</div>
					</td>
					<td data-testid="booking-status">
						{getBookingStatus(item.status.value)}
					</td>
					<td>
						<div
							className="booking-customer-container"
							data-testid="booking-customer-container"
						>
							<div data-testid="booking-customer-name" className='truncate admin-booking-customer-name'>{item.customer.name}</div>
						</div>
					</td>
					<td data-testid="booking-payment-status">
						{getPaymentStatus(item.payment_status.value)}
					</td>
					<td>
						<div className="booking-amount" data-testid="booking-price">
							{currencyDecorator(item.amount)}
						</div>
					</td>
				</>
			<td>
				<div className="bookings-actions-wrapper">
					<IconButton onClickIcon={onContextClicked} activeState={isComponentVisible} styleObject={{width: '32px',height: '32px',borderRadius: '6px'}}>
						<img src={dots} />
					</IconButton>

					<div ref={ref} className="booking-action-menus-container">
						{isComponentVisible ? (
							<>
								<div className="booking-actions-container">
									<div
										className="booking-action-container"
										data-testid="booking-action-view"
									>
										<EyeIcon />
										<div>{dictionary.view}</div>
									</div>
								</div>
								{showStatuses ? (
									<div className="booking-actions-container booking-statuses-container">
										{Object.keys(bookingStatuses).map((key) => (
											<div
												key={key}
												onClick={(e) => {
													changeBookingStatusAsync(e, key);
												}}
												className="booking-action-container"
												style={{ justifyContent: 'space-between' }}
											>
												<div>{bookingStatuses[key].text}</div>
												{item.status.value === key ? <img src={check} /> : null}
											</div>
										))}
									</div>
								) : null}
							</>
						) : null}
					</div>
				</div>
			</td>
		</tr>
	);
};

BookingItem.propTypes = {
	item: PropTypes.object,
	onDeleteClicked: PropTypes.func,
	onBookingClicked: PropTypes.func
};

export default BookingItem;
