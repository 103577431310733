// import { useEffect, useState } from 'react';
import '../../../../../../assets/css/spinner.css';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import { deleteExperienceAsset } from '../../../provider_experiences/networking';
import PropTypes from 'prop-types';
import React from 'react';

const UploadedItem = ({ file, onItemDeleted, setShowLoader, tempId }) => {
	const deleteExperienceAssetAsync = async () => {
		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteExperienceAsset(tempId, file.id);
			onItemDeleted();
		} catch (error) {
			setShowLoader(false);
		}
	};

	return (
		<div title={file?.file?.name} className="media-file-wrapper fade-on-load">
			<div className="media-file-container uploaded-file-container">
				<div
					onClick={() => deleteExperienceAssetAsync()}
					className="uploaded-file-delete-icon"
					data-testid="uploaded-file-delete-icon"
				/>
				{file?.file?.type?.includes('video') ? (
					<video
						className="media-file-bg"
						loop={true}
						autoPlay={true}
						muted={true}
						controls={true}
						src={ImageHelper.getFullImageUrl(file?.file?.path)}
					/>
				) : (
					<img
						src={ImageHelper.getFullImageUrl(file?.file?.path)}
						className="media-file-bg"
					/>
				)}
			</div>
			<div className="text ellipsize" style={{ lineClamp: '1' }}>
				{file?.file?.name}
			</div>
		</div>
	);
};

UploadedItem.propTypes = {
	file: PropTypes.object,
	onItemDeleted: PropTypes.func,
	setShowLoader: PropTypes.func,
	tempId: PropTypes.string
};

export default UploadedItem;
