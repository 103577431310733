import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { useHistory, useLocation } from 'react-router-dom';
import { addMonths, addDays } from 'date-fns';
import FullCalendar from '@fullcalendar/react';
import moment from 'moment/moment';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';

import FilterDialog from './FilterDialog';
import EditSlotTypeDialog from './EditSlotTypeDialog';
import NoItemsYetDialog from './NoItemsYetDialog';
import ExperienceTypeDialog from './ExperienceTypeDialog';
import ExperienceDetailsDialog from './experience_details_dialog/ExperienceDetailsDialog';
import IncreaseCapacityDialog from './increase_capacity_dialog/IncreaseCapacity';
import { deleteSlot, getExperienceSlots, getSlotDetails } from './networking';
import ResourceDetailsDialog from './resource_details_dialog/ResourceDetailsDialog';
import ScheduleSlotDialog from './schedule_slot_dialog/ScheduleSlotDialog';
import { useCookies } from '../../../../utilities/hooks/UseCookie';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import ConfirmationModal from '../../common/ConfirmationModal';
import DialogLoader from '../../common/DialogLoader';
import Tooltip from '../../common/Tooltip';
import WarningModal from '../../common/WarningModal';
import * as common from '../../common/__redux/commonRedux';
import { getProviderInfo } from '../my_settings/networking';
import RangeCalendar from '../../common/range_calendar/RangeCalendar';
import { NavigatorPaths } from '../../../../assets/dictionaries/NavigatorPaths';
import '../../../../assets/css/calendar.css';
import iconFilter from '../../../../assets/icons/ic_filter.svg';


const ProviderCalendar = (props) => {
	// const calendarRef = useRef();
	const dictionary = useDictionary();
	const history = useHistory();
	const location = useLocation();

	const [showScheduleDialog, setShowScheduleDialog] = useState(false);
	const [showFilterDialog, setShowFilterDialog] = useState(false);
	const [showSlotDialog, setShowSlotDialog] = useState(false);
	const [showNoItemsYetDialog, setNoItemsYetDialogVisibility] = useState(false);
	const [showResourceSlotDialog, setShowResourceSlotDialog] = useState(false);
	const [
		showExperienceScheduleTypeDialog,
		setExperienceScheduleTypeDialogVisibility
	] = useState(false);

	const [showEditSlotTypeDialog, setEditSlotTypeDialogVisibility] =
		useState(false);
	const [editType, setEditType] = useState(null);
	const [editSlot, setEditSlot] = useState(null);
	
	const [showLoader, setShowLoader] = useState(true);
	const [events, setEvents] = useState([]);
	const [event, setEvent] = useState(null);
	const [rangeStart, setRangeStart] = useState(null);
	const [rangeEnd, setRangeEnd] = useState(null);
	const [scheduleMode, setScheduleMode] = useState('experience');
	const [enableScheduling, setEnableScheduling] = useState(true);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [isResource, setIsResource] = useState(false);
	const [showSlotDetails, setSlotDetailsVisibility] = useState(false);
	const [showWarningDialog, setWarningDialogVisibility] = useState(false);
	const [filterCount, setFilterCount] = useState(0);
	const [filterExperienceParams, setFilterExperienceParams] = useState([]);
	const [filterAccessParams, setFilterAccessParams] = useState([]);
	const [filterSlotParams, setFilterSlotParams] = useState([]);
	const [saveToCookie, readCookie] = useCookies();
	const [calendarViewType, setCalendarViewType] = useState({ type: "timeGridWeek", start: new Date() });
	const [rangeCalendarOpen, setRangeCalendarOpen] = useState(false);
	const [initialView, setInitialView] = useState(JSON.parse(localStorage.getItem('calendar_view'))?.type || "timeGridWeek");
	const calendar_view = JSON.parse(localStorage.getItem('calendar_view'));
	const [startDate, setStartDate] = useState(calendar_view
		? calendar_view.type === "dayGridMonth"
		  ? new Date(calendar_view?.start).getDate() > 1
			? new Date(new Date(calendar_view?.start).getFullYear(), new Date(calendar_view?.start).getMonth() + 1, 1)
			: new Date(calendar_view?.start)
		  : new Date(calendar_view?.start)
		: new Date());

	const calendarRef = useRef(null);

	const getSlotsAsync = async (
		startDate,
		endDate,
		filterExperienceParams,
		filterAccessParams,
		filterSlotParams,
		isBooking = false
	) => {
		setEvents([]);
		setShowLoader(true);
		try {
			const { data } = await getExperienceSlots(
				startDate,
				endDate,
				filterExperienceParams,
				filterAccessParams,
				filterSlotParams,
				isBooking
			);
			let newEvents = [];
			data.experiences.map((event) =>
				newEvents.push(transformEvent(event, 'experience'))
			);
			data.resources.map((event) =>
				newEvents.push(transformEvent(event, 'resource'))
			);
			setEvents(newEvents);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};
	const getProviderAsync = async () => {
		try {
			const { data } = await getProviderInfo();
			setEnableScheduling(data?.verification_status.value === '3');
			return data;
		} catch (error) {
			console.log(error);
		}
	};

	const getSlotAsync = async (slotId, type) => {
		setShowLoader(true);
		try {
			const { data } = await getSlotDetails(slotId, type);
			setEditSlot(data);
			setEvent(data);
			setShowLoader(false);
			if (type === 'experience') {
				setScheduleMode('experience');
				setShowSlotDialog(true);
			} else {
				setScheduleMode('resource');
				setShowResourceSlotDialog(true);
			}
		} catch (error) {
			setShowLoader(false);
		}
	};

	const onEventClicked = (event) => {
		getSlotAsync(event?.event?.id, event.event.extendedProps.data.type);
	};

	const onCellLoaded = (args) => {
		if (args.isPast) {
			return 'fc-past';
		}
	};

	const onDetailClicked = () => {
		const isExperience = Object.prototype.hasOwnProperty.call(event, 'experiences')
		history.push(`/calendar/${event.id}?${isExperience ? 'resource' : 'experience' }`);
	};

	const transformEvent = (event, eventType) => {
		return {
			type: eventType,
			title: event.title,
			start: event.start_time * 1000,
			end: event.end_time * 1000,
			color: 'transparent',
			id: event.id,
			data: {
				type: eventType,
				startTime: event.start_time,
				endTime: event.end_time,
				bgColor: event.color,
				people: event.booked,
				capacity: event.experiences
					? event.quantity + event.booked
					: event.capacity,
				experiences: event?.experiences?.length?.toString(),
				experiencesArray: event?.experiences,
				bookingStatus: event?.booking_status,
				is_request_availability: event?.is_request_availability,
				avatar: ''
			}
		};
	};

	useEffect(() => {
		const stroedCalendarSettings = JSON.parse(localStorage.getItem('calendar_view'));

		if (stroedCalendarSettings) {
			const api = calendarRef.current.getApi();
			const start_date = stroedCalendarSettings
				? stroedCalendarSettings.type === "dayGridMonth"
				? new Date(stroedCalendarSettings?.start).getDate() > 1
					? new Date(new Date(stroedCalendarSettings?.start).getFullYear(), new Date(stroedCalendarSettings?.start).getMonth() + 1, 1)
					: new Date(stroedCalendarSettings?.start)
				: new Date(stroedCalendarSettings?.start)
				: new Date();
			api.changeView(stroedCalendarSettings.type, start_date);
		}
		const start_date = JSON.parse(localStorage.getItem('calendar_view'))?.start ? new Date(JSON.parse(localStorage.getItem('calendar_view'))?.start) : new Date()
		setStartDate(start_date);
		const view_type = JSON.parse(localStorage.getItem('calendar_view')).type || "timeGridWeek";
		setCalendarViewType({start: start_date, type: view_type});
		setInitialView(view_type);

	}, []);

	const onDatesChanged = (args) => {
		setCalendarViewType({ start: new Date(args.startStr), type: args.view.type });
		localStorage.setItem('calendar_view', JSON.stringify({ start: args.startStr, type: args.view.type }));
		setStartDate(new Date(args.startStr));
		setRangeStart(moment(args.start).unix());
		setRangeEnd(moment(args.end).unix());
		const experienceIds =
			JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		const accessIds =
			JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		const slotIds =
			JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		const isBooking = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;
		getSlotsAsync(
			moment(args.start).unix(),
			moment(args.end).unix(),
			experienceIds,
			accessIds,
			slotIds,
			isBooking
		);
	};

	const onSetDate = (selectedDate, type) => {
		setStartDate(new Date(selectedDate));
		setCalendarViewType({ start: new Date(selectedDate), type: type })
		setRangeStart(moment(selectedDate).unix());
		setRangeEnd(moment(selectedDate).unix());
		const experienceIds =
			JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		const accessIds =
			JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		const slotIds =
			JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		const isBooking = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;
		let endDate = new Date();
		if (type === 'timeGridDay') {
			endDate = addDays(new Date(selectedDate), 1);
		} else if (type === 'timeGridWeek') {
			endDate = addDays(new Date(selectedDate), 7);
		} else if (type === 'dayGridMonth') {
			endDate = addMonths(new Date(selectedDate), 1);
		}
		const api = calendarRef.current.getApi();
		api.gotoDate(selectedDate);

		getSlotsAsync(
			moment(selectedDate).unix(),
			moment(endDate).unix(),
			experienceIds,
			accessIds,
			slotIds,
			isBooking
		);
	}

	const refetch = () => {
		let endDate = new Date();
		if (calendarViewType.type === 'timeGridDay') {
			endDate = addDays(new Date(startDate), 1);
		} else if (calendarViewType.type === 'timeGridWeek') {
			endDate = addDays(new Date(startDate), 7);
		} else if (calendarViewType.type === 'dayGridMonth') {
			endDate = addMonths(new Date(startDate), 1);
		}
		const experienceIds =
			JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		const accessIds =
			JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		const slotIds =
			JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		const isBooking = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;

		getSlotsAsync(
			moment(startDate).unix(),
			moment(endDate).unix(),
			experienceIds,
			accessIds,
			slotIds,
			isBooking
		);
	};

	useEffect(() => {
		const request = location.state;
		if (calendarRef.current && request) {
			const date = new Date((request.slot_start_time || request.slot.start_time) * 1000);
			calendarRef.current.getApi().changeView("timeGridDay");
			calendarRef.current.getApi().gotoDate(date);
			setTimeout(() => {
				calendarRef.current.getApi().scrollToTime(moment.unix(request.slot_start_time || request.slot.start_time).format('H:mm:ss'));
			}, 100);

		}
	}, [calendarRef.current, location]);

	const onScheduleSet = () => {
		const experienceIds =
			JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		setFilterExperienceParams(experienceIds);
		const accessIds =
			JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		setFilterAccessParams(accessIds);
		const slotIds =
			JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		setFilterSlotParams(slotIds);
		if (showSlotDetails) {
			setSlotDetailsVisibility(false);
		}
		const isBooking = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;
		getSlotsAsync(rangeStart, rangeEnd, experienceIds, accessIds, slotIds, isBooking);
	};

	const showScheduleDialogWithMode = (mode) => {
		setScheduleMode(mode);
		setShowScheduleDialog(true);
	};

	const showEditSlotDialogWithMode = (editType) => {
		setEditType(editType);
		if (isDelete) {
			setDeleteDialogVisibility(true);
		} else {
			setShowScheduleDialog(true);
		}
	};

	const onEditSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(false);
		if (editSlot.is_one) {
			setEditType('single');
			setShowScheduleDialog(true);
		} else {
			setEditSlotTypeDialogVisibility(true);
		}
	};

	const onDeleteSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(true);
		if (editSlot.is_one) {
			setEditType('single');
			setDeleteDialogVisibility(true);
		} else {
			setEditSlotTypeDialogVisibility(true);
		}
	};

	const onDeleteComplete = () => {
		setShowResourceSlotDialog(false);
		setShowSlotDialog(false);
		setSlotDetailsVisibility(false);
		onScheduleSet();
	};



	const deleteSlotAsync = async (onSuccess, onFail) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteSlot(editSlot.id, editType, isResource);
			onSuccess();
		} catch (error) {
			onFail();
		}
	};

	const showFiterlDialogWithMode = () => {
		setShowFilterDialog(true);
	};

	const onCreateDateValueChanged = () => {
		countAllFilter();
	};

	const onStatusValuesChanged = () => {
		countAllFilter();
	};

	const onExperienceValuesChanged = () => {
		countAllFilter();
	};

	const onAccessValuesChanged = () => {
		countAllFilter();
	};

	const onSlotValuesChanged = () => {
		countAllFilter();
	};

	const onBookingFilterChanged = () => {
		countAllFilter();
	};

	const countAllFilter = () => {
		let filterCountAll = 0;
		const experienceIds =
			JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		filterCountAll += experienceIds.length;
		const accessIds =
			JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		filterCountAll += accessIds.length;
		const slotIds =
			JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		filterCountAll += slotIds.length;
		const bookingFilter = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;
		if (bookingFilter) {
			filterCountAll += 1;
		}
		setFilterCount(filterCountAll);
	};

	const renderExperiencesSideLine = (experiences) => {
		return (
			<div className="experiences-side-line-container">
				{experiences?.map((experience, index) => {
					if (index < 3)
						return (
							<div
								key={experience.id}
								className="experiences-side-line-item"
								style={{ backgroundColor: experience.color }}
							/>
						);
				})}
			</div>
		);
	};

	const renderEventContent = (event) => {
		let start = event.event.extendedProps.data.startTime;
		let end = event.event.extendedProps.data.endTime;
		let minutes = (end - start) / 60;
		let type = event.event.extendedProps.data.type;
		let experiences = '';
		let experiencesArray = event.event.extendedProps.data?.experiencesArray;
		if (type === 'resource') {
			// eslint-disable-next-line no-unused-vars
			experiences = event.event.extendedProps.data?.experiences;
		}

		let showTitle = minutes >= 60;

		return (
			<div
				key={event.id}
				className={`
					calendar-event-container ${
					event.event.extendedProps.data.is_request_availability 
					&& event.event.extendedProps.data.bookingStatus === 'pending' 
					&& 'calendar-request-event-container'
				}`}
				title={event.title}
			>
				<div className="calendar-event-time">{event.timeText}</div>

				{showTitle ? (
					<div
						className="calendar-slot-experience-list-item ellipsize ellipsize-1"
						title={event.event.title}
						style={{ marginTop: calendarViewType.type === 'dayGridMonth' ? 2 : 4 }}
					>
						{event.event.title}
					</div>
				) : null}
				{type === 'resource'
					? renderExperiencesList(minutes, experiencesArray)
					: null}
				<div className="flex-filler" />
				<div className="calendar-event-booked-container">
					<div className="calendar-slot-experience-list-item calendar-event-booked">
						{event.event.extendedProps.data.people +
							'/' +
							event.event.extendedProps.data.capacity}
					</div>
					{type === 'experience' ? (
						<div className="calendar-event-avatar-wrapper">
							<img
								onError={(e) => {
									e.currentTarget.style.display = 'none';
								}}
								src={event.event.extendedProps.data.avatar}
								className="calendar-event-avatar"
							/>
						</div>
					) : null}
				</div>
				{type === 'experience' ? (
					<div
						className="calendar-event-color-line"
						style={{ background: event.event.extendedProps.data.bgColor }}
					/>
				) : (
					<div className="calendar-event-color-line">
						{renderExperiencesSideLine(experiencesArray)}
					</div>
				)}
			</div>
		);
	};

	const renderExperiencesList = (minutes, experiencesArray) => {
		if (minutes >= 60) {
			return (
				<div className="calendar-slot-experience-list">
					{experiencesArray.map((experience) => (
						<div
							key={experience}
							className="calendar-slot-experience-list-item ellipsize ellipsize-1"
							title={experience.title}
						>
							{experience.title}
						</div>
					))}
				</div>
			);
		}
	};

	const onNoResults = () => {
		setShowScheduleDialog(false);
		setNoItemsYetDialogVisibility(true);
	};

	const dismissDetails = () => {
		setTimeout(() => {
			setSlotDetailsVisibility(false);
			setEvent(null);
			history.push(NavigatorPaths.calendar.path);
		}, 300);
	};

	useEffect(() => {
		getProviderAsync().then((res) => {
			if (+res.verification_status.value !== 3 && !readCookie('second-visit')) {
				props.setWarningMessage('Document verification pending');
				saveToCookie('second-visit', true);
			}
		});
		countAllFilter();
	}, []);

	useEffect(() => {
		if (!location.search && event) {
			dismissDetails();
		}
	}, [location]);

	const onOpenCalendar = (event) => {
		event.stopPropagation();
		event.preventDefault();
		setRangeCalendarOpen(true);
	};

	useEffect(() => {
		const titleElement = document.querySelector('.fc-toolbar-title');
		if (rangeCalendarOpen) {
			titleElement.classList.add('active');
		} else titleElement.classList.remove('active');
		if (titleElement)
			titleElement.addEventListener('mousedown', (event) => onOpenCalendar(event));
		return () => {
			if (titleElement)
				titleElement.removeEventListener('mousedown', (event) => onOpenCalendar(event));
		};
	}, [rangeCalendarOpen]);

	return (
		<div className="calendar-container" style={{ position: 'relative'}}>
			{rangeCalendarOpen &&
				<RangeCalendar
					startDate={startDate}
					type={calendarViewType.type}
					onClose={() => setRangeCalendarOpen(false)}
					onSetDate={onSetDate}
				/>
			}
			<div className="calendar-title-bar">
				<h2 data-testid="calendar-header">{dictionary.calendar}</h2>
				<button
					className="button"
					data-testid="schedule-button"
					style={{ marginLeft: '25px' }}
					disabled={!enableScheduling}
					onClick={() => {
						//setExperienceScheduleTypeDialogVisibility(true);
						setEditType(null);
						showScheduleDialogWithMode('experience');
					}}
				>
					<div className="plus-icon" />
					{dictionary.schedule}
				</button>
				{!enableScheduling && (
					<div className="text-with-info calendar-verification-info">
						<Tooltip content="Document verification pending" />
					</div>
				)}
			</div>

			<div
				className="calendar-filter"
				onClick={() => {
					showFiterlDialogWithMode();
				}}
			>
				<img className="icon-filter" src={iconFilter}  alt="icon-filter" />
				{filterCount > 0 ? (
					<span>
						{dictionary.filters} ({filterCount})
					</span>
				) : (
					<span>{dictionary.filters}</span>
				)}
			</div>

			<FullCalendar
				ref={calendarRef}
				eventTimeFormat={{
					hour: '2-digit',
					minute: '2-digit',
					hour12: false
				}}
				slotLabelFormat={{
					hour: 'numeric',
					minute: '2-digit',
					hour12: false
				}}
				/*dayHeaderFormat={{
                    weekday:'short',
                    day:'numeric'
                }}*/
				dayHeaderContent={function (args) {
					if (args.view.type === 'dayGridMonth') {
						return moment(args.date).format('ddd');
					}
					return moment(args.date).format('ddd Do');
				}}
				slotMinTime="06:00:00"
				slotMaxTime="30:00:00"
				nextDayThreshold={'06:00:00'}
				stickyFooterScrollbar={true}
				handleWindowResize={false}
				nowIndicator={true}
				events={events}
				eventContent={renderEventContent}
				allDaySlot={false}
				headerToolbar={{
					start: '',
					center: 'today,prev,title,next',
					end: 'timeGridDay,timeGridWeek,dayGridMonth'
				}}
				initialView={initialView}
				plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
				datesSet={onDatesChanged}
				eventClick={onEventClicked}
				dayCellClassNames={onCellLoaded}
				initialDate={startDate}
			/>

			{<DialogLoader showLoader={showLoader} />}
			{/*experience based dialog*/}
			{showSlotDialog ? (
				<ExperienceDetailsDialog
					showSlotDetails={onDetailClicked}
					onEditSlotClicked={onEditSlotClicked}
					onDeleteSlotClicked={onDeleteSlotClicked}
					experience={event}
					setShowExperienceDetailsDialog={setShowSlotDialog}
					refetch={refetch}
				/>
			) : null}
			{/*resource based dialog*/}
			{showResourceSlotDialog ? (
				<ResourceDetailsDialog
					showSlotDetails={onDetailClicked}
					onEditSlotClicked={onEditSlotClicked}
					onDeleteSlotClicked={onDeleteSlotClicked}
					setShowLoader={setShowLoader}
					resource={event}
					setShowResourceDetailsDialog={setShowResourceSlotDialog}
				/>
			) : null}
			{/*schedule slot dialog*/}
			{showScheduleDialog ? (
				<ScheduleSlotDialog
					isResource={isResource}
					editType={editType}
					editSlot={editSlot}
					scheduleMode={scheduleMode}
					setShowScheduleDialog={setShowScheduleDialog}
					onScheduleSet={onScheduleSet}
					onNoResults={onNoResults}
					getSlotAsync={getSlotAsync}
				/>
			) : null}
			{/*type selection (experience or resource) before schedule slot*/}
			{showExperienceScheduleTypeDialog ? (
				<ExperienceTypeDialog
					showScheduleDialogWithMode={showScheduleDialogWithMode}
					setExperienceTypeDialogVisibility={
						setExperienceScheduleTypeDialogVisibility
					}
				/>
			) : null}
			{/*edit and delete slot dialog selecting this or this an all next*/}
			{showEditSlotTypeDialog ? (
				<EditSlotTypeDialog
					isDelete={isDelete}
					isResource={isResource}
					showEditSlotDialogWithMode={showEditSlotDialogWithMode}
					editSlot={editSlot}
					setEditSlotTypeDialogVisibility={setEditSlotTypeDialogVisibility}
				/>
			) : null}
			{showNoItemsYetDialog ? (
				<NoItemsYetDialog
					setDialogVisibility={setNoItemsYetDialogVisibility}
					mode={scheduleMode}
				/>
			) : null}

			{/*deletion confirmation dialog*/}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => onDeleteComplete()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteSlotAsync}
					title={dictionary.delete_slot}
					message={dictionary.delete_slot_message}
					successMessage={dictionary.slot_deleted}
					errorMessage={dictionary.problem_deleting_slot}
					action={dictionary.delete}
				/>
			) : null}
			{/*slot details screen*/}
			{/*{showSlotDetails ? (*/}
			{/*	<SlotDetails*/}
			{/*		onEditSlotClicked={onEditSlotClicked}*/}
			{/*		onDeleteSlotClicked={onDeleteSlotClicked}*/}
			{/*		onIncreaseCapacityClicked={onIncreaseCapacityClicked}*/}
			{/*		details={event}*/}
			{/*		dismissDetails={dismissDetails}*/}
			{/*	/>*/}
			{/*) : null}*/}


			{showWarningDialog ? (
				<WarningModal setWarningDialogVisibility={setWarningDialogVisibility} />
			) : null}

			{showFilterDialog ? (
				<FilterDialog
					onCreateDateValueChanged={onCreateDateValueChanged}
					onStatusValuesChanged={onStatusValuesChanged}
					onExperienceValuesChanged={onExperienceValuesChanged}
					onAccessValuesChanged={onAccessValuesChanged}
					onSlotValuesChanged={onSlotValuesChanged}
					onBookingFilterChanged={onBookingFilterChanged}
					setShowFilterDialog={setShowFilterDialog}
					filterCount={filterCount}
					countAllFilter={countAllFilter}
					setFilterExperienceParams={setFilterExperienceParams}
					setFilterAccessParams={setFilterAccessParams}
					setFilterSlotParams={setFilterSlotParams}
					onScheduleSet={onScheduleSet}
				/>
			) : null}

			<ToastContainer />
		</div>
	);
};

export default connect(common.states, common.actions)(ProviderCalendar);
