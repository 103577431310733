import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import CustomRepeatDayItem from './CustomRepeatDayItem';
import PropTypes from 'prop-types';
import React from 'react';

const RepetitionSelector = ({ selection, disabled, setSelection }) => {
	const dictionary = useDictionary();

	const onSelectAll = () => {
		setSelection(dictionary.day_initials_pos);
	};

	return (
		<div
			className="repeat-selection-container"
			data-testid="repeat-selection-container"
		>
			<div className="repeat-selection-items">
				{dictionary.day_initials.map((item, index) => (
					<CustomRepeatDayItem
						key={item}
						disabled={disabled}
						selection={selection}
						setSelection={setSelection}
						item={item}
						index={dictionary.day_initials_pos[index]}
					/>
				))}
			</div>

			<button
				className="button tertiary-button"
				disabled={disabled}
				onClick={onSelectAll}
				style={{ height: 32, padding: '8px 16px' }}
			>
				Select All
			</button>
		</div>
	);
};

RepetitionSelector.propTypes = {
	selection: PropTypes.array,
	disabled: PropTypes.bool,
	setSelection: PropTypes.func
};

export default RepetitionSelector;
