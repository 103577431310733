import { PROVIDER_GROUPS } from '../../../../config';
import { DataTableHelper } from '../../../../utilities/helpers/DataTableHelper';
import axios from 'axios';

export function getGroups(
	sortParam,
	pageSize,
	pageNumber,
	searchParam = '',
	createDateParam = '',
	filterStatusParams = [],
	filterPaymentStatusParams = []
) {
	return axios.get(
		PROVIDER_GROUPS +
			( DataTableHelper.prepareParameters(
						sortParam,
						pageSize,
						pageNumber,
						null,
						searchParam,
						createDateParam,
						filterStatusParams,
						filterPaymentStatusParams
				)),
	);
}

export function getGroup(id) {
	return axios.get(PROVIDER_GROUPS + '/' + id);
}

export function createProviderGroup(data) {
	return axios.post(PROVIDER_GROUPS , data);
}

export function deleteProviderGroup(id) {
	return axios.delete(PROVIDER_GROUPS + '/' + id);
}

export function updateProviderGroup(id, data) {
	return axios
		.put(PROVIDER_GROUPS + '/' + id, data)
		.catch((error) => {
			return error.response;
		});
}

export function changeGroupStatus(groupId, newStatus) {
	const data = {
		is_active: newStatus
	};
	return axios
		.put(PROVIDER_GROUPS + '/' + groupId, data)
		.catch((error) => {
			return error.response;
		});
}

export function removeProviderExperience(groupId, experienceId) {
	return axios
		.delete(PROVIDER_GROUPS + '/' + groupId + '/experience/' + experienceId)
		.catch((error) => {
			return error.response;
		});
}

