import React, { useEffect, useState } from 'react';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { NavLink } from 'react-router-dom';
import ResourceListItemFull from './ResourceListItemFull';
import { deleteResource, getAllResources } from './networking';
import SimpleLoader from '../../common/SimpleLoader';
import SortOptionsSelector from '../../common/sort_options/SortOptionsSelector';
import ViewTypeSelector from '../../common/ViewTypeSelector';
import ResourceListItemCompact from './ResourceListItemCompact';
import PaginationController from '../../common/pagination_controller/PaginationController';
import RelatedExperiencesModal from './RelatedExperiencesModal';
import ConfirmationModal from '../../common/ConfirmationModal';
import axios from 'axios';

const ProviderResources = () => {
	const dictionary = useDictionary();
	const userType = useUserTypeId();
	const [resources, setResources] = useState([]);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [selectedResource, setSelectedResource] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [viewType, setViewType] = useState(
		parseInt(localStorage.getItem('resources_selected_view_type') ?? 0)
	);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('resources_selected_page_size') ?? 10)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataLength, setDataLength] = useState(0);
	const [showLoaded, setShowLoader] = useState(true);
	const [showRelatedModal, setRelatedModalVisibility] = useState(false);
	const [errorMessage, setErrorMessage] = useState(
		dictionary.problem_deleting_resource
	);

	const source = axios.CancelToken.source();

	useEffect(() => {
		if (selectedOption) getResourcesAsync();
	}, [pageSize, currentPage, selectedOption]);

	useEffect(() => {
		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	const getResourcesAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getAllResources(
				selectedOption,
				pageSize,
				currentPage,
				source.token
			);
			setResources(data.data);
			setDataLength(data.recordsTotal);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const setSelectedDeleteResource = (resource) => {
		setSelectedResource(resource);
		setDeleteDialogVisibility(true);
	};

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};

	const onViewTypeChanged = (type) => {
		setViewType(type);
	};

	const onRelatedExperiencesClicked = (resource) => {
		setSelectedResource(resource);
		setRelatedModalVisibility(true);
	};

	const renderResources = () => {
		if (viewType === 0) {
			return (
				<>
					{resources.map((resource) => (
						<ResourceListItemFull
							key={resource.id}
							setShowLoader={setShowLoader}
							onStatusChangeCompleted={getResourcesAsync}
							onRelatedClicked={onRelatedExperiencesClicked}
							setSelectedDeleteResource={setSelectedDeleteResource}
							resource={resource}
						/>
					))}
				</>
			);
		} else {
			return (
				<>
					{resources.map((resource) => (
						<ResourceListItemCompact
							key={resource.id}
							setShowLoader={setShowLoader}
							onStatusChangeCompleted={getResourcesAsync}
							setSelectedDeleteResource={setSelectedDeleteResource}
							resource={resource}
						/>
					))}
				</>
			);
		}
	};

	const deleteResourceAsync = async (onSuccess, onFail) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteResource(selectedResource.id);
			onSuccess();
		} catch (error) {
			onFail();
			if (error.response) {
				if (error.response.data.error_code.toString() === '23000') {
					setErrorMessage(dictionary.errors['23000']);
				} else {
					setErrorMessage(dictionary.problem_deleting_resource);
				}
			}
		}
	};

	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};

	return (
		<div className="experiences-wrapper">
			<div className="experiences-top-bar">
				<h2>{dictionary.resources}</h2>
				{userType === 2 ? (
					<NavLink
						to={'provider-resources/add'}
						className="button"
						style={{ marginLeft: '25px' }}
					>
						<div className="plus-icon" />
						{dictionary.create_resource}
					</NavLink>
				) : null}
				<div className="flex-filler" />
				<SortOptionsSelector
					onValueChanged={onSortOptionValueChanged}
					name={'resources'}
				/>
				<div style={{ width: '16px' }} />
				<ViewTypeSelector
					onValueChanged={onViewTypeChanged}
					name={'resources'}
				/>
			</div>
			{resources.length === 0 ? (
				<div className="empty-experiences-list-wrapper">
					<div className="empty-experiences-list-placeholder">
						<div className="empty-experiences-image" />
						<h3 style={{ textAlign: 'center', marginTop: '24px' }}>
							{dictionary.no_resources_yet}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.all_resources_will_be_listed}
						</div>
					</div>
				</div>
			) : null}
			{resources.length > 0 ? (
				<div
					className={
						viewType === 0
							? 'experience-items-wrapper thin-scrollbar'
							: 'experience-items-wrapper thin-scrollbar tile-experience-items-wrapper'
					}
				>
					{renderResources()}
				</div>
			) : null}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => getResourcesAsync()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteResourceAsync}
					title={dictionary.delete_resource}
					message={dictionary.delete_resource_message}
					successMessage={dictionary.resource_deleted}
					errorMessage={errorMessage}
					action={dictionary.delete}
				/>
			) : null}
			<PaginationController
				name="resources"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			<SimpleLoader showLoader={showLoaded} />
			{showRelatedModal ? (
				<RelatedExperiencesModal
					experiences={selectedResource?.experiences}
					setShow={setRelatedModalVisibility}
				/>
			) : null}
		</div>
	);
};

export default ProviderResources;
