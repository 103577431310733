import loader from '../../../assets/lottie/upiria_loader.json';
import '../../../assets/css/dialogs.css';
import Lottie from 'lottie-react';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const SimpleLoader = ({ showLoader }) => {
	const [opacity, setOpacity] = useState(0);
	const [display, setDisplay] = useState('none');

	useEffect(() => {
		if (showLoader) {
			setTimeout(() => {
				setDisplay('flex');
				setOpacity(1);
			}, 10);
		} else {
			setOpacity(0);
			setTimeout(() => {
				setDisplay('none');
			}, 200);
		}
	}, [showLoader]);

	return (
		<div
			style={{
				borderRadius: '8px',
				zIndex: '5',
				opacity: opacity,
				display: display
			}}
			className="simple-loader-container"
		>
			<div className="lottie-container">
				<Lottie loop={true} animationData={loader} />
			</div>
		</div>
	);
};

SimpleLoader.propTypes = {
	showLoader: PropTypes.bool
};

export default SimpleLoader;
