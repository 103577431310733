import React, { Suspense, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import * as common from './containers/common/__redux/commonRedux';
// import Logout from './containers/auth/pages/Logout';
import PropTypes from 'prop-types';

const BasePage = (props) => {
	const location = useLocation();
	const userTypeId = props?.user?.user_type?.id;

	useEffect(() => {
		if (props.modal) {
			props.closeModal();
		}
		if (props.burger === true) {
			props.closeBurger();
		}
		if (props.routes) {
			const key = props?.routes?.findIndex(
				(x) => '/' + x?.path === window?.location?.pathname
			);
			let pageTitle = key >= 0 ? props.routes[key].pageTitle : null;
			props.setPageTitle(pageTitle);
		}
	}, [location.pathname]);

	const getRedirect = () => {
		if (userTypeId === 1) {
			return '/applications';
		}
		if (
			userTypeId === 2 ||
			userTypeId === 3 ||
			userTypeId === 7 ||
			userTypeId === 8
		) {
			return '/home';
		} else if (userTypeId === 5 || userTypeId === 6) {
			return 'visits';
		}
	};

	const getRoute = (route, index) => {
		if (!route.nested) {
			return (
				<Route
					exact={true}
					path={'/' + route.path}
					key={index}
					component={route.component}
				/>
			);
		} else {
			return (
				<Route path={'/' + route.path} key={index} component={route.component}>
					<Route path={route.nested.path} component={route.nested.component} />
				</Route>
			);
		}
	};
	return props.routes ? (
		<Suspense>
			<Switch>
				{props.routes.map((route, index) => {
					return getRoute(route, index);
				})}
				{/*<Route path={'/error'} component={GenericError}/>*/}
				<Redirect exact from="/login" to={getRedirect()} />
				<Redirect exact from="/wizard" to={getRedirect()} />
				<Redirect to="/home" />
			</Switch>
		</Suspense>
	) : null;
	/*<NewVisit/>*/
};

BasePage.propTypes = {
	routes: PropTypes.array,
	modal: PropTypes.bool,
	burger: PropTypes.bool,
	closeModal: PropTypes.func,
	closeBurger: PropTypes.func,
	setPageTitle: PropTypes.func,
	user: PropTypes.object
};

export default connect(common.states, common.actions)(BasePage);
