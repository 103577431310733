export const useCookies = () => {
	const saveToCookie = (cookieName, cookieValue) => {
		document.cookie = `${cookieName}=${JSON.stringify(cookieValue)}`;
	};

	const readCookie = (cookieName) => {
		const cookies = document.cookie.split(';');
		let formCookie;
		cookies.forEach((cookie) => {
			if (cookie.includes(`${cookieName}=`)) {
				formCookie = cookie.replace(`${cookieName}=`, '');
			}
		});
		return formCookie ? JSON.parse(formCookie) : null;
	};

	const deleteCookies = () => {
		const cookies = document.cookie.split(';');

		for (let i = 0; i < cookies.length; i++)
			document.cookie = cookies[i] + '=;expires=' + new Date(0).toUTCString();
	};

	return [saveToCookie, readCookie, deleteCookies];
};
