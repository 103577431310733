export const countries = [
	{
		key: "1",
		value: "Afghanistan",
		sn: "AF"
	},
	{
		key: "2",
		value: "Albania",
		sn: "AL"
	},
	{
		key: "3",
		value: "Algeria",
		sn: "DZ"
	},
	{
		key: "4",
		value: "American Samoa",
		sn: "AS"
	},
	{
		key: "5",
		value: "Andorra",
		sn: "AD"
	},
	{
		key: "6",
		value: "Angola",
		sn: "AO"
	},
	{
		key: "7",
		value: "Anguilla",
		sn: "AI"
	},
	{
		key: "8",
		value: "Antarctica",
		sn: "AQ"
	},
	{
		key: "9",
		value: "Antigua and Barbuda",
		sn: "AG"
	},
	{
		key: "10",
		value: "Argentina",
		sn: "AR"
	},
	{
		key: "11",
		value: "Armenia",
		sn: "AM"
	},
	{
		key: "12",
		value: "Aruba",
		sn: "AW"
	},
	{
		key: "13",
		value: "Australia",
		sn: "AU"
	},
	{
		key: "14",
		value: "Austria",
		sn: "AT"
	},
	{
		key: "15",
		value: "Azerbaijan",
		sn: "AZ"
	},
	{
		key: "16",
		value: "Bahamas",
		sn: "BS"
	},
	{
		key: "17",
		value: "Bahrain",
		sn: "BH"
	},
	{
		key: "18",
		value: "Bangladesh",
		sn: "BD"
	},
	{
		key: "19",
		value: "Barbados",
		sn: "BB"
	},
	{
		key: "20",
		value: "Belarus",
		sn: "BY"
	},
	{
		key: "21",
		value: "Belgium",
		sn: "BE"
	},
	{
		key: "22",
		value: "Belize",
		sn: "BZ"
	},
	{
		key: "23",
		value: "Benin",
		sn: "BJ"
	},
	{
		key: "24",
		value: "Bermuda",
		sn: "BM"
	},
	{
		key: "25",
		value: "Bhutan",
		sn: "BT"
	},
	{
		key: "26",
		value: "Bolivia",
		sn: "BO"
	},
	{
		key: "27",
		value: "Bosnia and Herzegovina",
		sn: "BA"
	},
	{
		key: "28",
		value: "Botswana",
		sn: "BW"
	},
	{
		key: "29",
		value: "Brazil",
		sn: "BR"
	},
	{
		key: "30",
		value: "British Indian Ocean Territory",
		sn: "IO"
	},
	{
		key: "31",
		value: "British Virgin Islands",
		sn: "VG"
	},
	{
		key: "32",
		value: "Brunei",
		sn: "BN"
	},
	{
		key: "33",
		value: "Bulgaria",
		sn: "BG"
	},
	{
		key: "34",
		value: "Burkina Faso",
		sn: "BF"
	},
	{
		key: "35",
		value: "Burundi",
		sn: "BI"
	},
	{
		key: "36",
		value: "Cambodia",
		sn: "KH"
	},
	{
		key: "37",
		value: "Cameroon",
		sn: "CM"
	},
	{
		key: "38",
		value: "Canada",
		sn: "CA"
	},
	{
		key: "39",
		value: "Cape Verde",
		sn: "CV"
	},
	{
		key: "40",
		value: "Cayman Islands",
		sn: "KY"
	},
	{
		key: "41",
		value: "Central African Republic",
		sn: "CF"
	},
	{
		key: "42",
		value: "Chad",
		sn: "TD"
	},
	{
		key: "43",
		value: "Chile",
		sn: "CL"
	},
	{
		key: "44",
		value: "China",
		sn: "CN"
	},
	{
		key: "45",
		value: "Christmas Island",
		sn: "CX"
	},
	{
		key: "46",
		value: "Cocos Islands",
		sn: "CC"
	},
	{
		key: "47",
		value: "Colombia",
		sn: "CO"
	},
	{
		key: "48",
		value: "Comoros",
		sn: "KM"
	},
	{
		key: "49",
		value: "Congo",
		sn: "CG"
	},
	{
		key: "50",
		value: "Cook Islands",
		sn: "CK"
	},
	{
		key: "51",
		value: "Costa Rica",
		sn: "CR"
	},
	{
		key: "52",
		value: "Cote d Ivoire",
		sn: "CI"
	},
	{
		key: "53",
		value: "Croatia",
		sn: "HR"
	},
	{
		key: "54",
		value: "Cuba",
		sn: "CU"
	},
	{
		key: "55",
		value: "Curacao",
		sn: "CW"
	},
	{
		key: "56",
		value: "Cyprus",
		sn: "CY"
	},
	{
		key: "57",
		value: "Czech Republic",
		sn: "CZ"
	},
	{
		key: "58",
		value: "Democratic Republic of the Congo",
		sn: "CD"
	},
	{
		key: "59",
		value: "Denmark",
		sn: "DK"
	},
	{
		key: "60",
		value: "Djibouti",
		sn: "DJ"
	},
	{
		key: "61",
		value: "Dominica",
		sn: "DM"
	},
	{
		key: "62",
		value: "Dominican Republic",
		sn: "DO"
	},
	{
		key: "63",
		value: "East Timor",
		sn: "TL"
	},
	{
		key: "64",
		value: "Ecuador",
		sn: "EC"
	},
	{
		key: "65",
		value: "Egypt",
		sn: "EG"
	},
	{
		key: "66",
		value: "El Salvador",
		sn: "SV"
	},
	{
		key: "67",
		value: "Equatorial Guinea",
		sn: "GQ"
	},
	{
		key: "68",
		value: "Eritrea",
		sn: "ER"
	},
	{
		key: "69",
		value: "Estonia",
		sn: "EE"
	},
	{
		key: "70",
		value: "Ethiopia",
		sn: "ET"
	},
	{
		key: "71",
		value: "Falkland Islands",
		sn: "FK"
	},
	{
		key: "72",
		value: "Faroe Islands",
		sn: "FO"
	},
	{
		key: "73",
		value: "Fiji",
		sn: "FJ"
	},
	{
		key: "74",
		value: "Finland",
		sn: "FI"
	},
	{
		key: "75",
		value: "France",
		sn: "FR"
	},
	{
		key: "76",
		value: "French Guiana",
		sn: "GF"
	},
	{
		key: "77",
		value: "French Polynesia",
		sn: "PF"
	},
	{
		key: "78",
		value: "Gabon",
		sn: "GA"
	},
	{
		key: "79",
		value: "Gambia",
		sn: "GM"
	},
	{
		key: "80",
		value: "Georgia",
		sn: "GE"
	},
	{
		key: "81",
		value: "Germany",
		sn: "DE"
	},
	{
		key: "82",
		value: "Ghana",
		sn: "GH"
	},
	{
		key: "83",
		value: "Gibraltar",
		sn: "GI"
	},
	{
		key: "84",
		value: "Greece",
		sn: "GR"
	},
	{
		key: "85",
		value: "Greenland",
		sn: "GL"
	},
	{
		key: "86",
		value: "Grenada",
		sn: "GD"
	},
	{
		key: "87",
		value: "Guam",
		sn: "GU"
	},
	{
		key: "88",
		value: "Guadeloupe",
		sn: "GP"
	},
	{
		key: "89",
		value: "Guatemala",
		sn: "GT"
	},
	{
		key: "90",
		value: "Guernsey",
		sn: "GG"
	},
	{
		key: "91",
		value: "Guinea",
		sn: "GN"
	},
	{
		key: "92",
		value: "Guinea-Bissau",
		sn: "GW"
	},
	{
		key: "93",
		value: "Guyana",
		sn: "GY"
	},
	{
		key: "94",
		value: "Haiti",
		sn: "HT"
	},
	{
		key: "95",
		value: "Honduras",
		sn: "HN"
	},
	{
		key: "96",
		value: "Hong Kong",
		sn: "HK"
	},
	{
		key: "97",
		value: "Hungary",
		sn: "HU"
	},
	{
		key: "98",
		value: "Iceland",
		sn: "IS"
	},
	{
		key: "99",
		value: "India",
		sn: "IN"
	},
	{
		key: "100",
		value: "Indonesia",
		sn: "ID"
	},
	{
		key: "101",
		value: "Iran",
		sn: "IR"
	},
	{
		key: "102",
		value: "Iraq",
		sn: "IQ"
	},
	{
		key: "103",
		value: "Ireland",
		sn: "IE"
	},
	{
		key: "104",
		value: "Isle of Man",
		sn: "IM"
	},
	{
		key: "105",
		value: "Israel",
		sn: "IL"
	},
	{
		key: "106",
		value: "Italy",
		sn: "IT"
	},
	{
		key: "107",
		value: "Ivory Coast",
		sn: "CI"
	},
	{
		key: "108",
		value: "Jamaica",
		sn: "JM"
	},
	{
		key: "109",
		value: "Japan",
		sn: "JP"
	},
	{
		key: "110",
		value: "Jersey",
		sn: "JE"
	},
	{
		key: "111",
		value: "Jordan",
		sn: "JO"
	},
	{
		key: "112",
		value: "Kazakhstan",
		sn: "KZ"
	},
	{
		key: "113",
		value: "Kenya",
		sn: "KE"
	},
	{
		key: "114",
		value: "Kiribati",
		sn: "KI"
	},
	{
		key: "115",
		value: "Kosovo",
		sn: "XK"
	},
	{
		key: "116",
		value: "Kuwait",
		sn: "KW"
	},
	{
		key: "117",
		value: "Kyrgyzstan",
		sn: "KG"
	},
	{
		key: "118",
		value: "Laos",
		sn: "LA"
	},
	{
		key: "119",
		value: "Latvia",
		sn: "LV"
	},
	{
		key: "120",
		value: "Lebanon",
		sn: "LB"
	},
	{
		key: "121",
		value: "Lesotho",
		sn: "LS"
	},
	{
		key: "122",
		value: "Liberia",
		sn: "LR"
	},
	{
		key: "123",
		value: "Libya",
		sn: "LY"
	},
	{
		key: "124",
		value: "Liechtenstein",
		sn: "LI"
	},
	{
		key: "125",
		value: "Lithuania",
		sn: "LT"
	},
	{
		key: "126",
		value: "Luxembourg",
		sn: "LU"
	},
	{
		key: "127",
		value: "Macau",
		sn: "MO"
	},
	{
		key: "128",
		value: "Macedonia",
		sn: "MK"
	},
	{
		key: "129",
		value: "Madagascar",
		sn: "MG"
	},
	{
		key: "130",
		value: "Malawi",
		sn: "MW"
	},
	{
		key: "131",
		value: "Malaysia",
		sn: "MY"
	},
	{
		key: "132",
		value: "Maldives",
		sn: "MV"
	},
	{
		key: "133",
		value: "Mali",
		sn: "ML"
	},
	{
		key: "134",
		value: "Malta",
		sn: "MT"
	},
	{
		key: "135",
		value: "Marshall Islands",
		sn: "MH"
	},
	{
		key: "136",
		value: "Mauritania",
		sn: "MR"
	},
	{
		key: "137",
		value: "Mauritius",
		sn: "MU"
	},
	{
		key: "138",
		value: "Mayotte",
		sn: "YT"
	},
	{
		key: "139",
		value: "Mexico",
		sn: "MX"
	},
	{
		key: "140",
		value: "Micronesia",
		sn: "FM"
	},
	{
		key: "141",
		value: "Moldova",
		sn: "MD"
	},
	{
		key: "142",
		value: "Monaco",
		sn: "MC"
	},
	{
		key: "143",
		value: "Mongolia",
		sn: "MN"
	},
	{
		key: "144",
		value: "Montenegro",
		sn: "ME"
	},
	{
		key: "145",
		value: "Montserrat",
		sn: "MS"
	},
	{
		key: "146",
		value: "Morocco",
		sn: "MA"
	},
	{
		key: "147",
		value: "Mozambique",
		sn: "MZ"
	},
	{
		key: "148",
		value: "Myanmar",
		sn: "MM"
	},
	{
		key: "149",
		value: "Namibia",
		sn: "NA"
	},
	{
		key: "150",
		value: "Nauru",
		sn: "NR"
	},
	{
		key: "151",
		value: "Nepal",
		sn: "NP"
	},
	{
		key: "152",
		value: "Netherlands",
		sn: "NL"
	},
	{
		key: "153",
		value: "Netherlands Antilles",
		sn: "AN"
	},
	{
		key: "154",
		value: "New Caledonia",
		sn: "NC"
	},
	{
		key: "155",
		value: "New Zealand",
		sn: "NZ"
	},
	{
		key: "156",
		value: "Nicaragua",
		sn: "NI"
	},
	{
		key: "157",
		value: "Niger",
		sn: "NE"
	},
	{
		key: "158",
		value: "Nigeria",
		sn: "NG"
	},
	{
		key: "159",
		value: "Niue",
		sn: "NU"
	},
	{
		key: "160",
		value: "North Korea",
		sn: "KP"
	},
	{
		key: "161",
		value: "Northern Mariana Islands",
		sn: "MP"
	},
	{
		key: "162",
		value: "Norway",
		sn: "NO"
	},
	{
		key: "163",
		value: "Oman",
		sn: "OM"
	},
	{
		key: "164",
		value: "Pakistan",
		sn: "PK"
	},
	{
		key: "165",
		value: "Palau",
		sn: "PW"
	},
	{
		key: "166",
		value: "Palestine",
		sn: "PS"
	},
	{
		key: "167",
		value: "Panama",
		sn: "PA"
	},
	{
		key: "168",
		value: "Papua New Guinea",
		sn: "PG"
	},
	{
		key: "169",
		value: "Paraguay",
		sn: "PY"
	},
	{
		key: "170",
		value: "Peru",
		sn: "PE"
	},
	{
		key: "171",
		value: "Philippines",
		sn: "PH"
	},
	{
		key: "172",
		value: "Pitcairn",
		sn: "PN"
	},
	{
		key: "173",
		value: "Poland",
		sn: "PL"
	},
	{
		key: "174",
		value: "Portugal",
		sn: "PT"
	},
	{
		key: "175",
		value: "Puerto Rico",
		sn: "PR"
	},
	{
		key: "176",
		value: "Qatar",
		sn: "QA"
	},
	{
		key: "177",
		value: "Republic of the Congo",
		sn: "CG"
	},
	{
		key: "178",
		value: "Reunion",
		sn: "RE"
	},
	{
		key: "179",
		value: "Romania",
		sn: "RO"
	},
	{
		key: "180",
		value: "Russia",
		sn: "RU"
	},
	{
		key: "181",
		value: "Rwanda",
		sn: "RW"
	},
	{
		key: "182",
		value: "Saint Barthelemy",
		sn: "BL"
	},
	{
		key: "183",
		value: "Saint Helena",
		sn: "SH"
	},
	{
		key: "184",
		value: "Saint Kitts and Nevis",
		sn: "KN"
	},
	{
		key: "185",
		value: "Saint Lucia",
		sn: "LC"
	},
	{
		key: "186",
		value: "Saint Martin",
		sn: "MF"
	},
	{
		key: "187",
		value: "Saint Pierre and Miquelon",
		sn: "PM"
	},
	{
		key: "188",
		value: "Saint Vincent and the Grenadines",
		sn: "VC"
	},
	{
		key: "189",
		value: "Samoa",
		sn: "WS"
	},
	{
		key: "190",
		value: "San Marino",
		sn: "SM"
	},
	{
		key: "191",
		value: "Sao Tome and Principe",
		sn: "ST"
	},
	{
		key: "192",
		value: "Saudi Arabia",
		sn: "SA"
	},
	{
		key: "193",
		value: "Senegal",
		sn: "SN"
	},
	{
		key: "194",
		value: "Serbia",
		sn: "RS"
	},
	{
		key: "195",
		value: "Seychelles",
		sn: "SC"
	},
	{
		key: "196",
		value: "Sierra Leone",
		sn: "SL"
	},
	{
		key: "197",
		value: "Singapore",
		sn: "SG"
	},
	{
		key: "198",
		value: "Sint Maarten",
		sn: "SX"
	},
	{
		key: "199",
		value: "Slovakia",
		sn: "SK"
	},
	{
		key: "200",
		value: "Slovenia",
		sn: "SI"
	},
	{
		key: "201",
		value: "Solomon Islands",
		sn: "SB"
	},
	{
		key: "202",
		value: "Somalia",
		sn: "SO"
	},
	{
		key: "203",
		value: "South Africa",
		sn: "ZA"
	},
	{
		key: "204",
		value: "South Korea",
		sn: "KR"
	},
	{
		key: "205",
		value: "South Sudan",
		sn: "SS"
	},
	{
		key: "206",
		value: "Spain",
		sn: "ES"
	},
	{
		key: "207",
		value: "Sri Lanka",
		sn: "LK"
	},
	{
		key: "208",
		value: "Sudan",
		sn: "SD"
	},
	{
		key: "209",
		value: "Suriname",
		sn: "SR"
	},
	{
		key: "210",
		value: "Svalbard and Jan Mayen",
		sn: "SJ"
	},
	{
		key: "211",
		value: "Swaziland",
		sn: "SZ"
	},
	{
		key: "212",
		value: "Sweden",
		sn: "SE"
	},
	{
		key: "213",
		value: "Switzerland",
		sn: "CH"
	},
	{
		key: "214",
		value: "Syria",
		sn: "SY"
	},
	{
		key: "215",
		value: "Taiwan",
		sn: "TW"
	},
	{
		key: "216",
		value: "Tajikistan",
		sn: "TJ"
	},
	{
		key: "217",
		value: "Tanzania",
		sn: "TZ"
	},
	{
		key: "218",
		value: "Thailand",
		sn: "TH"
	},
	{
		key: "219",
		value: "Togo",
		sn: "TG"
	},
	{
		key: "220",
		value: "Tokelau",
		sn: "TK"
	},
	{
		key: "221",
		value: "Tonga",
		sn: "TO"
	},
	{
		key: "222",
		value: "Trinidad and Tobago",
		sn: "TT"
	},
	{
		key: "223",
		value: "Tunisia",
		sn: "TN"
	},
	{
		key: "224",
		value: "Turkey",
		sn: "TR"
	},
	{
		key: "225",
		value: "Turkmenistan",
		sn: "TM"
	},
	{
		key: "226",
		value: "Turks and Caicos Islands",
		sn: "TC"
	},
	{
		key: "227",
		value: "Tuvalu",
		sn: "TV"
	},
	{
		key: "228",
		value: "U.S. Virgin Islands",
		sn: "VI"
	},
	{
		key: "229",
		value: "Uganda",
		sn: "UG"
	},
	{
		key: "230",
		value: "Ukraine",
		sn: "UA"
	},
	{
		key: "231",
		value: "United Arab Emirates",
		sn: "AE"
	},
	{
		key: "232",
		value: "United Kingdom",
		sn: "GB"
	},
	{
		key: "233",
		value: "United States",
		sn: "US"
	},
	{
		key: "234",
		value: "Uruguay",
		sn: "UY"
	},
	{
		key: "235",
		value: "Uzbekistan",
		sn: "UZ"
	},
	{
		key: "236",
		value: "Vanuatu",
		sn: "VU"
	},
	{
		key: "237",
		value: "Vatican",
		sn: "VA"
	},
	{
		key: "238",
		value: "Venezuela",
		sn: "VE"
	},
	{
		key: "239",
		value: "Vietnam",
		sn: "VN"
	},
	{
		key: "240",
		value: "Wallis and Futuna",
		sn: "WF"
	},
	{
		key: "241",
		value: "Western Sahara",
		sn: "EH"
	},
	{
		key: "242",
		value: "Yemen",
		sn: "YE"
	},
	{
		key: "243",
		value: "Zambia",
		sn: "ZM"
	},
	{
		key: "244",
		value: "Zimbabwe",
		sn: "ZW"
	}
];

