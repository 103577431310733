import { ADMIN_BOKKINGS_URL, PROVIDERS_URL } from '../../../../../config';
import { DataTableHelper } from '../../../../../utilities/helpers/DataTableHelper';
import axios from 'axios';

export function getBookingDetails(id) {
	return axios.get(ADMIN_BOKKINGS_URL + '/' + id);
}

export function getUpcomingBookings(
	sortParam,
	pageSize,
	pageNumber,
	upcoming = false,
	cancelToken,
	searchParam = '',
	createDateParam = '',
	filterStatusParams = [],
	filterPaymentStatusParams = [],
	filterProviderParams=[]
) {
	return axios.get(
		(ADMIN_BOKKINGS_URL) +
			(upcoming
				? '?upcoming=true'
				: DataTableHelper.prepareParameters(
						sortParam,
						pageSize,
						pageNumber,
						null,
						searchParam,
						createDateParam,
						filterStatusParams,
						filterPaymentStatusParams,
						filterProviderParams,
						[],
						true
				  )),
		{ cancelToken: cancelToken }
	);
}

export function getProviders() {
	return axios.get(PROVIDERS_URL);
}