import '../../../../assets/css/groups.css';
import '../../../../assets/css/filter.css';
import '../../../../assets/css/tables.css';
import * as common from '../../common/__redux/commonRedux';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SortOptionsSelector from '../../common/sort_options/SortOptionsSelector';
import SimpleLoader from '../../common/SimpleLoader';
import ViewTypeSelector from '../../common/ViewTypeSelector';
import share from "../../../../assets/icons/ic_share.svg";
import GroupListItemCompact from './GroupListItemCompact';
import GroupListItemFull from './GroupListItemFull';
import ShareGroupsDialog from './ShareGroupsDialog';
import ShareGroupDialog from './ShareGroupDialog';
import GroupInfo from './group_dialog/GroupInfo';
import ConfirmationModal from '../../common/ConfirmationModal';
import PaginationController from '../../common/pagination_controller/PaginationController';
import { connect } from 'react-redux';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { groupsDummy } from './dummy';
import { getGroups, deleteProviderGroup } from './networking';
import { getProviderInfo } from '../provider_home/networking';
import { useAccount } from "../../../../utilities/hooks/UseAccount";


const Groups = ({ setWarningMessage }) => {
	const dictionary = useDictionary();
	const account = useAccount();
	const [config, setConfig] = useState(null);
	const [showLoader, setShowLoader] = useState(true);
    const userType = useUserTypeId();
    const [viewType, setViewType] = useState(
		parseInt(localStorage.getItem('groups_selected_view_type') ?? 0)
	);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('groups_selected_page_size') ?? 10)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataLength, setDataLength] = useState(0);
	const [groups, setGroups] = useState([]);
	const [shareUrl, setShareUrl] = useState();
	const [editId, setEditId] = useState('');
	const [showGroupInfo, setShowGroupInfo] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState(null);
	const [showShareDialog, setShareDialogVisibility] = useState(false);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [selectedViewUrl, setSelectedViewUrl] = useState(null);
	const [selectedOption, setSelectedOption] = useState(null);
	const [groupInfoType, setGroupInfoType] = useState(null);
	const [showSharesDialog, setSharesDialogVisibility] = useState(false);
	const source = axios.CancelToken.source();
	const [isVerified, setIsVerified] = useState(account?.verification_status?.value === '3');
	const [errorMessage, setErrorMessage] = useState(
		dictionary.problem_deleting_group
	);

	useEffect(() => {
		if (!showShareDialog) {
			setSelectedGroup(null);
		}
	}, [showShareDialog]);

	useEffect(() => {
		if (selectedOption) getAllGroupsAsync();
	}, [pageSize, currentPage, selectedOption]);

	useEffect(() => {
		getProviderInfoAsync();
		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};

    const onViewTypeChanged = (type) => {
		setViewType(type);
	};

	const setSelectedShareGroup = (group) => {
		setSelectedGroup(group);
		setShareDialogVisibility(true);
	};

    const shareGroups = (group) => {
		setSelectedGroup(group);
		setSharesDialogVisibility(true);
	};

    const createGroup = async () => {
		setGroupInfoType('create');
		setShowGroupInfo(true);
	};

	const groupSaveEvent = async () => {
		setEditId('');
		setGroupInfoType(null);
		setShowGroupInfo(false);
		getAllGroupsAsync();
	};

	const onEditClicked = (id) => {
		setEditId(id);
		setGroupInfoType('edit');
		setShowGroupInfo(true);
	};

	const setSelectedDeleteGroup = (group) => {
		setSelectedGroup(group);
		setDeleteDialogVisibility(true);
	};

	const deleteGroupAsync = async (onSuccess, onFail) => {
		try {
			await deleteProviderGroup(selectedGroup.id);
			onSuccess();
		} catch (error) {
			if (error.response) {
				if (error.response.data.error_code.toString() === '23000') {
					setErrorMessage(dictionary.errors['23000']);
				} else {
					setErrorMessage(error.response.data.message);
				}
			}
			onFail();
		}
	}
	
	const getAllGroupsAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getGroups(
				selectedOption,
				pageSize,
				currentPage,
			 );
			//const data = groupsDummy;	
			setDataLength(data?.recordsTotal);
			setShowLoader(false);
			setGroups(data?.data);
			setShareUrl(data?.share_url);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getProviderInfoAsync = async () => {
		try {
			const result = await getProviderInfo(source.token);
			if (result && result.data) {
				setConfig(result.data);
				setIsVerified(result.data?.verification_status?.value === '3')
			} else {
				throw new Error('Get provider info failed');
			}
		} catch (error) {
			console.error(error);
		}
	};

	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};

	const renderGroups = () => {
		if (viewType === 0) {
			return (
				<>
					{groups.map((group) => (
						<GroupListItemFull
							key={group.id}
							setWarningMessage={setWarningMessage}
							onEditClicked={onEditClicked}
							setSelectedShareGroup={setSelectedShareGroup}
							setSelectedDeleteGroup={setSelectedDeleteGroup}
							setShowLoader={setShowLoader}
							group={group}
							accountConfig={config}
							onStatusChangeCompleted={getAllGroupsAsync}
						/>
					))}
				</>
			);
		} else {
			return (
				<>
					{groups.map((group) => (
						<GroupListItemCompact
							key={group.id}
							setWarningMessage={setWarningMessage}
							onEditClicked={onEditClicked}
							setShowLoader={setShowLoader}
							setSelectedShareGroup={setSelectedShareGroup}
							setSelectedDeleteGroup={setSelectedDeleteGroup}
							group={group}
							accountConfig={config}
							onStatusChangeCompleted={getAllGroupsAsync}
						/>
					))}
				</>
			);
		}
	};

	return (
		<div className="groups-wrapper">
			<div className="groups-top-bar">
                <h2>{dictionary.collections}</h2> 
                <div className="groups-actions">
                    {userType === 2 ? (
						<div className="group-create-button">
							<div
								onClick={createGroup}
								className={`button`}
							>
								<div className="plus-icon" />
                                    {dictionary.create_new_group}
                            </div>
						</div>
					) : null}
                    {/* <button
							onClick={shareGroups}
							className="secondary-button"
							style={{ marginLeft: '25px' }}
						>
                        <div style={{ height: 16, marginRight: 8 }}>
                            <img src={share} alt="share.svg" />
                        </div>
                        {dictionary.share_groups}
                    </button> */}
                </div>
                <div className="flex-filler" />
                <div className="groups-filter-actions">
					<SortOptionsSelector
						onValueChanged={onSortOptionValueChanged}
						name={'groups'}
					/>
					<div style={{ width: '16px' }} />
					<ViewTypeSelector
						onValueChanged={onViewTypeChanged}
						name={'groups'}
					/>
				</div>
                <div className="groups-mobile-actions">
                    {userType === 2 ? (
						<div
							onClick={createGroup}
							className="button"
							style={{ marginLeft: 16, padding: 14 }}
						>
							<div
								className="plus-icon"
								style={{ marginRight: 0, width: 14, height: 14 }}
							/>
						</div>
					) : null}
                </div>
                <div className="groups-mobile-filter-actions">
					<SortOptionsSelector
						onValueChanged={onSortOptionValueChanged}
						name={'groups'}
					/>
					<div style={{ width: '16px' }} />
					<ViewTypeSelector
						onValueChanged={onViewTypeChanged}
						name={'groups'}
					/>
				</div>
		    </div>
			{groups?.length === 0 ? (
				<div className="empty-groups-list-wrapper">
					<div className="empty-groups-list-placeholder">
						<div className="empty-groups-image" />
						<h3
							style={{ textAlign: 'center', marginTop: '24px' }}
							data-testid="no-groups-yet-label"
						>
							{dictionary.start_creating_groups}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.you_will_see_all_of_them_here}
						</div>
						<SimpleLoader showLoader={showLoader} />
					</div>
				</div>
			) : null}
			{groups?.length > 0 ? (
				<div
					className={
						viewType === 0
							? 'group-items-wrapper thin-scrollbar'
							: 'group-items-wrapper thin-scrollbar tile-group-items-wrapper'
					}
				>
					<SimpleLoader showLoader={showLoader} />
					{renderGroups()}
				</div>
		    ) : null} 
			<PaginationController
				name="groups"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			{showShareDialog ? (
				<ShareGroupDialog
					setShareDialogVisibility={setShareDialogVisibility}
					shareUrl={selectedGroup.share_url}
				/>
			) : null}
			{showSharesDialog ? (
				<ShareGroupsDialog
					setShareDialogVisibility={setSharesDialogVisibility}
					shareUrl={shareUrl}
				/>
			) : null}
			{showGroupInfo ? (
				<GroupInfo
					type={groupInfoType}
					showDialog={showGroupInfo}
					setShowDialog={setShowGroupInfo}
					groupSaveEvent={groupSaveEvent}
					editId={editId}
				/>
			) : null}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => getAllGroupsAsync()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteGroupAsync}
					title={dictionary.delete_group}
					message={dictionary.delete_group_message}
					successMessage={dictionary.group_deleted}
					errorMessage={errorMessage}
					action={dictionary.delete}
				/>
			) : null}
        </div>
	);
};

Groups.propTypes = {
	setWarningMessage: PropTypes.func
};

export default connect(common.states, common.actions)(Groups);
