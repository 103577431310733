import React from 'react';
import PropTypes from 'prop-types';

const CustomSelectItem = ({ item, selectedItem, onItemSelected }) => {
	return (
		<div
			onClick={() => onItemSelected(item)}
			className={
				selectedItem === item.value
					? 'custom-select-item custom-select-item-selected'
					: 'custom-select-item'
			}
			data-testid={'custom-select-item' + item.value}
		>
			{item.name}
		</div>
	);
};

CustomSelectItem.propTypes = {
	item: PropTypes.object,
	selectedItem: PropTypes.number,
	onItemSelected: PropTypes.func
};

export default CustomSelectItem;
