import { detectMobile2 } from '../../../../../utilities/helpers/MobileHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import ExperienceStep from '../../new_experience/ExperienceStep';
import {
	createExperienceResource,
	getAvailableExperiences,
	getResourceById
} from '../networking';
import LinkedExperiences from './steps/LinkedExperiences';
import ResourceNameAndDetails from './steps/ResourceNameAndDetails';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const ResourceDetails = () => {
	const dictionary = useDictionary();

	const [step, setStep] = useState(1);
	const [max, setMax] = useState(1);
	const [enabled, setEnabled] = useState(false);

	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [quantity, setQuantity] = useState(0);
	const [capacity, setCapacity] = useState(0);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [showLoaded, setShowLoader] = useState(true);
	const [image, setImage] = useState(null);
	const [experiences, setExperiences] = useState([]);
	const history = useHistory();

	const { id } = useParams();
	const isMobile = detectMobile2();

	const [initLabel, setInitLabel] = useState(
		id ? dictionary.edit_resource : dictionary.create_resource
	);

	const steps = {
		1: (
			<ResourceNameAndDetails
				name={name}
				setName={setName}
				capacity={capacity}
				setCapacity={setCapacity}
				image={image}
				setImage={setImage}
				quantity={quantity}
				setQuantity={setQuantity}
				description={description}
				setDescription={setDescription}
				fileToUpload={fileToUpload}
				setFileToUpload={setFileToUpload}
				setShowLoader={setShowLoader}
				showLoaded={showLoaded}
			/>
		),
		2: (
			<LinkedExperiences
				experiences={experiences}
				setExperiences={setExperiences}
			/>
		)
	};

	useEffect(() => {
		if (id) {
			setMax(2);
			getResourceDetailsAsync();
		} else {
			setShowLoader(false);
			getAllExperiencesAsync();
		}
	}, []);

	useEffect(() => {
		if (step === 1) {
			setEnabled(name !== '' && description !== '');
		} else {
			setEnabled(name !== '' && description !== '' && experiences.length > 0);
		}
	}, [name, description, experiences]);

	const getAllExperiencesAsync = async (existing) => {
		try {
			const { data } = await getAvailableExperiences(
				id !== null && id !== undefined ? id : null
			);

			let experiences = data.data;

			if (existing) {
				experiences.map((item) => {
					if (
						existing.findIndex((experience) => experience.id === item.id) !== -1
					) {
						item.selected = true;
					}
				});
			}
			setExperiences(experiences);
		} catch (error) {
			console.log(error);
		}
	};

	const getResourceDetailsAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getResourceById(id);
			setName(data?.title);
			setDescription(data?.description);
			setImage(data?.image_url);
			setQuantity(data?.quantity);
			getAllExperiencesAsync(data?.experiences);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const setIncrementalStep = (index) => {
		setStep(index);
		if (index > max) {
			setMax(index);
		}
	};

	const onNextClicked = () => {
		if (enabled) {
			if (step === 2) {
				saveResource();
			} else {
				setIncrementalStep(step + 1);
			}
		}
	};

	const onPreviousClicked = () => {
		setIncrementalStep(step - 1);
	};

	// const getButtonLabel = () => {
	// 	if (!id) {
	// 		return dictionary.create;
	// 	} else {
	// 		return dictionary.save;
	// 	}
	// };

	const onCancelClicked = () => {
		history.goBack();
	};

	const saveResource = () => {
		updateResourceAsync(id !== null && id !== undefined);
	};

	const updateResourceAsync = async (update) => {
		setShowLoader(true);

		let selectedExperiences = experiences
			.filter((item) => item.selected)
			.map((item) => item.id);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await createExperienceResource(
				name,
				description,
				quantity,
				fileToUpload,
				selectedExperiences,
				id,
				update
			);
			setShowLoader(false);
			history.goBack();
		} catch (e) {
			setShowLoader(false);
		}
	};

	return (
		<div className="new-experience-wrapper">
			<div className="new-experience-nav-wrapper">
				<div
					className="round-close-button-white new-experience-close-button"
					onClick={() => onCancelClicked()}
				/>
				<div className="new-experience-nav-container">
					<div className="new-experience-nav-top-container">
						<div className="new-experience-nav-top-logo" />
						<div onClick={() => saveResource()} className="sidebar-button">
							{max === 0 ? dictionary.exit : dictionary.save_and_exit}
						</div>
					</div>
					<h2
						className="new-experience-nav-title mobile-invisible"
						style={{ color: 'white' }}
					>
						{initLabel}
					</h2>
					<div className="new-experience-nav-items-container thin-scrollbar">
						<ExperienceStep
							step={1}
							max={max}
							activeStep={step}
							name={dictionary.name_and_details}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={2}
							max={max}
							activeStep={step}
							name={dictionary.linked_experiences}
							setStep={setIncrementalStep}
						/>
					</div>
				</div>
			</div>
			<div className="new-experience-content-wrapper">
				<div className="new-experience-content-container">{steps[step]}</div>
				<div className="new-experience-bottom-navigation-container">
					{step !== 1 ? (
						<div
							onClick={onPreviousClicked}
							className="button secondary-button"
						>
							{dictionary.back}
						</div>
					) : null}
					{2 -
						step +
						1 +
						' ' +
						(isMobile
							? dictionary.steps_left_short
							: dictionary.steps_left_resource)}
					<div
						onClick={onNextClicked}
						className={enabled ? 'button' : 'button button-disabled'}
					>
						{step === 2 ? dictionary.finish : dictionary.next}
					</div>
				</div>
			</div>

			<SimpleLoader showLoader={showLoaded} />
		</div>
	);
};

export default ResourceDetails;
