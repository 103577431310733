import React from 'react';
import { useBookingStatuses } from '../../../../../../utilities/hooks/useBookingStatuses';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import { UseCurrencyDecorator } from '../../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import { ReactComponent as ClockIcon } from '../../../../../../assets/icons/ic_clock.svg';

const SlotBookingIntentItem = ({ booking, onBookingClicked }) => {
	const bookingStatuses = useBookingStatuses();
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();

	const getBookingStatus = (status) => {
		return (
			<div
				className={'booking-status ' + bookingStatuses[status].class}
				data-testid={'booking-status'}
			>
				{bookingStatuses[status].text}
			</div>
		);
	};

	const getPaymentStatus = (status) => {
		if (status === 'paid') {
			return (
				<div
					className="booking-customer-container"
					data-testid="payment-status-paid"
				>
					<div className="booking-status-paid-icon" />
					<span>{dictionary.paid}</span>
				</div>
			);
		} else if (status === 'refunded') {
			return (
				<div
					className="booking-customer-container"
					data-testid="payment-status-refunded"
				>
					<div className="booking-status-refunded-icon" />
					<span>{dictionary.refunded}</span>
				</div>
			);
		} else if (status === 'pending') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-pending-icon">
						<ClockIcon />
					</div>
					<div>{dictionary.pending}</div>
				</div>
			)
		}  else {
			return (
				<div
					className="booking-customer-container"
					data-testid="payment-status-unpaid"
				>
					<div className="booking-status-unpaid-icon" />
					<span>{dictionary.no_charge}</span>
				</div>
			);
		}
	};

	return (
		<tr
			className="slot-booking-container"
			key={booking.id}
			onClick={() => onBookingClicked(booking)}
			data-testid="slot-booking-container"
		>
			<td>
				<div className="booking-number" data-testid="booking-number">
					{booking.display_id}
				</div>
			</td>
			<td>{getBookingStatus(booking.status)}</td>
			<td>
				<div
					className="booking-customer-container"
					data-testid="booking-customer-name"
				>
					<img className="booking-customer-avatar" src={booking.avatar_url} />
					<div>{booking.customer_name}</div>
				</div>
			</td>
			<td>
				<div
					data-testid="booking-participants-quantity"
					className="booking-participants-quantity"
				>
					<div>{booking?.participants_quantity || 0}</div>
				</div>
			</td>
			<td>{getPaymentStatus(booking.payment_status)}</td>
			<td>
				<div className="booking-price" data-testid="booking-price">
					{currencyDecorator(booking.amount/100)}
				</div>
			</td>
		</tr>
	);
};

SlotBookingIntentItem.propTypes = {
	booking: PropTypes.object,
	onBookingClicked: PropTypes.func
};

export default SlotBookingIntentItem;
