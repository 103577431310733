import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { getLegalFiles } from './networking';
import React from 'react';
import PropTypes from 'prop-types';

export const documentTypes = {
	1: 'id_document',
	2: 'proof_of_address',
	3: 'iban_document',
	4: 'company_document'
};

const ApplicationDocumentItem = ({ document, changeDocumentStatusAsync }) => {
	const dictionary = useDictionary();

	const getStatusLabel = () => {
		let statuses = {
			approved: {
				class: 'status-label-valid'
			},
			pending: {
				class: 'status-label-warning'
			},
			rejected: {
				label: dictionary.paused,
				class: 'status-label-error'
			}
		};
		return (
			<div
				className={'status-label ' + statuses[document?.status?.key].class}
				title={dictionary[documentTypes[document.type.value] + '_text']}
				style={{ minWidth: '160px' }}
			>
				{dictionary[documentTypes[document.type.value]]}
			</div>
		);
	};

	const handleDownloadFile = async (documentId) => {
		try {
			const response = await getLegalFiles(documentId);
			const contentType = response.headers['content-type'];
			const fileName = 'legal-file.' + contentType.split('/')[1];
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = window.document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName);
			// document.body.appendChild(link);
			link.click();
			// link.parentNode.removeChild(link);
		} catch (error) {
			console.log('Error downloading legal file', error);
		}
	};

	return (
		<div className="application-document-item-wrapper">
			<div className="application-document-item-container">
				<div
					style={{
						width: '100%',
						display: 'flex',
						flexFlow: 'column',
						alignItems: 'center'
					}}
				>
					<div
						style={{
							display: 'flex',
							minHeight: '40px',
							justifyContent: 'space-between'
						}}
					>
						{/*<div title={dictionary[documentTypes[document.type.value]+'_text']} style={{minWidth:'150px'}} className='text-with-info'>{dictionary[documentTypes[document.type.value]]}</div>*/}
						{getStatusLabel()}
					</div>
					{document !== null ? (
						<>
							<div className="dialog-title-divider" />
							<div className="application-document-buttons-container">
								<div
									onClick={() => handleDownloadFile(document.id)}
									className="tertiary-button"
									data-testid={'download-document-btn'}
								>
									{dictionary.download}
								</div>
							</div>
						</>
					) : (
						<h2>{dictionary.not_uploaded_yet}</h2>
					)}
				</div>
			</div>
			<div>
				<div
					onClick={() => changeDocumentStatusAsync(document.id, 'approved')}
					className="experience-item-label experience-item-label-active"
					style={{ cursor: 'pointer' }}
					data-testid={'approve-document-btn'}
				>
					{dictionary.approve}
				</div>

				<div
					onClick={() => changeDocumentStatusAsync(document.id, 'rejected')}
					className="experience-item-label experience-item-label-error"
					style={{ marginTop: '12px', cursor: 'pointer', textAlign: 'center' }}
					data-testid={'reject-document-btn'}
				>
					{dictionary.reject}
				</div>
			</div>
		</div>
	);
};

ApplicationDocumentItem.propTypes = {
	document: PropTypes.object,
	changeDocumentStatusAsync: PropTypes.func
};

export default ApplicationDocumentItem;
