export const calendarColors = [
	'#CBCBCB',
	'#BC9CEF',
	'#A5B1FD',
	'#7BBBFD',
	'#87D788',
	'#FFE75B',
	'#FBB662',
	'#E56F6B'
];

export const phoneNumberMask = [
	'+',
	'(',
	/[1-9]/,
	/\d/,
	')',
	/\d/,
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/
];
