import useComponentVisible from '../../../../utilities/hooks/useComponentVisible';
import PropTypes from 'prop-types';
import React from 'react';
import {NavLink, useHistory} from 'react-router-dom';

const CollapsedNavGroup = ({
	item,
	count,
	groupIndex,
	selectedIndex,
	setSelectedIndex
}) => {
	const history = useHistory();
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	const isActive = item.items.some(subItem => {
		console.log("subItem:", subItem.url);
		console.log("history:", history.location.pathname);
		return `/${subItem.url}` === history.location.pathname
	});

	const onHeaderClicked = () => {
		setSelectedIndex(groupIndex);
		setIsComponentVisible(true);
	};

	console.log("isActive???", isActive);

	return (
		<div
			key={item.title}
			className={`nav-group-container nav-group-container-collapsed ${isActive ? 'nav-group-container-active' : ''}`}
		>
			<div
				onClick={(e) => onHeaderClicked(e)}
				className="nav-group-header-container"
			>
				<span className="nav-item-icon">{item.icon}</span>
			</div>
			{selectedIndex === groupIndex && isComponentVisible ? (
				<div ref={ref} className="collapsed-nav-group-items-container">
					{item.items.map((item) => (
						<NavLink
							onClick={() => {
								setSelectedIndex(-1);
							}}
							to={'/' + item.url}
							key={count++}
						>
							<div
								className="collapsed-nav-group-item"
								style={{ backgroundColor: 'transparent' }}
							>
								{item.title}
							</div>
						</NavLink>
					))}
				</div>
			) : null}
		</div>
	);
};

CollapsedNavGroup.propTypes = {
	item: PropTypes.object,
	count: PropTypes.number,
	groupIndex: PropTypes.number,
	selectedIndex: PropTypes.number,
	setSelectedIndex: PropTypes.func
};

export default CollapsedNavGroup;
