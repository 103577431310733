import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';

const WizardCompanyForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);

	const wizardProfileSchema = Yup.object().shape({
		company_name: Yup.string().required(dictionary.company_name_required),
		legal_name: Yup.string().required(dictionary.legal_name_required),
		short_description: Yup.string().required(
			dictionary.short_description_required
		),
		//vat_number: FormikHelper.getDefaultYupTextInput(dictionary.vat_number_required),
		website: Yup.string()
	});

	const formik = useFormik({
		initialValues: {
			company_name: props.details.company_name,
			legal_name: props.details.legal_name,
			//vat_number: props.details.vat_number,
			website: props.details.website,
			short_description: props.details.short_description
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, props.details);
			newDetails.company_name = values.company_name;
			newDetails.legal_name = values.legal_name;
			//newDetails.vat_number = values.vat_number;
			newDetails.website = values.website;
			newDetails.short_description = values.short_description;

			props.setDetails(newDetails);
			props.setStep(3);
		},
		validateOnMount: true,
		validationSchema: wizardProfileSchema
	});

	FormikHelper.setFormik(formik);

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	return (
		<form
			style={{ opacity: showForm ? '1' : '0', overflowY: 'auto' }}
			className="login-form-content-container transparent-form thin-scrollbar"
			onSubmit={formik.handleSubmit}
		>
			<h1>{dictionary.step2_title}</h1>
			{/*<div className="text">{dictionary.step2_message}</div>*/}
			{FormikHelper.getInputTemplate(
				'company_name',
				dictionary.brand_name,
				'Upiria',
				'text',
				true,
				true
			)}
			{FormikHelper.getInputTemplate(
				'legal_name',
				dictionary.legal_name,
				'Upiria P.C',
				'text',
				true,
				true
			)}
			{FormikHelper.getTextareaTemplate(
				'short_description',
				dictionary.short_profile,
				dictionary.provide_short_description_provider,
				true,
				true
			)}
			{/*{FormikHelper.getInputTemplate('vat_number', dictionary.vat_number, '00000000', 'text', true, true)}*/}
			{FormikHelper.getInputTemplate(
				'website',
				dictionary.website,
				'https://www.upiria.com',
				'text',
				false,
				true
			)}

			<div className="group-inputs" style={{ marginTop: '30px' }}>
				<button
					data-testid="continue-button"
					type="submit"
					className={formik.isValid ? 'button' : 'button button-disabled'}
				>
					{dictionary.continue}
				</button>
			</div>
		</form>
	);
};

WizardCompanyForm.propTypes = {
	details: PropTypes.object,
	setDetails: PropTypes.func,
	setStep: PropTypes.func
};

export default WizardCompanyForm;
