import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as common from '../../../common/__redux/commonRedux';
import * as Yup from 'yup';
import {inviteCompany} from "../networking";

const InviteModal = ({ setShow, setSuccessMessage, setErrorMessage}) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);

	const invitationSchema = Yup.object().shape({
		email: Yup.string().email().required(dictionary.email_required),
		company_name: Yup.string().required(dictionary.company_name_required)
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			company_name: ''
		},
		onSubmit: (values) => {
			let data = Object.assign({}, values);
			sendInviteAsync(data);
		},
		validateOnMount: true,
		validationSchema: invitationSchema
	});

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShow(false);
		}, 800);
	};

	const sendInviteAsync = async (data) => {
		onCancelClicked();

		try {
			await inviteCompany(data);
			setSuccessMessage(dictionary.invitation_sent);
		} catch (e) {
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
			}
		}
	}

	FormikHelper.setFormik(formik);

	return (
		<form className="dialog-wrapper" style={{ opacity: opacity }} onSubmit={formik.handleSubmit}>
			<div className="dialog-container" style={{ minHeight: 382 }}>
				<div className="dialog-title-container">
					<div className="dialog-title">{dictionary.invite_company}</div>
					<div
						className="dialog-close-button"
						onClick={() => onCancelClicked()}
					/>
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container-with-scroll thin-scrollbar" style={{height: '260px'}}>
					{FormikHelper.getInputTemplate(
						'email',
						dictionary.email,
						dictionary.enter_email,
						'email',
						true,
						true,
						false,
						null,
						false
					)}
					{FormikHelper.getInputTemplate(
						'company_name',
						dictionary.company,
						dictionary.company_name,
						'text',
						true,
						true,
						false,
						null,
						false
					)}
				</div>
				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{
						padding: '12px',
						marginBottom: '0',
						justifyContent: 'space-between'
					}}
				>
					<div className="tertiary-button" onClick={onCancelClicked}>
						{dictionary.cancel}
					</div>
					<button
						type={'submit'}
						disabled={!formik.isValid}
						className={formik.isValid ? 'button' : 'button button-disabled'}
					>
						{dictionary.invite}
					</button>
				</div>
			</div>
		</form>
	);
};

InviteModal.propTypes = {
	setShow: PropTypes.func.isRequired,
	setSuccessMessage: PropTypes.func,
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(InviteModal);
