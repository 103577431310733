import '../../../../assets/css/dropdown_button.css';
import downArrow from '../../../../assets/icons/ic_caret_down.svg';
import check from '../../../../assets/icons/ic_check.svg';
import useComponentVisible from '../../../../utilities/hooks/useComponentVisible';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const DropdownButton = ({
	options,
	setOption,
	style,
	label = null
}) => {
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	const onOptionClicked = (newOption) => {
		setOption(newOption);
		setIsComponentVisible(false);
	};

	useEffect(() => {
		if (isComponentVisible === false) {
			setOption(null);
		}
	}, [isComponentVisible]);

	return (
		<div className="options-button-wrapper" ref={ref} style={style}>
			<div
				onClick={() => setIsComponentVisible(!isComponentVisible)}
				className="options-button options-button-confirmed"
				style={{
					backgroundColor: isComponentVisible
						? 'rgba(73, 78, 128, 0.05)'
						: 'white'
				}}
			>
				{label}
				<img src={downArrow} />
			</div>

			{isComponentVisible ? (
				<div className="booking-more-actions-container options-button-options-container">
					{Object.keys(options).map((key) =>
						options[key].disabled ? (
							<></>
						) : (
							<div
								key={key}
								onClick={() => onOptionClicked(options[key].text)}
								className="booking-more-action-container"
							>
								<div className="booking-more-action-icon">
									{options[key].icon}
								</div>
								{options[key]?.type !== 'call' ? (
									<div>{options[key].text}</div>
								) : (
									<div><a href={`tel:${options[key].phone}`}>{options[key].text}</a></div>
								)}
							</div>
						)
					)}
				</div>
			) : null}
		</div>
	);
};

DropdownButton.propTypes = {
	options: PropTypes.object,
	setOption: PropTypes.func,
	style: PropTypes.object,
	label: PropTypes.string.isRequired
};

export default DropdownButton;
