import avatar from '../../../../assets/images/avatar.svg';
import loader from '../../../../assets/lottie/upiria_loader.json';
import { MAX_FILE_UPLOAD_IMAGES } from '../../../../config';
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import CustomToast from '../../common/CustomToast';
import Tooltip from '../../common/Tooltip';
import * as common from '../../common/__redux/commonRedux';
import { deleteSinglePhoto, uploadSinglePhoto } from './networking';
import 'cropperjs/dist/cropper.css';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import Cropper from 'react-cropper';
import { connect } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

const ProfilePhoto = ({
	setPhoto,
	photo,
	type,
	url,
	title,
	setSuccessMessage,
	setErrorMessage,
	isVerified
}) => {
	const dictionary = useDictionary();
	const [picture, setPicture] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const input = useRef(null);
	const [image, setImage] = useState();
	const [cropData, setCropData] = useState();
	const cropperRef = useRef(null);

	const onFileSelected = (e) => {
		const validTypes = ['image/jpeg', 'image/png'];
		const selectedFile = e.target.files[0];

		const { size, type } = selectedFile;
		if (size > MAX_FILE_UPLOAD_IMAGES) {
			setErrorMessage('loc1');

			return;
		}
		if (!validTypes.includes(type)) {
			setErrorMessage('loc2');

			return;
		}

		const reader = new FileReader();
		reader.onload = () => {
			setImage(reader.result);
		};
		reader.readAsDataURL(selectedFile);
	};

	const onDeleteClicked = async () => {
		await deletePhotoAsync();
	};

	const deletePhotoAsync = async () => {
		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteSinglePhoto(url, photo.id);
			setPhoto(null);
			setPicture(null);
			input.current.value = null;
			setSuccessMessage(dictionary.photo_delete_success);
		} catch (e) {
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
			}
		} finally {
			setShowLoader(false);
		}
	};

	const onPictureLoaded = (e) => {
		URL.revokeObjectURL(e.target.src);
	};

	const onUpload = () => {
		uploadPhotoAsync(cropData);
	};

	const uploadPhotoAsync = async (dataURL) => {
		setShowLoader(true);
		try {
			const blobBin = atob(dataURL.split(',')[1]);
			const array = [];
			for (let i = 0; i < blobBin.length; i++) {
				array.push(blobBin.charCodeAt(i));
			}
			const file = new Blob([new Uint8Array(array)], {
				type: 'image/png',
				encoding: 'utf-8'
			});
			const { data } = await uploadSinglePhoto(url, type, file);
			if (data?.path) {
				localStorage.setItem('company_photo_path', data.path);
			}
			
			setPhoto(data);
			setShowLoader(false);
			setImage(null);
			setCropData(null);
			toast.success(
				<CustomToast
					type="info"
					message={dictionary.photo_upload_successfully}
				/>,
				{
					position: 'bottom-right',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				}
			);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const getPicture = () => {
		if (picture) {
			return picture;
		} else if (photo) {
			return ImageHelper.getFullImageUrl(photo?.path);
		} else {
			return avatar;
		}
	};

	const onSelectClicked = () => {
		if (isVerified) {
			input.current.click();
		}
	};

	const onCrop = () => {
		const cropper = cropperRef.current?.cropper;
		setCropData(cropper.getCroppedCanvas().toDataURL());
	};

	return (
		<div className="profile-photo-container">
			<div className="onboarding-activity-title" style={{ marginTop: 20 }}>
				{title}
			</div>
			<div className="profile-photo-wrapper">
				<div className="profile-photo-content">
					<img
						src={getPicture()}
						onLoad={onPictureLoaded}
						className="profile-photo"
					/>
					{cropData && (
						<div className="cropped-photo-wrapper">
							<img className="cropped-photo" src={cropData} alt="cropped" />
						</div>
					)}
					<input
						ref={input}
						accept=".jpeg, .jpg, .png"
						onChange={onFileSelected}
						type="file"
						className="profile-photo-input"
						data-testid="profile-photo-input"
					/>
					<div
						onClick={cropData && onUpload}
						style={{ width: '100%' }}
						className={`secondary-button ${
							!isVerified || !cropData ? 'secondary-button-upload-disabled' : ''
						}`}
						data-testid="upload-photo-button"
					>
						{dictionary.upload_photo}
						{!isVerified ? (
							<Tooltip content={dictionary.user_not_verified} />
						) : null}
					</div>
					{photo ? (
						<div
							onClick={onDeleteClicked}
							className="tertiary-button"
							style={{ marginTop: '10px', width: '100%' }}
							data-testid="delete-photo-button"
						>
							{dictionary.remove_photo}
						</div>
					) : null}
				</div>
				{image && (
					<div className="profile-preview-content">
						<Cropper
							style={{ height: 300, width: '100%' }}
							initialAspectRatio={1}
							preview=".img-preview"
							src={image}
							ref={cropperRef}
							viewMode={1}
							guides={true}
							minCropBoxHeight={10}
							minCropBoxWidth={10}
							background={false}
							responsive={true}
							checkOrientation={false}
							crop={onCrop}
						/>
					</div>
				)}
				{showLoader ? (
					<div className="photo-loader-container">
						<Lottie loop={true} animationData={loader} />
					</div>
				) : null}
			</div>
			<ToastContainer />
		</div>
	);
};

ProfilePhoto.propTypes = {
	setPhoto: PropTypes.func,
	photo: PropTypes.object,
	type: PropTypes.string,
	url: PropTypes.string,
	title: PropTypes.string,
	setSuccessMessage: PropTypes.func,
	setErrorMessage: PropTypes.func,
	isVerified: PropTypes.bool
};

export default connect(common.states, common.actions)(ProfilePhoto);
