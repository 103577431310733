import React from 'react';
import PropTypes from 'prop-types';

const ExperienceItem = ({ experience }) => {
	return (
		<div
			key={experience.id}
			className="experience-row-container"
			style={{ marginBottom: '16px' }}
			data-testid="participant-item"
		>
			<div style={{ display: 'flex' }}>
				{<img className="participant-avatar" />}
				<div>
					<h4
						style={{ marginLeft: '8px' }}
					>
						{experience.title}
					</h4>
					<h4
						style={{ fontWeight: 'normal', marginLeft: '8px', marginTop: '3px', cursor: 'pointer' }}
						onClick={() => window.open(experience.share_url, '_blank')}
					>
						{experience.share_url}
					</h4>
				</div>
			</div>
		</div>
	);
};

ExperienceItem.propTypes = {
	experience: PropTypes.object.isRequired
};

export default ExperienceItem;
