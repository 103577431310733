import success from '../../../assets/icons/ic_success.svg';
import error from '../../../assets/icons/ic_fail.svg';
import warning from '../../../assets/icons/ic_warning.svg';
import React from 'react';
import PropTypes from 'prop-types';

const ResultModal = ({ message, type, opacity }) => {
	const iconsMap = {
		success: success,
		warning: warning,
		error: error
	};

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div
				className="dialog-container dialog-container-narrow"
				style={{ padding: '24px', alignItems: 'center', gap: '12px' }}
			>
				<img className="modal-large-icon" src={iconsMap[type]} />
				<h4>{message}</h4>
			</div>
		</div>
	);
};

ResultModal.propTypes = {
	message: PropTypes.string,
	type: PropTypes.string,
	opacity: PropTypes.number
};

export default ResultModal;
