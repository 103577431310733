import React from 'react';
import { useBookingStatuses } from '../../../../../../../../utilities/hooks/useBookingStatuses';
import { useDictionary } from '../../../../../../../../utilities/hooks/UseDictionary';
import { UseCurrencyDecorator } from '../../../../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import { ReactComponent as ClockIcon } from '../../../../../../../../assets/icons/ic_clock.svg';

const BookingsTabItem = ({ booking, onBookingClicked }) => {
	const bookingStatuses = useBookingStatuses();
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();

	const getBookingStatus = (status) => {
		return (
			<div
				className={'booking-status ' + bookingStatuses[status].class}
				data-testid={'booking-status'}
			>
				{bookingStatuses[status].text}
			</div>
		);
	};

	const getPaymentStatus = (status) => {
		if (status === 'paid') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-paid-icon" />
					<div>{dictionary.paid}</div>
				</div>
			);
		} else if (status === 'refunded') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-refunded-icon" />
					<div>{dictionary.refunded}</div>
				</div>
			);
		} else if (status === 'pending') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-pending-icon">
						<ClockIcon />
					</div>
					<div>{dictionary.pending}</div>
				</div>
			)
		} else if (status === 'failed') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-unpaid-icon"></div>
					<div>{dictionary.failed}</div>
				</div>
			);
		} else if (status === 'partial_refunded') {
			return (
				<div className="booking-customer-container">
					<div className="booking-status-refunded-icon" />
					<div>{dictionary.partial_refunded}</div>
				</div>
			);
		}
		return (
			<div className="booking-customer-container">
				<div className="booking-status-unpaid-icon" />
				<div>{dictionary.no_charge}</div>
			</div>
		);

	};

	return (
		<tr
			className="slot-booking-container"
			key={booking.id}
			onClick={() => onBookingClicked(booking)}
			data-testid="slot-booking-container"
		>
			<td>
				<div className="booking-number" data-testid="booking-number">
					{booking.display_id}
				</div>
			</td>
			<td>{getBookingStatus(booking.status.value)}</td>
			<td>
				<div
					className="booking-customer-container"
					data-testid="booking-customer-name"
				>
					<img className="booking-customer-avatar" src={booking.customer.avatar_url} />
					<div className='truncate provider-booking-customer-name'>{booking.customer.name}</div>
				</div>
			</td>
			<td>
				<div
					data-testid="booking-participants-quantity"
					className="booking-participants-quantity"
				>
					<div>{(booking.participants ? (booking.participants > 1 ? (booking.participants + dictionary.spot) : (booking.participants + dictionary.spots)) : 0 )}</div>
				</div>
			</td>
			<td>{getPaymentStatus(booking.payment_status.value)}</td>
			<td>
				<div className="booking-price" data-testid="booking-price">
					{currencyDecorator(booking.amount)}
				</div>
			</td>
		</tr>
	);
};

BookingsTabItem.propTypes = {
	booking: PropTypes.object,
	onBookingClicked: PropTypes.func
};

export default BookingsTabItem;
