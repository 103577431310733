import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import RejectRequestDialog from './RejectRequestDialog';
import SuggestDateDialog from './SuggestDateDialog';
import { Link } from 'react-router-dom';
import { NavigatorPaths } from '../../../../../assets/dictionaries/NavigatorPaths';
import { ReactComponent as AlertIcon } from '../../../../../assets/icons/ic_alert.svg'
import { ReactComponent as DateCalendarIcon } from '../../../../../assets/icons/ic_calendar_date.svg';
import { getBookingRequests, updateBookingRequestState } from "../networking";
import { UseCurrencyDecorator } from "../../../../../utilities/hooks/UseCurrencyDecorator";
import { DateFormatHelper } from "../../../../../utilities/helpers/DateFormatHelper";
import ConfirmRequestDialog from "./ConfirmRequestDialog";
import { useHistory } from "react-router-dom";
import {getBookingDetails} from "../../bookings/networking";
import SimpleLoader from "../../../common/SimpleLoader";
import {getExperienceSlots} from "../../provider_calendar/networking";
import CustomToast from '../../../common/CustomToast';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BookingRequests = () => {
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();
	const [showRejectRequestDialog, setShowRejectRequestDialog] = useState(false);
	const [showConfirmRequestDialog, setShowConfirmRequestDialog] = useState(false);
	const [showSuggestDateDialog, setShowSuggestDateDialog] = useState(false);
	const [bookingRequests, setBookingRequests] = useState(null);
	const [selectedRequest, setSelectedRequest] = useState(null);
	const [selectedDetail, setSelectedDetail] = useState(null);
	const [showLoader, setLoaderVisibility] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const history = useHistory();
	const onBookingClicked = (booking) => {
		history.push(`/bookings/${booking.id}`, booking);
	};
	useEffect(() => {
		getBookingRequestsAsync();
	}, []);
	const getBookingRequestsAsync = async () => {
		try {
			let result = await getBookingRequests();
			setBookingRequests(result.data.data);
		} catch (e) {
			console.log(e);
		}
	};

	const getDetailsByIdAsync = async (id) => {
		try {
			let { data } = await getBookingDetails(id);
			setSelectedDetail(data);

			setLoaderVisibility(false);
		} catch (e) {
			setLoaderVisibility(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
				setTimeout(() => {
					setErrorMessage('');
				}, 2000);
			}
		}
	};

	const handleOpenRejectRequestDialog = (event, request) => {
		event.stopPropagation();
		event.preventDefault();
		setShowRejectRequestDialog(true);
		setSelectedRequest(request);
	};

	const handleOpenConfirmRequestDialog = (event, request) => {
		event.stopPropagation();
		event.preventDefault();
		setLoaderVisibility(true);
		getDetailsByIdAsync(request.id).then(() => {
			setShowConfirmRequestDialog(true);
			setSelectedRequest(request);
		})
	};

	const handleOpenSuggestDateDialog = (event, request) => {
		event.stopPropagation();
		event.preventDefault();
		setSelectedRequest(request);
		setShowSuggestDateDialog(true);
	};

	const handleUpdateRequestStatus = async (data) => {
		try {
			setLoaderVisibility(true);
			const response = await updateBookingRequestState(selectedRequest.id, data);
			getBookingRequestsAsync();
			setSelectedRequest(null);
			setShowConfirmRequestDialog(false);
			setShowSuggestDateDialog(false);
			setShowRejectRequestDialog(false);
			setLoaderVisibility(false);
		} catch (error) {
			setLoaderVisibility(false);
			console.log(error);
			if (error?.response?.data?.message) {
				toast.error(<CustomToast message={error.response.data.message} />,
				{
					position: 'bottom-left',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				});
			}
			
		}
	}

	const seeCalendar = (e, request) => {
		e.stopPropagation();
		history.push(NavigatorPaths.calendar.path, request);
	};

	const goToSlotDetailPage = async (e, request) => {
		e.stopPropagation();
		e.preventDefault();
		const { data } = await getExperienceSlots(
			request.slot_start_time,
			request.slot_end_time,
		);

		const isExperience = !!data.experiences.find(experience => experience.id === request.slot_id);
		const isResource = !!data.resources.find(resource => resource.id === request.slot_id);
		const type = isExperience ? 'experience' : isResource ? 'resource' : undefined;
		if (type) {
			history.push(`/calendar/${request.slot_id}?${type}`);
		}
	}

	return (
		<>
			{!!bookingRequests?.length &&
				<div className="booking-requests">
					<h2 style={{marginBottom: '16px'}}>{dictionary.booking_request}</h2>
					{bookingRequests?.map((request, index) => (
						<div
							key={index}
							className="booking-request-item"
							onClick={() => onBookingClicked(request)}
						>
							<div className="booking-request-item-lead flex-1">
								<div className="booking-request-title" style={{gap: 16}}>
									<h4 className="">{request.experience_title}</h4>
									<h4 className="nowrap">{currencyDecorator(request.amount)}</h4>
								</div>

								<div className="description">
									<div
										className="d-flex items-center truncate"
										style={{
											color: request.overlap ? '#FD4F4F' : '#22264C99',
											gap: 8
										}}
										onClick={(e) => goToSlotDetailPage(e, request)}
									>
										{request.overlap && <AlertIcon />}
										{/*Oct 9, 2021, 10:00 - 11:00 • {request.participants}{' '}*/}

										{DateFormatHelper.rangeDayMonthYearHourFormat(request?.slot_start_time, request?.slot_end_time)} • {request.participants}{' '}
										{request.participants > 1
											? dictionary.participants
											: dictionary.participant}
									</div>
									{request.overlap && (
										<button className="see-calendar nowrap" onClick={(e) => seeCalendar(e, request)}>
											{dictionary.see_calendar}
										</button>
									)}
								</div>
							</div>
							<div className="booking-request-item-actions">
								<div className="booking-request-item-actions-left">
									<button
										className="button button--small"
										onClick={(e) => handleOpenConfirmRequestDialog(e, request)}
									>
										{dictionary.confirm}
									</button>
									<button
										className="button secondary-button button--small"
										onClick={(e) => handleOpenRejectRequestDialog(e, request)}
									>
										{dictionary.decline}
									</button>
								</div>

								<div className="responsive-divider"/>
								<button
									className="button secondary-button button--small"
									onClick={(e) => handleOpenSuggestDateDialog(e, request)}
								>
									<DateCalendarIcon/>
									{dictionary.suggest_new_date}
								</button>
							</div>
						</div>
					))}
				</div>
			}
			<SimpleLoader showLoader={showLoader} />
			{showRejectRequestDialog && selectedRequest && (
				<RejectRequestDialog
					request={selectedRequest}
					setShowDialog={setShowRejectRequestDialog}
					onReject={handleUpdateRequestStatus}
				/>
			)}
			{showConfirmRequestDialog && selectedRequest && (
				<ConfirmRequestDialog
					onConfirm={handleUpdateRequestStatus}
					setShowDialog={setShowConfirmRequestDialog}
					detail={selectedDetail}
				/>
			)}
			{showSuggestDateDialog && selectedRequest && (
				<SuggestDateDialog
					request={selectedRequest}
					setShowDialog={setShowSuggestDateDialog}
					onSuggest={handleUpdateRequestStatus}
				/>
			)}
			<ToastContainer />
		</>
	);
};
export default BookingRequests;
