import ic_users from '../../../../../../assets/icons/ic_users.svg';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import SlotParticipantItem from './SlotParticipantItem';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const SlotParticipantsAndAddons = ({ details }) => {
	const dictionary = useDictionary();

	const isResource = 'experiences' in details;

	const groups = useMemo(() => {
		let result = {};
		details?.bookings?.map((booking) => {
			booking?.participant_groups?.map((group) => {
				result[group?.name] = group?.rate;
			});
		});

		return result;
	}, [details]);

	const availableParticipants = useMemo(() =>
		details?.bookings?.filter(booking => booking.payment_status.value === 'paid' || (booking.status.value === 'confirmed' && booking.payment_status.value === 'no_charge')
		),
		[details]);

	return (
		<div className="settings-page-container thin-scrollbar fade-on-load">
			<>
				<div
					className="participant-row-container"
					style={{
						justifyContent: 'space-between',
						marginBottom: '20px',
						minWidth: 360,
						paddingBottom: 16,
						borderBottom: '1px solid rgba(73, 78, 128, 0.17)'
					}}
				>
					<h3 className="participant-header" data-testid="participants-header">
						{dictionary.participants}{' '}
						<span style={{ marginLeft: 8, fontSize: 12, fontWeight: 400 }}>
							{details?.booked + '/' + details?.capacity?.toString()}{' '}
							{dictionary.seats}
						</span>
					</h3>
					{/*<h3 className="participant-header" data-testid="participants-header">{dictionary.add_ons}</h3>*/}
					<h3
						className="booking-number-header"
						data-testid="booking-number-header"
					>
						{dictionary.booking} #
					</h3>
				</div>
				{availableParticipants?.length > 0 ? (
					availableParticipants.map((booking, index) => (
						<div key={index}>
							<SlotParticipantItem
								key={booking}
								isResource={isResource}
								details={details}
								booking={booking}
							/>
							{index < details.bookings.length - 1 && (
								<div
									className="title-divider"
									style={{ background: 'rgba(73, 78, 128, 0.17)', marginTop: 16, marginBottom: 16 }}
								/>
							)}
						</div>
					))
				) : (
					<div className="empty-participants-wrapper">
						<div className="empty-participants">
							<div className="empty-participants-icon-wrapper">
								<img src={ic_users} />
							</div>
							<h5 className="empty-participants-title">
								{dictionary.no_participants_yet}
							</h5>
							<p className="empty-participants-description">
								{dictionary.all_participants_will_be_listed}
							</p>
						</div>
						{/*<div className="empty-participants">*/}
						{/*	<div className="empty-participants-icon-wrapper">*/}
						{/*		<img src={ic_four_circles} />*/}
						{/*	</div>*/}
						{/*	<h5 className="empty-participants-title">{dictionary.no_participants_yet}</h5>*/}
						{/*	<p className="empty-participants-description">*/}
						{/*		{dictionary.all_participants_will_be_listed}*/}
						{/*	</p>*/}
						{/*</div>*/}
						<div style={{ width: 81 }} />
					</div>
				)}
			</>
			{/* <AddButtonSquare isRound={true} label={dictionary.add_new_participant}/>*/}

			{/*<div className='participant-row-container' style={{justifyContent: 'space-between', marginBottom:'20px'}}>
            <h3 style={{marginTop:'36px'}}>{dictionary.add_ons}</h3>
            <div style={{fontWeight: 'normal', opacity: '0.6'}}
                 className='booking-number'>{details.add_ons.length.toString() + ' ' + dictionary.add_ons}</div>
        </div>
        {details.add_ons.map(addon =>
            <AddonItem addon={addon}/>
        )}*/}
		</div>
	);
};

SlotParticipantsAndAddons.propTypes = {
	details: PropTypes.shape({
		bookings: PropTypes.array,
		booked: PropTypes.number,
		capacity: PropTypes.string
	})
};

export default SlotParticipantsAndAddons;
