import '../../../../assets/css/experiences.css';
import { ReactComponent as ShareNetworkIcon } from '../../../../assets/icons/ic_share_network.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import ConfirmationModal from '../../common/ConfirmationModal';
import FormLoader from '../../common/FormLoader';
import Tooltip from '../../common/Tooltip';
import ViewTypeSelector from '../../common/ViewTypeSelector';
import * as common from '../../common/__redux/commonRedux';
import PaginationController from '../../common/pagination_controller/PaginationController';
import SortOptionsSelector from '../../common/sort_options/SortOptionsSelector';
import NewExperience from '../new_experience/NewExperience';
import { getProviderInfo } from '../provider_home/networking';
import ExperienceListItemCompact from './ExperienceListItemCompact';
import ExperienceListItemFull from './ExperienceListItemFull';
import ShareExperienceDialog from './ShareExperienceDialog';
import ShareExperiencesDialog from './ShareExperiencesDialog';
import AddGroupDialog from '../groups/AddGroupDialog';
import {
	deleteExperience,
	getAllExperiences,
	getExperienceTempId,
	addProviderGroup,
	removeProviderGroup
} from './networking';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {useAccount} from "../../../../utilities/hooks/UseAccount";
import ExperienceDetailDialog from "../resale_hub/components/ExperienceDetailDialog";
import {getLanguages} from "../new_experience/networking";
import share from "../../../../assets/icons/ic_share.svg";
import { getMatchedCountryData } from "../../../../utilities/helpers/LanguageHelper";
import GroupInfo from '../groups/group_dialog/GroupInfo';

const ProviderExperiences = ({ setWarningMessage, setSuccessMessage }) => {
	const dictionary = useDictionary();
	const [config, setConfig] = useState(null);

	const [showNewExperience, setShowNewExperience] = useState(false);
	const [experiences, setExperiences] = useState([]);
	const [shareUrl, setShareUrl] = useState();
	const [showLoader, setShowLoader] = useState(false);
	const [tempId, setTempId] = useState('');
	const [tempData, setTempData] = useState();
	const [editId, setEditId] = useState('');
	const [showShareDialog, setShareDialogVisibility] = useState(false);
	const [showGroupDialog, setGroupDialogVisibility] = useState(false);
	const [showSharesDialog, setSharesDialogVisibility] = useState(false);
	const [selectedExperience, setSelectedExperience] = useState(null);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [selectedOption, setSelectedOption] = useState(null);
	const [selectedViewUrl, setSelectedViewUrl] = useState(null);
	const [viewType, setViewType] = useState(
		parseInt(localStorage.getItem('experiences_selected_view_type') ?? 0)
	);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('experiences_selected_page_size') ?? 10)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataLength, setDataLength] = useState(0);
	const userType = useUserTypeId();
	const [errorMessage, setErrorMessage] = useState(
		dictionary.problem_deleting_experience
	);
	const [allLanguages, setAllLanguages] = useState([]);

	const account = useAccount();
	const [isVerified, setIsVerified] = useState(account?.verification_status?.value === '3');
	const [showGroupInfo, setShowGroupInfo] = useState(false);
    const [groupInfoType, setGroupInfoType] = useState(null);

	const source = axios.CancelToken.source();
	

	const getProviderInfoAsync = async () => {
		try {
			const result = await getProviderInfo(source.token);
			if (result && result.data) {
				setConfig(result.data);
				setIsVerified(result.data?.verification_status?.value === '3')
			} else {
				throw new Error('Get provider info failed');
			}
		} catch (error) {
			console.error(error);
		}
	};

	const createExperience = async () => {
		if (isVerified || (!isVerified && experiences.length === 0)) {
			setEditId('');
			setShowLoader(true);
			await getExperienceId();
		}
	};

	const shareExperiences = (experience) => {
		setSelectedExperience(experience);
		setSharesDialogVisibility(true);
	};

	const getExperienceId = async () => {
		try {
			const { data } = await getExperienceTempId();
			setTempId(data.id);
			setTempData(data);
			setShowLoader(false);
			setShowNewExperience(true);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getAllExperiencesAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getAllExperiences(
				selectedOption,
				pageSize,
				currentPage,
				source.token
			);
			setDataLength(data.recordsTotal);
			setShowLoader(false);
			setExperiences(data.data);
			setShareUrl(data.share_url);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getLanguagesAsync = async () => {
		try {
			let { data } = await getLanguages();
			setAllLanguages(data.filter(({ code }) => !!getMatchedCountryData(code)));
		} catch (e) {
			console.log(e);
		}
	};

	const onExperienceSaved = () => {
		setEditId('');
		setShowNewExperience(false);
		getAllExperiencesAsync();
	};

	const deleteExperienceAsync = async (onSuccess, onFail) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await deleteExperience(selectedExperience.id);
			onSuccess();
		} catch (error) {
			if (error.response) {
				if (error.response.data.error_code.toString() === '23000') {
					setErrorMessage(dictionary.errors['23000']);
				} else {
					setErrorMessage(error.response.data.message);
				}
			}
			onFail();
		}
	};

	const setSelectedShareExperience = (experience) => {
		setSelectedExperience(experience);
		setShareDialogVisibility(true);
	};

	const setSelectedDeleteExperience = (experience) => {
		setSelectedExperience(experience);
		setDeleteDialogVisibility(true);
	};

	const setSelectedAddGroup = (experience) => {
		setSelectedExperience(experience);
		setGroupDialogVisibility(true);
	};

	const onEditClicked = (id) => {
		setEditId(id);
		setShowNewExperience(true);
	};

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};

	const handleOpenDetailDialog = (viewUrl) => {
		setSelectedViewUrl(viewUrl);
	};

	const handleCloseDetailDialog = () => {
		setSelectedViewUrl(null);
	};

	const renderExperiences = () => {
		if (viewType === 0) {
			return (
				<>
					{experiences.map((experience) => (
						<ExperienceListItemFull
							key={experience.id}
							setWarningMessage={setWarningMessage}
							onEditClicked={onEditClicked}
							setSelectedShareExperience={setSelectedShareExperience}
							setSelectedDeleteExperience={setSelectedDeleteExperience}
							setSelectedAddGroup={setSelectedAddGroup}
							setShowLoader={setShowLoader}
							onStatusChangeCompleted={getAllExperiencesAsync}
							experience={experience}
							accountConfig={config}
							allLanguages={allLanguages}
							viewExperienceHandler={handleOpenDetailDialog}
						/>
					))}
				</>
			);
		} else {
			return (
				<>
					{experiences.map((experience) => (
						<ExperienceListItemCompact
							key={experience.id}
							setWarningMessage={setWarningMessage}
							onEditClicked={onEditClicked}
							setShowLoader={setShowLoader}
							onStatusChangeCompleted={getAllExperiencesAsync}
							setSelectedShareExperience={setSelectedShareExperience}
							setSelectedDeleteExperience={setSelectedDeleteExperience}
							setSelectedAddGroup={setSelectedAddGroup}
							experience={experience}
							accountConfig={config}
							allLanguages={allLanguages}
							viewExperienceHandler={handleOpenDetailDialog}
						/>
					))}
				</>
			);
		}
	};

	const onViewTypeChanged = (type) => {
		setViewType(type);
	};
	
	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};

    const addGroup = async (groupId) => {
		try{
			await addProviderGroup(groupId,selectedExperience.id);
			setSuccessMessage(
				dictionary.experience_added
			);
			setTimeout(() => {
				setSuccessMessage('');
			},3000);
		}
		catch (error) {
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const removeGroup = async (groupId) => {
         try{
			await removeProviderGroup(groupId,selectedExperience.id);
			setSuccessMessage(
				dictionary.experience_removed
			);
			setTimeout(() => {
				setSuccessMessage('');
			}, 3000);
		}
		catch (error) {
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const createNewGroup = () => {
		setGroupDialogVisibility(false);
        setGroupInfoType('create');
		setShowGroupInfo(true);
    }

    const groupSaveEvent = async (group) => {
		addGroup(group.id);
		setGroupInfoType(null);
		setShowGroupInfo(false);
	};

	useEffect(() => {
		if (!showShareDialog) {
			setSelectedExperience(null);
		}
	}, [showShareDialog]);

	useEffect(() => {
		if (selectedOption) getAllExperiencesAsync();
	}, [pageSize, currentPage, selectedOption]);

	useEffect(() => {
		getProviderInfoAsync();
		getLanguagesAsync()

		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	return (
		<div className="experiences-wrapper">
			<div className="experiences-top-bar">
				<h2 data-testid="experiences-title">{dictionary.experiences}</h2>
				<div className="experiences-actions">
					{userType === 2 ? (
						<div className="experience-create-button">
							<div
								onClick={createExperience}
								className={`button ${
									!isVerified && experiences.length > 0 && 'button-disabled'
								}`}
								data-testid="create-experience-button"
							>
								<div className="plus-icon" />
								{dictionary.create_new_experience}
							</div>
							{!isVerified && experiences.length > 0 && (
								<Tooltip content={dictionary.you_need_to_be_verified_account_to_add_more} />
							)}
						</div>
					) : null}
					{userType === 2 && isVerified ? (
						<button
							onClick={shareExperiences}
							className="secondary-button"
							style={{ marginLeft: '25px' }}
							data-testid="share-experience-button"
							disabled={!experiences.length}
						>
							<div style={{ height: 16, marginRight: 8 }}>
								{/*<ShareNetworkIcon style={{ height: 16, width: 16 }} />*/}
								<img src={share} alt="share.svg" />
							</div>
							{dictionary.share_experiences}
						</button>
					) : null}
				</div>
				<div className="flex-filler" />
				<div className="experiences-filter-actions">
					<SortOptionsSelector
						onValueChanged={onSortOptionValueChanged}
						name={'experiences'}
					/>
					<div style={{ width: '16px' }} />
					<ViewTypeSelector
						onValueChanged={onViewTypeChanged}
						name={'experiences'}
					/>
				</div>
				<div className="experiences-mobile-actions">
					{userType === 2 ? (
						<div
							onClick={createExperience}
							className="button"
							style={{ marginLeft: 16, padding: 14 }}
							data-testid="create-experience-button"
						>
							<div
								className="plus-icon"
								style={{ marginRight: 0, width: 14, height: 14 }}
							/>
						</div>
					) : null}
					{userType === 2 ? (
						<div
							onClick={shareExperiences}
							className="button secondary-button"
							style={{ marginLeft: 16, padding: 12 }}
							data-testid="share-experience-button"
						>
							<div style={{ height: 16 }}>
								<ShareNetworkIcon style={{ height: 16, width: 16 }} />
							</div>
						</div>
					) : null}
				</div>
			</div>
			<div className="experiences-mobile-filter-actions">
				<SortOptionsSelector
					startPosition="left"
					onValueChanged={onSortOptionValueChanged}
					name={'experiences'}
				/>
				<div style={{ width: '16px' }} />
				<ViewTypeSelector
					onValueChanged={onViewTypeChanged}
					name={'experiences'}
				/>
			</div>
			{experiences?.length === 0 ? (
				<div className="empty-experiences-list-wrapper">
					<div className="empty-experiences-list-placeholder">
						<div className="empty-experiences-image" />
						<h3
							style={{ textAlign: 'center', marginTop: '24px' }}
							data-testid="no-experiences-yet-label"
						>
							{dictionary.start_adding_experience}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.you_will_see_all_of_them_here}
						</div>
					</div>
				</div>
			) : null}
			{experiences?.length > 0 ? (
				<div
					className={
						viewType === 0
							? 'experience-items-wrapper thin-scrollbar'
							: 'experience-items-wrapper thin-scrollbar tile-experience-items-wrapper'
					}
				>
					{renderExperiences()}
				</div>
			) : null}
			<ExperienceDetailDialog
				viewUrl={selectedViewUrl}
				showDialog={Boolean(selectedViewUrl)}
				setShowDialog={handleCloseDetailDialog}
			/>
			<PaginationController
				name="experiences"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			<FormLoader showLoader={showLoader} showEnter={false} />
			{showNewExperience ? (
				<NewExperience
					editId={editId}
					onExperienceSaved={onExperienceSaved}
					tempId={tempId}
					tempData={tempData}

				/>
			) : null}
			{showShareDialog ? (
				<ShareExperienceDialog
					setShareDialogVisibility={setShareDialogVisibility}
					shareUrl={selectedExperience.share_url}
				/>
			) : null}
			{showSharesDialog ? (
				<ShareExperiencesDialog
					setShareDialogVisibility={setSharesDialogVisibility}
					shareUrl={shareUrl}
				/>
			) : null}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => getAllExperiencesAsync()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteExperienceAsync}
					title={dictionary.delete_experience}
					message={dictionary.delete_experience_message}
					successMessage={dictionary.experience_deleted}
					errorMessage={errorMessage}
					action={dictionary.delete}
				/>
			) : null}
			{showGroupDialog && selectedExperience ? (
				<AddGroupDialog
					actionType={'Experience'}
					setAddGroupDialogVisibility={setGroupDialogVisibility}
					experience={selectedExperience}
					addGroup={addGroup}
					removeGroup={removeGroup}
					createNewGroup={createNewGroup}
				/>
			) : null }
		{showGroupInfo ? (
				<GroupInfo
					type={groupInfoType}
					showDialog={showGroupInfo}
					setShowDialog={setShowGroupInfo}
					groupSaveEvent={groupSaveEvent}
					editId={editId}
				/>
			) : null}
		</div>
	);
};

ProviderExperiences.propTypes = {
	setWarningMessage: PropTypes.func
};

export default connect(common.states, common.actions)(ProviderExperiences);
