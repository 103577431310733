import axios from 'axios';
import {
	PROVIDERS_LEGAL_INFO_URL,
	PROVIDERS_ME_URL,
	// PROVIDERS_URL
} from '../../../../../config';

export function uploadProviderDocument(file, type) {
	const formData = new FormData();

	formData.append('file', file, file.name);
	formData.append('type', type);
	return axios.post(PROVIDERS_ME_URL + '/legal-files', formData);
}

export function submitLegalInfo(beneficiary, iban, vatNumber) {
	return axios.post(PROVIDERS_LEGAL_INFO_URL, {
		account_iban: iban,
		account_beneficiary: beneficiary,
		tax_id_number: vatNumber
	});
}

export function submitLegalInfoWithDocuments(beneficiary, iban, vatNumber, files, types) {

	const formData = new FormData();
	formData.append('account_iban', iban);
	formData.append('account_beneficiary', beneficiary);
	formData.append('tax_id_number', vatNumber);
	formData.append('types', types);

	files.forEach(file => {
		formData.append('files[]', file, file.name);
	});

	return axios.post(PROVIDERS_LEGAL_INFO_URL, formData);
}
