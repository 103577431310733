import { countries } from '../../../../../data/countries';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import * as common from '../../../common/__redux/commonRedux';
import ProfilePhoto from '../ProfilePhoto';
import { updateProviderInfo } from '../networking';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

const CompanyProfile = ({
	info,
	setInfo,
	companyPhoto,
	setCompanyPhoto,
	setSuccessMessage,
	setErrorMessage
}) => {
	const dictionary = useDictionary();
	const [showLoader, setShowLoader] = useState(false);
	const [changed, setChanged] = useState(0);

	const companyProfileSchema = Yup.object().shape({
		brand_name: Yup.string()
			.required(dictionary.company_name_required)
			.nullable(),
		legal_name: Yup.string()
			.required(dictionary.legal_name_required)
			.nullable(),
		contact_email: Yup.string()
			.email()
			.nullable(),
		short_description: Yup.string()
			.required(dictionary.short_description_required)
			.nullable(),
		website: Yup.string().nullable(),
		address1: Yup.string().required(dictionary.address_required).nullable(),
		address2: Yup.string().nullable(),
		city: Yup.string().required(dictionary.city_required).nullable(),
		region: Yup.string().required(dictionary.state_required).nullable(),
		country_id: Yup.string().required(dictionary.country_required).nullable()
		//country:Yup.string().required(dictionary.country_required).nullable(),
		//company_registration: FormikHelper.getDefaultYupTextInput(dictionary.company_registration_number_required).nullable(),
		//vat_number: FormikHelper.getDefaultYupTextInput(dictionary.vat_number_required).nullable()
	});

	useEffect(() => {
		if (info) {
			initFormik();
		}
	}, [info]);

	const initFormik = () => {
		formik.setFieldValue('brand_name', info.brand_name);
		formik.setFieldValue('legal_name', info.legal_name);
		formik.setFieldValue('short_description', info.short_description);
		formik.setFieldValue('website', info.website);
		formik.setFieldValue('address1', info.address1);
		formik.setFieldValue('address2', info.address2);
		formik.setFieldValue('city', info.city);
		formik.setFieldValue('region', info.region);
		formik.setFieldValue('country_id', info.country_id);
		formik.setFieldValue('contact_email', info.contact_email);
		//formik.setFieldValue('vat_number', info.tax_id_number)
	};

	const formik = useFormik({
		initialValues: {
			brand_name: '',
			legal_name: '',
			short_description: '',
			website: '',
			address1: '',
			address2: '',
			city: '',
			region: '',
			country_id: '',
			contact_email: ''
			//company_registration: '',
			//vat_number: ''
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, values);
			submitProviderDataAsync(newDetails);
		},
		validateOnMount: true,
		validationSchema: companyProfileSchema
	});

	useEffect(() => {
		if (changed < 2) {
			setChanged(changed + 1);
		}
	}, [formik?.values]);

	const onCancelClicked = () => {
		initFormik();
	};

	const submitProviderDataAsync = async (info) => {
		setShowLoader(true);

		try {
			let { data } = await updateProviderInfo(info);
			localStorage.setItem('company_photo_path', data.company_photo?.path);
			setInfo(data);
			setShowLoader(false);
			setSuccessMessage(dictionary.company_profile_success);
		} catch (e) {
			setShowLoader(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
			}
		}
	};

	FormikHelper.setFormik(formik);

	return (
		<form
			className="settings-page-container thin-scrollbar fade-on-load"
			onSubmit={formik.handleSubmit}
		>
			<div
				className="onboarding-activity-title"
				data-testid="company-info-header"
			>
				{dictionary.company_info}
			</div>

			<div className="settings-form-content">
				<div className="settings-form-content-left">
					<div className="settings-info-column">
						{FormikHelper.getInputTemplate(
							'brand_name',
							dictionary.brand_name,
							dictionary.enter_brand_name,
							'text',
							true,
							true
						)}
						{FormikHelper.getInputTemplate(
							'legal_name',
							dictionary.legal_name,
							dictionary.enter_legal_name,
							'text',
							true,
							true
						)}
						{FormikHelper.getInputTemplate(
							'contact_email',
							dictionary.contact_email,
							dictionary.enter_contact_email,
							'text',
							true,
							true
						)}
						{FormikHelper.getTextareaTemplate(
							'short_description',
							dictionary.short_description,
							dictionary.provide_short_description_provider,
							true,
							true
						)}
						{/*{FormikHelper.getInputTemplate('company_registration', dictionary.company_registration_number, '00000000', 'text', true, true)}*/}
						{/*{FormikHelper.getInputTemplate('vat_number', dictionary.vat_number, '00000000', 'text', true, true)}*/}
						{FormikHelper.getInputTemplate(
							'website',
							dictionary.website,
							dictionary.enter_website,
							'text',
							false,
							true
						)}
						{FormikHelper.getInputTemplate(
							'address1',
							dictionary.address_line1,
							dictionary.enter_address,
							'text',
							true,
							true
						)}
						{FormikHelper.getInputTemplate(
							'address2',
							dictionary.address_line2,
							dictionary.enter_address,
							'text',
							false,
							true
						)}
						{FormikHelper.getInputTemplate(
							'city',
							dictionary.city,
							dictionary.enter_city,
							'text',
							true,
							true
						)}
						{FormikHelper.getInputTemplate(
							'region',
							dictionary.state,
							dictionary.enter_state,
							'text',
							true,
							true
						)}
						{FormikHelper.getSelectTemplate(
							'country_id',
							dictionary.country,
							true,
							countries,
							dictionary.select_country,
							false,
							true
						)}
					</div>
				</div>
				<div className="settings-form-content-right">
					<ProfilePhoto
						title={dictionary.company_logo}
						type={'company-photo'}
						url={'providers/me/assets'}
						photo={companyPhoto}
						setPhoto={setCompanyPhoto}
						isVerified={info.verification_status.key === 'verified'}
					/>
				</div>
			</div>

			<div style={{ display: 'flex', marginTop: '30px', gap: '16px' }}>
				<button
					type={'submit'}
					disabled={changed <= 1 || !formik.isValid}
					className="button"
					data-testid="save-button"
				>
					{dictionary.save}
				</button>
				<div
					onClick={onCancelClicked}
					className="secondary-button"
					data-testid="cancel-btn"
				>
					{dictionary.cancel}
				</div>
			</div>
			<SimpleLoader showLoader={showLoader} />
		</form>
	);
};

CompanyProfile.propTypes = {
	info: PropTypes.object,
	setInfo: PropTypes.func,
	companyPhoto: PropTypes.object,
	setCompanyPhoto: PropTypes.func,
	setSuccessMessage: PropTypes.func,
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(CompanyProfile);
