import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { ENABLE_RESOURCES } from '../../../../ui_config';
import PropTypes from 'prop-types';

const ExperienceTypeDialog = ({
	setExperienceTypeDialogVisibility,
	showScheduleDialogWithMode
}) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setExperienceTypeDialogVisibility(false);
		}, 800);
	};

	const onModeSelected = (mode) => {
		onCancelClicked();
		showScheduleDialogWithMode(mode);
	};

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ opacity: opacity }}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className="dialog-container"
				style={{ maxHeight: '90%' }}
				data-testid="experience-type-dialog"
			>
				
			</div>
		</div>
	);
};

ExperienceTypeDialog.propTypes = {
	setExperienceTypeDialogVisibility: PropTypes.func,
	showScheduleDialogWithMode: PropTypes.func
};

export default ExperienceTypeDialog;
