import React, { useEffect, useState } from 'react';
import { CryptoHelper } from '../../../../../utilities/helpers/CryptoHelper';
import { getPK } from '../../__redux/authCrud';
import { connect } from 'react-redux';
import * as auth from '../../__redux/authRedux';
import RegisterForm from '../../forms/RegisterForm';

const Register = () => {
	const [sessionId, setSessionId] = useState(null);
	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		let sessionId = CryptoHelper.generateSessionId(20);
		setSessionId(sessionId);

		getPK(sessionId).then((response) => {
			setMetadata(response.data.metadata);
		});
	}, []);

	return (
		<div className="main-container">
			<RegisterForm sessionId={sessionId} metadata={metadata} />
		</div>
	);
};

export default connect(
	({ auth }) => ({ errors: auth.errors, account_type: auth.account_type }),
	auth.actions
)(Register);
