import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialCommonState = {
	navigation: null,
	user: null,
	modal: null,
	pageTitle: null,
	routes: null,
	blockui: null,
	components: null,
	burger: false,
	collapsed: false,
	dependent: false,
	errorMessage: '',
	warningMessage: '',
	successMessage: ''
};

export const reducer = persistReducer(
	{ storage, key: 'upiria_common', whitelist: ['collapsed'] },
	(state = initialCommonState, action) => {
		switch (action.type) {
			case 'openModal': {
				const modal = action.payload.data;
				return {
					...state,
					modal
				};
			}

			case 'closeModal': {
				const modal = null;
				return {
					...state,
					modal
				};
			}

			case 'openBurger': {
				return {
					...state,
					burger: true
				};
			}

			case 'closeBurger': {
				return {
					...state,
					burger: false
				};
			}

			case 'expand': {
				return {
					...state,
					collapsed: false
				};
			}

			case 'collapse': {
				return {
					...state,
					collapsed: true
				};
			}

			case 'setErrorMessage': {
				let errorMessage = action.payload.data;
				return {
					...state,
					errorMessage: errorMessage
				};
			}

			case 'setWarningMessage': {
				let warningMessage = action.payload.data;
				return {
					...state,
					warningMessage: warningMessage
				};
			}

			case 'setSuccessMessage': {
				let successMessage = action.payload.data;
				return {
					...state,
					successMessage: successMessage
				};
			}

			case 'pageTitle': {
				let pageTitle = action.payload.data;
				if (pageTitle == null) {
					let key = state.routes.findIndex(
						(x) => '/' + x.path == window.location.pathname
					);
					if (key < 0) {
						const pathname = window.location.pathname;
						const path = pathname.split('/');
						const newPath =
							path.length > 2 ? '/' + path[1] + '/:id' : '/' + path[1];
						key = state.routes.findIndex((x) => '/' + x.path == newPath);
						pageTitle = key >= 0 ? state.routes[key].pageTitle : '';
					}
				}

				return {
					...state,
					pageTitle
				};
			}

			case 'setRoutes': {
				const routes = action.payload.data;
				return {
					...state,
					routes
				};
			}

			case 'blockUI': {
				return {
					...state,
					blockui: action.payload.data
				};
			}

			case 'openDependent': {
				return {
					...state,
					dependent: true
				};
			}

			case 'closeDependent': {
				return {
					...state,
					dependent: false
				};
			}

			default: {
				return state;
			}
		}
	}
);

export const actions = {
	getProjects: (data) => ({ type: 'getProjects', payload: { data } }),
	openModal: (data) => ({ type: 'openModal', payload: { data } }),
	closeModal: (data) => ({ type: 'closeModal', payload: { data } }),
	setPageTitle: (data) => ({ type: 'pageTitle', payload: { data } }),
	setErrorMessage: (data) => ({ type: 'setErrorMessage', payload: { data } }),
	setWarningMessage: (data) => ({
		type: 'setWarningMessage',
		payload: { data }
	}),
	setSuccessMessage: (data) => ({
		type: 'setSuccessMessage',
		payload: { data }
	}),
	setRoutes: (data) => ({ type: 'setRoutes', payload: { data } }),
	loading: (data) => ({ type: 'blockUI', payload: { data } }),
	setComponents: (data) => ({ type: 'setComponents', payload: { data } }),
	update_profile: (data) => ({ type: 'update_profile', payload: data }),
	openBurger: (data) => ({ type: 'openBurger', payload: data }),
	closeBurger: (data) => ({ type: 'closeBurger', payload: data }),
	collapse: (data) => ({ type: 'collapse', payload: data }),
	expand: (data) => ({ type: 'expand', payload: data }),
	openDependent: (data) => ({ type: 'openDependent', payload: data }),
	closeDependent: (data) => ({ type: 'closeDependent', payload: data })
};

export const setComponents = actions.setComponents;

export const states = (initialCommonState) => {
	return {
		projects: 100,
		user: initialCommonState.auth.user,
		modal: initialCommonState.common.modal,
		pageTitle: initialCommonState.common.pageTitle,
		routes: initialCommonState.common.routes,
		isloading: initialCommonState.common.blockui,
		components: initialCommonState.auth.components,
		burger: initialCommonState.common.burger,
		collapsed: initialCommonState.common.collapsed,
		dependent: initialCommonState.common.dependent,
		errorMessage: initialCommonState.common.errorMessage,
		warningMessage: initialCommonState.common.warningMessage,
		successMessage: initialCommonState.common.successMessage
	};
};
