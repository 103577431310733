import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import MoneyInput from '../../../../common/MoneyInput';
import PropTypes from 'prop-types';
import React from 'react';

const ParticipantGroup = ({
	group,
	onEditItemClicked,
	onRemoveItemClicked,
	setValue,
	index,
	onBlur
}) => {
	const dictionary = useDictionary();

	const getAge = () => {
		if (!group.max_age && group.min_age) {
			return dictionary.ages + ' ' + group.min_age + ' ' + dictionary.and_older;
		} else if (group.max_age && !group.min_age) {
			return dictionary.ages_up_to + ' ' + group.max_age;
		} else if (group.max_age && group.min_age) {
			return (
				dictionary.ages +
				' ' +
				group.min_age +
				' ' +
				dictionary.to +
				' ' +
				group.max_age
			);
		} else if (!group.max_age && !group.min_age) {
			return null;
		}
	};

	const onMoneyValueChanged = (e) => {
		setValue(e, index);
	};

	const onMoneyValueBlur = () => {
		onBlur(index);
	};

	return (
		<div key={index} style={{ width: '100%' }} className="provided-item-row">
			<div style={{ maxWidth: '40%', wordWrap: 'break-word', wordBreak: 'break-word' }}>
				<h3 style={{ fontSize: '16px' }}>{group.name}</h3>
				<div style={{ display: getAge() ? 'block' : 'none' }} className="text">
					{getAge()}
				</div>
			</div>
			<div className="flex-filler" />
			<div className="group-inputs" style={{ width: '120px' }}>
				<MoneyInput
					min={1}
					value={group.rate}
					setValue={onMoneyValueChanged}
					onBlur={onMoneyValueBlur}
				/>
			</div>
			<div
				onClick={() => onEditItemClicked(group)}
				className="dialog-document-row-edit"
				data-testid="edit-participant-group"
			/>
			<div
				onClick={() => onRemoveItemClicked(group)}
				className="dialog-document-row-delete"
				data-testid="delete-participant-group"
			/>
		</div>
	);
};

ParticipantGroup.propTypes = {
	group: PropTypes.object.isRequired,
	onBlur: PropTypes.func,
	onEditItemClicked: PropTypes.func.isRequired,
	onRemoveItemClicked: PropTypes.func.isRequired,
	setValue: PropTypes.func.isRequired,
	index: PropTypes.number.isRequired,
};

export default ParticipantGroup;
