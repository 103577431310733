import { useEffect, useState } from 'react';
import { MAX_FILE_UPLOAD_IMAGES } from '../../../../../../config';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import { connect } from 'react-redux';
import * as common from '../../../../common/__redux/commonRedux';
import { TextFormatHelper } from '../../../../../../utilities/helpers/TextFormatHelper';
import PropTypes from 'prop-types';
import React from 'react';

const SubmitDocumentsDialogDocumentRow = ({
	setLocalFile,
	title,
	text,
	remoteFile,
	onDeleteRemoteFileClicked,
	clear,
	setErrorMessage,
	disabled
}) => {
	const dictionary = useDictionary();
	const [file, setFile] = useState(null);

	useEffect(() => {
		if (clear > 0) {
			setFile(null);
		}
	}, [clear]);

	const onFileSelected = (e) => {
		const validTypes = ['image/jpeg', 'application/pdf'];
		const selectedFile = e.target.files[0];
		const { size, type } = selectedFile;
		if (size > MAX_FILE_UPLOAD_IMAGES) {
			setErrorMessage('loc1');

			return;
		}
		if (!validTypes.includes(type)) {
			setErrorMessage('loc1');
			return;
		}
		setLocalFile(selectedFile);
		setFile(selectedFile);
	};

	const onDeleteClicked = () => {
		setLocalFile(null);
		setFile(null);
	};

	const getStatusButton = () => {
		switch (remoteFile?.status?.key) {
			case null: {
				return null;
			}
			case 'rejected': {
				return (
					<>
						<div
							style={{ marginLeft: '10px', cursor: 'unset' }}
							className="status-label status-label-error"
							data-testid="rejected-status"
						>
							{dictionary.verification_rejected}
						</div>
						<div
							className="dialog-document-row-delete"
							data-testid="delete-btn"
							onClick={() => onDeleteRemoteFileClicked(remoteFile?.id)}
						/>
					</>
				);
			}
			case 'pending': {
				return (
					<div
						style={{ marginLeft: '10px', cursor: 'unset' }}
						className="status-label status-label-warning"
						data-testid="pending-status"
					>
						{dictionary.verification_pending}
					</div>
				);
			}
			case 'approved': {
				return (
					<div
						style={{ marginLeft: '10px', cursor: 'unset' }}
						className="status-label status-label-valid"
						data-testid="approved-status"
					>
						{dictionary.verification_confirmed}
					</div>
				);
			}
		}
	};

	return (
		<div className="dialog-document-row" data-testid="document-row">
			<div title={text} className="text-with-info">
				{title}
			</div>
			<div className="dialog-document-row-info-container">
				{!remoteFile ? (
					<>
						{file ? (
							<>
								<div className="dialog-document-row-filename">
									{TextFormatHelper.truncateString(file.name, 20)}
								</div>
								<div
									data-test-id="delete-btn"
									className="dialog-document-row-delete"
									onClick={onDeleteClicked}
									style={{ pointerEvents: disabled ? 'none' : 'auto' }}
								/>
							</>
						) : null}
						{!file ? (
							<div className="dialog-document-upload-button-container">
								<div
									className={`secondary-button${
										disabled ? ' secondary-button-disabled' : ''
									}`}
								>
									{dictionary.upload_doc}
								</div>
								<input
									data-testid="upload-btn"
									accept=".jpeg, .jpg, .pdf"
									onChange={onFileSelected}
									type="file"
									disabled={disabled}
								/>
							</div>
						) : null}
					</>
				) : (
					<>{getStatusButton()}</>
				)}
			</div>
		</div>
	);
};

SubmitDocumentsDialogDocumentRow.propTypes = {
	setLocalFile: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	remoteFile: PropTypes.object,
	onDeleteRemoteFileClicked: PropTypes.func.isRequired,
	clear: PropTypes.number.isRequired,
	setErrorMessage: PropTypes.func.isRequired,
	disabled: PropTypes.bool.isRequired
};

export default connect(
	common.states,
	common.actions
)(SubmitDocumentsDialogDocumentRow);
