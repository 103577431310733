import axios from 'axios';
import React, { useEffect, useState } from 'react';
import '../../../../../assets/css/bookings.css';
import '../../../../../assets/css/filter.css';
import '../../../../../assets/css/tables.css';
import iconFilter from '../../../../../assets/icons/ic_filter.svg';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import ConfirmationModal from '../../../common/ConfirmationModal';
import SimpleLoader from '../../../common/SimpleLoader';
import PaginationController from '../../../common/pagination_controller/PaginationController';
import SortOptionsSelector from '../../../common/sort_options/SortOptionsSelector';
import { deleteExperience } from '../../provider_experiences/networking';
import BookingItem from './BookingItem';
import FilterDialog from './booking_list/FilterDialog';
import SearchInput from './booking_list/SearchInput';
import { getUpcomingBookings } from './networking';
import { useHistory } from 'react-router-dom';
import { NavigatorPaths } from '../../../../../assets/dictionaries/NavigatorPaths';
import IconButton from '../../atom/IconButton';
import useClientWidth from '../../../../../utilities/hooks/useClientWidth';
{
	/*import iconSetting from '../../../../../assets/icons/ic_setting.svg'; */
}
const Bookings = () => {
	const dictionary = useDictionary();
	const clientWidth = useClientWidth();
	const [selectedBooking, setSelectedBooking] = useState(null);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [showLoader, setShowLoader] = useState(true);
	const [bookings, setBookings] = useState(null);
	const [selectedOption, setSelectedOption] = useState(localStorage.getItem('adminbookings_selected_sort_type') ? JSON.parse(localStorage.getItem('adminbookings_selected_sort_type')) : {value: 'created_at', direction: 'desc', label: 'Newest'});
	const [searchParam, setSearchParam] = useState('');
	const [showScheduleDialog, setShowScheduleDialog] = useState(false);
	const [createDateParam, setCreateDateParam] = useState('');
	const [filterCount, setFilterCount] = useState(0);
	const [filterStatusParams, setFilterStatusParams] = useState([]);
	const [filterPaymentStatusParams, setFilterPaymentStatusParams] = useState([]);
	const [showFilter, setShowFilter] = useState(false);
	const [filterProviderParams, setFilterProviderParams] = useState([]);

	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('bookings_selected_page_size') ?? 10)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataLength, setDataLength] = useState(0);

	const source = axios.CancelToken.source();
	const history = useHistory();

	useEffect(() => {
		countAllFilter();

		const date = localStorage.getItem('bookings_filter_date');
		setCreateDateParam(date);

		const statusIds =
			JSON.parse(localStorage.getItem('bookings_filter_status')) || [];
		setFilterStatusParams(statusIds);

		const paymentStatusIds =
			JSON.parse(localStorage.getItem('bookings_filter_payment_status')) || [];
		setFilterPaymentStatusParams(paymentStatusIds);

		getBookingsAsync(source);
	}, [pageSize, currentPage, selectedOption, searchParam]);

	const onDeleteClicked = (booking) => {
		setSelectedBooking(booking);
		setDeleteDialogVisibility(true);
	};

	const onCancelClicked = (booking) => {
		setSelectedBooking(booking);
		setCancelDialogVisibility(true);
	};

	const deleteBookingAsync = async (onSuccess, onFail) => {
		try {
			await deleteExperience(selectedBooking.id);
			onSuccess();
		} catch (error) {
			onFail();
		}
	};

	useEffect(() => {
		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	const getBookingsAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getUpcomingBookings(
				selectedOption,
				pageSize,
				currentPage,
				false,
				source.token,
				searchParam,
				createDateParam,
				filterStatusParams,
				filterPaymentStatusParams,
				filterProviderParams
			);
			setBookings(data.data);
			setDataLength(data.recordsFiltered ?? data.recordsTotal ?? 0);
			if (data.data.length > 0) {
				setShowFilter(true);
			}
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const getBookingsAsyncNoParams = async () => {
		setShowLoader(true);
		try {
			let { data } = await getUpcomingBookings(
				selectedOption,
				pageSize,
				currentPage,
				false,
				source.token
			);
			setBookings(data.data);
			setDataLength(data.recordsFiltered ?? data.recordsTotal ?? 0);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const onBookingClicked = (booking) => {
		setSelectedBooking(booking);
		history.push(`/${NavigatorPaths.admin_bookings.path}/${booking.id}`);
	};

	const dismissDetails = () => {
		setTimeout(() => {
			// setDetailsVisibility(false);
			setSelectedBooking(null);
		}, 300);
		getBookingsAsync();
	};

	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};

	const onSortOptionValueChanged = (option) => {
		if(option.value !== selectedOption.value){
			setSelectedOption(option);
		}
	};

	const onSearchValueChanged = (value) => {
		setSearchParam(value);
	};

	const onCreateDateValueChanged = (date) => {
		countAllFilter();
		setCreateDateParam(date);
	};

	const onStatusValuesChanged = () => {
		countAllFilter();
	};

	const onPaymentStatusValuesChanged = () => {
		countAllFilter();
	}
	const onProviderValuesChanged = () => {
		countAllFilter();
	};

    const onScheduleSet = () => {
        const providerIds = JSON.parse(localStorage.getItem('admin_booking_filter_provider')) || [];
        setFilterProviderParams(providerIds);
        getBookingsAsync();
	};

	const countAllFilter = () => {
		const dateFilter = localStorage.getItem('bookings_filter_date');
		const statusIds =
			JSON.parse(localStorage.getItem('bookings_filter_status')) || [];
		const paymentStatusIds =
			JSON.parse(localStorage.getItem('bookings_filter_payment_status')) || [];
		const providerIds = JSON.parse(localStorage.getItem('admin_booking_filter_provider')) || [];
		let filterCountAll = 0;
		if (dateFilter) {
			filterCountAll += 1;
		}

		filterCountAll += statusIds.length;
		filterCountAll += paymentStatusIds.length;
		filterCountAll += providerIds.length;
		setFilterCount(filterCountAll);
	};

	const showScheduleDialogWithMode = () => {
		setShowScheduleDialog(true);
	};

	return (
		<div
			className="calendar-container"
			style={{
				position: 'relative',
				background: 'var(--background-color)',
				paddingTop: '0'
			}}
		>
			{clientWidth > 662 ? (
				<div className="booking-header-container">
					<h2 className="title-main" data-testid="bookings-header">
						{dictionary.bookings}
					</h2>
					{showFilter ? (
						<div className="booking-right-group">
							<div className="booking-right-filter-sort">
								<SearchInput onValueChanged={onSearchValueChanged} />
								
								<IconButton onClickIcon={showScheduleDialogWithMode} activeState={showScheduleDialog}>
									<div className="booking-filter">
										<img className="icon-filter" src={iconFilter} />
										{filterCount > 0 ? (
											<span>
												{dictionary.filters} ({filterCount})
											</span>
										) : (
											<span>{dictionary.filters}</span>
										)}
									</div>
								</IconButton>
								<SortOptionsSelector
									onValueChanged={onSortOptionValueChanged}
									name={'adminbookings'}
								/>
							</div>
						</div>
					) : null}
				</div>
			) : null}
			{clientWidth <= 662 ? (
				<div className="booking-mobile-header-container">
					<div className="booking-mobile-header-top">
						<h2 className="title-main">{dictionary.bookings}</h2>
						<div style={{ display: 'flex', gap: 8 }}>
							<div
								className="booking-filter"
								onClick={() => {
									showScheduleDialogWithMode();
								}}
							>
								<img className="icon-filter" src={iconFilter} />
								{filterCount > 0 ? (
									<span>
										{dictionary.filters} ({filterCount})
									</span>
								) : (
									<span>{dictionary.filters}</span>
								)}
							</div>
							<SortOptionsSelector
								onValueChanged={onSortOptionValueChanged}
								name={'adminbookings'}
							/>
						</div>
					</div>
					<div className="booking-mobile-header-bottom">
						<SearchInput onValueChanged={onSearchValueChanged} />
					</div>
				</div>
			) : null}
			<div className="upiria-table-container">
				<div className="upiria-table-header-strip" />
				<div className="upiria-table-container-inner thin-scrollbar-trans">
					<table
						data-testid="bookings-table"
						cellSpacing="0"
						cellPadding="0"
						border="0"
						className={'upiria-table-container-admin-bookings-table'}
					>
						<thead>
							<tr>
								<th data-testid="booking-number-table-header">#</th>
								<th data-testid="booking-date-table-header">
									{dictionary.create_date}
								</th>
								<th data-testid="booking-status-table-header">
									{dictionary.provider}
								</th>
								<th data-testid="booking-status-table-header">
									{dictionary.booked}
								</th>
								<th data-testid="booking-status-table-header">
									{dictionary.status}
								</th>
								<th data-testid="booking-customer-table-header">
									{dictionary.customer}
								</th>
								{/*<th>{dictionary.discount}</th>*/}
								<th data-testid="payment-table-header">{dictionary.payment}</th>
								<th data-testid="price-table-header">{dictionary.amount}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{bookings?.map((item) => (
								<BookingItem
									key={item.id}
									onBookingClicked={onBookingClicked}
									onDeleteClicked={onDeleteClicked}
									onCancelClicked={onCancelClicked}
									item={item}
								/>
							))}
						</tbody>
					</table>
				</div>

				{bookings?.length === 0 ? (
					<div
						className="empty-experiences-list-wrapper"
						style={{ height: '80%' }}
					>
						<div className="empty-experiences-list-placeholder">
							<div className="empty-experiences-image empty-bookings-image" />
							<h3
								data-testid="no-bookings-yet-header"
								style={{ textAlign: 'center', marginTop: '24px' }}
							>
								{dictionary.start_selling}
							</h3>
							<div style={{ marginTop: '8px' }} className="text">
								{dictionary.your_bookings_will_be_listed_here}
							</div>
						</div>
					</div>
				) : null}
			</div>
			{showLoader ? <SimpleLoader showLoader={showLoader} /> : null}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => getBookingsAsync()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={deleteBookingAsync}
					title={dictionary.delete_booking}
					message={dictionary.delete_booking_message}
					successMessage={dictionary.booking_deleted}
					errorMessage={dictionary.problem_deleting_booking}
					action={dictionary.delete}
				/>
			) : null}
			{showScheduleDialog ? (
				<FilterDialog
					onCreateDateValueChanged={onCreateDateValueChanged}
					onStatusValuesChanged={onStatusValuesChanged}
					onPaymentStatusValuesChanged={onPaymentStatusValuesChanged}
					setShowScheduleDialog={setShowScheduleDialog}
					filterCount={filterCount}
					countAllFilter={countAllFilter}
					getBookingsAsync={getBookingsAsync}
					getBookingsAsyncNoParams={getBookingsAsyncNoParams}
					setSearchParam={setSearchParam}
					setCreateDateParam={setCreateDateParam}
					setFilterStatusParams={setFilterStatusParams}
					setFilterPaymentStatusParams={setFilterPaymentStatusParams}
					setCurrentPage={setCurrentPage}
					setFilterProviderParams={setFilterProviderParams}
					onScheduleSet={onScheduleSet}
					onProviderValuesChanged={onProviderValuesChanged}
				/>
			) : null}
			<PaginationController
				name="bookings"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
		</div>
	);
};

export default Bookings;
