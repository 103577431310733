import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import back from '../../../../../assets/icons/ic_caret_left.svg';
import bin from '../../../../../assets/icons/ic_trash.svg';
import logo from '../../../../../assets/images/logo_white_letter.svg';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import * as common from '../../../common/__redux/commonRedux';
import SlotInfo from './SlotInfo';
import SlotBookings from './bookings/SlotBookings';
import SlotParticipantsAndAddons from './participant_groups/SlotParticipantsAndAddons';
import SlotPricing from './pricing/SlotPricing';
import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';
import {useHistory} from "react-router-dom";
import {getSlotDetails, deleteSlot, increaseCapacity} from '../networking';
import ScheduleSlotDialog from '../schedule_slot_dialog/ScheduleSlotDialog';
import EditSlotTypeDialog from '../EditSlotTypeDialog';
import ConfirmationModal from '../../../common/ConfirmationModal';
import NoItemsYetDialog from '../NoItemsYetDialog';
import IncreaseCapacityDialog from "../increase_capacity_dialog/IncreaseCapacity";
import StatusLabel from "../../../common/status-label/StatusLabel";
import RescheduleBookings from "./bookings/RescheduleBookings";

const SlotDetails = () => {
	const history = useHistory();
	const location = useLocation();
	const { id } = useParams();
	const hasExperience = location.search.includes('experience');

	const [details, setDetails] = useState();
	const dictionary = useDictionary();
	const [tabIndex, setTabIndex] = useState('bookings');
	const [showLoader, setLoaderVisibility] = useState(true);
	const [hasError, setHasError] = useState(false);
	const [isResource, setIsResource] = useState(false);
	const [isDelete, setIsDelete] = useState(false);
	const [editType, setEditType] = useState(null);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [showEditSlotTypeDialog, setEditSlotTypeDialogVisibility] =
		useState(false);
	const [showScheduleDialog, setShowScheduleDialog] = useState(false);
	const [scheduleMode, setScheduleMode] = useState('experience');
	const [showNoItemsYetDialog, setNoItemsYetDialogVisibility] = useState(false);
	const [showIncreaseCapacityDialog, setShowIncreaseCapacityDialog] = useState(false);
	const [slotStatus, setSlotStatus] = useState(null);
	const onIncreaseCapacityClicked = () => {
		setShowIncreaseCapacityDialog(true);
	}

	const getSlotAsync = async (slotId, type) => {
		setLoaderVisibility(true);
		try {
			const { data } = await getSlotDetails(slotId, type);
			setDetails(data);
			setLoaderVisibility(false);
			setSlotStatus(data.status.value);
		} catch (error) {
			setLoaderVisibility(false);
		}
	};

	useEffect(() => {
		const type = hasExperience ? 'experience' : 'resource';
		if (id && type) {
			getSlotAsync(id, type);
		}
	}, [id, hasExperience]);

	const onBookingClicked = (booking) => {
		history.push(`/bookings/${booking.id}`, booking);
	};

	const onDeleteSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(true);
		if (details.is_one) {
			setEditType('single');
			setDeleteDialogVisibility(true);
		} else {
			setEditSlotTypeDialogVisibility(true);
		}
	};

	const onNoResults = () => {
		setShowScheduleDialog(false);
		setNoItemsYetDialogVisibility(true);
	};

	const routeMap = {
		bookings: <SlotBookings details={details} onBookingClicked={onBookingClicked} />,
		participants: <SlotParticipantsAndAddons details={details} />,
		reschedule_bookings: <RescheduleBookings details={details} onBookingClicked={onBookingClicked} />,
		pricing: <SlotPricing details={details} />
	};

	const dismiss = () => {
		// dismissDetails();
		history.goBack();
	};

	const isUnExpired = useMemo(() => {
		const start = new Date(details?.start_time * 1000);
		const today = new Date();
		return start - today >= 0;
	}, [details]);

	const showActions = () => {
		return details?.status ?
			(details?.status.value === 'open') && isUnExpired
			:
			false
	};

	const showIncreaseCapacity = () => {
		return details?.status ?
			(details?.status.value !== 'open') && isUnExpired
			:
			false
	};

	const showEditSlotDialogWithMode = (editType) => {
		setEditType(editType);
		if (isDelete) {
			setDeleteDialogVisibility(true);
		} else {
			setShowScheduleDialog(true);
		}
	};

	const onEditSlotClicked = (isResource) => {
		setIsResource(isResource);
		setIsDelete(false);
		if (details.is_one) {
			setEditType('single');
			setShowScheduleDialog(true);
		} else {
			setEditSlotTypeDialogVisibility(true);
		}
	};

	const deleteSlotAsync = async (onSuccess, onFail) => {
		try {
			await deleteSlot(id, editType, isResource);
			onSuccess();
		} catch (error) {
			onFail();
		}
	};

	const increaseCapacityAsync = async (capacity, onSuccess, onFail) => {
		try {
			const type = details && Object.prototype.hasOwnProperty.call(details, 'experience') ? 'experience' : 'resource';
			details?.id && await increaseCapacity(details.id, capacity).then(() =>
				details?.id && getSlotAsync(details?.id, type)
			);
			onSuccess();
		} catch (error) {
			onFail();
		}
	}

	const onIncreaseCapacityFinished = () => {
		setShowIncreaseCapacityDialog(false);
	};

	const tabs = useMemo(() => {
		return details?.status?.value === "pending_reschedule" && details?.booking_intents?.length > 0
			? ['bookings', 'participants', 'reschedule_bookings', 'pricing']
			: ['bookings', 'participants', 'pricing'];
	}, [details]);

	return (
		<div
			className="booking-details-wrapper"
			style={{ top: 0 }}
		>
			<div
				className="booking-header-container"
				style={{
					height: 'unset',
					padding: '20px 30px',
					justifyContent: 'initial'
				}}
			>
				<div className="booking-detail-back-button" onClick={() => dismiss()}>
					<img src={back} alt="back-svg" />
				</div>
				{details?.experience ? (
					<div
						className="custom-experience-image-container"
						style={{ marginLeft: 8 }}
					>
						<img
							onError={(e) => {
								e.currentTarget.src = logo;
								setHasError(true);
							}}
							src={ImageHelper.getFullImageUrl(
								details?.experience?.assets?.[0]?.thumbnail_url
							)}
							className="custom-experience-image"
							alt="experience-assets"
						/>
						{!hasError ? (
							<div
								className="custom-experience-image-color"
								style={{ background: details?.experience?.color }}
							/>
						) : null}
					</div>
				) : null}
				<h2 style={{ marginLeft: '20px' }}>
					{details?.experiences
						? dictionary.resource_based_experience
						: details?.title}
				</h2>

				<div
					className="booking-option-buttons-container"
					style={{ marginLeft: '24px' }}
				>
					<StatusLabel type="slot" status={slotStatus}/>
					{showActions() ? (
						<div
							onClick={() =>
								onDeleteSlotClicked(
									Object.prototype.hasOwnProperty.call(details, 'experiences')
								)
							}
							className="secondary-button"
							style={{ gap: '6px', height: '34px' }}
							data-testid="delete-slot-button"
						>
							<img src={bin} alt="bin-svg" />
							{dictionary.delete}
						</div>
					) : null}
					{showIncreaseCapacity() &&
						<div
							onClick={() =>
								onIncreaseCapacityClicked()
							}
							className="secondary-button"
							style={{gap: '6px', height: '34px'}}
							data-testid="delete-slot-button"
						>
							{dictionary.increase_capacity}
						</div>
					}
				</div>
			</div>

			<div className="booking-details-body">
				<div className="booking-details-container">
					<div className="settings-tab-bar">
						{tabs.map((tab, index) =>
							<div
								key={index}
								onClick={() => setTabIndex(tab)}
								className={tabIndex === tab ? 'settings-tab settings-tab-active' : 'settings-tab'}
								data-testid={`${tab}-tab`}
							>
								{dictionary[tab]}
							</div>
						)}
					</div>
					{details ? routeMap[tabIndex] : null}
					<SimpleLoader showLoader={showLoader} />
				</div>
				{details ? (
					<SlotInfo
						showActions={showActions()}
						onEditSlotClicked={onEditSlotClicked}
						details={details}
					/>
				) : null}
				{showScheduleDialog ? (
					<ScheduleSlotDialog
						isResource={isResource}
						editType={editType}
						editSlot={details}
						scheduleMode={scheduleMode}
						setShowScheduleDialog={setShowScheduleDialog}
						onNoResults={onNoResults}
						getSlotAsync={getSlotAsync}
					/>
				) : null}
				{showEditSlotTypeDialog ? (
					<EditSlotTypeDialog
						isDelete={isDelete}
						isResource={isResource}
						showEditSlotDialogWithMode={showEditSlotDialogWithMode}
						editSlot={details}
						setEditSlotTypeDialogVisibility={setEditSlotTypeDialogVisibility}
					/>
				) : null}

				{showDeleteDialog ? (
					<ConfirmationModal
						onActionFinished={dismiss}
						setShowModal={setDeleteDialogVisibility}
						asyncTask={deleteSlotAsync}
						title={dictionary.delete_slot}
						message={dictionary.delete_slot_message}
						successMessage={dictionary.slot_deleted}
						errorMessage={dictionary.problem_deleting_slot}
						action={dictionary.delete}
					/>
				) : null}
				{showNoItemsYetDialog ? (
					<NoItemsYetDialog
						setDialogVisibility={setNoItemsYetDialogVisibility}
						mode={scheduleMode}
					/>
				) : null}
				{showIncreaseCapacityDialog ? (
					<IncreaseCapacityDialog
						asyncTask={increaseCapacityAsync}
						setShowModal={setShowIncreaseCapacityDialog}
						onCancel={() => setShowIncreaseCapacityDialog(false)}
						onActionFinished={onIncreaseCapacityFinished}
						initialValue={details?.capacity}
					/>
				) : null }
			</div>
			{/*{showBookingDetails && selectedBooking ? (*/}
			{/*	<BookingDetails*/}
			{/*		dismissDetails={dismissBookingDetails}*/}
			{/*		bookingId={selectedBooking.id}*/}
			{/*	/>*/}
			{/*) : null}*/}
		</div>
	);
};

export default connect(common.states, common.actions)(SlotDetails);
