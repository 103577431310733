import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useExperienceSlotAccess } from '../../../utilities/hooks/useExperienceSlotAccess';

const MultipeCustomAccessCheckbox = ({
	label,
    checbboxObj,
    itemKey,
	onFilterAccessSelected,
	setEnableScheduling,
	filterCount,
	name = null,
	type
}) => {
	const getSize = () => {
		if (!type) {
			return '16px';
		} else {
			if (type === 1) {
				return '16px';
			} else if (type === 2) {
				return '24px';
			}
		}
	};

    const asccessStatuses = useExperienceSlotAccess();
    const [checkboxChecked, SetCheckboxChecked] = useState(false);
   
    useEffect(() => {
		SetCheckboxChecked(checbboxObj[itemKey]);
	}, []);

    const setCheckboxCheckedChoose = (itemKey) => {
		let acccessIds = JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
        SetCheckboxChecked(!checbboxObj[itemKey]);
        checbboxObj[itemKey] = !checbboxObj[itemKey];
        Object.keys(asccessStatuses).map((asccessStatus) => {
            if (asccessStatus === itemKey) {
				if (checbboxObj[asccessStatus] === true) {
					if (!acccessIds.includes(asccessStatus)) {
						acccessIds.push(asccessStatus);
					}
				} else {
					if (acccessIds.includes(asccessStatus)) { 
						for( var i = 0; i < acccessIds.length; i++) { 
							if ( acccessIds[i] === asccessStatus) { 
								acccessIds.splice(i, 1); 
							}
						
						}
					}
				}

            }
        });

		if (acccessIds.length > 0) {
			localStorage.setItem('calendar_filter_access', JSON.stringify(acccessIds));
			setEnableScheduling(true);
		} else {
			localStorage.removeItem('calendar_filter_access');
			if (filterCount - 1 <= 0) {
				setEnableScheduling(false);
			}
		}
		
		onFilterAccessSelected(acccessIds);
    }

	return (
		<div
			onClick={() => setCheckboxCheckedChoose(itemKey)}
			className="form-check-container"
			style={{ marginLeft: '0' }}
			data-testid={name ? name : 'custom-checkbox'}
		>
			<div
				style={{ width: getSize(), height: getSize() }}
				className={
					'custom-checkbox ' +
					(checkboxChecked
						? type === 1 || !type
							? 'custom-checkbox-checked'
							: 'custom-checkbox-checked-medium'
						: 'custom-checkbox')
				}
			/>
			<label
				style={{ marginBottom: 0, marginLeft: '10px', fontWeight: 400 }}
				className="checkbox-label"
			>
				{label}
			</label>
			{name ? (
				<input
					name={name}
					type="checkbox"
					style={{ display: 'none' }}
					checked={checkboxChecked}
					readOnly={true}
					data-testid={name}
				/>
			) : null}
		</div>
	);
};

MultipeCustomAccessCheckbox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.number,
	itemKey: PropTypes.string,
    checbboxObj: PropTypes.array,
	onFilterAccessSelected: PropTypes.func,
	setEnableScheduling: PropTypes.func,
	filterCount: PropTypes.number
};

export default MultipeCustomAccessCheckbox;
