import '../../../../../assets/css/bookings.css';
import '../../../../../assets/css/tables.css';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import ResaleBookingItem from './ResaleBookingItem';
import { getResaleBookings } from '../networking';
import React, { useEffect, useState } from 'react';

const ResaleBookings = () => {
	const dictionary = useDictionary();

	const [showLoader, setShowLoader] = useState(false);
	const [bookings, setBookings] = useState([]);

	const [sortSelectedOption, setSortSelectedOption] = useState(null);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('bookings_selected_page_size') ?? 10)
	);
	const [currentPage, setCurrentPage] = useState(0);
	const [dataLength, setDataLength] = useState(0);

	useEffect(() => {
		getAllBookingsAsync();
	}, [pageSize, currentPage, sortSelectedOption]);

	const getAllBookingsAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getResaleBookings(
				sortSelectedOption,
				pageSize,
				currentPage
			);
			setDataLength(data.recordsTotal);
			setBookings(data.data);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};

	return (
		<div
			className="calendar-container"
			style={{
				position: 'relative',
				background: 'var(--background-color)',
				paddingTop: '0'
			}}
		>
			<div className="booking-header-container">
				<h2 className="title-main" data-testid="bookings-header">
					{dictionary.bookings}
				</h2>
			</div>
			<div className="upiria-table-container">
				<div className="upiria-table-header-strip" />
				<div className="upiria-table-container-inner thin-scrollbar-trans">
					<table
						data-testid="bookings-table"
						cellSpacing="0"
						cellPadding="0"
						border="0"
					>
						<thead>
							<tr>
								<th>#</th>
								<th>{dictionary.create_date}</th>
								<th>{dictionary.status}</th>
								<th>{dictionary.provider}</th>
								<th>{dictionary.booked}</th>
								<th>{dictionary.booked_date}</th>
								<th>{dictionary.collection}</th>
								<th>{dictionary.customer}</th>
								<th>{dictionary.payment}</th>
								<th>{dictionary.amount}</th>
								<th>{dictionary.commission}</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{bookings?.map((item) => (
								<ResaleBookingItem resaleBookingItem={item} />
							))}
						</tbody>
					</table>
				</div>
				{bookings?.length === 0 ? (
					<div
						className="empty-experiences-list-wrapper"
						style={{ height: '80%' }}
					>
						<div className="empty-experiences-list-placeholder">
							<div className="empty-experiences-image empty-bookings-image" />
							<h3
								data-testid="no-bookings-yet-header"
								style={{ textAlign: 'center', marginTop: '24px' }}
							>
								{dictionary.start_selling}
							</h3>
							<div style={{ marginTop: '8px' }} className="text">
								{dictionary.your_bookings_will_be_listed_here}
							</div>
						</div>
					</div>
				) : null}
			</div>
			{showLoader ? <SimpleLoader showLoader={showLoader} /> : null}
		</div>
	);
};

export default ResaleBookings;
