import { connect } from 'react-redux';
import * as common from '../../auth/__redux/authRedux';
import axios from 'axios';

import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import SortOptionsSelector from '../../common/sort_options/SortOptionsSelector';
import ViewTypeSelector from '../../common/ViewTypeSelector';
import FormLoader from '../../common/FormLoader';
import { addToCollection, getMarketplaceExperiences } from './networking';
import ExperienceListItemFull from './components/ExperienceListItemFull';
import ExperienceListItemCard from './components/ExperienceListItemCard';
import ResaleExperienceButtons from './components/ResaleExperienceButtons';
import ResaleExperienceDetails from './components/ResaleExperienceDetails';
import ResultModal from '../../common/ResultModal';
import InviteModal from './components/InviteModal';
import ExperienceDetailDialog from './components/ExperienceDetailDialog';
import SearchLocation from './components/SearchLocation';
import PaginationController from '../../common/pagination_controller/PaginationController';

const ResaleMarketplace = () => {
	const source = axios.CancelToken.source();
	const dictionary = useDictionary();

	const [experienceList, setExperienceList] = useState([]);
	const [isEmptyList, setIsEmptyList] = useState(true);

	// Modals
	const [showLoader, setShowLoader] = useState(false);
	const [showMessage, setShowMessage] = useState(0);
	const [message, setMessage] = useState('');
	const [messageType, setMessageType] = useState('success');
	const [showInviteModal, setShowInviteModal] = useState(false);
	const [selectedViewUrl, setSelectedViewUrl] = useState(null);

	// Sorting
	const [selectedOption, setSelectedOption] = useState(null);

	// Listing view type
	const [viewType, setViewType] = useState(
		parseInt(localStorage.getItem('resale_market_places_selected_view_type') ?? 0)
	);

	// Pagination
	const [dataLength, setDataLength] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('resale_market_places_selected_page_size') ?? 10)
	);

	const [searchParam, setSearchParam] = useState('');

	useEffect(() => {
		if (selectedOption) {
			getMarketplaceAsync();
		}
	}, [selectedOption, searchParam, currentPage, selectedOption]);

	const displayMessage = (_message, _type) => {
		setShowLoader(false);
		setMessage(_message);
		setMessageType(_type);
		setTimeout(() => {
			setShowMessage(1);
		}, 50);
	};

	const closeMessage = () => {
		setTimeout(() => {
			setShowMessage(0);
			getMarketplaceAsync();
		}, 1500);
	};

	const getMarketplaceAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getMarketplaceExperiences(
				selectedOption,
				searchParam,
				pageSize,
				currentPage,
				source.token
			);
			
			if (data.data.length === 0 && data.recordsTotal > 0) {
				setCurrentPage(0);
				const { data } = await getMarketplaceExperiences(
					selectedOption,
					searchParam,
					pageSize,
					currentPage,
					source.token
				);
				setIsEmptyList(data.data.length === 0);
				setExperienceList(data.data);
				setDataLength(data.recordsTotal);
			} else {
				setIsEmptyList(data.data.length === 0);
				setExperienceList(data.data);
				setDataLength(data.recordsTotal);
			}
			
		} catch (e) {
			// todo check logger
		} finally {
			setShowLoader(false);
		}
	};

	const addToCollectionAsync = async (experienceId) => {
		setShowLoader(true);
		try {
			await addToCollection(experienceId);
			displayMessage(dictionary.added_to_resale_experiences, 'success');
		} catch (error) {
			displayMessage(dictionary.problem_adding_experience, 'error');
		} finally {
			closeMessage();
		}
	};

	const onOpenInviteModal = () => {
		setShowInviteModal(true);
	};

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};

	const onViewTypeChanged = (type) => {
		setViewType(type);
	};

	const handleOpenDetailDialog = (viewUrl) => {
		setSelectedViewUrl(viewUrl);
	};

	const handleCloseDetailDialog = () => {
		setSelectedViewUrl(null);
	};

	const onSearchValueChanged = (value) => {
		setSearchParam(value);
	}

	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};

	// Children components
	const getActionButtons = (experience) => {
		const ActionButtons = ({ children }) => {
			return (
				<ResaleExperienceButtons
					experience={experience}
					inviteProviderHandler={addToCollectionAsync}
					viewExperienceHandler={handleOpenDetailDialog}
				/>
			);
		};

		return ActionButtons;
	};

	const getExperienceDetails = (experience) => {
		const ExperienceDetails = ({ children }) => {
			return (
				<ResaleExperienceDetails
					category={[]}
					location={experience.address}
					language={[]}
					duration={experience.duration}
					capacity={experience.capacity}
					child_friendly={experience.child_friendly}
					accessible={experience.accessible}
				/>
			);
		};

		return ExperienceDetails;
	};

	const getExperienceSubcategories = (experience) => {
		const ExperienceSubcategories = ({ children }) => {
			return (
				<div className="resale-collection-item-experience-block-categories">
					{experience.tags.map((tag, index) => (
						<div
							key={index}
							className="resale-collection-item-experience-block-categories-item"
							style={{ background: tag.color.replace('0xFF', '#') }}
						>
							<div className="resale-collection-item-experience-block-categories-item-text">
								{tag.title}
							</div>
						</div>
					))}
				</div>
			);
		};

		return ExperienceSubcategories;
	};
	const renderMarketplaceExperiences = () => {
		// Full view
		if (viewType === 0) {
			return (
				<div className="resale-collection-items-wrapper thin-scrollbar">
					{experienceList.map((experience) => {
						return (
							<ExperienceListItemFull
								key={experience.experience_id}
								experience={experience}
								HeaderItems={getExperienceSubcategories(experience)}
								ActionButtons={getActionButtons(experience)}
								ExperienceDetails={getExperienceDetails(experience)}
							/>
						);
					})}
				</div>
			);
		} else {
			// Card view
			return (
				<div className="resale-collection-items-wrapper resale-collection-items-wrapper-card thin-scrollbar">
					{experienceList.map((experience) => (
						<ExperienceListItemCard
							key={experience.experience_id}
							experience={experience}
							HeaderItems={getExperienceSubcategories(experience)}
							ActionButtons={getActionButtons(experience)}
						/>
					))}
				</div>
			);
		}
	};

	return (
		<div className="experiences-wrapper">
			<div className="experiences-top-bar">
				<h2 data-testid="resale-collection-title">{dictionary.marketplace}</h2>
				<div className="experiences-actions">
					<div className="experience-create-button">
						<div
							onClick={onOpenInviteModal}
							className="button"
							data-testid="resale-marketpalce-invite-company"
						>
							<div className="plus-icon" />
							{dictionary.invite_company}
						</div>
					</div>
				</div>
				<div className="flex-filler" />
				<div className="experiences-filter-actions">
					<SearchLocation onValueChanged={onSearchValueChanged} />
					<SortOptionsSelector
						onValueChanged={onSortOptionValueChanged}
						name={'resale_marketplace'}
					/>
					<div style={{ width: '16px' }} />
					<ViewTypeSelector
						onValueChanged={onViewTypeChanged}
						name={'resale_marketplace'}
					/>
				</div>
				<div className="experiences-mobile-actions">
					<div
						onClick={onOpenInviteModal}
						className="button"
						data-testid="resale-marketpalce-invite-company"
					>
						<div className="plus-icon" />
						{dictionary.invite_company}
					</div>
				</div>
			</div>
			<div className="experiences-mobile-filter-actions">
				<SearchLocation onValueChanged={onSearchValueChanged} />
				<SortOptionsSelector
					onValueChanged={onSortOptionValueChanged}
					name={'resale_marketplace'}
				/>
				<div style={{ width: '16px' }} />
				<ViewTypeSelector
					onValueChanged={onViewTypeChanged}
					name={'resale_marketplace'}
				/>
			</div>
			{isEmptyList ? (
				<div className="empty-experiences-list-wrapper">
					<div className="empty-experiences-list-placeholder">
						<div className="empty-experiences-image" />
						<h3
							style={{ textAlign: 'center', marginTop: '24px' }}
							data-testid="no-experiences-yet-label"
						>
							{dictionary.no_experiences_yet}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.all_experiences_will_be_listed}
						</div>
					</div>
				</div>
			) : (
				<>{renderMarketplaceExperiences()}</>
			)}
			<ExperienceDetailDialog
				viewUrl={selectedViewUrl}
				showDialog={Boolean(selectedViewUrl)}
				setShowDialog={handleCloseDetailDialog}
			/>
			<PaginationController
				name="resale_market_places"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			<FormLoader showLoader={showLoader} showEnter={false} />
			{showInviteModal && <InviteModal setShow={setShowInviteModal} />}
			{showMessage ? (
				<ResultModal
					opacity={showMessage}
					message={message}
					type={messageType}
				/>
			) : null}
		</div>
	);
};

export default connect(common.states, common.actions)(ResaleMarketplace);
