// import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
import React from 'react';
import PropTypes from 'prop-types';

const ParticipantItem = ({ participant }) => {
	// const dictionary = useDictionary();

	return (
		<div
			key={participant.name}
			className="participant-row-container"
			style={{ marginBottom: (window.innerWidth > 767 ? '16px' : '8px') }}
			data-testid="participant-item"
		>
			{<img className="participant-avatar" />}
			<h4
				className='truncate'
				title={participant.name}
				style={{ fontWeight: 'normal', marginLeft: '8px', minWidth: '164px', maxWidth:'100px' }}
			>
				{participant.name}
			</h4>
			<h4 style={{ fontWeight: 'normal', marginLeft: '8px', opacity: '0.6' }}>
				{participant.quantity.toString()}
			</h4>
			{/*<div className='flex-filler'/>
            <h4 style={{fontSize:'12px', cursor:'pointer'}}>{dictionary.edit}</h4>
            <h4 style={{marginLeft:'20px', fontSize:'12px', cursor:'pointer', color:'var(--brand-red)'}}>{dictionary.remove}</h4>*/}
		</div>
	);
};

ParticipantItem.propTypes = {
	participant: PropTypes.object.isRequired
};

export default ParticipantItem;
