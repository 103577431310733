import logo from '../../../../../assets/images/logo_white_letter.svg';
import bin from '../../../../../assets/icons/ic_trash.svg';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { useState } from 'react';
import PropTypes from 'prop-types';
import React from 'react';

const LinkedExperienceItem = ({ experience, removeExperience }) => {
	const [hasError, setHasError] = useState(false);

	return (
		<div
			style={{ marginBottom: '8px', cursor: 'unset' }}
			className={'select-experience-item select-item-small'}
		>
			<div className="custom-experience-image-container">
				<img
					onError={(e) => {
						e.currentTarget.src = logo;
						setHasError(true);
					}}
					src={ImageHelper.getFullImageUrl(experience?.assets?.[0]?.thumbnail_url)}
					className="custom-experience-image"
				/>
				{!hasError ? (
					<div
						className="custom-experience-image-color"
						style={{ background: experience?.color }}
					/>
				) : null}
			</div>
			<div className="custom-experience-title">{experience?.title}</div>
			<div className="flex-filler" />
			<img
				onClick={() => removeExperience(experience)}
				style={{ cursor: 'pointer' }}
				src={bin}
				data-testid="remove-experience"
			/>
		</div>
	);
};

LinkedExperienceItem.propTypes = {
	experience: PropTypes.object,
	removeExperience: PropTypes.func
};

export default LinkedExperienceItem;
