import React from 'react';
import { useState, useEffect } from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import DialogLoader from '../../../common/DialogLoader';
import ResultModal from '../../../common/ResultModal';
import PropTypes from 'prop-types';

const IncreaseCapacityModal = ({
    setShowModal,
    initialValue,
    onCancel,
    onActionFinished,
    asyncTask
}) => {
    const dictionary = useDictionary();
    const [opacity, setOpacity] = useState(0);
    const [showLoader, setLoaderVisibility] = useState(false);
    const [status, setStatus] = useState(null);
	const [resultMessage, setResultMessage] = useState(null);
    const [capacity, setCapacity] = useState(+(initialValue || 0));

    useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

    useEffect(() => {
        setCapacity(+(initialValue || 0));
        }, [initialValue])

    const onCancelClicked = () => {
        setOpacity(0);
        setTimeout(() => {
			setShowModal(false);
			if (onCancel) onCancel();
		}, 800);
	};

    const onActionComplete = () => {
		setLoaderVisibility(false);
		setStatus('success');
		setResultMessage('Increased Capacity');
		messageTimeOut();
	};

	const onActionFail = () => {
		setLoaderVisibility(false);
		setStatus('error');
		setResultMessage('Failed Increase Capacity');
		messageTimeOut();
	};

	const messageTimeOut = () => {
		setTimeout(() => {
			onCancelClicked();
			onActionFinished();
		}, 2000);
	};

    const onConfirmClicked = () => {
		if (onActionFinished) {
			setLoaderVisibility(true);
		}
		asyncTask(capacity, onActionComplete, onActionFail);
	};

    
    return status !== null ? (
		<ResultModal opacity={opacity} message={resultMessage} type={status} />
	) : (
        <div className="dialog-wrapper fade-on-load">
			<div className="dialog-container dialog-container-mid">
				<div className="dialog-title-container">
					<div className="dialog-title" style={{ fontWeight: 500 }}>
						{dictionary.increase_capacity}
					</div>
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container">
					<h3 style={{ fontSize: '16px' }}>{dictionary.seats}</h3>
					<div
						className="group-inputs d-flex justify-content-between"
					>
						<input
							type="number"
							min={initialValue}
							step="1"
							onKeyDown={(e) =>
								['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
							}
							value={capacity}
							onChange={(e) => setCapacity(e.target.value)}
							data-testid="max-participants-input"
						/>
					</div>
				</div>
				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{ padding: '12px', marginBottom: '0' }}
				>
					<div onClick={onCancelClicked} className="tertiary-button">
						{dictionary.cancel}
					</div>
					<div onClick={() => onConfirmClicked()} className="button">
						{dictionary.save}
					</div>
				</div>
				<DialogLoader showLoader={showLoader} />
			</div>
		</div>
    )
}

IncreaseCapacityModal.propTypes = {
    initialValue: PropTypes.number,
    setShowModal: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    onActionFinished: PropTypes.func, // Add this line
    asyncTask: PropTypes.func.isRequired,
}

export default IncreaseCapacityModal;