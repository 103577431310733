import eye from '../../../../../assets/icons/ic_eye.svg';
import { ReactComponent as LeafIcon } from '../../../../../assets/icons/ic_leaf.svg';
import placeholder from '../../../../../assets/images/bg_placeholder_square.svg';
import { CURRENCY } from '../../../../../config';
import ConverterHelper from '../../../../../utilities/helpers/Converters';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../../utilities/hooks/UseUserTypeId';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Flag from 'react-world-flags';
import Tooltip from '../../../common/Tooltip';
import {getMatchedCountryData} from "../../../../../utilities/helpers/LanguageHelper";

const ExperienceListItemFull = ({
	experience,
	setSelectedAddGroup,
	viewExperienceHandler,
    allLanguages
}) => {
	const dictionary = useDictionary();
	const history = useHistory();

	const userType = useUserTypeId();
	const [isCopied, setIsCopied] = useState(null);

	const onCopyClicked = (shareUrl) => {
		navigator.clipboard.writeText(shareUrl).then(
			function () {
				setIsCopied(shareUrl);
				setTimeout(() => {
					setIsCopied(null);
				}, 2000);
			},
			// eslint-disable-next-line no-unused-vars
			function (err) {}
		);
	};

	const getStatusLabel = () => {
		let statuses = {
			1: {
				label: dictionary.available,
				class: 'experience-item-label-active'
			},
			2: {
				label: dictionary.in_progress,
				class: 'experience-item-label-unavailable'
			},
			3: {
				label: dictionary.unavailable,
				class: 'experience-item-label-unavailable'
			},
			4: {
				label: dictionary.paused,
				class: 'experience-item-label-error'
			},
			5: {
				label: dictionary.add_availability,
				class: 'experience-item-label-add-availability'
			}
		};
		return (
			<div
				onClick={() => {
					if (experience.status.id === 5) {
						history.push('/calendar');
					}
				}}
				className={
					'experience-item-label ' + statuses[experience.status.id].class
				}
				data-testid="experience-item-status-label"
			>
				{statuses[experience.status.id].label}
			</div>
		);
	};

	const getViewButton = () => {
		const buttonText = dictionary.preview;
		return (
				<a
					onClick={() => viewExperienceHandler(experience.view_url)}
					className="experience-item-button-container"
					rel="noopener noreferrer"
					data-testid="experience-item-view-button"
				>
					<img src={eye} />
					<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
				</a>
			);
	};

	const getPreviewViewButton = () => {
		const buttonText = dictionary.view;
		const handleViewClick = () => {
			window.open(experience.view_url, '_blank', 'noreferrer');
		};

		return (
			<div
				onClick={handleViewClick}
				className="experience-item-button-container"
				data-testid="experience-item-view-button"
			>
				<img src={eye} />
				<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
			</div>
		);
	};

	const getCopyButton = () => {
		return (
			<div
				onClick={() => onCopyClicked(experience.view_url)}
				className="experience-item-button-container"
				data-testid="experience-item-copy-button"
			>
				<div
					className={`share-link-copy-icon ${isCopied === experience.view_url ? 'copied' : ''}`}
					data-testid="share-link-copy-icon"
				></div>
				<h4 style={{ fontSize: '12px' }}>{dictionary.copy}</h4>
			</div>
		);
	};
	const getGroupButton = () => {
		return (
			<div
				onClick={() => setSelectedAddGroup(experience)}
				className="experience-item-button-container"
				data-testid="experience-item-delete-button"
			>
				<h4 style={{ fontSize: '12px' }}>+ {dictionary.add_to_group}</h4>
			</div>
		);
	};

	return (
		<div key={experience?.id} className="experience-item-full-container">
			<div className="experience-item-basic-info-container">
				<div className="experience-item-row" style={{ maxWidth: 'unset' }}>
					{getStatusLabel()}
					<div className="experience-flags-container ">
						{/*<img src={uk} />*/}
						{experience.languages.slice(0, 4)
							?.map(({ language_id }, index) => {
								const { code } = allLanguages?.find((languageItem) => languageItem.id === language_id) || {};
								return (
									<div key={index} className="experience-flag-item">
										<Flag
											code={getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											fallback={
												<span>
												{getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											</span>
											}
										/>
									</div>

								)
							})}
						{experience.languages.length > 4 &&
							<div className="experience-flag-item more-label">
								+ {experience.languages.length - 4}
							</div>
						}
					</div>
				</div>
				{userType === 1 ? (
					<div style={{ marginTop: '6px' }}>
						<h3 className='truncate' title={experience?.provider.brand_name}>{experience?.provider.brand_name}</h3>
						<h4 className='truncate' title={experience?.provider?.first_name + ' ' + experience?.provider?.last_name}>
							{experience?.provider?.first_name +
								' ' +
								experience?.provider?.last_name}
						</h4>
						<h4 className='truncate' title={experience?.provider?.email}>{experience?.provider?.email}</h4>
						<div
							style={{ marginTop: '4px', marginBottom: '4px' }}
							className="dialog-title-divider"
						/>
					</div>
				) : null}
				<div className="flex-filler-exp">
					<h3>{experience.title}</h3>
					<h4 style={{ marginTop: 3 }} className='truncate' title={experience.location}>Location: {experience.location}</h4>
				</div>
				<div
					className="experience-item-row"
					style={{ maxWidth: 'unset', marginTop: 10 }}
				>
					<div style={{ opacity: '0.6' }} className="text ellipsize">
						{experience.description}
					</div>
					{experience.is_eco_friendly && (
						<div className="d-flex">
							<LeafIcon />
							<span
								style={{
									marginLeft: 4,
									font: 'normal normal 500 14px/16px Roboto'
								}}
							>
								{dictionary.eco}
							</span>
						</div>
					)}
				</div>
				<div className="experience-item-buttons-container experience-item-buttons-container-full-flex">
					{getViewButton()}
					{getGroupButton()}
					{getPreviewViewButton()}
					{getCopyButton()}
				</div>
			</div>
			<div className="experience-thumbs-container">
			<div className="image-wrapper">
				<img
					className="experience-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[0]
							? ImageHelper.getFullImageUrl(
									experience?.assets[0]
							  )
							: placeholder
					}
				/>
				<img
					className="experience-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[1]
							? ImageHelper.getFullImageUrl(
									experience?.assets[1]
							  )
							: placeholder
					}
				/>
				<img
					className="experience-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[2]
							? ImageHelper.getFullImageUrl(
									experience?.assets[2]
							  )
							: placeholder
					}
				/>
				<img
					className="experience-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[3]
							? ImageHelper.getFullImageUrl(
									experience?.assets[3]
							  )
							: placeholder
					}
				/>
				<div
					className="experience-thumb-color-line"
					style={{ background: experience.color }}
				/>
			</div>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="experience-small-column-heading">
					{dictionary.pricing}
					<div className="text-with-info calendar-verification-info">
						<Tooltip content={dictionary.pricing_are_per_participants} />
					</div>
				</div>
				<div className="experience-info-rows-container thin-scrollbar">
					{experience.participants_group.map((group) => (
						<div
							key={group.id}
							className="experience-item-row"
							style={{ marginBottom: '6px', maxWidth: '200px' }}
						>
							<div
								className="experience-info-title"
								style={{ opacity: '0.6', maxWidth: 130, width: '100%' }}
							>
								{group.name}
							</div>
							<div className="experience-info-title experience-info-text">
								{ConverterHelper.normalizePrice(group.rate)} {CURRENCY}
							</div>
						</div>
					))}
				</div>
				{experience.group_rates.length > 0 && (
					<>
						<div
							className="experience-small-column-heading"
							style={{ marginTop: 10 }}
						>
							{dictionary.group_rates}
							<div className="text-with-info calendar-verification-info">
								<Tooltip
									content={dictionary.group_rates_are_per_participants}
								/>
							</div>
						</div>
						<div className="experience-info-rows-container thin-scrollbar">
							{experience.group_rates.map((rate) => (
								<div
									key={rate.id}
									className="experience-item-row"
									style={{
										marginBottom: '6px',
										maxWidth: '200px',
										alignItems: 'flex-start'
									}}
								>
									<div
										className="experience-info-title"
										style={{ opacity: '0.6', maxWidth: 130, width: '100%' }}
									>
										{rate.min_size} - {rate.max_size}
									</div>
									<div className="experience-info-title experience-info-text">
										<div className="experience-info-title">
											-{rate.discount} %
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="experience-small-column-heading">
					{dictionary.details}
					<div className="text-with-info calendar-verification-info">
						<Tooltip content={dictionary.operation_information} />
					</div>
				</div>
				<div className="experience-info-rows-container thin-scrollbar">
					<div
						className="experience-item-row"
						style={{ marginBottom: '6px', maxWidth: '200px' }}
					>
						<div className="experience-info-title" style={{ opacity: '0.6' }}>
							{dictionary.duration}
						</div>
						<div className="experience-info-title experience-info-text">
							{ConverterHelper.secondsToDayHourMinute(
								experience.duration,
								dictionary
							)}
						</div>
					</div>
					<div
						className="experience-item-row"
						style={{ marginBottom: '6px', maxWidth: '200px' }}
					>
						<div className="experience-info-title" style={{ opacity: '0.6' }}>
							{dictionary.capacity}
						</div>
						<div className="experience-info-title experience-info-text">
							{experience.capacity + ' ' + dictionary.participants}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ExperienceListItemFull.propTypes = {
	experience: PropTypes.object.isRequired,
	setSelectedAddGroup: PropTypes.func.isRequired,
	viewExperienceHandler: PropTypes.func,
	allLanguages: PropTypes.array,
};

export default ExperienceListItemFull;
