import eye from '../../../../assets/icons/ic_eye.svg';
import pause from '../../../../assets/icons/ic_pause.svg';
import pencil from '../../../../assets/icons/ic_pencil.svg';
import play from '../../../../assets/icons/ic_play.svg';
import share from '../../../../assets/icons/ic_share.svg';
import trash from '../../../../assets/icons/ic_trash.svg';
import plus from '../../../../assets/icons/ic_plus_dark.svg';
import placeholder from "../../../../assets/images/bg_placeholder_square.svg";
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import { useAccount } from '../../../../utilities/hooks/UseAccount';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { getMatchedCountryData } from '../../../../utilities/helpers/LanguageHelper';
import { changeExperienceStatus } from './networking';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Flag from 'react-world-flags';

const ExperienceListItemCompact = ({
	experience,
	setSelectedShareExperience,
	setSelectedDeleteExperience,
	setSelectedAddGroup,
	onStatusChangeCompleted,
	onEditClicked,
	setShowLoader,
	setWarningMessage,
	allLanguages,
	viewExperienceHandler
}) => {
	const dictionary = useDictionary();
	const history = useHistory();

	const account = useAccount();

	const changeExperienceStatusAsync = async (experienceid, newStatus) => {
		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await changeExperienceStatus(experienceid, newStatus);
			onStatusChangeCompleted();
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getStatusLabel = () => {
		let statuses = {
			1: {
				label: dictionary.available,
				class: 'experience-item-label-active'
			},
			2: {
				label: dictionary.in_progress,
				class: 'experience-item-label-unavailable'
			},
			3: {
				label: dictionary.unavailable,
				class: 'experience-item-label-unavailable'
			},
			4: {
				label: dictionary.paused,
				class: 'experience-item-label-error'
			},
			5: {
				label: dictionary.add_availability,
				class: 'experience-item-label-add-availability'
			}
		};
		return (
			<div
				onClick={() => {
					if (experience.status.id === 5) {
						history.push('/calendar');
					}
				}}
				className={
					'experience-item-label ' + statuses[experience.status.id].class
				}
				data-testid="experience-status-label"
			>
				{statuses[experience.status.id].label}
			</div>
		);
	};


	const getPauseButton = () => {
		if (experience.status.id === 1) {
			return (
				<div
					onClick={() => changeExperienceStatusAsync(experience.id, 4)}
					className="experience-item-button-container"
					data-testid="pause-experience-button"
				>
					<img src={pause} />
				</div>
			);
		} else if (experience.status.id === 2) {
			return '';
		} else if (experience.status.id === 4) {
			return (
				<div
					onClick={() => changeExperienceStatusAsync(experience.id, 1)}
					className="experience-item-button-container"
					data-testid="resume-experience-button"
				>
					<img src={play} />
				</div>
			);
		}
		return null;
	};

	const getViewButton = () => {
		if (experience?.assets?.length > 0) {
			return (
				<a
					onClick={() => viewExperienceHandler(experience.view_url)}
					rel="noopener noreferrer"
					className="experience-item-button-container"
					data-testid="view-experience-button"
				>
					<img src={eye} />
				</a>
			);
		} else {
			return (
				<div
					onClick={() => setWarningMessage(dictionary.no_images_yet)}
					className="experience-item-button-container"
					data-testid="view-experience-button"
				>
					<img src={eye} />
				</div>
			);
		}
	};

	const getEditButton = () => {
		if (experience.status.id !== 1) {
			return (
				<div
					onClick={() => onEditClicked(experience.id)}
					className="experience-item-button-container"
					data-testid="edit-experience-button"
				>
					<img src={pencil} />
				</div>
			);
		} else {
			return null;
		}
	};

	const getShareButton = () => {
		const isVerified = account.verification_status.key === 'verified';
		const hasAssets = experience?.assets?.length > 0;

		const handleShareClick = () => {
			if (hasAssets) {
				setSelectedShareExperience(experience);
			} else {
				setWarningMessage(dictionary.no_images_yet);
			}
		};

		return (
			<div
				onClick={(isVerified && experience.share_url) ? handleShareClick : null}
				className={`experience-item-button-container${
					(isVerified && experience.share_url) ? '' : ' disabled'
				}`}
			>
				<img src={share} />
			</div>
		);
	};

	const getGroupButton = () => {
		return (
			<div
				onClick={() => setSelectedAddGroup(experience)}
				className="experience-item-button-container"
				data-testid="experience-item-group-button"
			>
				<img src={plus} />
			</div>
		);
	};

	return (
		<div key={experience?.id} className="experience-item-compact-container">
			<div className="experience-item-row" style={{ maxWidth: 'unset' }}>
				{getStatusLabel()}
				<div className="experience-flags-container">
					{experience.languages.slice(0, 2)
						?.map(({ language_id }, index) => {
							const { code } = allLanguages?.find((languageItem) => languageItem.id === language_id) || {};
							return (
								<div key={index} className="experience-flag-item">
									<Flag
										code={getMatchedCountryData(code)?.countryCode?.toLowerCase()}
										width={20}
										fallback={
											<span>
												{getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											</span>
										}
									/>
								</div>

							)
						})}
					{experience.languages.length > 2 &&
						<div className="experience-flag-item more-label">
							+ {experience.languages.length - 2}
						</div>
					}
				</div>
			</div>

			<h3 style={{ marginTop: '12px' }}>{experience.title}</h3>
			<div
				style={{ marginTop: '10px', opacity: '0.6' }}
				className="text ellipsize"
			>
				{experience.description}
			</div>
			<div className="flex-filler" />
			<div
				className="experience-item-buttons-container-compact"
				style={{ flexFlow: 'row' }}
			>
				{getEditButton()}
				{getViewButton()}
				{getPauseButton()}
				{getGroupButton()}
				{experience.status.id !== 1 ? (
					<div
						onClick={() => setSelectedDeleteExperience(experience)}
						className="experience-item-button-container"
					>
						<img src={trash} />
					</div>
				) : null}
				{getShareButton()}
			</div>
			<div className="experience-compact-thumbs-container">
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[0]
							? ImageHelper.getFullImageUrl(
									experience?.assets[0]?.thumbnail_url
							  )
							: placeholder
					}
				/>
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[1]
							? ImageHelper.getFullImageUrl(
									experience?.assets[1]?.thumbnail_url
							  )
							: placeholder
					}
				/>
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[2]
							? ImageHelper.getFullImageUrl(
									experience?.assets[2]?.thumbnail_url
							  )
							: placeholder
					}
				/>
				<div
					className="experience-thumb-color-line"
					style={{ background: experience.color }}
				/>
			</div>
		</div>
	);
};

ExperienceListItemCompact.propTypes = {
	experience: PropTypes.object,
	setSelectedShareExperience: PropTypes.func,
	setSelectedDeleteExperience: PropTypes.func,
	setSelectedAddGroup: PropTypes.func.isRequired,
	onStatusChangeCompleted: PropTypes.func,
	onEditClicked: PropTypes.func,
	setShowLoader: PropTypes.func,
	setWarningMessage: PropTypes.func,
	allLanguages: PropTypes.array,
	viewExperienceHandler: PropTypes.func
};

export default ExperienceListItemCompact;
