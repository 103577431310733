import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useFormik } from 'formik';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const WizardFinalForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);

	const wizardFinalSchema = Yup.object().shape({
		ecommerce: Yup.boolean(),
		presents: Yup.boolean(),
		courier: Yup.boolean(),
		upiria_co: Yup.boolean()
	});

	const formik = useFormik({
		initialValues: {
			ecommerce: props.details.ecommerce,
			presents: props.details.presents,
			courier: props.details.courier,
			upiria_co: props.details.upiria_co
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, props.details);
			newDetails.ecommerce = values.ecommerce;
			newDetails.presents = values.presents;
			newDetails.courier = values.courier;
			newDetails.upiria_co = values.upiria_co;

			props.setDetails(newDetails);
			props.onCompleteClicked();
		},
		enableReinitialize: true,
		validateOnMount: true,
		validationSchema: wizardFinalSchema
	});

	FormikHelper.setFormik(formik);

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const onCheckboxChanged = (name, value) => {
		let newDetails = Object.assign({}, props.details);
		newDetails[name] = value;
		formik.setFieldValue(name, value);
		props.setDetails(newDetails);
	};

	return (
		<div
			style={{ opacity: showForm ? '1' : '0', overflowY: 'auto' }}
			className="login-form-content-container transparent-form thin-scrollbar"
			onSubmit={formik.handleSubmit}
		>
			<h1>{dictionary.step4_title}</h1>
			<img src="https://t24390293.p.clickup-attachments.com/t24390293/915b2f3d-b75f-4c54-9674-55177e69d73f/upiria-community.svg?view=open" />
			<div className="text">{dictionary.step4_message}</div>
			{/*{FormikHelper.getCheckboxTemplate(*/}
			{/*	'ecommerce',*/}
			{/*	dictionary.e_commerce_newsletter_title,*/}
			{/*	false,*/}
			{/*	onCheckboxChanged*/}
			{/*)}*/}
			{/*<div style={{ marginLeft: '30px' }} className="text">*/}
			{/*	{dictionary.e_commerce_newsletter_text}*/}
			{/*</div>*/}

			{/*{FormikHelper.getCheckboxTemplate(*/}
			{/*	'presents',*/}
			{/*	dictionary.upiria_presents,*/}
			{/*	false,*/}
			{/*	onCheckboxChanged*/}
			{/*)}*/}
			{/*<div style={{ marginLeft: '30px' }} className="text">*/}
			{/*	{dictionary.upiria_presents_text}*/}
			{/*</div>*/}

			{/*{FormikHelper.getCheckboxTemplate(*/}
			{/*	'courier',*/}
			{/*	dictionary.courier_weekly_title,*/}
			{/*	false,*/}
			{/*	onCheckboxChanged*/}
			{/*)}*/}
			{/*<div style={{ marginLeft: '30px' }} className="text">*/}
			{/*	{dictionary.courier_weekly_text}*/}
			{/*</div>*/}

			{/*{FormikHelper.getCheckboxTemplate(*/}
			{/*	'upiria_co',*/}
			{/*	dictionary.upiria_co_title,*/}
			{/*	false,*/}
			{/*	onCheckboxChanged*/}
			{/*)}*/}
			{/*<div style={{ marginLeft: '30px' }} className="text">*/}
			{/*	{dictionary.upiria_co_text}*/}
			{/*</div>*/}

			{/*<div className="group-inputs" style={{ marginTop: '30px' }}>*/}
				<button
					data-testid="complete-button"
					type="submit"
					className={formik.isValid ? 'button' : 'button button-disabled'}
					onClick={props.onCompleteClicked}
					style={{ marginTop: 32 }}
				>
					{dictionary.complete}
				</button>
			{/*</div>*/}
		</div>
	);
};

WizardFinalForm.propTypes = {
	details: PropTypes.object.isRequired,
	setDetails: PropTypes.func.isRequired,
	onCompleteClicked: PropTypes.func.isRequired
};

export default WizardFinalForm;
