import eye from '../../../../assets/icons/ic_eye.svg';
import pause from '../../../../assets/icons/ic_pause.svg';
import pencil from '../../../../assets/icons/ic_pencil.svg';
import play from '../../../../assets/icons/ic_play.svg';
import share from '../../../../assets/icons/ic_share.svg';
import trash from '../../../../assets/icons/ic_trash.svg';
import { ReactComponent as LeafIcon } from '../../../../assets/icons/ic_leaf.svg';
import placeholder from '../../../../assets/images/bg_placeholder_square.svg';
import { CURRENCY } from '../../../../config';
import ConverterHelper from '../../../../utilities/helpers/Converters';
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { changeExperienceStatus } from './networking';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import Flag from 'react-world-flags';
import { getMatchedCountryData } from '../../../../utilities/helpers/LanguageHelper';
import Tooltip from '../../common/Tooltip';

const ExperienceListItemFull = ({
	accountConfig,
	experience,
	setSelectedShareExperience,
	setSelectedDeleteExperience,
	setSelectedAddGroup,
	onStatusChangeCompleted,
	onEditClicked,
	setShowLoader,
	setWarningMessage,
	viewExperienceHandler,
	allLanguages
}) => {
	const dictionary = useDictionary();
	const history = useHistory();
	// const account = useAccount();

	const userType = useUserTypeId();

	const changeExperienceStatusAsync = async (experienceid, newStatus) => {
		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await changeExperienceStatus(experienceid, newStatus);
			onStatusChangeCompleted();
		} catch (error) {
			setShowLoader(false);
		}
	};

	const getStatusLabel = () => {
		let statuses = {
			1: {
				label: dictionary.available,
				class: 'experience-item-label-active'
			},
			2: {
				label: dictionary.in_progress,
				class: 'experience-item-label-unavailable'
			},
			3: {
				label: dictionary.unavailable,
				class: 'experience-item-label-unavailable'
			},
			4: {
				label: dictionary.paused,
				class: 'experience-item-label-error'
			},
			5: {
				label: dictionary.add_availability,
				class: 'experience-item-label-add-availability'
			}
		};
		return (
			<div
				onClick={() => {
					if (experience.status.id === 5) {
						history.push('/calendar');
					}
				}}
				className={
					'experience-item-label ' + statuses[experience.status.id]?.class || ''
				}
				data-testid="experience-item-status-label"
			>
				{statuses[experience.status.id]?.label || ''}
			</div>
		);
	};

	const getPauseButton = () => {
		if (experience.status.id === 1) {
			return (
				<div
					onClick={() => changeExperienceStatusAsync(experience.id, 4)}
					className="experience-item-button-container"
					data-testid="experience-item-pause-button"
				>
					<img src={pause} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.pause}</h4>
				</div>
			);
		} else if (experience.status.id === 2) {
			return '';
		} else if (experience.status.id === 4) {
			return (
				<div
					onClick={() => changeExperienceStatusAsync(experience.id, 1)}
					className="experience-item-button-container"
					data-testid="experience-item-play-button"
				>
					<img src={play} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.go_live}</h4>
				</div>
			);
		}
		return null;
	};

	const getGroupButton = () => {
		return (
			<div
				onClick={() => setSelectedAddGroup(experience)}
				className="experience-item-button-container"
				data-testid="experience-item-delete-button"
			>
				<h4 style={{ fontSize: '12px' }}>+ {dictionary.add_to_group}</h4>
			</div>
		);
	};

	const getViewButton = () => {
		const isVerified = accountConfig?.verification_status?.key === 'verified';
		const buttonText = isVerified ? dictionary.view : dictionary.preview;
		const hasAssets = experience?.assets?.length > 0;

		const handleViewClick = () => {
			if (!hasAssets) {
				setWarningMessage(dictionary.no_images_yet);
			}
		};

		if (hasAssets) {
			return (
				<a
					onClick={() => viewExperienceHandler(experience.view_url)}
					className="experience-item-button-container"
					rel="noopener noreferrer"
					data-testid="experience-item-view-button"
				>
					<img src={eye} />
					<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
				</a>
			);
		} else {
			return (
				<div
					onClick={handleViewClick}
					className="experience-item-button-container"
					data-testid="experience-item-view-button"
				>
					<img src={eye} />
					<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
				</div>
			);
		}
	};

	const getEditButton = () => {
		if (experience.status.id !== 1) {
			return (
				<div
					onClick={() => onEditClicked(experience.id)}
					className="experience-item-button-container"
					data-testid="experience-item-edit-button"
				>
					<img src={pencil} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.edit}</h4>
				</div>
			);
		} else {
			return null;
		}
	};

	const getDeleteButton = () => {
		if (experience.status.id !== 1) {
			return (
				<div
					onClick={() => setSelectedDeleteExperience(experience)}
					className="experience-item-button-container"
					data-testid="experience-item-delete-button"
				>
					<img src={trash} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.delete}</h4>
				</div>
			);
		} else {
			return null;
		}
	};

	const getShareButton = () => {
		const isVerified =
			accountConfig?.verification_status?.key === 'verified' &&
			experience?.status?.id !== 4;
		const hasAssets = experience?.assets?.length > 0;

		const handleShareClick = () => {
			if (hasAssets) {
				setSelectedShareExperience(experience);
			} else {
				setWarningMessage(dictionary.no_images_yet);
			}
		};

		return (
			<div
				onClick={(isVerified && experience.share_url) ? handleShareClick : null}
				className={`experience-item-button-container${
					(isVerified && experience.share_url) ? '' : ' disabled'
				}`}
				data-testid="experience-item-share-button"
			>
				<img src={share} />
				<h4 style={{ fontSize: '12px' }}>{dictionary.share}</h4>
			</div>
		);
	};

	return (
		<div key={experience?.id} className="experience-item-full-container">
			<div className="experience-item-basic-info-container experience-item-basic-info-container1">
				<div className="experience-item-row" style={{ maxWidth: 'unset' }}>
					{getStatusLabel()}
					<div className="experience-flags-container">
						{experience.languages.slice(0, 4)
							?.map(({ language_id }, index) => {
								const { code } = allLanguages?.find((languageItem) => languageItem.id === language_id) || {};
								return (
									<div key={index} className="experience-flag-item">
										<Flag
											code={getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											fallback={
												<span>
												{getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											</span>
											}
										/>
									</div>

								)
							})}
							{experience.languages.length > 4 &&
								<div className="experience-flag-item more-label">
									+ {experience.languages.length - 4}
								</div>
							}
					</div>
				</div>
				{userType === 1 ? (
					<div style={{ marginTop: '6px' }}>
						<h3>{experience?.provider?.email}</h3>
						<h3>
							{experience?.provider?.first_name +
								' ' +
								experience?.provider?.last_name}
						</h3>
						<div
							style={{ marginTop: '4px', marginBottom: '4px' }}
							className="dialog-title-divider"
						/>
					</div>
				) : null}
				<div>
					<div className="flex-filler-exp">
						<h3>{experience.title}</h3>
						<h4 style={{marginTop: 3}}>{experience?.provider?.brand_name}</h4>
					</div>
					<div
						className="experience-item-row"
						style={{maxWidth: 'unset', marginTop: 8}}
					>
						<div style={{opacity: '0.6', fontSize: 13, lineHeight: '16px'}} className="text ellipsize">
							{experience.description}
						</div>
						{experience.is_eco_friendly && (
							<div className="d-flex eco_icon">
								<LeafIcon/>
								<span
									style={{
										marginLeft: 4,
										font: 'normal normal 500 14px/16px Roboto'
									}}
								>
								{dictionary.eco}
							</span>
							</div>
						)}
					</div>
					<div className="experience-item-buttons-container-full">
						{getEditButton()}
						{getViewButton()}
						{getGroupButton()}
						{getPauseButton()}
						{/* {experience.status.id !== 1 ? (
						<div
							onClick={() => setSelectedDeleteExperience(experience)}
							className="experience-item-button-container"
						>
							<img src={trash} />
							<h4 style={{ fontSize: '12px' }}>{dictionary.delete}</h4>
						</div>
					) : null} */}
						{getDeleteButton()}
						{/* <div
						onClick={() => setSelectedShareExperience(experience)}
						className="experience-item-button-container"
					>
						<img src={share} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.share}</h4>
					</div> */}
						{getShareButton()}
					</div>
				</div>
			</div>
			<div className="experience-thumbs-container">
				<div
					className="experience-thumb-color-line"
					style={{background: experience.color}}
				/>
				<div className="image-wrapper">
					<img
						className="experience-thumb"
						alt="experience-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							experience?.assets[0]
								? ImageHelper.getFullImageUrl(
									experience?.assets[0]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<img
						className="experience-thumb"
						alt="experience-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							experience?.assets[1]
								? ImageHelper.getFullImageUrl(
									experience?.assets[1]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<img
						className="experience-thumb"
						alt="experience-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							experience?.assets[2]
								? ImageHelper.getFullImageUrl(
									experience?.assets[2]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<img
						className="experience-thumb"
						alt="experience-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							experience?.assets[3]
								? ImageHelper.getFullImageUrl(
									experience?.assets[3]?.thumbnail_url
								)
								: placeholder
						}
					/>
				</div>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="experience-small-column-heading">
					{dictionary.pricing}
					<div className="text-with-info calendar-verification-info">
						<Tooltip content={dictionary.pricing_are_per_participants} />
					</div>
				</div>
				<div className="experience-info-rows-container thin-scrollbar">
					{experience.participant_groups.map((group) => (
						<div
							key={group.id}
							className="experience-item-row"
							style={{ marginBottom: '6px', maxWidth: '200px' }}
						>
							<div
								className="experience-info-title"
								style={{ opacity: '0.6', maxWidth: 80, width: '100%' }}
							>
								{group.name}
							</div>
							<div className="experience-info-title experience-info-text">
								{group.rate} {CURRENCY}
							</div>
						</div>
					))}
				</div>
				{experience.group_rates.length > 0 && (
					<>
						<div
							className="experience-small-column-heading"
							style={{ marginTop: 10 }}
						>
							{dictionary.group_rates}
							<div className="text-with-info calendar-verification-info">
								<Tooltip
									content={dictionary.group_rates_are_per_participants}
								/>
							</div>
						</div>
						<div className="experience-info-rows-container thin-scrollbar">
							{experience.group_rates.map((rate) => (
								<div
									key={rate.id}
									className="experience-item-row"
									style={{
										marginBottom: '6px',
										maxWidth: '200px',
										alignItems: 'flex-start'
									}}
								>
									<div
										className="experience-info-title"
										style={{ opacity: '0.6', maxWidth: 130, width: '100%' }}
									>
										{rate.min_size} - {rate.max_size}
									</div>
									<div className="experience-info-title experience-info-text">
										<div className="experience-info-title">
											-{rate.discount} %
										</div>
									</div>
								</div>
							))}
						</div>
					</>
				)}
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="experience-small-column-heading">
					{dictionary.details}
					<div className="text-with-info calendar-verification-info">
						<Tooltip content={dictionary.operation_information} />
					</div>
				</div>
				<div className="experience-info-rows-container thin-scrollbar">
					<div
						className="experience-item-row"
						style={{ marginBottom: '6px', maxWidth: '200px' }}
					>
						<div className="experience-info-title" style={{ opacity: '0.6' }}>
							{dictionary.duration}
						</div>
						<div className="experience-info-title experience-info-text">
							{ConverterHelper.secondsToDayHourMinute(
								experience.duration,
								dictionary
							)}
						</div>
					</div>
					<div
						className="experience-item-row"
						style={{ marginBottom: '6px', maxWidth: '200px' }}
					>
						<div className="experience-info-title" style={{ opacity: '0.6' }}>
							{dictionary.capacity}
						</div>
						<div className="experience-info-title experience-info-text">
							{experience.maximum_group_size + ' ' + dictionary.participants}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ExperienceListItemFull.propTypes = {
	experience: PropTypes.object.isRequired,
	setSelectedShareExperience: PropTypes.func.isRequired,
	setSelectedDeleteExperience: PropTypes.func.isRequired,
	setSelectedAddGroup: PropTypes.func.isRequired,
	onStatusChangeCompleted: PropTypes.func.isRequired,
	onEditClicked: PropTypes.func.isRequired,
	setShowLoader: PropTypes.func.isRequired,
	setWarningMessage: PropTypes.func.isRequired,
	accountConfig: PropTypes.object,
	allLanguages: PropTypes.array,
	viewExperienceHandler: PropTypes.func
};

export default ExperienceListItemFull;
