import { dictionaryMap } from '../../assets/dictionaries/dictionary';
import { shallowEqual, useSelector } from 'react-redux';

export const useExperienceSlotAccess = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	let dictionary = user ? dictionaryMap[user.language_id] : dictionaryMap[143];

	return {
		isPublic: {
			id: 0,
			text: dictionary.public,
			class: 'is-public'
		},
		isPrivate: {
			id: 1,
			text: dictionary.private,
			class: 'is-private'
		}
	};
};
