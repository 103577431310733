import React from "react";
import '../../../../../src/assets/css/IconButton.css';
import PropTypes from "prop-types";

const IconButton = ({ children, onClickIcon,activeState, disabled=false, styleObject={} }) => {
    const handleClick = (e) => {
        if(disabled) {
            e.stopPropagation();
            return;
        };
        onClickIcon();
        e.stopPropagation();
    };

    return (
        <div
            onClick={handleClick}
            className={`IconButton_button ${activeState ? "IconButton_button_active" : ""} ${disabled ? "IconButton_button_disabled" : ""}`}
            style={{...styleObject}}
        >
            {children}
        </div>
    );
};

IconButton.propTypes = {
	onClickIcon: PropTypes.func,
    activeState: PropTypes.bool,
    styleObject: PropTypes.object
};
export default IconButton;