import React from 'react';
import PropTypes from 'prop-types';

const Switch = ({ label, value, style, onChange }) => {
	return (
		<div className="switch-container" style={style}>
			<div className="text">{label}</div>
			<label className="switch">
				<input
					id="allow-requests"
					checked={value}
					type="checkbox"
					onChange={onChange}
				/>
				<span className="slider round" />
			</label>
		</div>
	);
};

Switch.propTypes = {
	label: PropTypes.string,
	value: PropTypes.bool,
	style: PropTypes.object,
	onChange: PropTypes.func
};

export default Switch;
