import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { verifyEmail } from '../__redux/authCrud';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import * as common from '../../common/__redux/commonRedux';
import SimpleLoader from '../../common/SimpleLoader';
import PropTypes from 'prop-types';

const Confirm = ({ setErrorMessage, setSuccessMessage }) => {
	const { hash } = useParams();
	// const [activated, setActivated] = useState(false);
	const history = useHistory();
	const dictionary = useDictionary();
	const [showLoader, setShowLoader] = useState(true);

	useEffect(() => {
		if (hash) {
			verifyEmail(hash)
				.then(() => {
					setShowLoader(false);
					setSuccessMessage(dictionary.activation_success);
					setTimeout(() => {
						setSuccessMessage('');
						history.push('/login');
					}, 3000);
				})
				.catch((error) => {
					if (
						error &&
						error.response &&
						error.response.data &&
						error.response.data.error_code
					) {
						setShowLoader(false);
						if (error.response.data.error_code === 1300) {
							setErrorMessage('1300_2');
							history.push('/login');
						}
					}
					// const error_response = error.response.data;
				});
		}
	}, []);

	return (
		<div className="main-container">
			<SimpleLoader showLoader={showLoader} />
		</div>
	);
};

Confirm.propTypes = {
	setErrorMessage: PropTypes.func,
	setSuccessMessage: PropTypes.func
};

export default connect(common.states, common.actions)(withRouter(Confirm));
