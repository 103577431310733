import { dictionaryMap } from '../../assets/dictionaries/dictionary';
import { shallowEqual, useSelector } from 'react-redux';

export const useExperienceSlotStatuses = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	let dictionary = user ? dictionaryMap[user.language_id] : dictionaryMap[143];

	return {
		open: {
			id: 0,
			text: dictionary.open,
			class: 'open'
		},
		booked: {
			id: 1,
			text: dictionary.booked,
			class: 'booked'
		},
		booked_open: {
			id: 2,
			text: dictionary.booked_open,
			class: 'booked-open'
		},
		pending_confirm: {
			id: 3,
			text: dictionary.pending_confirm,
			class: 'pending-confirm'
		},
		pending_payment: {
			id: 4,
			text: dictionary.pending_payment,
			class: 'pending-payment'
		},
		confirm_reschedule: {
			id: 5,
			text: dictionary.confirm_reschedule,
			class: 'confirm-reschedule'
		},
		pending_reschedule: {
			id: 6,
			text: dictionary.pending_reschedule,
			class: 'pending-reschedule'
		},
		paused: {
			id: 7,
			text: dictionary.paused,
			class: 'paused'
		},
		closed: {
			id: 8,
			text: dictionary.closed,
			class: 'closed'
		}
	};
};
