import { API_URL, PROVIDERS_ME_URL } from '../../../../config';
import axios from 'axios';

export function uploadSinglePhoto(url, type, file) {
	const formData = new FormData();

	console.log(file);
	formData.append('file', file, file.name);
	formData.append('type', type);
	return axios.post(API_URL + url, formData);
}

export function deleteSinglePhoto(url, id) {
	return axios.delete(API_URL + url + '/' + id);
}

export function deleteLegalFile(id) {
	return axios.delete(PROVIDERS_ME_URL + '/legal-files/' + id);
}

export function updateProviderInfo(data) {
	return axios.put(PROVIDERS_ME_URL, data);
}

export function getProviderInfo() {
	return axios.get(PROVIDERS_ME_URL);
}
