import plus from '../../../../../assets/icons/ic_plus_dark.svg';
import eye from "../../../../../assets/icons/ic_eye.svg";
//import plus from "../../../../../assets/icons/ic_plus.svg"
import share from "../../../../../assets/icons/ic_share.svg";
import trash from "../../../../../assets/icons/ic_trash.svg"



import PropTypes from 'prop-types';
import { useDictionary } from "../../../../../utilities/hooks/UseDictionary";


const ResaleExperienceButtons = ({
    experience,
    setSelectedAddGroup,
    inviteProviderHandler,
    viewExperienceHandler,
    shareExperienceHandler,
    removeExperienceHandler
}) => {
    //console.log(" resale expereience",experience)

    const dictionary = useDictionary();
    const viewType = parseInt(localStorage.getItem('resale_collection_selected_view_type') ?? 0);

    let extraStyle = {};
    if (viewType === 0) {
        extraStyle = {
            marginLeft: '-6px'
        };
    }

    /**
     * Just show two cases to handle call back function
     * see also removeExperienceHandler
     */
    const handleShareClick = () => {
        shareExperienceHandler(experience)
    }

    return (
        <div className="resale-collection-item-experience-block-button-group">
            <div className="resale-collection-item-experience-block-button-group-row" style={extraStyle}>
                {(inviteProviderHandler) ?
                    <a className="resale-collection-item-experience-block-button-group-row-item"
                        onClick={() => inviteProviderHandler(experience.experience_id)}>
                        <img src={plus} alt={dictionary.add_to_sale_experiences} />
                        {(viewType === 0) ? 
                            <div className="resale-collection-item-experience-block-button-group-row-item-text" data-testid="add-to-resale-button">
                                {dictionary.add_to_sale_experiences}
                            </div>
                        : null}
                    </a>
                    : null}

                {(viewExperienceHandler) ?
                    <a className="resale-collection-item-experience-block-button-group-row-item"
                        onClick={() => viewExperienceHandler(experience.view_url)}>
                        <img src={eye} alt={dictionary.view} />
                        {(viewType === 0) ? 
                            <div className="resale-collection-item-experience-block-button-group-row-item-text" data-testid="resale-view-button">
                                {dictionary.view}
                            </div>
                        : null}
                    </a>
                    : null}

                {(!inviteProviderHandler)?
                    <a className="resale-collection-item-experience-block-button-group-row-item"
                        onClick={() => setSelectedAddGroup(experience)}>
                        <img src={plus} alt={dictionary.add_to_group} />
                        {(viewType === 0) ? 
                            <div className="resale-collection-item-experience-block-button-group-row-item-text" >
                                {dictionary.add_to_group}
                            </div>
                        : null}
                    </a>
                    : null}

                {(shareExperienceHandler) ?
                    <a className="resale-collection-item-experience-block-button-group-row-item"
                        onClick={handleShareClick}>
                        <img src={share} alt={dictionary.share} />
                        {(viewType === 0) ? 
                            <div className="resale-collection-item-experience-block-button-group-row-item-text" data-testid="resale-share-button">
                                {dictionary.share}
                            </div>
                        : null}
                    </a>
                    : null}
                {(removeExperienceHandler) ?
                    <a className="resale-collection-item-experience-block-button-group-row-item"
                        onClick={() => removeExperienceHandler(experience)}>
                        <img src={trash} alt={dictionary.remove} />
                        {(viewType === 0) ? 
                        <div className="resale-collection-item-experience-block-button-group-row-item-text" data-testid="resale-remove-button">
                            {dictionary.remove}
                        </div>
                        : null}
                    </a>
                    : null}

            </div>
        </div>
    );
}

ResaleExperienceButtons.prototype = {
    experience: PropTypes.object.isRequired,
    inviteProviderHandler: PropTypes.func,
    viewExperienceHandler: PropTypes.func,
    shareExperienceHandler: PropTypes.func,
    removeExperienceHandler: PropTypes.func,
}

export default ResaleExperienceButtons;