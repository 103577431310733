import { shallowEqual, useSelector } from 'react-redux';

export const UseCurrencyDecorator = () => {
	const currencyMap = {
		EUR: '€',
		USD: '$'
	};
	// eslint-disable-next-line no-unused-vars
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	return (amount, currency) => {
		return (
			currency !== 'USD' ? parseFloat(amount).toLocaleString('el', {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2
			}) + ' ' +
			(currency === undefined || currency === null
				? '€'
				: currencyMap[currency]) : `${currencyMap[currency]}${amount}`
		);
	};
};
