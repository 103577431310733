import '../../../../../../assets/css/switch.css';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const BookingSettings = ({
	setEnabled,
	allowRequests,
	setAllowRequests,
	cutoffTime,
	setCutoffTime,
	isPrivateTour,
	setIsPrivateTour,
	autoConfirm,
	setAutoConfirm
}) => {
	const dictionary = useDictionary();

	const [hoursBefore, setHoursBefore] = useState(
		(cutoffTime % (3600 * 24)) / 3600
	);
	const [daysBefore, setDaysBefore] = useState(
		Math.floor(cutoffTime / 3600 / 24)
	);

	// const hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	const onAllowToRequestChanged = (e) => {
		setAllowRequests(e.target.checked);
	};

	const onPrivateTourChanged = (e) => {
		setIsPrivateTour(e.target.checked);
	};

	const onInstantChanged = (e) => {
		setAutoConfirm(e.target.checked);
	};

	const onDaysChanged = (e) => {
		setDaysBefore(e.target.value);
		setCutoffTime(e.target.value * 3600 * 24 + hoursBefore * 3600);
	};

	const onHoursChanged = (e) => {
		setHoursBefore(e.target.value);
		setCutoffTime(daysBefore * 3600 * 24 + e.target.value * 3600);
	};

	useEffect(() => {
		setEnabled(true);
	}, [cutoffTime]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2 style={{ fontSize: '24px' }}>{dictionary.booking_settings}</h2>
				<div className="text" style={{ marginTop: '20px' }}>
					{dictionary.we_recommend_cutoff}
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.cutoff_time}
				</h3>
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.if_no_one_books}
				</div>
				<div className="group-inputs">
					<div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
						<input
							type="number"
							step="1"
							min={0}
							value={daysBefore}
							onChange={onDaysChanged}
							onKeyDown={(e) =>
								['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
							}
							data-testid="days-before"
							style={{ maxWidth: 80 }}
						/>
						<h4 style={{ whiteSpace: 'nowrap' }}>
							{daysBefore <= 1 ? dictionary.day : dictionary.days}
						</h4>
						<input
							type="number"
							step="1"
							min={1}
							max={23}
							value={hoursBefore}
							onChange={onHoursChanged}
							onKeyDown={(e) =>
								['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
							}
							data-testid="hours-before"
							style={{ maxWidth: 80 }}
						/>
						<h4 style={{ whiteSpace: 'nowrap' }}>
							{(hoursBefore <= 1 ? dictionary.hour : dictionary.hours) +
								' ' +
								dictionary.before_start_time}
						</h4>
					</div>
				</div>

				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.private}
				</h3>
				<div className="switch-container" style={{ width: '100%' }}>
					<div className="text">{dictionary.private_description}</div>
					<label className="switch">
						<input
							id="private-tours"
							checked={isPrivateTour}
							type="checkbox"
							onChange={onPrivateTourChanged}
							data-testid="private-tours"
						/>
						<span className="slider round" />
					</label>
				</div>

				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.requests_for_availability}
				</h3>
				<div className="switch-container" style={{ width: '100%' }}>
					<div className="text">{dictionary.allow_people}</div>
					<label className="switch">
						<input
							id="allow-requests"
							checked={allowRequests}
							type="checkbox"
							onChange={onAllowToRequestChanged}
							data-testid="allow-requests"
						/>
						<span className="slider round" />
					</label>
				</div>

				<h3 style={{fontSize: '16px', marginTop: '30px'}}>{dictionary.instant_confirmation}</h3>
                <div className='switch-container'>
                    <div className='text'>{dictionary.all_booking_requests_confirmed}</div>
                    <label className="switch">
                        <input id='auto-confirm' checked={autoConfirm} type="checkbox" onChange={onInstantChanged}/>
                        <span className="slider round"/>
                    </label>
                </div>
			</div>
		</div>
	);
};

BookingSettings.propTypes = {
	setEnabled: PropTypes.func,
	allowRequests: PropTypes.bool,
	setAllowRequests: PropTypes.func,
	cutoffTime: PropTypes.number,
	setCutoffTime: PropTypes.func,
	isPrivateTour: PropTypes.bool,
	setIsPrivateTour: PropTypes.func,
	autoConfirm: PropTypes.bool,
	setAutoConfirm: PropTypes.func
};

export default BookingSettings;
