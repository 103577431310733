import PropTypes from 'prop-types';
import React from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import {AmountFormatHelper} from "../../../../../../utilities/helpers/AmountFormatHelper";

const ProviderDetailRight = ({ details }) => {
	const dictionary = useDictionary();
	return (
		<div
			className="booking-experience-details-container"
			data-testid="booking-experience-details-container"
		>
			<div
				className="settings-tab-bar"
				style={{
					justifyContent: 'space-between',
					backgroundColor: 'white',
					alignItems: 'center',
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12
				}}
			>
				<div
					className="settings-tab"
					style={{ color: 'var(--brand-dark-blue)', cursor: 'initial' }}
				>
					{dictionary?.details}
				</div>
				<div
					className="settings-tab"
					style={{ color: 'var(--brand-red)', cursor: 'pointer', textDecoration: 'underline' }}
					onClick={() => window.open(details?.share_url, '_blank')}
				>
					{dictionary?.provider_list}
				</div>
			</div>
			<div className="booking-experience-details-content-container thin-scrollbar">
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '36px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.name}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? `${details.first_name} ${details.last_name}` : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.email}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? details.email : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.company}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? details.brand_name : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.vat_number}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? details.tax_id_number : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.account_iban}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? details.account_iban : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.beneficiary_name}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? details.account_beneficiary : null }
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: '24px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.upiria_commission}
					</h4>
					<h4 style={{ fontWeight: 'normal', textAlign: 'right' }}>
						{ details ? AmountFormatHelper.formatAmount(details.upiria_commission) : null }%
					</h4>
				</div>
			</div>
		</div>
	);
};

ProviderDetailRight.propTypes = {
	details: PropTypes.object
};

export default ProviderDetailRight;
