import countryCodes from 'country-codes-list';

console.log(countryCodes.all().filter(({officialLanguageNameEn}) => officialLanguageNameEn.includes('Chinese')))

console.log(countryCodes.findOne(
	'officialLanguageNameEn',
	'chinese'
))

export const getMatchedCountryData = (code) => {
	if (!code) {
		return null;
	}
	const allCountryCodes = countryCodes.all();
	const terms = code.split('_');
	const officialLanguageCode = terms[0];
	const countryCode = terms[1] || terms[0];
	return allCountryCodes.find((countryCodeItem) => {
		return countryCodeItem.officialLanguageCode === officialLanguageCode && countryCodeItem.countryCode?.toLowerCase() === countryCode;
	});
};

export const getCountryData = (language, code) => {
	if (language.includes('(')) {
		return countryCodes.findOne(
			'countryNameEn',
			language.match(/\(([^)]+)\)/)[1]
		);
	}
	const countryFromLanguageName = countryCodes.findOne(
		'officialLanguageNameEn',
		language
	);
	if (countryFromLanguageName) return countryFromLanguageName;
	return countryCodes.all().find(({ officialLanguageCode }) => officialLanguageCode.includes(code));
};
