import '../../../../../../../assets/css/dialogs.css';
import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import React from 'react';

const DurationSelectionDialog = ({
	setDialogVisibility,
	parentHours,
	parentMinutes,
	setParentHours,
	setParentMinutes,
	parentDays,
	setParentDays
}) => {
	const dictionary = useDictionary();
	const [minutes, setMinutes] = useState(parentMinutes);
	const [hours, setHours] = useState(parentHours);
	const [days, setDays] = useState(parentDays);
	// eslint-disable-next-line no-unused-vars
	const [showDropdown, setShowDropdown] = useState(false);
	const wrapper = useRef();
	const dropdown = useRef();

	const checkDisabled = () => {
		return !(days > 0 || hours > 0 || minutes > 0);
	};

	function handleClickOutside(event) {
		if (dropdown.current && !dropdown.current.contains(event.target)) {
			setShowDropdown(false);
		}
	}

	// Bind the event listener

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const onSaveClicked = () => {
		setParentMinutes(minutes);
		setParentHours(hours);
		setParentDays(days);
		setDialogVisibility(false);
	};

	return (
		<div ref={wrapper} className="dialog-wrapper fade-on-load">
			<div className="dialog-container dialog-container-mid">
				<div className="dialog-title-container">
					<div className="dialog-title" style={{ fontWeight: 500 }}>
						{dictionary.select_duration}
					</div>
					<div
						className="dialog-close-button"
						onClick={() => setDialogVisibility(false)}
					/>
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />

				<div className="dialog-content-container">
					<h3 style={{ fontSize: '16px' }}>{dictionary.my_experience_lasts}</h3>

					<div
						className="group-inputs"
						style={{ display: 'flex', justifyContent: 'space-between' }}
					>
						<div className="duration-input-container">
							<input
								className="duration-input"
								type="number"
								min="0"
								step="1"
								value={days}
								onChange={(e) => setDays(e.target.value)}
								data-testid="days-input"
							/>
							<div className="duration-input-label">
								{dictionary.days_dialog}
							</div>
						</div>
						<div className="duration-input-container">
							<input
								className="duration-input duration-input-hour"
								type="number"
								min="0"
								step="1"
								value={hours}
								onChange={(e) => setHours(e.target.value)}
								data-testid="hours-input"
							/>
							<div className="duration-input-label">
								{dictionary.hours_dialog}
							</div>
						</div>
						<div className="duration-input-container">
							<input
								className="duration-input"
								type="number"
								min="0"
								step="1"
								value={minutes}
								onChange={(e) => setMinutes(e.target.value)}
								data-testid="minutes-input"
							/>
							<div className="duration-input-label">
								{dictionary.minutes_dialog}
							</div>
						</div>
					</div>
				</div>
				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{ padding: '12px', marginBottom: '0' }}
				>
					<div
						className="tertiary-button"
						onClick={() => setDialogVisibility(false)}
						data-testid="cancel-button"
					>
						{dictionary.cancel}
					</div>
					<button
						disabled={checkDisabled()}
						onClick={onSaveClicked}
						className="button"
						data-testid="save-button"
					>
						{dictionary.save}
					</button>
				</div>
			</div>
		</div>
	);
};

DurationSelectionDialog.propTypes = {
	setDialogVisibility: PropTypes.func,
	parentHours: PropTypes.number,
	parentMinutes: PropTypes.number,
	setParentHours: PropTypes.func,
	setParentMinutes: PropTypes.func,
	parentDays: PropTypes.number,
	setParentDays: PropTypes.func
};

export default DurationSelectionDialog;
