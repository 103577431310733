import { NavLink } from 'react-router-dom';
import SimpleLoader from '../../common/SimpleLoader';
import React, { useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { addonsDummy } from './dummy';
import AddonListItemFull from './AddonListItemFull';

const ExperiencesAddons = () => {
	const dictionary = useDictionary();
	const userType = useUserTypeId();
	const [addOns, setAddOns] = useState(addonsDummy);
	// eslint-disable-next-line no-unused-vars
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [selectedResource, setSelectedResource] = useState(null);
	const [showLoaded, setShowLoader] = useState(false);

	const setSelectedDeleteAddon = (resource) => {
		setSelectedResource(resource);
		setDeleteDialogVisibility(true);
	};

	return (
		<div className="experiences-wrapper">
			<div className="experiences-top-bar">
				<h2>{dictionary.resources}</h2>
				{userType === 2 ? (
					<NavLink
						to={'experience-addons/add'}
						className="button"
						style={{ marginLeft: '25px' }}
					>
						<div className="plus-icon" />
						{dictionary.create_addon}
					</NavLink>
				) : null}
			</div>
			{addOns.length === 0 ? (
				<div className="empty-experiences-list-wrapper">
					<div className="empty-experiences-list-placeholder">
						<div className="empty-experiences-image" />
						<h3 style={{ textAlign: 'center', marginTop: '24px' }}>
							{dictionary.no_resources_yet}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.all_resources_will_be_listed}
						</div>
					</div>
				</div>
			) : null}
			{addOns.length > 0 ? (
				<div className="experience-items-wrapper">
					{addOns.map((addon) => (
						<AddonListItemFull
							key={addon.id}
							setSelectedDeleteAddon={setSelectedDeleteAddon}
							addon={addon}
						/>
					))}
				</div>
			) : null}
			<SimpleLoader showLoader={showLoaded} />
		</div>
	);
};



export default ExperiencesAddons;
