import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const WorkingHoursDialog = ({ setShow }) => {
	const dictionary = useDictionary();

	const [opacity, setOpacity] = useState(0);

	const onCancelClick = () => {
		setOpacity(0);
		setTimeout(() => {
			setShow(false);
		}, 800);
	};

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	return (
		<div style={{ opacity }} className="dialog-wrapper">
			<div
				className="dialog-container dialog-container-max-height"
				style={{ maxWidth: 360 }}
			>
				<div
					className="dialog-title-container"
					data-testid="dialog-title-container"
				>
					<div
						className="dialog-title"
						data-testid="dialog-title"
						style={{ fontWeight: 500 }}
					>
						{dictionary.company_working_hours}
					</div>
					<div className="dialog-close-button" onClick={onCancelClick} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div
					className="dialog-content-container-with-scroll thin-scrollbar-trans"
					style={{ height: 'unset' }}
				>
					<div className="text">
						{dictionary.you_can_change_your_working_hours_in_settings}
					</div>
                    <div className="working-hour-item-container">
                        {dictionary.days_array.slice(1, 6).map((day, index) => (
                            <React.Fragment key={index}>
                                <div className="text">{day}</div>
                                <div className="working-hour-range-container">
                                    <div
                                        className="group-inputs group-inputs--small"
                                        style={{ marginBottom: 'unset', marginTop: 'unset' }}
                                    >
                                        <input
                                            disabled
                                            style={{
                                                width: 68
                                            }}
                                            value="9:00"
                                            type="text"
                                        />
                                    </div>
                                    <div className="text">{dictionary.to}</div>
                                    <div
                                        className="group-inputs group-inputs--small"
                                        style={{ marginBottom: 'unset', marginTop: 'unset' }}
                                    >
                                        <input
                                            disabled
                                            style={{
                                                width: 68
                                            }}
                                            value="18:00"
                                            type="text"
                                        />
                                    </div>
                                </div>
                            </React.Fragment>
                        ))}
                    </div>
				</div>
			</div>
		</div>
	);
};

WorkingHoursDialog.propTypes = {
	setShow: PropTypes.func
};

export default WorkingHoursDialog;
