import { useState, useEffect } from 'react';

const useClientWidth = () => {
    const [clientWidth, setClientWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setClientWidth(window.innerWidth);
        };
        
        handleResize();

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return clientWidth;
};

export default useClientWidth;