/* eslint-disable no-undef */
import { HOST, SCHEME } from './host_config';

// export const ENVIRONMENT = 'development' || 'production' || 'staging' //example
export const ENVIRONMENT = process.env.REACT_APP_ENV; //'production'; // development|production

// example
// export const API_KEY = 'M2M3WFVLazZVOXN0ZGJaOWtKODk6YmQ2aDl0YTJvNmU3aTZhbjdrWlI'; //dev-api.upiria.com database inside rds
// export const API_KEY = 'bmRZSGdaWEw4UitoKmdKKzp6dUYrRkJTNyNZSzl0Q1gp'; //staging-api.upiria.com database rds
// export const API_KEY = 'M2M3WFVLazZVOXN0ZGJaOWtKODk6YmQ2aDl0YTJvNmU3aTZhbjdrWlI='; //local database inside
export const API_KEY = process.env.REACT_APP_API_KEY;
export const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
export const CDN_URL = process.env.REACT_APP_CDN_URL;

export const MAX_FILE_UPLOAD_IMAGES =
	process.env.REACT_APP_IMAGE_UPLOAD_SIZE_LIMIT;
export const MAX_FILE_UPLOAD_IMAGES_LABEL =
	MAX_FILE_UPLOAD_IMAGES / 1000000 + 'MB';
export const MAX_FILE_UPLOAD_VIDEOS =
	process.env.REACT_APP_VIDEO_UPLOAD_SIZE_LIMIT;
export const MAX_FILE_UPLOAD_VIDEOS_LABEL =
	MAX_FILE_UPLOAD_VIDEOS / 1000000 + 'MB';

export const MAX_LEGAL_FILE_SIZE = 5 * 1000000;
export const LEGAL_FILES_TYPES = ['image/jpeg', 'application/pdf'];

export const API_VERSION = '/v1/';
export const API_VERSION_NO_SLASH = '/v1';
export const URL = SCHEME + HOST;
export const API_URL = URL + API_VERSION;

export const LOGIN_URL = API_URL + 'users/login';
export const LOGOUT_URL = API_URL + 'users/logout';
export const SECURE_URL = API_URL + 'users/secure';
export const RESEND_URL = API_URL + 'users/confirmation/resend';
export const SET_PASSWORD_URL = API_URL + 'users/set-password';
export const ADMIN_LOGIN_URL = API_URL + 'admin/login';
export const REGISTER_URL = API_URL + 'users';
export const REQUEST_PASSWORD_URL = API_URL + 'users/reset-password';
export const ME_URL = API_URL + 'auth/me';
export const CUSTOMERS_URL = API_URL + 'customers';
export const CONFIRM_EMAIL_URL = API_URL + 'users/confirm';
export const PROVIDERS_EXPERIENCES_URL = API_URL + 'provider-experiences';
export const PROVIDERS_ADMIN_EXPERIENCES_URL = API_URL + 'experiences';
export const PROVIDERS_ADMIN_MARKETPLACE_EXPERIENCES_URL = API_URL + 'marketplace-experiences';
export const EXPERIENCE_RESOURCES_URL = API_URL + 'provider-resources';
export const AVAILABLE_EXPERIENCES_URL =
	PROVIDERS_EXPERIENCES_URL + '/available';
export const PROVIDERS_URL = API_URL + 'providers';

export const ADMIN_RESELLERS_URL = API_URL + 'admin/resellers';
export const ADMIN_BOKKINGS_URL = API_URL + 'admin/bookings';
export const ADMIN_COLLECTION_GROUP = API_URL + 'admin/provider-groups';
export const PROVIDERS_ME_URL = API_URL + 'providers/me';
export const PROVIDERS_LEGAL_INFO_URL = API_URL + 'providers/me/legal-info';
export const BOOKINGS_URL = API_URL + 'bookings';
export const PROVIDER_AUTHENTICATE = PROVIDERS_ME_URL + '/authenticate';
export const PROVIDER_STRIPE_LOGIN = PROVIDERS_ME_URL + '/stripe-login';
export const PROVIDER_BALANCE = PROVIDERS_ME_URL + '/balance';
export const PROVIDER_BOOKINGS = PROVIDERS_ME_URL + '/bookings';

export const PROVIDER_BOOKING_CANCEL = PROVIDERS_ME_URL + '/bookings/cancel';

export const PROVIDER_GROUPS = API_URL + 'provider-groups';

export const PROVIDER_PAYOUTS = PROVIDERS_ME_URL + '/payouts';
export const PROVIDER_WITHDRAW = PROVIDERS_ME_URL + '/withdraw';
export const RESALE_MARKETPLACE_URL = API_URL + 'resale/marketplace';
export const RESALE_EXPERIENCES_URL = API_URL + 'resale/experiences';
export const RESALE_BOOKINGS_URL = API_URL + 'resale/bookings';

export const RESALE_INVITE_URL = API_URL + 'resale/invite';
export const PROVIDERS_EXPERIENCES_SLOTS_URL =
	API_URL + 'provider-experiences-slots';
export const PROVIDER_EXPERIENCE_CATEGORIES_URL =
	API_URL + 'experience-categories/init';

export const LANGUAGES_URL = API_URL + 'provider-experiences/languages';

export const UPLOAD_URL = API_URL + 'files/upload';

export const ADMIN_EMAIL = 'admin@upiria.com';

export const CURRENCY = '€';
export const CURRENCY_FULL = {
	symbol: '€',
	code: 'EUR'
};
export const PAGE_SIZE = 50;
export const MAX_VISITS = 20;

//local storage constants

export const GRANT_TYPE = 'password';

export const CAN_EDIT_TYPES = [1];

export const UPIRIA_ASCII_ART =
	"\n _     _       _       _      \n| |   | |     (_)     (_)      \n| |   | |____  _  ____ _  ____\n| |   | |  _ \\| |/ ___) |/ _  |\n| \\__/  | | | | | |   | ( ( | |\n \\___/\\_| ||_/|_|_|   |_|\\_||_|\n        |_|\n\n_,.-'~'-.,__,.-'~'-.,__,.-'~'-.,\n\nUpiria 2021 Ⓒ All rights reserved\n________________________________";
