import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import NewParticipantGroupDialog from '../provided_items/NewParticipantGroupDialog';
import ParticipantGroup from './ParticipantGroup';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const CapacityAndPricing = ({
	setEnabled,
	minimumAge,
	minParticipants,
	setMinParticipants,
	maxParticipants,
	setMaxParticipants,
	participantGroups,
	setParticipantGroups,
	showCapacityValidationError,
	setShowCapacityValidationError
}) => {
	const dictionary = useDictionary();

	const [showDialog, setShowDialog] = useState(false);
	const [selectedGroup, setSelectedGroup] = useState();

	const onMinParticipantsChanged = (e) => {
		const minValue = Number(e.target.value);

		if (minValue > 0) {
			setMinParticipants(minValue);
		}

		if (showCapacityValidationError && minValue < maxParticipants) {
			setShowCapacityValidationError(false);
		}
	};

	const onMaxParticipantsChanged = (e) => {
		const maxValue = Number(e.target.value);

		if (maxValue > 0) {
			setMaxParticipants(maxValue);
		}

		if (showCapacityValidationError && minParticipants < maxValue) {
			setShowCapacityValidationError(false);
		}
	};

	const onAddItemClicked = () => {
		setShowDialog(true);
	};

	const onRemoveItemClicked = (item) => {
		let newItems = Object.assign([], participantGroups);
		ArrayHelper.removeItemOnce(newItems, item);
		setParticipantGroups(newItems);
	};

	const onEditItemClicked = (index, item) => {
		setSelectedGroup({ index, ...item });
		setShowDialog(true);
	};

	const onItemChanged = (e, index) => {
		const value = e.target.value;
		let newItems = Object.assign([], participantGroups);
		newItems[index].rate = value;
		setParticipantGroups(newItems);
	};

	const onItemBlur = (index) => {
		let newItems = Object.assign([], participantGroups);
		const value = newItems[index].rate;
		newItems[index].rate = value >= 5 ? value : 5;
		setParticipantGroups(newItems);
	};

	const onCreateClicked = (group) => {
		if (selectedGroup) {
			setParticipantGroups(
				participantGroups.map((item, index) =>
					index === group.index ? group : item
				)
			);
		} else {
			let newItems = Object.assign([], participantGroups);
			newItems.push(group);
			setParticipantGroups(newItems);
		}
	};

	const checkPrices = () => {
		for (let i = 0; i < participantGroups.length; i++) {
			if (!participantGroups[i].rate || participantGroups[i].rate === 0) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (!showDialog) {
			setSelectedGroup(null);
		}
	}, [showDialog]);

	useEffect(() => {
		if (participantGroups) {
			setEnabled(
				minParticipants > 0 &&
					maxParticipants > 0 &&
					participantGroups.length > 0 &&
					checkPrices()
			);
		}
	}, [minParticipants, maxParticipants, participantGroups]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2>{dictionary.how_many_can_you_host}</h2>
				<div className="text" style={{ opacity: '0.8' }}>
					{dictionary.think_about_group_size}
				</div>
				<div style={{ display: 'flex', marginTop: 30 }}>
					<div
						style={{ marginRight: 30 }}
						className={`group-inputs ${
							showCapacityValidationError && 'group-inputs--error'
						}`}
					>
						<label style={{ fontSize: '16px' }}>
							{dictionary.minimum_group_size}
						</label>
						<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
							<input
								style={{ width: '100px' }}
								type="number"
								min="1"
								step="1"
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								value={minParticipants}
								onChange={onMinParticipantsChanged}
								data-testid="min-participants-input"
							/>
							<h4>
								{minParticipants <= 1
									? dictionary.participant
									: dictionary.participants}
							</h4>
						</div>
					</div>
					<div className="group-inputs">
						<label style={{ fontSize: '16px' }}>
							{dictionary.maximum_group_size}
						</label>
						<div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
							<input
								style={{ width: '100px' }}
								type="number"
								min={participantGroups.length}
								step="1"
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								value={maxParticipants}
								onChange={onMaxParticipantsChanged}
								data-testid="max-participants-input"
							/>
							<h4>
								{maxParticipants <= 1
									? dictionary.participant
									: dictionary.participants}
							</h4>
						</div>
					</div>
				</div>
				{showCapacityValidationError && (
					<div
						className="terms-text"
						style={{ color: '#FD4F4F', marginTop: -9 }}
					>
						{dictionary.min_group_size_is_grater_than_max}
					</div>
				)}
				<h2 style={{ marginTop: '30px' }}>{dictionary.participant_pricing}</h2>
				<div style={{ marginTop: '15px', opacity: '0.8' }} className="text">
					{dictionary.how_much_you_charge}
				</div>
				<div style={{ marginTop: '25px', opacity: '0.8' }} className="text">
					{dictionary.keep_in_mind_minimum_age} {minimumAge}{' '}
					{dictionary.minimum_price_is}.
				</div>
				<div className="provided-items-container">
					{participantGroups?.map((group, index) => (
						<ParticipantGroup
							group={group}
							key={index}
							onEditItemClicked={(group) => onEditItemClicked(index, group)}
							onRemoveItemClicked={onRemoveItemClicked}
							index={index}
							setValue={onItemChanged}
							onBlur={onItemBlur}
						/>
					))}
				</div>
				<button
					onClick={onAddItemClicked}
					style={{ marginTop: '25px' }}
					className="secondary-button plus-button"
					data-testid="create-new-group-button"
				>
					{dictionary.create_new_participant_group}
				</button>
			</div>
			{showDialog ? (
				<NewParticipantGroupDialog
					paticipantGroup={selectedGroup}
					setVisibility={setShowDialog}
					onCreateClicked={onCreateClicked}
				/>
			) : null}
		</div>
	);
};

CapacityAndPricing.propTypes = {
	setEnabled: PropTypes.func,
	minimumAge: PropTypes.number,
	minParticipants: PropTypes.number,
	setMinParticipants: PropTypes.func,
	maxParticipants: PropTypes.number,
	setMaxParticipants: PropTypes.func,
	participantGroups: PropTypes.array,
	setParticipantGroups: PropTypes.func,
	showCapacityValidationError: PropTypes.bool,
	setShowCapacityValidationError: PropTypes.func
};

export default CapacityAndPricing;
