import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
// import pencil from '../../../../../assets/icons/ic_pencil.svg';
import back from '../../../../../assets/icons/ic_caret_left.svg';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {getFinancialOverview, withdrawAmount, stripeLogin} from '../networking';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import SimpleLoader from '../../../common/SimpleLoader';
import PayoutsList from './PayoutsList';
import Tooltip from '../../../common/Tooltip';
import ConfirmationModal from '../../../common/ConfirmationModal';

const ProviderPayouts = () => {
	const dictionary = useDictionary();
	const [showLoader, setLoaderVisibility] = useState(true);
	const currencyDecorator = UseCurrencyDecorator();
	const [showConfirmDialog, setConfirmDialogVisibility] = useState(false);
	const [overviewResult, setOverviewResult] = useState(null);
	const [showList, setListVisibility] = useState(false);
	const history = useHistory();
	const isMounted = useRef(true);

	useEffect(() => {
		isMounted.current = true;
		getFinancialOverviewAsync();
	
		return () => {
		  isMounted.current = false;
		};
	  }, []);

	const onClickWithdrawAmountAsync = async () => {
		if (overviewResult?.available_balance) {
			setConfirmDialogVisibility(true);
		}
		// try {
		//  eslint-disable-next-line no-unused-vars
		// 	let result = await withdrawAmount();
		// 	await getFinancialOverviewAsync();
		// } catch (e) {
		// 	setLoaderVisibility(false);
		// }
	}

	const withdrawAmountAsync = async (onSuccess, onFail) => {
		try {
		  await withdrawAmount();
		  if (isMounted.current) {
			setOverviewResult(null);
			setListVisibility(false);
			onSuccess();
		  }
		} catch (error) {
		  if (isMounted.current) {
			onFail();
		 }
		}
	  }


	const getFinancialOverviewAsync = async () => {
		setLoaderVisibility(true);
		try {
		  let result = await getFinancialOverview();
		 if (isMounted.current) {
			setOverviewResult(result.data);
			setListVisibility(true);
			setLoaderVisibility(false);
		 }
		} catch (e) {
		  if (isMounted.current) {
			setLoaderVisibility(false);
			setListVisibility(true);
		  }
		}
	  };

	  const onDetailsClicked = async () => {
		setLoaderVisibility(true);
		let response = await stripeLogin();
		let stripeLoginUrl = response.data.url;
		window.open(stripeLoginUrl, '_blank');
		if (isMounted.current) {
		  setLoaderVisibility(false);
		}
	  };

	return (
		<div className="home-container" style={{ position: 'relative' }}>
			<div className="home-content-container" style={{ maxWidth: '1600px' }}>
				<div style={{ marginBottom: '16px' , display: 'flex', justifyContent: 'space-between' }}>
					<div style={{ display: 'flex', gap: '12px'}}>
						<div className="booking-detail-back-button" onClick={() => history.goBack()}>
							<img src={back} />
						</div>
						<h2>{dictionary.payouts}</h2>
					</div>
					<div
						style={{ cursor: 'pointer' }}
						className="see-details"
						data-testid="see-details"
						onClick={onDetailsClicked}
					>
						{dictionary.see_details}
					</div>
				</div>

				<div className="payout-overview-container">
					<div
						className="payout-container"
						style={{ width: '0', flexGrow: '1' }}
					>
						<div className='payout-text-area'>
							<span className='payout-text'>{dictionary.pending}</span>
							<div className='text-with-info provider-payout-info'> <Tooltip content={dictionary.upcoming_earnings} />
							</div>
						</div>

						<h3 data-testid="pending-balance">
							{overviewResult
								? currencyDecorator(overviewResult?.pending_balance)
								: ''}
						</h3>
						{/*<h4 style={{fontSize: '12px', opacity: '0.6'}}>{dictionary.estimated_upcoming} {currencyDecorator(0)}</h4>*/}
					</div>

					<div
						className="payout-container"
						style={{ flexFlow: 'row', width: '0', flexGrow: '1' }}
					>
						<div
							className="payout-container"
							style={{
								width: '0',
								flexGrow: '1',
								padding: 'unset',
								background: 'unset'
							}}
						>
							<div className='payout-text-area'>
								<span className='payout-text'>{dictionary.available}</span>
								<div className='text-with-info provider-payout-info'> <Tooltip content={dictionary.ready_to_withdraw} />
								</div>
							</div>
							<h3 data-testid="available-balance">
								{overviewResult
									? currencyDecorator(overviewResult?.available_balance)
									: ''}
							</h3>
							{/*<h4 style={{fontSize: '12px', opacity: '0.6'}}>{dictionary.estimated_upcoming} {currencyDecorator(0)}</h4>*/}
						</div>
						<div
							style={{
								display: 'flex',
								flexFlow: 'column',
								justifyContent: 'space-between'
							}}
						>
							<div style={{ display: 'flex', gap: '16px' }}>
								<h4 style={{ fontSize: '14px' }}>
									{
										overviewResult
											? 'IBAN ' + overviewResult?.iban
											: ''
									}
								</h4>
								{/*<img src={pencil}/>*/}
							</div>
							<div onClick={onClickWithdrawAmountAsync}
								className={`button ${!overviewResult?.available_balance ? 'button-disabled' : ''}`}>
								{dictionary.withdraw_funds}
							</div>
						</div>
					</div>
				</div>

				{showList ? (
					<PayoutsList
						overviewResult={overviewResult}
						showLoader={showLoader}
						setLoaderVisibility={setLoaderVisibility}
					/>
				) : null}
				{showConfirmDialog ? (
				<ConfirmationModal
					onActionFinished={() => getFinancialOverviewAsync()}
					setShowModal={setConfirmDialogVisibility}
					asyncTask={withdrawAmountAsync}
					title={dictionary.withdraw_funds}
					message={`${dictionary.confirmation_to_withdraw} ${currencyDecorator(overviewResult?.available_balance)}`}
					successMessage={dictionary.withdraw_success}
					errorMessage={dictionary.problem_withdraw}
					action={dictionary.confirm}
				/>
			) : null}
			</div>
			<SimpleLoader showLoader={showLoader} />
		</div>
	);
};

export default ProviderPayouts;
