import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { updateCommission } from "./networking";
import SimpleLoader from '../../../common/SimpleLoader';

const SetUpiriaCommissionDialog = ({ providerId, upiriaCommission, setCommissionVisibility, setUpiriaCommission }) => {
	const [opacity, setOpacity] = useState(0);
	const [commissionValue, setCommissionValue] = useState(upiriaCommission);
	const [showLoader, setLoaderVisibility] = useState(false);

	const dictionary = useDictionary();

	const handleUpiriaCommission = async () => {
		try {
			setLoaderVisibility(true);
			await updateCommission(providerId, commissionValue);
			setUpiriaCommission(commissionValue);
			onCancelClicked();
			setLoaderVisibility(false);
		} catch (error) {
		}
	};

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setCommissionVisibility(false);
		}, 800);
	};

	const handleChange = (event) => {
        setCommissionValue(event.target.value);
    }

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div className="share-dialog-container share-dialog-container-commission" data-testid="share-dialog">
				<div className="share-dialog-content-container">
					<div className="share-dialog-header">
						<div className="login-logo" style={{ marginTop: '0' }} />
						<div
							onClick={onCancelClicked}
							className="dialog-close-button"
							style={{ position: 'unset' }}
						/>
					</div>
					<div className="flex-filler" />
					<h3 style={{ fontStyle: 'normal', marginTop: 40, marginBottom: 16 }}>
						{dictionary.set_upiria_commission}
					</h3>
					<div className="share-link-header">
						<input
							type="text"
							onChange={handleChange} 
							value={commissionValue} 
							className="form-control-1"
						/>
						<div
							className="secondary-button"
							style={{ marginLeft: 10, fontFamily: 'Roboto' }}
							onClick={handleUpiriaCommission}
						>
							{dictionary.save}
						</div>
					</div>
				</div>
			</div>
			<SimpleLoader showLoader={showLoader} />
		</div>
	);
};

SetUpiriaCommissionDialog.propTypes = {
	providerId: PropTypes.string.isRequired,
	upiriaCommission: PropTypes.number.isRequired,
	setCommissionVisibility: PropTypes.func,
	setUpiriaCommission: PropTypes.func
};

export default SetUpiriaCommissionDialog;
