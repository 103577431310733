/* eslint-disable no-unused-vars */
export const downloadCSVFromJson = (filename, arrayOfJson) => {
	// convert JSON to CSV
	const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
	const header = Object.keys(arrayOfJson[0]);
	let csv = arrayOfJson.map((row) =>
		header
			.map((fieldName) => JSON.stringify(row[fieldName], replacer))
			.join(',')
	);
	csv.unshift(header.join(','));
	csv = csv.join('\r\n');

	// Create link and download
	var link = document.createElement('a');
	link.setAttribute(
		'href',
		'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv)
	);
	link.setAttribute('download', filename);
	link.style.visibility = 'hidden';
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export const ConverterHelper = (function () {
	function bytesToSize(bytes) {
		var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
		if (bytes === 0) return '0 Byte';
		var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
		return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
	}

	function numberWithCommas(number) {
		return number.toLocaleString();
	}

	function timeConverter(UNIX_timestamp, mode) {
		var a = new Date(UNIX_timestamp * 1000);
		var months = [
			'Jan',
			'Feb',
			'Mar',
			'Apr',
			'May',
			'Jun',
			'Jul',
			'Aug',
			'Sep',
			'Oct',
			'Nov',
			'Dec'
		];
		var year = a.getFullYear();
		var month = a.getMonth(); //months[a.getMonth()];
		var date = a.getDate();
		var hour = a.getHours();
		var min = a.getMinutes();
		var sec = a.getSeconds();

		if (mode === 'time') {
			return `${hour}:${min}`;
		} else {
			return `${date}/${month}/${year}`;
		}
	}

	function secondsToDayHourMinute(seconds, dictionary) {
		let hours = parseInt(seconds / 3600);
		let minutes = (seconds % 3600) / 60;
		let days = parseInt(hours / 24);

		if (days > 0) {
			hours = hours % 24;
		}

		return `${
			days === 0
				? ''
				: days.toString() +
				  ' ' +
				  (days > 1 ? dictionary.days : dictionary.day) +
				  ' '
		}${
			hours === 0
				? ''
				: Math.round(hours).toLocaleString() +
				  ' ' +
				  (hours > 1 ? dictionary.hours : dictionary.hour)
		}${
			minutes
				? ' ' +
				  Math.round(minutes).toLocaleString() +
				  ' ' +
				  (minutes > 1 ? dictionary.minutes : dictionary.minute)
				: ''
		}`;
	}

	function intDividedBy100(price) {
		return price / 100;
	}

	function calculateCommissionAmount(price, commission) {
		return (intDividedBy100(price) * intDividedBy100(commission)) / 100;
	}

	function participantsLabel(capacity, dictionary) {
		let participantLabel = (capacity > 1)
			? dictionary.participants.toLowerCase()
			: dictionary.participant.toLowerCase();

		return `${capacity} ${participantLabel}`;
	}

	function booleanToLabel(booleanToCheck, dictionary) {
		return (booleanToCheck)
			? dictionary.yes
			: dictionary.no;
	}

	return {
		bytesToSize: function (bytes) {
			return bytesToSize(bytes);
		},
		numberWithCommas: function (number) {
			return numberWithCommas(number);
		},
		timeConverter: function (UNIX_timestamp, mode) {
			return timeConverter(UNIX_timestamp, mode);
		},
		secondsToDayHourMinute: function (seconds, dictionary) {
			return secondsToDayHourMinute(seconds, dictionary);
		},
		normalizePrice: function (price) {
			return intDividedBy100(price);
		},
		normalizeCommission: function (price) {
			return intDividedBy100(price);
		},
		calculateCommissionAmount: function (price, commission) {
			return calculateCommissionAmount(price, commission);
		},
		participantsLabel: function (capacity, dictionary) {
			return participantsLabel(capacity, dictionary);
		},
		booleanToLabel: function (booleanToCheck, dictionary) {
			return booleanToLabel(booleanToCheck, dictionary);
		}
	};
})();

export default ConverterHelper;

export function getLastPathPart(path) {
	return path.substring(path.lastIndexOf('/') + 1);
}
