import React from 'react';
import PropTypes from 'prop-types';
import {useDictionary} from "../../../../utilities/hooks/UseDictionary";
import { ReactComponent as ClockIcon } from '../../../../assets/icons/ic_clock.svg';
const PaymentStatusListItem = ({status}) => {
    const dictionary = useDictionary();

    if (status === 'paid') {
        return (
            <div className="booking-customer-container">
                <div className="booking-status-paid-icon" />
                <div>{dictionary.paid}</div>
            </div>
        );
    } else if (status === 'refunded') {
        return (
            <div className="booking-customer-container">
                <div className="booking-status-refunded-icon" />
                <div>{dictionary.refunded}</div>
            </div>
        );
    } else if (status === 'pending') {
        return (
            <div className="booking-customer-container">
                <div className="booking-status-pending-icon">
                    <ClockIcon />
                </div>
                <div>{dictionary.pending}</div>
            </div>
        )
    } else if (status === 'failed') {
        return (
            <div className="booking-customer-container">
                <div className="booking-status-unpaid-icon"></div>
                <div>{dictionary.failed}</div>
            </div>
        );
    } else if (status === 'partial_refunded') {
        return (
            <div className="booking-customer-container">
                <div className="booking-status-refunded-icon" />
                <div>{dictionary.partial_refunded}</div>
            </div>
        );
    }
    return (
        <div className="booking-customer-container">
            <div className="booking-status-unpaid-icon" />
            <div>{dictionary.no_charge}</div>
        </div>
    );
}

PaymentStatusListItem.prototype = {
    status: PropTypes.string
};

export default PaymentStatusListItem;