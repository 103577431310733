/**
 * This helper is user to format the amount.
 * Amount in BE is stored to integer type fue to decimals complexity for amounts.
 *
 * For example 10.50$ is stores x100 -> 10.50x100 = 1050 (as an integer)
 * For this reason in the FE we need to format the amount when we display the amount and when we stored.
 *
 * @type {{formatAmount: (function(*): *)}}
 */
export const AmountFormatHelper = (function () {

    function normalizeAmount(amount) {
        return amount / 100;
    }

    function deNormalizeAmount(amount){
        return amount * 100;
    }

    return {

        formatAmount: function (amount) {
            return normalizeAmount(amount)
        },

        deFormatAmount: function (amount) {
            return deNormalizeAmount(amount)
        }
    };
})();