import { dictionaryMap } from '../../assets/dictionaries/dictionary';
import { shallowEqual, useSelector } from 'react-redux';

export const useBookingStatuses = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	let dictionary = user ? dictionaryMap[user.language_id] : dictionaryMap[143];

	return {
		confirmed: {
			id: 1,
			text: dictionary.confirmed,
			class: 'booking-status-confirmed'
		},
		pending: {
			id: 2,
			text: dictionary.pending,
			class: 'booking-status-pending'
		},
		rejected: {
			id: 3,
			text: dictionary.rejected,
			class: 'booking-status-rejected'
		},
		cancelled: {
			id: 4,
			text: dictionary.cancelled,
			class: 'booking-status-cancelled'
		},
		cancelled_provider: {
			id: 5,
			text: dictionary.cancelled_provider,
			class: 'booking-status-cancelled'
		},
		declined: {
			id: 6,
			text: dictionary.declined,
			class: 'booking-status-declined'
		},
		reschedule: {
			id: 7,
			text: dictionary.reschedule,
			class: 'booking-status-pending'
		}
	};
};
