export const SortOptions = {
	experiences: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'title',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
	bookings: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'index',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
	adminbookings: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'display_id',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
	resources: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'title',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
	resale_marketplace: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'reseller_commission',
			direction: 'desc',
			label_en: 'Commission'
		}
	],
	resale_collection: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'reseller_commission',
			direction: 'desc',
			label_en: 'Commission'
		}
	],
	providers: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'first_name',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
	groups: [
		{
			value: 'created_at',
			direction: 'desc',
			label_en: 'Newest'
		},
		{
			value: 'title',
			direction: 'asc',
			label_en: 'A-Z'
		}
	],
};
