import { UseCurrencyDecorator } from '../../../../../../../utilities/hooks/UseCurrencyDecorator';
import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
// import envelope from '../../../../../../../assets/icons/ic_envelope.svg';
// import download from '../../../../../../../assets/icons/ic_download.svg';
import InvoiceItem from './InvoiceItem';
import PropTypes from 'prop-types';
import React from 'react';

const Invoice = ({ details }) => {
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();
	const getTotal = () => {
		let total = 0;
		details.participant_groups.map((item) => {
			total += item.quantity * item.rate;
		});

		return total;
	};

	return (
		<div className="detail-tab-container thin-scrollbar fade-on-load">
			<div
				className="participant-row-container"
				style={{ marginBottom: '28px', gap: '12px' }}
			>
				<h3 data-testid="customer-invoice-header">
					{dictionary.customer_invoice}
				</h3>
				<div className="flex-filler" />
				{/* <div className='tertiary-button' style={{gap: '6px'}}>
                <img src={envelope}/>
                {dictionary.send}
            </div>
            <div className='tertiary-button' style={{gap: '6px'}}>
                <img src={download}/>
                {dictionary.download}
            </div> */}
			</div>
			{details?.participant_groups?.map((item) => (
				<InvoiceItem key={item.id} item={item} />
			))}
			{details?.discount && (
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginBottom: '24px' }}
				>
					<h4 style={{ fontWeight: 'normal' }}>
						{dictionary.discount + ' ' + details?.discount + '%'}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						-{currencyDecorator(getTotal() * (details?.discount/100))}
					</h4>
				</div>
			)}
			<div
				className="title-divider"
				style={{ marginBottom: '20px', marginTop: '0' }}
			/>

			<div
				className="participant-row-container"
				style={{ justifyContent: 'space-between' }}
			>
				<h4 style={{ fontWeight: 'normal', textTransform: 'capitalize' }}>
					{dictionary.total}
				</h4>
				<h4 style={{ fontWeight: 'normal' }}>
					{currencyDecorator(getTotal() - (getTotal() * (details?.discount/100) || 0))}
				</h4>
			</div>
		</div>
	);
};

Invoice.propTypes = {
	details: PropTypes.object
};

export default Invoice;
