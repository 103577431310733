import axios from 'axios';
import {
    RESALE_BOOKINGS_URL,
    RESALE_EXPERIENCES_URL,
    RESALE_INVITE_URL,
    RESALE_MARKETPLACE_URL,
    PROVIDER_GROUPS
} from "../../../../config";
import {DataTableHelper} from "../../../../utilities/helpers/DataTableHelper";

export function getMarketplaceExperiences(
    sortParam,
    searchParam,
    pageSize,
    pageNumber,
    cancelToken
) {
    return axios.get(
        RESALE_MARKETPLACE_URL +
        DataTableHelper.prepareParameters(
            sortParam, pageSize, pageNumber, null, searchParam
        ),
        { cancelToken: cancelToken }
    );
}
export function getResaleCollection(
    sortParam,
    pageSize,
    pageNumber,
    cancelToken
) {
    return axios.get(
        RESALE_EXPERIENCES_URL +
        DataTableHelper.prepareParameters(sortParam, pageSize, pageNumber),
        { cancelToken: cancelToken }
    );
}

export function getResaleBookings(sortParam, pageSize, pageNumber) {
    return axios.get(
        RESALE_BOOKINGS_URL +
        DataTableHelper.prepareParameters(sortParam, pageSize, pageNumber)
    );
}

export function addToCollection(id) {
    return axios.post(RESALE_EXPERIENCES_URL + '/' + id);
}

export function removeExperience(id) {
    return axios.delete(RESALE_EXPERIENCES_URL + '/' + id);
}

export function inviteCompany(data) {
    return axios.post(RESALE_INVITE_URL, data);
}
export function addProviderGroup(groupId,experienceId) {
	return axios.post(`${PROVIDER_GROUPS}\\${groupId}\\resale-collection\\${experienceId}`)
		.catch((error) => {
			return error.response;
		});
}

export function removeProviderGroup(groupId,experienceId) {
	return axios.delete(`${PROVIDER_GROUPS}\\${groupId}\\experience\\${experienceId}`)
		.catch((error) => {
			return error.response;
		});
}