import '../../../../assets/css/status_label.css';
import React from 'react';
import PropTypes from 'prop-types';
import {useDictionary} from "../../../../utilities/hooks/UseDictionary";

const labelStyles = {
	booking: {
		confirmed: 'status-label-confirmed',
		pending: 'status-label-pending',
		reschedule: 'status-label-pending',
		cancelled: 'status-label-rejected',
		declined: 'status-label-no-charge',
		cancelled_provider: 'status-label-rejected'
	},
	payment: {
		paid: 'status-label-paid',
		pending: 'status-label-pending',
		failed: 'status-label-unpaid',
		refunded: 'status-label-unpaid',
		partial_refunded: 'status-label-unpaid',
		no_charge: 'status-label-no-charge'
	},
	slot: {
		open: 'status-label-no-charge',
		booked: 'status-label-no-charge',
		booked_open: 'status-label-no-charge',
		pending_confirm: 'status-label-no-charge',
		pending_payment: 'status-label-no-charge',
		confirm_reschedule: 'status-label-no-charge',
		pending_reschedule: 'status-label-no-charge',
		paused: 'status-label-no-charge',
		closed: 'status-label-no-charge',
	}
};

const capitalize = (str) => {
	return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
};

const StatusLabel = ({ type, status }) => {
	const dictionary = useDictionary();

	return (
		<div className="status-label-wrapper">
			<div
				className={`status-label ${labelStyles[type][status]}`}
				data-testid={`${type}-status-label`}
			>
				{capitalize(type)}: {dictionary[status]}
			</div>
		</div>
	);
};

StatusLabel.propTypes = {
	type: PropTypes.string,
	status: PropTypes.string
};

export default StatusLabel;
