import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React from 'react';
import { DateFormatHelper } from '../../../../../utilities/helpers/DateFormatHelper';
import placeholder from "../../../../../assets/images/bg_placeholder_square.svg";
import warning from "../../../../../assets/icons/ic_warning_triangle.svg";
import eye from '../../../../../assets/icons/ic_eye.svg';
import useClientWidth from '../../../../../utilities/hooks/useClientWidth';
const BookingExperienceDetails = ({ details,viewExperienceHandler }) => {
	const dictionary = useDictionary();
	const clientWidth = useClientWidth();
	const currencyDecorator = UseCurrencyDecorator();

	return (
		<div
			className="booking-experience-details-container"
			data-testid="booking-experience-details-container"
		>
			<div
				className="settings-tab-bar"
				style={{
					justifyContent: 'space-between',
					backgroundColor: 'white',
					alignItems: 'center',
					borderTopLeftRadius: 12,
					borderTopRightRadius: 12
				}}
			>
				<div
					className="settings-tab"
					style={{ color: 'var(--brand-dark-blue)', cursor: 'initial' }}
				>
					{dictionary.details}
				</div>
				{/*<div className='tertiary-button' style={{marginRight:'32px', gap:'6px'}}>
          <img src={pencil}/>
          {dictionary.edit}
        </div>*/}
			</div>
			<div className="booking-experience-details-content-container thin-scrollbar">
				<div className="booking-experience-images-container">
					{/*{details.experience.assets.map((item, index) => {*/}
					{/*	if (index <= 3) {*/}
					{/*		return (*/}
					{/*			<img*/}
					{/*				key={item?.id}*/}
					{/*				className="booking-experience-images-container-image"*/}
					{/*				src={ImageHelper.getFullImageUrl(item?.thumbnail_url)}*/}
					{/*			/>*/}
					{/*		*/}
					{/*		);*/}
					{/*	}*/}
					{/*})}*/}
					<img
						className="experience-compact-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							details.experience?.assets[0]
								? ImageHelper.getFullImageUrl(
									details.experience?.assets[0]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<img
						className="experience-compact-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							details.experience?.assets[1]
								? ImageHelper.getFullImageUrl(
									details.experience?.assets[1]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<img
						className="experience-compact-thumb"
						onError={(e) => {
							e.currentTarget.src = placeholder;
						}}
						src={
							details.experience?.assets[2]
								? ImageHelper.getFullImageUrl(
									details.experience?.assets[2]?.thumbnail_url
								)
								: placeholder
						}
					/>
					<div
						className="booking-experience-images-container-color"
						style={{ backgroundColor: details.experience.color }}
					/>
				</div>
				<div className="participant-row-container" style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '16px' : '8px') }}>
					<h3 onClick={() => viewExperienceHandler(details.experience.view_url)} style={{ fontSize: '16px', marginTop: (clientWidth >= 767 ? '16px' : '8px'), marginBottom: '12px', cursor: 'pointer' }}>
						{details.experience.title}
					</h3>
					<img onClick={() => viewExperienceHandler(details.experience.view_url)} src={eye} style={{ cursor: 'pointer' }}/>
				</div>
				{details.status.value === 'reschedule' && details.reschedule_slot ? (
                    <>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal'}}>
                                {dictionary.date}
                            </h4>
							<h4 style={{fontWeight: 'normal'}}>
                                {DateFormatHelper.rangeDayMonthYearFormat(
                                    details.reschedule_slot.start_time,
                                    details.reschedule_slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4>
                            </h4>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal' , textDecorationLine : 'line-through'}}>
                                {DateFormatHelper.rangeDayMonthYearFormat(
                                    details.slot.start_time,
                                    details.slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal'}}>
                                {dictionary.time}
                            </h4>
							<h4 style={{fontWeight: 'normal'}}>
                                {DateFormatHelper.timeRangeFormat(
                                    details.reschedule_slot.start_time,
                                    details.reschedule_slot.end_time
                                )}
                            </h4>
                        </div>
                        <div className="participant-row-container"
                            style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                            <h4>
                            </h4>
                            <h4 style={{opacity: 0.6, fontWeight: 'normal' , textDecorationLine : 'line-through'}}>
                                {DateFormatHelper.timeRangeFormat(
                                    details.slot.start_time,
                                    details.slot.end_time
                                )}
                            </h4>
                        </div>
                    </>
                ): (
                    <>
                    <div className="participant-row-container"
                        style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                      {/* Your else condition JSX here */}
                      <h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
                        {dictionary.date}
                        </h4>
                        <h4 style={{ fontWeight: 'normal' }}>
                            {DateFormatHelper.rangeDayMonthYearFormat(
                                details.slot.start_time,
                                details.slot.end_time
                            )}
                        </h4>
                    </div>
                    <div className="participant-row-container" style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}>
                        <h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
                            {dictionary.time}
                        </h4>
                        <h4 style={{ fontWeight: 'normal' }}>
                            {DateFormatHelper.timeRangeFormat(
                                details.slot.start_time,
                                details.slot.end_time
                            )}
                        </h4>
                    </div>
                    </>
                  )}


				{details.overlap &&
					<div className="d-flex overlap-warn-container">
						<img src={warning} />
						<div className="overlap-warn">
							Suggested slot is overlapping with other existing slots

						</div>
					</div>
				}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.customer}
					</h4>
					<div className="booking-customer-container">
						<img className="booking-customer-avatar" src={details.avatar_url} />
						<h4 className='truncate booking-customer-container-customername' title={details.customer_name}>{details.customer_name}</h4>
					</div>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.email}
					</h4>
					<div className="booking-customer-container">
						<h4 style={{ fontWeight: 'normal' }}>{details.customer_email}</h4>
					</div>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.channel}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>{details.channel}</h4>
				</div>
				{details?.group ? (
					<div
						className="participant-row-container"
						style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.group}
						</h4>
						<div style={{ fontWeight: 'normal' }} className='truncate booking-detail-group-title' title={details.group.title}>{details.group.title}</div>
					</div>
				 ) : null}
				{/*<div*/}
				{/*	className="participant-row-container"*/}
				{/*	style={{ justifyContent: 'space-between', marginTop: '24px' }}*/}
				{/*>*/}
				{/*	<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>*/}
				{/*		{dictionary.email}*/}
				{/*	</h4>*/}
				{/*	<div className="booking-customer-container">*/}
				{/*		<h4 style={{ fontWeight: 'normal' }}>{details.customer_email}</h4>*/}
				{/*	</div>*/}
				{/*</div>*/}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.phone}
					</h4>
					<div className="booking-customer-container">
						<h4 style={{ fontWeight: 'normal' }}>{details.customer_phone}</h4>
					</div>
				</div>

				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.total_price}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{currencyDecorator(details.amount)}
					</h4>
				</div>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.discount}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>{details.discount || 0}%</h4>
				</div>
				{details.voucher ? (
					<div
						className="participant-row-container"
						style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
					>
						<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
							{dictionary.voucher}
						</h4>
						<h4 style={{ fontWeight: 'normal' }}>{details.voucher}</h4>
					</div>
				) : null}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px') }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.date_created}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{DateFormatHelper.monthYearTimeFormat(details.created_at)}
					</h4>
				</div>
				{/*<div*/}
				{/*	className="participant-row-container"*/}
				{/*	style={{ justifyContent: 'space-between', marginTop: '24px' }}*/}
				{/*>*/}
				{/*	<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>*/}
				{/*		{dictionary.notes}*/}
				{/*	</h4>*/}
				{/*	<h4 style={{ fontWeight: 'normal' }}>*/}
				{/*		{details.notes ?? dictionary.no_notes}*/}
				{/*	</h4>*/}
				{/*</div>*/}
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginTop: (clientWidth >= 767 ? '24px' : '16px'), gap: '5px' }}
				>
					<h4 style={{ opacity: 0.6, fontWeight: 'normal' }}>
						{dictionary.access}
					</h4>
					<h4 style={{ fontWeight: 'normal' }}>
						{details.slot.is_private_tour
							? dictionary.private
							: dictionary.public}
					</h4>
				</div>
			</div>
		</div>
	);
};

BookingExperienceDetails.propTypes = {
	details: PropTypes.object.isRequired,
};

export default BookingExperienceDetails;
