import info_14 from '../../../../../assets/icons/ic_info_14.svg';
import { ReactComponent as LeafIcon } from '../../../../../assets/icons/ic_leaf.svg';

import PropTypes from 'prop-types';
import ResaleImageCollage from './ResaleImageCollage';
import { TextFormatHelper } from '../../../../../utilities/helpers/TextFormatHelper';
import ConverterHelper from '../../../../../utilities/helpers/Converters';
import { CURRENCY } from '../../../../../config';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import Tooltip from '../../../common/Tooltip';
import React from 'react';

const ExperienceListItemFull = ({
	isResaleCollection,
	experience,
	HeaderItems,
	ActionButtons,
	ExperienceDetails
}) => {
	const dictionary = useDictionary();

	return (
		<div className="resale-collection-item-full-container">
			<div className="resale-collection-item-experience">
				<div className="resale-collection-item-experience-block">
					<HeaderItems />
					<div className="resale-collection-item-experience-block-title">
						<div className="resale-collection-item-experience-block-title-text">
							{experience.experience_title}
						</div>
						<div className="resale-collection-item-experience-block-title-provider-text">
							<div>{experience.provider_brand_name}</div>
							{experience.is_eco_friendly && (
								<div className="d-flex">
									<LeafIcon />
									<span
										style={{
											marginLeft: 4,
											font: 'normal normal 500 14px/16px Roboto'
										}}
									>
										{dictionary.eco}
									</span>
								</div>
							)}
						</div>
					</div>
					<ActionButtons />
				</div>
				<ResaleImageCollage assets={experience.assets} isResaleCollection={isResaleCollection} />
			</div>
			<div className="resale-collection-item-line" />
			<div className="resale-collection-item-experience-details">
				<ExperienceDetails />
				<div className="resale-collection-item-experience-pricing">
					<div className="resale-collection-item-experience-pricing-info-title">
						<div className="resale-collection-item-experience-pricing-info-title-text">
							Pricing
							<div className="text-with-info calendar-verification-info">
								<Tooltip content={dictionary.pricing_are_per_participants} />
							</div>
						</div>
					</div>
					<div className="resale-collection-item-experience-pricing-list thin-scrollbar">
						{experience.participant_groups.map((group) => (
							<div
								key={`price-${group.id}`}
								className="resale-collection-item-experience-pricing-list-item"
							>
								<div className="resale-collection-item-experience-pricing-list-label">
									{TextFormatHelper.toUpperCaseFirstLetter(group.name)}
								</div>
								<div className="resale-collection-item-experience-pricing-list-text">
									{ConverterHelper.normalizePrice(group.rate)}
									{CURRENCY}
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
			<div className="resale-collection-item-line" />
			<div className="resale-collection-item-commission">
				<div className="resale-collection-item-commission-block">
					<div className="resale-collection-item-commission-block-text">
						{ConverterHelper.normalizeCommission(
							experience.reseller_commission
						)}
						%
					</div>
					<div className="resale-collection-item-commission-block-label">
						{dictionary.commission}
					</div>
				</div>
				<div className="resale-collection-item-commission-line"></div>
				<div className="resale-collection-item-commission-price-list thin-scrollbar">
					{experience.participant_groups.map((group) => (
						<div
							key={`commission-${group.id}`}
							className="resale-collection-item-commission-price-list-item"
						>
							<div className="resale-collection-item-commission-price-list-item-label">
								{TextFormatHelper.toUpperCaseFirstLetter(group.name)}
							</div>
							<div className="resale-collection-item-commission-price-list-item-text">
								{ConverterHelper.calculateCommissionAmount(
									group.rate,
									experience.reseller_commission
								)}
								{CURRENCY}
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

ExperienceListItemFull.prototype = {
	experience: PropTypes.object.isRequired,
	HeaderItems: PropTypes.element,
	ActionButtons: PropTypes.element,
	ExperienceDetails: PropTypes.element
};

export default ExperienceListItemFull;
