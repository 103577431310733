import axios from 'axios';
import {
	EXPERIENCE_RESOURCES_URL,
	PROVIDERS_EXPERIENCES_SLOTS_URL,
	PROVIDERS_EXPERIENCES_URL,
	ENVIRONMENT
} from '../../../../config';
import React from 'react';

export function createSlot(id, data) {
	return axios.post(PROVIDERS_EXPERIENCES_URL + '/' + id + '/slots', data);
}

export function updateSlot(id, data, isResource) {
	return axios.put(
		(isResource ? EXPERIENCE_RESOURCES_URL : PROVIDERS_EXPERIENCES_URL) +
			'/slots/' +
			id,
		data
	);
}

export function deleteSlot(id, mode, isResource) {
	return axios.delete(
		(isResource ? EXPERIENCE_RESOURCES_URL : PROVIDERS_EXPERIENCES_URL) +
			'/slots/' +
			id,
		{
			data: {
				update_type: mode,
			}
		}
	);
}


export function increaseCapacity(id, capacity) {
	return axios.put(
		PROVIDERS_EXPERIENCES_URL +
			'/increase-capacity/' +
			id,
		{
			
			capacity: capacity,
			
		}
	);
}

export function createResourceSlot(id, data) {
	return axios.post(EXPERIENCE_RESOURCES_URL + '/' + id + '/slots', data);
}

export function getExperienceSlots(startDate, endDate, filterExperienceParams = [], filterAccessParams = [], filterSlotParams = [], isBooking = false) {
	let experienceIds = '';
	let isPrivateTour = '';
	const selectedSlots = [];
	if (filterExperienceParams.length > 0) {
		filterExperienceParams.map(expParam => {
			experienceIds += expParam + ',';
		});
	}

	experienceIds = experienceIds.slice(0, -1);
	if (filterAccessParams.length > 0) {
		filterAccessParams.map(accessParam => {
			if (accessParam === 'isPrivate') {
				isPrivateTour += '1,';
			}
			if (accessParam === 'isPublic') {
				isPrivateTour += '0,';
			}
		});
	}

	if (filterSlotParams.length > 0) {	
		selectedSlots.push(filterSlotParams.join(','));
	}

	if (isPrivateTour.slice(-1) === ',') {
		isPrivateTour = isPrivateTour.slice(0, -1);
	}

	const isBookingParam = isBooking ? 1 : 0;

	return axios.get(
		PROVIDERS_EXPERIENCES_SLOTS_URL +
			'?start_date=' +
			startDate +
			'&end_date=' +
			endDate +
			'&is_private_tour=' +
			isPrivateTour +
			'&experience_ids=' +
			experienceIds +
			'&is_booking=' +
			isBookingParam +
			'&status=' +
			selectedSlots
	);
}

export function getSlotDetails(id, type) {
	return axios.get(
		(type === 'experience'
			? PROVIDERS_EXPERIENCES_URL
			: EXPERIENCE_RESOURCES_URL) +
			'/slots/' +
			id
	);
}
