import React, { useEffect, useState } from 'react';
import { dictionaries } from '../../../../assets/dictionaries/dictionary';
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

const RegistrationConfirmation = ({
	setShowConfirmation,
	email,
	isPasswordReset = false
}) => {
	const dictionary = dictionaries.enDictionary;
	let count = 0;
	const [openAccordion, setOpenAccordion] = useState(false);

	const [showForm, setShowForm] = useState(false);

	// const history = useHistory();

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const onClickOpenAccordion = () => {
		setTimeout(() => setOpenAccordion(!openAccordion), 100);
	};


	return (
		<div
			style={{ opacity: showForm ? '1' : '0', zIndex: 5 }}
			className="login-form-container confirmation-container"
			data-testid="registration-confirmation-container"
		>
			<div className="login-logo" />
			<div className="title-divider" />
			<h1>{dictionary.check_your_email}</h1>
			<div className="text" style={{ marginBottom: '30px' }}>
				<span>{dictionary.sent_message_to_mail_1}</span>
				<span style={{ paddingLeft: '5px', paddingRight: '5px' }}>{email}</span>
				<span>
					{isPasswordReset
						? dictionary.sent_message_to_mail_2_2
						: dictionary.sent_message_to_mail_2}
				</span>
			</div>
			<div className='d-flex justify-between items-center'>
				<div
					className="text"
					style={{ fontWeight: 'bold', marginBottom: '20px' }}
				>
					{dictionary.didnt_get_email}
				</div>
				<label
					className='link-text'
					style={{ marginBottom: 20 }}
					onClick={onClickOpenAccordion}
					data-testid="re-enter-email-btn"
				>
					{dictionary.here_why}
				</label>
			</div>
			<div className={`registration-accordion-content ${openAccordion ? 'open' : ''}`}>
				<div className="text" style={{ marginBottom: '20px' }}>
					{dictionary.here_is_why}:
				</div>
				<ul className="no-margin-list">
					{dictionary.no_email_reasons.map((reason) => (
						<li key={count++} className="text">
							{reason}
						</li>
					))}
				</ul>
			</div>
			<div
				onClick={() => setShowConfirmation(false)}
				style={{ marginTop: '20px', marginBottom: '30px' }}
				className="secondary-button"
				data-testid="re-enter-email-btn"
			>
				{dictionary.re_enter_email}
			</div>
		</div>
	);
};

RegistrationConfirmation.propTypes = {
	setShowConfirmation: PropTypes.func,
	email: PropTypes.string,
	isPasswordReset: PropTypes.bool
};

export default RegistrationConfirmation;
