
export const NavigatorPaths = {
    home: {
        path: 'home',
        name: 'navigation_home'
    },
    home_payouts: {
        path: 'home/payouts',
        name: 'navigation_home_payouts'
    },
    applications: {
        path: 'applications',
        name: 'navigation_provider_applications'
    },
    settings: {
        path: 'settings/:id?',
        name: 'navigation_my_settings'
    },
    experiences: {
        path: 'experiences',
        name: 'navigation_provider_experiences'
    },
    provider_resources_add: {
        path: 'provider-resources/add',
        name: 'provider_resources_add'
    },
    provider_resources_edit: {
        path: 'provider-resources/:id',
        name: 'provider_resources_edit'
    },
    provider_resources: {
        path: 'provider-resources',
        name: 'navigation_provider_resources'
    },
    experience_addons_add: {
        path: 'experience-addons/add-resources',
        name: 'experience_addons_add'
    },
    experience_addons_edit: {
        path: 'experience-addons/:id',
        name: 'experience_addons_edit'
    },
    experience_addons: {
        path: 'experience-addons',
        name: 'navigation_experience_addons'
    },
    admin_experiences: {
        path: 'admin-experiences',
        name: 'navigation_admin_experiences'
    },
    resale_marketplace: {
        path: 'resale/marketplace',
        name: 'navigation_resale_marketplace'
    },
    resale_bookings: {
        path: 'resale/bookings',
        name: 'navigation_resale_bookings'
    },
    resale_collection: {
        path: 'resale/collection',
        name: 'navigation_resale_collection'
    },
    bookings: {
        path: 'bookings',
        name: 'navigation_provider_bookings'
    },
    booking_detail: {
        path: 'bookings/:id',
        name: 'navigation_provider_bookings_detail'
    },
    calendar: {
        path: 'calendar',
        name: 'navigation_provider_calendar'
    },
    calendar_slot_details: {
        path: 'calendar/:id',
        name: 'navigation_provider_calendar_slot_detail'
    },
    groups: {
        path: 'groups',
        name: 'navigation_provider_groups'
    },
    admin_bookings: {
        path: 'admin/bookings',
        name: 'navigation_admin_bookings'
    },
    admin_bookings_details: {
        path: 'admin/bookings/:id',
        name: 'navigation_admin_bookings_detail'
    },
    admin_groups: {
        path: 'admin/groups',
        name: 'navigation_provider_groups'
    },
}