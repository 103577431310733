import '../../../../assets/css/navigation.css';
import { ReactComponent as ApplicationIcon } from '../../../../assets/icons/ic_application.svg';
import { ReactComponent as BookingsIcon } from '../../../../assets/icons/ic_bookings.svg';
import { ReactComponent as CalendarIcon } from '../../../../assets/icons/ic_calendar.svg';
import { ReactComponent as ExperiencesIcon } from '../../../../assets/icons/ic_experience.svg';
import { ReactComponent as HomeIcon } from '../../../../assets/icons/ic_home.svg';
import { ReactComponent as ResaleIcon } from '../../../../assets/icons/ic_resale.svg';
import { ReactComponent as BurgerIcon } from '../../../../assets/icons/ic_burger.svg';
import { ReactComponent as GroupsIcon } from '../../../../assets/icons/ic_groups.svg';
import { ENABLE_RESOURCES } from '../../../../ui_config';
import { detectMobile2 } from '../../../../utilities/helpers/MobileHelper';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import ProfileWidget from '../ProfileWidget';
import * as common from '../__redux/commonRedux';
import CollapsedNavGroup from './CollapsedNavGroup';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { useAccount } from '../../../../utilities/hooks/UseAccount';
import { NavigatorPaths } from '../../../../assets/dictionaries/NavigatorPaths';

const Navigation = (props) => {
	const dictionary = useDictionary();
	const userTypeId = useUserTypeId();
	const [selectedGroupIndex, setSelectedGroupIndex] = useState(-1);
	const navContainer = useRef();
	const isMobile = detectMobile2();
	const account = useAccount();
	const isVerified = account?.verification_status?.value === '3';

	let count = 0;
	let groupNum = 0;

	const getSectionList = () => {
		if (userTypeId === 1) {
			return {
				provider_applications: {
					title: dictionary.provider_applications,
					icon: <ApplicationIcon />,
					url: NavigatorPaths.applications.path
				},

				experiences: {
					title: dictionary.experiences,
					icon: <ExperiencesIcon />,
					url: NavigatorPaths.admin_experiences.path
				},
				bookings: {
					title: dictionary.bookings,
					icon: <BookingsIcon />,
					url: NavigatorPaths.admin_bookings.path,
				},
				groups: {
					title: dictionary.collections,
					icon: <GroupsIcon />,
					url: NavigatorPaths.admin_groups.path
				}
			};
		} else if (userTypeId === 2) {
			return ENABLE_RESOURCES
				? {
						home: {
							title: dictionary.home,
							icon: <HomeIcon />,
							url: NavigatorPaths.home.path
						},
						bookings: {
							title: dictionary.bookings,
							icon: <BookingsIcon />,
							url: NavigatorPaths.bookings.path
						},
						calendar: {
							title: dictionary.calendar,
							icon: <CalendarIcon />,
							url: NavigatorPaths.calendar.path
						},
						products: {
							title: dictionary.products,
							icon: <ExperiencesIcon />,
							items: [
								{
									title: dictionary.experiences,
									url: NavigatorPaths.experiences.path
								},
								// {
								//     title: dictionary.add_ons,
								//     url: 'experience-addons'
								// },
								{
									title: dictionary.resources,
									url: NavigatorPaths.provider_resources.path
								}
							]
						},
						groups: {
							title: dictionary.collections,
							icon: <GroupsIcon />,
							url: NavigatorPaths.groups.path
						},
						...(isVerified && {
							resale: {
								title: dictionary.resale,
								icon: <ResaleIcon />,
								items: [
									{
										title: dictionary.marketplace,
										url: NavigatorPaths.resale_marketplace.path
									},
									{
										title: dictionary.resale_experience,
										url: NavigatorPaths.resale_collection.path
									},
									{
										title: dictionary.booking_information,
										url: NavigatorPaths.resale_bookings.path
									}
								]
							}
						})
				  }
				: {
						home: {
							title: dictionary.home,
							icon: <HomeIcon />,
							url: NavigatorPaths.home.path
						},
						bookings: {
							title: dictionary.bookings,
							icon: <BookingsIcon />,
							url: NavigatorPaths.bookings.path
						},
						calendar: {
							title: dictionary.calendar,
							icon: <CalendarIcon />,
							url: NavigatorPaths.calendar.path
						},
						groups: {
							title: dictionary.collections,
							icon: <GroupsIcon />,
							url: NavigatorPaths.groups.path
						},
						experiences: {
							title: dictionary.experiences,
							icon: <ExperiencesIcon />,
							url: NavigatorPaths.experiences.path
						},
						...(isVerified && {
							resale: {
								title: dictionary.resale,
								icon: <ResaleIcon />,
								items: [
									{
										title: dictionary.marketplace,
										url: NavigatorPaths.resale_marketplace.path
									},
									{
										title: dictionary.resale_experience,
										url: NavigatorPaths.resale_collection.path
									},
									{
										title: dictionary.booking_information,
										url: NavigatorPaths.resale_bookings.path
									}
								]
							}
						})
				  };
		}

		return {};
	};

	const showNavigation = () => {
		navContainer.current.style.display = 'flex';
		setTimeout(() => {
			navContainer.current.style.opacity = 1;
			navContainer.current.style.left = 0;
		}, 10);
	};

	const hideNavigation = () => {
		if (navContainer.current.style.display !== 'none') {
			navContainer.current.style.display = 'flex';
			navContainer.current.style.left = '-250px';
			navContainer.current.style.opacity = 0;

			setTimeout(() => {
				navContainer.current.style.display = 'none';
			}, 300);
		}
	};

	const collapseNavigation = () => {
		props.collapse();
	};

	useEffect(() => {
		if (navContainer) {
			if (props.burger) {
				showNavigation();
			} else if (isMobile) {
				hideNavigation();
			}
		}
	});

	const getUserSections = () => {
		let sections = Object.keys(getSectionList()).map(function (key) {
			return getSection(key);
		});

		return <>{sections}</>;
	};

	const onHeaderClicked = (e) => {
		e.currentTarget.parentNode.classList.toggle(
			'nav-group-container-collapsed'
		);
	};

	const resetGroupIndex = () => {
		setSelectedGroupIndex(-1);
	};

	const getSection = (section) => {
		count++;
		let sectionNew = getSectionList()[section];

		if (Object.prototype.hasOwnProperty.call(sectionNew, 'items')) {
			const isOpened = Boolean(
				sectionNew.items.find((item) => `/${item.url}` === location.pathname)
			);

			groupNum++;
			return !props.collapsed ? (
				<div
					key={sectionNew.title}
					className={`nav-group-container ${
						!isOpened && 'nav-group-container-collapsed'
					}`}
				>
					<div
						onClick={(e) => (isOpened ? null : onHeaderClicked(e, sectionNew))}
						className="nav-group-header-container"
					>
						<span className="nav-item-icon">{sectionNew.icon}</span>
						<div
							className="nav-item-title"
							data-testid={`${sectionNew.title}-nav-title`}
						>
							{sectionNew.title}
						</div>
						<div className="nav-group-caret" />
					</div>
					<div className="nav-group-items-container">
						{sectionNew.items.map((item) => (
							<NavLink to={'/' + item.url} key={count++}>
								<div className="nav-item-row">
									<div
										className="nav-item-title"
										data-testid={`${item.title}-nav-item`}
									>
										{item.title}
									</div>
								</div>
							</NavLink>
						))}
					</div>
				</div>
			) : (
				<CollapsedNavGroup
					groupIndex={groupNum}
					selectedIndex={selectedGroupIndex}
					setSelectedIndex={setSelectedGroupIndex}
					key={sectionNew.title}
					item={sectionNew}
					count={count}
				/>
			);
		} else {
			const { icon, title, url } = getSectionList()[section];
			return (
				<NavLink to={'/' + url} key={title} onClick={() => resetGroupIndex()}>
					<div className="nav-item-row">
						<span className="nav-item-icon">{icon}</span>
						{!props.collapsed ? (
							<div
								className="nav-item-title"
								data-testid={`${title}-nav-title`}
							>
								{title}
							</div>
						) : null}
					</div>
				</NavLink>
			);
		}
	};

	return (
		<div
			ref={navContainer}
			className={
				!props.collapsed
					? 'navigation-container'
					: 'navigation-container navigation-container-narrow'
			}
		>
			<div className="navigation-logo-container">
				{!props.collapsed ? (
					<>
						<div className="navigation-logo" />
						<div
							className="navigation-burger"
							onClick={() => {
								if (isMobile) {
									props.closeBurger();
								} else {
									collapseNavigation();
								}
							}}
						>
							<BurgerIcon />
						</div>
					</>
				) : (
					<div className="navigation-logo-collapsed" />
				)}
			</div>
			<div className="navigation-divider" />
			<div className="nav-items-container">
				{getUserSections()}

				{isMobile ? <ProfileWidget /> : null}
			</div>
		</div>
	);
};

Navigation.propTypes = {
	collapsed: PropTypes.bool,
	collapse: PropTypes.func,
	burger: PropTypes.bool,
	closeBurger: PropTypes.func
};

export default connect(common.states, common.actions)(Navigation);
