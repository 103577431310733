import info from '../../../../../../../assets/icons/ic_info.svg';
import logo from '../../../../../../../assets/images/logo_white_letter.svg';
import ImageHelper from '../../../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
import useComponentVisible from '../../../../../../../utilities/hooks/useComponentVisible';
import PropTypes from 'prop-types';
import React from 'react';

const ResourceSelectItem = ({ item, selectedItem, onItemSelected }) => {
	const dictionary = useDictionary();

	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	return (
		<div
			style={{ marginBottom: '8px', height: 'unset', minHeight: '56px' }}
			onClick={() => onItemSelected(item)}
			className={
				selectedItem?.id === item?.id
					? 'custom-select-item select-experience-item custom-select-item-selected'
					: 'select-experience-item custom-select-item'
			}
		>
			<div className="custom-experience-image-container">
				<img
					onError={(e) => {
						e.currentTarget.src = logo;
					}}
					src={ImageHelper.getFullImageUrl(item?.assets?.[0]?.thumbnail_url)}
					className={
						item.id === -1
							? 'custom-experience-image experience-icon-disabled'
							: 'custom-experience-image'
					}
				/>
			</div>
			<div className="custom-experience-title">{item?.title}</div>

			<div ref={ref} className="resource-info-icon-container">
				<img
					src={info}
					className="resource-info-icon"
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						setIsComponentVisible(!isComponentVisible);
					}}
				/>
				{isComponentVisible ? (
					<div
						className="schedule-options-wrapper resource-info-wrapper"
						style={{ left: '20px', top: '-30px', display: 'flex', zIndex: '4' }}
					>
						<div className="schedule-options-container">
							<div
								style={{
									paddingLeft: '12px',
									paddingRight: '12px',
									marginTop: '12px',
									fontWeight: 'bold'
								}}
								className="text"
							>
								{dictionary.used_in_experiences}
							</div>
							<div className="dialog-title-divider" />
							{item.experiences && item?.experiences?.length > 0 ? (
								item.experiences.map((item) => (
									<div
										key={item}
										className="drop-down-item"
										style={{ height: 'unset', marginBottom: '6px' }}
									>
										{item.title}
									</div>
								))
							) : (
								<div
									style={{ paddingLeft: '12px', paddingBottom: '12px' }}
									className="text"
								>
									{dictionary.no_experiences_yet}
								</div>
							)}
						</div>
					</div>
				) : null}
			</div>
		</div>
	);
};

ResourceSelectItem.propTypes = {
	item: PropTypes.object,
	selectedItem: PropTypes.object,
	onItemSelected: PropTypes.func
};

export default ResourceSelectItem;
