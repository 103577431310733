/* eslint-disable no-unused-vars */
import React from 'react';
import MaskedInput from '@biproxi/react-text-mask';
import * as Yup from 'yup';
import Select from 'react-select';
import Flag from 'react-world-flags';
import { MultiSelect } from 'react-multi-select-component';
import { ReactComponent as CheckIcon } from '../../assets/icons/ic_check.svg';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const FormikHelper = (function () {
	let formik;

	let dictionary = {
		tooShort: 'Too Short!',
		tooLong: 'Too Long!',
		invalidEmail: 'Invalid email',
		passwordMessage:
			"The password can't start with a number, must contain 8 characters including one uppercase, one lowercase, one number and one special case character",
		required: 'Required!',
		defaultTextMin: 1,
		defaultTextMax: 128,
		defaultPassMin: 8,
		defaultPassMax: 40,
		defaultNumberMin: 10,
		select: 'Please select an option'
	};

	function getDefaultYupTextInput(required) {
		let yup = Yup.string()
			.min(dictionary.defaultTextMin, dictionary.tooShort)
			.max(dictionary.defaultTextMax, dictionary.tooLong);

		if (required) {
			yup = yup.required(dictionary.required);
		}

		return yup;
	}

	function getNoLimitTextInput(required) {
		let yup = Yup.string();

		if (required) {
			yup = yup.required(dictionary.required);
		}
		return yup;
	}

	function getDefaultYupEmail(required, dictionary) {
		let yup = Yup.string().email(dictionary.invalid_email);

		if (required) {
			yup = yup.required(dictionary.requiredTemplate(dictionary.email));
		}
		return yup;
	}

	function getDefaultYupPhone(required) {
		let yup = Yup.number()
			.min(dictionary.defaultNumberMin, dictionary.tooShort)
			.typeError('Please input a valid phone number')
			.nullable(true);

		if (required) {
			yup = yup.required(dictionary.required);
		}

		return yup;
	}

	function getDefaultYupCountry(required) {
		let yup = Yup.number()
			.min(1, dictionary.select)
			.typeError('Please select an option');

		if (required) {
			yup = yup.required(dictionary.required);
		}

		return yup;
	}

	function getDefaultYupLanguage(required) {
		let yup = Yup.string();

		if (required) {
			yup = yup.required(dictionary.required);
		}

		return yup;
	}

	function getDefaultYupPassword(required, dictionary) {
		let yup = Yup.string()
			.min(8, dictionary.password_too_short)
			.max(30, dictionary.password_too_long)
			.matches(
				/^[^\d](?=.*[A-Za-z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]+$/,
				dictionary.passwordMessage
			);

		if (required) {
			yup = yup.required(dictionary.requiredTemplate(dictionary.password));
		}

		return yup;
	}

	function getInputTemplate(
		name,
		label,
		placeholder,
		inputType,
		required = false,
		enabled = true,
		tooltipAbs = true,
		step = '0.01',
		noValidate = false
	) {
		tooltipAbs = false;

		function getChangePattern() {
			if (inputType === 'phone') {
				return /^[0-9\b]+$/;
			} else if (inputType === 'number') {
				return /^[.0-9\b]+$/;
			}
		}

		function onChange(e) {
			const re = getChangePattern();
			if (e.target.value === '' || re.test(e.target.value)) {
				formik.handleChange(e);
			}
		}

		if (inputType === 'email') {
			inputType = 'text';
		}

		if (inputType === 'phone') {
			return (
				<div
					className={
						formik.errors[name] && formik.touched[name]
							? 'group-inputs invalid'
							: formik.touched[name]
							? 'group-inputs valid'
							: 'group-inputs'
					}
				>
					<label>
						{label}
						{required ? <span>*</span> : ''}
					</label>

					<input
						type={inputType}
						pattern="[0-9]*"
						disabled={!enabled}
						name={name}
						data-testid={name + '-input'}
						placeholder={placeholder}
						onChange={onChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
					/>
					{formik.errors[name] && formik.touched[name] ? (
						<div
							data-testid={name + '-error-tooltip'}
							className="error-tooltip"
						>
							{formik.errors[name]}
						</div>
					) : null}
				</div>
			);
		} else {
			return (
				<div
					className={
						formik.errors[name] && formik.touched[name]
							? 'group-inputs invalid'
							: formik.touched[name]
							? 'group-inputs valid'
							: 'group-inputs'
					}
				>
					<label>
						{label}
						{required ? <span>*</span> : ''}
					</label>

					<input
						step={step}
						disabled={!enabled}
						type={inputType}
						name={name}
						data-testid={name + '-input'}
						placeholder={placeholder}
						onChange={formik.handleChange}
						onBlur={formik.handleBlur}
						value={formik.values[name]}
					/>
					{formik.errors[name] && formik.touched[name] ? (
						<div
							data-testid={name + '-error-tooltip'}
							className="error-tooltip"
						>
							{formik.errors[name]}
						</div>
					) : null}
				</div>
			);
		}
	}

	function getTextareaTemplate(
		name,
		label,
		placeholder,
		required,
		enabled = true,
		tooltipAbs = true,
		height = 48
	) {
		return (
			<div
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
				</label>
				<textarea
					style={{ height: height, resize: enabled ? 'vertical' : 'none', marginTop: 0 }}
					disabled={!enabled}
					name={name}
					data-testid={name + '-input'}
					placeholder={placeholder}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
				{formik.errors[name] && formik.touched[name] ? (
					<span
						data-testid={name + '-error-tooltip'}
						style={{ position: 'unset' }}
						className="error-tooltip"
					>
						{formik.errors[name]}
					</span>
				) : null}
			</div>
		);
	}

	function getSelectTemplate(
		name,
		label,
		required,
		options,
		defaultLabel,
		tooltipAbs = true,
		enabled = true,
		changeHandler = null
	) {
		tooltipAbs = false;
		return (
			<div
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
					{/* {formik.errors[name] && formik.touched[name] ? (
						<span className="label-error-message">{formik.errors[name]}</span>
					) : null} */}
				</label>
				<select
					disabled={!enabled}
					name={name}
					data-testid={name + '-input'}
					onChange={changeHandler ? changeHandler : formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				>
					<option value="" label={defaultLabel}>
						{defaultLabel}
					</option>
					{options
						? options.map((option, index) => {
								return (
									<option
										key={option.key}
										value={option.key}
										label={option.value}
									>
										{option.value}
									</option>
								);
						  })
						: null}
				</select>
				{formik.errors[name] && formik.touched[name] ? (
					<span data-testid={name + '-error-tooltip'} className="error-tooltip">
						{formik.errors[name]}
					</span>
				) : null}
			</div>
		);
	}

	function getCountrySelectTemplate(
		name,
		label,
		required,
		options,
		defaultLabel,
		tooltipAbs = true,
		enabled = true,
		changeHandler = null
	) {
		const handleClick = () => {
			setTimeout(() => {
				const searchElement = document.getElementsByName("rfs-q")[0];
				if (searchElement) {
					searchElement.focus();
				}
			});
		};
		const matchedCountryData = options?.find((option) => option.key === formik.values[name]);

		return (
			<>
				<div
					className={
						formik.errors[name] && formik.touched[name]
							? 'group-inputs invalid'
							: formik.touched[name]
								? 'group-inputs valid'
								: 'group-inputs'
					}
					onClick={handleClick}
				>
					<label>
						{label}
						{required ? <span>*</span> : ''}
					</label>
					<MultiSelect
						{...({
							placeholder: "Select a country",
							options: options.map(({key, value, sn}) => ({
								id: key,
								label: value,
								value: sn,
							})),
							ClearSelectedIcon: null,
							closeOnChangedValue: true,
							valueRenderer: () => (
								<div className="company-selected-country-name">
									<Flag
										code={matchedCountryData?.sn?.toLowerCase()}
										width={16}
									/>
									<div>{matchedCountryData ? matchedCountryData?.value : "Select a country"}</div>
								</div>
							),
							ItemRenderer: (node) => {
								return (
									<div
										className={`company-country-item`}
										onClick={() => node.onClick(node.option.value)}
									>
										<span className="experience-country-flag">
											<Flag
												code={node.option.value?.toLowerCase()}
												width={16}
											/>
										</span>
										<span className="company-country-name">
											{node.option.label}
										</span>
										{node.checked && (
											<span className="company-country-checked">
												<CheckIcon/>
											</span>
										)}
									</div>
								)
							},
							value: matchedCountryData ? [{
								id: matchedCountryData?.key,
								label: matchedCountryData?.value,
								value: matchedCountryData?.sn,
							}] : [],
							onChange: (values) => (changeHandler ? changeHandler : formik.handleChange)(values[values.length - 1]?.id),
							hasSelectAll: false,
							labelledBy: "select",
							className: "company-country-select"
						})}
					/>
					{formik.errors[name] && formik.touched[name] ? (
						<span data-testid={name + '-error-tooltip'} className="error-tooltip">
						{formik.errors[name]}
					</span>
					) : null}
				</div>
			</>
		);
	}

	function getSelectWithSearchTemplate(
		name,
		label,
		required,
		options,
		tooltipAbs = true,
		enabled = true,
		changeHandler = null
	) {
		return (
			<div className={'group-inputs'}>
				<label>
					{label}
					{required ? <span>*</span> : ''}
					{formik.errors[name] && formik.touched[name] ? (
						<span className="label-error-message">{formik.errors[name]}</span>
					) : null}
				</label>
				<Select
					className={'react-select react-multiselect'}
					isDisabled={!enabled}
					name={name}
					options={options}
					isClearable={true}
					placeholder={'Type to search...'}
					closeMenuOnSelect={true}
					onChange={(selectedOption) => {
						if (changeHandler) {
							changeHandler(selectedOption);
						} else {
							formik.setFieldValue(name, selectedOption?.value);
						}
					}}
					onBlur={formik.handleBlur}
					value={
						options.filter(
							(option) => option.value.toString() === formik.values[name]
						)[0]
					}
				/>
			</div>
		);
	}

	function getTextFieldTemplate(name, label, placeholder) {
		return (
			<div
				title="The email address you provided during registration cannot be changed"
				className={'group-inputs'}
			>
				<label>
					{label}
					{formik.errors[name] && formik.touched[name] ? (
						<span className="label-error-message">{formik.errors[name]}</span>
					) : null}
				</label>
				<input
					disabled={true}
					type="text"
					className="text-field"
					name={name}
					data-testid={name + '-input'}
					placeholder={placeholder}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
				{formik.errors[name] && formik.touched[name] ? (
					<span data-testid={name + '-error-tooltip'} className="error-tooltip">
						{formik.errors[name]}
					</span>
				) : null}
			</div>
		);
	}

	function getDisabledInputTemplate(
		name,
		label,
		placeholder,
		inputType,
		required
	) {
		return (
			<div
				title="The email address you provided during registration cannot be changed"
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
					{formik.errors[name] && formik.touched[name] ? (
						<span className="label-error-message">{formik.errors[name]}</span>
					) : null}
				</label>
				<input
					disabled
					type={inputType}
					className="grey-text"
					name={name}
					placeholder={placeholder}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
			</div>
		);
	}

	function getCheckboxTemplate(name, label, required, handler) {
		return (
			<div
				onClick={() =>
					handler
						? handler(name, !formik.values[name])
						: formik.setFieldValue(name, !formik.values[name])
				}
				className="form-check-container"
				style={{ marginLeft: '0' }}
			>
				<div
					className={
						formik.values[name]
							? 'custom-checkbox custom-checkbox-checked'
							: 'custom-checkbox'
					}
					data-testid={name + '-checkbox'}
				/>
				<label
					style={{ marginBottom: 0, marginLeft: '10px', fontWeight: 400 }}
					className="checkbox-label"
				>
					{label}
				</label>
				<input
					name={name}
					type="checkbox"
					style={{ display: 'none' }}
					checked={formik.values[name]}
					value={formik.values[name]}
					readOnly={true}
				/>
			</div>
		);
	}

	function getPhoneInputTemplate(
		name,
		label,
		placeholder,
		required,
		enabled = true
	) {
		return (
			<div
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
					{formik.errors[name] && formik.touched[name] ? (
						<span className="label-error-message">{formik.errors[name]}</span>
					) : null}
				</label>
				<MaskedInput
					type="phone"
					mask={[
						'(',
						/[1-9]/,
						/\d/,
						/\d/,
						')',
						' ',
						/\d/,
						/\d/,
						/\d/,
						'-',
						/\d/,
						/\d/,
						/\d/,
						/\d/
					]}
					disabled={!enabled}
					name={name}
					placeholder={placeholder}
					guide={false}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
			</div>
		);
	}

	
	function getPhoneNumberInputTemplate(
		name,
		label,
		placeholder,
		required,
		enabled = true
	) {
		return (
			<div
				className={
					formik.errors[name] && formik.touched[name]
						? 'group-inputs invalid'
						: formik.touched[name]
						? 'group-inputs valid'
						: 'group-inputs'
				}
			>
				<label>
					{label}
					{required ? <span>*</span> : ''}
				</label>
				<PhoneInput
					country={'gr'}
					disabled={!enabled}
					name={name}
					data-testid={name + '-input'}
					placeholder={placeholder}
					onChange={(value) =>  {
						if (!formik.touched[name]) formik.setFieldTouched(name);
						formik.setFieldValue(name, value);
					}}
					onBlur={formik.handleBlur}
					value={formik.values[name]}
				/>
				{formik.errors[name] && formik.touched[name] ? (
					<div
						data-testid={name + '-error-tooltip'}
						className="error-tooltip"
					>
						{formik.errors[name]}
					</div>
				) : null}
			</div>
		);
	}

	function setFormik(newFormik) {
		formik = newFormik;
	}

	return {
		getInputTemplate: function (
			name,
			label,
			placeholder,
			type,
			required,
			enabled,
			tooltipAbs,
			step,
			noValidate
		) {
			return getInputTemplate(
				name,
				label,
				placeholder,
				type,
				required,
				enabled,
				tooltipAbs,
				step,
				noValidate
			);
		},
		getTextareaTemplate: function (
			name,
			label,
			placeholder,
			required,
			enabled,
			tooltipAbs,
			height
		) {
			return getTextareaTemplate(
				name,
				label,
				placeholder,
				required,
				enabled,
				tooltipAbs,
				height
			);
		},
		getSelectTemplate: function (
			name,
			label,
			required,
			options,
			defaultLabel,
			tooltipAbs,
			enabled,
			changeHandler
		) {
			return getSelectTemplate(
				name,
				label,
				required,
				options,
				defaultLabel,
				tooltipAbs,
				enabled,
				changeHandler
			);
		},
		getCountrySelectTemplate: function (
			name,
			label,
			required,
			options,
			defaultLabel,
			tooltipAbs,
			enabled,
			changeHandler
		) {
			return getCountrySelectTemplate(
				name,
				label,
				required,
				options,
				defaultLabel,
				tooltipAbs,
				enabled,
				changeHandler
			);
		},
		getSelectWithSearchTemplate: function (
			name,
			label,
			required,
			options,
			tooltipAbs,
			enabled,
			changeHandler
		) {
			return getSelectWithSearchTemplate(
				name,
				label,
				required,
				options,
				tooltipAbs,
				enabled,
				changeHandler
			);
		},
		getDisabledInputTemplate: function (
			name,
			label,
			placeholder,
			type,
			required
		) {
			return getDisabledInputTemplate(name, label, placeholder, type, required);
		},
		getTextFieldTemplate: function (name, label, placeholder) {
			return getTextFieldTemplate(name, label, placeholder);
		},
		getCheckboxTemplate: function (name, label, required, handler) {
			return getCheckboxTemplate(name, label, required, handler);
		},
		getPhoneInputTemplate: function (
			name,
			label,
			placeholder,
			required,
			enabled
		) {
			return getPhoneInputTemplate(name, label, placeholder, required, enabled);
		},
		
		getPhoneNumberInputTemplate: function (
			name,
			label,
			placeholder,
			required,
			enabled
		) {
			return getPhoneNumberInputTemplate(name, label, placeholder, required, enabled);
		},
		setFormik: function (formik) {
			setFormik(formik);
		},
		getDefaultYupTextInput: function (required) {
			return getDefaultYupTextInput(required);
		},
		getNoLimitTextInput: function (required) {
			return getNoLimitTextInput(required);
		},
		getDefaultYupEmail: function (required, dictionary) {
			return getDefaultYupEmail(required, dictionary);
		},
		getDefaultYupPhone: function (required) {
			return getDefaultYupPhone(required);
		},
		getDefaultYupPassword: function (required, dictionary) {
			return getDefaultYupPassword(required, dictionary);
		},
		getDefaultYupCountry: function (required) {
			return getDefaultYupCountry(required);
		},

		getDefaultYupLanguage: function (required) {
			return getDefaultYupLanguage(required);
		},
		dictionary: dictionary
	};
})();

export default FormikHelper;
