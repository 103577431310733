export const addonsDummy = [
	{
		id: 1,
		title: 'Deluxe cabin upgrade',
		description:
			'Upgrade your stay at the top of Olympus to a great luxurious experience.',
		image_url:
			'https://www.ekdromi.gr/public/images/upload/1058356rock_dandy_mountain_house_arachova.jpg',
		quantity: 3,
		price: 15,
		experiences: [
			{
				id: 1,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 1',
				description: 'Experience 1 description'
			},
			{
				id: 2,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 2',
				description: 'Experience 2 description'
			}
		]
	},
	{
		id: 2,
		title: '2 seat ATV upgrade',
		description:
			"You don't have to travel alone, get this addon and invite a friend",
		image_url:
			'https://www.atv.com/blog/wp-content/uploads/2019/06/Two-Seat-ATV-Guide-671x382.jpg',
		quantity: 4,
		price: 13,
		experiences: [
			{
				id: 3,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 3',
				description: 'Experience 3 description'
			},
			{
				id: 4,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 4',
				description: 'Experience 4 description'
			}
		]
	},
	{
		id: 3,
		title: 'VIP Wine Tasting',
		description: 'Gain access to an exclusive wine tasting at our VIP cellar',
		image_url:
			'https://media.tacdn.com/media/attractions-splice-spp-674x446/06/73/d3/18.jpg',
		quantity: 4,
		price: 13,
		experiences: [
			{
				id: 3,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 3',
				description: 'Experience 3 description'
			},
			{
				id: 4,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 4',
				description: 'Experience 4 description'
			}
		]
	},
	{
		id: 4,
		title: 'Health insurance',
		description:
			"Exclusive deals with our country's best health insurance providers.",
		image_url:
			'https://www.cyprusinsurancenews.com/wp-content/uploads/2022/01/healthinsurance.jpg',
		quantity: 4,
		price: 13,
		experiences: [
			{
				id: 3,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 3',
				description: 'Experience 3 description'
			},
			{
				id: 4,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 4',
				description: 'Experience 4 description'
			}
		]
	},
	{
		id: 5,
		title: 'Transportation',
		description:
			'No more waiting in line for a taxi. Get a conmfortable ride straight from the airport to any destination in the city.',
		image_url:
			'https://www.speedishuttle.com/images/site/hawaii-airport-transportation.jpg',
		quantity: 4,
		price: 13,
		experiences: [
			{
				id: 3,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 3',
				description: 'Experience 3 description'
			},
			{
				id: 4,
				color: '#FFE75B',
				image:
					'https://www.newsauto.gr/wp-content/uploads/2021/08/210826154814_jets-ski-b.jpg',
				title: 'Experience 4',
				description: 'Experience 4 description'
			}
		]
	}
];
