import '../../../../../assets/css/experiences.css';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { useUserTypeId } from '../../../../../utilities/hooks/UseUserTypeId';
import ConfirmationModal from '../../../common/ConfirmationModal';
import FormLoader from '../../../common/FormLoader';
import ViewTypeSelector from '../../../common/ViewTypeSelector';
import * as common from '../../../common/__redux/commonRedux';
import PaginationController from '../../../common/pagination_controller/PaginationController';
import SortOptionsSelector from '../../../common/sort_options/SortOptionsSelector';
import NewExperience from '../../new_experience/NewExperience';
import { getProviderInfo } from '../../provider_home/networking';
import ExperienceListItemCompact from './ExperienceListItemCompact';
import ExperienceListItemFull from './ExperienceListItemFull';
import ShareExperienceDialog from './ShareExperienceDialog';
import ShareExperiencesDialog from './ShareExperiencesDialog';
import iconFilter from '../../../../../assets/icons/ic_filter.svg';
import {
    deleteExperience,
    getAllAdminExperiences,
    addProviderGroup,
    removeProviderGroup
} from './networking';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {useAccount} from "../../../../../utilities/hooks/UseAccount";
import ExperienceDetailDialog from "../../resale_hub/components/ExperienceDetailDialog";
import FilterDialog from './FilterDialog';
import GroupInfo from '../groups/group_dialog/GroupInfo.js'
import AddGroupDialog from '../groups/AddGroupDialog.js'
import {getLanguages} from "../../new_experience/networking";
import {getMatchedCountryData} from "../../../../../utilities/helpers/LanguageHelper";
import SearchLocation from "../../resale_hub/components/SearchLocation";
import IconButton from '../../atom/IconButton';

const AdminProviderExperiences = ({ setWarningMessage,setSuccessMessage }) => {
    const dictionary = useDictionary();
    const [config, setConfig] = useState(null);

    const [showNewExperience, setShowNewExperience] = useState(false);
    const [experiences, setExperiences] = useState([]);
    const [shareUrl, setShareUrl] = useState();
    const [showLoader, setShowLoader] = useState(false);
    const [tempId, setTempId] = useState('');
    const [tempData, setTempData] = useState();
    const [editId, setEditId] = useState('');
    const [showShareDialog, setShareDialogVisibility] = useState(false);
    const [showSharesDialog, setSharesDialogVisibility] = useState(false);
    const [selectedExperience, setSelectedExperience] = useState(null);
    const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedViewUrl, setSelectedViewUrl] = useState(null);
    const [showGroupDialog, setGroupDialogVisibility] = useState(false);
    const [showGroupInfo, setShowGroupInfo] = useState(false);
    const [groupInfoType, setGroupInfoType] = useState(null);
    const [viewType, setViewType] = useState(
        parseInt(localStorage.getItem('experiences_selected_view_type') ?? 0)
    );
    const [pageSize, setPageSize] = useState(
        parseInt(localStorage.getItem('experiences_selected_page_size') ?? 10)
    );
    const [currentPage, setCurrentPage] = useState(0);
    const [dataLength, setDataLength] = useState(0);
    const [errorMessage, setErrorMessage] = useState(
        dictionary.problem_deleting_experience
    );

    const [allLanguages, setAllLanguages] = useState([]);

    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [filterProviderParams, setFilterProviderParams] = useState([]);

    const [searchParam, setSearchParam] = useState('');

    const source = axios.CancelToken.source();

    useEffect(() => {
        if (!showShareDialog) {
            setSelectedExperience(null);
        }
    }, [showShareDialog]);

    useEffect(() => {
        if (selectedOption) {
            getAllExperiencesAsync();
            countAllFilter();
        }
    }, [pageSize, currentPage, selectedOption, searchParam]);

    useEffect(() => {
        getLanguagesAsync();
        return () => {
            localStorage.removeItem('admin_experiences_filter_provider');
            source.cancel('component unmounted');
        };
    }, []);

    const getLanguagesAsync = async () => {
        try {
            let { data } = await getLanguages();
            setAllLanguages(data.filter(({ code }) => !!getMatchedCountryData(code)));
        } catch (e) {
            console.log(e);
        }
    };
    const getAllExperiencesAsync = async () => {
        setShowLoader(true);
        try {
            const { data } = await getAllAdminExperiences(
                selectedOption,
                pageSize,
                currentPage,
                source.token,
                filterProviderParams,
                searchParam
            );
            setDataLength(data.recordsFiltered);
            setShowLoader(false);
            setExperiences(data.data);
            setShareUrl(data.share_url);
        } catch (error) {
            console.error(error)
            setShowLoader(false);
        }
    };

    const onExperienceSaved = () => {
        setEditId('');
        setShowNewExperience(false);
        getAllExperiencesAsync();
    };

    const deleteExperienceAsync = async (onSuccess, onFail) => {
        try {
            // eslint-disable-next-line no-unused-vars
            const { data } = await deleteExperience(selectedExperience.id);
            onSuccess();
        } catch (error) {
            if (error.response) {
                if (error.response.data.error_code.toString() === '23000') {
                    setErrorMessage(dictionary.errors['23000']);
                } else {
                    setErrorMessage(error.response.data.message);
                }
            }
            onFail();
        }
    };

    const setSelectedShareExperience = (exprience) => {
        setSelectedExperience(exprience);
        setShareDialogVisibility(true);
    };

    const setSelectedDeleteExperience = (experience) => {
        setSelectedExperience(experience);
        setDeleteDialogVisibility(true);
    };

    const onEditClicked = (id) => {
        setEditId(id);
        setShowNewExperience(true);
    };

    const onSortOptionValueChanged = (option) => {
        setSelectedOption(option);
    };

    const handleOpenDetailDialog = (viewUrl) => {
        setSelectedViewUrl(viewUrl);
    };

    const handleCloseDetailDialog = () => {
        setSelectedViewUrl(null);
    };
    const renderExperiences = () => {
        if (viewType === 0) {
            return (
                <>
                    {experiences.map((experience) => (
                        <ExperienceListItemFull
                            key={experience.id}
                            experience={experience}
                            setSelectedAddGroup={setSelectedAddGroup}
                            viewExperienceHandler={handleOpenDetailDialog}
                            allLanguages={allLanguages}
                        />
                    ))}
                </>
            );
        } else {
            return (
                <>
                    {experiences.map((experience) => (
                        <ExperienceListItemCompact
                            key={experience.id}
                            experience={experience}
                            setSelectedAddGroup={setSelectedAddGroup}
                            viewExperienceHandler={handleOpenDetailDialog}
                            allLanguages={allLanguages}
                        />
                    ))}
                </>
            );
        }
    };

    const onViewTypeChanged = (type) => {
        setViewType(type);
    };
    const onPagingParameterChanged = (type, newParameter) => {
        if (type === 'page') {
            setCurrentPage(newParameter);
        } else if (type === 'page-size') {
            setPageSize(newParameter);
        }
    };

    const showFiterlDialogWithMode = () => {
		setShowFilterDialog(true);
	};

    const countAllFilter = () => {
		let filterCountAll = 0;
		const providerIds =
			JSON.parse(localStorage.getItem('admin_experiences_filter_provider')) || [];
		filterCountAll += providerIds.length;
		setFilterCount(filterCountAll);
	};

    const onProviderValuesChanged = () => {
		countAllFilter();
	};

    const onScheduleSet = () => {
        const providerIds = JSON.parse(localStorage.getItem('admin_experiences_filter_provider')) || [];
        setFilterProviderParams(providerIds);

        getAllExperiencesAsync();
		
		// getSlotsAsync(rangeStart, rangeEnd, experienceIds, accessIds, isBooking);
	};

    const setSelectedAddGroup = (experience) => {
		setSelectedExperience(experience);
		setGroupDialogVisibility(true);
	};

    const createNewGroup = () => {
		setGroupDialogVisibility(false);
        setGroupInfoType('create');
		setShowGroupInfo(true);
    }

    const addGroup = async (groupId) => {
		try{
			await addProviderGroup(groupId,selectedExperience.id);
			setSuccessMessage(
				dictionary.experience_added
			);
			setTimeout(() => {
				setSuccessMessage('');
			},3000);
		}
		catch (error) {
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const removeGroup = async (groupId) => {
         try{
			await removeProviderGroup(groupId,selectedExperience.id);
			setSuccessMessage(
				dictionary.experience_removed
			);
			setTimeout(() => {
				setSuccessMessage('');
			}, 3000);
		}
		catch (error) {
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const groupSaveEvent = async (group) => {
		addGroup(group.id);
		setGroupInfoType(null);
		setShowGroupInfo(false);
	};

    const onSearchValueChanged = (value) => {
        if(value === '') {
            setCurrentPage(0)
        }
        setSearchParam(value);
    }

    return (
        <div className="experiences-wrapper">
            <div className="experiences-top-bar">
                <h2 data-testid="experiences-title">{dictionary.experiences}</h2>
                <div className="flex-filler" />
                <div className="experiences-filter-actions">
                    <SearchLocation onValueChanged={onSearchValueChanged} />
                    <div className="experiences-filter booking-exp-admin-filter">
                        <IconButton onClickIcon={showFiterlDialogWithMode} activeState={showFilterDialog}>
                        <div
                            className="experiences-filter-box"
                        >
                            <img className="icon-filter" src={iconFilter} />
                            {filterCount > 0 ? (
                                <span>
                                    {dictionary.filters} ({filterCount})
                                </span>
                            ) : (
                                <span>{dictionary.filters}</span>
                            )}
                        </div>
                        </IconButton>
                    </div>
                    <SortOptionsSelector
                        onValueChanged={onSortOptionValueChanged}
                        name={'experiences'}
                    />
                    <div style={{ width: '16px' }} />
                    <ViewTypeSelector
                        onValueChanged={onViewTypeChanged}
                        name={'experiences'}
                    />
                </div>
                <div className="experiences-mobile-actions">
                    <div className="booking-input-search booking-exp-admin-search">
                        <SearchLocation onValueChanged={onSearchValueChanged} />
                    </div>
                </div>
            </div>

            <div className="experiences-mobile-filter-actions">
                <SortOptionsSelector
                    startPosition="left"
                    onValueChanged={onSortOptionValueChanged}
                    name={'experiences'}
                />
                <div style={{ width: '16px' }} />
                <ViewTypeSelector
                    onValueChanged={onViewTypeChanged}
                    name={'experiences'}
                />
            </div>
            {experiences?.length === 0 ? (
                <div className="empty-experiences-list-wrapper">
                    <div className="empty-experiences-list-placeholder">
                        <div className="empty-experiences-image" />
                        <h3
                            style={{ textAlign: 'center', marginTop: '24px' }}
                            data-testid="no-experiences-yet-label"
                        >
                            {dictionary.no_experiences_yet}
                        </h3>
                        <div style={{ marginTop: '8px' }} className="text">
                            {dictionary.all_experiences_will_be_listed}
                        </div>
                    </div>
                </div>
            ) : null}
            {experiences?.length > 0 ? (
                <div
                    className={
                        viewType === 0
                            ? 'experience-items-wrapper thin-scrollbar'
                            : 'experience-items-wrapper thin-scrollbar tile-experience-items-wrapper'
                    }
                >
                    {renderExperiences()}
                </div>
            ) : null}
            <ExperienceDetailDialog
                viewUrl={selectedViewUrl}
                showDialog={Boolean(selectedViewUrl)}
                setShowDialog={handleCloseDetailDialog}
            />
            <PaginationController
                name="experiences"
                onParameterChanged={onPagingParameterChanged}
                dataLength={dataLength}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
            />
            <FormLoader showLoader={showLoader} showEnter={false} />
            {showNewExperience ? (
                <NewExperience
                    editId={editId}
                    onExperienceSaved={onExperienceSaved}
                    tempId={tempId}
                    tempData={tempData}
                />
            ) : null}
            {showShareDialog ? (
                <ShareExperienceDialog
                    setShareDialogVisibility={setShareDialogVisibility}
                    shareUrl={selectedExperience.share_url}
                />
            ) : null}
            {showSharesDialog ? (
                <ShareExperiencesDialog
                    setShareDialogVisibility={setSharesDialogVisibility}
                    shareUrl={shareUrl}
                />
            ) : null}
            {showDeleteDialog ? (
                <ConfirmationModal
                    onActionFinished={() => getAllExperiencesAsync()}
                    setShowModal={setDeleteDialogVisibility}
                    asyncTask={deleteExperienceAsync}
                    title={dictionary.delete_experience}
                    message={dictionary.delete_experience_message}
                    successMessage={dictionary.experience_deleted}
                    errorMessage={errorMessage}
                    action={dictionary.delete}
                />
            ) : null}
            {showFilterDialog ? (
				<FilterDialog
                    onProviderValuesChanged={onProviderValuesChanged}
					setShowFilterDialog={setShowFilterDialog}
					filterCount={filterCount}
					countAllFilter={countAllFilter}
					setFilterProviderParams={setFilterProviderParams}
					onScheduleSet={onScheduleSet}
				/>
			) : null}
            {showGroupDialog && selectedExperience ? (
				<AddGroupDialog
					actionType={'Experience'}
					setAddGroupDialogVisibility={setGroupDialogVisibility}
					experience={selectedExperience}
					addGroup={addGroup}
					removeGroup={removeGroup}
					createNewGroup={createNewGroup}
				/>
			) : null }
            {showGroupInfo ? (
                <GroupInfo
                    type={groupInfoType}
                    showDialog={showGroupInfo}
                    setShowDialog={setShowGroupInfo}
                    groupSaveEvent={groupSaveEvent}
                    editId={editId}
                />
            ) : null}
        </div>
    );
};

AdminProviderExperiences.propTypes = {
    setWarningMessage: PropTypes.func
};

export default connect(common.states, common.actions)(AdminProviderExperiences);
