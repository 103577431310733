import React from 'react';
import loader from '../../../assets/lottie/upiria_loader.json';
import '../../../assets/css/dialogs.css';
import Lottie from 'lottie-react';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from 'react';
import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const FormLoader = ({
	showLoader,
	showEnter = true,
	rounded = false,
	showMessage = false,
	message = null,
	onDismissClicked
}) => {
	const dictionary = useDictionary();
	const [classes, setClasses] = useState('d-none');
	const [messageOpacity, setMessageOpacity] = useState(0);

	function hideView() {
		setClasses('d-none');
	}

	// eslint-disable-next-line no-unused-vars
	function showView(_node) {
		setClasses('d-flex');
	}

	useEffect(() => {
		if (showMessage) {
			setTimeout(() => {
				setMessageOpacity(1);
			}, 10);
		}
	}, [showMessage]);

	return (
		<CSSTransition
			in={showLoader}
			timeout={300}
			classNames={
				showEnter
					? {
							enterActive: 'animate__fadeIn',
							exitActive: 'animate__fadeOut'
					  }
					: {
							exitActive: 'animate__fadeOut'
					  }
			}
			onEnter={showView}
			onExited={hideView}
		>
			<div
				style={{ borderRadius: rounded ? '8px' : '0' }}
				className={`form-loader-container animate__animated  ${classes}`}
			>
				{!showMessage ? (
					<div className="lottie-container">
						<Lottie loop={true} animationData={loader} />
					</div>
				) : null}
				<div
					style={{
						display: showMessage ? 'flex' : 'none',
						opacity: messageOpacity
					}}
					className="loader-message-container"
				>
					<h2>{message}</h2>
					<div
						onClick={() => onDismissClicked()}
						className="secondary-button"
						style={{ marginTop: '20px' }}
					>
						{dictionary.dismiss}
					</div>
				</div>
			</div>
		</CSSTransition>
	);
};

FormLoader.propTypes = {
	showLoader: PropTypes.bool,
	showEnter: PropTypes.bool,
	rounded: PropTypes.bool,
	showMessage: PropTypes.bool,
	message: PropTypes.string,
	onDismissClicked: PropTypes.func
};

export default FormLoader;
