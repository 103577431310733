import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import ProvidedItemRow from './ProvidedItemRow';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const ProvidedItems = ({ setEnabled, providedItems, setProvidedItems }) => {
	const dictionary = useDictionary();

	const onItemChanged = (e, index) => {
		let newItems = Object.assign([], providedItems);
		newItems[index] = e.target.value;
		setProvidedItems(newItems);
	};

	const onAddItemClicked = () => {
		let newItems = Object.assign([], providedItems);
		newItems.push('');
		setProvidedItems(newItems);
	};

	const onRemoveItemClicked = (item) => {
		let newItems = Object.assign([], providedItems);
		ArrayHelper.removeItemOnce(newItems, item);
		setProvidedItems(newItems);
	};

	useEffect(() => {
		setEnabled(providedItems.length > 0 && !providedItems.includes(''));
	}, [providedItems]);

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container">
				<h2>{dictionary.what_will_you_provide}</h2>
				<div className="text">{dictionary.you_can_provide}</div>
				<div className="provided-items-container">
					{providedItems.map((item, index) => (
						<ProvidedItemRow
							key={index}
							onRemoveItemClicked={onRemoveItemClicked}
							index={index}
							value={item}
							setValue={onItemChanged}
						/>
					))}
				</div>
				<div
					onClick={onAddItemClicked}
					className="secondary-button"
					data-testid="add-item-button"
				>
					{dictionary.add_another_item}
				</div>
			</div>
		</div>
	);
};

ProvidedItems.propTypes = {
	setEnabled: PropTypes.func.isRequired,
	providedItems: PropTypes.array.isRequired,
	setProvidedItems: PropTypes.func.isRequired
};

export default ProvidedItems;
