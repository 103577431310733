import { useDictionary } from '../../../../../../../../utilities/hooks/UseDictionary';
import React from 'react';
import PropTypes from 'prop-types';
import ResellerItem from "./ResellerItem";

const Resellers = ({ providers }) => {
	const dictionary = useDictionary();

	return (
		<div className="detail-tab-container thin-scrollbar fade-on-load">
			<div
				className="participant-row-container"
				style={{ justifyContent: 'space-between', marginBottom: '20px' }}
			>
				<h3 data-testid="participant-groups-header">
					{ dictionary.resellers }
				</h3>
				<div
					style={{ fontWeight: 'normal', opacity: '0.6' }}
					className="booking-number"
				>
				</div>
			</div>
			{providers?.map((provider) => (
				<ResellerItem key={provider.id} provider={provider} />
			))}
		</div>
	);
};

Resellers.propTypes = {
	providers: PropTypes.array
};

export default Resellers;
