import logo from '../../../../../../../assets/images/logo_white_letter.svg';
import ImageHelper from '../../../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
import useComponentVisible from '../../../../../../../utilities/hooks/useComponentVisible';
import ResourceSelectItem from './ResourceSelectItem';
import PropTypes from 'prop-types';
import React from 'react';

const ResourceSelect = ({ value, setValue, items, label, showSubtitle }) => {
	const dictionary = useDictionary();

	const onItemSelected = (item) => {
		setIsComponentVisible(!isComponentVisible);
		setValue(item);
	};

	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	return (
		<div ref={ref} className="custom_select_wrapper">
			<label>{label}</label>
			{showSubtitle ? (
				<div className="text" style={{ marginBottom: '12px' }}>
					{dictionary.used_resource_message}
				</div>
			) : null}
			<div
				onClick={() => setIsComponentVisible(!isComponentVisible)}
				className={
					isComponentVisible
						? 'custom-select custom-experience-select custom-select-active'
						: 'custom-select custom-experience-select'
				}
			>
				<div className="custom-experience-image-container">
					<img
						onError={(e) => {
							e.currentTarget.src = logo;
						}}
						src={ImageHelper.getFullImageUrl(value?.assets?.[0]?.thumbnail_url)}
						className={
							value?.id === -1
								? 'custom-experience-image experience-icon-disabled'
								: 'custom-experience-image'
						}
						style={{ display: value ? 'block' : 'none' }}
					/>
				</div>
				<div className="custom-experience-title">{value?.title}</div>
			</div>
			<div
				style={{
					opacity: isComponentVisible ? '1' : '0',
					display: isComponentVisible ? 'flex' : 'none'
				}}
				className="custom-select-items-container experience-select-items-container thin-scrollbar"
			>
				{items.map((item) => (
					<ResourceSelectItem
						key={item.id}
						item={item}
						onItemSelected={onItemSelected}
						selectedItem={value}
					/>
				))}
			</div>
		</div>
	);
};

ResourceSelect.propTypes = {
	value: PropTypes.object,
	setValue: PropTypes.func,
	items: PropTypes.array,
	label: PropTypes.string,
	showSubtitle: PropTypes.bool
};

export default ResourceSelect;
