import * as auth from '../app/containers/auth/__redux/authRedux';
import * as common from '../app/containers/common/__redux/commonRedux';
import { combineReducers } from 'redux';

/*export const rootReducer = combineReducers({
    auth: auth.reducer,
    members: members.reducer,
    blockui: () => { return false; }
    // members: members.reducer
});*/

const allReducers = combineReducers({
	auth: auth.reducer,
	common: common.reducer
});

export const rootReducer = (state, action) => {
	switch (action.type) {
		case 'update_profile': {
			const { payload } = action;
			const { user, account } = state.auth;
			const newUser = {
				...user,
				first_name: payload.first_name,
				last_name: payload.last_name,
				email: payload.email
			};

			const newAccount = {
				...account,
				brand_name: payload.brand_name,
				company_photo: payload.company_photo,
				address1: payload.address1,
				address2: payload.address2,
				city: payload.city,
				profile_photo: payload.profile_photo,
				phone: payload.phone,
				region: payload.region,
				short_description: payload.short_description,
				tax_id_number: payload.tax_id_number,
				website: payload.website,
				verification_status: payload.verification_status
			};

			const auth = {
				...state.auth,
				user: newUser,
				account: newAccount
			};

			return {
				...state,
				auth
			};
		}

		case 'logout': {
			const common = {
				...state.common,
				components: null
			};
			const auth = {
				...state.auth,
				components: null
			};
			return allReducers(
				{
					...state,
					auth,
					common
				},
				action
			);
		}

		case 'setComponents': {
			const components = action.payload.data;
			const common = {
				...state.common,
				routes: null,
				components
			};
			const auth = {
				...state.auth,
				components
			};
			return allReducers(
				{
					...state,
					auth,
					common
				},
				action
			);
		}

		case 'login': {
			// eslint-disable-next-line no-unused-vars
			const { user, account, oauth, components } = action.payload.auth;
			const common = {
				...state.common,
				account,
				user,
				components
			};
			// local storage company photo path
			localStorage.setItem('company_photo_path', account?.company_photo?.path);

			return allReducers(
				{
					...state,
					common
				},
				action
			);
		}

		default: {
			return allReducers(state, action);
		}
	}
};
