import loader from '../../../assets/lottie/upiria_loader.json';
import '../../../assets/css/dialogs.css';
import Lottie from 'lottie-react';
import { CSSTransition } from 'react-transition-group';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const PageLoader = ({ showLoader, showEnter = true }) => {
	const [classes, setClasses] = useState('d-none');

	function hideView() {
		setClasses('d-none');
	}

	// eslint-disable-next-line no-unused-vars
	function showView(_node) {
		setClasses('d-flex');
	}

	return (
		<CSSTransition
			in={showLoader}
			timeout={300}
			classNames={
				showEnter
					? {
							enterActive: 'animate__fadeIn',
							exitActive: 'animate__fadeOut'
					  }
					: {
							exitActive: 'animate__fadeOut'
					  }
			}
			onEnter={showView}
			onExited={hideView}
		>
			<div className={`page-loader-container animate__animated  ${classes}`}>
				<div className="lottie-container">
					<Lottie loop={true} animationData={loader} />
				</div>
			</div>
		</CSSTransition>
	);
};

PageLoader.propTypes = {
	showLoader: PropTypes.bool,
	showEnter: PropTypes.bool
};

export default PageLoader;
