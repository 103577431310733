import '../../../../assets/css/resale.css';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CodeIcon } from '../../../../assets/icons/ic_code.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import SortOptionsSelector from '../../common/sort_options/SortOptionsSelector';
import ViewTypeSelector from '../../common/ViewTypeSelector';
import PaginationController from '../../common/pagination_controller/PaginationController';
import FormLoader from '../../common/FormLoader';
import ShareExperienceDialog from '../provider_experiences/ShareExperienceDialog';
import ShareExperiencesDialog from '../provider_experiences/ShareExperiencesDialog';
import * as common from '../../common/__redux/commonRedux';
import { connect } from 'react-redux';
import axios from 'axios';
import { getResaleCollection, removeExperience,addProviderGroup,removeProviderGroup} from './networking';
import ExperienceListItemFull from './components/ExperienceListItemFull';
import ExperienceListItemCard from './components/ExperienceListItemCard';
import ConfirmationModal from '../../common/ConfirmationModal';
import ResaleExperienceButtons from './components/ResaleExperienceButtons';
import ResaleExperienceDetails from './components/ResaleExperienceDetails';
import ExperienceDetailDialog from './components/ExperienceDetailDialog';
import AddGroupDialog from '../groups/AddGroupDialog';
import GroupInfo from '../groups/group_dialog/GroupInfo';


const ResaleCollection = ({setSuccessMessage}) => {
	const source = axios.CancelToken.source();
	const dictionary = useDictionary();

	const [collection, setCollection] = useState([]);
	const [isEmptyCollection, setIsEmptyCollection] = useState(true);
	const [selectedExperience, setSelectedExperience] = useState(null);
	
	// Modals
	const [showLoader, setShowLoader] = useState(false);
	const[showGroupDialog,setShowGroupDialogVisibility]=useState(false);
	const [showShareDialog, setShareDialogVisibility] = useState(false);
	const [showSharesDialog, setSharesDialogVisibility] = useState(false);
	const [showDeleteDialog, setDeleteDialogVisibility] = useState(false);
	const [shareUrl, setShareUrl] = useState();

	
	const [errorMessage, setErrorMessage] = useState(
		dictionary.problem_removing_experience
	);
	const [selectedViewUrl, setSelectedViewUrl] = useState(null);

	// Sorting
	const [selectedOption, setSelectedOption] = useState(null);

	// Listing view type
	const [viewType, setViewType] = useState(
		parseInt(localStorage.getItem('resale_collection_selected_view_type') ?? 0)
	);

	// Pagination
	const [dataLength, setDataLength] = useState(0);
	const [currentPage, setCurrentPage] = useState(0);
	const [pageSize, setPageSize] = useState(
		parseInt(localStorage.getItem('experiences_selected_page_size') ?? 10)
	);
    
	//add experience to group
	const [showGroupInfo, setShowGroupInfo] = useState(false);
    const [groupInfoType, setGroupInfoType] = useState(null);
	

	useEffect(() => {
		if (selectedOption) {
			getCollectionAsync();
		}
	}, [pageSize, currentPage, selectedOption]);

	useEffect(() => {
		if (!showShareDialog) {
			setSelectedExperience(null);
		}
	}, [showShareDialog]);

	const getCollectionAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getResaleCollection(
				selectedOption,
				pageSize,
				currentPage,
				source.token
			);
			setIsEmptyCollection(data.data.length === 0);
			setDataLength(data.recordsTotal);
			setCollection(data.data);
			setShareUrl(data.share_url);
		} catch (e) {
			// todo check logger
		} finally {
			setShowLoader(false);
		}
	};

	const removeExperienceAsync = async (onSuccess, onFail) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await removeExperience(selectedExperience.experience_id);
			onSuccess();
		} catch (error) {
			if (error.response) {
				if (error.response.data.error_code.toString() === '23000') {
					setErrorMessage(dictionary.errors['23000']);
				} else {
					setErrorMessage(dictionary.problem_removing_experience);
				}
			}
			onFail();
		}
	};

	const generateWidget = () => {
		if (!isEmptyCollection) {
			setSharesDialogVisibility(true);
		}
	};

    const setSelectedAddGroup = (experience) => {
		setSelectedExperience(experience);
		setShowGroupDialogVisibility(true);
	};

	const displayShareExperienceModal = (experience) => {
		setSelectedExperience(experience);
		setShareDialogVisibility(true);
	};

	const displayRemoveExperienceModal = (experience) => {
		setSelectedExperience(experience);
		setDeleteDialogVisibility(true);
	};

	const onSortOptionValueChanged = (option) => {
		setSelectedOption(option);
	};
	const onViewTypeChanged = (type) => {
		setViewType(type);
	};

	const handleOpenDetailDialog = (viewUrl) => {
		setSelectedViewUrl(viewUrl);
	};

	const handleCloseDetailDialog = () => {
		setSelectedViewUrl(null);
	};

	const onPagingParameterChanged = (type, newParameter) => {
		if (type === 'page') {
			setCurrentPage(newParameter);
		} else if (type === 'page-size') {
			setPageSize(newParameter);
		}
	};
      
const addGroup = async (groupId) => {
	
		try{
			await addProviderGroup(groupId,selectedExperience.experience_id);
		    setSuccessMessage(
				dictionary.experience_added
			);
			setTimeout(() => {
				setSuccessMessage('');
			}, 3000);
		}
		catch (error) {
			
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const removeGroup = async (groupId) => {
		try{
			await removeProviderGroup(groupId,selectedExperience.experience_id);
		    setSuccessMessage(
				dictionary.experience_removed
			);
			setTimeout(() => {
				setSuccessMessage('');
			}, 3000);
		}
		catch (error) {
		
			setErrorMessage(
				'Something went wrong'
			);
			setTimeout(() => {
				setErrorMessage('');
			}, 3000);
		}
    }

    const createNewGroup = () => {
		setShowGroupDialogVisibility(false);
        setGroupInfoType('create');
		setShowGroupInfo(true);
    }
	const groupSaveEvent = async (group) => {
		addGroup(group.id);
		setGroupInfoType(null);
		setShowGroupInfo(false);
	};


	// Children components
	const getActionButtons = (experience) => {
		const ActionButtons = ({ children }) => {
			return (
				<ResaleExperienceButtons
				    setSelectedAddGroup={setSelectedAddGroup}
					experience={experience}
					viewExperienceHandler={handleOpenDetailDialog}
					shareExperienceHandler={displayShareExperienceModal}
					removeExperienceHandler={displayRemoveExperienceModal}
				/>
			);
		};

		return ActionButtons;
	};

	const getExperienceStatus = (experience) => {
		const ExperienceStatus = ({ children }) => {
			return (
				<div
					className="resale-collection-item-experience-block-status"
					style={{ background: experience.status.color }}
				>
					<div className="resale-collection-item-experience-block-status-title">
						{experience.status.title}
					</div>
				</div>
			);
		};

		return ExperienceStatus;
	};

	const getExperienceDetails = (experience) => {
		const ExperienceDetails = ({ children }) => {
			return (
				<ResaleExperienceDetails
					category={experience.tags.map((tag) => tag.title)}
					location={experience.address}
					language={[]}
					duration={experience.duration}
					capacity={experience.capacity}
					child_friendly={experience.child_friendly}
					accessible={experience.accessible}
				/>
			);
		};

		return ExperienceDetails;
	};
	const renderCollection = () => {
		// Full view
		if (viewType === 0) {
			return (
				<div className="resale-collection-items-wrapper thin-scrollbar">
					{collection.map((experience) => (
						<ExperienceListItemFull
							isResaleCollection
							key={experience.experience_id}
							experience={experience}
							HeaderItems={getExperienceStatus(experience)}
							ActionButtons={getActionButtons(experience)}
							ExperienceDetails={getExperienceDetails(experience)}
						/>
					))}
				</div>
			);
		} else {
			// Card view
			return (
				<div className="resale-collection-items-wrapper resale-collection-items-wrapper-card thin-scrollbar">
					{collection.map((experience) => (
						<ExperienceListItemCard
							isResaleCollection
							key={experience.experience_id}
							experience={experience}
							HeaderItems={getExperienceStatus(experience)}
							ActionButtons={getActionButtons(experience)}
						/>
					))}
				</div>
			);
		}
	};

	return (
		<div className="experiences-wrapper">
			<div className="experiences-top-bar">
				<h2 data-testid="resale-collection-title">
					{dictionary.resale_experience}
				</h2>
				<div className="experiences-actions">
					<div className="experience-create-button">
						<div
							onClick={generateWidget}
							className={`button ${isEmptyCollection && 'button-disabled'}`}
							data-testid="share-resale-collection"
						>
							<span className="code-icon">
								<CodeIcon />
							</span>
							{dictionary.generate_widget}
						</div>
					</div>
				</div>
				<div className="flex-filler" />
				<div className="experiences-filter-actions">
					<SortOptionsSelector
						onValueChanged={onSortOptionValueChanged}
						name={'resale_collection'}
					/>
					<div style={{ width: '16px' }} />
					<ViewTypeSelector
						onValueChanged={onViewTypeChanged}
						name={'resale_collection'}
					/>
				</div>
				<div className="experiences-mobile-actions">
					<div
						onClick={generateWidget}
						className={`button ${isEmptyCollection && 'button-disabled'}`}
						style={{ marginLeft: 16, padding: 14 }}
						data-testid="share-resale-collection"
					>
						<div style={{ height: 16 }}>
							<CodeIcon style={{ height: 16, width: 16 }} />
						</div>
					</div>
				</div>
			</div>
			<div className="experiences-mobile-filter-actions">
				<SortOptionsSelector
					onValueChanged={onSortOptionValueChanged}
					name={'resale_collection'}
				/>
				<div style={{ width: '16px' }} />
				<ViewTypeSelector
					onValueChanged={onViewTypeChanged}
					name={'resale_collection'}
				/>
			</div>
			{isEmptyCollection ? (
				<div className="empty-experiences-list-wrapper">
					<div className="empty-experiences-list-placeholder">
						<div className="empty-experiences-image" />
						<h3
							style={{ textAlign: 'center', marginTop: '24px' }}
							data-testid="no-experiences-yet-label"
						>
							{dictionary.start_adding_experience}
						</h3>
						<div style={{ marginTop: '8px' }} className="text">
							{dictionary.you_will_see_all_of_them_here}
						</div>
					</div>
				</div>
			) : (
				<>{renderCollection()}</>
			)}

			{showGroupDialog && selectedExperience ?
			 (
			<AddGroupDialog
			actionType={'Resale'}
			experience={selectedExperience}
			addGroup={addGroup}
			removeGroup={removeGroup}
			createNewGroup={createNewGroup}
			setAddGroupDialogVisibility={setShowGroupDialogVisibility}
		/>):null}

		{showGroupInfo ? (
				<GroupInfo
					type={groupInfoType}
					showDialog={showGroupInfo}
					setShowDialog={setShowGroupInfo}
					groupSaveEvent={groupSaveEvent}
					
				/>
			) : null}
			
			{selectedViewUrl ? (
			<ExperienceDetailDialog
				viewUrl={selectedViewUrl}
				showDialog={Boolean(selectedViewUrl)}
				setShowDialog={handleCloseDetailDialog}
			/>):null}
			<PaginationController
				name="experiences"
				onParameterChanged={onPagingParameterChanged}
				dataLength={dataLength}
				currentPage={currentPage}
				setCurrentPage={setCurrentPage}
			/>
			<FormLoader showLoader={showLoader} showEnter={false} />
			{showShareDialog ? (
				<ShareExperienceDialog
					setShareDialogVisibility={setShareDialogVisibility}
					shareUrl={selectedExperience.share_url}
				/>
			) : null}
			{showSharesDialog ? (
				<ShareExperiencesDialog
					setShareDialogVisibility={setSharesDialogVisibility}
					shareUrl={shareUrl}
				/>
			) : null}
			{showDeleteDialog ? (
				<ConfirmationModal
					onActionFinished={() => getCollectionAsync()}
					setShowModal={setDeleteDialogVisibility}
					asyncTask={removeExperienceAsync}
					title={dictionary.remove_experience}
					message={dictionary.remove_experience_message}
					successMessage={dictionary.experience_removed}
					errorMessage={errorMessage}
					action={dictionary.remove}
				/>
			) : null}
		</div>
	);
};

export default connect(common.states, common.actions)(ResaleCollection);
