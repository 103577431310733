import { UseCurrencyDecorator } from '../../../../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import React from 'react';

const InvoiceItem = ({ item }) => {
	const currencyDecorator = UseCurrencyDecorator();
	
	return (
		<div
			className="participant-row-container"
			data-testid="customer-invoice-item"
			style={{ justifyContent: 'space-between', marginBottom: '24px' }}
		>
			<h4 style={{ fontWeight: 'normal' }} className='truncate participant-row-container-quty-rate' title={currencyDecorator(item.rate) + ' x ' + item.quantity + ' ' + item.name}>
				{currencyDecorator(item.rate) + ' x ' + item.quantity + ' ' + item.name}
			</h4>
			<h4 style={{ fontWeight: 'normal' }}>
				{currencyDecorator(item.quantity * item.rate)}
			</h4>
		</div>
	);
};

InvoiceItem.propTypes = {
	item: PropTypes.object.isRequired
};

export default InvoiceItem;
