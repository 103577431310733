import logo from '../../../../../../assets/images/logo_white_letter.svg';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const LinkedExperienceItem = ({ item, items, setItems }) => {
	const [hasError, setHasError] = useState(false);

	const onItemSelected = () => {
		let itemsNew = Object.assign([], items);
		let itemNewIndex = itemsNew.findIndex(
			(experience) => experience.id === item.id
		);
		itemsNew[itemNewIndex].selected = !itemsNew[itemNewIndex].selected;
		setItems(itemsNew);
	};

	return (
		<div
			style={{ marginBottom: '8px' }}
			onClick={() => onItemSelected()}
			className={
				item.selected
					? 'custom-select-item linked-experience-item custom-select-item-selected'
					: 'linked-experience-item custom-select-item'
			}
		>
			<div
				className="custom-experience-image-container"
				style={{ width: '44px', height: '44px' }}
			>
				<img
					onError={(e) => {
						e.currentTarget.src = logo;
						setHasError(true);
					}}
					src={ImageHelper.getFullImageUrl(item?.assets?.[0]?.thumbnail_url)}
					className="custom-experience-image"
				/>
				{!hasError ? (
					<div
						className="custom-experience-image-color"
						style={{ background: item?.color }}
					/>
				) : null}
			</div>
			<div className="custom-experience-title">{item?.title}</div>
		</div>
	);
};

LinkedExperienceItem.propTypes = {
	item: PropTypes.object,
	items: PropTypes.array,
	setItems: PropTypes.func,
};

export default LinkedExperienceItem;
