import { useEffect, useState } from 'react';
import balloon from '../../../../assets/images/air_balloon_illustration.svg';
import boat from '../../../../assets/images/boat_illustration.svg';
import logo from '../../../../assets/images/logo_with_wordmark.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';

const NoItemsYetDialog = ({ setDialogVisibility, mode }) => {
	const [opacity, setOpacity] = useState(0);
	const dictionary = useDictionary();
	const history = useHistory();

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setDialogVisibility(false);
		}, 800);
	};

	const reroute = () => {
		history.push(
			mode === 'experience' ? 'experiences' : 'provider-resources'
		);
	};

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div
				className="dialog-container dialog-container-wide"
				data-testid="no-items-yet-dialog"
			>
				<img src={mode === 'experience' ? balloon : boat} />

				<div className="dialog-container-wide-content">
					<img style={{ height: '28px' }} src={logo} />

					<div
						onClick={() => onCancelClicked()}
						style={{ position: 'absolute', top: '40px', right: '40px' }}
						className="dialog-close-button"
					/>

					<div className="flex-filler" />
					<h2
						style={{
							fontSize: '24px',
							fontWeight: '500'
						}}
					>
						{mode === 'experience'
							? dictionary.no_experiences_yet
							: dictionary.no_resources_yet}
					</h2>
					<div style={{ marginTop: '12px' }} className="text">
						{mode === 'experience'
							? dictionary.create_your_first_experience
							: dictionary.create_your_first_resource}
					</div>

					<div className="flex-filler" />
					<div style={{ display: 'flex', gap: '20px' }}>
						<div
							className="button"
							onClick={() => reroute()}
							data-testid="no-items-yet-dialog-create-button"
						>
							{mode === 'experience'
								? dictionary.create_experience
								: dictionary.create_resource}
						</div>
						<div
							className="secondary-button"
							onClick={() => onCancelClicked()}
							data-testid="no-items-yet-dialog-cancel-button"
						>
							{dictionary.do_it_later}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

NoItemsYetDialog.propTypes = {
	setDialogVisibility: PropTypes.func.isRequired,
	mode: PropTypes.string.isRequired
};

export default NoItemsYetDialog;
