import success from '../../../assets/icons/ic_success.svg';
import React, { useEffect, useState } from 'react';
// import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import { connect } from 'react-redux';
import * as common from './__redux/commonRedux';
import PropTypes from 'prop-types';

const SuccessModal = ({
	setSuccessDialogVisibility,
	successMessage,
	setSuccessMessage
}) => {
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 10);
	}, []);

	const closeDialog = () => {
		setOpacity(0);
		setTimeout(() => {
			setSuccessMessage('');
			setSuccessDialogVisibility(false);
		}, 300);
	};

	return (
		<div
			className="dialog-wrapper"
			style={{ opacity: opacity }}
			onClick={() => {
				closeDialog();
			}}
		>
			<div
				className="dialog-container dialog-container-narrow"
				onClick={(e) => e.stopPropagation()}
				style={{ padding: '24px', alignItems: 'center', gap: '12px' }}
			>
				<div
					className="dialog-close-button"
					style={{ top: '12px' }}
					onClick={closeDialog}
				/>
				<img
					className="modal-large-icon"
					style={{ marginTop: '24px' }}
					src={success}
				/>
				<h4>{successMessage}</h4>
			</div>
		</div>
	);
};

SuccessModal.propTypes = {
	setSuccessDialogVisibility: PropTypes.func,
	successMessage: PropTypes.string,
	setSuccessMessage: PropTypes.func
};

export default connect(common.states, common.actions)(SuccessModal);
