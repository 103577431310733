import { React } from "react";
import PropTypes from "prop-types";
import { useDictionary } from "../../../../../../../../utilities/hooks/UseDictionary";
import { TextFormatHelper } from "../../../../../../../../utilities/helpers/TextFormatHelper";

const BookingNotes = ({ details }) => {
    const dictionary = useDictionary();

    return (
        <div className="detail-tab-container thin-scrollbar fade-on-load">
            <h3 data-testid="booking-note-header" className="booking-note-header">
                {dictionary.booking_notes}
            </h3>
            <div style={{ marginLeft: 24 }}>
                {Object.keys((typeof details?.notes == 'string' ? JSON.parse(details?.notes) : details?.notes) ?? {}).map((key) => (
                    <div key={key}>
                        <h3 className="booking-tab-content">{dictionary[key] || TextFormatHelper.toUsefulTextFromField(key)}</h3>
                        <ul className="booking-notes">
                            {Object.entries((typeof details?.notes == 'string' ? JSON.parse(details?.notes) : details?.notes)[key] ?? {}).map(([subKey, value]) => (
                                <li key={subKey}>
                                    <b>{dictionary[subKey] || TextFormatHelper.toUsefulTextFromField(subKey)}: </b>{value}
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

        </div>
    );
};
BookingNotes.propTypes = {
    details: PropTypes.object
};


export default BookingNotes;