import axios from 'axios';
import {
	PROVIDER_AUTHENTICATE,
	PROVIDER_BALANCE,
	// PROVIDER_BOOKINGS,
	PROVIDER_PAYOUTS, PROVIDER_STRIPE_LOGIN, PROVIDER_WITHDRAW,
	// PROVIDERS_EXPERIENCES_SLOTS_URL,
	PROVIDERS_ME_URL,
	PROVIDERS_URL
} from '../../../../config';
import { DataTableHelper } from '../../../../utilities/helpers/DataTableHelper';

export function getProviderInfo(cancelToken) {
	return axios.get(PROVIDERS_URL + '/me', { cancelToken: cancelToken });
}

export function getFinancialOverview() {
	return axios.get(PROVIDER_BALANCE);
}

export function withdrawAmount() {
	return axios.post(PROVIDER_WITHDRAW);
}

export function getUpcomingSlots() {
	return axios.get(PROVIDERS_ME_URL + '/slots/upcoming');
}

export function getBookingRequests() {
	return axios.get(PROVIDERS_ME_URL + '/booking-requests');
}

export function updateBookingRequestState(bookingRequestId, data) {
	return axios.put(PROVIDERS_ME_URL + `/booking-status/${bookingRequestId}`, data)
}
export function updateLegalInfo() {}

export function getProviderPayouts(startDate, endDate) {
	if (startDate !== '' && endDate !== '') {
		const params = new URLSearchParams([
			['date_from', new Date(startDate).getTime() / 1000],
			['date_to', new Date(endDate).getTime() / 10000]
		]);
		return axios.get(PROVIDER_PAYOUTS, { params });
	}

	return axios.get(PROVIDER_PAYOUTS);
}

export function authenticateProvider() {
	return axios.post(PROVIDER_AUTHENTICATE);
}

export function stripeLogin() {
	return axios.get(PROVIDER_STRIPE_LOGIN);
}
