import PropTypes from 'prop-types';
import React from 'react';

const ProvidedItemRow = ({ index, value, setValue, onRemoveItemClicked }) => {
	return (
		<div key={index} className="provided-item-row">
			<div
				className="group-inputs"
				style={{ width: '100%', marginTop: '0', marginBottom: '0' }}
			>
				<input
					type="text"
					value={value}
					onChange={(e) => setValue(e, index)}
					data-testid="provided-item-input"
				/>
			</div>
			<div
				onClick={() => onRemoveItemClicked(value)}
				className="dialog-document-row-delete"
				data-testid="remove-item-button"
			/>
		</div>
	);
};

ProvidedItemRow.propTypes = {
	index: PropTypes.number.isRequired,
	value: PropTypes.string.isRequired,
	setValue: PropTypes.func.isRequired,
	onRemoveItemClicked: PropTypes.func.isRequired
};

export default ProvidedItemRow;
