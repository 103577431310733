import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import SlotPricingItem from './SlotPricingItem';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CURRENCY } from '../../../../../../config';

const SlotPricing = ({ details }) => {
	const dictionary = useDictionary();

	const [groups, setGroups] = useState(null);

	const getGroups = () => {
		let groups = {};
		details?.participant_groups?.map((group) => {
			groups[group?.name] = +group?.rate / 100 + CURRENCY;
		});

		setGroups(groups);
	};

	useEffect(() => {
		getGroups();
	}, []);

	return (
		<div className="settings-page-container thin-scrollbar fade-on-load">
			<>
				<div
					className="participant-row-container"
					style={{ justifyContent: 'space-between', marginBottom: '20px' }}
					data-testid="pricing-header"
				>
					<h3>{dictionary.participants_pricing}</h3>
				</div>

				{groups
					? Object?.keys(groups)?.map((key) => (
							<SlotPricingItem key={key} title={key} value={groups[key]} />
					  ))
					: null}

				{details?.rates?.length > 0 && (
					<>
						<div
							className="participant-row-container"
							style={{
								justifyContent: 'space-between',
								marginTop: '8px',
								marginBottom: '20px'
							}}
							data-testid="pricing-header"
						>
							<h3>{dictionary.group_rates}</h3>
						</div>
						{details?.rates?.map(({ min_size, max_size, discount }, index) => (
							<SlotPricingItem
								key={index}
								title={`${dictionary.group_size} ${min_size}-${max_size}`}
								value={`-${discount}%`}
							/>
						))}
					</>
				)}
			</>
		</div>
	);
};

SlotPricing.propTypes = {
	details: PropTypes.object
};

export default SlotPricing;
