import { CDN_URL } from '../../config';

const ImageHelper = (function () {
	function getFullImageUrl(imageUrl) {
		return imageUrl ? CDN_URL + imageUrl : null;
	}

	/**
	 * Replace the category image url with the large
	 * E.g. Adventure.png -> Adventure-large.png
	 *
	 * @param imageUrl
	 * @returns {string}
	 */
	function getLargeCategoryImageUrl(imageUrl){
		const imageExtension = imageUrl?.split('.').pop();
		const largeImageUrl = imageUrl?.replace(
			'.' + imageExtension,
			'-large.' + imageExtension,
		);

		return getFullImageUrl(largeImageUrl);
	}

	return {
		getFullImageUrl: function (imageUrl) {
			return getFullImageUrl(imageUrl);
		},
		getLargeCategoryImageUrl: function (imageUrl){
			return getLargeCategoryImageUrl(imageUrl);
		}
	};
})();

export default ImageHelper;
