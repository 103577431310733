import { useState } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import ExperienceSelectItem from './ExperienceSelectItem';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import logo from '../../../../../../assets/images/logo_white_letter.svg';
import PropTypes from 'prop-types';
import React from 'react';

const ExperienceSelect = ({ value, setValue, items }) => {
	const dictionary = useDictionary();

	const [showOptions, setShowOptions] = useState(false);
	const [hasError, setHasError] = useState(false);

	const onItemSelected = (item) => {
		setHasError(false);
		setShowOptions(false);
		setValue(item);
	};

	return (
		<div className="custom_select_wrapper">
			<label>{dictionary.experience}</label>
			<div
				onClick={() => setShowOptions(!showOptions)}
				className={
					showOptions
						? 'custom-select custom-experience-select custom-select-active'
						: 'custom-select custom-experience-select'
				}
				data-testid="experience-select"
			>
				<div className="custom-experience-image-container">
					<img
						onError={(e) => {
							e.currentTarget.src = logo;
						}}
						src={ImageHelper.getFullImageUrl(value?.assets?.[0]?.thumbnail_url)}
						className="custom-experience-image"
						style={{ display: value ? 'block' : 'none' }}
					/>
					{!hasError ? (
						<div
							className="custom-experience-image-color"
							style={{ background: value?.color }}
						/>
					) : null}
				</div>
				<div className="custom-experience-title" data-testid="experience-title">
					{value?.title}
				</div>
			</div>
			<div
				style={{
					opacity: showOptions ? '1' : '0',
					display: showOptions ? 'flex' : 'none'
				}}
				className="custom-select-items-container experience-select-items-container thin-scrollbar"
			>
				{items.map((item) => (
					<ExperienceSelectItem
						item={item}
						key={item.id}
						onItemSelected={onItemSelected}
						selectedItem={value}
					/>
				))}
			</div>
		</div>
	);
};

ExperienceSelect.propTypes = {
	value: PropTypes.object,
	setValue: PropTypes.func,
	items: PropTypes.array
};

export default ExperienceSelect;
