export function detectMobile() {
	if (
		/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
			navigator.userAgent
		)
	) {
		return true;
	} else {
		return window.innerWidth < 986;
	}
}

export function detectIpad() {
	return /iPad/i.test(navigator.userAgent);
}

export function detectMobile2() {
	return window.innerWidth < 986;
}
