import { shallowEqual, useSelector } from 'react-redux';

export const useAccount = () => {
	const { account } = useSelector(
		({ auth }) => ({
			account: auth.account
		}),
		shallowEqual
	);
	return account;
};
