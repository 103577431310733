import PropTypes from 'prop-types';
import React from 'react';

const ExperienceCalendarColor = ({ color, selectedColor, setColorCode }) => {
	return (
		<div
			onClick={() => setColorCode(color)}
			className={
				selectedColor === color
					? 'experience-calendar-color-container experience-calendar-color-container-active'
					: 'experience-calendar-color-container'
			}
			data-testid="experience-calendar-color-container"
		>
			<div
				style={{ backgroundColor: color }}
				className="experience-calendar-color"
			/>
			<div className="experience-calendar-check" />
		</div>
	);
};

ExperienceCalendarColor.propTypes = {
	color: PropTypes.string.isRequired,
	selectedColor: PropTypes.string.isRequired,
	setColorCode: PropTypes.func.isRequired
};

export default ExperienceCalendarColor;
