import React, { useState, useEffect } from 'react';
import iconSearch from '../../../../../../assets/icons/ic_search.svg';
import iconRemove from '../../../../../../assets/icons/ic_remove.svg';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const SearchInput = ({onValueChanged}) => {
    const dictionary = useDictionary();
    const [searchValue, setSearchValue] = useState('');
    const [removeClass, setRemoveClass] = useState('btn btn-remove-search-value d-none');
    const [boxInputSearchClass, setBoxInputSearchClass] = useState('booking-input-search');

    useEffect(() => {
		if (onValueChanged) {
			onValueChanged(searchValue);
		}
	}, []);

    const handleChange = (event) => {
        setSearchValue(event.target.value);
        if (event.target.value !== '') {
            setRemoveClass('btn btn-remove-search-value d-block');
        } else {
            setRemoveClass('btn btn-remove-search-value d-none');
        }
    }

	const handleClear = () => {
        setSearchValue('');
        setRemoveClass('btn btn-remove-search-value d-none');
        onValueChanged('');
    }

    const handSubmit = () => {
        onValueChanged(searchValue);
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            onValueChanged(searchValue);
        }
    }

    const handleFocus = () => {
        setBoxInputSearchClass('booking-input-search selected');
    }

    const handleBlur = () => {
        setBoxInputSearchClass('booking-input-search');
    }

    return (
        <div className={boxInputSearchClass}>
            <a href="#" onClick={handSubmit} className="btn btn-icon" aria-label="Button">
                <img src={iconSearch} />
            </a>
            <input
                type="text"
                value={searchValue} 
                onChange={handleChange} 
                onKeyDown={handleKeyDown}
                onFocus={handleFocus}
                onBlur={handleBlur}
                className="form-control"
                placeholder={dictionary.search_by_booking_or_customer}
            />
            <a href="#" onClick={handleClear} className={removeClass} aria-label="Button">
                <img id="ic-remove-input-search" src={iconRemove} />
            </a>
        </div>
    );
}

SearchInput.propTypes = {
	onValueChanged: PropTypes.func
};

export default SearchInput;
