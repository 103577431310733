import uk from '../../../../assets/icons/flags/uk.svg';
import React from 'react';
// import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
// import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import pencil from '../../../../assets/icons/ic_pencil.svg';
// import pause from '../../../../assets/icons/ic_pause.svg';
import trash from '../../../../assets/icons/ic_trash.svg';
import { NavLink } from 'react-router-dom';
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import placeholder from '../../../../assets/images/bg_placeholder_square.svg';
// import { changeResourceStatus } from './networking';
import PropTypes from 'prop-types';

const ResourceListItemFull = ({
	resource,
	setSelectedDeleteResource,
	// onStatusChangeCompleted,
	// setShowLoader
}) => {
	// const userType = useUserTypeId();
	// const dictionary = useDictionary();

	// const changeResourceStatusAsync = async (resourceId, newStatus) => {
	// 	setShowLoader(true);
	// 	try {
	// 		const { data } = await changeResourceStatus(resourceId, newStatus);
	// 		onStatusChangeCompleted();
	// 	} catch (error) {
	// 		setShowLoader(false);
	// 	}
	// };

	return (
		<div className="experience-item-compact-container">
			<div className="experience-item-row">
				<div className="experience-flags-container">
					<img src={uk} />
				</div>
			</div>

			<h3 style={{ marginTop: '26px' }}>{resource?.title}</h3>
			<div style={{ marginTop: '10px' }} className="text ellipsize">
				{resource?.description}
			</div>
			<div className="flex-filler" />
			<div className="experience-item-buttons-container">
				<NavLink
					to={'provider-resources/' + resource.id}
					className="experience-item-button-container"
				>
					<img src={pencil} />
				</NavLink>
				{/*<div className='experience-item-button-container'>
                    <img src={pause}/>
                </div>*/}
				<div
					onClick={() => setSelectedDeleteResource(resource)}
					className="experience-item-button-container"
				>
					<img src={trash} />
				</div>
			</div>
			<div className="experience-thumbs-container-compact">
				<img
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					className="uploaded-image-full"
					src={ImageHelper.getFullImageUrl(resource?.thumbnail_url)}
				/>
			</div>
		</div>
	);
};

ResourceListItemFull.propTypes = {
	resource: PropTypes.object,
	setSelectedDeleteResource: PropTypes.func,
	onStatusChangeCompleted: PropTypes.func,
	setShowLoader: PropTypes.func
};

export default ResourceListItemFull;
