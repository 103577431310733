import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import ApplicationDocumentItem from './ApplicationDocumentItem';
import React from 'react';
import {
	changeDocumentStatus,
	changeProviderStatus
	// getProviders
} from './networking';
import PropTypes from 'prop-types';
import moment from 'moment';

const PendingApplicationItem = ({ item, onUpdated, setLoaderVisibility, onProviderClicked }) => {
	const dictionary = useDictionary();

	const getStatusLabel = () => {
		let statuses = {
			1: {
				label: dictionary.unverified,
				class: 'experience-item-label-error'
			},
			2: {
				label: dictionary.pending,
				class: 'experience-item-label-unavailable'
			},
			3: {
				label: dictionary.verified,
				class: 'experience-item-label-active'
			},
			4: {
				label: dictionary.rejected,
				class: 'experience-item-label-error'
			}
		};
		return (
			<div
				className={
					'experience-item-label ' +
					statuses[item.verification_status.value].class
				}
				style={{ width: '100px', textAlign: 'center' }}
				data-testid="status-label"
			>
				{statuses[item.verification_status.value].label}
			</div>
		);
	};

	const getDateCreated = () => {
		return (
			<div>
				<div style={{ color: 'gray' }}>{dictionary.registered_at}</div>
				<div>{moment.unix(item.created_at).format('MMM DD, YYYY')}</div>
			</div>
		);
	};

	const getDaysSinceRegistration = () => {
		const days = moment().diff(moment.unix(item.created_at), 'days');
		return (
			<div>
				<div style={{ color: 'gray' }}>{dictionary.days_since_registration}</div>
				<div>
					{days} {dictionary.days}
				</div>
			</div>
		);
	};

	const changeProviderStatusAsync = async (verify) => {
		setLoaderVisibility(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await changeProviderStatus(item.id, verify);
			onUpdated();
		} catch (error) {
			setLoaderVisibility(false);
		}
	};

	const changeDocumentStatusAsync = async (documentId, status) => {
		setLoaderVisibility(true);
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await changeDocumentStatus(documentId, status);
			onUpdated();
		} catch (error) {
			setLoaderVisibility(false);
		}
	};

	const getChangeStatusButton = () => {
		if (item.verification_status.value === '1') {
			return null;
		}
		return (
			<div
				className="button"
				data-testid="change-status-button"
				style={{ width: '100px' }}
				onClick={() =>
					changeProviderStatusAsync(item.verification_status.value !== '3')
				}
			>
				{item.verification_status.value === '3'
					? dictionary.unverify
					: dictionary.verify}
			</div>
		);
	};

	return (
		<div
			className={'application-item-container'}
			data-testid={'application-item-' + item.id}
		>
			<div className="application-user-profile-info-container thin-scrollbar-trans">
				<div
					className="profile-photo-container application-photo-container"
					style={{ alignSelf: 'center' }}
				>
					<img
						src={item.image_url}
						className="profile-photo application-profile-photo"
						onClick={() => onProviderClicked(item)}
					/>
				</div>
				<label style={{ marginBottom: '4px' }}>{dictionary.name}</label>
				<h4
					style={{ marginBottom: '8px' }}
				>{`${item.first_name} ${item.last_name}`}</h4>
				<label style={{ marginBottom: '4px' }}>{dictionary.email}</label>
				<h4 style={{ marginBottom: '8px' }}>{item.email}</h4>
				<label style={{ marginBottom: '4px' }}>{dictionary.company}</label>
				<h4 style={{ marginBottom: '8px' }}>{item.brand_name}</h4>
				<label style={{ marginBottom: '4px' }}>{dictionary.vat_number}</label>
				<h4 style={{ marginBottom: '8px' }}>{item.tax_id_number}</h4>
				<label style={{ marginBottom: '4px' }}>{dictionary.account_iban}</label>
				<h4 style={{ marginBottom: '8px' }}>{item.account_iban}</h4>
				<label style={{ marginBottom: '4px' }}>
					{dictionary.beneficiary_name}
				</label>
				<h4 style={{ marginBottom: '8px' }}>{item.account_beneficiary}</h4>
			</div>
			<div
				className="dialog-vertical-divider"
				style={{ marginRight: '12px' }}
			/>
			{item.files.length > 0 ? (
				<>
					<div
						className="application-document-column"
						style={{ width: '0', flexGrow: '1' }}
					>
						{item.files.map((item) => (
							<ApplicationDocumentItem
								key={item.id}
								changeDocumentStatusAsync={changeDocumentStatusAsync}
								document={item}
							/>
						))}
					</div>
				</>
			) : (
				<h3 style={{ alignSelf: 'center', width: '0', flexGrow: '1' }}>
					{dictionary.no_files_uploaded_yet}
				</h3>
			)}
			<div
				className="dialog-vertical-divider"
				style={{ marginRight: '12px' }}
			/>
			<div
				className="application-document-column"
				style={{ width: '0', flexGrow: '1', gap: '20px', maxWidth: '140px' }}
			>
				{getDateCreated()}
				{getDaysSinceRegistration()}
				{getStatusLabel()}
				{getChangeStatusButton()}
			</div>
		</div>
	);
};

PendingApplicationItem.propTypes = {
	item: PropTypes.object.isRequired,
	onUpdated: PropTypes.func.isRequired,
	setLoaderVisibility: PropTypes.func.isRequired,
	onProviderClicked: PropTypes.func
};

export default PendingApplicationItem;
