import axios from 'axios';
import CryptoJS from 'crypto-js';
import {
	ADMIN_LOGIN_URL,
	API_URL,
	CONFIRM_EMAIL_URL,
	CUSTOMERS_URL,
	LOGIN_URL,
	LOGOUT_URL,
	REGISTER_URL,
	REQUEST_PASSWORD_URL,
	RESEND_URL,
	SECRET_KEY,
	SECURE_URL,
	SET_PASSWORD_URL
} from '../../../../config';

// User login function
export async function login(email, password, session_id) {
	return await axios.post(LOGIN_URL, {
		email,
		password,
		session_id,
		token: CryptoJS.SHA256(
			email + password + session_id + SECRET_KEY
		).toString()
	});
}

// User logout function
export async function logout(session_id) {
	try {
		const response = await axios.delete(LOGOUT_URL, {
			data: {
				session_id: session_id,
				token: CryptoJS.SHA256(session_id + SECRET_KEY).toString()
			}
		});
		return response;
	} catch (error) {
		// console.error(error);
		return error.response;
	}
}

// Get public key function
export async function getPK(session_id) {
	return await axios.post(SECURE_URL, {
		session_id: session_id,
		token: CryptoJS.SHA256(session_id + SECRET_KEY).toString()
	});
}

// Resend email function
export async function resendEmail(email) {
	return await axios.post(RESEND_URL, {
		email,
		token: CryptoJS.SHA256(email + SECRET_KEY).toString()
	});
}

// Admin login function
// todo we don't have admin login
export async function adminLogin(email, password) {
	return await axios.post(ADMIN_LOGIN_URL, {email, password});
}

// User registration function
export async function register(email, password, session_id, type) {
	return await axios.post(REGISTER_URL, {
		email,
		password,
		session_id,
		type,
		token: CryptoJS.SHA256(
			email + password + session_id + type + SECRET_KEY
		).toString()
	});
}

// Create personal account function
// todo we don't have customers login
export async function createPersonal(data) {
	return await axios.post(CUSTOMERS_URL, data);
}

// Set password function
export async function setPassword(password, session_id) {
	return await axios.post(SET_PASSWORD_URL, {
		password: password,
		session_id: session_id
	});
}

// Get countries function
// todo we don't have countries end point
export async function getCountries() {
	return await axios.get(API_URL + 'countries');
}

// Reset password function
export async function resetPassword(email) {
	return await axios.post(REQUEST_PASSWORD_URL, {
		email,
		token: CryptoJS.SHA256(email + SECRET_KEY).toString()
	});
}

// Verify email function
export async function verifyEmail(hash) {
	return await axios.post(CONFIRM_EMAIL_URL + '/' + hash, {
		token: CryptoJS.SHA256(hash + SECRET_KEY).toString()
	});
}

// Change password function
export async function changePassword(password, hash, session_id) {
	return await axios.post(REQUEST_PASSWORD_URL + '/' + hash, {
		new_password: password,
		session_id: session_id,
		token: CryptoJS.SHA256(
			password + session_id + hash + SECRET_KEY
		).toString()
	});
}

/*export function login(email, password) {
    return axios({
        "method": "POST",
        "url": config.API_URL + "oauth/token",
        "headers": {
            "Content-Type": "application/x-www-form-urlencoded",
            "Authorization": "Basic " + config.API_KEY
        },
        "data": "grant_type="+ config.GRANT_TYPE +"&username=" + email + "&password=" + password
    });

}*/
