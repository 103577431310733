// import { dictionaries } from '../../../../../assets/dictionaries/dictionary';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useFormik } from 'formik';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { countries } from '../../../../../data/countries';
import PropTypes from 'prop-types';

const WizardAddressForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);

	const wizardProfileSchema = Yup.object().shape({
		address_line1: Yup.string().required(dictionary.address_required),
		address_line2: Yup.string(),
		city: Yup.string().required(dictionary.city_required),
		state: Yup.string().required(dictionary.state_required),
		country_id: Yup.string().required(dictionary.country_required)
	});

	const formik = useFormik({
		initialValues: {
			address_line1: props.details.address_line1,
			address_line2: props.details.address_line2,
			city: props.details.city,
			state: props.details.state,
			country_id: props.details.country_id
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, props.details);
			newDetails.address_line1 = values.address_line1;
			newDetails.address_line2 = values.address_line2;
			newDetails.city = values.city;
			newDetails.state = values.state;
			newDetails.country_id = values.country_id;

			props.setDetails(newDetails);
			props.setStep(4);
		},
		validateOnMount: true,
		validationSchema: wizardProfileSchema
	});

	FormikHelper.setFormik(formik);

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	return (
		<form
			style={{ opacity: showForm ? '1' : '0', overflowY: 'auto' }}
			className="login-form-content-container transparent-form thin-scrollbar"
			onSubmit={formik.handleSubmit}
		>
			<h1>{dictionary.step3_title}</h1>
			{/*<div className="text">{dictionary.step3_message}</div>*/}
			{FormikHelper.getInputTemplate(
				'address_line1',
				dictionary.street_and_number,
				'eg. Egnatia 154',
				'text',
				true,
				true
			)}
			{FormikHelper.getInputTemplate(
				'city',
				dictionary.city,
				'eg. Thessaloniki',
				'text',
				true,
				true
			)}
			{FormikHelper.getInputTemplate(
				'state',
				dictionary.state,
				'eg. Thessaloniki',
				'text',
				true,
				true
			)}
			{FormikHelper.getInputTemplate(
				'address_line2',
				dictionary.postal_code,
				'eg. 54636',
				'text',
				false,
				true
			)}
			{FormikHelper.getCountrySelectTemplate(
				'country_id',
				dictionary.country,
				true,
				countries,
				dictionary.select_country,
				false,
				true,
				(value) => formik.setFieldValue('country_id', value)
			)}

			<div className="group-inputs" style={{ marginTop: '30px' }}>
				<button
					data-testid="continue-button"
					type="submit"
					className={formik.isValid ? 'button' : 'button button-disabled'}
				>
					{dictionary.continue}
				</button>
			</div>
		</form>
	);
};

WizardAddressForm.propTypes = {
	details: PropTypes.object.isRequired,
	setDetails: PropTypes.func.isRequired,
	setStep: PropTypes.func.isRequired
};

export default WizardAddressForm;
