import { useDictionary } from '../../../../../../../../utilities/hooks/UseDictionary';
import PaymentItem from './PaymentItem';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { UseCurrencyDecorator } from '../../../../../../../../utilities/hooks/UseCurrencyDecorator';

const Payments = ({ details }) => {
	const dictionary = useDictionary();
	const currencyDecorator = UseCurrencyDecorator();

	const totalPaid = useMemo(() => {
		return details?.payments.reduce((s, c) => {
			return s + c.amount;
		}, 0);
	}, [details]);

	return (
		<div className="detail-tab-container fade-on-load">
			<div
				className="participant-row-container"
				style={{ justifyContent: 'space-between', marginBottom: '20px' }}
			>
				<h3 data-testid="participant-groups-header">
					{dictionary.payment_transaction}
				</h3>
			</div>
			<div className="detail-payments-table-container thin-scrollbar">
				<table
					className="detail-payments-table"
					data-testid="payments-table"
					cellSpacing="0"
					cellPadding="5"
					border="0"
				>
					<thead>
						<tr>
							<td>#</td>
							<td>{dictionary.date}</td>
							<td>{dictionary.type}</td>
							<td>{dictionary.reference}</td>
							<td>{dictionary.card_number}</td>
							<td align="right">{dictionary.amount}</td>
						</tr>
					</thead>
					<tbody>
						{details?.payments?.map((item) => (
							<PaymentItem
								key={item.id}
								item={item}
								date={details.created_at}
							/>
						))}
						<tr>
							<td colSpan={5} align="right">
								{dictionary.total_price}
							</td>
							<td align="right">
								<b>{currencyDecorator(totalPaid)}</b>
							</td>
						</tr>
						<tr>
							<td colSpan={5} align="right">
								{dictionary.remaining}
							</td>
							<td align="right">
								<b>{currencyDecorator(0)}</b>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

Payments.propTypes = {
	details: PropTypes.object
};

export default Payments;
