import { shallowEqual, useSelector } from 'react-redux';
import { dictionaries } from '../../assets/dictionaries/dictionary';

export const useDictionary = () => {
	// eslint-disable-next-line no-unused-vars
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);
	//return user?dictionaryMap[user.language_id]:dictionaryMap[143];
	return dictionaries.enDictionary;
};
