import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React from 'react';

const SlotPricingItem = ({ title, value }) => {
	const dictionary = useDictionary();

	return (
		<div className="slot-participant-pricing-item">
			<div data-testid="pricing-item-title">{title}</div>
			<div data-testid="pricing-item-value">
				{value + ' / ' + dictionary.seat}
			</div>
		</div>
	);
};

SlotPricingItem.propTypes = {
	title: PropTypes.string,
	value: PropTypes.string
};

export default SlotPricingItem;
