import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import calendar from '../../../../../assets/icons/ic_calendar_blue.svg';
import { getUpcomingSlots } from '../networking';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import UpcomingAgendaItem from './UpcomingAgendaItem';
import PropTypes from 'prop-types';

const UpcomingBookings = ({
	setShowLoader,
	onAgendaItemClicked,
	reloadCounter
}) => {
	const dictionary = useDictionary();
	const [agenda, setAgenda] = useState(null);

	const history = useHistory();

	useEffect(() => {
		getBookingsAsync();
	}, [reloadCounter]);

	const getBookingsAsync = async () => {
		setShowLoader(true);
		try {
			let result = await getUpcomingSlots();
			setAgenda(result.data.data);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	return (
		<>
			<div className="home-content-container" style={{ width: '100%' }}>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						maxWidth: '1260px'
					}}
				>
					<h2 style={{ marginBottom: '10px' }}>{dictionary.upcoming_agenda}</h2>
					<div
						className="tertiary-button"
						style={{ background: 'transparent', gap: '8px' }}
						onClick={() => history.push('/calendar')}
						data-testid="see-calendar-button"
					>
						<img src={calendar} />
						{dictionary.see_calendar}
					</div>
				</div>

				{agenda?.map((item) => (
					<UpcomingAgendaItem
						key={item.id}
						onAgendaItemClicked={onAgendaItemClicked}
						item={item}
					/>
				))}

				{agenda?.length === 0 ? (
					<div
						className="empty-experiences-list-wrapper"
						style={{ height: '80%', maxWidth: '1260px' }}
					>
						<div className="empty-experiences-list-placeholder">
							<div className="empty-experiences-image empty-bookings-image" />
							<h3 style={{ textAlign: 'center', marginTop: '24px' }}>
								{dictionary.no_upcoming_bookings}
							</h3>
							<div style={{ marginTop: '8px' }} className="text">
								{dictionary.all_bookings_will_be_listed}
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

UpcomingBookings.propTypes = {
	setShowLoader: PropTypes.func,
	onAgendaItemClicked: PropTypes.func,
	reloadCounter: PropTypes.number
};

export default UpcomingBookings;
