import '../../../../assets/css/dialogs.css';
import '../../../../assets/css/login.css';
import React, { useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const DocumentsOnBoardingDialog = (props) => {
	const dictionary = useDictionary();
	const from_wizard = +localStorage.getItem('from_wizard') || 0
	const [showDialog, setShowDialog] = useState(true);

	const hideDialog = () => {
		setShowDialog(false);
		setTimeout(() => {
			props.hideDocumentsOnBoarding();
		}, 800);
	};

	const showUpload = async () => {
		setShowDialog(false);
		setTimeout(async () => {
			props.hideDocumentsOnBoarding();
			props.showUploadDialog(true);
			// props.setShowLoader(true);
			// let response = await authenticateProvider();
			// let stripeUrl = response.data.url;
			// window.location.href = stripeUrl;
		}, 800);
	};

	return (
		<div
			style={{ opacity: showDialog ? '1' : '0' }}
			className="dialog-wrapper fade-visible"
		>
			<div
				className="onboarding-dialog-container"
				data-testid="documents-onboarding-dialog"
			>
				<div className="onboarding-dialog-info-container">
					<div className="login-logo" />
					<div className="title-divider" />
					{from_wizard === 1 ?
						<h1>{dictionary.submit_documents_title}</h1>
						:
						<h1>{dictionary.submit_documents_title_2}</h1>
					}
					<div className="text">{dictionary.submit_documents_text}</div>
					<div className="onboarding-dialog-buttons-container">
						<button
							onClick={showUpload}
							className="button"
							data-testid="submit-documents-button"
						>
							{dictionary.lets_do_this}
						</button>
						<button
							onClick={hideDialog}
							className="secondary-button"
							data-testid="do-it-later-button"
						>
							{dictionary.skip_for_now}
						</button>
					</div>
				</div>
				<div className="onboarding-dialog-side-image" />
			</div>
		</div>
	);
};

DocumentsOnBoardingDialog.propTypes = {
	hideDocumentsOnBoarding: PropTypes.func.isRequired,
	showUploadDialog: PropTypes.func.isRequired,
	setShowLoader: PropTypes.func.isRequired,
};

export default DocumentsOnBoardingDialog;
