import pencil from '../../../../../assets/icons/ic_pencil.svg';
import share from '../../../../../assets/icons/ic_share.svg';
import trash from '../../../../../assets/icons/ic_trash.svg';
import play from '../../../../../assets/icons/ic_play.svg';
import pause from '../../../../../assets/icons/ic_pause.svg';
import PropTypes from 'prop-types';
import React from 'react';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import placeholder from '../../../../../assets/images/bg_placeholder_square.svg';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { useHistory } from 'react-router-dom';
import { useAccount } from "../../../../../utilities/hooks/UseAccount";
import { ReactComponent as ResaleIcon } from '../../../../../assets/icons/ic_resale.svg';
import { ReactComponent as ExperiencesIcon } from '../../../../../assets/icons/ic_experience.svg';
import { changeGroupStatus } from './networking';

const GroupListItemFull = ({
	group,
	setSelectedShareGroup,
	setSelectedDeleteGroup,
	onEditClicked,
	setShowLoader,
	setWarningMessage,
	onStatusChangeCompleted
}) => {
	const dictionary = useDictionary();
	const history = useHistory();
	const account = useAccount();

	const getEditButton = () => {
		return (
			<div
				onClick={() => onEditClicked(group.id)}
				className="group-item-button-container"
				data-testid="group-item-edit-button"
			>
				<img src={pencil} />
				<h4 style={{ fontSize: '12px' }}>{dictionary.edit}</h4>
			</div>
		);
	};

	const getDeleteButton = () => {
		return (
			<div
				onClick={() => setSelectedDeleteGroup(group)}
				className="group-item-button-container"
				data-testid="group-item-delete-button"
			>
				<img src={trash} />
				<h4 style={{ fontSize: '12px' }}>{dictionary.delete}</h4>
			</div>
		);
	};

	const getShareButton = () => {
		// const isVerified = accountConfig?.verification_status?.key === 'verified';
		const hasAssets = group?.experiences.length > 0;
		const handleShareClick = () => {
			if (hasAssets) {
				setSelectedShareGroup(group);
			} else {
				setWarningMessage(dictionary.empty_group_message);
			}
		};

		return (
			<div
				onClick={(group.experiences.length) ? handleShareClick : null}
				className={`group-item-button-container${(group.experiences.length) ? '' : ' disabled'}`}
				data-testid="group-item-share-button"
			>
				<img src={share} />
				<h4 style={{ fontSize: '12px' }}>{dictionary.share}</h4>
			</div>
		);
	};
	
	const getPauseButton = () => {
		if (group?.is_active === true) {
			return (
				<div
					onClick={() => changeGroupStatusAsync(group.id, false)}
					className="experience-item-button-container"
					data-testid="experience-item-pause-button"
				>
					<img src={pause} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.pause}</h4>
				</div>
			);
		} else if (group?.is_active === false) {
			return (
				<div
					onClick={() => changeGroupStatusAsync(group.id, true)}
					className="experience-item-button-container"
					data-testid="experience-item-play-button"
				>
					<img src={play} />
					<h4 style={{ fontSize: '12px' }}>{dictionary.go_live}</h4>
				</div>
			);
		}
		else {
			return null;
		}
	};

	const changeGroupStatusAsync = async (groupId, newStatus) => {
		setShowLoader(true);
		try {
			await changeGroupStatus(groupId, newStatus);
			onStatusChangeCompleted();
		} catch (error) {
			setShowLoader(false);
		}
	};

	const renderImages = () => {
		const totalSlots = 5;
		let filledImages = [ ...Array(Math.max(6, 0)).fill({ thumbnail_url: placeholder })];
		if(group.experiences.length>0){
			filledImages = [
			...group?.experiences.slice(0, totalSlots),
			...Array(Math.max(totalSlots - group?.experiences.length, 0)).fill({ thumbnail_url: placeholder })
			];
		}

		return (
			<div className='image-wrapper-group image-wrapper-group-full-view'>
				{ 
					filledImages.map((image, index) => (
						<img key={index}
								className="group-thumb"
								alt="group-thumb"
								onError={(e) => {
									e.currentTarget.src = placeholder;
								}}
								src={
									image
										? ImageHelper.getFullImageUrl(
											image.thumbnail_url
										)
										: placeholder
								}
							/>
					))
				}
			</div>
		);
	}

	const renderEmptyState = () => (
		<div className="empty-state-container">
			<h3 className="empty-group-title" style={{ textAlign: 'center' }}>{dictionary.add_experiences}</h3>
			<div className="empty-group-description">{dictionary.empty_group_message}</div>
			<div style={{ display: 'flex' }}>
				<div className="empty-group-button" style={{ cursor: 'pointer' }} onClick={() => history.push('/admin-experiences')}>  <ExperiencesIcon />  {dictionary.go_to_experience} </div>
				{/* <div className="empty-group-button disabled" style={{ cursor: 'pointer' }} onClick={() => history.push('/resale/collection')}> <ResaleIcon />  {dictionary.go_to_resale} </div> */}
			</div>
		</div>
	);

	return (
		<div key={group?.id} className="group-item-full-container group-item-full-container1">
			<div className="group-item-basic-info-container item-list-container">
				<div>
					<div className="flex-filler-exp">
						<h3>{group.title}</h3>
					</div>
					<div
						className="group-item-row"
						style={{ maxWidth: 'unset', marginTop: 8 }}
					>
						<div style={{ opacity: '0.6', fontSize: 13, lineHeight: '16px' }} className="text ellipsize">
							{group.experiences.length} Items
						</div>
					</div>
					<div className="group-item-buttons-container-full">
						{getEditButton()}
						{getShareButton()}
						{getDeleteButton()}
						{getPauseButton()}
					</div>
				</div>
				<div className={
					group.experiences.length> 0
						? 'group-thumbs-container'
						: 'group-thumbs-container group-thumbs-container-empty'
				}>
					{group.experiences.length > 0 ? renderImages() : renderEmptyState()}
				</div>
			</div>
		</div>
	);
}

GroupListItemFull.propTypes = {
	group: PropTypes.object.isRequired,
	setSelectedShareGroup: PropTypes.func.isRequired,
	setSelectedDeleteGroup: PropTypes.func.isRequired,
	onEditClicked: PropTypes.func.isRequired,
	setShowLoader: PropTypes.func.isRequired,
	setWarningMessage: PropTypes.func.isRequired,
	onStatusChangeCompleted: PropTypes.func,
};

export default GroupListItemFull;