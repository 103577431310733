import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';

import { connect } from 'react-redux';
import * as common from '../../common/__redux/commonRedux';
import PropTypes from 'prop-types';
import axios from 'axios';

import caretRightFilter from '../../../../assets/icons/ic_caret_right_filter.svg';
import caretDown from '../../../../assets/icons/ic_caret_down.svg';
import icCalendar from '../../../../assets/icons/ic_calendar_title.svg';
import icRemoveFilter from '../../../../assets/icons/ic_remove_filter.svg';
import icExperience from '../../../../assets/icons/ic_experience.svg';
import icAccess from '../../../../assets/icons/ic_access.svg';
import icStatus from '../../../../assets/icons/ic_status.svg';

import { useBookingStatuses } from '../../../../utilities/hooks/useBookingStatuses';
import { useExperienceSlotAccess } from '../../../../utilities/hooks/useExperienceSlotAccess';
import { useExperienceSlotStatuses } from '../../../../utilities/hooks/useExperienceSlotStatuses';
import SelectFilterAccess from './SelectFilterAccess';
import SelectFilterExperiences from './SelectFilterExperiences';
import SelectFilterSlot from './SelectFilterSlot';
import {
	getAvailableAndPauseExperiencesList
} from '../provider_experiences/networking';

const FilterDialog = (props) => {
	const dictionary = useDictionary();
	const bookingStatuses = useBookingStatuses();
	const asccessStatuses = useExperienceSlotAccess();
	const slotStatuses = useExperienceSlotStatuses();
	const [date, setDate] = useState('');

	const [showDialog, setDialogVisibility] = useState(false);
	const [enableScheduling, setEnableScheduling] = useState(false);

	const [imgCreateDateCarret, setImgCreateDateCarret] = useState(caretRightFilter);
	const [showCreateDateInput, setShowCreateDateInput] = useState(false);
	const [showStatusSelectbox, setShowStatusSelectbox] = useState(false);
	const [showBoxFilter, setShowBoxFilter] = useState(true);
	
	const [statusNameSelecteds, setStatusNameSelecteds] = useState([]);
	const [statusCheckeds, setStatusCheckeds] = useState([]);

	const [accessNameSelecteds, setAccessNameSelecteds] = useState([]);
	const [accessCheckeds, setAccessCheckeds] = useState([]);

	const [slotNameSelecteds, setSlotNameSelecteds] = useState([]);
	const [slotCheckeds, setSlotCheckeds] = useState([]);

	const [showExperienceSelectbox, setShowExperienceSelectbox] = useState(false);
	const [showAccessSelectbox, setShowAccessSelectbox] = useState(false);
	const [showSlotSelectbox, setShowSlotSelectbox] = useState(false);
	const [experiences, setExperiences] = useState([]);

	const [experienceNameSelecteds, setExperienceNameSelecteds] = useState([]);
	const [experienceCheckeds, setExperienceCheckeds] = useState([]);
	const [experienceStatuses, setExperienceStatuses] = useState({});
	const [bookingCheckboxChecked, setBookingCheckboxChecked] = useState(false);

	const source = axios.CancelToken.source();
	

	const onFinishClicked = () => {
		props.onScheduleSet();
		onCancelClicked();
	};
	
	useEffect(() => {
		setTimeout(() => {
			setDialogVisibility(true);
		}, 50);

		getAccessSelected();
		getSlotSelected();
		getExperienceSelected();
		getBookingCheckboxChecked();
		getAllExperiencesAsync();
		if (props.filterCount > 0) {
			setEnableScheduling(true);
		}
	}, []);

	const onCancelClicked = () => {
		dismissDialog();
	};

	const dismissDialog = () => {
		setDialogVisibility(false);
		setTimeout(() => {
			props.setShowFilterDialog(false);
		}, 600);
	};

	const getAllExperiencesAsync = async () => {
		try {
			const { data } = await getAvailableAndPauseExperiencesList(source.token);
			if (data.data.length > 0) {
				setExperiences(data.data);
				let experienceObjs = {};
				for (const experience of data.data) {
					const experienceItem = {
						id: experience.id,
						text: experience.title,
						class: experience.id,
					}
					
					experienceObjs[experience.id] = experienceItem;
				}
				localStorage.setItem('calendar_filter_experience_list', JSON.stringify(experienceObjs));
				setExperienceStatuses(experienceObjs);
			}  else {
				// props.onNoResults();
			}
		} catch (error) {
			//
		}
	};

	const getExperienceSelected = () => {
		const experienceIds = JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		let expStatuses = experienceStatuses;
		if (Object.keys(experienceStatuses).length === 0) {
			expStatuses = JSON.parse(localStorage.getItem('calendar_filter_experience_list')) || {};
			setExperienceStatuses(expStatuses);
		}
		let experienceSelecteds = [];
		Object.keys(expStatuses).map((experienceStatus) => {
			if (experienceIds.length > 0) {
				if (experienceIds.includes(experienceStatus)) {
					experienceSelecteds[experienceStatus] = expStatuses[experienceStatus];
				}
			}
		});

		setExperienceNameSelecteds(experienceSelecteds);

		let experienCheckedElms = [];
		Object.keys(expStatuses).map((experienceStatus) => {
			if (experienceIds.length === 0) {
				experienCheckedElms[experienceStatus] = false;
			} else {
				if (experienceIds.includes(experienceStatus)) {
					experienCheckedElms[experienceStatus] = true;
				}
			}
		});

		setExperienceCheckeds(experienCheckedElms);
	}

	const getAccessSelected = () => {
		const accessIds = JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		let accessSelecteds = [];
		Object.keys(asccessStatuses).map((asccessStatus) => {
			if (accessIds.length > 0) {
				if (accessIds.includes(asccessStatus)) {
					accessSelecteds[asccessStatus] = asccessStatuses[asccessStatus];
				}
			}
		});

		setAccessNameSelecteds(accessSelecteds);

		let accessCheckedElms = [];
		Object.keys(asccessStatuses).map((asccessStatus) => {
			if (accessIds.length === 0) {
				accessCheckedElms[asccessStatus] = false;
			} else {
				if (accessIds.includes(asccessStatus)) {
					accessCheckedElms[asccessStatus] = true;
				}
			}
		});

		setAccessCheckeds(accessCheckedElms);
	}
	
	const getSlotSelected = () => {
		const slotIds = JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		let slotSelecteds = [];
		Object.keys(slotStatuses).map((slotStatus) => {
			if (slotIds.length > 0) {
				if (slotIds.includes(slotStatus)) {
					slotSelecteds[slotStatus] = slotStatuses[slotStatus];
				}
			}
		});
		
		setSlotNameSelecteds(slotSelecteds);

		let slotCheckedElms = [];
		Object.keys(slotStatuses).map((slotStatus) => {
			if (slotIds.length === 0) {
				slotCheckedElms[slotStatus] = false;
			} else {
				if (slotIds.includes(slotStatus)) {
					slotCheckedElms[slotStatus] = true;
				}
			}
		});

		setSlotCheckeds(slotCheckedElms);
	}

	const showExperienceBox = () => {
		if (showExperienceSelectbox) {
			setShowExperienceSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowExperienceSelectbox(true);
			setShowBoxFilter(false);
		}
	}

	const showAccessBox = () => {
		if (showAccessSelectbox) {
			setShowAccessSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowAccessSelectbox(true);
			setShowBoxFilter(false);
		}
	}

	const showSlotBox = () => {
		if (showSlotSelectbox) {
			setShowSlotSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowSlotSelectbox(true);
			setShowBoxFilter(false);
		}
	}

	const onFilterExperienceSelected = (statusParams) => {
		props.setFilterExperienceParams(statusParams);
		props.onExperienceValuesChanged();
		getExperienceSelected();
	};

	const onFilterAccessSelected = (accessParams) => {
		props.setFilterAccessParams(accessParams);
		props.onAccessValuesChanged();
		getAccessSelected();
	};

	const onFilterSlotSelected = (slotParams) => {
		props.setFilterSlotParams(slotParams);
		props.onSlotValuesChanged();
		getSlotSelected();
	};

	const removeExperienceFilter = (experienceId) => {
		let experienceIds = JSON.parse(localStorage.getItem('calendar_filter_experiences')) || [];
		if (experienceIds.includes(experienceId)) { 
			for( var i = 0; i < experienceIds.length; i++) { 
				if ( experienceIds[i] === experienceId) { 
					experienceIds.splice(i, 1); 
				}
			}
		}

		if (experienceIds.length > 0) {
			localStorage.setItem('calendar_filter_experiences', JSON.stringify(experienceIds));
		} else {
			localStorage.removeItem('calendar_filter_experiences');
		}

		props.onExperienceValuesChanged();
		getExperienceSelected();
		setEnableScheduling(true);
	}

	const removeAccessFilter = (accessId) => {
		let accessIds = JSON.parse(localStorage.getItem('calendar_filter_access')) || [];
		if (accessIds.includes(accessId)) { 
			for( var i = 0; i < accessIds.length; i++) { 
				if ( accessIds[i] === accessId) { 
					accessIds.splice(i, 1); 
				}
			}
		}

		if (accessIds.length > 0) {
			localStorage.setItem('calendar_filter_access', JSON.stringify(accessIds));
		} else {
			localStorage.removeItem('calendar_filter_access');
		}

		props.onAccessValuesChanged();
		getAccessSelected();
		setEnableScheduling(true);
	}

	const removeSlotFilter = (slotId) => {
		let slotIds = JSON.parse(localStorage.getItem('calendar_filter_slot')) || [];
		if (slotIds.includes(slotId)) { 
			for( var i = 0; i < slotIds.length; i++) { 
				if ( slotIds[i] === slotId) { 
					slotIds.splice(i, 1); 
				}
			}
		}

		if (slotIds.length > 0) {
			localStorage.setItem('calendar_filter_slot', JSON.stringify(slotIds));
		} else {
			localStorage.removeItem('calendar_filter_slot');
		}

		props.onSlotValuesChanged();
		getSlotSelected();
		setEnableScheduling(true);
	}

	const setBookingCheckboxCheckedChoose = (itemKey) => {
        setBookingCheckboxChecked(!itemKey);
        itemKey = !itemKey;
        
		if (itemKey) {
			console.log(bookingCheckboxChecked)
			localStorage.setItem('calendar_filter_booking', true);
		} else {
			localStorage.removeItem('calendar_filter_booking');
		}
		
		setEnableScheduling(true);
		props.onBookingFilterChanged();
    }

	const getBookingCheckboxChecked = () => {
		const booking = JSON.parse(localStorage.getItem('calendar_filter_booking')) || false;
		setBookingCheckboxChecked(booking);
	}

	const handleResetAll = () => {
		localStorage.removeItem('calendar_filter_experiences');
		localStorage.removeItem('calendar_filter_access');
		localStorage.removeItem('calendar_filter_booking');
		localStorage.removeItem('calendar_filter_slot');
		props.onExperienceValuesChanged();
		props.onAccessValuesChanged();
		props.onSlotValuesChanged();
		props.onBookingFilterChanged();
		getAccessSelected();
		getBookingCheckboxChecked();
		getExperienceSelected();
		getSlotSelected();
		setShowAccessSelectbox(false);
		setShowExperienceSelectbox(false);
		setShowBoxFilter(true);
		setEnableScheduling(false);
		props.setFilterExperienceParams([]);
		props.setFilterAccessParams([]);
		props.setFilterSlotParams([]);
		props.onScheduleSet();
	}

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ background: 'transparent', position: 'fixed', zIndex: '11' }}
			data-testid="schedule-dialog-wrapper"
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={
					showDialog
						? 'experience-category-tags-wrapper experience-category-tags-wrapper-visible'
						: 'experience-category-tags-wrapper'
				}
				data-testid="schedule-dialog"
			>
				<div className="schedule-dialog-content-wrapper thin-scrollbar">
					<div
						className="dialog-close-button"
						style={{ right: '24px', top: '21px' }}
						onClick={onCancelClicked}
						data-testid="schedule-dialog-close-button"
					/>
					
					<div className="schedule-dialog-content-container schedule-dialog-content-container-pa-0">
						<div className="schedule-dialog-content-container-content border-bottom">
							<h2 style={{ whiteSpace: 'pre-line', fontSize: '16px', lineHeight: '24px', fontWeight: 500 }}>
								Filters
							</h2>
						</div>
						
						<div className="schedule-dialog-content-container-content">
							{showBoxFilter ? (
							<div className="box-filter-list">
								<div className="group-filter">
									<div className="group-filter-icon-label">
										<img className="group-filter-icon" src={icExperience} />
										<span onClick={showExperienceBox} className="group-filter-label">{dictionary.experience}</span>
									</div>
									<img onClick={showExperienceBox} className="icon-to-select" src={caretRightFilter} />
								</div>
								
								<div className="group-filter-selected-status">
									{Object.keys(experienceNameSelecteds).map((experienceNameSelected) => (
										<div className="filter-selected-item" key={experienceNameSelected}>
											<span>{experienceNameSelecteds[experienceNameSelected].text}</span>
											<img onClick={() => removeExperienceFilter(experienceNameSelected)} src={icRemoveFilter} />
										</div>
									))}
								</div>

								<div className="group-filter">
									<div className="group-filter-icon-label">
										<img className="group-filter-icon" src={icAccess} />
										<span onClick={showAccessBox} className="group-filter-label">{dictionary.access}</span>
									</div>
									<img onClick={showAccessBox} className="icon-to-select" src={caretRightFilter} />
								</div>
								
								<div className="group-filter-selected-status">
									{Object.keys(accessNameSelecteds).map((accessNameSelected) => (
										<div className="filter-selected-item" key={accessNameSelected}>
											<span>{accessNameSelecteds[accessNameSelected].text}</span>
											<img onClick={() => removeAccessFilter(accessNameSelected)} src={icRemoveFilter} />
										</div>
									))}
								</div>

								<div className="group-filter">
									<div className="group-filter-icon-label">
										<img className="group-filter-icon" src={icStatus} />
										<span onClick={showSlotBox} className="group-filter-label">{dictionary.slot_status}</span>
									</div>
									<img onClick={showSlotBox} className="icon-to-select" src={caretRightFilter} />
								</div>

								<div className="group-filter-selected-status">
									{Object.keys(slotNameSelecteds).map((slotNameSelected) => (
										<div className="filter-selected-item" key={slotNameSelected}>
											<span>{slotNameSelecteds[slotNameSelected].text}</span>
											<img onClick={() => removeSlotFilter(slotNameSelected)} src={icRemoveFilter} />
										</div>
									))}
								</div>

								<div className="group-filter group-filter-booking">
									<div onClick={() => setBookingCheckboxCheckedChoose(bookingCheckboxChecked)}
										className="form-check-container" data-testid="booking" style={{ marginLeft: '0' }}>
										<div className={
											'custom-checkbox ' +
											(bookingCheckboxChecked
												? 'custom-checkbox-checked'
												: 'custom-checkbox')
										} style={{ width: '16', height: '16' }}></div>
										<label className="checkbox-label" style={{ marginBottom: 0, marginLeft: '10px', fontWeight: '600' }}>{dictionary.booking}</label>
										<input
											name="booking"
											type="checkbox"
											style={{ display: 'none' }}
											checked={bookingCheckboxChecked}
											readOnly={true}
											data-testid="booking"
										/>	
									</div>
								</div>
							</div>
							): null}
							
							{showExperienceSelectbox ? (
								<SelectFilterExperiences
									setShowBoxFilter={setShowBoxFilter} 
									setShowExperienceSelectbox={setShowExperienceSelectbox} 
									onFilterExperienceSelected={onFilterExperienceSelected}
									experienceCheckeds={experienceCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
									experiences={experiences}
								/>
							) : null}

							{showAccessSelectbox ? (
								<SelectFilterAccess
									setShowBoxFilter={setShowBoxFilter} 
									setShowAccessSelectbox={setShowAccessSelectbox} 
									onFilterAccessSelected={onFilterAccessSelected}
									accessCheckeds={accessCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
								/>
							) : null}

							{showSlotSelectbox ? (
								<SelectFilterSlot
									setShowBoxFilter={setShowBoxFilter} 
									setShowSlotSelectbox={setShowSlotSelectbox} 
									onFilterSlotSelected={onFilterSlotSelected}
									slotCheckeds={slotCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
								/>
							) : null}
						</div>
					</div>
				</div>

				<div className="filter-dialog-button-container">
					<button
						onClick={() => handleResetAll()}
						className="schedule-dialog-reset-button"
						data-testid="schedule-dialog-reset-button"
					>
						Reset
					</button>
					<button
						disabled={!enableScheduling}
						onClick={() => onFinishClicked()}
						className="button filter-dialog-apply-button"
						data-testid="filter-dialog-apply-button"
					>
						Apply
					</button>
				</div>
			</div>
		</div>
	);
};

FilterDialog.propTypes = {
	onCreateDateValueChanged: PropTypes.func,
	onStatusValuesChanged: PropTypes.func,
	onAccessValuesChanged: PropTypes.func,
	onSlotValuesChanged: PropTypes.func,
	onBookingFilterChanged: PropTypes.func,
	setShowFilterDialog: PropTypes.func,
	countAllFilter: PropTypes.func,
	filterCount: PropTypes.number,
	setFilterExperienceParams: PropTypes.func,
	setFilterAccessParams: PropTypes.func,
	setFilterSlotParams: PropTypes.func,
	onScheduleSet: PropTypes.func
};

export default connect(common.states, common.actions)(FilterDialog);
