import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

const NewParticipantGroupDialog = ({
	setVisibility,
	paticipantGroup,
	onCreateClicked
	// onRemoveItemClicked
}) => {
	const dictionary = useDictionary();
	const [group, setGroup] = useState({});
	const wrapper = useRef();

	const onNameChanged = (e) => {
		let newGroup = Object.assign({}, group);
		newGroup.name = e.target.value;
		setGroup(newGroup);
	};

	const onAgeChanged = (e) => {
		let newGroup = Object.assign({}, group);
		if (e.target.id === 'from') {
			newGroup.min_age = e.target.value;
		} else {
			newGroup.max_age = e.target.value;
		}

		setGroup(newGroup);
	};

	useEffect(() => {
		wrapper.current.style.opacity = 1;
	}, []);

	const onClose = () => {
		wrapper.current.style.opacity = 0;
		setTimeout(() => {
			setVisibility(false);
		}, 800);
	};

	const onCreate = () => {
		if (group.name) {
			if (!group.rate) {
				group.rate = 5;
			}
			onCreateClicked(group);
			onClose();
		}
	};

	useEffect(() => {
		if (paticipantGroup) {
			setGroup(paticipantGroup);
		}
	}, [paticipantGroup]);

	return (
		<div ref={wrapper} style={{ opacity: '0' }} className="dialog-wrapper">
			<div className="dialog-container dialog-container-narrow" data-testid='new-group-dialog'>
				<div className="dialog-title-container">
					<div className="dialog-title">
						{paticipantGroup
							? dictionary.edit_participant_group
							: dictionary.create_new_participant_group}
					</div>
					<div className="dialog-close-button" onClick={onClose} />
				</div>
				<div className="dialog-title-divider" />

				<div className="dialog-content-container">
					<h3 style={{ fontSize: '16px', marginTop: '8px' }}>
						{paticipantGroup
							? dictionary.name_of_participant_group
							: dictionary.name_of_participant_new_group}
					</h3>
					<div style={{ marginTop: '10px' }} className="text">
						{dictionary.make_sure_name_clear}
					</div>
					<div className="group-inputs">
						<input type="text" value={group.name} onChange={onNameChanged} data-testid='new-group-name'/>
					</div>
					<h3 style={{ fontSize: '16px', marginTop: '8px' }}>
						{paticipantGroup
							? dictionary.whats_the_age_group
							: dictionary.whats_the_age_new_group}
					</h3>
					<div style={{ display: 'flex', width: '100%', gap: '20px' }}>
						<div className="group-inputs" style={{ width: '0', flexGrow: '1' }}>
							<label style={{ marginBottom: '8px' }}>{dictionary.from}</label>
							<input
								min="1"
								id="from"
								onChange={onAgeChanged}
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								value={group.min_age}
								type="number"
								data-testid="from-input"
							/>
						</div>
						<div className="group-inputs" style={{ width: '0', flexGrow: '1' }}>
							<label style={{ marginBottom: '8px' }}>{dictionary.to}</label>
							<input
								min="1"
								id="to"
								onChange={onAgeChanged}
								onKeyDown={(e) =>
									['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
								}
								value={group.max_age}
								type="number"
								data-testid="to-input"
							/>
						</div>
					</div>
				</div>
				<div className="dialog-title-divider" />
				<div
					className="dialog-buttons-container"
					style={{
						paddingLeft: '12px',
						paddingRight: '12px',
						marginBottom: '12px'
					}}
				>
					<div
						onClick={onClose}
						className="secondary-button"
						data-testid="cancel-button"
					>
						{dictionary.cancel}
					</div>
					<div
						onClick={onCreate}
						className={group.name ? 'button' : 'button button-disabled'}
						data-testid="create-button"
					>
						{paticipantGroup ? dictionary.edit : dictionary.create}
					</div>
				</div>
			</div>
		</div>
	);
};

NewParticipantGroupDialog.propTypes = {
	setVisibility: PropTypes.func.isRequired,
	paticipantGroup: PropTypes.object,
	onCreateClicked: PropTypes.func.isRequired
};

export default NewParticipantGroupDialog;
