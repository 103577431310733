import uk from '../../../../assets/icons/flags/uk.svg';
import React from 'react';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import pencil from '../../../../assets/icons/ic_pencil.svg';
// import pause from '../../../../assets/icons/ic_pause.svg';
import trash from '../../../../assets/icons/ic_trash.svg';
import { NavLink } from 'react-router-dom';
import RelatedExperienceItem from './RelatedExperieceItem';
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import placeholder from '../../../../assets/images/bg_placeholder_square.svg';
import MoreItemsButton from '../../common/MoreItemsButton';
// import { changeExperienceStatus } from '../provider_experiences/networking';
// import { changeResourceStatus } from './networking';
import PropTypes from 'prop-types';

const ResourceListItemFull = ({
	resource,
	setSelectedDeleteResource,
	onRelatedClicked,
	// onStatusChangeCompleted,
	// setShowLoader
}) => {
	const userType = useUserTypeId();
	const dictionary = useDictionary();

	// const changeResourceStatusAsync = async (resourceId, newStatus) => {
	// 	setShowLoader(true);
	// 	try {
	// 		const { data } = await changeResourceStatus(resourceId, newStatus);
	// 		onStatusChangeCompleted();
	// 	} catch (error) {
	// 		setShowLoader(false);
	// 	}
	// };

	return (
		<div className="experience-item-full-container">
			<div className="experience-item-basic-info-container">
				<div className="experience-item-row">
					<div className="experience-flags-container">
						<img src={uk} />
					</div>
				</div>
				{userType === 1 ? (
					<div style={{ marginTop: '6px' }}>
						<h3>{resource?.provider?.email}</h3>
						<h3>
							{resource?.provider?.first_name +
								' ' +
								resource?.provider?.last_name}
						</h3>
						<div
							style={{ marginTop: '4px', marginBottom: '4px' }}
							className="dialog-title-divider"
						/>
					</div>
				) : null}
				<div className="flex-filler" />
				<h3 style={{ marginTop: '12px' }}>{resource?.title}</h3>
				<div style={{ marginTop: '10px' }} className="text ellipsize">
					{resource?.description}
				</div>
				<div className="experience-item-buttons-container">
					<NavLink
						to={'provider-resources/' + resource.id}
						className="experience-item-button-container"
					>
						<img src={pencil} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.edit}</h4>
					</NavLink>
					{/*<div className='experience-item-button-container'>
                        <img src={pause}/>
                        <h4 style={{fontSize: '12px'}}>{dictionary.pause}</h4>
                    </div>*/}
					<div
						onClick={() => setSelectedDeleteResource(resource)}
						className="experience-item-button-container"
					>
						<img src={trash} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.delete}</h4>
					</div>
				</div>
			</div>
			<div
				className="experience-thumbs-container"
				style={{ marginTop: '16px', marginBottom: '16px' }}
			>
				<img
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					className="uploaded-image-full"
					src={ImageHelper.getFullImageUrl(resource?.thumbnail_url)}
				/>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<h4 style={{ fontSize: '12px' }}>{dictionary.details}</h4>
				<div className="experience-info-rows-container">
					<div
						className="experience-item-row"
						style={{ marginBottom: '6px', maxWidth: '200px' }}
					>
						<div className="experience-info-title" style={{ opacity: '0.6' }}>
							{dictionary.quantity}
						</div>
						<div className="experience-info-title">
							{resource?.quantity.toString()}
						</div>
					</div>
				</div>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="text-with-info-light">
					{dictionary.used_in_experiences}
				</div>
				<div className="experience-info-rows-container">
					{resource.experiences.map((experience, index) => {
						if (index < 3)
							return (
								<RelatedExperienceItem
									key={experience.id}
									item={experience}
									compact={true}
								/>
							);
					})}
					{!resource.experiences || resource.experiences.length === 0 ? (
						<div className={'text'}>{dictionary.no_experiences_yet}</div>
					) : null}
					{resource.experiences.length >= 3 ? (
						<MoreItemsButton
							resource={resource}
							onMoreClicked={onRelatedClicked}
							title={dictionary.more_experiences}
							number={resource.experiences.length - 2}
						/>
					) : null}
				</div>
			</div>
		</div>
	);
};

ResourceListItemFull.propTypes = {
	resource: PropTypes.object,
	setSelectedDeleteResource: PropTypes.func,
	onRelatedClicked: PropTypes.func,
	onStatusChangeCompleted: PropTypes.func,
	setShowLoader: PropTypes.func
};

export default ResourceListItemFull;
