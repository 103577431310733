import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import React, { useEffect, useState } from 'react';
import { updateProviderInfo } from '../networking';
import PropTypes from 'prop-types';

const Notifications = ({ info, setInfo }) => {
	const dictionary = useDictionary();
	// eslint-disable-next-line no-unused-vars
	const [showLoader, setShowLoader] = useState(false);

	const notificationsSchema = Yup.object().shape({
		booking_updates: Yup.boolean(),
		agenda_updates: Yup.boolean(),
		product_updates: Yup.boolean(),
		partnership_updates: Yup.boolean(),
		discount_updates: Yup.boolean(),
		chat_updates: Yup.boolean(),
		weekly_reports: Yup.boolean(),
		b_booking_updates: Yup.boolean(),
		b_agenda_updates: Yup.boolean(),
		b_product_updates: Yup.boolean(),
		b_partnership_updates: Yup.boolean(),
		b_discount_updates: Yup.boolean(),
		b_chat_updates: Yup.boolean(),
		b_weekly_reports: Yup.boolean()
	});

	useEffect(() => {
		if (info) {
			initFormik();
		}
	}, [info]);

	const initFormik = () => {
		formik.setFieldValue(
			'booking_updates',
			info?.notification_settings?.booking_updates
		);
		formik.setFieldValue(
			'agenda_updates',
			info?.notification_settings?.agenda_updates
		);
		formik.setFieldValue(
			'product_updates',
			info?.notification_settings?.product_updates
		);
		formik.setFieldValue(
			'partnership_updates',
			info?.notification_settings?.partnership_updates
		);
		formik.setFieldValue(
			'discount_updates',
			info?.notification_settings?.discount_updates
		);
		formik.setFieldValue(
			'chat_updates',
			info?.notification_settings?.chat_updates
		);
		formik.setFieldValue(
			'weekly_reports',
			info?.notification_settings?.weekly_reports
		);
		formik.setFieldValue(
			'b_booking_updates',
			info?.notification_settings?.b_booking_updates
		);
		formik.setFieldValue(
			'b_agenda_updates',
			info?.notification_settings?.b_agenda_updates
		);
		formik.setFieldValue(
			'b_product_updates',
			info?.notification_settings?.b_product_updates
		);
		formik.setFieldValue(
			'b_partnership_updates',
			info?.notification_settings?.b_partnership_updates
		);
		formik.setFieldValue(
			'b_discount_updates',
			info?.notification_settings?.b_discount_updates
		);
		formik.setFieldValue(
			'b_chat_updates',
			info?.notification_settings?.b_chat_updates
		);
		formik.setFieldValue(
			'b_weekly_reports',
			info?.notification_settings?.b_weekly_reports
		);
	};

	const formik = useFormik({
		initialValues: {
			booking_updates: false,
			agenda_updates: false,
			product_updates: false,
			partnership_updates: false,
			discount_updates: false,
			chat_updates: false,
			weekly_reports: false,
			b_booking_updates: false,
			b_agenda_updates: false,
			b_product_updates: false,
			b_partnership_updates: false,
			b_discount_updates: false,
			b_chat_updates: false,
			b_weekly_reports: false
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = Object.assign({}, values);
			newDetails.b_product_updates = false;
			submitProviderDataAsync({ notification_settings: newDetails });
		},
		//enableReinitialize: true,
		validateOnMount: true,
		validationSchema: notificationsSchema
	});

	const submitProviderDataAsync = async (info) => {
		setShowLoader(true);

		try {
			let { data } = await updateProviderInfo(info);
			setInfo(data);
			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	FormikHelper.setFormik(formik);

	return (
		<form
			className="settings-page-container thin-scrollbar fade-on-load"
			onSubmit={formik.handleSubmit}
		>
			<div className="onboarding-activity-title">
				{dictionary.email_notifications_title}
			</div>
			<h4 style={{ marginTop: '15px', marginBottom: '25px' }}>
				{dictionary.email_notifications_text}
			</h4>

			{FormikHelper.getCheckboxTemplate(
				'booking_updates',
				dictionary.booking_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.booking_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'agenda_updates',
				dictionary.agenda_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.agenda_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'product_updates',
				dictionary.product_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.product_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'partnership_updates',
				dictionary.partnership_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.partnership_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'discount_updates',
				dictionary.discount_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.discount_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'chat_updates',
				dictionary.chat_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.chat_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'weekly_reports',
				dictionary.weekly_reports_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.weekly_reports_text}
			</div>

			<div className="onboarding-activity-title" style={{ marginTop: '35px' }}>
				{dictionary.browser_notifications_title}
			</div>
			<h4 style={{ marginTop: '15px', marginBottom: '25px' }}>
				{dictionary.browser_notifications_text}
			</h4>

			{FormikHelper.getCheckboxTemplate(
				'b_booking_updates',
				dictionary.booking_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.booking_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_agenda_updates',
				dictionary.agenda_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.agenda_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_product_updates',
				dictionary.product_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.product_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_partnership_updates',
				dictionary.partnership_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.partnership_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_discount_updates',
				dictionary.discount_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.discount_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_chat_updates',
				dictionary.chat_updates_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.chat_updates_text}
			</div>

			{FormikHelper.getCheckboxTemplate(
				'b_weekly_reports',
				dictionary.weekly_reports_title,
				false
			)}
			<div
				style={{ marginLeft: '30px', marginTop: '8px', marginBottom: '24px' }}
				className="text"
			>
				{dictionary.weekly_reports_text}
			</div>

			<button style={{ marginTop: '30px' }} type={'submit'} className="button">
				{dictionary.save}
			</button>
		</form>
	);
};

Notifications.propTypes = {
	info: PropTypes.object,
	setInfo: PropTypes.func
};

export default Notifications;
