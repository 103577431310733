import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import * as Yup from 'yup';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useFormik } from 'formik';
import React from 'react';

const LanguageSelection = () => {
	const dictionary = useDictionary();

	const languageSchema = Yup.object().shape({
		language: Yup.string()
	});

	const formik = useFormik({
		initialValues: {
			language: 'en'
		},
		// eslint-disable-next-line no-unused-vars
		onSubmit: (values, { setStatus, setSubmitting }) => {},
		validateOnMount: true,
		validationSchema: languageSchema
	});

	FormikHelper.setFormik(formik);

	return (
		<form
			className="settings-page-container fade-on-load"
			onSubmit={formik.handleSubmit}
		>
			<div className="onboarding-activity-title">
				{dictionary.language_settings}
			</div>

			<div className="settings-form-row">
				<div className="settings-info-column">
					<label style={{ marginTop: '25px' }} className="radio-label">
						{dictionary.english}
						<input
							onChange={formik.handleChange}
							checked={formik.values.language === 'en'}
							value="en"
							type="radio"
							name="language"
						/>
						<span className="checkmark" />
					</label>
					<label className="radio-label">
						{dictionary.greek}
						<input
							onChange={formik.handleChange}
							checked={formik.values.language === 'el'}
							value="el"
							type="radio"
							name="language"
						/>
						<span className="checkmark" />
					</label>
					<button
						style={{ marginTop: '45px' }}
						type={'submit'}
						className="button"
					>
						{dictionary.save}
					</button>
				</div>
			</div>
		</form>
	);
};

export default LanguageSelection;
