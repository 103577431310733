import React, { useEffect, useState } from 'react';
import RelatedExperienceItem from './RelatedExperieceItem';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const RelatedExperiencesModal = ({ experiences, setShow }) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);
	// const [value, setValue] = useState('');

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShow(false);
		}, 800);
	};

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div className="dialog-container" style={{ minHeight: '470px' }}>
				<div className="dialog-title-container">
					<div className="dialog-title">{dictionary.experiences}</div>
					<div
						className="dialog-close-button"
						onClick={() => onCancelClicked()}
					/>
				</div>
				<div className="dialog-title-divider" />
				<div className="dialog-content-container-with-scroll thin-scrollbar">
					{experiences?.map((experience) => (
						<RelatedExperienceItem key={experience} item={experience} />
					))}
				</div>
				<div className="dialog-title-divider" />
				<div
					className="dialog-buttons-container"
					style={{
						padding: '12px',
						marginBottom: '0',
						justifyContent: 'flex-end'
					}}
				>
					<button
						onClick={() => {
							onCancelClicked();
						}}
						className="button"
					>
						{dictionary.got_it}
					</button>
				</div>
			</div>
		</div>
	);
};

RelatedExperiencesModal.propTypes = {
	experiences: PropTypes.array.isRequired,
	setShow: PropTypes.func.isRequired
};

export default RelatedExperiencesModal;
