import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ExperienceDetailDialog = ({ viewUrl, showDialog, setShowDialog }) => {
	const [screenHeight, setScreenHeight] = useState(window.innerHeight);
	const [showDialogAnimation, setShowDialogAnimation] = useState(false);

	const handleClose = () => {
		setShowDialogAnimation(false);
		setTimeout(() => {
			setShowDialog(false);
		}, 600);
	};

	useEffect(() => {
		if (showDialog) {
			setShowDialogAnimation(true);
		}
	}, [showDialog]);

	useEffect(() => {
		// Function to update the screen height when the window is resized
		const updateScreenHeight = () => setScreenHeight(window.innerHeight);

		// Add event listener to listen for window resize events
		window.addEventListener('resize', updateScreenHeight);

		// Clean up the event listener when the component is unmounted
		return () => window.removeEventListener('resize', updateScreenHeight);
	}, []);

	const viewUrlIframe = () => {
		return {
			__html:
				'<iframe id="marketplace-preview" width="100%" height="' +
				screenHeight +
				'" src="' +
				viewUrl +
				'" frameborder="0" allowfullscreen></iframe>'
		};
	};

	return (
		showDialog && (
			<div className={`experience-category-tags-wrapper${showDialogAnimation ? ' experience-category-tags-wrapper-visible' : ''}`}>
				<div
					className="dialog-close-button"
					style={{ top: 20, right: 24 }}
					onClick={handleClose}
				/>
				<div dangerouslySetInnerHTML={viewUrlIframe()} />
			</div>
		)
	);
};

ExperienceDetailDialog.propTypes = {
	viewUrl: PropTypes.string,
	showDialog: PropTypes.bool,
	setShowDialog: PropTypes.func
};

export default ExperienceDetailDialog;
