import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import PropTypes from 'prop-types';
import React from 'react';

const ExperienceCategory = ({ category, setSelectedCategory, setShowTags }) => {
	return (
		<div
			onClick={() => {
				setSelectedCategory(category);
				setShowTags(true);
			}}
			style={{ backgroundColor: category.color }}
			className="experience-category-container"
			data-testid="experience-category-container"
		>
			<img
				src={ImageHelper.getFullImageUrl(category.image_url)}
				className="experience-category-image"
			/>
			<div className="experience-category-title">{category.title}</div>
		</div>
	);
};

ExperienceCategory.propTypes = {
	category: PropTypes.object,
	setSelectedCategory: PropTypes.func,
	setShowTags: PropTypes.func
};

export default ExperienceCategory;
