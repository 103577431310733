import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ic_bookings from '../../../../../../../../assets/icons/ic_bookings_blue.svg';
import { useDictionary } from '../../../../../../../../utilities/hooks/UseDictionary';
import BookingItem from './bookingItem';
import '../../../../../../../../assets/css/providerbookingtable.css';
import SimpleLoader from '../../../../../../common/SimpleLoader';

const BookingsTab = ({ details, onBookingClicked, loading, hasMore, onLoadMore }) => {
  const dictionary = useDictionary();
  const containerRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const container = containerRef.current;
      if (container) {
        const { scrollTop, scrollHeight, clientHeight } = container;
        if (scrollTop + clientHeight >= scrollHeight - 10) {
          if (hasMore && !loading) {
            onLoadMore();
          }
        }
      }
    };

    const container = containerRef.current;
    if (container) {
      	container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
          container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [loading, hasMore, onLoadMore]);

  return (
    <div className="settings-page-container thin-scrollbar fade-on-load" ref={containerRef}>
      <div className="slot-detail-title" style={{ fontWeight: 500 }}>
        {dictionary.bookings}
      </div>
      {details?.length > 0 ? (
        <table style={{ marginTop: 16, marginLeft: -8, borderCollapse: "collapse" }} className='provider-detail-booking-listing'>
          <tbody>
            {details.map((booking) => (
              <BookingItem
                key={booking.id}
                onBookingClicked={() => onBookingClicked(booking)}
                booking={booking}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <div className="empty-participants-wrapper">
          <div className="empty-participants">
            <div className="empty-participants-icon-wrapper">
              <img src={ic_bookings} alt="No bookings" />
            </div>
            <h5 className="empty-participants-title">
              {dictionary.no_bookings_yet}
            </h5>
            <p className="empty-participants-description">
              {dictionary.all_bookings_will_be_listed}
            </p>
          </div>
        </div>
      )}
      <SimpleLoader showLoader={loading}/>
    </div>
  );
};

BookingsTab.propTypes = {
  details: PropTypes.array.isRequired,
  onBookingClicked: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func.isRequired,
};

export default BookingsTab;
