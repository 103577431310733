import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../../../../assets/css/custom_datepicker.css';
import PropTypes from 'prop-types';
import { ReactComponent as CaretLeftIcon } from '../../../../assets/icons/ic_caret_left.svg';
import moment from 'moment';
import { addDays } from 'date-fns';
export const DatePickerField = ({
	name,
	value,
	minDate,
	dateFormat,
	placeholderText,
	className,
	disabled,
	onChange
}) => {
	return (
		<DatePicker
			name={name}
			minDate={minDate}
			maxDate={addDays(new Date(), 365)}
			dateFormat={dateFormat}
			placeholderText={placeholderText}
			selected={(value && new Date(value)) || null}
			onChange={onChange}
			useWeekdaysShort
			disabled={disabled}
			wrapperClassName={className}
			renderCustomHeader={(header) => (
				<div className="react-datepicker__custom-header">
					<button
						disabled={header.prevMonthButtonDisabled}
						onClick={header.decreaseMonth}
					>
						<CaretLeftIcon />
					</button>
					<div className="react-datepicker__current-month">
						{moment(header.date).format('MMMM YYYY')}
					</div>
					<button
						disabled={header.nextMonthButtonDisabled}
						onClick={header.increaseMonth}
					>
						<CaretLeftIcon />
					</button>
				</div>
			)}
		/>
	);
};

DatePickerField.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	minDate: PropTypes.string,
	dateFormat: PropTypes.string,
	placeholderText: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func.isRequired
};
