import React from 'react';
import loader from '../../../assets/lottie/upiria_loader.json';
import '../../../assets/css/dialogs.css';
import Lottie from 'lottie-react';
import { CSSTransition } from 'react-transition-group';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const DialogLoader = ({ showLoader }) => {
	const [opacity, setOpacity] = useState(0);
	const [display, setDisplay] = useState('flex');

	useEffect(() => {
		if (showLoader) {
			showDialog();
		} else {
			hideDialog();
		}
	}, [showLoader]);

	const showDialog = () => {
		setDisplay('flex');
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	};

	const hideDialog = () => {
		setOpacity(0);
		setTimeout(() => {
			setDisplay('none');
		}, 800);
	};

	return (
		<div
			className={`dialog-loader-container`}
			style={{ display: display, opacity: opacity }}
		>
			<div className="lottie-container">
				<Lottie loop={true} animationData={loader} />
			</div>
		</div>
	);
};

DialogLoader.propTypes = {
	showLoader: PropTypes.bool
};

export default DialogLoader;
