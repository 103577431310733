import React from 'react';
import PropTypes from 'prop-types';

const ResourceDetailsRelatedExperienceItem = ({
	experience,
	resource,
	onExperienceClicked
}) => {
	const getParticipants = () => {
		let total = 0;
		let bookings = resource?.bookings?.filter(
			(booking) => booking?.experience.id === experience?.id
		);

		bookings?.map((booking) =>
			booking?.participant_groups?.map((group) => {
				total += group?.quantity;
			})
		);

		return total;
	};

	return (
		<div
			onClick={() => onExperienceClicked(experience.id)}
			className="calendar-related-experience-container"
		>
			<div className="experience-details-dialog-row">
				<div
					className="experience-details-dialog-color-small"
					style={{ backgroundColor: experience.color }}
				/>
				<div className="experience-details-dialog-row-content">
					<h4 style={{ fontSize: '14px' }} className="ellipsize ellipsize-1">
						{experience?.title}
					</h4>
					<div className="calendar-slot-experience-list-item calendar-event-booked">
						{getParticipants() + '/' + (experience?.maximum_group_size ?? '0')}
					</div>
				</div>
			</div>
		</div>
	);
};

ResourceDetailsRelatedExperienceItem.propTypes = {
	experience: PropTypes.object,
	resource: PropTypes.object,
	onExperienceClicked: PropTypes.func
};

export default ResourceDetailsRelatedExperienceItem;
