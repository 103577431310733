import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { register } from '../__redux/authCrud';
import * as auth from '../__redux/authRedux';
import * as Yup from 'yup';
import FormikHelper from '../../../../utilities/helpers/FormikHelper';
import ShowHidePassword from '../../components/custom/ShowHidePassword';
import '../../../../assets/css/login.css';
import '../../../../assets/css/form_elements.css';
import { useHistory } from 'react-router-dom';
import RegistrationConfirmation from './RegistrationConfirmation';
import { CryptoHelper } from '../../../../utilities/helpers/CryptoHelper';
import FormLoader from '../../common/FormLoader';
import { bindActionCreators } from 'redux';
import * as common from '../../common/__redux/commonRedux';
import LegalInfoDialog from '../../common/legal-info-dialog/LegalInfoDialog';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const RegisterForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const [showLoaded, setShowLoader] = useState(false);
	const [showLegalInfoDialog, setLegalInfoDialogVisibility] = useState(false);
	const [type, setType] = useState(1);
	const history = useHistory();

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const RegisterSchema = Yup.object().shape({
		email: FormikHelper.getDefaultYupEmail(true, dictionary),
		password: FormikHelper.getDefaultYupPassword(true, dictionary)
	});

	const formik = useFormik({
		initialValues: {
			email: '',
			password: '',
			no_updates: false
		},
		onSubmit: (values, { setSubmitting }) => {
			setShowLoader(true);
			register(
				values.email,
				CryptoHelper.encryptData(values.password, props.metadata),
				props.sessionId,
				2
			)
				.then(() => {
					setShowLoader(false);
					setShowConfirmation(true);
				})
				.catch((error) => {
					setShowLoader(false);
					setSubmitting(false);
					if (error.response) {
						props.actions.setErrorMessage(
							error.response.data.error_code.toString()
						);
					}
				});
		},
		validateOnMount: true,
		validationSchema: RegisterSchema
	});

	FormikHelper.setFormik(formik);

	const onLoginCLicked = () => {
		history.push('login');
	};

	const onCheckboxChanged = (name, value) => {
		formik.setFieldValue(name, value);
	};

	const showLegaDialog = (type) => {
		setType(type);
		setLegalInfoDialogVisibility(true);
	};

	return (
		<div className="login-wrapper">
			<form
				style={{ opacity: showForm ? '1' : '0' }}
				className="login-form-wrapper"
				onSubmit={formik.handleSubmit}
			>
				<div className="login-form-container">
					<div className="login-logo" />
					<div className="title-divider" />
					<h1 data-testid="welcome-title">{dictionary.join_to_upiria}</h1>
					<div
						data-testid="welcome-message"
						className="text"
						style={{ marginTop: '20px', marginBottom: '30px' }}
					>
						<span>{dictionary.registerMessage}</span>
					</div>
					<div className="login-form-content-container">
						{FormikHelper.getInputTemplate(
							'email',
							dictionary.email,
							dictionary.enter_your_email,
							'email',
							true,
							true,
							false,
							null,
							true
						)}
						{ShowHidePassword(
							'password',
							dictionary.password,
							dictionary.create_password,
							true,
							formik,
							true
						)}
						{/* <div className="group-inputs">
							<div className="form-check-container">
								{FormikHelper.getCheckboxTemplate(
									'receive_updates',
									dictionary.registration_updates_message,
									false,
									onCheckboxChanged
								)}
							</div>
						</div> */}
						<div className="group-inputs" style={{ marginTop: '30px' }}>
							<button
								data-testid="register-btn"
								type="submit"
								className={formik.isValid ? 'button' : 'button button-disabled'}
							>
								{dictionary.signup}
							</button>
						</div>
						<div
							style={{ marginTop: '15px', marginBottom: '20px' }}
							className="terms-text"
						>
							<span>{dictionary.registration_terms_message}</span>
							<span data-testid='terms-label' className="link-text" onClick={() => showLegaDialog(2)}>
								{dictionary.terms_label}
							</span>
							<span style={{ paddingLeft: '10px' }}>{dictionary.and}</span>
							<span data-testid='privacy-policy-label' className="link-text" onClick={() => showLegaDialog(1)}>
								{dictionary.privacy_policy_label}
							</span>
						</div>
						<div 
							style={{ marginTop: '15px', marginBottom: '20px' }}
							className="text"
						>
							<span>{dictionary.already_upirian}</span>
							<span data-testid='login-btn' onClick={onLoginCLicked} className="link-text">
								{dictionary.login}
							</span>
						</div>
					</div>
				</div>
				{showConfirmation ? (
					<RegistrationConfirmation
						setShowConfirmation={setShowConfirmation}
						email={formik.values.email}
					/>
				) : null}
				<FormLoader showLoader={showLoaded} />
			</form>
			<div className="login-side-image" />
			{showLegalInfoDialog ? (
				<LegalInfoDialog
					type={type}
					setLegalInfoDialogVisibility={setLegalInfoDialogVisibility}
				/>
			) : null}
		</div>
	);
};

RegisterForm.propTypes = {
	actions: PropTypes.object,
	metadata: PropTypes.string,
	sessionId: PropTypes.string
};

export default connect(null, (dispatch) => {
	return {
		actions: bindActionCreators(
			{ ...auth.actions, ...common.actions },
			dispatch
		)
	};
})(RegisterForm);
