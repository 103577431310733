import { shallowEqual, useSelector } from 'react-redux';
import { dictionaryCodeMap } from '../../assets/dictionaries/dictionary';
import { SortOptions } from '../../assets/dictionaries/SortOptions';

export const useSortOptions = (name) => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);
	let code = dictionaryCodeMap[user.language_id];
	let options = [];
	let selected = SortOptions[name];

	selected.map((item) => {
		options.push({
			value: item.value,
			direction: item.direction,
			label: item['label_' + code]
		});
	});

	return options;
};
