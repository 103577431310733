import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useBookingStatuses } from '../../../utilities/hooks/useBookingStatuses';

const MultipeCustomCheckbox = ({
	label,
    checbboxObj,
    itemKey,
	onFilterStatusSelected,
	setEnableScheduling,
	filterCount,
	name = null,
	type
}) => {
	const getSize = () => {
		if (!type) {
			return '16px';
		} else {
			if (type === 1) {
				return '16px';
			} else if (type === 2) {
				return '24px';
			}
		}
	};

    const bookingStatuses = useBookingStatuses();
    const [checkboxChecked, SetCheckboxChecked] = useState(false);
   
    useEffect(() => {
		SetCheckboxChecked(checbboxObj[itemKey]);
	}, []);

    const setCheckboxCheckedChoose = (itemKey) => {
		let statusIds = JSON.parse(localStorage.getItem('bookings_filter_status')) || [];
        SetCheckboxChecked(!checbboxObj[itemKey]);
        checbboxObj[itemKey] = !checbboxObj[itemKey];
        Object.keys(bookingStatuses).map((bookingStatus) => {
            if (bookingStatus === itemKey) {
				if (checbboxObj[bookingStatus] === true) {
					if (!statusIds.includes(bookingStatus)) {
						statusIds.push(bookingStatus);
					}
				} else {
					if (statusIds.includes(bookingStatus)) { 
						for( var i = 0; i < statusIds.length; i++) { 
							if ( statusIds[i] === bookingStatus) { 
								statusIds.splice(i, 1); 
							}
						
						}
					}
				}

            }
        });

		if (statusIds.length > 0) {
			localStorage.setItem('bookings_filter_status', JSON.stringify(statusIds));
			setEnableScheduling(true);
		} else {
			localStorage.removeItem('bookings_filter_status');
			if (filterCount - 1 <= 0) {
				setEnableScheduling(false);
			}
		}
		
		onFilterStatusSelected(statusIds);
    }

	return (
		<div
			onClick={() => setCheckboxCheckedChoose(itemKey)}
			className="form-check-container"
			style={{ marginLeft: '0' }}
			data-testid={name ? name : 'custom-checkbox'}
		>
			<div
				style={{ width: getSize(), height: getSize() }}
				className={
					'custom-checkbox ' +
					(checkboxChecked
						? type === 1 || !type
							? 'custom-checkbox-checked'
							: 'custom-checkbox-checked-medium'
						: 'custom-checkbox')
				}
			/>
			<label
				style={{ marginBottom: 0, marginLeft: '10px', fontWeight: 400 }}
				className="checkbox-label"
			>
				{label}
			</label>
			{name ? (
				<input
					name={name}
					type="checkbox"
					style={{ display: 'none' }}
					checked={checkboxChecked}
					readOnly={true}
					data-testid={name}
				/>
			) : null}
		</div>
	);
};

MultipeCustomCheckbox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.number,
	itemKey: PropTypes.string,
    checbboxObj: PropTypes.array,
	onFilterStatusSelected: PropTypes.func,
	setEnableScheduling: PropTypes.func,
	filterCount: PropTypes.number
};

export default MultipeCustomCheckbox;
