import React from 'react';
import PropTypes from 'prop-types';

const SortOption = ({ option, selectedOption, onOptionSelected }) => {
	return (
		<div
			onClick={() => onOptionSelected(option)}
			className={
				option?.value === selectedOption?.value && option?.direction === selectedOption?.direction
					? 'sort-option sort-option-active'
					: 'sort-option'
			}
		>
			{option.label}
		</div>
	);
};

SortOption.propTypes = {
	option: PropTypes.object,
	selectedOption: PropTypes.object,
	onOptionSelected: PropTypes.func
};

export default SortOption;
