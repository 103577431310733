import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import Flag from 'react-world-flags';
import { ENABLE_RESOURCES } from '../../../../../../ui_config';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import { getAllResources } from '../../../provider-resources/networking';
import ExperienceCalendarColorPicker from './ExperienceCalendarColorPicker';
import DurationSelectionDialog from './duration_selection_dialog/DurationSelectionDialog';
import ResourceSelect from './resource_select/ResourceSelect';
import { getCountryData, getMatchedCountryData } from '../../../../../../utilities/helpers/LanguageHelper';
import DescriptionDialog from './DescriptionDialog';
import { ReactComponent as CornersOutIcon } from '../../../../../../assets/icons/ic_corners_out.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/ic_check.svg';
import { ReactComponent as PlusIcon } from '../../../../../../assets/icons/ic_plus_black.svg';
import Switch from '../../../../common/Switch';

const NameAndDetails = ({
	setEnabled,
	name,
	setName,
	plans,
	setPlans,
	colorCode,
	setColorCode,
	minutes,
	setMinutes,
	hours,
	setHours,
	days,
	setDays,
	// workingHours,
	// setWorkingHours,
	resource,
	setResource,
	allLanguages,
	selectedLanguages,
	setSelectedLanguages,
	isEcoFriendly,
	setIsEcoFriendly
}) => {
	const maxLength = 60;
	const dictionary = useDictionary();

	const [showDurationDialog, setShowDurationDialog] = useState(false);
	const [showDescriptionDialog, setShowDescriptionDialog] = useState(false);
	// eslint-disable-next-line no-unused-vars
	const [showLoader, setShowLoader] = useState(true);
	const [resources, setResources] = useState([]);

	const source = axios.CancelToken.source();

	const getResourcesAsync = async () => {
		setShowLoader(true);
		try {
			let { data } = await getAllResources(null, null, null, source.token);
			data.data.splice(0, 0, { title: dictionary.none, id: -1 });
			setResources(data.data);

			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
		}
	};

	const onNameChanged = (e) => {
		if (e.target.value.trim().length <= maxLength) {
			setName(e.target.value);
		}
	};

	const onPlansChanged = (e) => {
		//if (e.target.value.length <= maxLength) {
		setPlans(e.target.value);
		//}
	};

	const onEcoChanged = () => {
		setIsEcoFriendly(!isEcoFriendly);
	};

	const getDuration = () => {
		if (days > 0 || hours > 0 || minutes > 0) {
			return (
				days +
				' ' +
				dictionary.days +
				' ' +
				hours +
				' ' +
				dictionary.hours +
				'  ' +
				minutes +
				' ' +
				dictionary.minutes
			);
		} else {
			return dictionary.select_duration;
		}
	};

	const openDurationDialog = () => {
		setShowDurationDialog(true);
	};

	const openDescriptionDialog = () => {
		setShowDescriptionDialog(true);
	};

	const handleRemoveSelectedLanguage = (id) => {
		setSelectedLanguages(selectedLanguages.filter((i) => id ? i !== id : !!i));
	};

	const handleAddAnotherLanguage = () => {
		if (selectedLanguages.includes('')) {
			return;
		}
		setSelectedLanguages([...selectedLanguages, ""]);
	};

	const handleChangeSelectedLanguage = (id, lang, index) => {
		const updatedOne = lang.find(langItem => !!langItem.id && langItem.id !== id);
		setSelectedLanguages(selectedLanguages.map((langItem, idx) => index === idx ? updatedOne.id : langItem));
	};

	useEffect(() => {
		setEnabled(
			name.trim().length > 0 &&
				plans.trim().length > 0 &&
				colorCode !== '' &&
				(minutes > 0 || hours > 0 || days > 0)
		);
	}, [name, plans, days, hours, minutes, colorCode]);

	useEffect(async () => {
		if (ENABLE_RESOURCES) {
			await getResourcesAsync();
		}

		return () => {
			source.cancel('component unmounted');
		};
	}, []);

	const searchInputs = document.getElementsByClassName('search');

	if (searchInputs.length > 0) {
		searchInputs[0].setAttribute('placeholder', 'New Placeholder');
	}

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container thin-scrollbar">
				<h2>{dictionary.name_and_describe}</h2>
				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.give_experience_name}
				</h3>
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.make_it_descriptive}
				</div>
				<div className="group-inputs">
					<input
						type="text"
						value={name}
						onChange={onNameChanged}
						data-testid="experience-name-input"
					/>
				</div>
				<div className="textarea-info-row">
					<div className="text">{name.trim().length + '/' + maxLength}</div>
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.choose_color_code}
				</h3>
				<ExperienceCalendarColorPicker
					colorCode={colorCode}
					setColorCode={setColorCode}
				/>

				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.what_will_you_and_guests_do}
				</h3>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between'
					}}
				>
					<div className="text">{dictionary.provide_specific_plans}</div>
					<button
						className="description-expand-button"
						onClick={openDescriptionDialog}
					>
						<CornersOutIcon />
					</button>
				</div>
				<textarea
					style={{ marginBottom: '20px' }}
					value={plans}
					onChange={onPlansChanged}
					placeholder={dictionary.tell_participants_a_story}
					data-testid="experience-plans-input"
				/>

				<div className="environment-friendly-switch-wrapper">
					<div>
						<div className="text" style={{ fontSize: 16, fontWeight: 500 }}>
							{dictionary.environmentally_friendly}
						</div>
						<div
							className="text"
							style={{ fontWeight: 400, opacity: 0.8, marginTop: 8 }}
						>
							{
								dictionary.current_experience_is_an_environmentally_friendly_travel_experience
							}
						</div>
					</div>
					<Switch value={isEcoFriendly} onChange={onEcoChanged} />
				</div>

				{ENABLE_RESOURCES ? (
					<ResourceSelect
						value={resource}
						setValue={setResource}
						items={resources}
						label={dictionary.used_resource}
						showSubtitle={true}
					/>
				) : null}

				<h3 style={{ fontSize: 16, marginTop: 30, marginBottom: 16 }}>
					{dictionary.what_languages_will_be_spoken}
				</h3>
				
				{selectedLanguages
					.map((languageId, index) => {
						const { id, name, code } = allLanguages?.find((languageItem) => languageItem.id === languageId) || {};
						const matchedCountryData = getMatchedCountryData(code);
						const selectOptions = {
							options: allLanguages.map(({id, code, name}) => ({
								id,
								label: name,
								value: code
							})),
							ClearSelectedIcon: null,
							closeOnChangedValue: true,
							valueRenderer: () => (
								<div className="experience-selected-language-name">
									<Flag
										code={matchedCountryData?.countryCode?.toLowerCase()}
										width={16}
										height={16}
									/>
									{languageId ? matchedCountryData?.officialLanguageNameEn.split(', ')[0] : ''}
								</div>
							),
							ItemRenderer: (node) => {
								const disabled = selectedLanguages.includes(node.option.id);
								return (
									<div
										className={`experience-language-item ${disabled ? 'disabled' : ''}`}
										onClick={() => !disabled && node.onClick(node.option.value)}
									>
										<div className="experience-language-item-left-side">
											<span className="experience-country-flag">
											<Flag
												code={getMatchedCountryData(node.option.value)?.countryCode?.toLowerCase()}
												width={16}
												height={16}
											/>
											</span>
												<span className="experience-language-name">
												{node.option.label}
											</span>
										</div>
										{node.checked && (
											<div className="experience-language-checked">
												<CheckIcon/>
											</div>
										)}
									</div>
								)
							},
							value: [{
								id,
								label: name,
								value: code
							}],
							onChange: (lang) => handleChangeSelectedLanguage(id, lang, index),
							hasSelectAll: false,
							labelledBy: "select",
							className: "experience-language-select"
						};

						return (
							<div
								key={index}
								className="experience-selected-language"
							>
								<MultiSelect {...selectOptions} />

								<div
									onClick={() => handleRemoveSelectedLanguage(id)}
									className="dialog-document-row-delete"
								/>
							</div>
						)
					})}
				<div
					onClick={handleAddAnotherLanguage}
					className="secondary-button"
					data-testid="add-item-button"
				>
					<PlusIcon /> {dictionary.add_another_language}
				</div>

				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.how_long_experience}
				</h3>
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.provide_experience_duration}
				</div>
				<div
					className="experience-duration-input"
					style={{ marginBottom: '20px' }}
					onClick={openDurationDialog}
					data-testid="experience-duration-input"
				>
					<div className="experience-duration-input-title">
						<div className="watch"></div>
						<div className="title">{getDuration()}</div>
					</div>
				</div>
				{/*<WorkingHours setWorkingHours={setWorkingHours} workingHours={workingHours} />*/}
			</div>
			{showDurationDialog ? (
				<DurationSelectionDialog
					parentDays={days}
					parentMinutes={minutes}
					parentHours={hours}
					setParentMinutes={setMinutes}
					setParentHours={setHours}
					setParentDays={setDays}
					setDialogVisibility={setShowDurationDialog}
				/>
			) : null}
			{showDescriptionDialog && (
				<DescriptionDialog
					description={plans}
					setDescription={onPlansChanged}
					setShowDescriptionDialog={setShowDescriptionDialog}
				/>
			)}
		</div>
	);
};

NameAndDetails.propTypes = {
	setEnabled: PropTypes.func,
	name: PropTypes.string,
	setName: PropTypes.func,
	plans: PropTypes.string,
	setPlans: PropTypes.func,
	colorCode: PropTypes.string,
	setColorCode: PropTypes.func,
	minutes: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // allow both string and number
	setMinutes: PropTypes.func,
	hours: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // allow both string and number
	setHours: PropTypes.func,
	days: PropTypes.oneOfType([PropTypes.string, PropTypes.number]), // allow both string and number
	setDays: PropTypes.func,
	workingHours: PropTypes.object,
	setWorkingHours: PropTypes.func,
	resource: PropTypes.object,
	setResource: PropTypes.func,
	selectedLanguages: PropTypes.array,
	setSelectedLanguages: PropTypes.func,
	allLanguages: PropTypes.array,
	isEcoFriendly: PropTypes.bool,
	setIsEcoFriendly: PropTypes.func
};

export default NameAndDetails;
