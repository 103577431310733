import React from 'react';
import PropTypes from 'prop-types';

const CustomSelectCompactItem = ({ item, selectedItem, onItemSelected }) => {
	return (
		<div
			onClick={() => onItemSelected(item)}
			className={
				selectedItem === item.value
					? 'custom-select-item custom-select-compact-item custom-select-item-selected'
					: 'custom-select-item custom-select-compact-item'
			}
		>
			{item.name}
		</div>
	);
};

CustomSelectCompactItem.propTypes = {
	item: PropTypes.object,
	selectedItem: PropTypes.number,
	onItemSelected: PropTypes.func
};

export default CustomSelectCompactItem;
