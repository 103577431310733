import React from 'react';
import PropTypes from 'prop-types';
import ImageHelper from '../../../../../../../../utilities/helpers/ImageHelper';

const Assets = ({ assets }) => {
    const jsonAssets = assets.map(asset => JSON.stringify(asset));
    const uniqueJsonAssets = [...new Set(jsonAssets)];
    const uniqueAssets = uniqueJsonAssets.map(uniqueAsset => JSON.parse(uniqueAsset));
    const allRows = Math.ceil(uniqueAssets.length / 4);
    let from = 0;
    let to = 4;
    const assetElements = [];
    for (let i = 0; i < allRows; i++) {
        assetElements.push(uniqueAssets.slice(from, to));
        from += 4;
        to += 4;
    }
    return (<div className="booking-experience-images-container-wrapper">
        {assetElements.map((element, elementIndex) => {
            return (
                <div className="booking-experience-images-container" key={elementIndex}>
                    {element.map((item, index) => {
                        return (
                            <img
                                key={item?.id}
                                className="booking-experience-images-container-image"
                                src={ImageHelper.getFullImageUrl(item?.thumbnail_url)}
                            />
                        );
                    })}
                </div>
            );
        })}
    </div>)
};

Assets.propTypes = {
	assets: PropTypes.array
};

export default Assets;
