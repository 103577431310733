import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import placeholder from '../../../../assets/images/bg_placeholder_square.svg';
import React, { useState } from 'react';
import PropTypes from 'prop-types';

const RelatedExperienceItem = ({ item, compact = false }) => {
	const [hasError, setHasError] = useState(false);
	return (
		<div
			title={item?.title}
			style={{
				marginBottom: '0px',
				paddingLeft: '0',
				height: compact ? '48px' : '56px'
			}}
			className={'select-experience-item custom-select-item'}
		>
			<div className="custom-experience-image-container">
				<img
					onError={(e) => {
						e.currentTarget.src = placeholder;
						setHasError(true);
					}}
					src={ImageHelper.getFullImageUrl(item?.assets?.[0]?.thumbnail_url)}
					className="custom-experience-image"
				/>
				{!hasError ? (
					<div
						className="custom-experience-image-color"
						style={{ background: item?.color }}
					/>
				) : null}
			</div>
			<div className="custom-experience-title ellipsize">{item?.title}</div>
		</div>
	);
};

RelatedExperienceItem.propTypes = {
	item: PropTypes.object,
	compact: PropTypes.bool
};

export default RelatedExperienceItem;
