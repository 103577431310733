import React from 'react';
import warning from '../../../assets/icons/ic_info_warning.svg';
import info from '../../../assets/icons/ic_info_light.svg';
import PropTypes from 'prop-types';

const CustomToast = ({ message, type = 'warning' }) => (
	<div
		style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
		className="custom-toast-content" // Add this class here
	>
		<img
			style={{ width: '22px', height: '22px' }}
			src={type === 'info' ? info : warning}
			alt="warning icon"
		/>
		<p style={{ marginTop: '10px', textAlign: 'center' }}>{message}</p>
	</div>
);

CustomToast.propTypes = {
	message: PropTypes.string
};

export default CustomToast;
