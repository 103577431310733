/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useLocation, useParams, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as common from './__redux/commonRedux';
import '../../../assets/css/header.css';
import {
	detectMobile,
	detectMobile2
} from '../../../utilities/helpers/MobileHelper';
import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import ProfileWidget from './ProfileWidget';
import PropTypes from 'prop-types';

const Header = (props) => {
	// const dictionary = useDictionary();
	const location = useLocation();
	const { id } = useParams();
	const [addButton, setAddButton] = useState(null);
	let isMobile = detectMobile();
	const userTypeId = props?.user?.user_type?.id;
	const adminPaths = ['/hotels', '/providers', '/tests'];

	const toggleBurger = (event) => {
		event.stopPropagation();

		if (detectMobile2()) {
			if (props.burger) {
				props.closeBurger();
			} else {
				props.openBurger();
			}
		} else {
			props.expand();
		}
	};

	useEffect(() => {}, [location]);

	return (
		<div className="header-wrapper">
			<div className="header-search-container">
				{props.collapsed || detectMobile2() ? (
					<div
						className="header-icon header-icon-list"
						onClick={(e) => {
							toggleBurger(e);
						}}
					/>
				) : null}
				{/*<div className='header-divider'/>
                <div className='header-search-icon'/>
                <input placeholder={dictionary.header_search} className='header-search-input' type='text'/>*/}
			</div>
			<div className="header-icons-container">
				{/*                <div className='header-icon header-icon-barcode'/>
                <div className='header-icon header-icon-message'/>
                <div className='header-icon header-icon-notification'/>*/}
				{window.innerWidth > 986 ? <ProfileWidget /> : null}
			</div>
		</div>
	);
};

Header.propTypes = {
	collapsed: PropTypes.bool,
	expand: PropTypes.func,
	burger: PropTypes.bool,
	openBurger: PropTypes.func,
	closeBurger: PropTypes.func,
	user: PropTypes.object
};

export default connect(common.states, common.actions)(withRouter(Header));
