import React from 'react';
import { CURRENCY_FULL } from '../../../config';
import PropTypes from 'prop-types';
import { useUser } from '../../../utilities/hooks/UseUser';

// const { useUser } = require('../../../utilities/hooks/UseUser');
const CurrencyInput = ({ label, value, setValue }) => {
	const user = useUser();

	const getCurrency = () => {
		if (user.currency === null || user.currency === undefined) {
			return CURRENCY_FULL;
		} else {
			return user.currency;
		}
	};

	return (
		<div style={{ marginTop: '30px' }} className="group-inputs">
			<label style={{ fontSize: '16px' }}>{label}</label>
			<div className="currency-input-container">
				<input
					className="currency-input"
					type="number"
					min="1"
					step="1"
					onKeyDown={(e) =>
						['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
					}
					value={value}
					onChange={(e) => setValue(e.target.value)}
				/>
				<div className="currency-input-short">{getCurrency().code}</div>
			</div>
		</div>
	);
};

CurrencyInput.propTypes = {
	label: PropTypes.string,
	value: PropTypes.number,
	setValue: PropTypes.func
};

export default CurrencyInput;
