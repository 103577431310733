import React from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import LinkedExperienceItem from './LinkedExperienceItem';
import PropTypes from 'prop-types';

const LinkedExperiences = ({ experiences, setExperiences }) => {
	const dictionary = useDictionary();

	return (
		<div
			className="new-experience-form-wrapper "
			style={{ background: 'white', flexFlow: 'column' }}
		>
			<div
				className="new-experience-form-container "
				style={{ height: '0', flexGrow: '1', overflowY: 'hidden' }}
			>
				<h2 style={{ fontSize: '24px' }}>{dictionary.link_experiences}</h2>
				<div style={{ marginTop: '16px' }} className="text">
					{dictionary.think_about_experiences}
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '24px' }}>
					{dictionary.selected_experiences +
						'(' +
						experiences?.filter((item) => item.selected)?.length +
						')'}
				</h3>

				<div
					style={{
						width: '100%',
						flexGrow: '1',
						display: 'flex',
						flexFlow: 'column',
						overflowY: 'auto',
						marginTop: '16px'
					}}
					className="thin-scrollbar"
				>
					{experiences.map((experiece) => (
						<LinkedExperienceItem
							key={experiece}
							item={experiece}
							items={experiences}
							setItems={setExperiences}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

LinkedExperiences.propTypes = {
	experiences: PropTypes.array,
	setExperiences: PropTypes.func
};

export default LinkedExperiences;
