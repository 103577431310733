import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import React, { useEffect, useState } from 'react';
import { ValidationHelper } from '../../../../../../utilities/helpers/ValidationHelper';
import PropTypes from 'prop-types';

const SendInvoiceDialog = ({ setShow }) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);
	const [value, setValue] = useState('');

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShow(false);
		}, 800);
	};

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">{dictionary.send_invoice}</div>
					<div
						className="dialog-close-button"
						onClick={() => onCancelClicked()}
					/>
				</div>
				<div className="dialog-title-divider" />
				<div className="dialog-content-container">
					<div className="group-inputs">
						<label>{dictionary.email}</label>
						<input
							data-testid="email-input"
							type="text"
							value={value}
							onChange={(e) => setValue(e.target.value)}
						/>
					</div>
				</div>
				<div className="dialog-title-divider" />
				<div
					className="dialog-buttons-container"
					style={{ padding: '12px', marginBottom: '0' }}
				>
					<button
						onClick={() => {
							onCancelClicked();
						}}
						className="tertiary-button"
						data-testid="cancel-button"
					>
						{dictionary.cancel}
					</button>

					<button
						disabled={!ValidationHelper.validateEmail(value)}
						onClick={() => {}}
						className="button"
						data-testid="send-button"
					>
						{dictionary.send}
					</button>
				</div>
			</div>
		</div>
	);
};

SendInvoiceDialog.propTypes = {
	setShow: PropTypes.func.isRequired
};

export default SendInvoiceDialog;
