import {
	MAX_FILE_UPLOAD_IMAGES,
	MAX_FILE_UPLOAD_IMAGES_LABEL,
	MAX_FILE_UPLOAD_VIDEOS,
	MAX_FILE_UPLOAD_VIDEOS_LABEL
} from '../../../../../../config';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import CustomToast from '../../../../common/CustomToast';
import FormLoader from '../../../../common/FormLoader';
import { getExperienceAssets } from '../../../provider_experiences/networking';
import UploadedItem from './UploadedItem';
import UploadingItem from './UploadingItem';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const PhotosAndVideos = ({ setEnabled, tempId, editId }) => {
	const dictionary = useDictionary();
	const inputRef = useRef();
	const [uploadedFiles, setUploadedFiles] = useState([]);
	const [filesToUpload, setFilesToUpload] = useState([]);
	const [showLoader, setShowLoader] = useState(false);
	const [inputKey, setInputKey] = useState(0);

	const IMAGE_SIZE_LIMIT = parseInt(MAX_FILE_UPLOAD_IMAGES);
	const VIDEO_SIZE_LIMIT = parseInt(MAX_FILE_UPLOAD_VIDEOS);

	let count = 0;

	useEffect(() => {
		setEnabled(true);
		getExperienceAssetsAsync();
	}, []);

	const getExperienceAssetsAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getExperienceAssets(
				editId !== '' ? editId : tempId
			);
			setUploadedFiles(data);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const onDrop = useCallback(
		(acceptedFiles) => {
			let newFiles = [];
			let maxFiles = 6 - uploadedFiles.length;

			acceptedFiles.slice(0, maxFiles).forEach((file) => {
				const isImage = file.type.startsWith('image/');
				const isVideo = file.type.startsWith('video/');
				const isSizeValid =
					(isImage && file.size <= IMAGE_SIZE_LIMIT) ||
					(isVideo && file.size <= VIDEO_SIZE_LIMIT);

				if (isSizeValid) {
					newFiles.push(file);
				} else {
					const limitInMB = isImage
						? MAX_FILE_UPLOAD_IMAGES_LABEL
						: MAX_FILE_UPLOAD_VIDEOS_LABEL;
					toast.error(
						<CustomToast
							message={`${file.name}: File size exceeds ${limitInMB} limit`}
						/>,
						{
							position: 'bottom-left',
							autoClose: 5000,
							hideProgressBar: true,
							closeOnClick: true,
							pauseOnHover: false,
							draggable: false,
							progress: undefined,
							closeButton: false,
							icon: false,
							className: 'toast-white-error'
						}
					);
				}
			});

			setFilesToUpload((filesToUpload) => [...filesToUpload, ...newFiles]);

			// Reset the value of the file input element
			if (inputRef.current) {
				inputRef.current.value = '';
			}
		},
		[uploadedFiles]
	);

	const { getRootProps, getInputProps } = useDropzone({
		accept: ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4', 'video/webm'],
		// maxSize: 5200000,
		onDrop
	});

	const getUploadVisibility = () => {
		return uploadedFiles.length === 0 && filesToUpload.length === 0;
	};

	const onAssetUploadFinished = (file, uploadedFile) => {
		setFilesToUpload((filesToUpload) =>
			filesToUpload.filter((item) => item.name !== file.name)
		);
		addUploadedItem(uploadedFile);
	};

	const addUploadedItem = (file) => {
		setUploadedFiles((uploadedFiles) => [...uploadedFiles, file]);
	};

	return (
		<div className="new-experience-form-wrapper">
			<div
				className="new-experience-form-container thin-scrollbar-trans"
				style={{ position: 'relative' }}
			>
				<h2>{dictionary.add_photos_and_videos}</h2>

				<div style={{ display: getUploadVisibility() ? 'block' : 'none' }}>
					<div className="upload-media-wrapper" {...getRootProps()}>
						<input
							ref={inputRef}
							{...getInputProps()}
							className="gallery-upload-input"
							data-testid="gallery-upload-input"
							max={5}
							key={inputKey}
						/>
						<div className="upload-media-container">
							<div className="upload-gallery-icon" />
							<h3 style={{ fontSize: '18px', marginTop: '25px' }}>
								{dictionary.drag_photos_and_videos}
							</h3>
							<div
								style={{
									marginTop: '10px',
									whiteSpace: 'pre-line',
									textAlign: 'center'
								}}
								className="text"
							>
								{dictionary.add_a_maximum_of_5}
							</div>
							<div
								style={{ marginTop: '25px' }}
								className="secondary-button"
								data-testid="upload-from-device-button"
							>
								{dictionary.upload_from_device}
							</div>
						</div>
					</div>
				</div>
				<div style={{ display: getUploadVisibility() ? 'none' : 'block' }}>
					<div className="upload-info-row">
						<h2
							style={{
								fontSize: '20px',
								display: uploadedFiles.length >= 6 ? 'none' : 'block'
							}}
						>
							{dictionary.upload_a_maximum_of +
								' ' +
								(6 - uploadedFiles.length) +
								' ' +
								dictionary.more}
						</h2>
						<div
							className={`secondary-button ${
								uploadedFiles.length >= 6
									? 'secondary-button-upload-disabled'
									: ''
							}`}
							onClick={() => {
								if (uploadedFiles.length < 6) {
									document.querySelector('.gallery-upload-input').click();
								}
							}}
							data-testid="upload-from-device-button"
						>
							{dictionary.upload_from_device}
						</div>
					</div>
					<div className="upload-files-container">
						{filesToUpload.map(
							(file) =>
								file && (
									<UploadingItem
										onAssetUploadFinished={onAssetUploadFinished}
										tempId={editId !== '' ? editId : tempId}
										key={count++}
										file={file}
									/>
								)
						)}
						{uploadedFiles.map(
							(file) =>
								file && (
									<UploadedItem
										tempId={editId !== '' ? editId : tempId}
										setShowLoader={setShowLoader}
										onItemDeleted={() => {
											setInputKey(inputKey + 1); // Update the input key when a file is deleted
											getExperienceAssetsAsync();
										}}
										key={count++}
										file={file}
									/>
								)
						)}
					</div>
				</div>
				<h3 style={{ fontSize: '16px', marginTop: '30px' }}>
					{dictionary.requirements}
				</h3>
				<ul>
					<li className="text">{dictionary.a_variety_of_details}</li>
					<li className="text">{dictionary.candid_moments}</li>
					<li className="text">{dictionary.good_quality}</li>
					<li className="text">{dictionary.maximum_file_size}</li>
				</ul>
				{/* <h3 style={{fontSize: '12px'}}>{dictionary.learn_more_photo}</h3>*/}
				<FormLoader showLoader={showLoader} />
			</div>
			{/* <ToastContainer /> */}
		</div>
	);
};

PhotosAndVideos.propTypes = {
	setEnabled: PropTypes.func,
	tempId: PropTypes.string,
	editId: PropTypes.string
};

export default PhotosAndVideos;
