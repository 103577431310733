import PropTypes from 'prop-types';
import React from 'react';
import caretLeft from '../../../../assets/icons/ic_caret_left.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useExperienceSlotStatuses } from '../../../../utilities/hooks/useExperienceSlotStatuses';
import MultipleCustomSlotCheckbox from '../../common/MultipleCustomSlotCheckbox';

const SelectFilterSlot = (props) => {
    const dictionary = useDictionary();
    const slotStatuses = useExperienceSlotStatuses();

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowSlotSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(slotStatuses).map((slotStatus) => (
                <MultipleCustomSlotCheckbox
                    label={slotStatuses[slotStatus].text}
                    checbboxObj={props.slotCheckeds}
                    itemKey={slotStatus}
                    onFilterSlotSelected={props.onFilterSlotSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    key={slotStatuses[slotStatus].id}
                />
            ))}
        </div>
    );
}

SelectFilterSlot.propTypes = {
	setShowBoxFilter: PropTypes.func,
	setShowSlotSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    slotCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterSlotSelected: PropTypes.func
};

export default SelectFilterSlot;
