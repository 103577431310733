import eye from '../../../../../assets/icons/ic_eye.svg';
import ImageHelper from '../../../../../utilities/helpers/ImageHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import placeholder from "../../../../../assets/images/bg_placeholder_square.svg";
import {getMatchedCountryData} from "../../../../../utilities/helpers/LanguageHelper";
import Flag from 'react-world-flags';
const ExperienceListItemCompact = ({
	experience,
	setSelectedAddGroup,
    viewExperienceHandler,
    allLanguages
}) => {
	const dictionary = useDictionary();
	const history = useHistory();
	const [isCopied, setIsCopied] = useState(null);

	const onCopyClicked = (shareUrl) => {
		navigator.clipboard.writeText(shareUrl).then(
			function () {
				setIsCopied(shareUrl + '_compact');
				setTimeout(() => {
					setIsCopied(null);
				}, 2000);
			},
			// eslint-disable-next-line no-unused-vars
			function (err) {}
		);
	};
	

	const getStatusLabel = () => {
		let statuses = {
			1: {
				label: dictionary.available,
				class: 'experience-item-label-active'
			},
			2: {
				label: dictionary.in_progress,
				class: 'experience-item-label-unavailable'
			},
			3: {
				label: dictionary.unavailable,
				class: 'experience-item-label-unavailable'
			},
			4: {
				label: dictionary.paused,
				class: 'experience-item-label-error'
			},
			5: {
				label: dictionary.add_availability,
				class: 'experience-item-label-add-availability'
			}
		};
		return (
			<div
				onClick={() => {
					if (experience.status.id === 5) {
						history.push('/calendar');
					}
				}}
				className={
					'experience-item-label ' + statuses[experience.status.id].class
				}
				data-testid="experience-status-label"
			>
				{statuses[experience.status.id].label}
			</div>
		);
	};

	const getViewButton = () => {
		const buttonText = dictionary.preview;
		return (
				<a
					onClick={() => viewExperienceHandler(experience.view_url)}
					className="experience-item-button-container"
					rel="noopener noreferrer"
					data-testid="experience-item-view-button"
				>
					<img src={eye} />
					<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
				</a>
			);
	};

	const getPreviewViewButton = () => {
		const buttonText = dictionary.view;
		const handleViewClick = () => {
			window.open(experience.view_url, '_blank', 'noreferrer');
		};

		return (
			<div
				onClick={handleViewClick}
				className="experience-item-button-container"
				data-testid="experience-item-view-button"
			>
				<img src={eye} />
				<h4 style={{ fontSize: '12px' }}>{buttonText}</h4>
			</div>
		);
	};

	const getCopyButton = () => {
		return (
			<div
				onClick={() => onCopyClicked(experience.view_url)}
				className="experience-item-button-container"
				data-testid="experience-item-copy-button"
			>
				<div
					className={`share-link-copy-icon ${isCopied === experience.view_url + `_compact` ? 'copied' : ''}`}
					data-testid="share-link-copy-icon"
				></div>
				<h4 style={{ fontSize: '12px' }}>{dictionary.copy}</h4>
			</div>
		);
	};
	const getGroupButton = () => {
		return (
			<div
				onClick={() => setSelectedAddGroup(experience)}
				className="experience-item-button-container"
				data-testid="experience-item-delete-button"
			>
				<h4 style={{ fontSize: '12px' }}>+ {dictionary.add_to_group}</h4>
			</div>
		);
	};

	return (
		<div key={experience?.id} className="experience-item-compact-container">
			<div className="experience-item-row" style={{ maxWidth: 'unset' }}>
				{getStatusLabel()}
				<div className="experience-flags-container">
					{experience.languages.slice(0, 2)
						?.map(({ language_id }, index) => {
							const { code } = allLanguages?.find((languageItem) => languageItem.id === language_id) || {};
							return (
								<div key={index} className="experience-flag-item">
									<Flag
										code={getMatchedCountryData(code)?.countryCode?.toLowerCase()}
										width={20}
										fallback={
											<span>
												{getMatchedCountryData(code)?.countryCode?.toLowerCase()}
											</span>
										}
									/>
								</div>

							)
						})}
					{experience.languages.length > 2 &&
						<div className="experience-flag-item more-label">
							+ {experience.languages.length - 2}
						</div>
					}
				</div>
			</div>

			<h3 style={{ marginTop: '12px' }}>{experience.title}</h3>
			<h4 style={{ marginTop: 10 }}>{experience?.provider.brand_name}</h4>
			<div
				style={{ marginTop: '10px', opacity: '0.6' }}
				className="text ellipsize"
			>
				{experience.description}
			</div>
			<div className="flex-filler" />
			<div
				className="experience-item-buttons-container experience-item-buttons-container-collection"
				style={{ flexFlow: 'row' }}
			>
				{getViewButton()}
				{getGroupButton()}
				{getPreviewViewButton()}
				{getCopyButton()}
			</div>
			<div className="experience-compact-thumbs-container">
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[0]
							? ImageHelper.getFullImageUrl(
									experience?.assets[0]
							  )
							: placeholder
					}
				/>
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[1]
							? ImageHelper.getFullImageUrl(
									experience?.assets[1]
							  )
							: placeholder
					}
				/>
				<img
					className="experience-compact-thumb"
					onError={(e) => {
						e.currentTarget.src = placeholder;
					}}
					src={
						experience?.assets[2]
							? ImageHelper.getFullImageUrl(
									experience?.assets[2]
							  )
							: placeholder
					}
				/>
				<div
					className="experience-thumb-color-line"
					style={{ background: experience.color }}
				/>
			</div>
		</div>
	);
};

ExperienceListItemCompact.propTypes = {
	experience: PropTypes.object,
	setSelectedAddGroup: PropTypes.func.isRequired,
	viewExperienceHandler: PropTypes.func,
	allLanguages: PropTypes.array,
};

export default ExperienceListItemCompact;
