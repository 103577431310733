import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PageLoader from '../../../../common/PageLoader';
import { getExperienceCategories } from '../../../provider_experiences/networking';
import ExperienceCategory from './ExperienceCategory';
import ExperienceCategoryTags from './ExperienceCategoryTags';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const ExperienceCategories = ({
	setEnabled,
	selectedTags,
	setSelectedTags
}) => {
	const dictionary = useDictionary();
	const [categories, setCategories] = useState([]);
	const [selectedCategory, setSelectedCategory] = useState({});
	const [showTags, setShowTags] = useState(false);
	const [showLoader, setShowLoader] = useState(false);

	useEffect(() => {
		getExperienceCategoriesAsync();
	}, []);

	useEffect(() => {
		setEnabled(selectedTags?.length > 0);
	}, [selectedTags]);

	const addTag = (tag) => {
		if (selectedTags.length < 3) {
			let newTags = Object.assign([], selectedTags);
			newTags.push(tag);
			setSelectedTags(newTags);
		}
	};

	const removeTag = (tag) => {
		let newTags = Object.assign([], selectedTags);
		ArrayHelper.removeItemOnce(newTags, tag);
		setSelectedTags(newTags);
	};

	const getExperienceCategoriesAsync = async () => {
		setShowLoader(true);
		try {
			const { data } = await getExperienceCategories();
			setCategories(data);
			setShowLoader(false);
		} catch (error) {
			setShowLoader(false);
		}
	};

	return (
		<div className="new-experience-categories-wrapper">
			<div
				className="new-experience-categories-container"
				data-testid="new-experience-categories-container"
			>
				<h2 style={{ fontSize: 24 }}>{dictionary.what_types_of_activities}</h2>
				{selectedTags.length > 0 && (
					<>
						<h3
							style={{
								fontSize: '16px',
								marginTop: '32px'
							}}
						>
							{dictionary.selected_tags + ' (' + selectedTags?.length + '/3)'}
						</h3>
						<div className="new-experience-selected-tags-container">
							{selectedTags?.map((tag, index) => (
								<div
									key={index}
									onClick={() => removeTag(tag)}
									className="experience-tag experience-tag-selected"
								>
									{tag.title}
								</div>
							))}
						</div>
					</>
				)}
				<div style={{ margin: '32px 0 16px' }} className="text">
					{dictionary.select_up_to_3_tags}
				</div>
				<div className="new-experience-category-items-container thin-scrollbar">
					{categories?.map((item, index) => (
						<ExperienceCategory
							key={index}
							setShowTags={setShowTags}
							setSelectedCategory={setSelectedCategory}
							category={item}
						/>
					))}
				</div>
			</div>
			<ExperienceCategoryTags
				setShowTags={setShowTags}
				showTags={showTags}
				addTag={addTag}
				category={selectedCategory}
				selectedTags={selectedTags}
			/>
			<PageLoader showLoader={showLoader} />
			{/* <ToastContainer /> */}
		</div>
	);
};

ExperienceCategories.propTypes = {
	setEnabled: PropTypes.func,
	selectedTags: PropTypes.array,
	setSelectedTags: PropTypes.func
};

export default ExperienceCategories;
