import axios from 'axios';
import {
	AVAILABLE_EXPERIENCES_URL,
	EXPERIENCE_RESOURCES_URL,
} from '../../../../config';
import { DataTableHelper } from '../../../../utilities/helpers/DataTableHelper';

export function getAllResources(sortParam, pageSize, pageNumber, cancelToken) {
	return axios.get(
		EXPERIENCE_RESOURCES_URL +
			DataTableHelper.prepareParameters(sortParam, pageSize, pageNumber),
		{ cancelToken: cancelToken }
	);
}

export function getResourceById(id) {
	return axios.get(EXPERIENCE_RESOURCES_URL + '/' + id);
}

export function getAvailableExperiences(resourceId) {
	return axios.get(
		AVAILABLE_EXPERIENCES_URL + (resourceId ? '?resource_id=' + resourceId : '')
	);
}

export function deleteResource(id) {
	return axios.delete(EXPERIENCE_RESOURCES_URL + '/' + id);
}

export function changeResourceStatus(resourceId, newStatus) {
	return axios.put(`${EXPERIENCE_RESOURCES_URL}\\${resourceId}\\status`, {
		status_id: newStatus
	});
}

export function createExperienceResource(
	title,
	description,
	quantity,
	file,
	experiences,
	id,
	update = false
) {
	const formData = new FormData();

	if (file) {
		formData.append('file', file, file.name);
	}

	formData.append('title', title);
	formData.append('description', description);
	formData.append('quantity', quantity);

	if (experiences && experiences?.length > 0) {
		experiences.forEach((item) => {
			formData.append('linked_experiences[]', item);
		});
	}
	//formData.append('linked_experiences[]',experiences)

	if (!update) {
		return axios.post(EXPERIENCE_RESOURCES_URL, formData);
	} else {
		return axios.post(EXPERIENCE_RESOURCES_URL + '/' + id, formData);
	}
}
