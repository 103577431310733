import { JSEncrypt } from 'jsencrypt';

export const CryptoHelper = (function () {
	let jsEncrypt = new JSEncrypt({
		default_key_size: '2048'
	});

	function encryptData(data, publicKey) {
		jsEncrypt.setPublicKey(atob(publicKey));
		return jsEncrypt.encrypt(data);
	}

	function generateSessionId(length) {
		var result = '';
		var characters =
			'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for (var i = 0; i < length; i++) {
			result += characters.charAt(Math.floor(Math.random() * charactersLength));
		}
		return result;
	}

	return {
		encryptData: function (data, publicKey) {
			return encryptData(data, publicKey);
		},
		generateSessionId: function (length) {
			return generateSessionId(length);
		}
	};
})();
