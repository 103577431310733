import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { changePassword } from '../__redux/authCrud';
import * as auth from '../__redux/authRedux';
import * as Yup from 'yup';
import FormikHelper from '../../../../utilities/helpers/FormikHelper';
import ShowHidePassword from '../../components/custom/ShowHidePassword';
import { CryptoHelper } from '../../../../utilities/helpers/CryptoHelper';
import '../../../../assets/css/header.css';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import FormLoader from '../../common/FormLoader';
import { bindActionCreators } from 'redux';
import * as common from '../../common/__redux/commonRedux';
import PropTypes from 'prop-types';

const ResetPasswordForm = (props) => {
	const dictionary = useDictionary();
	const { hash } = useParams();
	const [showForm, setShowForm] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const history = useHistory();
	const ActivationSchema = Yup.object().shape({
		password: Yup.string()
			.min(3, 'Too Short!')
			.max(40, 'Too Long!')
			.required(dictionary.required),
		password_again: FormikHelper.getDefaultYupPassword(true, dictionary).oneOf(
			[Yup.ref('password'), null],
			dictionary.passwords_must_match
		)
	});

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const formik = useFormik({
		initialValues: {
			password: ''
		},
		onSubmit: (values, { setSubmitting }) => {
			setShowLoader(true);

			changePassword(
				CryptoHelper.encryptData(values.password, props.metadata),
				hash,
				props.sessionId
			)
				.then(() => {
					setShowLoader(false);
					props.actions.setSuccessMessage(
						dictionary.password_successfully_changed
					);
					setTimeout(() => {
						props.actions.setSuccessMessage('');
						props.history.push('/login');
					}, 2000);
				})
				.catch((error) => {
					setShowLoader(false);
					setSubmitting(false);
					if (error.response) {
						props.actions.setErrorMessage(
							error.response.data.error_code.toString()
						);
						props.history.push('/login');
					}
				});
		},
		validateOnMount: true,
		validationSchema: ActivationSchema
	});

	FormikHelper.setFormik(formik);

	return (
		<form
			style={{ opacity: showForm ? '1' : '0' }}
			className="login-form-wrapper"
			onSubmit={formik.handleSubmit}
		>
			<div className="login-form-container" style={{ position: 'relative' }}>
				<div
					className="login-logo"
					style={{ cursor: 'pointer' }}
					onClick={() => history.push('/login')}
				/>
				<div className="title-divider" />
				<h1>{dictionary.set_up_your_new_password}</h1>
				<div
					className="text"
					style={{ marginTop: '20px', marginBottom: '30px' }}
				>
					{dictionary.remember_new_password}
				</div>
				<div className="login-form-content-container">
					{ShowHidePassword(
						'password',
						dictionary.new_password,
						'',
						true,
						formik,
						true
					)}
					{ShowHidePassword(
						'password_again',
						dictionary.confirm_password,
						'',
						true,
						formik,
						true
					)}

					<div className="group-inputs" style={{ marginTop: '50px' }}>
						<button
							data-testid="submit-button"
							type="submit"
							className={
								formik.isValid
									? 'button primary-button login-button'
									: 'button login-button button-disabled'
							}
						>
							{dictionary.confirm}
						</button>
					</div>
					<FormLoader showLoader={showLoader} />
				</div>
			</div>
		</form>
	);
};

ResetPasswordForm.propTypes = {
	actions: PropTypes.object,
	metadata: PropTypes.object,
	sessionId: PropTypes.string,
	history: PropTypes.object
};

export default connect(null, (dispatch) => {
	return {
		actions: bindActionCreators(
			{ ...auth.actions, ...common.actions },
			dispatch
		)
	};
})(withRouter(ResetPasswordForm));
