import PropTypes from 'prop-types';
import React from 'react';

const MoneyInput = ({ setValue, value, onBlur, min }) => {
	function handleMoneyChange(e) {
		if (e.key !== 'Backspace') {
			if (e.target.value.includes('.')) {
				if (e.target.value.split('.')[1].length >= 2) {
					let num = parseFloat(e.target.value);
					let cleanNum = num.toFixed(2);
					e.target.value = cleanNum;
					e.preventDefault();
				}
			}
		}
	}

	return (
		<div className="money-input-container">
			<input
				onKeyDown={(e) => handleMoneyChange(e)}
				className="money-input"
				data-testid="money-input"
				min={min || 0}
				type="number"
				value={value}
				onChange={(e) => {
					setValue(e);
				}}
				onBlur={onBlur}
			/>
		</div>
	);
};

MoneyInput.propTypes = {
	min: PropTypes.number,
	setValue: PropTypes.func,
	onBlur: PropTypes.func,
	value: PropTypes.string
};

export default MoneyInput;
