import { shallowEqual, useSelector } from 'react-redux';
import { dictionaryMap } from '../../assets/dictionaries/dictionary';

export const usePaymentStatuses = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	let dictionary = user ? dictionaryMap[user.language_id] : dictionaryMap[143];

	return {
		paid: {
			id: 1,
			text: dictionary.paid,
			class: 'booking-status-confirmed',
			icon: 'booking-status-paid-icon'
		},
		failed: {
			id: 2,
			text: dictionary.failed,
			class: 'booking-status-failed'
		},
		refunded: {
			id: 3,
			text: dictionary.refunded,
			class: 'booking-status-cancelled'
		},
		pending: {
			id: 4,
			text: dictionary.pending,
			class: 'booking-status-pending'
		},
		no_charge: {
			id: 5,
			text: dictionary.no_charge,
			class: 'booking-status-no-charge'
		},
		partial_refunded: {
			id: 6,
			text: dictionary.partial_refunded,
			class: 'booking-status-partial-refunded'
		}
	};
};
