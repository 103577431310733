import moment from 'moment/moment';

export const DateFormatHelper = (function () {

    function getDayNameFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('ddd');
    }

    function getFullDayNameFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('dddd');
    }

    function getMonthNameFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('MMM');
    }

    function getMonthNameTextFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('Do');
    }

    function getDayOfMonthFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('D');
    }

    function getYearFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('YYYY');
    }

    function getTimeFromTimestamp(timestamp) {
        return moment.unix(timestamp).format('H:mm');
    }

    function getTimeFromTo(startTime, endTime) {
        return getTimeFromTimestamp(startTime) + ' - ' + getTimeFromTimestamp(endTime);
    }

    function isSameDay(startTime, endTime) {
        return moment.unix(startTime).dayOfYear() === moment.unix(endTime).dayOfYear();
    }

    function timestampIsFutureDay(startTime){
        return moment.unix(startTime).valueOf() > moment().valueOf();
    }

    function getFrequencyLabel(frequency, dictionary){
        if(frequency === dictionary.weekly.toLowerCase()){
            return dictionary.weeks
        } else {
            return dictionary.months
        }
    }

    function weekOfMonth(startTime) {
        let mom = moment.unix(startTime);
        return mom.week() - moment(mom).startOf('month').week() + 1;
    }

    function ordinalSuffixOf(i, dictionary) {
        let j = i % 10,
            k = i % 100;
        if (j == 1 && k != 11) {
            return i + dictionary.st;
        }
        if (j == 2 && k != 12) {
            return i + dictionary.nd;
        }
        if (j == 3 && k != 13) {
            return i + dictionary.rd;
        }
        return i + dictionary.th;
    }

    return {
        /**
         * Example: Mon, Oct 9, 14:30
         *
         * @param startTime
         * @returns {*|string}
         */
        dayMonthTimeFormat: function (startTime, ) {
            let format = '';
            format = getDayNameFromTimestamp(startTime);
            format += ', ' + getMonthNameFromTimestamp(startTime) + ' ';
            format += getDayOfMonthFromTimestamp(startTime) + ', ';
            format += getTimeFromTimestamp(startTime);

            return format;
        },
        /**
         * Example: Oct 9, 14:30
         *
         * @param startTime
         * @returns {*|string}
         */
        monthTimeFormat: function (startTime) {
            let format = '';
            format += getMonthNameFromTimestamp(startTime) + ' ';
            format += getDayOfMonthFromTimestamp(startTime) + ', ';
            format += getTimeFromTimestamp(startTime);

            return format;
        },
        /**
         * Example: Nov 12, 2021, 11:41
         *
         * @param timestamp
         * @returns {string}
         */
        monthYearTimeFormat: function (timestamp) {
            let format = '';
            format += getMonthNameFromTimestamp(timestamp) + ' ';
            format += getDayOfMonthFromTimestamp(timestamp) + ', ';
            format += getYearFromTimestamp(timestamp) + ', ';
            format += getTimeFromTimestamp(timestamp);

            return format;
        },
        /**
         * Example: Mon, Oct 9, 14:30 - 15:30
         *          Fri, Sep 29, 14:30 - Mon, Oct 2, 15:30
         * @param startTime
         * @param endTime
         * @returns {*}
         */
        rangeDayMonthTimeFormat: function (startTime, endTime) {
            let format = '';
            format = this.dayMonthTimeFormat(startTime);
            format += ' - ';
            if(isSameDay(startTime, endTime)){
                format += getTimeFromTimestamp(endTime);
            } else {
                format += this.dayMonthTimeFormat(endTime);
            }

            return format;
        },
        /**
         * Example: Mon, Oct 9, 2021
         *
         * @param startTime
         * @returns {*|string}
         */
        dayMonthYearFormat: function (startTime) {
            let format = '';
            format = getDayNameFromTimestamp(startTime);
            format += ', ' + getMonthNameFromTimestamp(startTime);
            format += ' ' + getDayOfMonthFromTimestamp(startTime);

            return format;
        },
        /**
         * Example: Mon, Oct 9, 2021
         *          Fri, Sep 29 - Mon, Oct 2
         * @param startTime
         * @param endTime
         * @returns {*}
         */
        rangeDayMonthYearFormat: function (startTime, endTime) {
            let format = '';
            format = this.dayMonthYearFormat(startTime);
            if(isSameDay(startTime, endTime)){
                format += ', ' + getYearFromTimestamp(startTime);
            } else {
                format += ' - ' + this.dayMonthYearFormat(endTime);
            }

            return format;
        },
        /**
         * Example: Mon, Oct 9, 2021
         *          Fri, Sep 29, 14:30 - Mon, Oct 2, 15:30
         * @param startTime
         * @param endTime
         * @returns {*}
         */
        rangeDayMonthYearHourFormat: function (startTime, endTime) {
            let format = '';
            format = this.monthYearTimeFormat(startTime);
            if(isSameDay(startTime, endTime)){
                format += ' - ' + getTimeFromTimestamp(endTime);
            } else {
                format += ' - ' + this.monthYearTimeFormat(endTime);
            }

            return format;
        },
        /**
         * Example: 10:00 - 20:00
         *
         * @param startTime
         * @param endTime
         * @returns {string}
         */
        timeRangeFormat: function (startTime, endTime) {
            return getTimeFromTo(startTime, endTime);
        },
        /**
         * Example: Weekly on Mon, Wed, Fri until Oct 14
         *
         * @param setting
         * @param dictionary
         * @returns {string}
         */
        slotRecurringFormat: function (setting, dictionary) {
            if (!setting) {
                return dictionary.no_repeat;
            }

            let label = ''
            if(setting?.frequency){
                let until = setting?.repeat_until;
                // Weekly or Monthly on
                let intervalLabel = setting?.frequency.charAt(0).toUpperCase() + setting?.frequency.slice(1) + ' ' + dictionary.on.toLowerCase() + ' ';
                if (setting?.interval > 1) {
                    // Every 2 weeks/months on
                    intervalLabel = dictionary.every + ' ' + setting?.interval + ' ' + getFrequencyLabel(setting?.frequency, dictionary) +  ' ' + dictionary.on.toLowerCase()
                }

                let repeatOnLabel = '';
                if(setting?.repeat_week_days?.length > 0 && setting?.repeat_week_days[0] !== ''){
                    // Su,Mo
                    let repeat = setting?.repeat_week_days;
                    let days = '';
                    repeat?.map((day) => (days += dictionary.days_short_map[day] + ', '));
                    repeatOnLabel = days.substring(0, days.length - 2);
                    //In monthly add week number of month
                    if(setting.frequency === dictionary.monthly){
                        let weekNumberOfMonth = weekOfMonth(setting?.start_time);
                        if(weekNumberOfMonth === 5){
                            repeatOnLabel = dictionary.last + ' ' + repeatOnLabel;
                        } else {
                            repeatOnLabel = ordinalSuffixOf(weekOfMonth(setting?.start_time), dictionary) + ' ' + repeatOnLabel;
                        }
                    }

                } else if(setting?.frequency === dictionary.monthly){
                    // 15th
                    repeatOnLabel = getMonthNameTextFromTimestamp(setting?.start_time);
                }

                label +=
                    intervalLabel +
                    ' ' +
                    repeatOnLabel +
                    ' ' +
                    dictionary.until.toLowerCase() +
                    ' ' +
                    getMonthNameFromTimestamp(until) +
                    ' ' +
                    getDayOfMonthFromTimestamp(until);
            }

            if (setting?.day_repeat === 2) {
                if (label === '') {
                    label = dictionary.repeat_day_label + ' ' + dictionary.repeat_from + ' ';
                } else {
                    label += ', ' + dictionary.repeat_from + ' ';
                }

                label += getTimeFromTimestamp(setting.start_time);
                label += ' ' + dictionary.repeat_to + ' ';
                label += getTimeFromTimestamp(setting.end_time)
            }

            return label;
        },
        /**
         * Example: This and all following slots occurring every Tuesday at 14:00 - 16:00
         *
         * @param startTime
         * @param endTime
         * @param dictionary
         * @returns {string}
         */
        slotOccurringDayTimeFormat: function (startTime, endTime, dictionary) {
            let label = dictionary.this_and_following;

            label += ' ' + getFullDayNameFromTimestamp(startTime);
            label += ' ' + dictionary.at;
            label += ' ' + getTimeFromTo(startTime, endTime);

            return label;
        },
        /**
         *
         * @param startTime
         * @param endTime
         * @param dictionary
         * @returns {string}
         */
        slotOccurringDayFormat: function (startTime, endTime, dictionary) {
            let label = dictionary.this_and_following;
            label += ' ' + getFullDayNameFromTimestamp(startTime);

            return label;
        },
        /**
         * Check if giver timestamp is in the future
         *
         * @param timestamp
         * @returns {boolean}
         */
        isFutureDay: function (timestamp){
            return timestampIsFutureDay(timestamp);
        }
    };
})();