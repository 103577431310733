import { ArrayHelper } from '../../../../../../utilities/helpers/ArrayHelper';
import React from 'react';
import PropTypes from 'prop-types';

const CustomRepeatDayItem = ({
	item,
	index,
	disabled,
	selection,
	setSelection
}) => {
	const onItemClicked = () => {
		let newSelection = Object.assign([], selection);
		ArrayHelper.toggleItem(newSelection, index);
		setSelection(newSelection);
	};

	return (
		<div
			key={index}
			onClick={!disabled ? onItemClicked : null}
			className={`
				${
					selection.includes(index)
						? 'repeat-day-item repeat-day-item-active'
						: 'repeat-day-item'
				} ${disabled && 'repeat-day-item-disabled'}
			`}
			data-testid="repeat-day-item"
		>
			{item}
		</div>
	);
};

CustomRepeatDayItem.propTypes = {
	item: PropTypes.string,
	index: PropTypes.number,
	disabled: PropTypes.bool,
	selection: PropTypes.array,
	setSelection: PropTypes.func
};

export default CustomRepeatDayItem;
