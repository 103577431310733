import React from 'react';
import PropTypes from 'prop-types';

const CustomCheckbox = ({
	label,
	checked,
	setChecked,
	name = null,
	// onChange = null,
	type
}) => {
	const getSize = () => {
		if (!type) {
			return '16px';
		} else {
			if (type === 1) {
				return '16px';
			} else if (type === 2) {
				return '24px';
			}
		}
	};

	return (
		<div
			onClick={() => setChecked(!checked)}
			className="form-check-container"
			style={{ marginLeft: '0' }}
			data-testid={name ? name : 'custom-checkbox'}
		>
			<div
				style={{ width: getSize(), height: getSize() }}
				className={
					'custom-checkbox ' +
					(checked
						? type === 1 || !type
							? 'custom-checkbox-checked'
							: 'custom-checkbox-checked-medium'
						: 'custom-checkbox')
				}
			/>
			<label
				style={{ marginBottom: 0, marginLeft: '10px', fontWeight: 400 }}
				className="checkbox-label"
			>
				{label}
			</label>
			{name ? (
				<input
					name={name}
					type="checkbox"
					style={{ display: 'none' }}
					checked={checked}
					readOnly={true}
					data-testid={name}
				/>
			) : null}
		</div>
	);
};

CustomCheckbox.propTypes = {
	label: PropTypes.string,
	checked: PropTypes.bool,
	setChecked: PropTypes.func,
	name: PropTypes.string,
	onChange: PropTypes.func,
	type: PropTypes.number
};

export default CustomCheckbox;
