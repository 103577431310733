import React from 'react';
import PropTypes from 'prop-types';
import '../../../../assets/css/custom_select.css';
import CustomSelectItem from './CustomSelectItem';
import useComponentVisible from '../../../../utilities/hooks/useComponentVisible';

const CustomSelect = ({
	title,
	value,
	setValue,
	items,
	placeholder,
	onValueChanged = null,
	absolute = false,
	style,
	disabled = false
}) => {
	const { ref, isComponentVisible, setIsComponentVisible } =
		useComponentVisible(false);

	const getValueLabel = (value) => {
		let item = items.find((item) => item.value === value);
		if (item?.title) {
			return item?.title;
		} else {
			return item?.name;
		}
	};

	const onItemSelected = (item) => {
		setIsComponentVisible(!isComponentVisible);
		setValue(item.value);
		if (onValueChanged !== null) {
			onValueChanged(item.value);
		}
	};

	const getSelectClasses = () => {
		if (disabled) {
			return 'custom-select custom-select-disabled';
		} else {
			return isComponentVisible
				? 'custom-select custom-select-active'
				: 'custom-select';
		}
	};

	return (
		<div ref={ref} className="custom_select_wrapper" style={style}>
			<label>{title}</label>
			<div
				onClick={() => {
					if (!disabled) {
						setIsComponentVisible(!isComponentVisible);
					}
				}}
				className={getSelectClasses()}
				data-testid={title + '-custom-select'}
			>
				{value === -1 || value === null ? placeholder : getValueLabel(value)}
			</div>
			<div
				style={{
					opacity: isComponentVisible ? '1' : '0',
					display: isComponentVisible ? 'flex' : 'none'
				}}
				className={
					'custom-select-items-container thin-scrollbar' +
					(absolute ? ' custom-select-items-container-absolute' : '')
				}
				data-testid="custom-select-items-container"
			>
				{items.map((item) => (
					<CustomSelectItem
						key={item.value}
						item={item}
						onItemSelected={onItemSelected}
						selectedItem={value}
					/>
				))}
			</div>
		</div>
	);
};

CustomSelect.propTypes = {
	title: PropTypes.string,
	value: PropTypes.any,
	setValue: PropTypes.func,
	items: PropTypes.array,
	placeholder: PropTypes.string,
	onValueChanged: PropTypes.func,
	absolute: PropTypes.bool,
	style: PropTypes.object,
	disabled: PropTypes.bool
};

export default CustomSelect;
