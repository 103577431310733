import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';
import ParticipantItem from './ParticipantItem';
import React from 'react';
import PropTypes from 'prop-types';

const ParticipantsAndAddons = ({ details }) => {
	const dictionary = useDictionary();

	const getParticipantsQuantity = () => {
		let quantity = 0;
		// let result = '';
		details?.participant_groups.map((group) => {
			quantity += group.quantity;
		});
		let diff = parseInt(details?.slot?.capacity) - parseInt(details?.slot?.booked);

		return (
			quantity +
			' ' +
			(quantity > 1 ? dictionary.spots : dictionary.spot) +
			' (' +
			diff.toString() +
			' ' +
			(diff === 1 ? dictionary.spot : dictionary.spots) +
			' ' +
			dictionary.available +
			')'
		);
	};

	return (
		<div className="detail-tab-container thin-scrollbar fade-on-load">
			<div
				className="participant-row-container"
				style={{ justifyContent: 'space-between', marginBottom: '20px', textAlign: 'end' }}
			>
				<h3 data-testid="participant-groups-header">
					{dictionary.participants}
				</h3>
				<div
					style={{ fontWeight: 'normal', opacity: '0.6' }}
					className="booking-number"
				>
					{getParticipantsQuantity()}
				</div>
			</div>
			{details?.participant_groups?.map((participant) => (
				<ParticipantItem key={participant.id} participant={participant} />
			))}
			{/* <AddButtonSquare isRound={true} label={dictionary.add_new_participant}/>*/}

			{/*<div className='participant-row-container' style={{justifyContent: 'space-between', marginBottom:'20px'}}>
            <h3 style={{marginTop:'36px'}}>{dictionary.add_ons}</h3>
            <div style={{fontWeight: 'normal', opacity: '0.6'}}
                 className='booking-number'>{details.add_ons.length.toString() + ' ' + dictionary.add_ons}</div>
        </div>
        {details.add_ons.map(addon =>
            <AddonItem addon={addon}/>
        )}*/}
		</div>
	);
};

ParticipantsAndAddons.propTypes = {
	details: PropTypes.object
};

export default ParticipantsAndAddons;
