import React, { useEffect, useState } from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';

import { connect } from 'react-redux';
import * as common from '../../../common/__redux/commonRedux';
import PropTypes from 'prop-types';
import axios from 'axios';

import caretRightFilter from '../../../../../assets/icons/ic_caret_right_filter.svg';
import icRemoveFilter from '../../../../../assets/icons/ic_remove_filter.svg';
import icExperience from '../../../../../assets/icons/ic_experience.svg';
import {
	getProviders
} from '../provider_experiences/networking';
import SelectFilterProviders from './SelectFilterProviders';

const FilterDialog = (props) => {
	const dictionary = useDictionary();

	const [showDialog, setDialogVisibility] = useState(false);
	const [enableScheduling, setEnableScheduling] = useState(false);

	const [showBoxFilter, setShowBoxFilter] = useState(true);
	
	const [showProviderSelectbox, setShowProviderSelectbox] = useState(false);
	const [providers, setProviders] = useState([]);

	const [providerNameSelecteds, setProviderNameSelecteds] = useState([]);
	const [providerCheckeds, setProviderCheckeds] = useState([]);
	const [providerStatuses, setProviderStatuses] = useState({});

	const onFinishClicked = () => {
		props.onScheduleSet();
		onCancelClicked();
	};
	
	useEffect(() => {
		setTimeout(() => {
			setDialogVisibility(true);
		}, 50);

		getAllProvidersAsync();
		getProviderSelected();
		if (props.filterCount > 0) {
			setEnableScheduling(true);
		}
	}, []);

	const onCancelClicked = () => {
		dismissDialog();
	};

	const dismissDialog = () => {
		setDialogVisibility(false);
		setTimeout(() => {
			props.setShowFilterDialog(false);
		}, 600);
	};

	const getAllProvidersAsync = async () => {
		try {
			const providerData  = await getProviders();
			if (providerData.data.data.length > 0) {
				setProviders(providerData.data.data);
				let providerObjs = {};
				for (const provider of providerData.data.data) {
					const itemProvider = {
						id: provider.id,
						text:  provider.brand_name ? provider.brand_name : provider.email,
						class: provider.id
					};
					providerObjs[provider.id] = itemProvider;
				}
				localStorage.setItem('admin_experiences_filter_provider_list', JSON.stringify(providerObjs));
				setProviderStatuses(providerObjs);
			}  else {
				// props.onNoResults();
			}
		} catch (error) {
			//
		}
	};

	const getProviderSelected = () => {
		const providerIds = JSON.parse(localStorage.getItem('admin_experiences_filter_provider')) || [];
		let expStatuses = providerStatuses;
		if (Object.keys(providerStatuses).length === 0) {
			expStatuses = JSON.parse(localStorage.getItem('admin_experiences_filter_provider_list')) || {};
			setProviderStatuses(expStatuses);
		}
		let providerSelecteds = [];
		Object.keys(expStatuses).map((providerStatus) => {
			if (providerIds.length > 0) {
				if (providerIds.includes(providerStatus)) {
					providerSelecteds[providerStatus] = expStatuses[providerStatus];
				}
			}
		});

		setProviderNameSelecteds(providerSelecteds);

		let providerCheckedElms = [];
		Object.keys(expStatuses).map((providerceStatus) => {
			if (providerIds.length === 0) {
				providerCheckedElms[providerceStatus] = false;
			} else {
				if (providerIds.includes(providerceStatus)) {
					providerCheckedElms[providerceStatus] = true;
				}
			}
		});

		setProviderCheckeds(providerCheckedElms);
	}

	const showProviderBox = () => {
		if (showProviderSelectbox) {
			setShowProviderSelectbox(false);
			setShowBoxFilter(true);
		} else {
			setShowProviderSelectbox(true);
			setShowBoxFilter(false);
		}
	}

	const onFilterProviderSelected = (statusParams) => {
		props.setFilterProviderParams(statusParams);
		props.onProviderValuesChanged();
		getProviderSelected();
	};
	
	const removeExperienceFilter = (experienceId) => {
		let providerIds = JSON.parse(localStorage.getItem('admin_experiences_filter_provider')) || [];
		if (providerIds.includes(experienceId)) { 
			for( var i = 0; i < providerIds.length; i++) { 
				if ( providerIds[i] === experienceId) { 
					providerIds.splice(i, 1); 
				}
			}
		}

		if (providerIds.length > 0) {
			localStorage.setItem('admin_experiences_filter_provider', JSON.stringify(providerIds));
		} else {
			localStorage.removeItem('admin_experiences_filter_provider');
		}

		props.onProviderValuesChanged();
		props.setFilterProviderParams(providerIds);
		getProviderSelected();
		setEnableScheduling(true);
	}

	const handleResetAll = () => {
		localStorage.removeItem('admin_experiences_filter_provider');
		props.onProviderValuesChanged();
		getProviderSelected();
		setShowProviderSelectbox(false);
		setShowBoxFilter(true);
		setEnableScheduling(false);
		props.setFilterProviderParams([]);
		props.onScheduleSet();
	}

	return (
		<div
			onClick={() => onCancelClicked()}
			className="dialog-wrapper"
			style={{ background: 'transparent', position: 'fixed', zIndex: '11' }}
			data-testid="schedule-dialog-wrapper"
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className={
					showDialog
						? 'experience-category-tags-wrapper experience-category-tags-wrapper-visible'
						: 'experience-category-tags-wrapper'
				}
				data-testid="schedule-dialog"
			>
				<div className="schedule-dialog-content-wrapper thin-scrollbar">
					<div
						className="dialog-close-button"
						style={{ right: '24px', top: '21px' }}
						onClick={onCancelClicked}
						data-testid="schedule-dialog-close-button"
					/>
					
					<div className="schedule-dialog-content-container schedule-dialog-content-container-pa-0">
						<div className="schedule-dialog-content-container-content border-bottom">
							<h2 style={{ whiteSpace: 'pre-line', fontSize: '16px', lineHeight: '24px', fontWeight: 500 }}>
								Filters
							</h2>
						</div>
						
						<div className="schedule-dialog-content-container-content">
							{showBoxFilter ? (
							<div className="box-filter-list">
								<div className="group-filter">
									<div className="group-filter-icon-label">
										<img className="group-filter-icon" src={icExperience} />
										<span onClick={showProviderBox} className="group-filter-label">{dictionary.providers}</span>
									</div>
									<img onClick={showProviderBox} className="icon-to-select" src={caretRightFilter} />
								</div>
								
								<div className="group-filter-selected-status">
									{Object.keys(providerNameSelecteds).map((experienceNameSelected) => (
										<div className="filter-selected-item" key={experienceNameSelected}>
											<span>{providerNameSelecteds[experienceNameSelected].text}</span>
											<img onClick={() => removeExperienceFilter(experienceNameSelected)} src={icRemoveFilter} />
										</div>
									))}
								</div>
							</div>
							): null}
							
							{showProviderSelectbox ? (
								<SelectFilterProviders
									setShowBoxFilter={setShowBoxFilter} 
									setShowProviderSelectbox={setShowProviderSelectbox} 
									onFilterProviderSelected={onFilterProviderSelected}
									providerCheckeds={providerCheckeds}
									setEnableScheduling={setEnableScheduling}
									filterCount={props.filterCount}
									providers={providers}
								/>
							) : null}
						</div>
					</div>
				</div>

				<div className="filter-dialog-button-container">
					<button
						onClick={() => handleResetAll()}
						className="schedule-dialog-reset-button"
						data-testid="schedule-dialog-reset-button"
					>
						Reset
					</button>
					<button
						disabled={!enableScheduling}
						onClick={() => onFinishClicked()}
						className="button filter-dialog-apply-button"
						data-testid="filter-dialog-apply-button"
					>
						Apply
					</button>
				</div>
			</div>
		</div>
	);
};

FilterDialog.propTypes = {
	onProviderValuesChanged: PropTypes.func,
	setShowFilterDialog: PropTypes.func,
	countAllFilter: PropTypes.func,
	filterCount: PropTypes.number,
	setFilterProviderParams: PropTypes.func,
	onScheduleSet: PropTypes.func
};

export default connect(common.states, common.actions)(FilterDialog);
