import image from '../../../../../assets/images/onboarding_legal_info.png';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { authenticateProvider } from '../networking';
import PropTypes from 'prop-types';
import React from 'react';
// import hourglass from '../../../../../assets/icons/ic_hourglass.svg';
// import error from '../../../../../assets/icons/ic_error.svg';
import { useHistory } from 'react-router-dom';

const OnBoardingLegalInfo = ({
	showDocumentsUploadDialog,
	config,
	setShowLoader
}) => {
	const dictionary = useDictionary();

	const history = useHistory();

	const onAddClicked = async () => {
		if (config?.verification_status?.value === '1') {
			showDocumentsUploadDialog(true);
		} else if (config?.verification_status?.value === '2') {
			setShowLoader(true);
			try {
				let response = await authenticateProvider();
				window.location.href = response.data.url;
			} catch (e) {
				console.log(e);
			}
			setShowLoader(false);
		} else if (config?.verification_status?.value === '4') {
			history.push('/settings/2');
		}
	};

	return (
		<div
			className="onboarding-activity-container"
			data-testid="onboarding-legal-info"
		>
			<img src={image} className="onboarding-activity-image" />
			<div className="onboarding-activity-info-container">
				<div className="onboarding-activity-title">
					{dictionary.add_legal_info_title}
				</div>
				<div className="text">{dictionary.add_legal_info_text}</div>
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						alignItems: 'center',
						marginTop: '24px'
					}}
				>
					<button
						// disabled={config?.verification_status?.value === '2'}
						// TODO - add dictionary for this
						onClick={onAddClicked}
						className="button"
						data-testid="add-legal-info-button"
					>
						{config?.verification_status?.value === '4'
							? dictionary.change_legal_info
							: dictionary.add_legal_info}
					</button>
					{/* <button
						disabled={config?.verification_status?.value === '2'}
						onClick={onAddClicked}
						className="button"
						data-testid="add-legal-info-button"
					>
						{config?.verification_status?.value === '4'
							? dictionary.change_legal_info
							: dictionary.add_legal_info_title}
					</button>
					{config?.verification_status?.value === '2' ||
					config?.verification_status?.value === '4' ? (
						<div
							className="text"
							style={{
								display: 'flex',
								gap: '4px',
								fontWeight: 'bold',
								fontSize: '12px'
							}}
						>
							<img
								src={
									config?.verification_status?.value === '2' ? hourglass : error
								}
							/>

							<div>
								{config?.verification_status?.value === '2'
									? dictionary.checking_documents
									: dictionary.documents_rejected}
							</div>
						</div>
					) : null} */}
				</div>
			</div>
		</div>
	);
};

OnBoardingLegalInfo.propTypes = {
	showDocumentsUploadDialog: PropTypes.func,
	config: PropTypes.object,
	setShowLoader: PropTypes.func
};

export default OnBoardingLegalInfo;
