import { ADMIN_EMAIL } from '../../../../../config';
import { CryptoHelper } from '../../../../../utilities/helpers/CryptoHelper';
import FormikHelper from '../../../../../utilities/helpers/FormikHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { getPK } from '../../../auth/__redux/authCrud';
import FormLoader from '../../../common/FormLoader';
import * as common from '../../../common/__redux/commonRedux';
import ShowHidePassword from '../../custom/ShowHidePassword';
import ProfilePhoto from '../ProfilePhoto';
import { updateProviderInfo } from '../networking';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as Yup from 'yup';

const MyProfile = ({
	info,
	setInfo,
	profilePhoto,
	setProfilePhoto,
	setSuccessMessage,
	setErrorMessage
}) => {
	const dictionary = useDictionary();
	const [showLoader, setShowLoader] = useState(false);
	const [changed, setChanged] = useState(0);

	const profileSchema = Yup.object().shape({
		first_name: Yup.string().required(dictionary.first_name_required),
		last_name: Yup.string().required(dictionary.last_name_required),
		phone: Yup.string().required(dictionary.phone_number_required),
		email: Yup.string().required(dictionary.email_required),
		password: Yup.string(),
		new_password: FormikHelper.getDefaultYupPassword(false, dictionary),
		password_again: FormikHelper.getDefaultYupPassword(false, dictionary).oneOf(
			[Yup.ref('new_password'), null],
			dictionary.passwords_must_match
		)
	});

	useEffect(() => {
		if (info) {
			initFormik();
		}
	}, [info]);

	const initFormik = () => {
		formik.setFieldValue('first_name', info.first_name);
		formik.setFieldValue('last_name', info.last_name);
		formik.setFieldValue('email', info.email);
		formik.setFieldValue('phone', info.phone);
	};

	const formik = useFormik({
		initialValues: {
			first_name: '',
			last_name: '',
			phone: '',
			email: '',
			password: '',
			new_password: '',
			password_again: ''
		},
		// onSubmit: (values, { setStatus, setSubmitting }) => {
		onSubmit: (values) => {
			let newDetails = {};
			newDetails.first_name = values.first_name;
			newDetails.last_name = values.last_name;
			newDetails.phone = values.phone;

			if (values.password !== '') {
				if (
					values.new_password !== '' &&
					values.new_password === values.password_again
				) {
					updateWithPassword(newDetails);
				} else if (values.new_password === '' || values.password_again === '') {
					formik.setErrors({ new_password: dictionary.fill_in_both_passwords });
				} else if (values.new_password !== values.password_again) {
					formik.setErrors({ password_again: dictionary.passwords_must_match });
				}
			} else {
				submitProviderDataAsync(newDetails);
			}
		},
		validateOnMount: true,
		validationSchema: profileSchema
	});

	const onCancelClicked = () => {
		initFormik();
		formik.setFieldValue('password', '');
		formik.setFieldValue('new_password', '');
		formik.setFieldValue('password_again', '');
	};

	const updateWithPassword = (details) => {
		setShowLoader(true);
		let sessionId = CryptoHelper.generateSessionId(20);

		getPK(sessionId)
			.then((response) => {
				let metadata = response.data.metadata;

				details.old_password = CryptoHelper.encryptData(
					formik.values.password,
					metadata
				);
				details.new_password = CryptoHelper.encryptData(
					formik.values.new_password,
					metadata
				);
				details.session_id = sessionId;

				submitProviderDataAsync(details);
			})
			.catch((error) => {
				setShowLoader(false);
				formik.setSubmitting(false);
				if (error.response) {
					setErrorMessage(error.response.data.error_code.toString());
				}
			});
	};

	useEffect(() => {
		if (changed < 2) {
			setChanged(changed + 1);
		}
	}, [formik?.values]);

	const submitProviderDataAsync = async (info) => {
		setShowLoader(true);

		try {
			let { data } = await updateProviderInfo(info);
			setInfo(data);
			setShowLoader(false);
			setSuccessMessage(dictionary.profile_update_success);
		} catch (e) {
			setShowLoader(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
			}
		}
	};

	FormikHelper.setFormik(formik);

	const getDisabled = () => {
		if (changed <= 1 || !formik.isValid) {
			return true;
		}
		if (
			formik.values.password !== '' &&
			(formik.values.new_password === '' || formik.values.password_again === '')
		) {
			return true;
		}
		if (
			formik.values.password === '' &&
			(formik.values.new_password !== '' || formik.values.password_again !== '')
		) {
			return true;
		}
	};

	return (
		<form
			className="settings-page-container thin-scrollbar fade-on-load"
			onSubmit={formik.handleSubmit}
		>
			<div
				className="onboarding-activity-title"
				data-testid="account-info-header"
			>
				{dictionary.account_info}
			</div>

			<div className="settings-form-content">
				<div className="settings-form-content-left">
					<div className="settings-form-row">
						<div className="settings-info-column">
							{FormikHelper.getInputTemplate(
								'first_name',
								dictionary.first_name,
								dictionary.enter_first_name,
								'text',
								true,
								true
							)}
							{FormikHelper.getInputTemplate(
								'last_name',
								dictionary.last_name,
								dictionary.enter_last_name,
								'text',
								true,
								true
							)}
							{FormikHelper.getInputTemplate(
								'phone',
								dictionary.phone,
								'',
								'phone',
								true,
								true
							)}
							{FormikHelper.getInputTemplate(
								'email',
								dictionary.email,
								'',
								'email',
								false,
								false
							)}
						</div>
					</div>
					<div style={{ marginTop: '20px' }} className="onboarding-activity-title">
						{dictionary.password}
					</div>
					<div className="settings-form-row">
						<div className="settings-info-column">
							{ShowHidePassword(
								'password',
								dictionary.password,
								dictionary.enter_password,
								false,
								formik,
								true
							)}
							{ShowHidePassword(
								'new_password',
								dictionary.new_password,
								dictionary.enter_password,
								false,
								formik,
								true
							)}
							{ShowHidePassword(
								'password_again',
								dictionary.confirm_password,
								dictionary.enter_password,
								false,
								formik,
								true
							)}
						</div>
					</div>

					<div
						className="onboarding-activity-title"
						data-testid="delete-account-header"
						style={{ marginTop: '20px' }}
					>
						{dictionary.contact_support}
					</div>
					<div className="settings-info-row">
						<div className="settings-info-column">
							<div
								style={{
									display: 'flex',
									flexDir: 'row',
									justifyContent: 'space-between',
									alignItems: 'center'
								}}
							>
								<div className="text" style={{ flex: 1, marginRight: '25px' }}>
									{dictionary.contact_support_text}
								</div>
								<div>
									<div
										className="secondary-button"
										onClick={() =>
											(window.location.href = `mailto:${ADMIN_EMAIL}?subject=Contact%20Support&body=Please%20provide%20details%20about%20your%20issue%20here.`)
										}
									>
										{dictionary.contact_support}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="settings-form-content-right">
					<ProfilePhoto
						title={dictionary.profile_photo}
						type={'profile-photo'}
						url={'providers/me/assets'}
						photo={profilePhoto}
						setPhoto={setProfilePhoto}
						isVerified={
							info &&
							info.verification_status &&
							info.verification_status.key === 'verified'
						}
					/>
				</div>
			</div>
			<div style={{ display: 'flex', marginTop: '30px', gap: '16px' }}>
				<button
					type="submit"
					className="button"
					disabled={getDisabled()}
					data-testid="save-btn"
				>
					{dictionary.save}
				</button>

				<div
					onClick={onCancelClicked}
					className="secondary-button"
					data-testid="cancel-btn"
				>
					{dictionary.cancel}
				</div>
			</div>
			<FormLoader showLoader={showLoader} />
		</form>
	);
};

MyProfile.propTypes = {
	info: PropTypes.object,
	setInfo: PropTypes.func,
	profilePhoto: PropTypes.object,
	setProfilePhoto: PropTypes.func,
	setSuccessMessage: PropTypes.func,
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(MyProfile);
