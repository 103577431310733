/* eslint-disable no-useless-escape */
export const ValidationHelper = (function () {
	const validateEmail = (email) => {
		if (email && email.length > 4) {
			let validRegex =
				/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
			return email.toLowerCase().match(validRegex);
		}
	};

	return {
		validateEmail: function (email) {
			return validateEmail(email);
		}
	};
})();
