import React from 'react';
import '../../../../assets/css/custom_select.css';
import { useState } from 'react';
import CustomSelectCompactItem from './CustomSelectCompactItem';
import PropTypes from 'prop-types';

const CustomSelectCompact = ({
	value,
	setValue,
	items,
	placeholder,
	onValueChanged = null
}) => {
	const [showOptions, setShowOptions] = useState(false);

	const getValueLabel = (value) => {
		let item = items.find((item) => item.value === value);
		if (item?.title) {
			return item?.title;
		} else {
			return item?.name;
		}
	};

	const onItemSelected = (item) => {
		setShowOptions(false);
		setValue(item.value);
		if (onValueChanged !== null) {
			onValueChanged(item.value);
		}
	};

	return (
		<div className="custom_select_wrapper custom_select_compact_wrapper">
			<div
				onClick={() => setShowOptions(!showOptions)}
				className={
					(showOptions ? 'custom-select custom-select-active' : 'custom-select') + ' bg-transperant'
				}
				style={{
					fontWeight: 'bold',
					height: '32px'
				}}
			>
				{value === -1 ? placeholder : getValueLabel(value)}
			</div>
			<div
				style={{
					opacity: showOptions ? '1' : '0',
					display: showOptions ? 'flex' : 'none'
				}}
				className="custom-select-items-container custom-select-compact-items-container thin-scrollbar"
			>
				{items.map((item) => (
					<CustomSelectCompactItem
						key={item.value}
						item={item}
						onItemSelected={onItemSelected}
						selectedItem={value}
					/>
				))}
			</div>
		</div>
	);
};

CustomSelectCompact.propTypes = {
	value: PropTypes.number,
	setValue: PropTypes.func,
	items: PropTypes.array,
	placeholder: PropTypes.string,
	onValueChanged: PropTypes.func
};

export default CustomSelectCompact;
