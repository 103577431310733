import React from 'react';
import { useEffect, useState } from 'react';
import '../../../../assets/css/pagination_controller.css';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import caretRight from '../../../../assets/icons/ic_caret_right.svg';
import CustomSelectCompact from '../custom_select_compact/CustomSelectCompact';
import PropTypes from 'prop-types';

const PaginationController = ({ name, dataLength, onParameterChanged, currentPage, setCurrentPage }) => {
	const dictionary = useDictionary();
	const [pageSize, setPageSize] = useState(0);
	const [totalPages, setTotalPages] = useState(0);

	useEffect(() => {
		let savedPageSize = retrieveSavedOption();
		setPageSize(savedPageSize);
		setTotalPages(Math.ceil(dataLength / pageSize));
	}, [dataLength]);

	const retrieveSavedOption = () => {
		let option = localStorage.getItem(name + '_selected_page_size');
		if (!option) {
			return 10;
		}
		return parseInt(option);
	};

	const options = [
		{
			name: '10' + ' ' + dictionary.items_per_page,
			value: 10
		},
		{
			name: '25' + ' ' + dictionary.items_per_page,
			value: 25
		},
		{
			name: '50' + ' ' + dictionary.items_per_page,
			value: 50
		}
	];

	const saveSelectedOption = (option) => {
		localStorage.setItem(name + '_selected_page_size', option);
	};

	const onPageSizeChanged = (newSize) => {
		if(name == 'bookings') {
			setCurrentPage(0);
		}
		setPageSize(newSize);
		saveSelectedOption(newSize);
		onParameterChanged('page-size', newSize);
	};

	const changeCurrentPage = (page) => {
		setCurrentPage(page);
		onParameterChanged('page', page);
	};

	const getPages = () => {
		let limit = 5;
		if (pageSize > 0) {
			let pages = Math.ceil(dataLength / pageSize);
			let rendered = [];

			if (pages <= limit) {
				for (let i = 1; i < pages; i++) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(i)}
							key={i}
							className={
								currentPage === i
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{i + 1}
						</div>
					);
				}
				return <>{rendered}</>;
			}

			let isSection = currentPage % 3 === 0;

			if (currentPage === 0) {
				for (let i = 1; i < limit; i++) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(i)}
							className={
								currentPage === i
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{i + 1}
						</div>
					);
				}
				
				rendered.push(
					<>
						{pages > limit + 1 ? <div className="pagination-ellipse" /> : null}
						{pages > limit ? 
							<div
								onClick={() => changeCurrentPage(pages - 1)}
								className={
									currentPage === pages
										? 'pagination-controller-page pagination-controller-page-selected'
										: 'pagination-controller-page'
								}
							>
								{pages}
							</div> :
						null}
					</>
				);

				return <>{rendered}</>;
			} else if (isSection) {
				for (
					let i = currentPage;
					i < (currentPage + 4 < pages ? currentPage + 4 : pages);
					i++
				) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(i)}
							className={
								currentPage === i
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{i + 1}
						</div>
					);
				}
				rendered.push(
					<>
						{currentPage + 4 < pages ? (
							<div className="pagination-ellipse" />
						) : null}
						{currentPage + 4 < pages ? (
							<div
								onClick={() => changeCurrentPage(pages)}
								className={
									currentPage === pages
										? 'pagination-controller-page pagination-controller-page-selected'
										: 'pagination-controller-page'
								}
							>
								{pages + 1}
							</div>
						) : null}
					</>
				);

				return <>{rendered}</>;
			} else {
				let section = Math.floor(currentPage / 3);

				for (
					let i = section === 0 ? section * 3 + 1 : section * 3;
					i <
					(currentPage + 3 < pages
						? section === 0
							? 5
							: section * 3 + 4
						: pages);
					i++
				) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(i)}
							className={
								currentPage === i
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{i + 1}
						</div>
					);
				}
				rendered.push(
					<>
						{currentPage + limit < pages ? (
							<div className="pagination-ellipse" />
						) : null}
						
					</>
				);
				if (currentPage + limit === pages) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(pages)}
							className={
								currentPage === pages
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{pages}
						</div>
					);
				}

				if (currentPage + 4 === pages) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(pages)}
							className={
								currentPage === pages
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{pages}
						</div>
					);
				}

				if (currentPage + limit < pages) {
					rendered.push(
						<div
							onClick={() => changeCurrentPage(pages)}
							className={
								currentPage === pages
									? 'pagination-controller-page pagination-controller-page-selected'
									: 'pagination-controller-page'
							}
						>
							{pages + 1}
						</div>
					);
				}
				
				return <>{rendered}</>;
			}
		}
	};

	const onNextCLicked = () => {
		if (currentPage < totalPages - 1) {
			onParameterChanged('page', currentPage + 1);
			setCurrentPage(currentPage + 1);
		}
	};

	const onPreviousClicked = () => {
		if (currentPage > 0) {
			onParameterChanged('page', currentPage - 1);
			setCurrentPage(currentPage - 1);
		}
	};

	// return dataLength < pageSize || dataLength === 0 ? null : (
	return (
		<div className={`pagination-controller-wrapper` + (name ? (' ' + name) : '')}>
			<div className="pagination-controller-pages-wrapper">
				<div className="pagination-controller-pages-container">
					<div
						onClick={() => onPreviousClicked()}
						className="pagination-controller-button"
					>
						<img style={{transform: 'rotate(180deg)'}} src={caretRight} />
						{dictionary.previous}
					</div>
					<div
						onClick={() => changeCurrentPage(0)}
						className={
							currentPage === 0
								? 'pagination-controller-page pagination-controller-page-selected'
								: 'pagination-controller-page'
						}
					>
						1
					</div>
					{pageSize > 0 ? getPages() : null}

					<div
						className="pagination-controller-button"
						onClick={() => onNextCLicked()}
					>
						{dictionary.next}
						<img src={caretRight} />
					</div>
				</div>
			</div>
			<div className="flex-filler" />
			<CustomSelectCompact
				setValue={onPageSizeChanged}
				value={pageSize}
				items={options}
				placeholder={''}
				title={''}
			/>
		</div>
	);
};

PaginationController.propTypes = {
	name: PropTypes.string,
	dataLength: PropTypes.number,
	onParameterChanged: PropTypes.func,
};

export default PaginationController;
