import { UseCurrencyDecorator } from '../../../../../utilities/hooks/UseCurrencyDecorator';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import * as common from '../../../common/__redux/commonRedux';
import { confirmBookingCancellation } from '../networking';
import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { connect } from 'react-redux';

export const reasons = [
	{
		label: 'Schedule conflict',
		value: 'schedule_conflict'
	},
	{
		label: 'Illness or injury',
		value: 'illness_or_injury'
	},
	{
		label: 'Weather conditions',
		value: 'weather_conditions'
	},
	{
		label: 'Technical issues',
		value: 'technical_issues'
	},
	{
		label: 'Other reason',
		value: 'other'
	}
];

const CancelBookingModal = ({
	details,
	setShow,
	dismissDetails,
	setErrorMessage
}) => {
	const dictionary = useDictionary();
	const [opacity, setOpacity] = useState(0);
	const [reason, setReason] = useState(null);
	const [step, setStep] = useState(1);
	const [showLoader, setLoaderVisibility] = useState(false);
	const [otherReason, setOtherReason] = useState('');

	const currencyDecorator = UseCurrencyDecorator();

	const confirmBookingCancellationAsync = async () => {
		setLoaderVisibility(true);
		try {
			const data = {
				bookingId: details.id,
				cancel_reason: reason,
				cancel_other_reason: otherReason
			};
			await confirmBookingCancellation(data);
			setLoaderVisibility(false);
			onClose();
			dismissDetails();
		} catch (e) {
			setLoaderVisibility(false);
			if (e.response) {
				setErrorMessage(e.response.data.error_code.toString());
				setTimeout(() => {
					setErrorMessage('');
				}, 2000);
			}
		}
	};

	const onClose = () => {
		setOpacity(0);
		setTimeout(() => {
			setShow(false);
		}, 800);
	};

	const onCancel = () => {
		if (step === 1) {
			onClose();
		} else {
			setStep(1);
		}
	};

	const onRadioChanged = (e) => {
		setReason(e.target.value);
	};

	const onContinue = async () => {
		if (details.payment_status.value === 'pending') {
			await confirmBookingCancellationAsync();
		} else {
			if (step === 1) {
				setStep(2);
			} else {
				await confirmBookingCancellationAsync();
			}
		}
	};

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const isDisabled = useMemo(() => {
		return step === 1 ? !!reason ? otherReason.length <= 0 : !reason : false;
	}, [reason, otherReason, step]);

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">
						{step === 1
							? dictionary.cancel_booking
							: dictionary.confirm_cancellation}
					</div>
					<div className="dialog-close-button" onClick={onClose} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container">
					{step === 1 && (
						<>
							<h3 style={{ fontSize: 16, marginBottom: 24 }}>
								{dictionary.why_cancel_booking}
							</h3>
							{reasons?.map(({ label, value }, index) => (
								<label key={index} className="radio-label">
									{label}
									<input
										onChange={onRadioChanged}
										checked={reason === value}
										value={value}
										type="radio"
									/>
									<span className="checkmark" />
								</label>
							))}
							{!!reason && (
								<>
									<div className="text">{dictionary.please_specify}</div>
									<textarea
										rows={8}
										value={otherReason}
										onChange={(e) => setOtherReason(e.target.value)}
										placeholder={dictionary.cancel_reason}
										style={{ marginTop: 8 }}
									/>
								</>
							)}
						</>
					)}
					{step === 2 && (
						<>
							<span className="text">
								Cancellation is effective immediately. The payment method used
								to reserve this experience will be refunded in 5-7 business
								days.
							</span>
							<h3 style={{ fontSize: 16, marginTop: 24, marginBottom: 8 }}>
								Payment details
							</h3>
							<div
								style={{
									height: 32,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<span className="text">Original booking</span>
								<span className="text" style={{ fontWeight: 500 }}>
									{currencyDecorator(details.amount)}
								</span>
							</div>
							<div
								style={{
									height: 32,
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between'
								}}
							>
								<span className="text">Total refund</span>
								<span className="text" style={{ fontWeight: 500 }}>
									{currencyDecorator(details.amount)}
								</span>
							</div>
						</>
					)}
				</div>
				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{
						padding: '12px',
						marginBottom: '0',
						justifyContent: 'space-between'
					}}
				>
					<button className="tertiary-button" onClick={onCancel}>
						{step === 1 ? dictionary.cancel : dictionary.back}
					</button>
					<button onClick={onContinue} disabled={isDisabled} className="button">
						{step === 1 ? dictionary.continue : dictionary.confirm}
					</button>
				</div>
				<SimpleLoader showLoader={showLoader} />
			</div>
		</div>
	);
};

CancelBookingModal.propTypes = {
	details: PropTypes.object.isRequired,
	setShow: PropTypes.func.isRequired,
	dismissDetails: PropTypes.func.isRequired,
	setErrorMessage: PropTypes.func.isRequired
};

export default connect(common.states, common.actions)(CancelBookingModal);
