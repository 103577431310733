import { PROVIDER_BOOKING_CANCEL, PROVIDER_BOOKINGS } from '../../../../config';
import { DataTableHelper } from '../../../../utilities/helpers/DataTableHelper';
import axios from 'axios';

export function getBookingDetails(id) {
	return axios.get(PROVIDER_BOOKINGS + '/' + id);
}

export function getUpcomingBookings(
	sortParam,
	pageSize,
	pageNumber,
	upcoming = false,
	cancelToken,
	searchParam = '',
	createDateParam = '',
	filterStatusParams = [],
	filterPaymentStatusParams = []
) {
	return axios.get(
		(PROVIDER_BOOKINGS) +
			(upcoming
				? '?upcoming=true'
				: DataTableHelper.prepareParameters(
						sortParam,
						pageSize,
						pageNumber,
						null,
						searchParam,
						createDateParam,
						filterStatusParams,
						filterPaymentStatusParams
				  )),
		{ cancelToken: cancelToken }
	);
}

export function confirmBookingCancellation(confirmData) {
	return axios.post(PROVIDER_BOOKING_CANCEL, confirmData);
}