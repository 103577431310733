/* eslint-disable no-undef */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './redux/store';
import * as _redux from './redux';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import axios from 'axios';
import 'dotenv/config';
import './assets/css/lato.css';
import './assets/css/roboto.css';
import './assets/css/global.css';
import './assets/css/animate.min.css';
import { UPIRIA_ASCII_ART } from './config';

const { PUBLIC_URL } = process.env;

{
	//ENVIRONMENT === 'production' ?
	_redux.setupAxios(axios, store); //:
	//_redux.mockAxios(axios)
}

console.log(UPIRIA_ASCII_ART);

Sentry.init({
	dsn: process.env.REACT_APP_SENTRY_DSN,
	integrations: [
		new BrowserTracing({
			// tracingPropagationTargets: ["localhost", "https://upiria.com", "https://staging.upiria.com", "https://dev.upiria.com"],
			// tracingOrigins: [],
			tracePropagationTargets: [
				'localhost',
				'https://www.upiria.com',
				'https://staging-portal.upiria.com/',
				'https://dev.d3w4ln37n6mku8.amplifyapp.com/'
			]
			// routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
		})
	],

	tracesSampleRate: 1.0,
	environment: process.env.REACT_APP_ENV,
});

ReactDOM.render(
	<App store={store} persistor={persistor} basename={PUBLIC_URL} />,
	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
