import React from 'react';
import '././../../../../assets/css/login.css';
import '././../../../../assets/css/form_elements.css';
import ForgotPasswordForm from '../forms/ForgotPasswordForm';

const ForgotPassword = () => {
	return (
		<div className="main-container">
			<ForgotPasswordForm />
		</div>
	);
};

export default ForgotPassword;
