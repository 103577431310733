import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { getProviderGroups } from '../provider_experiences/networking';
import empty from '../../../../assets/images/empty_bg.svg';
import addSquare from '../../../../assets/icons/ic_add_square.svg';
import addSquareWhite from '../../../../assets/icons/ic_add_square_white.svg';

import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import FormLoader from '../../common/FormLoader';

const AddGroupDialog = ({ actionType, experience, setAddGroupDialogVisibility, addGroup, removeGroup, createNewGroup }) => {

    const [dialogOpacity, setDialogOpacity] = useState(0);
    const dictionary = useDictionary();
    const [showPopupLoader, setShowPopupLoader] = useState(false);
    const [pageSize, setPageSize] = useState(100);
    const [currentPage, setCurrentPage] = useState(0);
    const [sortOrder, setSortOrder] = useState(null);
    const [groups, setGroups] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState([]);

    useEffect(() => {
        let options = { value: "created_at", direction: "desc" };
        setSortOrder(options);
        setTimeout(() => {
            setDialogOpacity(1);
        }, 50);
    }, []);

    useEffect(() => {
        getGroups();
    }, [sortOrder, pageSize, currentPage]);

    const getGroups = async () => {
        setShowPopupLoader(true);
        try {
            if(!experience.id){
                experience.id = experience.experience_id;
            }
            const excludeExperienceIds = [experience.id];
            let { data } = await getProviderGroups(
                sortOrder,
                pageSize,
                currentPage,
                excludeExperienceIds
            );
            if (data) {
                setGroups(data.data);
                setShowPopupLoader(false);
                const initialSelectedGroups = data.data
                    .filter(group => group.experiences.some(exp => exp.id === experience.id))
                    .map(group => group.id);
                setSelectedGroups(initialSelectedGroups);
            }

        } catch (e) {
            setShowPopupLoader(false);
        }
    };

    const onCancelClicked = () => {
        setDialogOpacity(0);
        setTimeout(() => {
            setAddGroupDialogVisibility(false);
        }, 200);
    };

    const handleGroupClick = async (groupId) => {
        setShowPopupLoader(true);
        try {
            if (selectedGroups.includes(groupId)) {
                await removeGroup(groupId);
                setSelectedGroups(selectedGroups.filter(id => id !== groupId));
                setGroups(prevGroups => prevGroups.map(group =>
                    group.id === groupId ? { ...group, experiences: group.experiences.filter(exp => exp.id !== experience.id) } : group
                ));
            } else {
                await addGroup(groupId);
                setSelectedGroups([...selectedGroups, groupId]);

                if (experience?.assets[0].thumbnail_url) {
                    setGroups(prevGroups => prevGroups.map(group =>
                        group.id === groupId ? {
                            ...group,
                            experiences: [...group.experiences, { id: experience.id, thumbnail_url: experience?.assets[0].thumbnail_url }]
                        } : group
                    ));
                } else {
                    setGroups(prevGroups => prevGroups.map(group =>
                        group.id === groupId ? {
                            ...group,
                            experiences: [...group.experiences, { id: experience.id, thumbnail_url: experience?.assets[0] }]
                        } : group
                    ));
                }
            }
        } catch (error) {
            console.error("Error handling group click:", error);
        } finally {
            setShowPopupLoader(false);
        }
    };

    return (
        <div className="dialog-wrapper">
            <div className="group-dialog-container">
                <FormLoader showLoader={showPopupLoader} />
                <div className="group-dialog-content-container">
                    <div className="group-dialog-header">
                        <h3
                            style={{ fontStyle: 'normal', marginBottom: 12 }}
                            className="group-dialog-title"
                        >
                            {dictionary.add_to_collection}
                        </h3>
                        <div
                            onClick={onCancelClicked}
                            className="dialog-close-button"
                            style={{ position: 'unset', marginBottom: 12 }}
                        />
                    </div>
                    <div className="flex-filler" />
                    <div className="groups-selection-container">
                        <div className="popup-scroll">
                            {groups.map((group) => (
                                <div key={group.id} style={{ display: 'flex', marginTop: 15, alignItems: 'center' }}>
                                    <div className="group-details-dialog-row">
                                        <img
                                            className="group-image"
                                            onError={(e) => {
                                                e.currentTarget.src = empty;
                                            }}
                                            src={
                                                group.experiences.length > 0
                                                    ? ImageHelper.getFullImageUrl(
                                                        group.experiences[group?.experiences.length - 1].thumbnail_url
                                                    )
                                                    : empty
                                            }
                                        />
                                        <div className="group-info">
                                            <div style={{ fontSize: 'medium' }}>{group.title}</div>
                                            <div style={{ fontSize: '14px', marginTop: '4px', opacity: 0.6 }}>{group.experiences.length} item</div>
                                        </div>
                                    </div>
                                    <div
                                        className={`group-status-image-wrapper ${group.experiences.some(exp => exp.id === experience.id) ? 'selected' : ''}`}
                                        onClick={() => handleGroupClick(group.id)}
                                    >
                                        <img
                                            style={{ borderRadius: '50%' }}
                                            className="group-status-image"
                                            src={addSquare}
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex-filler" />
                    <div className='create-new-groupCol'>
                        <div
                            key="create-new-group"
                            className="group-details-dialog-row"
                            style={{ marginTop: '15px', cursor: 'pointer' }}
                            onClick={() => createNewGroup()}
                        >
                            <img className="group-image" style={{ backgroundColor: '#e55a5c' }} src={addSquareWhite} />
                            <div className="group-info">
                                <div style={{ fontSize: 'medium', marginTop: 13 }}>{dictionary.create_new_group}</div>
                            </div>
                        </div>

                        <button className="tertiary-button" style={{ marginTop: '15px' }} onClick={onCancelClicked}>
                            {dictionary.done}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

AddGroupDialog.propTypes = {
    actionType: PropTypes.string.isRequired,
    experience: PropTypes.object.isRequired,
    setAddGroupDialogVisibility: PropTypes.func,
    addGroup: PropTypes.func,
    removeGroup: PropTypes.func,
    createNewGroup: PropTypes.func,
};

export default AddGroupDialog;
