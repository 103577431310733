import { useState } from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { LEGAL_FILES_TYPES, MAX_LEGAL_FILE_SIZE } from '../../../../../config';
import { connect } from 'react-redux';
import * as common from '../../../common/__redux/commonRedux';
import { TextFormatHelper } from '../../../../../utilities/helpers/TextFormatHelper';
import React from 'react';
import PropTypes from 'prop-types';

const SubmitDocumentsDialogDocumentRow = (props) => {
	const dictionary = useDictionary();
	const [file, setFile] = useState(null);

	const onFileSelected = (e) => {
		const validTypes = LEGAL_FILES_TYPES;
		const selectedFile = e.target.files[0];
		const { size, type } = selectedFile;
		if (size > MAX_LEGAL_FILE_SIZE) {
			props.setErrorMessage('loc1');
			e.target.value = null;
			return;
		}
		if (!validTypes.includes(type)) {
			props.setErrorMessage('loc2');
			e.target.value = null;
			return;
		}
		props.setFile(selectedFile);
		setFile(selectedFile);
	};

	const onDeleteClicked = () => {
		props.setFile(null);
		setFile(null);
	};

	return (
		<div className="dialog-document-row">
			<div title={props.text} className="text-with-info">
				{props.title}
			</div>
			<div className="dialog-document-row-info-container">
				{file ? (
					<>
						<div className="dialog-document-row-filename">
							<div className="icon-container">
								<div className="paperclip-icon"></div>
							</div>
							<div>{TextFormatHelper.truncateString(file.name, 20)}</div>
						</div>
						<div
							className="dialog-document-row-delete"
							style={{ width: '40px', height: '40px' }}
							onClick={onDeleteClicked}
							data-testid="remove-item-button"
						/>
					</>
				) : null}
				{!file ? (
					<div className="dialog-document-upload-button-container">
						<div className="secondary-button">{dictionary.upload_doc}</div>
						<input
							accept=".jpeg, .jpg, .pdf"
							onChange={onFileSelected}
							type="file"
							data-testid="upload-file-button"
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

SubmitDocumentsDialogDocumentRow.propTypes = {
	title: PropTypes.string.isRequired,
	text: PropTypes.string.isRequired,
	file: PropTypes.object,
	setFile: PropTypes.func.isRequired,
	setErrorMessage: PropTypes.func.isRequired
};

export default connect(
	common.states,
	common.actions
)(SubmitDocumentsDialogDocumentRow);
