import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import logo from '../../../../../../assets/images/logo_white_letter.svg';
import { useState } from 'react';
import PropTypes from 'prop-types';
import React from 'react';

const ExperienceSelectItem = ({ item, selectedItem, onItemSelected }) => {
	const [hasError, setHasError] = useState(false);
	return (
		<div
			style={{ marginBottom: '8px' }}
			onClick={() => onItemSelected(item)}
			className={
				selectedItem === item
					? 'custom-select-item select-experience-item custom-select-item-selected'
					: 'select-experience-item custom-select-item'
			}
		>
			<div className="custom-experience-image-container">
				<img
					onError={(e) => {
						e.currentTarget.src = logo;
						// setHasError(true);
					}}
					src={ImageHelper.getFullImageUrl(item?.assets?.[0]?.thumbnail_url)}
					className="custom-experience-image"
					data-testid="custom-experience-image"
				/>
				{!hasError ? (
					<div
						className="custom-experience-image-color"
						style={{ background: item?.color }}
					/>
				) : null}
			</div>
			<div
				className="custom-experience-title"
				data-testid="custom-experience-title"
			>
				{item?.title}
			</div>
		</div>
	);
};

ExperienceSelectItem.propTypes = {
	item: PropTypes.object,
	selectedItem: PropTypes.object,
	onItemSelected: PropTypes.func
};

export default ExperienceSelectItem;
