import React, { useEffect, useState } from 'react';
import '../../../../assets/css/profile_wizard.css';
import WizardProfileForm from './step_forms/WizardProfileForm';
import WizardCompanyForm from './step_forms/WizardCompanyForm';
import WizardAddressForm from './step_forms/WizardAddressForm';
import WizardFinalForm from './step_forms/WizardFinalForm';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { submitProviderInfo } from './networking';
import FormLoader from '../../common/FormLoader';
import { connect } from 'react-redux';
import * as auth from '../../auth/__redux/authRedux';
import { withRouter, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as common from '../../common/__redux/commonRedux';
import PropTypes from 'prop-types';

const AccountSetupWizard = (props) => {
	const dictionary = useDictionary();
	const [step, setStep] = useState(1);
	const [max, setMax] = useState(1);
	const [showLoader, setShowLoader] = useState(false);
	const [details, setDetails] = useState({
		first_name: '',
		last_name: '',
		phone: '',
		company_name: '',
		legal_name: '',
		vat_number: '',
		website: '',
		address_line1: '',
		address_line2: '',
		city: '',
		state: '',
		country_id: '',
		short_description: '',
		ecommerce: false,
		presents: false,
		courier: false,
		upiria_co: false
	});
	const history = useHistory();

	useEffect(() => {}, []);

	const submitOnboardingData = async () => {
		setShowLoader(true);
		let newData = {
			first_name: details.first_name,
			last_name: details.last_name,
			phone: details.phone,
			brand_name: details.company_name,
			legal_name: details.legal_name,
			//tax_id_number: details.vat_number,
			website: details.website,
			address1: details.address_line1,
			address2: details.address_line2,
			city: details.city,
			region: details.state,
			short_description: details.short_description,
			country_id: parseInt(details.country_id)
		};

		try {
			let { data } = await submitProviderInfo(newData);
			props.actions.update_user(data);
			localStorage.setItem('from_wizard', 1);
			setTimeout(() => {
				history.push('/home');
			}, 100);

			setShowLoader(false);
		} catch (e) {
			setShowLoader(false);
			if (e?.response) {
				let code = e.response.data.error_code.toString();
				props.actions.setErrorMessage(code);
				if (code === '81023') {
					setStep(1);
				} else if (code === '81009') {
					setStep(2);
				}
			}
		}
	};

	const setIncrementalStep = (index) => {
		setStep(index);
		if (index > max) {
			setMax(index);
		}
	};

	const onCompleteClicked = () => {
		submitOnboardingData();
	};

	return (
		<div className="login-wrapper">
			<div className="login-form-wrapper">
				<div className="login-form-container">
					<div className="wizard-steps-wrapper">
						<div className="wizard-steps-title" style={{ paddingLeft: 6 }}>
							{dictionary.account_setup}
						</div>
						<div className="wizard-steps-container">
							<button
								data-testid="wizard-step-1"
								onClick={() => setStep(1)}
								disabled={max < 1}
								className={
									step === 1 ? 'wizard-step wizard-step-active' : 'wizard-step'
								}
							>
								{dictionary.profile}
							</button>
							<div className="wizard-caret" />
							<button
								data-testid="wizard-step-2"
								onClick={() => setStep(2)}
								disabled={max < 2}
								className={
									step === 2 ? 'wizard-step wizard-step-active' : 'wizard-step'
								}
							>
								{dictionary.company}
							</button>
							<div className="wizard-caret" />
							<button
								data-testid="wizard-step-3"
								onClick={() => setStep(3)}
								disabled={max < 3}
								className={
									step === 3 ? 'wizard-step wizard-step-active' : 'wizard-step'
								}
							>
								{dictionary.address}
							</button>
							<div className="wizard-caret" />
							<button
								data-testid="wizard-step-4"
								onClick={() => setStep(4)}
								disabled={max < 4}
								className={
									step === 4 ? 'wizard-step wizard-step-active' : 'wizard-step'
								}
							>
								{dictionary.complete}
							</button>
						</div>
					</div>
					<div className="title-divider" />
					{step === 1 ? (
						<WizardProfileForm
							setStep={setIncrementalStep}
							details={details}
							setDetails={setDetails}
						/>
					) : null}
					{step === 2 ? (
						<WizardCompanyForm
							setStep={setIncrementalStep}
							details={details}
							setDetails={setDetails}
						/>
					) : null}
					{step === 3 ? (
						<WizardAddressForm
							setStep={setIncrementalStep}
							details={details}
							setDetails={setDetails}
						/>
					) : null}
					{step === 4 ? (
						<WizardFinalForm
							onCompleteClicked={onCompleteClicked}
							setStep={setIncrementalStep}
							details={details}
							setDetails={setDetails}
						/>
					) : null}
				</div>
			</div>
			<FormLoader showLoader={showLoader} />
			<div className="login-side-image" />
		</div>
	);
};

AccountSetupWizard.propTypes = {
	actions: PropTypes.object,
	history: PropTypes.object
};

export default connect(auth.states, (dispatch) => {
	return {
		actions: bindActionCreators(
			{ ...auth.actions, ...common.actions },
			dispatch
		)
	};
})(withRouter(AccountSetupWizard));
