import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { resendEmail } from '../__redux/authCrud';
import * as Yup from 'yup';
import FormikHelper from '../../../../utilities/helpers/FormikHelper';
import '../../../../assets/css/login.css';
import '../../../../assets/css/form_elements.css';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import * as common from '../../common/__redux/commonRedux';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import FormLoader from '../../common/FormLoader';
import RegistrationConfirmation from './RegistrationConfirmation';
import PropTypes from 'prop-types';

const ResendActivationForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const [showConfirmation, setShowConfirmation] = useState(false);
	const history = useHistory();

	const LoginSchema = Yup.object().shape({
		email: FormikHelper.getDefaultYupEmail(true, dictionary)
	});

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const formik = useFormik({
		initialValues: {
			email: ''
		},
		onSubmit: (values, { setSubmitting }) => {
			setShowLoader(true);
			resendEmail(values.email)
				.then(() => {
					setShowLoader(false);
					setShowConfirmation(true);
				})
				.catch((error) => {
					setShowLoader(false);
					setSubmitting(false);

					if (error.response) {
						props.setErrorMessage(error.response.data.error_code.toString());
					}
				});
		},
		validateOnMount: true,
		validationSchema: LoginSchema
	});

	FormikHelper.setFormik(formik);

	// const onSignupClicked = () => {
	// 	history.push('register');
	// };

	return (
		<div className="login-wrapper">
			<form
				style={{ opacity: showForm ? '1' : '0' }}
				className="login-form-wrapper"
				onSubmit={formik.handleSubmit}
			>
				<div className="login-form-container" style={{ position: 'relative' }}>
					<div
						className="login-logo"
						style={{ cursor: 'pointer' }}
						onClick={() => history.goBack()}
					/>
					<div className="title-divider" />
					<h1>{dictionary.resend_activation_link}</h1>
					<div
						className="text"
						style={{ marginTop: '20px', marginBottom: '30px' }}
					>
						{dictionary.resend_activation_text}
					</div>
					<div className="login-form-content-container">
						{FormikHelper.getInputTemplate(
							'email',
							dictionary.email,
							dictionary.enter_email,
							'text',
							true,
							true
						)}
						<div className="group-inputs" style={{ marginTop: '30px' }}>
							<button
								data-testid="submit-button"
								type="submit"
								className={formik.isValid ? 'button' : 'button button-disabled'}
							>
								{dictionary.submit}
							</button>
						</div>
					</div>
					<FormLoader showLoader={showLoader} />
				</div>
				{showConfirmation ? (
					<RegistrationConfirmation
						setShowConfirmation={setShowConfirmation}
						email={formik.values.email}
					/>
				) : null}
			</form>
			<div className="login-side-image" />
		</div>
	);
};

ResendActivationForm.propTypes = {
	setErrorMessage: PropTypes.func
};

export default connect(common.states, common.actions)(ResendActivationForm);
