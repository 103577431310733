import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

const RejectRequestDialog = ({
	setShowDialog,
	onReject
}) => {
	const dictionary = useDictionary();
	const [rejectReason, setRejectReason] = useState();
	const [rejectOtherReason, setRejectOtherReason] = useState('');

	const rejectReasons = [
		{
			label: dictionary.schedule_conflict,
			value: 'schedule_conflict'
		},
		{
			label: dictionary.illness_or_injury,
			value: 'illness_or_injury'
		},
		{
			label: dictionary.weather_conditions,
			value: 'weather_conditions'
		},
		{
			label: dictionary.technical_issues,
			value: 'technical_issues'
		},
		{
			label: dictionary.other_reason,
			value: 'other'
		}
	];

	const onCancelClicked = () => {
		setShowDialog(false);
	};

	const onConfirmClicked = () => {
		const data = rejectReason === 'other' ?
			{
				status: "declined",
				cancel_reason: rejectReason,
				cancel_other_reason: rejectOtherReason
			} : {
				status: "declined",
				cancel_reason: rejectReason
			};
		onReject(data)
		setShowDialog(false);
	};

	const onRadioChanged = (e) => {
		setRejectReason(e.target.value);
	};

	const isDisabled = useMemo(() => {
		return rejectReason === 'other' ? rejectOtherReason.length <= 0 : !rejectReason;
	}, [rejectReason, rejectOtherReason]);

	return (
		<div className="dialog-wrapper" onClick={(e) => e.stopPropagation()}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">
						{dictionary.decline_booking_request}
					</div>
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container">
					<div className="slot-detail-title" style={{ marginBottom: 24 }}>
						{dictionary.why_cant_you_accept_this_request}
					</div>
					{rejectReasons.map(({ label, value }, index) => (
						<label key={index} className="radio-label">
							{label}
							<input
								type="radio"
								value={value}
								checked={rejectReason === value}
								onChange={onRadioChanged}
							/>
							<span className="checkmark" />
						</label>
					))}
					{rejectReason === 'other' && (
						<>
							<div className="text">{dictionary.please_specify}</div>
							<textarea
								rows={8}
								value={rejectOtherReason}
								onChange={(e) => setRejectOtherReason(e.target.value)}
								placeholder={dictionary.decline_reason}
								style={{ marginTop: 8 }}
							/>
						</>
					)}
				</div>

				<div className="dialog-title-divider" style={{ margin: 0 }} />
				<div
					className="dialog-buttons-container"
					style={{
						padding: 12,
						marginBottom: 0,
						alignSelf: 'center',
						width: '100%'
					}}
				>
					<button onClick={onCancelClicked} className="tertiary-button">
						{dictionary.cancel}
					</button>
					<button
						onClick={onConfirmClicked}
						disabled={isDisabled}
						className="button"
					>
						{dictionary.decline}
					</button>
				</div>
			</div>
		</div>
	);
};

RejectRequestDialog.propTypes = {
	setShowDialog: PropTypes.func,
	onReject: PropTypes.func
};

export default RejectRequestDialog;
