import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import groupRates from './GroupRates';
import PropTypes from 'prop-types';
import React from 'react';

const GroupRateItem = ({
	item,
	onRemoveItemClicked,
	setValue,
	index,
	min,
	max,
	groupRated
}) => {
	const dictionary = useDictionary();
	const setMinSize = (e) => {
		setValue(e, index, 'min_size');
	};

	const handleMinBlur = (e) => {
		const value = +e.target.value;
		if (value > item.max_size || value < min) {
			e.target.value = item.max_size;
		}
		setValue(e, index, 'min_size');
	};

	const setMaxSize = (e) => {
		setValue(e, index, 'max_size');
	};

	const handleMaxBlur = (e) => {
		const value = +e.target.value;
		if (value > max || value < item.min_size) {
			e.target.value = item.min_size;
		}
		setValue(e, index, 'max_size');
	};

	return (
		<div
			key={index}
			className="provided-item-row"
			style={{
				gap: '20px',
				alignItems: 'unset',
				position: 'relative',
				marginBottom: item.isInvalid ? 20 : 15
			}}
			data-testid="group-rate-item"
		>
			<div
				className="group-inputs"
				style={{ marginTop: '0', marginBottom: '0' }}
			>
				<label
					style={{ fontWeight: '400', marginBottom: '10px', fontSize: '12px' }}
				>
					{dictionary.group_size}
				</label>
				<div className="provided-item-row">
					<input
						value={item.min_size}
						min={min}
						max={+item.max_size}
						onChange={setMinSize}
						onBlur={handleMinBlur}
						size={1}
						className="small-number-input"
						type="number"
						data-testid="min-size-input"
					/>
					<div
						style={{ marginLeft: '5px', marginRight: '5px' }}
						className="text"
					>
						-
					</div>
					<input
						value={item.max_size}
						min={+item.min_size}
						max={max}
						onChange={setMaxSize}
						onBlur={handleMaxBlur}
						className="small-number-input"
						type="number"
						data-testid="max-size-input"
					/>
				</div>
			</div>

			<div
				className={`group-inputs ${item.isInvalid && 'group-inputs--error'}`}
				style={{ marginTop: '0', marginBottom: '0' }}
			>
				<label
					style={{ fontWeight: '400', marginBottom: '10px', fontSize: '12px' }}
				>
					{dictionary.discount}
				</label>
				<div className="percent-input-container">
					<input
						onChange={(e) => setValue(e, index, 'discount')}
						value={item.discount}
						className="percent-input"
						type="number"
						min={0}
						data-testid="discount-input"
					/>
				</div>
			</div>

			<div className="group-inputs" style={{ marginTop: '0' }}>
				<label
					style={{ fontWeight: '400', marginBottom: '10px', fontSize: '12px' }}
				>
					{dictionary.each_participant_pays}
				</label>
				<div className="money-input-container">
					<input
						onChange={(e) => setValue(e, index, 'participantPrice')}
						value={item.participantPrice}
						className="money-input"
						type="number"
					/>
				</div>
			</div>
			<div
				onClick={() => onRemoveItemClicked(item)}
				style={{ alignSelf: 'end', marginBottom: '15px', marginLeft: '0' }}
				className="dialog-document-row-delete"
				data-testid="remove-item-button"
			/>
			{item.isInvalid && (
				<div
					className="terms-text"
					style={{ color: '#fd4f4f', position: 'absolute', top: 69 }}
				>
					{dictionary.invalid_group_range}
				</div>
			)}
		</div>
	);
};

GroupRateItem.propTypes = {
	item: PropTypes.object,
	onRemoveItemClicked: PropTypes.func,
	setValue: PropTypes.func,
	index: PropTypes.number,
	min: PropTypes.number,
	max: PropTypes.number
};

export default GroupRateItem;
