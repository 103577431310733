import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as CornersInIcon } from '../../../../../../assets/icons/ic_corners_in.svg';

const DescriptionDialog = ({
	description,
	setDescription,
	setShowDescriptionDialog
}) => {
	const dictionary = useDictionary();
	const [value, setValue] = useState(description);

	const onChange = (e) => {
		setValue(e.target.value);
	};

	const saveDescription = () => {
		setDescription({ target: { value } });
		setShowDescriptionDialog(false);
	};

	return (
		<div className="dialog-wrapper">
			<div className="dialog-container description-dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">
						{dictionary.what_will_you_and_guests_do}
					</div>
					<button className="description-collapse-button" onClick={saveDescription}>
						<CornersInIcon />
					</button>
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />
				<div className="dialog-content-container">
					<div className="text">{dictionary.provide_specific_plans}</div>
					<textarea
						rows={20}
						value={value}
						onChange={onChange}
						placeholder={dictionary.tell_participants_a_story}
						data-testid="experience-plans-input"
					/>
					<div
						className="dialog-buttons-container"
						style={{ marginTop: 20, marginBottom: 0, marginLeft: 'auto' }}
					>
						<button className="button" onClick={saveDescription}>
							Save
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

DescriptionDialog.propTypes = {
	description: PropTypes.string.isRequired,
	setDescription: PropTypes.func,
	setShowDescriptionDialog: PropTypes.func
};

export default DescriptionDialog;
