import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import UpcomingBookings from '../upcoming_bookings/UpcomingBookings';
import OnBoardingFirstExperience from './OnBoardingFirstExperience';
import OnBoardingLegalInfo from './OnBoardingLegalInfo';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import BookingRequests from '../booking_requests/BookingRequests';

const ProviderOnBoardingActivities = ({
	showDocumentsUploadDialog,
	config,
	setShowLoader,
	onAgendaItemClicked,
	showSlotDetails,
	slotDetails,
	dismissDetails,
	reloadCounter
}) => {
	const dictionary = useDictionary();
	const [showBookingRequest, setShowBookingRequest] = useState(true);

	return (
		<div className="home-content-container" style={{ width: '100%' }}>
			{(!config?.documents_submitted && config !== null) ||
			!config?.experience_created ? (
				<>
					<h2 style={{ marginBottom: '10px' }}>
						{dictionary.get_the_most_out_of_upiria}
					</h2>
					<h4 style={{ fontWeight: 'normal' }}>{dictionary.recommend_next}</h4>
				</>
			) : null}
			<div className="onboarding-activities-container thin-scrollbar">
				{/*{config?.verification_status?.value !== "3" && config!==null ? <OnBoardingVerificationStatus status={config?.verification_status?.value}/> : null}*/}
				{config !== null && config?.verification_status?.value !== '3' ? (
					<OnBoardingLegalInfo
						config={config}
						showDocumentsUploadDialog={showDocumentsUploadDialog}
						setShowLoader={setShowLoader}
					/>
				) : null}
				{!config?.experience_created ? <OnBoardingFirstExperience /> : null}
				{/*{!config?.customer_added ? <OnBoardingFirstCustomers/> : null}*/}
				{/*{!config?.payout_method_added ? <OnBoardingPayoutMethod/> : null}*/}
				{showBookingRequest && <BookingRequests />}
				{config?.experience_created ? (
					<UpcomingBookings
						reloadCounter={reloadCounter}
						setShowLoader={setShowLoader}
						onAgendaItemClicked={onAgendaItemClicked}
						showSlotDetails={showSlotDetails}
						slotDetails={slotDetails}
						dismissDetails={dismissDetails}
					/>
				) : null}
			</div>
		</div>
	);
};

ProviderOnBoardingActivities.propTypes = {
	showDocumentsUploadDialog: PropTypes.func.isRequired,
	config: PropTypes.object,
	setShowLoader: PropTypes.func.isRequired,
	onAgendaItemClicked: PropTypes.func.isRequired,
	showSlotDetails: PropTypes.bool.isRequired,
	slotDetails: PropTypes.object,
	dismissDetails: PropTypes.func.isRequired,
	reloadCounter: PropTypes.number.isRequired
};

export default ProviderOnBoardingActivities;
