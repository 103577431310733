import logo from '../../../../assets/images/logo_white_letter.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import React from 'react';
import { QRCode } from 'react-qrcode-logo';
import { ReactComponent as ShareOutIcon } from "../../../../assets/icons/ic_share_out.svg";
import {FacebookShareButton, TelegramShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import whatsapp from "../../../../assets/icons/ic_whatsapp.svg";
import facebook from "../../../../assets/icons/ic_facebook.svg";
import twitter from "../../../../assets/icons/ic_twitter.svg";
import telegram from "../../../../assets/icons/ic_telegram.svg";

const ShareExperienceDialog = ({ shareUrl, setShareDialogVisibility }) => {
	const [opacity, setOpacity] = useState(0);
	const [isCopied, setIsCopied] = useState(false);

	const dictionary = useDictionary();

	const onCopyClicked = () => {
		navigator.clipboard.writeText(shareUrl).then(
			function () {
				setIsCopied(true);
				setTimeout(() => {
					setIsCopied(false);
				}, 2000);
			},
			// eslint-disable-next-line no-unused-vars
			function (err) {}
		);
	};

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShareDialogVisibility(false);
		}, 800);
	};

	const downloadQRCode = () => {
		// Generate download with use canvas and stream
		const canvas = document.getElementById('qr-gen');
		const pngUrl = canvas
			.toDataURL('image/png')
			.replace('image/png', 'image/octet-stream');
		let downloadLink = document.createElement('a');
		downloadLink.href = pngUrl;
		downloadLink.download = `${shareUrl}.png`;
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	};

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	return (
		<div className="dialog-wrapper" style={{ opacity: opacity }}>
			<div
				className="share-dialog-container share-dialog-container--experiences"
				data-testid="share-dialog"
			>
				<div className="share-dialog-side-image share-dialog-side-image--experiences" />
				<div className="share-dialog-content-container">
					<div className="share-dialog-header">
						<div className="login-logo" style={{ marginTop: '0' }} />
						<div
							onClick={onCancelClicked}
							className="dialog-close-button"
							style={{ position: 'unset' }}
						/>
					</div>
					<h3 style={{ fontStyle: 'normal', marginBottom: 16 }} data-testid="share-dialog-title">
						{dictionary.share_on_socials}
					</h3>
					<div className="share-dialog-icons-container">
						<WhatsappShareButton
							url={shareUrl}
							quote={'I just created an experience on Upiria!'}
							hashtag="#upiria"
						>
							<div
								className="share-dialog-icon"
								style={{ backgroundImage: 'url(' + whatsapp + ')' }}
							/>
						</WhatsappShareButton>
						<FacebookShareButton
							url={shareUrl}
							quote={'I just created an experience on Upiria!'}
							hashtag="#upiria"
						>
							{/* <FacebookIcon size={32} round /> */}
							<div
								className="share-dialog-icon"
								style={{ backgroundImage: 'url(' + facebook + ')' }}
							/>
						</FacebookShareButton>
						<TwitterShareButton
							url={shareUrl}
							quote={'I just created an experience on Upiria!'}
							hashtag="#upiria"
						>
							<div
								className="share-dialog-icon"
								style={{ backgroundImage: 'url(' + twitter + ')' }}
							/>
						</TwitterShareButton>
						<TelegramShareButton
							url={shareUrl}
							quote={'I just created an experience on Upiria!'}
							hashtag="#upiria"
						>
							<div
								className="share-dialog-icon"
								style={{ backgroundImage: 'url(' + telegram + ')' }}
							/>
						</TelegramShareButton>
					</div>
					<div className="share-link-header">
						<h3 style={{ fontStyle: 'normal', marginTop: 30, marginBottom: 16 }}>
							{dictionary.copy_link}
						</h3>
					</div>
					<div className="share-link-text-container-wrapper">
						<div
							className="share-link-text-container"
							data-testid="share-link-text-container"
						>
							<div className="share-link-text">{shareUrl}</div>
							<div
								className={`share-link-copy-icon ${isCopied ? 'copied' : ''}`}
								onClick={onCopyClicked}
								data-testid="share-link-copy-icon"
							></div>
						</div>
						<a href={shareUrl} className="secondary-button open-new-button" target="_blank" rel="noreferrer">
							<ShareOutIcon />
						</a>
					</div>
					<div className="flex-filler" />
					<h3 style={{ fontStyle: 'normal', marginTop: 30, marginBottom: 4 }}>
						{dictionary.copy_qr_code}
					</h3>
					<div className="share-download-qrcode">
						<QRCode
							value={shareUrl}
							id="qr-gen"
							logoPadding={4}
							logoImage={logo}
							logoHeight={28}
							logoWidth={28}
							removeQrCodeBehindLogo={true}
							eyeColor={[
								{ inner: '#e55a5c', outer: 'black' },
								{ inner: '#e55a5c', outer: 'black' },
								{ inner: '#e55a5c', outer: 'black' }
							]}
						/>
						<div
							className="secondary-button"
							style={{ marginLeft: 10 }}
							onClick={downloadQRCode}
						>
							{dictionary.download_code}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

ShareExperienceDialog.propTypes = {
	shareUrl: PropTypes.string,
	setShareDialogVisibility: PropTypes.func
};

export default ShareExperienceDialog;
