import React from 'react';
import { UseCurrencyDecorator } from '../../../../../../../../utilities/hooks/UseCurrencyDecorator';
import PropTypes from 'prop-types';
import { TextFormatHelper } from '../../../../../../../../utilities/helpers/TextFormatHelper';
import { DateFormatHelper } from '../../../../../../../../utilities/helpers/DateFormatHelper';

const PaymentItem = ({ item, date }) => {
	const currencyDecorator = UseCurrencyDecorator();

	function cutString(str, x) {
		if (str.length < 11) {
			return str;
		}

		const start = str.substring(4, x + 4);
		const end = str.substring(str.length - x);
		return start + '...' + end;
	}

	return (
		<tr data-testid="payment-item">
			<td style={{ verticalAlign: 'top' }}>{cutString(item.id, 4)}</td>
			<td style={{ verticalAlign: 'top' }}>
				{DateFormatHelper.monthYearTimeFormat(date)}
			</td>
			<td style={{ verticalAlign: 'top', minWidth: '48px' }}>
				{item.type ? TextFormatHelper.toUpperCaseFirstLetter(item.type) : ''}
			</td>
			<td style={{ verticalAlign: 'top', maxWidth: '170px' }}>
				{item.reference}
			</td>
			<td style={{ verticalAlign: 'top' }}>{item.friendly_name}</td>
			<td align="right" style={{ verticalAlign: 'top' }}>
				{item.amount ? currencyDecorator(item.amount) : ''}
			</td>
		</tr>
	);
};

PaymentItem.propTypes = {
	item: PropTypes.object.isRequired,
	date: PropTypes.number.isRequired
};

export default PaymentItem;
