import React from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { usePaymentStatuses } from '../../../../../utilities/hooks/usePaymentStatuses';
import caretLeft from '../../../../../assets/icons/ic_caret_left.svg';
import MultipeCustomPaymentCheckbox from '../../../common/MultipeCustomPaymentCheckbox';

const SelectFilterPaymentStatus = (props) => {
    const dictionary = useDictionary();
    const paymentStatuses = usePaymentStatuses();

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowPaymentStatusSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(paymentStatuses).map((paymentStatus) => (
                <MultipeCustomPaymentCheckbox
                    label={paymentStatuses[paymentStatus].text}
                    checbboxObj={props.paymentStatusCheckeds}
                    itemKey={paymentStatus}
                    onFilterPaymentStatusSelected={props.onFilterPaymentStatusSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    key={paymentStatuses[paymentStatus].id}
                />
            ))}
        </div>
    );
}

SelectFilterPaymentStatus.propTypes = {
    onPaymentStatusValuesChanged: PropTypes.func,
	setShowBoxFilter: PropTypes.func,
	setShowPaymentStatusSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    paymentStatusCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterPaymentStatusSelected: PropTypes.func
};

export default SelectFilterPaymentStatus;
