import { shallowEqual, useSelector } from 'react-redux';

export const useUserTypeId = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);
	return user?.user_type?.id;
};
