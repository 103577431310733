import PropTypes from 'prop-types';
import React from 'react';
import caretLeft from '../../../../../assets/icons/ic_caret_left.svg';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import MultipeCustomProviderCheckbox from '../../../common/MultipeCustomProviderCheckbox';

const SelectFilterProviders = (props) => {
    const dictionary = useDictionary();
    const providers = props.providers;
    let providerItems = [];
    let providerObjs = {};
    for (const provider of providers) {
        const providerItem = {
            id: provider.id,
            text:  provider.brand_name ? provider.brand_name : provider.email,
            class: provider.id,
        }
        
        providerItems.push(providerItem);
        providerObjs[provider.id] = providerItem;
    }

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowProviderSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(providerObjs).map((providerObj) => (
                <MultipeCustomProviderCheckbox
                    label={providerObjs[providerObj].text}
                    checbboxObj={props.providerCheckeds}
                    itemKey={providerObj}
                    onFilterProviderSelected={props.onFilterProviderSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    providerObjs={providerObjs}
                    key={providerObjs[providerObj].id}
                />
            ))}
            
        </div>
    );
}

SelectFilterProviders.propTypes = {
	setShowBoxFilter: PropTypes.func,
	setShowExperienceSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    accessCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterExperienceSelected: PropTypes.func
};

export default SelectFilterProviders;
