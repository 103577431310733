import '../../../../../../assets/css/spinner.css';
import CustomToast from '../../../../common/CustomToast';
import { uploadExperienceAsset } from '../../../provider_experiences/networking';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// import { useDictionary } from '../../../../../../../utilities/hooks/UseDictionary';

const UploadingItem = ({ file, tempId, onAssetUploadFinished }) => {
	const [uploadFailed, setUploadFailed] = useState(false);

	useEffect(() => {
		uploadExperienceAssetAsync();
	}, []);

	const uploadExperienceAssetAsync = async () => {
		try {
			const { data } = await uploadExperienceAsset(tempId, file);
			// eslint-disable-next-line no-prototype-builtins
			if (data.hasOwnProperty('error_code')) {
				toast.info(<CustomToast message={data.message} />, {
					position: 'bottom-left',
					autoClose: 5000,
					toastId: 'max',
					hideProgressBar: true,
					closeOnClick: true,
					pauseOnHover: false,
					draggable: false,
					progress: undefined,
					closeButton: false,
					icon: false,
					className: 'toast-white-warning'
				});
				// Set the uploadFailed state to true
				setUploadFailed(true);
			} else {
				onAssetUploadFinished(file, data);
			}
		} catch (error) {
			console.log(error);
			// Set the uploadFailed state to true
			setUploadFailed(true);
		}
	};

	return (
		!uploadFailed && (
			<div className="media-file-wrapper">
				<div className="media-file-container">
					<div className="media-file-info">
						<div className="preview">
							<svg viewBox="0 0 50 50" className="spinner">
								<circle className="ring" cx="25" cy="25" r="22.5" />
								<circle className="line" cx="25" cy="25" r="22.5" />
							</svg>
						</div>
					</div>
				</div>
				<div className="text">{file.name}</div>
			</div>
		)
	);
};

UploadingItem.propTypes = {
	file: PropTypes.object,
	tempId: PropTypes.string,
	onAssetUploadFinished: PropTypes.func
};

export default UploadingItem;
