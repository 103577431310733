import marker from '../../../../../../assets/icons/ic_marker.svg';
import locationMarker from '../../../../../../assets/icons/ic_my_location.svg';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
// eslint-disable-next-line no-unused-vars
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import dotenv from 'dotenv';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef, useState } from 'react';
import React from 'react';
import { usePlacesWidget } from 'react-google-autocomplete';

dotenv.config();

const MeetingPoint = ({
	setEnabled,
	place,
	setPlace,
	directions,
	setDirections
}) => {
	const dictionary = useDictionary();
	const mapRef = useRef(null);
	const [zoom, setZoom] = useState(7);
	const [center, setCenter] = useState(null);
	const [myPosition, setMyPosition] = useState(null);
	const [isLoaded, setIsLoaded] = useState(false);
	const [markers, setMarkers] = useState([]);

	const { ref } = usePlacesWidget({
		// eslint-disable-next-line no-undef
		apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
		options: {
			types: []
		},
		onPlaceSelected: (place) => {
			setPlace(place);
		}
	});

	useEffect(() => {
		if (myPosition !== null && place === null) {
			setTimeout(() => {
				if (myPosition === null || !myPosition.coords) {
					setCenter({ lat: 38.0068661, lng: 23.6950456 });
				} else {
					setCenter({
						lat: myPosition.coords.latitude,
						lng: myPosition.coords.longitude
					});

					setMarkers([
						<Marker
							icon={locationMarker}
							key={2121}
							//onClick={() => onMarkerClick(provider.id)}
							position={{
								lat: myPosition.coords.latitude,
								lng: myPosition.coords.longitude
							}}
						/>
					]);
				}
			}, 2200);
		} else {
			if (place === null) {
				setTimeout(() => {
					setCenter({ lat: 38.0068661, lng: 23.6950456 });
				}, 2200);
			}
		}
	}, [myPosition]);

	useEffect(() => {
		if (center !== null) {
			mapRef.current.panTo(center);
		}
	}, [center]);

	const getMyLocationMarker = () => {
		if (navigator.geolocation) {
			if (Object.keys(navigator.geolocation).length === 0) {
				setMyPosition(null);
			}
			navigator.geolocation.getCurrentPosition((position) => {
				if (position === null) {
					setMyPosition(null);
				} else {
					setMyPosition(position);
				}
			});
		} else {
			setMyPosition(null);
		}
	};

	useEffect(() => {
		getMyLocationMarker();
		setTimeout(() => {
			setIsLoaded(true);
		}, 2000);
	}, []);

	const onLoad = useCallback((mapInstance) => {
		// eslint-disable-next-line no-undef
		const bounds = new google.maps.LatLngBounds();

		mapRef.current = mapInstance;
		mapInstance.fitBounds(bounds);
	}, []);

	useEffect(() => {
		if (
			place != null &&
			place &&
			place.geometry &&
			place.geometry.location &&
			isLoaded
		) {
			let newMarkers = [];
			newMarkers.push(getPlaceMarker(place));
			getMyLocationMarker();
			if (myPosition !== null) {
				newMarkers.push(
					<Marker
						icon={locationMarker}
						key={2121}
						position={{
							lat: myPosition.coords.latitude,
							lng: myPosition.coords.longitude
						}}
					/>
				);
			}

			setMarkers(newMarkers);
		}

		setEnabled(place !== null);
	}, [place, isLoaded]);

	const onMarkerDragged = (e) => {
		let newPlace = {};
		newPlace['geometry'] = {};
		newPlace.geometry['location'] = {};
		newPlace.geometry.location.lat = function () {
			return e.latLng.lat();
		};
		newPlace.geometry.location.lng = function () {
			return e.latLng.lng();
		};

		setPlace(newPlace);
	};

	const getPlaceMarker = (place) => {
		setCenter({
			lat: place?.geometry?.location?.lat(),
			lng: place?.geometry?.location?.lng()
		});
		return (
			<Marker
				onDragEnd={onMarkerDragged}
				draggable={true}
				icon={marker}
				key={place.place_id}
				position={{
					lat: place?.geometry?.location?.lat(),
					lng: place?.geometry?.location?.lng()
				}}
			/>
		);
	};

	const onDirectionsChanged = (e) => {
		setDirections(e.target.value);
	};

	return (
		<div className="new-experience-form-wrapper">
			<div className="new-experience-form-container thin-scrollbar-trans">
				<h2>{dictionary.where_should_meet}</h2>
				<div className="text">{dictionary.add_experience_point}</div>
				<div className="group-inputs">
					<input
						ref={ref}
						className="experience-address-input"
						placeholder={dictionary.enter_address}
						data-testid="address-input"
					/>
					<div className="experience-address-icon" />
				</div>
				{isLoaded ? (
					<div className="experience-map" data-testid="map">
						<GoogleMap
							onZoomChanged={(zoom) => {
								if (zoom != null) setZoom(zoom);
							}}
							zoom={zoom}
							center={center}
							defaultZoom={5}
							options={{ zoomControl: false, streetViewControl: false }}
							onLoad={onLoad}
							mapContainerClassName="experience-map"
						>
							{markers}
						</GoogleMap>
					</div>
				) : null}
				<div style={{ marginTop: '15px' }} className="text">
					{dictionary.directions}
				</div>
				<textarea
					style={{ marginBottom: '20px' }}
					value={directions}
					onChange={onDirectionsChanged}
					placeholder={dictionary.please_provide_directions}
					data-testid="directions-input"
				/>
			</div>
		</div>
	);
};

MeetingPoint.propTypes = {
	setPlace: PropTypes.func,
	setEnabled: PropTypes.func,
	setDirections: PropTypes.func,
	directions: PropTypes.string,
	place: PropTypes.object
};

export default MeetingPoint;
