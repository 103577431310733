import { ADMIN_EMAIL } from '../../config';

export const dictionaries = {
	elDictionary: {},
	enDictionary: {
		requiredTemplate: (label) => {
			return `${label} is required`;
		},

		//validation messages
		password_too_short: 'The password is too short, minimum 8 characters',
		password_too_long: 'The password is too long, maximun 30 characters',
		invalid_email:
			'Please enter a valid email address (e.g. user@provider.com)',

		//registration and login
		welcome_to_upiria: 'Welcome to Upiria',
		join_to_upiria: 'Join Upiria today!',
		welcome_upirian: 'Welcome Upirian!',
		login_message:
			'Find your people. Engage your customers. Build your brand. Do it all with Upiria Travel Platform.',
		dont_have_an_account: 'Don’t have an account?',
		registerMessage:
			'Become a member of a community that connects experience providers with a global audience.',
		signup: 'Sign up',
		login: 'Login',
		submit: 'Submit',
		forgot_pass: 'Forgot password?',
		registration_updates_message:
			'I want to receive updates from Upiria related to travel best practices, product and feature updates, and promotions.',
		registration_terms_message:
			'By clicking the “Sign up” button, you are creating a Upiria account, and you agree to Upiria’s',
		already_upirian: 'Already a Upirian?',
		and: 'and',
		terms_label: 'Terms of Usage',
		legal_documents_terms_message:
			'By clicking this checkbox you agree to Upiria’s:',
		agree_cancellation_policy: 'I agree to Upiria\'s',
		privacy_policy_label: 'Privacy Policy',
		general_terms_and_conditions: 'General Terms and Conditions',
		provider_terms_and_conditions: 'Provider Terms and Conditions',
		check_your_email: 'Check your email',
		sent_message_to_mail_1: 'We’ve sent a message to',
		sent_message_to_mail_2: 'with a link to confirm your email address.',
		sent_message_to_mail_2_2: 'with a link to reset your password.',
		didnt_get_email: 'Didn’t get an email?',
		activating_your_account:
			"We're activating your account, upon success you will be redirected to the login screen",
		dont_see_email:
			'If you don’t see an email from us within a few minutes, a few things could have happened:',
		here_is_why: 'Here is why according with',
		activation_success: 'Your account has been activated successfully.',
		no_email_reasons: [
			'The email might be in your spam folder.',
			'You misspelled your email address.',
			'You accidentally submitted another email address.',
			'We can’t deliver the email to your address, due to corporate firewalls or filtering.'
		],
		re_enter_email: 'Re-enter your email and try again',
		here_why: 'Here is why',
		reset_password: 'Reset password',
		complete_password_reset: 'Complete password reset',
		set_up_your_new_password: 'Set up your new password',
		reset_message:
			'Please enter the email registered with your account and we will send an email with instructions to reset your password.',
		complete_reset_message:
			'Please type your new password twice to complete the password reset process',
		resend_activation_link: 'Resend activation link',
		resend_activation_text:
			'Please enter the email address you used to registered and we will send you a new activation email.',

		//profile setup wizard
		account_setup: 'Account Setup',
		profile: 'Profile',
		company: 'Company',
		address: 'Address',
		finish: 'Finish',
		previous: 'Previous',
		cancel: 'Cancel',
		reason: 'Reason',
		note: 'Note',
		continue: 'Continue',
		complete: 'Complete',
		lets_setup: "Let's set up your account",
		step1_message: 'Please enter your personal information',
		step2_message: 'Please enter your company information',
		step2_title: 'Introduce your company',
		step3_title: 'Add your company address',
		step3_message:
			'But why? Believe it or not, we need a physical address to make sure your emails comply with international anti-spam laws. Don’t have an official business address? Learn about physical address alternatives',
		step4_title: 'You are one step away from becoming a Upirian!',
		step4_message:
			'Please click the button below to complete your Account Setup.',
		first_name: 'First Name',
		last_name: 'Last Name',
		phone: 'Phone Number',
		brand_name: 'Brand Name',
		company_name: 'Company name',
		legal_name: 'Legal Name',
		contact_email: 'Contact email',
		website: 'Website',
		account_iban: 'Account IBAN',
		beneficiary_name: 'Beneficiary name',
		bank_account_beneficiary_name: 'Bank Account Beneficiary Name',
		address_line1: 'Address line 1',
		address_line2: 'Address line 2',
		street_and_number: 'Street and Number',
		postal_code: 'Postal Code',
		city: 'City',
		state: 'State / Province / Region',
		country: 'Country',
		additional_emails:
			'Select additional emails you want to receive from Upiria:',
		e_commerce_newsletter_title: 'E-commerce Newsletter',
		e_commerce_newsletter_text:
			'Actionable advice to help you drive traffic, increase conversion, and grow sales for your online business.',
		upiria_presents: 'Upiria Presents',
		upiria_presents_text:
			'A monthly newsletter highlighting Upiria’s original short-form series, films, and podcasts made with entrepreneurs in mind.',
		courier_weekly_title: 'Courier Weekly',
		courier_weekly_text:
			'Practical insights, news analysis, and case studies of modern businesses, delivered to your inbox every week.',
		upiria_co_title: 'Upiria & Co',
		upiria_co_text:
			'Tips, updates, and inspiration for freelancers and agencies helping clients use Upiria.',
		checking_documents: 'We are checking your documents',
		documents_rejected: 'Documents were rejected',
		passwordMessage:
			"The password can't start with a number, must contain 8 characters including one uppercase, one lowercase, one number and one special case character",

		//navigation
		home: 'Home',
		booking: 'Booking',
		bookings: 'Bookings',
		calendar: 'Calendar',
		my_groups: 'My Groups',
		products: 'Products',
		experiences: 'Experiences',
		experience: 'Experience',
		add_ons: 'Add-ons',
		resource: 'Resource',
		resources: 'Resources',
		team: 'Team',
		customers: 'Customers',
		companies: 'Companies',
		marketing: 'Marketing',
		reports: 'reports',
		resale: 'Resale Hub',
		marketplace: 'Marketplace',
		asset: 'Asset',
		marketplaces: 'Marketplaces',
		assets: 'Assets',
		resale_collection: 'Resale Collection',
		resale_experience: 'Resale Experiences',
		booking_information: 'Booking Information',
		resellers: 'Resellers',
		collections: 'Collections',
		collection : 'Collection',

		//admin navigation
		provider_applications: 'Provider applications',
		pending_applications: 'Pending applications',
		approved_applications: 'Approved applications',

		//admin
		pending_provider_applications: 'Pending provider applications',
		approved_provider_applications: 'Approved provider applications',
		verified: 'Verified',
		unverified: 'Unverified',
		unverify: 'Unverify',
		rejected: 'Rejected',
		new: 'new',
		download: 'Download',
		reject: 'Reject',
		verify: 'Verify',
		approve: 'approve',
		not_uploaded_yet: 'Not uploaded yet',
		no_files_uploaded_yet: "The provider hasn't uploaded any files yet.",
		rejection_reason: 'Rejection reason',
		decline_reason: 'Decline reason',
		cancel_reason: 'Cancel reason',
		reschedule_reason: 'Reschedule reason',
		cancel_note: 'Cancel note',
		provide_rejection_reason: 'Please provide a rejection reason',
		registered_at: 'Registered at: ',
		days_since_registration: 'Days since registration',

		//home
		sort_by: 'Sort by',
		prev_page: 'Prev',
		upcoming_bookings: 'Upcoming bookings',
		booking_request: 'Booking Request',
		upcoming_agenda: 'Upcoming agenda',
		reject_booking_request: 'Reject booking request',
		decline_booking_request: 'Decline booking request',
		confirm_booking_request: 'Confirm booking request',
		booking_confirmation: 'Booking Confirmation',
		experience_title: 'Experience title',
		date_time: 'Date & time',
		total_cost: 'Total cost',
		reason_for_change: '*Reason for change',
		describe_reason_change: 'Describe the reason of change, what extra do will offer',
		allow_overbooking: 'Allow overbooking',
		why_do_you_want_to_reject_booking_request: 'Why do you want to reject booking request?',
		why_do_you_want_to_decline_booking_request: 'Why do you want to decline booking request?',
		why_cant_you_accept_this_request: 'Why can\'t you accept this request?',
		please_specify: 'Please Specify',
		why_do_you_want_to_confirm_booking_request: 'Why do you want to confirm booking request?',
		current_price: 'Current price',
		original_amount: 'Original amount',
		change_amount: 'Change amount',
		custom_and_field_to_add_the_amount: 'Custom and a field to add the amount',
		schedule_conflict: 'Schedule conflict',
		illness_or_injury: 'Illness or injury',
		weather_conditions: 'Weather conditions',
		technical_issues: 'Technical issues',
		other_reason: 'Other reason',
		suggest_date: 'Suggest date',
		suggest_new_date: 'Suggest new date',
		reschedule_new_date: 'Reschedule to new date',
		suggest: 'Suggest',
		see_calendar: 'See calendar',
		ready_to_get_started: 'Ready to get started?',
		get_the_most_out_of_upiria: 'Get the most out of Upiria!',
		recommend_next: 'Here are a few things you can do.',
		add_legal_info_title: 'Add your legal information',
		add_legal_info: 'Add legal information',
		change_legal_info: 'Change legal information',
		add_legal_info_text:
			'This is a necessary step to start selling and to accept payments.',
		create_first_experience_title: 'Create your first experience',
		create_first_experience: 'Create first experience',
		create_first_experience_text:
			'This is any travel service you provide. Once you create your experience, you can add it to your calendar and make bookings.',
		add_customers_title: 'Add your customers',
		add_customers_text:
			'Manage your offline bookings efficiently by adding your customer to your general database.',
		add_payout_title: 'Add payout method',
		add_payout_text:
			'Set up your payout method, so you can withdraw funds earned from Upiria Marketplace bookings.',
		payouts: 'Payouts',
		transactions: 'Transactions',
		withdraw_funds: 'Withdraw funds',
		see_details: 'See details',
		pending: 'Pending',
		estimated_upcoming: 'Estimated Upcoming',
		available: 'Available',
		overbooked: 'Overbooked',
		in_progress: 'In Progress',
		booked: 'Booked',
		booked_date: 'Booked date',
		unavailable: 'Unavailable',
		paused: 'Paused',
		add_availability: 'Add availability',
		booked_message:
			'You cannot edit this experience since there are bookings pending',
		last_payment: 'Last payment',
		reports_overview: 'Reports Overview',
		generated_revenue: 'Generated Revenue',
		upcoming_revenue: 'Upcoming Revenue',
		submit_documents_title: 'Welcome Upirian!',
		submit_documents_title_2: 'Please add your company\'s legal information! ',
		submit_documents_text:
			'You have gained access to our Trial Mode. This means you can create one experience and take a look around our features. To start selling experiences, please add your company\'s legal information.',
		submit_docs: 'Submit docs',
		lets_do_this: 'Let\'s do this',
		company_legal_info: 'Company\'s Legal Information',
		do_it_later: 'No, I’ll do it later',
		skip_for_now: 'Skip for now',
		company_registration_number: 'Company registration number',
		vat_number: 'International VAT Number',
		upload_doc: 'Upload doc',
		pdf_jpeg: 'PDF JPEG',
		id_document: 'ID Document',
		id_document_text:
			'A copy of your id card or passport, in case of an id card scan both sides of the document, in case of a passport scan the first page of the document',
		proof_of_address: 'Address document',
		proof_of_address_document_text:
			'A copy of a utility bill where the address is clearly stated',
		iban_document: 'IBAN Document',
		iban_document_text:
			"A copy of your corporate bank account statement where the IBAN and the account holder's name are clearly stated",
		company_document: 'Company Document',
		company_document_text:
			'An official government document stating the active fields of expertise of your company with a clearly stated date (screenshots will not be accepted)',
		view_terms_message:
			'We want to ensure that you have access to all relevant information regarding our services. To learn more about our policies, please view our ',
		verify_company_title: 'Verify your company',
		verify_company_text: 'Please upload your documents for verification',
		verify_company_reject_text:
			'Your documents were rejected. Perhaps you made a mistake when adding data. Check your details and try again.',
		verify_company_pending_text:
			'Your documents are being verified. As soon as the data is verified, Upiria will notify you about the possibility of adding experience.',
		verification_confirmed: 'Verification: Confirmed',
		verification_confirmed_text: 'YOur company has been verified',
		verification_pending: 'Verification: Pending',
		verification_rejected: 'Verification: Rejected',
		amount: 'Amount',
		commission: 'Commission',
		fee: 'Fee',
		balance: 'Balance',
		no_bookings_for_slot_yet: 'No bookings for this experience slot yet',

		//profile
		my_settings: 'My settings',
		upiria_support: 'Upiria support',
		terms_and_policies: 'Terms and Policies',
		log_out: 'Log out',

		//settings
		company_profile: 'Company profile',
		legal_info: 'Legal Info',
		language: 'Language',
		notifications: 'Notifications',
		billing: 'Billing',
		password: 'Password',
		retype_password: 'Retype password',
		account_info: 'Account Info',
		profile_photo: 'Profile photo',
		company_logo: 'Company logo',
		change_photo: 'Change photo',
		upload_photo: 'Upload photo',
		photo_upload_successfully: 'Photo upload successfully',
		remove_photo: 'Remove Photo',
		user_not_verified: 'User is not verified!',
		current_password: '',
		new_password: 'New Password',
		confirm_password: 'Confirm Password',
		delete_account: 'Delete account',
		contact_support: 'Contact support',
		contact_support_text:
			'To deactivate your subscription plan and/or delete your Upiria account, please contact support at ' +
			ADMIN_EMAIL +
			'.',
		confirm: 'Confirm',
		save: 'Save',
		done:'Done',
		email: 'Email',
		passwords_must_match: 'Passwords must match',
		new_password_different:
			'Your new password must be different from previous used passwords.',
		remember_new_password:
			'Please, remember that your new password must be different from previously used passwords.',
		fill_in_both_passwords: 'Please fill in all password fields',
		company_info: 'Company Info',
		language_settings: 'Language settings',
		english: 'English',
		greek: 'Ελληνικά',
		email_notifications_title: 'Email notifications',
		email_notifications_text: 'Send me email notifications for:',
		booking_updates_title: 'Booking updates',
		booking_updates_text:
			'New bookings and booking request, updates on booking or payment status etc.',
		agenda_updates_title: 'Agenda updates',
		agenda_updates_text:
			'New experience was assigned to you, notifications on experience start time',
		product_updates_title: 'Product updates',
		product_updates_text: 'New experience or add-on was created or updated',
		partnership_updates_title: 'Partnership updates',
		partnership_updates_text:
			'Updates on sent and received partnership requests',
		discount_updates_title: 'Discount and voucher updates',
		discount_updates_text: 'Updates on discounts and vouchers changes',
		chat_updates_title: 'Chat updates',
		chat_updates_text: 'New messages received, unanswered messages',
		weekly_reports_title: 'Weekly reports',
		weekly_reports_text:
			'Updates on generated/upcoming revenue, bookings, people attend, refunds etc.',
		browser_notifications_title: 'Browser notifications',
		browser_notifications_text: 'Send me browser notifications for:',
		profile_update_success: 'Profile updated successfully',
		company_profile_success: 'Company profile updated successfully',
		photo_delete_success: 'Photo deleted successfully',
		success_uploaded: 'Successfully uploaded your files',

		//experiences
		create_new_experience: 'Create new experience',
		you_need_to_be_verified_account: 'You need to be verified account',
		you_need_to_be_verified_account_to_add_more:
			'You need to be verified account to add more experiences',
		share_experiences: 'Share experiences',
		create_experience: 'Create experience',
		edit_experience: 'Edit experience',
		save_and_exit: 'Save and exit',
		exit: 'Exit',
		none: 'None',
		exit_newExperience: 'Exit',
		exitExperience_dialog:
			'All unsaved changes to new experience will be lost, exit new experience?',
		what_types_of_activities:
			'What types of activities is your experience focused on?',
		select_up_to_3_tags:
			'Select up to 3 tags related to your experience, they might be from different categories',
		maximum_tags_reached: 'The maximum number of tags has been selected',
		more_items_to_create: 'more items to create experience',
		select_tags: 'Select tags',
		selected_tags: 'Selected tags',
		max_selected_tags_message: 'The maximum number of tags have been selected',
		used_resource: 'Used resource',
		used_resource_message:
			'If your experience uses one of your resources, select it from the following list',
		name_and_describe: 'Name and describe your experience',
		give_experience_name: 'Give your experience a name',
		make_it_descriptive:
			'Make it descriptive and unique so guests will understand what you’re offering',
		show_examples: 'Show examples',
		choose_color_code:
			'Choose color code for identifying experience in the calendar',
		what_will_you_and_guests_do: 'What will you and your guests do?',
		provide_specific_plans:
			'Provide specific plans from start to finish, not multiple ideas or options',
		tell_participants_a_story:
			'Tell participants a story of what they will do during your experience',
		environmentally_friendly: 'Environmentally friendly',
		current_experience_is_an_environmentally_friendly_travel_experience:
			'Current experience is an environmentally friendly travel experience',
		how_long_experience: 'How long is your experience?',
		what_languages_will_be_spoken: 'What languages will be spoken during the experience?',
		add_another_language: 'Add another language',

		my_experience_lasts: 'My experience lasts',
		provide_experience_duration: 'Provide experience duration',
		directions: 'Directions',
		please_provide_directions:
			'You can provide additional directions to make it easier for visitors to find the exact location',
		select_duration: 'Select duration',
		select_time: 'Select time',
		back: 'Back',
		minutes: 'minutes',
		minute: 'minute',
		days: 'days',
		day: 'day',
		hours: 'hours',
		hour: 'hour',
		days_dialog: 'Days',
		hours_dialog: 'Hours',
		minutes_dialog: 'Mins',
		before_start_time: ' before start time',
		experience_working_hours: 'Experience working hours (optional)',
		select_working_days: 'Select working days and define working hours',
		apply: 'Apply',
		where_should_meet: 'Where should guests meet you?',
		add_experience_point: 'Add experience meeting point',
		what_will_you_provide: 'What will you provide?',
		you_can_provide:
			'You can provide special equipment, bikes, boats or anything else special according to your experience',
		add_another_item: '+ Add another item',
		bring_anything_to_experience:
			'Will participants need to bring anything to the experience?',
		select_applies: 'Select what applies',
		yes: 'Yes',
		no: 'No',
		no_just_show_up: 'No, participants just need to show up',
		what_to_bring: 'What guests need to bring',
		list_will_be_emailed:
			'This list will be emailed to guests when they book your experience. Help them prepare by being specific and listing each item separately.',
		who_can_attend: 'Who can attend your experience?',
		keep_in_mind:
			'Keep in mind that someone booking your experience might book spots for other guests. If there are strict requirements around age, skill level, or certifications, include them here.',
		minimum_age: 'Minimum age',
		set_age_limits:
			'Set age limits for guests. Minors can only attend with their legal guardian.',
		child_friendly: 'Child friendly ',
		explain_child_friendly: 'Explain how this experience is child friendly',
		accessible: 'Accessible',
		explain_accessible: 'Explain how this experience is accessible',
		add_photos_and_videos: 'Add photos and video',
		drag_photos_and_videos: 'Drag photos and video here',
		add_at_least_5:
			'Add at least 5 photos and one video optional \n (3:4 aspect ratio)',
		add_a_maximum_of_5:
			'Add a maximum of 5 photos and one video optional \n (3:4 aspect ratio)',
		upload_from_device: 'Upload from your device',
		requirements: 'Requirements',
		a_variety_of_details:
			'A variety of details and angles, including people in action',
		candid_moments: 'Candid moments that accurately illustrate the experience',
		good_quality:
			'Good quality—no heavy filters, distortions, overlaid text, or watermarks',
		maximum_file_size: 'Maximum allowed file size is 5 Megabytes',
		learn_more_photo: 'Learn more about photo and video requirements',
		upload_at_least: 'Upload at least',
		upload_a_maximum_of: 'Upload a maximum of',
		more: 'More',
		how_many_can_you_host: 'How many participants can you host at once?',
		think_about_group_size:
			'Think about what group size is best for your experience. Is it more interactive with fewer people, or more fun with a larger group?',
		max_group_size: 'Max group size',
		maximum_group_size: 'Maximum group size',
		minimum_group_size: 'Minimum group size',
		participant: 'Participant',
		participants: 'Participants',
		min_group_size_is_grater_than_max: 'Min group size is grater than max',
		participants_pricing: 'Participants pricing',
		participant_groups: 'Participant Groups',
		pricing_are_per_participants: 'Pricing are per participants',
		group_rates_are_per_participants: 'Group rates are per participants',
		operation_information: 'Operation Information',
		customer_invoice: 'Customer invoice',
		send: 'Send',
		participant_pricing: 'Participant pricing',
		how_much_you_charge:
			'How much you charge is entirely up to you. Enter the price you want each participant to pay and discover what you can earn.',
		keep_in_mind_minimum_age:
			'Keep in mind that your participant’s minimum age is ',
		minimum_price_is: 'and the minimum price value is 5',
		ages: 'ages',
		ages_up_to: 'ages up to',
		and_older: 'and older',
		create_new_participant_group: 'Create new participant group',
		edit_participant_group: 'Edit participant group',
		add_group_rate: 'Add group rate',
		name_of_participant_new_group: 'What’s the name of the new group?',
		name_of_participant_group: 'What’s the name of the group?',
		make_sure_name_clear:
			'Make sure your name is clear and people will easily understand group membership',
		whats_the_age_new_group: 'What’s the age of the new group? (optional)',
		whats_the_age_group: 'What’s the age of the group? (optional)',
		from: 'from',
		to: 'to',
		create: 'Create',
		add_discounts:
			'Add discounts for different group sizes. Discounts apply to all paying guests, including children and infants.',
		group_size: 'Group size',
		discount: 'Discount',
		voucher: 'Voucher',
		each_participant_pays: 'Each participant pays',
		discount_cannot_be_zero_in_the_specific_input:
			"Discount can't be zero in the specific input",
		invalid_group_range: "Invalid group range",
		we_recommend_cutoff:
			'We recommend setting your cutoff time close to your start time so more participants can book. Be sure to give yourself enough time to prepare for your participants.',
		cutoff_time: 'Cutoff time',
		if_no_one_books:
			"If no one books by this cutoff time, the instance won't be available to participants.",
		hour_before_start_time: 'Hour before start time',
		hours_before_start_time: 'Hours before start time',
		requests_for_availability: 'Requests for availability',
		allow_people: 'Allow people to request dates and times not listed',
		private_description: 'Private tours allow only one reservation',
		instant_confirmation: 'Instant confirmation',
		all_booking_requests_confirmed: 'All booking requests will be confirmed',
		reselling_your_experience:
			"If you're interested in reselling your experience, you can easily do so. By reselling your experience, you'll have the opportunity to reach a wider audience and increase your earnings.",
		disable_reselling: 'Disable reselling',
		enable_reselling: 'Enable reselling',
		commissions: 'Commissions',
		resell_an_experience:
			"When you resell an experience, you have the option to set a commission for the reseller. This means that if someone else sells your experience, you'll pay them a percentage of the sale price as a commission. Keep in mind that offering a commission may make your experience more attractive to resellers.",
		resellers_commission: 'Resellers commission',
		upiria_commission: 'Upiria’s commission',
		set_upiria_commission: 'Set Upiria’s commission',
		choose_policy: 'Choose a cancellation policy',
		changes_to_your_cancellation:
			'Changes to your cancellation policy are only applied to future bookings and don’t affect any pending or confirmed instances.',
		upiria_cancellation_policy: 'Upiria cancellation policy',
		guests_can_cancel:
			'Guests can cancel until 7 days before the Experience start time for a full refund, or within 24 hours of booking as long as the booking is made more than 48 hours before the start time.',
		read_more_at: 'Read more at ',
		terms_and_conditions: 'Terms and conditions',
		custom_cancellation_policy: 'Custom cancellation policy',
		cancellation_time: 'Cancellation time',
		i_would_like_you_to_remember:
			'I would like you to remember the custom cancellation policy I’ve created',
		today: 'Today',
		tomorrow: 'Tomorrow',
		edit: 'Edit',
		view: 'View',
		preview: 'Preview',
		pause: 'Pause',
		resume: 'Resume',
		publish: 'Publish',
		go_live: 'Go live',
		items_per_page: 'items per page',
		delete: 'Delete',
		increase_capacity: 'Increase Capacity',
		pricing: 'Pricing',
		details: 'Details',
		provider_list: 'Provider list',
		per_participant: 'Per participant',
		capacity: 'Capacity',
		capacity_of_one_item: 'Capacity of one item',
		location: 'Location',
		duration: 'Duration',
		rating: 'Rating',
		break_time: 'Break time',
		organization_details: 'Organization details',
		size: 'Size',
		schedule_experience: 'Schedule Experience',
		edit_time_slot: 'Edit time slot',
		edit_recurring_time_slot: 'Edit recurring time slot',
		simple_experience: 'Simple Experience',
		schedule_simple_experience:
			'Schedule simple experience not based on resource',
		resource_based_experience: 'Resource based experience',
		resource_based_slot: 'Resource based slot',
		schedule_resource_based_experience:
			'Schedule experiences in bulk based on resource',
		schedule_experiences_resource: 'Schedule Experiences\n(based on resource)',
		linked_experiences: 'Linked experiences',
		this_experience: 'This experience',
		this_and_following: 'This and all following experiences occurring every ',
		this_and_all_recurring: 'This and all following experiences occurring ',
		this_and_following_experiences: 'This and all following experiences',
		at: 'at',
		week: 'week',
		weeks: 'weeks',
		weekly: 'weekly',
		month: 'month',
		months: 'months',
		monthly: 'monthly',
		st: 'st',
		nd: 'nd',
		rd: 'rd',
		th: 'th',
		last: 'last',
		date: 'Date',
		new_date: 'New Date',
		requested_date: 'Requested Date',
		current_date: 'Current Date',
		start_date_time: 'Start date and time',
		start_date: 'Start date',
		end_date: 'End date',
		close: 'Close',
		time_set_by_repeat: 'Time is defined by repeat scheduling',
		duration_shorter_than:
			'The duration you have selected is shorter than the duration of the slot which is',
		slot_will_repeat: 'the slot will be repeated at least ',
		edit_repeat_slot_notification:
			"Can't update time and duration in slots that repeat during a day",
		times_within_the_day: 'times within the day',
		end_date_time: 'End date and time',
		time: 'Time',
		new_time: 'New Time',
		start_time: 'Start time',
		end_time: 'End time',
		repeat: 'Repeat',
		recurring: 'Recurring on days',
		notification: 'Notification',
		private: 'Private',
		private_request: 'Private request',
		public: 'Public',
		standard_rate: 'Standard rate',
		adults: 'Adults',
		share_on_socials: 'Share on socials',
		share_your_experience_listing: 'Share your experience listing',
		get_widget: 'Get widget',
		share: 'Share',
		eco: 'Eco',
		copy: 'Copy',
		copy_link: 'Copy link',
		open: 'Open',
		copy_qr_code: 'Get QR code',
		download_code: 'Download QR',
		delete_experience: 'Delete experience',
		remove_experience: 'Remove experience',
		delete_resource: 'Delete resource',
		delete_experience_message:
			'Are you sure you want to delete this experience?',
		// remove_experience_message:
		// 	'Are you sure you want to remove this experience from resale collection?',
		remove_experience_message:
			'Are you sure you want to remove this experience from resale experiences?',
		experience_deleted: 'The experience was deleted successfully',
		experience_removed: 'The experience was removed successfully',
		problem_deleting_experience: 'There was a problem deleting your experience',
		problem_removing_experience: 'There was a problem removing the experience',
		problem_adding_experience: 'There was a problem adding the experience',
		no_experiences_yet: 'No experiences yet',
		start_adding_experience: 'Start adding experiences!',
		add_experiences: 'Add experiences',
		no_images_yet:
			'To view your live experience you need to upload at least one photo.',
		create_your_first_experience:
			'Create your first experience, so you can schedule it later.',
		all_experiences_will_be_listed: 'All experiences will be listed here',
		you_will_see_all_of_them_here: 'You will see all of them here.',
		refund: 'refund',
		title: 'Title',
		and_more: 'and more',
		before_activity: 'before the activity',
		add_range: 'Add range',
		select_experiences: 'Select experiences',
		select_provider: 'Select Provider',

		//group
		create_new_group: 'Create new collection',
		share_groups: 'Share collection',
		start_creating_groups: 'Start creating collections!',
		empty_group_message: 'This collection is empty, Add experiences to be able to share collection',
		go_to_marketplace: 'Go to marketplace',
		go_to_experience: 'Go to Experiences',
		go_to_resale: 'Go to Resale Experiences',
		group_name: 'Collection name',
		delete_group: 'Delete collection',
		delete_group_message:
			'Are you sure you want to delete this collection?',
		group_deleted: 'The collection was deleted successfully',
		problem_deleting_group: 'There was a problem deleting your collection',
		edit_group: 'Edit collection',
		add_to_group: 'Add to collection',
		add_to_experience: 'Add to Experience',
		add_to_collection: 'Add to Collection',
		experience_added : 'Experience has been added to the collection',
		experience_removed: 'Experience has been removed from the collection',

		//Resale
		generate_widget: 'Generate a widget',
		invite: 'Invite',
		invite_company: 'Invite company',
		add_to_sale_experiences: 'Add to Resale Experiences',
		added_to_resale_collection: 'Experience was added to resale collection',
		added_to_resale_experiences: 'Experience was added to resale experiences',
		filter: 'Filter',
		invitation_sent: 'Invitation sent successfully',

		//calendar
		override: 'Override',
		bulk: 'Bulk',
		bulk_short: 'Bulk slot creation',
		normal_short: 'Normal slot creation',
		bulk_message:
			'Slots will be automatically created in bulk for the set start and end times according to the provided duration',
		normal_message:
			'Select a start time and the end time will be based on the duration of the experience',
		select_repeat_schedule: 'Select repeat schedule',
		repeat_scheduling: 'Repeat scheduling',
		repeat_during_the_day: 'Repeat during the day',
		repeat_in_the_range_of_working_hours:
			'Repeat in the range of working hours',
		we_will_automatically:
			'We will automatically create as many time slots as possible for the given time frame.',
		see_working_hours: 'See working hours',
		company_working_hours: 'Company working hours',
		you_can_change_your_working_hours_in_settings:
			'You can change your working hours in settings',
		set_custom:
			'Set custom start and end time and we will automatically create as many time slots as possible.',
		time_range_is_out_of_your_working_hours:
			'Time range is out of your working hours',
		sunday_is_out_of_your_working_hours: 'Sunday is out of your working hours',
		does_not_repeat_during_the_day:
			'The time slot does not repeat during the day',
		does_not_repeat: 'Does not repeat',
		no_repeat: 'No repeat',
		repeat_day_label: 'Repeat',
		repeat_from: 'from',
		repeat_to: 'to',
		no_notes: 'No notes',
		daily: 'Daily',
		weekly_monday: 'Weekly on Monday',
		weekly_on: 'Weekly on ',
		until: 'Until',
		every: 'Every',
		every_weekday: 'Every weekday (Mon - Fri)',
		custom: 'Custom',
		edit_custom_schedule: 'Edit custom schedule',
		custom_repeat: 'Custom Repeat',
		day_initials: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
		day_initials_pos: [1, 2, 3, 4, 5, 6, 7],
		days_short_map: {
			1: 'Sun',
			2: 'Mon',
			3: 'Tue',
			4: 'Wed',
			5: 'Thu',
			6: 'Fri',
			7: 'Sat'
		},
		days_short_map2: {
			1: 'Mon',
			2: 'Tue',
			3: 'Wed',
			4: 'Thu',
			5: 'Fri',
			6: 'Sat',
			7: 'Sun'
		},
		edit_slot_types: {
			all: 'all',
			recurring_day: 'recurring_day',
			recurring_day_time: 'recurring_day_time',
			single: 'single'
		},
		days_array: [
			'Sunday',
			'Monday',
			'Tuesday',
			'Wednesday',
			'Thursday',
			'Friday',
			'Saturday'
		],
		repeat_every_week_on: 'Repeat every week on',
		ends: 'Ends',
		time_slots_can_be_booked:
			'The recurring end time is calculated from the start date, slots can be book for 1 year in advance.',
		never: 'Never',
		on_six_months: 'On 6 months',

		on: 'On',
		schedule: 'Schedule',
		schedule_details: 'Schedule details',
		ten_minutes_before_start: '10 minutes before start',
		half_hour_before_start: '30 minutes before start',
		one_hour_before_start: '1 hour before start',
		no_experiences: 'To schedule an experience you need to create at least one',
		dismiss: 'Dismiss',
		dismiss_dialog: 'You have made changes, Dismiss dialog?',
		delete_slot: 'Delete time slot',
		delete_slot_message: 'Are you sure you want to delete this time slot?',
		slot_deleted: 'The time slot was deleted successfully',
		problem_deleting_slot: 'There was a problem deleting the times slot',
		no_participants_yet: 'No participants yet',
		no_add_ons_yet: 'No add-ons yet',
		all_booked_add_ons_will_be_listed_here:
			'All booked add-ons will be listed here',

		//Resources
		create_resource: 'Create Resource',
		edit_resource: 'Edit Resource',
		no_resources_yet: 'No resources yet',
		create_your_first_resource:
			'Create your first resource, so you can schedule resource based experience.',
		all_resources_will_be_listed: 'All resources will be listed here.',
		name_your_resource: 'Name your resource',
		link_experiences: 'Link experiences this resource is used in',
		selected_experiences: 'Selected experiences',
		think_about_experiences:
			'Think about the experiences that this resource is used in, so you can manage your resource based experiences later.',
		resource_title: 'Resource title',
		resource_description:
			'Add a description so it is easier for you to discern it for the others',
		quantity: 'Quantity',
		add_photo: 'Add photo',
		drag_photo_here: 'Drag photo here',
		add_one_photo: 'Add a single photo, preferably 256 X 256 pixels',
		resource_photo: 'Upload a photo of your resource',
		used_in_experiences: 'Used in experiences',
		more_experiences: 'more experiences',
		problem_deleting_resource: 'There was a problem deleting your resource',
		delete_resource_message: 'Are you sure you want to delete this resource?',
		resource_deleted: 'The resource was deleted successfully',

		//addons
		create_addon: 'Create Addon',
		edit_addon: 'Edit Addon',
		name_addon: 'Name your addon',
		no_addons_yet: 'No addons yet',
		all_addons_will_be_listed: 'All addons will be listed here',
		add_on_title: 'Add-on title',
		describe_addon:
			'Describe your add-on in details, so your participants know what to expect',
		group_addon: 'Group add-on',
		current_multiple_participants:
			'Current item is intended for multiple participants',
		add_on_closeup_photo: 'Upload a close up look photo of your add-on',
		supplier: 'Supplier',
		use_external_supplier:
			'Use external supplier company or person name if this add-on is not yours',
		examples: 'Examples',
		got_it: 'Got it',
		per_item: 'per item',
		total: 'total',
		total_price: 'Total price',
		total_paid: 'Total paid',
		remaining: 'Remaining',
		general: 'General',
		unlimited: 'Unlimited',
		how_many_can_you_provide: 'How many add-ons you can provide at once?',
		think_about_addon:
			'Think about your experience group size, so you can make sure all of your possible participants will be able to get the add-on if needed.',
		max_addons: 'Max number of add-ons',
		type_max_number: 'Type max number',
		unlimited_addons:
			'Unlimited. I’m able to provide as many add-ons as participants will book.',
		addon_pricing: 'Add-on pricing',
		price_per_item: 'Price per item',

		example_addon_1_title: 'Underwater flashlight',
		example_addon_1_description:
			'Get an even better scuba diving experience with a long distance underwater flashlight',
		example_addon_2_title: 'Fresh veggies mix',
		example_addon_2_description:
			'Give your kids the pleasure of feeding our animals, by purchasing a baggie of fresh veggies for their four legged friends',
		example_addon_3_title: 'Cheese tasting',
		example_addon_3_description:
			'Accompany our great wine stating tour with an exquisite collection of assorted cheeses',
		example_addon_4_title: 'Go pro with helmet mount',
		example_addon_4_description:
			'Record your RV experience like a pro with our go-Pro add-on. We will send you your captured material at the end of the experience.',

		//steps
		category: 'Category',
		name_and_details: 'Name and details',
		meeting_point: 'Meeting point',
		provided_items: 'Provided items',
		items_to_bring: 'Items to bring',
		participant_requirements: 'Participant requirements',
		photos_and_videos: 'Photos and videos',
		capacity_and_pricing: 'Capacity and pricing',
		quantity_and_pricing: 'Quantity and pricing',
		group_rates: 'Group rates',
		booking_settings: 'Booking settings',
		reselling: 'Reselling',
		unverified_provider_warning:
			'We are sorry to say that you are still not verified. Please verify your account, otherwise you can not access to this step',
		cancellation_policy_label: 'Cancellation Policy',
		cancellation_policy: 'Cancellation policy',
		cancellation_policy_title: 'Cancellation policy title',
		translations: 'Translations',
		steps_left: 'Steps left to create an experience',
		steps_left_resource: 'Steps left to create a resource',
		steps_left_addon: 'Steps left to create an addon',
		steps_left_short: 'Steps left',
		next: 'Next',
		name: 'Name',
		select: 'Select',
		description: 'Description',

		//bookings
		create_new_booking: 'Create new booking',
		delete_booking: 'Delete booking',
		delete_booking_message: 'Are you sure you want to delete this booking?',
		booking_deleted: 'The booking was deleted successfully',
		booking_notes: 'Booking notes',
		request_availability: 'Request availability',
		customer_note: 'Customer note',
		provider_note: 'Provider note',
		problem_deleting_booking: 'There was a problem deleting your booking',
		participants_and_addons: 'Participants & Add-ons',
		invoice: 'Invoice',
		payments: 'Payments',
		payment_transaction: 'Payment transactions',
		spots: 'spots',
		spot: 'spot',
		seat: 'seat',
		seats: 'Seats',
		adult: 'Adult',
		child: 'Child',
		remove: 'Remove',
		add_new_participant: 'Add new participant',
		add_more_addons: 'Add more addons',
		items: 'items',
		item: 'item',
		add_participant: 'Add participant',
		add_addon: 'Add add-on',
		send_invoice: 'Send invoice',
		no_bookings_yet: 'No bookings yet',
		start_selling: 'Start selling!',
		your_bookings_will_be_listed_here: 'Your bookings will be listed here.',
		no_upcoming_bookings: 'No upcoming bookings',
		all_bookings_will_be_listed: 'All bookings will be listed here',
		all_participants_will_be_listed: 'All participants will be listed here',
		pricing_will_be_shown_here: 'Pricing will be shown here',
		download_invoice: 'Download invoice',
		message_customer: 'Message customer',
		call_customer: 'Call customer',
		cancel_booking: 'Cancel booking',
		reschedule_booking: 'Reschedule booking',
		reschedule_bookings: 'Reschedule bookings',
		why_cancel_booking: 'Why do you want to cancel booking?',
		why_reschedule_booking: 'Why do you want to reschedule booking?',
		confirm_cancellation: 'Confirm cancellation',
		see_booking: 'See booking',

		//placeholders
		enter_email: 'Enter email address',
		enter_your_email: 'Enter your email address',
		enter_password: 'Enter password',
		create_password: 'Create strong password',
		password_successfully_changed: 'Your password was successfully changed',
		enter_first_name: 'Enter first name',
		enter_last_name: 'Enter last name',
		enter_brand_name: 'Enter brand name',
		enter_legal_name: 'Enter legal company name',
		enter_contact_email: 'Enter contact email',
		enter_company_name: 'Enter company name',
		short_description: 'Short description',
		short_profile: 'Short Profile',
		provide_short_description_provider:
			'Describe your company and the experiences you provide. This will be visible to everyone',
		enter_website: 'Enter website URL',
		enter_iban: 'Enter IBAN',
		enter_beneficiary_name: 'Enter beneficiary name',
		enter_address: 'Enter address',
		enter_city: 'Enter city',
		enter_state: 'Enter state/province/region',
		select_country: 'Select country',
		enter_country: 'Enter country',
		header_search: 'Search for bookings, customers, partners and more...',
		no_transactions: 'No transactions yet',
		transactions_will_be_shown: 'All transactions will be shown here',
		search_by_booking_or_customer: 'Search by booking # or customer',
		search_by_location: 'Search by location',
		filters: 'Filters',

		//validation messages
		email_required: 'Email required',
		first_name_required: 'First Name required',
		last_name_required: 'Last Name required',
		phone_number_required: 'Phone number required',
		company_name_required: 'Company Name required',
		legal_name_required: 'Legal name required',
		short_description_required: 'Short description required',
		address_required: 'Address required',
		city_required: 'City required',
		state_required: 'State / Province / Region required',
		country_required: 'Country required',
		company_registration_number_required:
			'Company registration number required',
		vat_number_required: 'VAT number required',
		iban_is_required: 'IBAN is required',
		beneficiary_name_required: 'Beneficiary Name is required',

		//error messages
		files_size_exceeds: 'File size exceeds limit!',
		unsupported: 'Unsupported file type! Please select a pdf or a Jpeg file',

		//tables
		create_date: 'Create date',
		date_created: 'Date created',
		notes: 'Notes',
		access: 'Access',
		status: 'Status',
		payment_status: 'Payment Status',
		type: 'Type',
		reference: 'Reference',
		card_number: 'Card number',
		customer: 'Customer',
		provider: 'Provider',
		providers: 'Providers',
		channel: 'Channel',
		payment: 'Payment',
		price: 'Price',
		paid: 'Paid',
		unpaid: 'Unpaid',
		no_charge: 'No Charge',
		failed: 'Failed',
		confirmed: 'Confirmed',
		cancelled: 'Cancelled',
		cancelled_provider: 'Cancelled by provider',
		no_refund: 'No refund',
		refunded: 'Refunded',
		partial_refunded: 'Partial Refunded',
		declined: 'Declined',
		decline: 'Decline',
		reschedule: 'Reschedule',
		booked_open: 'Booked and Open',
		pending_confirm: 'Pending Confirm',
		pending_payment: 'Pending Payment',
		confirm_reschedule: 'Confirm Reschedule',
		pending_reschedule: 'Pending Reschedule',
		closed: 'Closed',
		booking_slot: 'Booking Slot',
		slot_status: 'Slot Status',
		upcoming_earnings: 'Upcoming earnings',
		ready_to_withdraw: 'Ready to withdraw',
		confirmation_to_withdraw: 'Are you sure you want to withdraw',
		withdraw_success: 'The funds are withdrawn successfully',
		problem_withdraw: 'There was a problem in withdrawing the funds',
		errors: {
			loc1: 'File size exceeds limit! Maximum file size is 10MB',
			loc2: 'Unsupported file type! Please select a pdf or a Jpeg file',
			8: 'We cannot process the request, please try again or contact system administrator',
			1101: 'The email and password you entered did not match our records. Please try again or reset your password.',
			1205: "The user hasn't been activated yet",
			1306: "The user hasn't been activated yet",
			1200: "A user with this email has already registered with us. If you have forgotten your credentials, press 'Forgot Password' at the login screen and follow the easy instructions to reset your password.",
			1204: 'The password must be longer than 7 characters!',
			554: 'An error has occurred, our administrators will be notified immediately',
			1301: "We don't have any users registered with this email address",
			81023:
				'Please provide a phone number that is at least 10 characters long',
			81009: 'The provided IBAN is invalid',
			1300: 'This reset link has already been used to reset a password. Please reset the password again to get a new link.',
			'1300_2':
				'This activation link has already been used to activate an account. Please login using the credentials you provided during registration.',
			1207: 'The password you provided is incorrect',
			1305: 'Sorry, you can only request a password reset once per hour. Please try again later or contact customer support if you need further assistance.',
			911: 'Access denied',
			2102: 'Record not found',
			1011: "Start time and date can't be in the past",
			23000: 'You cannot delete an experience that has future bookings pending',
			2706: 'Invitation email failed, please try again.',
			2707: 'Email is not valid, please try another email.',
			default:
				'We were unable to process your request. For more information, please contact support.'
		},
		view_booking : 'View Booking',
		group:'Collection',

		//messages
		reset_sent:
			'We have sent an email to the address you provided. Please follow the instructions in the email to reset your password.'
	}
};

export const dictionaryMap = {
	143: dictionaries.enDictionary,
	234: dictionaries.elDictionary
};

export const dictionaryCodeMap = {
	143: 'en',
	234: 'el'
};
