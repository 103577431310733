import React from 'react';
import PropTypes from 'prop-types';

const MoreItemsButton = ({ resource, number, title, onMoreClicked }) => {
	return (
		<div
			style={{ cursor: 'pointer' }}
			onClick={() => onMoreClicked(resource)}
			className="more-experiences-container"
		>
			<div className="more-experiences-number-label">{'+' + number}</div>
			<h4 style={{ fontSize: '12px', fontWeight: 'normal' }}>{title}</h4>
		</div>
	);
};

MoreItemsButton.propTypes = {
	resource: PropTypes.string,
	number: PropTypes.number,
	title: PropTypes.string,
	onMoreClicked: PropTypes.func
};

export default MoreItemsButton;
