import React, { useEffect, useState } from 'react';
import ExperienceDetailsPopup from './ExperienceDetailsPopup';
import PropTypes from 'prop-types';

const ExperienceDetailsDialog = ({
	experience,
	setShowExperienceDetailsDialog,
	onEditSlotClicked,
	showSlotDetails,
	onDeleteSlotClicked,
	refetch
}) => {
	const [opacity, setOpacity] = useState(0);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShowExperienceDetailsDialog(false);
		}, 800);
	};

	return (
		<div
			onClick={onCancelClicked}
			className="dialog-wrapper"
			style={{ background: 'transparent', opacity: opacity }}
			data-testid="experience-details-dialog"
		>
			<ExperienceDetailsPopup
				showSlotDetails={showSlotDetails}
				onDeleteSlotClicked={onDeleteSlotClicked}
				onEditSlotClicked={onEditSlotClicked}
				showActions={true}
				experience={experience}
				onCancelClicked={onCancelClicked}
				refetch={refetch}
				setShowExperienceDetailsDialog={setShowExperienceDetailsDialog}
			/>
		</div>
	);
};

ExperienceDetailsDialog.propTypes = {
	experience: PropTypes.object,
	setShowExperienceDetailsDialog: PropTypes.func,
	onEditSlotClicked: PropTypes.func,
	showSlotDetails: PropTypes.func,
	onDeleteSlotClicked: PropTypes.func,
	refetch: PropTypes.func
};

export default ExperienceDetailsDialog;
