import { CURRENCY } from '../../config';

export const TextFormatHelper = (function () {
	function formatPrice(price) {
		return price.toLocaleString() + CURRENCY;
	}

	function getInitials(firstName, lastName) {
		if (firstName && firstName !== '' && lastName && lastName !== '') {
			return (
				firstName.substring(0, 1) + lastName.substring(0, 1)
			).toUpperCase();
		} else {
			return '';
		}
	}

	function truncateString(filename, n) {
		return filename.length > n
			? `${filename.substring(0, n / 2)} ...${filename.substring(
					filename.length - n / 2
			  )}`
			: filename;
	}

	function formatFileSize(bytes) {
		return (bytes / 1000000).toFixed(0) + ' MB';
	}

	function formatFileTypes(types) {
		return types
			.map((type) => {
				switch (type) {
					case 'image/jpeg':
						return 'JPEG';
					case 'image/png':
						return 'PNG';
					case 'application/pdf':
						return 'PDF';
					default:
						return type;
				}
			})
			.join(', ');
	}

	/**
	 *
	 * @param text
	 * @returns {string}
	 */
	function toUpperCaseFirstLetter(text) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}

	function toUsefulTextFromField(text) {
		return text.split('_')
			.map(word => toUpperCaseFirstLetter(word))
			.join(' ');
	}

	return {
		formatPrice: function (price) {
			return formatPrice(price);
		},
		getInitials: function (firstName, lastName) {
			return getInitials(firstName, lastName);
		},
		truncateString: function (filename, n) {
			return truncateString(filename, n);
		},
		formatFileSize: function (bytes) {
			return formatFileSize(bytes);
		},
		formatFileTypes: function (types) {
			return formatFileTypes(types);
		},
		toUsefulTextFromField: function (types) {
			return toUsefulTextFromField(types);
		},
		toUpperCaseFirstLetter: function (text) {
			return toUpperCaseFirstLetter(text);
		}
	};
})();
