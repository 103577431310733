import { useDictionary } from '../../../utilities/hooks/UseDictionary';
import DialogLoader from './DialogLoader';
import ResultModal from './ResultModal';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ConfirmationModal = ({
	setShowModal,
	onActionFinished,
	asyncTask,
	title,
	message,
	action,
	successMessage,
	errorMessage,
	onCancel // Add the onCancel prop here
}) => {
	const dictionary = useDictionary();
	const [status, setStatus] = useState(null);
	const [resultMessage, setResultMessage] = useState(null);
	const [opacity, setOpacity] = useState(0);
	const [showLoader, setLoaderVisibility] = useState(false);

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onConfirmClicked = () => {
		if (onActionFinished) {
			setLoaderVisibility(true);
		}
		asyncTask(onActionComplete, onActionFail);
	};

	const onActionComplete = () => {
		setLoaderVisibility(false);
		setStatus('success');
		setResultMessage(successMessage);
		messageTimeOut();
	};

	const onActionFail = () => {
		setLoaderVisibility(false);
		setStatus('error');
		setResultMessage(errorMessage);
		messageTimeOut();
	};

	const messageTimeOut = () => {
		setTimeout(() => {
			onCancelClicked();
			onActionFinished();
		}, 2000);
	};

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShowModal(false);
			if (onCancel) onCancel();
		}, 800);
	};

	return status !== null ? (
		<ResultModal opacity={opacity} message={resultMessage} type={status} />
	) : (
		<div className="dialog-wrapper" style={{ opacity: opacity, zIndex: '11' }}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div style={{ textAlign: 'center' }}>
					<h3 style={{ marginBottom: '8px' }}>{title}</h3>
					<div className="text">{message}</div>
				</div>

				<div
					className="dialog-buttons-container"
					style={{
						marginBottom: '48px',
						marginTop: '32px',
						maxWidth: '270px',
						alignSelf: 'center',
						width: '100%'
					}}
				>
					<div onClick={onCancelClicked} className="tertiary-button">
						{dictionary.cancel}
					</div>
					<div onClick={() => onConfirmClicked()} className="button">
						{action}
					</div>
				</div>
				<DialogLoader showLoader={showLoader} />
			</div>
		</div>
	);
};

ConfirmationModal.propTypes = {
	setShowModal: PropTypes.func.isRequired,
	onActionFinished: PropTypes.func,
	asyncTask: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired,
	message: PropTypes.string,
	action: PropTypes.string.isRequired,
	successMessage: PropTypes.string,
	errorMessage: PropTypes.string,
	onCancel: PropTypes.func // Add the onCancel propType here
};

export default ConfirmationModal;
