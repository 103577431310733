import React, { useCallback, useEffect, useRef } from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import ImageHelper from '../../../../../../utilities/helpers/ImageHelper';
import { useDropzone } from 'react-dropzone';
import PropTypes from 'prop-types';

const ResourceNameAndDetails = ({
	name,
	setName,
	description,
	setDescription,
	quantity,
	setQuantity,
	// capacity,
	// setCapacity,
	fileToUpload,
	setFileToUpload,
	// showLoaded,
	// setShowLoader,
	image,
	// setImage
}) => {
	const dictionary = useDictionary();
	const inputRef = useRef();

	useEffect(() => {
		return URL.revokeObjectURL(fileToUpload?.preview);
	}, []);
	const onDrop = useCallback((acceptedFiles) => {
		setFileToUpload(
			Object.assign(acceptedFiles[0], {
				preview: URL.createObjectURL(acceptedFiles[0])
			})
		);
	}, []);

	const onNameChanged = (e) => {
		setName(e.target.value);
	};

	const onDescriptionChanged = (e) => {
		setDescription(e.target.value);
	};

	const { getRootProps, getInputProps } = useDropzone({
		maxFiles: 1,
		accept: ['image/png', 'image/jpeg', 'image/jpg', 'video/mp4'],
		onDrop
	});

	const getPhoto = () => {
		if (fileToUpload !== null) {
			return fileToUpload.preview;
		} else if (image !== null) {
			return ImageHelper.getFullImageUrl(image);
		} else {
			return null;
		}
	};

	return (
		<div
			className="new-experience-form-wrapper "
			style={{ background: 'white', flexFlow: 'column' }}
		>
			<div
				className="new-experience-form-container thin-scrollbar"
				style={{ height: '0', flexGrow: '1' }}
			>
				<h2 style={{ fontSize: '24px' }}>{dictionary.name_your_resource}</h2>
				<div style={{ marginTop: '20px' }} className="text">
					{dictionary.name}
				</div>
				<div className="group-inputs">
					<input type="text" value={name} onChange={onNameChanged} />
				</div>
				<div style={{ marginTop: '20px' }} className="text">
					{dictionary.description}
				</div>
				<textarea
					value={description}
					onChange={onDescriptionChanged}
					placeholder={dictionary.resource_description}
				/>
				<div style={{ marginTop: '20px' }} className="text">
					{dictionary.quantity}
				</div>
				<div
					className="group-inputs"
					style={{ display: 'flex', justifyContent: 'space-between' }}
				>
					<div className="duration-input-container">
						<input
							onKeyDown={(e) =>
								['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()
							}
							type="number"
							min="0"
							step="1"
							value={quantity}
							onChange={(e) => setQuantity(e.target.value)}
						/>
					</div>
				</div>
				{/*<div style={{marginTop: '20px'}} className='text'>{dictionary.capacity_of_one_item}</div>
                <div className='group-inputs' style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className='duration-input-container'>
                        <input onKeyDown={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
                               type='number' min='0' step='1' value={capacity}
                               onChange={(e) => setCapacity(e.target.value)}/>
                    </div>
                </div>*/}

				<h2>{dictionary.resource_photo}</h2>

				<div
					className="upload-media-wrapper"
					style={{ marginBottom: '50px', width: 'unset' }}
					{...getRootProps()}
				>
					<input
						multiple={false}
						ref={inputRef}
						{...getInputProps()}
						className="gallery-upload-input"
					/>
					<div className="upload-media-container">
						<div className="upload-gallery-icon" />
						<h3 style={{ fontSize: '18px', marginTop: '25px' }}>
							{dictionary.drag_photo_here}
						</h3>
						<div style={{ marginTop: '10px' }} className="text">
							{dictionary.add_one_photo}
						</div>
						<div style={{ marginTop: '25px' }} className="secondary-button">
							{dictionary.upload_from_device}
						</div>
					</div>
					{getPhoto() ? (
						<img src={getPhoto()} className="uploaded-image-full" />
					) : null}
				</div>
			</div>
		</div>
	);
};

ResourceNameAndDetails.propTypes = {
	name: PropTypes.string,
	setName: PropTypes.func,
	description: PropTypes.string,
	setDescription: PropTypes.func,
	quantity: PropTypes.number,
	setQuantity: PropTypes.func,
	capacity: PropTypes.number,
	setCapacity: PropTypes.func,
	fileToUpload: PropTypes.object,
	setFileToUpload: PropTypes.func,
	showLoaded: PropTypes.bool,
	setShowLoader: PropTypes.func,
	image: PropTypes.string,
	setImage: PropTypes.func
};

export default ResourceNameAndDetails;
