/* eslint-disable no-unused-vars */
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialAuthState = {
	user: null,
	register: null,
	wizard: 1,
	errors: [],
	oauth: null,
	account: null,
	components: null
};

export const reducer = persistReducer(
	{
		storage,
		key: 'upiria',
		whitelist: [
			'user',
			'user_type',
			'account',
			'register',
			'wizard',
			'oauth',
			'components'
		]
	},
	(state = initialAuthState, action) => {
		switch (action.type) {
			case 'login': {
				const { user, oauth, account, components, user_type } =
					action.payload.auth;
				const register = user.hasAccount ? false : true;

				return {
					...state,
					account,
					user,
					register,
					oauth,
					components,
					user_type
				};
			}

			case 'update_user': {
				const { user, account, components } = action.payload.auth;

				return {
					...state,
					account,
					user,
					components
				};
			}

			case 'register': {
				// const initialUser = state.user;
				// const initialOauth = state.oauth;
				const { user, oauth } = action.payload.user;
				const newUser = user;
				const newOauth = oauth;
				return {
					...state,
					user: newUser,
					oauth: newOauth
				};
			}

			case 'create_account': {
				// const initialUser = initialAuthState.user;
				const user = action.payload.user;
				const register = state.register === null ? true : state.register;
				return {
					...state,
					user: {
						...state.user,
						...user
					},
					register
				};
			}

			case 'logout': {
				return initialAuthState;
			}

			case 'logout_expired': {
				return initialAuthState;
			}

			case 'account_type': {
				const register = action.payload;
				const wizard = register === 'corporate' ? 2 : 3;
				const final_wizard = register === true ? 1 : wizard;
				return {
					...state,
					register: register,
					wizard: final_wizard
				};
			}

			case 'wizard': {
				const wizard = action.payload;
				const register =
					wizard === 1 ? true : wizard === 4 ? null : state.register;
				return {
					...state,
					wizard: wizard,
					register: register
				};
			}

			case 'show_errors': {
				if (action.payload) {
					const errors = [
						{
							title: action.payload.error_code,
							message: action.payload.message
						}
					];
					return {
						...state,
						errors: errors
					};
				}
			}

			// eslint-disable-next-line no-fallthrough
			case 'hide_errors': {
				const errors = [];
				return {
					...state,
					errors: errors
				};
			}

			case 'verify_user': {
				const verified = true;
				return {
					...state,
					user: {
						...state.user,
						verified
					}
				};
			}

			default: {
				return state;
			}
		}
	}
);

export const actions = {
	admin_login: (data) => ({ type: 'admin_login', payload: { auth: data } }),
	login: (data) => ({ type: 'login', payload: { auth: data } }),
	update_user: (data) => ({ type: 'update_user', payload: { auth: data } }),
	register: (data) => ({ type: 'register', payload: { user: data } }),
	create_account: (data) => ({
		type: 'create_account',
		payload: { user: data }
	}),
	logout: () => ({ type: 'logout' }),
	logout_expired: () => ({ type: 'logout_expired' }),
	corporate_account: (data) => ({ type: 'account_type', payload: 'corporate' }),
	personal_account: (data) => ({ type: 'account_type', payload: 'personal' }),
	select_account_type: (data) => ({ type: 'account_type', payload: true }),
	step_one: (data) => ({ type: 'wizard', payload: 1 }),
	step_two: (data) => ({ type: 'wizard', payload: 2 }),
	step_three: (data) => ({ type: 'wizard', payload: 3 }),
	step_four: (data) => ({ type: 'wizard', payload: 4 }),
	showErrors: (data) => ({ type: 'show_errors', payload: data }),
	hideErrors: (data) => ({ type: 'hide_errors' }),
	verify_user: () => ({ type: 'verify_user' })
};

export const states = (initialAuthState) => {
	return {
		account_type: initialAuthState.auth.register,
		wizard: initialAuthState.auth.wizard,
		user: initialAuthState.auth.user,
		account: initialAuthState.auth.account,
		errors: initialAuthState.auth.errors,
		oauth: initialAuthState.auth.oauth
	};
};
