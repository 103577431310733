import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import ResourceDetailsRelatedExperienceItem from './ResourceDetailsRelatedExperienceItem';
import { getExperienceById } from '../../provider_experiences/networking';
import ExperienceDetailsPopup from '../experience_details_dialog/ExperienceDetailsPopup';
import DialogLoader from '../../../common/DialogLoader';
import PropTypes from 'prop-types';

const ResourceDetailsDialog = ({
	resource,
	setShowResourceDetailsDialog,
	// setShowLoader,
	onEditSlotClicked,
	showSlotDetails,
	onDeleteSlotClicked
}) => {
	const [opacity, setOpacity] = useState(0);
	const [experience, setExperience] = useState(null);
	const [showRelatedExperience, setRelatedExperienceVisibility] =
		useState(false);
	const [showSmallLoader, setSmallLoaderVisibility] = useState(false);
	const dictionary = useDictionary();

	const getExperienceDetailsAsync = async (experienceId) => {
		setSmallLoaderVisibility(true);
		try {
			const { data } = await getExperienceById(experienceId);
			setExperience(data);
			setRelatedExperienceVisibility(true);
			setSmallLoaderVisibility(false);
		} catch (error) {
			setSmallLoaderVisibility(false);
		}
	};

	const onEditClicked = () => {
		onCancelClicked();
		onEditSlotClicked(true);
	};

	useEffect(() => {
		setTimeout(() => {
			setOpacity(1);
		}, 50);
	}, []);

	const onCancelClicked = () => {
		setOpacity(0);
		setTimeout(() => {
			setShowResourceDetailsDialog(false);
		}, 800);
	};

	const getRange = () => {
		let start = moment(resource.start_time * 1000);
		let end = moment(resource.end_time * 1000);
		let isSameDay = start.dayOfYear() === end.dayOfYear();

		return (
			start.format('MMMM Do h:mm') +
			' - ' +
			(isSameDay ? end.format('h:mm') : end.format('MMMM Do h:mm'))
		);
	};

	const getRepeatSchedule = (repeat, until) => {
		if (!repeat || repeat?.length === 0) {
			return null;
		} else {
			let days = '';
			repeat?.map((day) => (days += dictionary.days_short_map[day] + ','));
			return (
				dictionary.weekly_on +
				' ' +
				days.substring(0, days.length - 1) +
				' ' +
				dictionary.until +
				' ' +
				moment(until * 1000).format('MMMM Do')
			);
		}
	};

	const getResourceExperiencesTitleNumberLabel = () => {
		if (resource.experiences && resource.experiences.length > 0) {
			return (
				resource?.title +
				' (' +
				resource?.experiences?.length +
				' ' +
				(resource?.experiences?.length === 1
					? dictionary.item
					: dictionary.items) +
				')'
			);
		}
	};

	const onExperienceClicked = (experienceId) => {
		getExperienceDetailsAsync(experienceId);
	};

	const getActions = () => {
		let start = new Date(resource.start_time * 1000);
		let today = new Date();

		let isFuture = start - today >= 0;

		return (
			<div
				className="dialog-title-container"
				style={{ justifyContent: 'flex-end', gap: '12px' }}
			>
				{isFuture && !resource.booked ? (
					<>
						<div
							className="dialog-close-button dialog-edit-button"
							onClick={onEditClicked}
						/>
						<div
							className="dialog-close-button dialog-delete-button"
							onClick={() => onDeleteSlotClicked(true)}
						/>
					</>
				) : null}
				<div
					className="dialog-close-button"
					style={{ position: 'unset' }}
					onClick={onCancelClicked}
				/>
			</div>
		);
	};

	return (
		<div
			onClick={onCancelClicked}
			className="dialog-wrapper"
			style={{ background: 'transparent', opacity: opacity }}
		>
			<div
				onClick={(e) => e.stopPropagation()}
				className="dialog-container dialog-container-narrow"
				style={{ maxHeight: '90%' }}
			>
				{getActions()}
				<div
					className="dialog-content-container-with-scroll thin-scrollbar"
					style={{ minHeight: '200px', height: 'unset', paddingTop: '12px' }}
				>
					<div className="experience-details-dialog-row">
						<div className="experience-details-dialog-row-content">
							<h3>{getRange()}</h3>
							{resource?.repeat_week_days?.length > 0 &&
							resource?.repeat_week_days?.[0] !== '' ? (
								<div
									className="calendar-slot-experience-list-item"
									style={{ lineHeight: '16px' }}
								>
									{getRepeatSchedule(
										resource?.setting?.repeat_week_days,
										resource?.setting?.repeat_until
									)}
								</div>
							) : null}

							<h4 style={{ marginTop: '16px' }}>
								{getResourceExperiencesTitleNumberLabel()}
							</h4>
							<div
								className="experience-info-rows-container"
								style={{ height: 'unset', gap: '4px' }}
							>
								{resource.experiences.map((experience) => (
									<ResourceDetailsRelatedExperienceItem
										onExperienceClicked={onExperienceClicked}
										key={experience.id}
										resource={resource}
										experience={experience}
									/>
								))}
								{!resource.experiences || resource.experiences.length === 0 ? (
									<div className={'text'}>{dictionary.no_experiences_yet}</div>
								) : null}
							</div>
						</div>
					</div>
				</div>
				<div className="dialog-title-divider" />
				<div
					className="dialog-buttons-container"
					style={{ padding: '12px', marginBottom: '0', gap: '16px' }}
				>
					{/*<button className='button plus-button'
                        style={{width: '0', flexGrow: '1'}}>{dictionary.create_new_booking}</button>*/}
					<div
						className="secondary-button"
						onClick={() => {
							showSlotDetails(true);
							onCancelClicked();
						}}
					>
						{dictionary.details}
					</div>
				</div>
				{<DialogLoader showLoader={showSmallLoader} />}
				{showRelatedExperience ? (
					<div className="related-experience-dialog-container">
						<ExperienceDetailsPopup
							showActions={false}
							onCancelClicked={() => setRelatedExperienceVisibility(false)}
							experience={experience}
						/>
					</div>
				) : null}
			</div>
		</div>
	);
};

ResourceDetailsDialog.propTypes = {
	resource: PropTypes.object,
	setShowResourceDetailsDialog: PropTypes.func,
	setShowLoader: PropTypes.func,
	onEditSlotClicked: PropTypes.func,
	showSlotDetails: PropTypes.bool,
	onDeleteSlotClicked: PropTypes.func
};

export default ResourceDetailsDialog;
