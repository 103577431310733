import React, {useEffect, useMemo, useState} from 'react';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { DatePickerField } from '../../custom/DatePickerField';
import { TimePickerField } from '../../custom/TimePickerField';
import moment from 'moment';
import { DateFormatHelper } from "../../../../../utilities/helpers/DateFormatHelper";
import CustomCheckbox from "../../../common/CustomCheckbox";
import { reasons } from "../../bookings/booking_details/CancelBookingDialog";
import {UseCurrencyDecorator} from "../../../../../utilities/hooks/UseCurrencyDecorator";
import MoneyInput from "../../../common/MoneyInput";

const SuggestDateDialog = ({
	setShowDialog,
   	onSuggest,
   	request,
   	refetch,
	isConfirmed = false,
}) => {
	const dictionary = useDictionary();
	const [suggestDate, setSuggestDate] = useState(false);
	const [suggestTime, setSuggestTime] = useState(false);
	const [isViewAll, setIsViewAll] = useState(false);
	const [isNewDate, setIsNewDate] = useState(true);
	const [step, setStep] = useState(isConfirmed ? 1 : 2);
	const [reason, setReason] = useState(null);
	const [otherReason, setOtherReason] = useState('');
	const [isCustomAmount, setIsCustomAmount] = useState(dictionary.original_amount);
	const [customAmount, setCustomAmount] = useState(0);
	const [changeReason, setChangeReason] = useState("");

	useEffect(() => {
		setStep(isConfirmed ? 1 : 2);
		setCustomAmount(request?.amount);
	}, [isConfirmed, request]);

	const currencyDecorator = UseCurrencyDecorator();
	const onDateChanged = (value) => {
		setSuggestDate(moment(value).format('YYYY-MM-DD'));
	};

	const onCancelClicked = () => {
		setShowDialog(false);
	};

	const onCancel = () => {
		if (step === 1) {
			onCancelClicked();
		} else {
			if (isConfirmed) {
				setStep(1);
			} else onCancelClicked();
		}
	};

	const onConfirmClicked = () => {
		const unixDate = new Date(`${suggestDate}T${suggestTime}`).getTime() / 1000;

		const customAmountFlag = !(isCustomAmount === dictionary.original_amount)

		const data = isNewDate 
			? { status: 'reschedule', reschedule: unixDate, is_new_date: isNewDate, is_request_availability: !isViewAll, is_custom_amount: customAmountFlag }
			: { status: 'reschedule', reschedule: unixDate, is_new_date: isNewDate, is_custom_amount: customAmountFlag };

		if(customAmountFlag){
			data.custom_amount= Number(customAmount);
			data.custom_amount_note= changeReason;
		}

		onSuggest({
			...data,
			cancel_reason: reason,
			cancel_other_reason: otherReason
		});
		refetch && refetch()
		setShowDialog(false);
	};

	const onRadioChanged = (e) => {
		setReason(e.target.value);
	};

	const onChangeAmountChanged = (e) => {
		setIsCustomAmount(e.target.value);
	}

	const isDisabled = useMemo(() => {
		let isDisabled = false;
		if(step === 1){
			if(reason){
				return otherReason.length <= 0;
			} else {
				return !reason;
			}
		} else {
			if(!suggestDate || !suggestTime){
				return true
			}
			if(isCustomAmount === dictionary.original_amount){
				return false;
			}
			return (changeReason === "");
		}

		return false;
	}, [reason, otherReason, step, isCustomAmount, changeReason, suggestDate, suggestTime]);

	const onContinue = async () => {
		if (step === 1) {
			setStep(2);
		} else {
			await onConfirmClicked();
		}
	};

	const onItemBlur = () => {
		const newValue = customAmount >= 5 ? customAmount : 5;
		setCustomAmount(newValue);
	};

	return (
		<div className="dialog-wrapper" onClick={(e) => e.stopPropagation()}>
			<div className="dialog-container">
				<div className="dialog-title-container">
					<div className="dialog-title">
						{isConfirmed
							? dictionary.reschedule_booking
							: dictionary.suggest_new_date}
					</div>
					<div className="dialog-close-button" onClick={onCancelClicked} />
				</div>
				<div className="dialog-title-divider" style={{ marginBottom: 0 }} />

				<div className="dialog-content-container">
					{step === 1 && (
						<>
							<h3 style={{ fontSize: 16, marginBottom: 24 }}>
								{dictionary.why_reschedule_booking}
							</h3>
							{reasons?.map(({ label, value }, index) => (
								<label key={index} className="radio-label">
									{label}
									<input
										onChange={onRadioChanged}
										checked={reason === value}
										value={value}
										type="radio"
									/>
									<span className="checkmark" />
								</label>
							))}
							{reason && (
								<>
									<div className="text">{dictionary.please_specify}</div>
									<textarea
										rows={8}
										value={otherReason}
										onChange={(e) => setOtherReason(e.target.value)}
										placeholder={dictionary.reschedule_reason}
										style={{ marginTop: 8 }}
									/>
								</>
							)}
						</>
					)}
					{step === 2 && (
						<>
							<label style={{marginBottom: 24, fontWeight: 500}}>
								{isConfirmed ? dictionary.current_date : dictionary.requested_date}: {DateFormatHelper.rangeDayMonthYearHourFormat(request.slot_start_time || request.slot.start_time, request.slot_end_time || request.slot.end_time)}
							</label>
							<div
								style={{marginTop: 0, marginBottom: 24}}
								className="group-inputs"
							>
								<label style={{marginBottom: 8}}>{dictionary.date}</label>
								<DatePickerField
									placeholderText="Select date"
									value={suggestDate}
									minDate={new Date()}
									dateFormat="EEE, MMM d, yyyy"
									onChange={onDateChanged}
								/>
							</div>
							<div
								style={{marginTop: 0, marginBottom: 24}}
								className="group-inputs"
							>
								<label style={{marginBottom: 8}}>{dictionary.time}</label>
								<TimePickerField value={suggestTime} onChange={setSuggestTime}/>
							</div>
							<CustomCheckbox
								label={'Make this new time slot visible to all users'}
								checked={isViewAll}
								setChecked={setIsViewAll}
							/>

							{!isConfirmed ? (
								<>
									<label style={{ marginBottom: 8, marginTop: 24, fontWeight: 500 }}>
										{dictionary.total_cost}:
									</label>

									<div>
										<label className="radio-label">
											{dictionary.original_amount}: {currencyDecorator(request?.amount)}
											<input
												type="radio"
												value={dictionary.original_amount}
												checked={isCustomAmount === dictionary.original_amount}
												onChange={onChangeAmountChanged}
											/>
											<span className="checkmark" />
										</label>
									</div>
									<div className="d-flex items-center">
										<label className="radio-label">
											{dictionary.change_amount}:
											<input
												type="radio"
												value={dictionary.change_amount}
												checked={isCustomAmount === dictionary.change_amount}
												onChange={onChangeAmountChanged}
											/>
											<span className="checkmark" />
										</label>
										{isCustomAmount === dictionary.change_amount &&
											<div className="group-inputs" style={{ width: '120px', marginTop: 0, marginBottom: 10	, marginLeft: 12 }}>
												<MoneyInput
													min={request?.amount}
													value={customAmount}
													setValue={(e) => setCustomAmount(e.target.value)}
													onBlur={onItemBlur}
												/>
											</div>
										}
									</div>
									<div className="slot-detail-content" style={{ marginBottom: 24 }}>
										{isCustomAmount === dictionary.change_amount ?
											<div className="group-inputs">
												<div className="text">{dictionary.reason_for_change}:</div>
												<textarea
													placeholder={dictionary.describe_reason_change}
													disabled={isCustomAmount !== dictionary.change_amount}
													value={changeReason}
													onChange={(e) => setChangeReason(e.target.value)}
													data-testid="experience-name-input"
												/>
											</div>
											: null}
									</div>
								</>): null}

						</>
					)}
				</div>
				<div className="dialog-title-divider" style={{margin: 0}}/>
				<div
					className="dialog-buttons-container"
					style={{
						padding: 12,
						marginBottom: 0,
						alignSelf: 'center',
						width: '100%'
					}}
				>
					<button className="tertiary-button" onClick={onCancel}>
						{step === 1 ? dictionary.cancel : isConfirmed ? dictionary.back : dictionary.cancel}
					</button>
					<button onClick={onContinue} disabled={isDisabled} className="button">
						{step === 1 ? dictionary.continue : (isConfirmed) ? dictionary.reschedule : dictionary.suggest}
					</button>
				</div>
			</div>
		</div>
	);
};

SuggestDateDialog.propTypes = {
	onSuggest: PropTypes.func,
	isConfirmed: PropTypes.bool,
	setShowDialog: PropTypes.func,
	refetch: PropTypes.func,
	request: PropTypes.object
};

export default SuggestDateDialog;
