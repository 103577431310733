import { API_KEY } from '../config';
import { actions } from '../app/containers/auth/__redux/authRedux';

export default function setupAxios(axios, store) {
	axios.interceptors.request.use(
		(config) => {
			const {
				auth: { oauth, user }
			} = store.getState();

			const activated = user ? user.activated : null;

			if (config.url.includes('users/secure')) {
				config.headers.Authorization = `Basic ${API_KEY}`;
			} else if (oauth && activated) {
				const { access_token } = oauth;
				if (access_token) {
					config.headers.Authorization = `Bearer ${access_token}`;
				}
			} else {
				config.headers.Authorization = `Basic ${API_KEY}`;
			}

			return config;
		},
		(err) => Promise.reject(err)
	);

	// Add a response interceptor
	axios.interceptors.response.use(
		function (response) {
			// Any status code that lie within the range of 2xx cause this function to trigger
			// Do something with response data
			return response;
		},
		function (error) {
			if (error && error.response && error.response.data) {
				const { data } = error.response;
				if (data.error_code == 1) {
					store.dispatch(actions.logout_expired());
					window.location.href = '/auth/login_expired';
				}
			}
			return Promise.reject(error);
		}
	);
}
