import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { connect } from 'react-redux';
import { login } from '../__redux/authCrud';
import * as auth from '../__redux/authRedux';
import * as Yup from 'yup';
import FormikHelper from '../../../../utilities/helpers/FormikHelper';
import ShowHidePassword from '../../components/custom/ShowHidePassword';
import { CryptoHelper } from '../../../../utilities/helpers/CryptoHelper';
import '../../../../assets/css/login.css';
import '../../../../assets/css/form_elements.css';
import { useHistory } from 'react-router-dom';
import FormLoader from '../../common/FormLoader';
import { bindActionCreators } from 'redux';
import * as common from '../../common/__redux/commonRedux';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';

const LoginForm = (props) => {
	const dictionary = useDictionary();
	const [showForm, setShowForm] = useState(false);
	const [showLoader, setShowLoader] = useState(false);
	const history = useHistory();

	const LoginSchema = Yup.object().shape({
		email: FormikHelper.getDefaultYupEmail(true, dictionary),
		password: Yup.string().required(
			dictionary.requiredTemplate(dictionary.password)
		)
	});

	useEffect(() => {
		setTimeout(() => setShowForm(true), 100);
	}, []);

	const formik = useFormik({
		initialValues: {
			email: '',
			password: ''
		},
		onSubmit: (values, { setSubmitting }) => {
			setShowLoader(true);
			login(
				values.email,
				CryptoHelper.encryptData(values.password, props.metadata),
				props.sessionId
			)
				.then((response) => {
					props.actions.login(response.data);
				})
				.catch((error) => {
					setShowLoader(false);
					setSubmitting(false);
					if (error.response) {
						props.actions.setErrorMessage(
							error.response.data.error_code.toString()
						);
					}
				});
		},
		validateOnMount: true,
		validationSchema: LoginSchema
	});

	FormikHelper.setFormik(formik);

	const onSignupClicked = () => {
		history.push('register');
	};

	const onResetClicked = () => {
		history.push('forgot-password');
	};

	return (
		<div className="login-wrapper">
			<form
				style={{ opacity: showForm ? '1' : '0' }}
				className="login-form-wrapper"
				onSubmit={formik.handleSubmit}
			>
				<div className="login-form-container" style={{ position: 'relative' }}>
					<div className="login-logo" />
					<div className="title-divider" />
					<h1 data-testid="welcome-title">{dictionary.welcome_upirian}</h1>
					{/* <div
						data-testid="welcome-message"
						className="text"
						style={{ marginTop: '20px', marginBottom: '30px' }}
					>
						{dictionary.login_message}
					</div> */}
					<div
						className="login-form-content-container"
						style={{ position: 'relative' }}
					>
						{FormikHelper.getInputTemplate(
							'email',
							'Email',
							dictionary.enter_email,
							'email',
							true,
							true
						)}
						{ShowHidePassword(
							'password',
							'Password',
							dictionary.enter_password,
							true,
							formik,
							true
						)}
						<label
							onClick={onResetClicked}
							style={{ position: 'absolute', right: '0', top: '125px' }}
							className="link-text"
							data-testid="forgot-pass-btn"
						>
							{dictionary.forgot_pass}
						</label>
						<div className="group-inputs" style={{ marginTop: '30px' }}>
							<button
								data-testid="login-btn"
								type="submit"
								className={formik.isValid ? 'button' : 'button button-disabled'}
							>
								{dictionary.login}
							</button>
						</div>
						<div
							data-testid="welcome-message"
							className="text"
							style={{ marginTop: '20px', marginBottom: '30px' }}
						>
							{dictionary.dont_have_an_account}
							<span
								data-testid="signup-btn"
								onClick={onSignupClicked}
								className="link-text"
							>
							{dictionary.signup}
						</span>
						</div>
					</div>
					<FormLoader showLoader={showLoader} />
				</div>
			</form>
			<div className="login-side-image" />
		</div>
	);
};

LoginForm.propTypes = {
	actions: PropTypes.object,
	metadata: PropTypes.string,
	sessionId: PropTypes.string
};

export default connect(null, (dispatch) => {
	return {
		actions: bindActionCreators(
			{ ...auth.actions, ...common.actions },
			dispatch
		)
	};
})(LoginForm);
