import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as auth from '../__redux/authRedux';
import { logout as apiLogout } from '../__redux/authCrud';
import PropTypes from 'prop-types';

const Logout = (props) => {
	const history = useHistory();

	useEffect(() => {
		// Clear local storage before logging out
		localStorage.clear();
		// send logout request to server
		const performLogout = async () => {
			try {
				await apiLogout(props.user.session_id);
			} catch (error) {
				console.error('Logout error:', error);
			}
			props.logout();
			history.push('login');
		};

		performLogout();
	}, []);

	return <></>;
};

Logout.propTypes = {
	logout: PropTypes.func,
	user: PropTypes.shape({
		session_id: PropTypes.string
	})
};

const mapStateToProps = (state) => ({
	user: state.auth.user
});

export default connect(mapStateToProps, auth.actions)(Logout);
