import { calendarColors } from '../../../../../../data/calendar_colors';
import ExperienceCalendarColor from './ExperienceCalendarColor';
import PropTypes from 'prop-types';
import React from 'react';

const ExperienceCalendarColorPicker = ({ colorCode, setColorCode }) => {
	return (
		<div className="experience-calendar-color-wrapper">
			{calendarColors.map((color) => (
				<ExperienceCalendarColor
					key={color}
					selectedColor={colorCode}
					setColorCode={setColorCode}
					color={color}
				/>
			))}
		</div>
	);
};

ExperienceCalendarColorPicker.propTypes = {
	colorCode: PropTypes.string.isRequired,
	setColorCode: PropTypes.func.isRequired
};

export default ExperienceCalendarColorPicker;
