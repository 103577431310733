import uk from '../../../../assets/icons/flags/uk.svg';
import React from 'react';
import { useUserTypeId } from '../../../../utilities/hooks/UseUserTypeId';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import pencil from '../../../../assets/icons/ic_pencil.svg';
import pause from '../../../../assets/icons/ic_pause.svg';
import trash from '../../../../assets/icons/ic_trash.svg';
import { NavLink } from 'react-router-dom';
import ImageHelper from '../../../../utilities/helpers/ImageHelper';
import logo from '../../../../assets/images/logo_white_letter.svg';
import RelatedExperienceItem from '../provider-resources/RelatedExperieceItem';
import { TextFormatHelper } from '../../../../utilities/helpers/TextFormatHelper';
import PropTypes from 'prop-types';

const AddonListItemFull = ({ addon, setSelectedDeleteAddon }) => {
	const userType = useUserTypeId();
	const dictionary = useDictionary();

	return (
		<div className="experience-item-full-container">
			<div className="experience-item-basic-info-container">
				<div className="experience-item-row">
					<div className="experience-flags-container">
						<img src={uk} />
					</div>
				</div>
				{userType === 1 ? (
					<div style={{ marginTop: '6px' }}>
						<h3>{addon?.provider?.email}</h3>
						<h3>
							{addon?.provider?.first_name + ' ' + addon?.provider?.last_name}
						</h3>
						<div
							style={{ marginTop: '4px', marginBottom: '4px' }}
							className="dialog-title-divider"
						/>
					</div>
				) : null}
				<div className="flex-filler" />
				<h3 style={{ marginTop: '12px' }}>{addon?.title}</h3>
				<div style={{ marginTop: '10px' }} className="text ellipsize">
					{addon?.description}
				</div>
				<div className="experience-item-buttons-container">
					<NavLink
						to={'provider-resources/' + addon.id}
						className="experience-item-button-container"
					>
						<img src={pencil} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.edit}</h4>
					</NavLink>
					<div className="experience-item-button-container">
						<img src={pause} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.pause}</h4>
					</div>
					<div
						onClick={() => setSelectedDeleteAddon(addon)}
						className="experience-item-button-container"
					>
						<img src={trash} />
						<h4 style={{ fontSize: '12px' }}>{dictionary.delete}</h4>
					</div>
				</div>
			</div>
			<div
				className="experience-thumbs-container"
				style={{ marginTop: '16px', marginBottom: '16px' }}
			>
				<img
					onError={(e) => {
						e.currentTarget.src = logo;
						e.currentTarget.style.padding = '40px';
					}}
					className="uploaded-image-full"
					src={/*ImageHelper.getFullImageUrl(*/ addon?.image_url /*)*/}
				/>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="text-with-info-light">{dictionary.pricing}</div>
				<div style={{ marginTop: '12px' }} className="experience-item-row">
					<div className="text">{dictionary.general}</div>
					<div className="experience-info-title">
						{TextFormatHelper.formatPrice(addon?.price) +
							' ' +
							dictionary.per_item}
					</div>
				</div>
				<div style={{ marginTop: '32px' }} className="text-with-info-light">
					{dictionary.details}
				</div>
				<div style={{ marginTop: '12px' }} className="experience-item-row">
					<div className="text">{dictionary.total}</div>
					<div className="experience-info-title">
						{addon.quantity === 0
							? dictionary.unlimited
							: addon?.quantity?.toString()}
					</div>
				</div>
			</div>
			<div className="experience-item-divider" />
			<div className="experience-small-column">
				<div className="text-with-info-light">
					{dictionary.used_in_experiences}
				</div>
				<div
					className="experience-info-rows-container thin-scrollbar"
					style={{ overflowY: 'auto' }}
				>
					{addon.experiences.map((experience) => (
						<RelatedExperienceItem key={experience.title} item={experience} />
					))}
					{!addon.experiences || addon.experiences.length === 0 ? (
						<div className={'text'}>{dictionary.no_experiences_yet}</div>
					) : null}
				</div>
			</div>
		</div>
	);
};

AddonListItemFull.propTypes = {
	addon: PropTypes.object.isRequired,
	setSelectedDeleteAddon: PropTypes.func.isRequired,
};

export default AddonListItemFull;
