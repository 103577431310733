import { shallowEqual, useSelector } from 'react-redux';

export const useUser = () => {
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);
	return user;
};
