import { detectMobile2 } from '../../../../../utilities/helpers/MobileHelper';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import SimpleLoader from '../../../common/SimpleLoader';
import ExperienceStep from '../../new_experience/ExperienceStep';
import { createExperienceResource } from '../../provider-resources/networking';
import AddonCapacityAndPricing from './steps/AddonCapacityAndPricing';
import AddonNameAndDetails from './steps/AddonNameAndDetails';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const AddonDetails = () => {
	const dictionary = useDictionary();

	const [step, setStep] = useState(1);
	const [max, setMax] = useState(1);
	const [showLoader, setShowLoader] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [name, setName] = useState('');
	const [description, setDescription] = useState('');
	const [supplier, setSupplier] = useState('');
	const [isGroupAddon, setGroupAddon] = useState(false);
	const [fileToUpload, setFileToUpload] = useState(null);
	const [image, setImage] = useState(null);

	//capacity and pricing
	const [maxNumber, setMaxNumber] = useState(0);
	const [price, setPrice] = useState(0);
	const [isUnlimited, setUnlimited] = useState(false);

	const { id } = useParams();
	const history = useHistory();
	const isMobile = detectMobile2();
	const [initLabel, setInitLabel] = useState(
		id ? dictionary.edit_addon : dictionary.create_addon
	);

	const setIncrementalStep = (index) => {
		setStep(index);
		if (index > max) {
			setMax(index);
		}
	};

	const onNextClicked = () => {
		if (enabled) {
			if (step === 2) {
				saveAddon();
			} else {
				setIncrementalStep(step + 1);
			}
		}
	};

	const onPreviousClicked = () => {
		setIncrementalStep(step - 1);
	};

	// const onSaveClicked = () => {
	// 	if (max === 0) {
	// 		onAddonSaved();
	// 	} else {
	// 		updateAddonAsync();
	// 	}
	// };

	// const onAddonSaved = () => {
	// 	history.goBack();
	// };

	const onCancelClicked = () => {
		history.goBack();
	};

	const saveAddon = () => {
		updateAddonAsync(id !== null && id !== undefined);
	};

	const updateAddonAsync = async (update) => {
		setShowLoader(true);

		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await createExperienceResource(
				name,
				description,
				fileToUpload,
				id,
				update
			);
			setShowLoader(false);
			history.goBack();
		} catch (e) {
			setShowLoader(false);
		}
	};
	const steps = {
		1: (
			<AddonNameAndDetails
				name={name}
				setName={setName}
				image={image}
				setImage={setImage}
				description={description}
				setDescription={setDescription}
				isGroupAddon={isGroupAddon}
				setGroupAddon={setGroupAddon}
				fileToUpload={fileToUpload}
				setFileToUpload={setFileToUpload}
				setEnabled={setEnabled}
				supplier={supplier}
				setSupplier={setSupplier}
			/>
		),
		2: (
			<AddonCapacityAndPricing
				setEnabled={setEnabled}
				maxNumber={maxNumber}
				setMaxNumber={setMaxNumber}
				price={price}
				setPrice={setPrice}
				isUnlimited={isUnlimited}
				setUnlimited={setUnlimited}
			/>
		)
	};

	return (
		<div className="new-experience-wrapper">
			<div className="new-experience-nav-wrapper">
				<div
					className="round-close-button-white new-experience-close-button"
					onClick={() => onCancelClicked()}
				/>
				<div className="new-experience-nav-container">
					<div className="new-experience-nav-top-container">
						<div className="new-experience-nav-top-logo" />
						<div onClick={() => saveAddon()} className="sidebar-button">
							{max === 0 ? dictionary.exit : dictionary.save_and_exit}
						</div>
					</div>
					<h2
						className="new-experience-nav-title mobile-invisible"
						style={{ color: 'white' }}
					>
						{initLabel}
					</h2>
					<div className="new-experience-nav-items-container thin-scrollbar">
						<ExperienceStep
							step={1}
							max={max}
							activeStep={step}
							name={dictionary.name_and_details}
							setStep={setIncrementalStep}
						/>
						<ExperienceStep
							step={2}
							max={max}
							activeStep={step}
							name={dictionary.capacity_and_pricing}
							setStep={setIncrementalStep}
						/>
					</div>
				</div>
			</div>
			<div className="new-experience-content-wrapper">
				<div className="new-experience-content-container">{steps[step]}</div>
				<div className="new-experience-bottom-navigation-container">
					{step !== 1 ? (
						<div
							onClick={onPreviousClicked}
							className="button secondary-button"
						>
							{dictionary.back}
						</div>
					) : null}
					{2 -
						step +
						1 +
						' ' +
						(isMobile ? dictionary.steps_left_short : dictionary.steps_left)}
					<div
						onClick={onNextClicked}
						className={enabled ? 'button' : 'button button-disabled'}
					>
						{step === 11 ? dictionary.finish : dictionary.next}
					</div>
				</div>
			</div>

			<SimpleLoader showLoader={showLoader} />
		</div>
	);
};
export default AddonDetails;
