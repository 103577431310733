import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import image from '../../../../../assets/images/onboarding_experience.png';
import { useHistory } from 'react-router-dom';
import React from 'react';

const OnBoardingFirstExperience = () => {
	const dictionary = useDictionary();
	const history = useHistory();

	return (
		<div
			className="onboarding-activity-container"
			data-testid="onboarding-first-experience"
		>
			<img src={image} className="onboarding-activity-image" />
			<div className="onboarding-activity-info-container">
				<div className="onboarding-activity-title">
					{dictionary.create_first_experience_title}
				</div>
				<div className="text">{dictionary.create_first_experience_text}</div>
				<button
					onClick={() => history.push('/experiences')}
					style={{ marginTop: '25px' }}
					className="button"
					data-testid="create-first-experience-button"
				>
					{dictionary.create_first_experience}
				</button>
			</div>
		</div>
	);
};

export default OnBoardingFirstExperience;
