import React from 'react';
import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import PropTypes from 'prop-types';
import { useBookingStatuses } from '../../../../../../utilities/hooks/useBookingStatuses';
import caretLeft from '../../../../../../assets/icons/ic_caret_left.svg';
import MultipeCustomCheckbox from '../../../../common/MultipeCustomCheckbox';

const SelectFilterStatus = (props) => {
    const dictionary = useDictionary();
    const bookingStatuses = useBookingStatuses();

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowStatusSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(bookingStatuses).map((bookingStatus) => (
                <MultipeCustomCheckbox
                    label={bookingStatuses[bookingStatus].text}
                    checbboxObj={props.statusCheckeds}
                    itemKey={bookingStatus}
                    onFilterStatusSelected={props.onFilterStatusSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    key={bookingStatuses[bookingStatus].id}
                />
            ))}
        </div>
    );
}

SelectFilterStatus.propTypes = {
    onStatusValuesChanged: PropTypes.func,
	setShowBoxFilter: PropTypes.func,
	setShowStatusSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    statusCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterStatusSelected: PropTypes.func
};

export default SelectFilterStatus;
