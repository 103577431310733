export const ArrayHelper = (function () {
	function removeItemOnce(arr, value) {
		let index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		}
		return arr;
	}

	function toggleItem(arr, value) {
		let index = arr.indexOf(value);
		if (index > -1) {
			arr.splice(index, 1);
		} else {
			arr.push(value);
		}
		return arr;
	}

	return {
		removeItemOnce: function (arr, value) {
			removeItemOnce(arr, value);
		},
		toggleItem: function (arr, value) {
			toggleItem(arr, value);
		}
	};
})();
