import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

const InfiniteScrollLoop = ({ surroundingBackup = 2, children }) => {
	const contentRef = useRef(null);
	const scrollRef = useRef(null);
	const [height, setHeight] = useState(0);

	const backupHeight = height * surroundingBackup;

	const handleScroll = useCallback(() => {
		if (scrollRef.current) {
			const scroll = scrollRef.current.scrollTop;
			if (scroll < backupHeight || scroll >= backupHeight + height) {
				scrollRef.current.scrollTop = backupHeight + (scroll % height);
			}
		}
	}, [height]);

	useEffect(() => {
		if (contentRef.current) {
			setHeight(contentRef.current.offsetHeight);
			scrollRef.current.scrollTop = backupHeight;
		}
	}, [contentRef.current]);

	return (
		<div className="infinite-scroll-loop-outer">
			<div
				ref={scrollRef}
				style={{
					height
				}}
				className="infinite-scroll-loop-inner"
				onScroll={handleScroll}
			>
				{Array(surroundingBackup)
					.fill()
					.map((_, index) => (
						<div key={index}>{children}</div>
					))}
				<div ref={contentRef}>{children}</div>
				{Array(surroundingBackup)
					.fill()
					.map((_, index) => (
						<div key={index}>{children}</div>
					))}
			</div>
		</div>
	);
};

InfiniteScrollLoop.propTypes = {
	surroundingBackup: PropTypes.number,
	children: PropTypes.node
};

export default InfiniteScrollLoop;
