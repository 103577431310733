import TransactionItem from './TransactionItem';
import React, { useEffect, useState } from 'react';
import DateRangePicker from '../../../common/DateRangePicker';
import { useDictionary } from '../../../../../utilities/hooks/UseDictionary';
import { getProviderPayouts } from '../networking';
import PropTypes from 'prop-types';

// eslint-disable-next-line no-unused-vars
const PayoutsList = ({ showLoader, setLoaderVisibility, overviewResult }) => {
	const [startDate, setStartDate] = useState('');
	const [endDate, setEndDate] = useState('');
	const dictionary = useDictionary();

	const [transactions, setTransactions] = useState([]);

	const [showPlaceholder, setPlaceholderVisibility] = useState(false);

	useEffect(() => {
		getTransactionsAsync();
	}, []);

	useEffect(() => {
		if (startDate !== '' && endDate !== '') getTransactionsAsync();
	}, [startDate, endDate]);

	const getTransactionsAsync = async () => {
		//setLoaderVisibility(true)
		try {
			let result = await getProviderPayouts(startDate, endDate);
			let orderItems = result.data.sort(function (dateA, dateB) {
				let a = new Date(dateA.date);
				let b = new Date(dateB.date);
				return a - b;
			});
			setTransactions(orderItems);
			if (result.data.length === 0) {
				setPlaceholderVisibility(true);
			}
			setLoaderVisibility(false);
		} catch (e) {
			setLoaderVisibility(false);
		}
	};

	return (
		<>
			<div
				style={{
					display: 'flex',
					justifyContent: 'space-between',
					marginBottom: '16px',
					marginTop: '20px'
				}}
			>
				<h2>{dictionary.transactions}</h2>
				{/* <DateRangePicker
					startDate={startDate}
					setStartDate={setStartDate}
					endDate={endDate}
					setEndDate={setEndDate}
				/> */}
			</div>
			<div
				className="upiria-table-container thin-scrollbar"
				style={{
					background: 'unset',
					overflowY: 'auto',
					height: 0,
					flexGrow: '1'
				}}
			>
				<div
					className="upiria-table-header-strip"
					style={{ background: 'unset' }}
				/>
				<table cellSpacing="0" cellPadding="0" border="0">
					<thead>
						<tr>
							<td>{dictionary.date}</td>
							<td>{dictionary.type}</td>
							<td>{dictionary.description}</td>
							<td>{dictionary.amount}</td>
							<td>{dictionary.fee}</td>
							<td>{dictionary.balance}</td>
						</tr>
					</thead>
					<tbody>
					{
						transactions?.map((item, index) => (
						<TransactionItem key={index} item={item} />
						))
					}
					</tbody>
				</table>
				{showPlaceholder ? (
					<div
						className="empty-experiences-list-wrapper"
						style={{ height: 'unset', marginTop: '8%' }}
					>
						<div className="empty-experiences-list-placeholder">
							<div className="empty-experiences-image empty-payouts-image" />
							<h3 style={{ textAlign: 'center', marginTop: '24px' }}>
								{dictionary.no_transactions}
							</h3>
							<div style={{ marginTop: '8px' }} className="text">
								{dictionary.transactions_will_be_shown}
							</div>
						</div>
					</div>
				) : null}
			</div>
		</>
	);
};

PayoutsList.propTypes = {
	showLoader: PropTypes.bool,
	setLoaderVisibility: PropTypes.func,
	overviewResult: PropTypes.object
};

export default PayoutsList;
