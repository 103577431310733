import React, { useEffect, useState } from 'react';
import LoginForm from '../forms/Loginform';
import { connect } from 'react-redux';
import * as auth from '../__redux/authRedux';
import { CryptoHelper } from '../../../../utilities/helpers/CryptoHelper.js';
import { getPK } from '../__redux/authCrud';
import '././../../../../assets/css/login.css';
import '././../../../../assets/css/form_elements.css';

const Login = () => {
	const [sessionId, setSessionId] = useState(null);
	const [metadata, setMetadata] = useState(null);

	useEffect(() => {
		let sessionId = CryptoHelper.generateSessionId(20);
		setSessionId(sessionId);

		getPK(sessionId).then((response) => {
			setMetadata(response.data.metadata);
		});
	}, []);

	return (
		<div className="main-container">
			<LoginForm sessionId={sessionId} metadata={metadata} />
		</div>
	);
};

export default connect(
	({ auth }) => ({ errors: auth.errors, account_type: auth.account_type }),
	auth.actions
)(Login);
