import PropTypes from 'prop-types';
import React from 'react';
import caretLeft from '../../../../assets/icons/ic_caret_left.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import { useExperienceSlotAccess } from '../../../../utilities/hooks/useExperienceSlotAccess';
import MultipeCustomAccessCheckbox from '../../common/MultipeCustomAccessCheckbox';

const SelectFilterAccess = (props) => {
    const dictionary = useDictionary();
    const asccessStatuses = useExperienceSlotAccess();

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowAccessSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(asccessStatuses).map((asccessStatus) => (
                <MultipeCustomAccessCheckbox
                    label={asccessStatuses[asccessStatus].text}
                    checbboxObj={props.accessCheckeds}
                    itemKey={asccessStatus}
                    onFilterAccessSelected={props.onFilterAccessSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    key={asccessStatuses[asccessStatus].id}
                />
            ))}
        </div>
    );
}

SelectFilterAccess.propTypes = {
	setShowBoxFilter: PropTypes.func,
	setShowAccessSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    accessCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterAccessSelected: PropTypes.func
};

export default SelectFilterAccess;
