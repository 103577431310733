import PropTypes from 'prop-types';
import React from 'react';
import caretLeft from '../../../../assets/icons/ic_caret_left.svg';
import { useDictionary } from '../../../../utilities/hooks/UseDictionary';
import MultipeCustomExperienceCheckbox from '../../common/MultipeCustomExperienceCheckbox';

const SelectFilterExperiences = (props) => {
    const dictionary = useDictionary();
    const experiences = props.experiences;
    let experienceItems = [];
    let experienceObjs = {};
    for (const experience of experiences) {
        const experienceItem = {
            id: experience.id,
            text: experience.title,
            class: experience.id,
        }
        
        experienceItems.push(experienceItem);
        experienceObjs[experience.id] = experienceItem;
    }

    const backToFilterList = () => {
        props.setShowBoxFilter(true);
        props.setShowExperienceSelectbox(false);
    }

    return (
        <div className="group-filter-hidden inputs">
            <a href="#" onClick={backToFilterList} className="back-to-filter-list">
                <img src={caretLeft} />
                <span>{dictionary.back}</span>
            </a>
            {Object.keys(experienceObjs).map((experienceObj) => (
                <MultipeCustomExperienceCheckbox
                    label={experienceObjs[experienceObj].text}
                    checbboxObj={props.experienceCheckeds}
                    itemKey={experienceObj}
                    onFilterExperienceSelected={props.onFilterExperienceSelected}
                    setEnableScheduling={props.setEnableScheduling}
                    filterCount={props.filterCount}
                    experienceObjs={experienceObjs}
                    key={experienceObjs[experienceObj].id}
                />
            ))}
            
        </div>
    );
}

SelectFilterExperiences.propTypes = {
	setShowBoxFilter: PropTypes.func,
	setShowExperienceSelectbox: PropTypes.func,
	setEnableScheduling: PropTypes.func,
    accessCheckeds: PropTypes.array,
    filterCount: PropTypes.number,
    onFilterExperienceSelected: PropTypes.func
};

export default SelectFilterExperiences;
