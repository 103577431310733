import { useDictionary } from '../../../../../../utilities/hooks/UseDictionary';
import * as common from '../../../../common/__redux/commonRedux';
import LegalInfoDialog from '../../../../common/legal-info-dialog/LegalInfoDialog';
import { uploadProviderDocument } from '../../../provider_home/submit_documents_dialog/networking';
import { deleteLegalFile, getProviderInfo } from '../../networking';
import LegalInfoDocumentRow from './LegalInfoDocumentRow';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

const LegalInfo = ({
	actions,
	remoteData,
	setShowLoader,
	getUserInfoAsync,
	setSuccessMessage
}) => {
	const dictionary = useDictionary();
	const [idFile, setIdFile] = useState(null);
	const [addressFile, setAddressFile] = useState(null);
	const [ibanFile, setIbanFile] = useState(null);
	const [companyFile, setCompanyFile] = useState(null);
	const [submitted, setSubmitted] = useState(0);
	const [max, setMax] = useState(0);
	const [saveDisabled, setSaveDisabled] = useState(true);
	const [clear, setClear] = useState(0);
	const [type, setType] = useState(3);
	const [showLegalInfoDialog, setLegalInfoDialogVisibility] = useState(false);
	const isUserUnverified = remoteData.verification_status.key === 'unverified';

	useEffect(() => {
		if (idFile || addressFile || ibanFile || companyFile) {
			setSaveDisabled(false);
		} else {
			setSaveDisabled(true);
		}
	}, [idFile, addressFile, ibanFile, companyFile]);

	useEffect(() => {
		if (submitted !== 0 && max !== 0 && submitted === max) {
			setSubmitted(0);
			setMax(0);
			clearFiles();
			setSuccessMessage(dictionary.success_uploaded);
			getUserInfoAsync();
		}
	}, [submitted]);

	const clearFiles = () => {
		setClear((oldvalue) => oldvalue + 1);
		setIdFile(null);
		setAddressFile(null);
		setIbanFile(null);
		setCompanyFile(null);
	};

	const getFile = (type) => {
		return (remoteData?.files?.filter((value) => value.type.key === type))[0];
	};

	const onDeleteRemoteFileClicked = async (id) => {
		setShowLoader(true);
		try {
			// eslint-disable-next-line no-unused-vars
			let { data } = await deleteLegalFile(id);
			getUserInfoAsync();
		} catch (e) {
			setShowLoader(false);
		}
	};

	const uploadDocumentAsync = async (file, status) => {
		try {
			// eslint-disable-next-line no-unused-vars
			const { data } = await uploadProviderDocument(file, status);
			const { data: account } = await getProviderInfo();
			actions.update_user({ account });
			setSubmitted((oldvalue) => oldvalue + 1);
		} catch (error) {
			setShowLoader(false);
		}
	};

	const submitDocs = () => {
		setShowLoader(true);
		if (idFile) {
			setMax(max + 1);
		}
		if (addressFile) {
			setMax(max + 1);
		}
		if (ibanFile) {
			setMax(max + 1);
		}
		if (companyFile) {
			setMax(max + 1);
		}

		if (idFile) {
			uploadDocumentAsync(idFile, '1');
		}
		if (addressFile) {
			uploadDocumentAsync(addressFile, '2');
		}
		if (ibanFile) {
			uploadDocumentAsync(ibanFile, '3');
		}
		if (companyFile) {
			uploadDocumentAsync(companyFile, '4');
		}
	};

	const showLegaDialog = (type) => {
		setType(type);
		setLegalInfoDialogVisibility(true);
	};

	return (
		<div className="settings-page-container fade-on-load thin-scrollbar-trans">
			<div
				className="onboarding-activity-title"
				data-testid="legal-info-header"
			>
				{dictionary.legal_info}
			</div>
			<div className="settings-form-row">
				<div className="settings-info-column">
					<div className="group-inputs">
						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{dictionary.vat_number}
						</label>
						<input
							data-testid="vat-number-input"
							disabled={true}
							type="text"
							value={remoteData?.tax_id_number}
						/>

						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{dictionary.beneficiary_name}
						</label>
						<input
							data-testid="beneficiary-name-input"
							disabled={true}
							type="text"
							value={remoteData?.account_beneficiary}
						/>

						<label style={{ marginBottom: '8x', marginTop: '24px' }}>
							{'IBAN'}
						</label>
						<input
							data-testid="iban-input"
							disabled={true}
							type="text"
							value={remoteData?.account_iban}
						/>
					</div>

					{/*<LegalInfoDocumentRow*/}
					{/*	clear={clear}*/}
					{/*	onDeleteRemoteFileClicked={onDeleteRemoteFileClicked}*/}
					{/*	remoteFile={getFile('proofOfId')}*/}
					{/*	setLocalFile={setIdFile}*/}
					{/*	title={dictionary.id_document}*/}
					{/*	text={dictionary.id_document_text}*/}
					{/*	disabled={isUserUnverified}*/}
					{/*/>*/}
					{/*<LegalInfoDocumentRow*/}
					{/*	clear={clear}*/}
					{/*	onDeleteRemoteFileClicked={onDeleteRemoteFileClicked}*/}
					{/*	remoteFile={getFile('proofOfAddress')}*/}
					{/*	setLocalFile={setAddressFile}*/}
					{/*	title={dictionary.proof_of_address}*/}
					{/*	text={dictionary.proof_of_address_document_text}*/}
					{/*	disabled={isUserUnverified}*/}
					{/*/>*/}
					{/*<LegalInfoDocumentRow*/}
					{/*	clear={clear}*/}
					{/*	onDeleteRemoteFileClicked={onDeleteRemoteFileClicked}*/}
					{/*	remoteFile={getFile('proofOfBank')}*/}
					{/*	setLocalFile={setIbanFile}*/}
					{/*	title={dictionary.iban_document}*/}
					{/*	text={dictionary.iban_document_text}*/}
					{/*	disabled={isUserUnverified}*/}
					{/*/>*/}
					{/*<LegalInfoDocumentRow*/}
					{/*	clear={clear}*/}
					{/*	onDeleteRemoteFileClicked={onDeleteRemoteFileClicked}*/}
					{/*	remoteFile={getFile('proofOfCompany')}*/}
					{/*	setLocalFile={setCompanyFile}*/}
					{/*	title={dictionary.company_document}*/}
					{/*	text={dictionary.company_document_text}*/}
					{/*	disabled={isUserUnverified}*/}
					{/*/>*/}
				</div>
			</div>

			<br />
			<br />

			<div
				className="onboarding-activity-title"
				data-testid="legal-info-header"
			>
				{dictionary.terms_and_policies}
			</div>
			<div className="settings-info-row">
				<div className="settings-info-column">
					<div className="text">
						{dictionary.view_terms_message}{' '}
						<span
							className="link-text-light"
							onClick={() => showLegaDialog(3)}
							data-testid="general-terms-and-conditions-link"
						>
							{dictionary.general_terms_and_conditions}
						</span>
						,{' '}
						<span
							className="link-text-light"
							onClick={() => showLegaDialog(4)}
							data-testid="provider-terms-and-conditions-link"
						>
							{dictionary.provider_terms_and_conditions}
						</span>
						, and{' '}
						<span
							className="link-text-light"
							onClick={() => showLegaDialog(5)}
							data-testid="cancellation-policy-link"
						>
							{dictionary.cancellation_policy}
						</span>
						.
					</div>
				</div>
			</div>

			<div style={{ display: 'flex', marginTop: '30px', gap: '16px' }}>
				<button
					onClick={submitDocs}
					disabled={saveDisabled}
					type={'submit'}
					className="button"
					data-testid="save-button"
				>
					{dictionary.save}
				</button>

				<button
					disabled={saveDisabled}
					onClick={clearFiles}
					className="secondary-button"
					data-testid="cancel-button"
				>
					{dictionary.cancel}
				</button>
			</div>

			{showLegalInfoDialog ? (
				<LegalInfoDialog
					type={type}
					setLegalInfoDialogVisibility={setLegalInfoDialogVisibility}
				/>
			) : null}
		</div>
	);
};

LegalInfo.propTypes = {
	remoteData: PropTypes.object,
	getUserInfoAsync: PropTypes.func,
	setShowLoader: PropTypes.func,
	setSuccessMessage: PropTypes.func
};

export default connect(common.states, common.actions)(LegalInfo);
